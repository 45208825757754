import React, { useState, useEffect } from "react";

import { humanIsoAge } from "./../util/time.js";
import { useAuth } from "./../util/auth.js";
import { Typography, Grid } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";

import { red } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

export default function MessageCard(props) {
  var { user, message } = props;

  const classes = useStyles();

  const auth = useAuth();

  const userID = user === undefined ? auth.user.id : user.id;
  const [timeText, setTimeText] = useState();

  //const hideSystem = false; // If true, hide all system messages
  //const hidePriceUpdate = true; // If true, hide price updates.

  //const { message } = props;
  /*
    if (message === undefined) {
      return "No Messages";
    }
    */
  const { broadcast } = props;

  const {
    
   
    side,
  
  } = props;


  var style = {
   
    color: "#ffffff",
    background: "#6f6f6f",
    borderRadius: "10px",
    marginBottom: "20px",
   
  };

  useEffect(() => {
    if (message === undefined) {
      return;
    }

//    setTimeText(humanAge(message.createdAt));


  }, [message]);

  useEffect(() => {
    setTimeText(humanIsoAge(message.createdAt));
    const timer2 = setInterval(() => {
      // console.log("tick")
      if (message === undefined || message === null) {
        return;
      }
      setTimeText(humanIsoAge(message.createdAt));
    }, 1000);
    // Clear timeout if the component is unmounted
    return () => clearTimeout(timer2);
  }, [message]);
useEffect(()=> {
console.log("MessageCard",message)

}, [message])
  // Message from Seller (private)
  const isMessageOwner =
    message && message.from === userID && auth.user.id === userID
      ? true
      : false;
  if (isMessageOwner) {
    style = {
   
      background: "#f0c135",
      borderRadius: "10px",
      color:'#000000',
  
      marginBottom: "20px",
    };
  }
  if (message && message.broadcast === "room") {
    style = { color: "blue" };
  }

  //if (message.from === 'system' && hideSystem)  {return null;}
  //if (message.subject === 'Price table updated.' && hidePriceUpdate)  {return null;}
  // Message to all from LadyBidwell (public)
 


  return (
    <>

<Grid
      container
      spacing={2} 
      display='flex'
      direction='row'
      justifyContent={isMessageOwner ? 'flex-end' : 'flex-start'  }
  >


<Grid item xs={12} >
<Typography
        
        variant="body2"
        color="textSecondary"
        style={{
          fontSize:'0.75em',
          padding: "0px 10px 0px 10px",
          textAlign: "center",
          // marginBottom: "10px",
        }}
      >
   
        {timeText}
      </Typography>
      </Grid>

<Grid item xs={10} style={style}>

      {message.subject}
      </Grid>





    </Grid>




{/* 
<Box display="flex" justifyContent='flex-end' flexDirection='column'>
      <Typography variant="subtitle1">
        <div style={style}>
          <div> {message.subject}</div>
        </div>
      </Typography>
      <Typography
        variant="body2"
        style={{
          padding: "0px 10px 0px 10px",
          textAlign: "center",
          marginBottom: "10px",
        }}
      >
   
        {timeText}
      </Typography>
      </Box> */}



    </>
  );
}
