import React, { useEffect } from "react";


import { filterHydratedItem } from "./../util/filter.js";
function filterItemLot() {
  return true;
}

function FilteredItems(props) {
  //console.log("FilteredItems props.items", props.items);
  //console.log("FilteredItems props.hydratedItems", props.hydratedItems);

console.log("HydratedItems props.filterType props.items", props.filterType, props.items);


  const updateWithprops = React.Children.map(props.children, (child, i) => {
    const filteredItems = props.items.filter((item) => {
//console.log("FilteredItems item", item);
//return true;
return filterHydratedItem(item, props.filterType);
    });
    return React.cloneElement(child, { color: "red", items: filteredItems });
  });



useEffect(()=>{

console.log("FilteredItems props.items", props.items);

}, [props.items]);



  return <div>{updateWithprops}</div>;
}

export default FilteredItems;
