import React, { useEffect, useState } from "react";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
//import ItemSave from "./../components/ItemSave";
import { useActiveLots, useAuctionsByFeatured } from "./../util/db.js";
import { useAuth } from "../util/auth";

import ItemsGrid from "./ItemsGrid";

import FilteredItems from "./FilteredItems";
import HydratedItems from "./HydratedItems";

import ScoredItems from "./ScoredItems";

import SortedItems from "./SortedItems";

import CutoffItems from "./CutoffItems";


import { debugFlag } from "./../util/dev.js";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: "0px",
  },

  gridItem: {
    // Add border that contrasts lightly with background color.
    // We use boxShadow so that it's hidden around outer edge
    // due to container <Card> having overflow: hidden
    // boxShadow: `1px 1px 0 0 ${emphasize(theme.palette.background.paper, 0.08)}`,
    textAlign: "left",
  },
  imageContainer: {
    margin: "0 auto",
    maxWidth: "200px",
    marginBottom: "30px",
    "& img": {
      width: "100%",
    },
  },
  // title: {
  //   fontWeight: 700,
  //   fontFamily: '"Cormorant Garamond", "serif"',

  //   textTransform: "uppercase",
  // },
  subtitle: {
    fontSize: "1rem",
  },
  open: {
    transform: "scaleX(1)",
  },
  close: {
    transform: "scaleX(-1)",
  },
  clamped: {
    WebkitBoxOrient: "vertical",
    display: "-webkit-box",
    WebkitLineClamp: 5,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "normal",
  },
  section1: {
    // border: '1px solid #f2f1f1',
    background: "#f7f7f7",

    marginBottom: 20,
  },
  section2: {
    // border: '1px solid #f2f1f1',
    background: "#ffffff",

    marginBottom: 20,
  },
}));

function FeaturedItems(props) {
  const classes = useStyles();
  const { item } = props;
  const [expanded, setExpanded] = React.useState(false);
  const { event } = props;
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const { data: lots, status: lotsStatus, error: lotsError } = useActiveLots();


  // Need to know what auctions are currently runnings
  const {
    data: auctions,
    status: auctionsStatus,
    error,
  } = useAuctionsByFeatured();

const [filteredAuctions, setFilteredAuctions] = useState([]);

useEffect(() =>{
    const a = auctions.filter((auction) => {
      return auction.featured === true && auction.published === true;
    }).map((liveAuction)=>{
return liveAuction.id;
});

    setFilteredAuctions(a);
}, [auctions]);


  const [processedItems, setProcessedItems] = useState();

  const [featuredItems, setFeaturedItems] = useState();

  const auth = useAuth();
  const isDeveloper = auth?.user?.developer?.enabled;

  const [count, setCount] = useState();

useEffect(() =>{

console.log("FeaturedItems auctions", filteredAuctions);

},[filteredAuctions]);

  useEffect(() => {
    if (lots == null) {
      return;
    }

    const l = lots
      .filter((lot) => {
        if (lot.lot === item.id) {
          if (!props.showItem) {
            return props.showItem;
          }
        }

if (!filteredAuctions.includes(lot.auction)) {
  return false;
}

        return true;
      })
      .map((lot) => {
        return { id: lot.id, lot: lot };
      });

    console.log("FeaturedItems lots", l);
    setFeaturedItems(l);
  }, [lots]);

  function handleCardCount(c) {
    setCount(c);
  }



  return (
    <Box my={3}>
      {/*
      {item && event && (
        <ItemDetailsSection bgColor="light" item={item} event={event} />
      )}
*/}
      {featuredItems && featuredItems.length > 0 && (
        <>
          {/* CARD COUNT {count} */}
          <Container>
            <Typography variant="h5" gutterBottom>
              Similar Items for Sale
            </Typography>
          </Container>

        

          {debugFlag && (
            <>
              {" "}
              FEATURED ITEMS COUNT {featuredItems.length}
              <br />{" "}
            </>
          )}
          <HydratedItems filterType={"can-buy"} items={featuredItems}>
            <FilteredItems filterType={"can-buy"}>
              {/*  <PaginatedItems itemsPerPage={10} filterType={'all'} /> */}
              <ScoredItems filterType={"can-buy"} item={item}>
                <SortedItems sortType={"score"}>
                <CutoffItems minimumScore={100} minimumItems={4} maximumItems={10} >

                  <ItemsGrid
                    searchQuery={""}
                    filterType={"can-buy"}
                    sortType={"score"}
                    event={{ id: null }}
                    onCardCount={(c) => handleCardCount(c)}
                    maxItems={20}
                  />
</CutoffItems>
                </SortedItems>
              </ScoredItems>
            </FilteredItems>
          </HydratedItems>
        </>
      )}
    </Box>
  );
}

export default FeaturedItems;
