import React, { useEffect, useState } from 'react';

function UnsupportedBrowserMessage() {
  const [isSafariUnsupported, setIsSafariUnsupported] = useState(false);

  useEffect(() => {
    const ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf('safari/') !== -1) {
      const safariVersionMatch = ua.match(/version\/(\d+)/);
      if (safariVersionMatch && safariVersionMatch[1]) {
        const safariVersion = parseInt(safariVersionMatch[1], 10);
        if (safariVersion < 11) {
          setIsSafariUnsupported(true);
        }
      }
    }
  }, []);

  if (isSafariUnsupported) {
    return (
      <div>
        <p>Sorry, your browser is not supported. Please upgrade to a newer version.</p>
      </div>
    );
  } else {
    return null;
  }
}

export default UnsupportedBrowserMessage;