import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { emphasize } from "@material-ui/core/styles/colorManipulator";
import { useFirebaseStorageImageURL } from "./../util/db";
import { Skeleton } from "@material-ui/lab";

const style = {
  position: "relative",
  display: "flex",
};



const useStyles = makeStyles((theme) => ({
  gridItem: {
    // Add border that contrasts lightly with background color.
    // We use boxShadow so that it's hidden around outer edge
    // due to container <Card> having overflow: hidden
    boxShadow: `1px 1px 0 0 ${emphasize(theme.palette.background.paper, 0.08)}`,
    textAlign: "center",
  },
  imageContainer: {
    margin: "0 auto",
    maxWidth: "200px",
    marginBottom: "30px",
  

    "& img": {
      width: "100%",    
    },
  },

  deleteButtonWrapper: {
    position: "absolute",
    top: 0,
    right: 0,
  },
  button: {
    padding:'5px',
  }

}));



function FirebaseStorageImage({ allowDelete, image, onDelete }) {
  const nullImageUrl = 'https://via.placeholder.com/200x200';
  const classes = useStyles();

  let location = image.location === undefined ? "" : image.location;
/*
  let path = location.split("://")[1];

  let parts = path === undefined ? ["", "", ""] : path.split("/");
  let imageBucket = parts[2];
  let imageFile = parts[3];

  let thumbPath = "images/items/" + imageBucket + "/" + imageFile;

  const { data: imageUrl, status, error } = useDownloadURL(thumbPath);
  */
  const { data: imageUrl, status, error } = useFirebaseStorageImageURL(location)

  if (status != "success") {
    return null;
  }

  if (error !== null) {
    return (
      <>
     <img src="/static/media/abstract1.a589349de9b9d298dcd5.jpg" />
      </>
    );
  }

  function handleDeleteItemImage(location) {
    onDelete(location);
  }

  return (
    <>
      <div style={{ ...style }} >
    
 
      {status === "loading" ? (
        <Skeleton variant="rect" width={200} height={200} />
      ) : (
    
        <img src={imageUrl ? imageUrl : nullImageUrl} width="100%" />
   )}

        {/* {allowDelete ? (
             <div className={classes.deleteButtonWrapper}>
            <IconButton
              className={classes.button}
              style={{ background: "#ffffff",}}
              aria-label="delete"
              onClick={() => handleDeleteItemImage(location)}
            >
              <DeleteIcon />
            </IconButton>
          </div>
        ) : null} */}
      </div>
    </>
  );
}

export default FirebaseStorageImage;
