import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
//import ItemSave from "./../components/ItemSave";
import {
  useEvent,
  useAuctionsByFeatured,
} from "./../util/db.js";
import { useAuth } from "../util/auth";


import ItemDetailsSection from "./ItemDetailsSection";


import FeaturedItems from "./FeaturedItems";
import NewLadyTown from "./NewLadyTown.js";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: "0px",
  },

  gridItem: {
    // Add border that contrasts lightly with background color.
    // We use boxShadow so that it's hidden around outer edge
    // due to container <Card> having overflow: hidden
    // boxShadow: `1px 1px 0 0 ${emphasize(theme.palette.background.paper, 0.08)}`,
    textAlign: "left",
  },
  imageContainer: {
    margin: "0 auto",
    maxWidth: "200px",
    marginBottom: "30px",
    "& img": {
      width: "100%",
    },
  },
  // title: {
  //   fontWeight: 700,
  //   fontFamily: '"Cormorant Garamond", "serif"',

  //   textTransform: "uppercase",
  // },
  subtitle: {
    fontSize: "1rem",
  },
  open: {
    transform: "scaleX(1)",
  },
  close: {
    transform: "scaleX(-1)",
  },
  clamped: {
    WebkitBoxOrient: "vertical",
    display: "-webkit-box",
    WebkitLineClamp: 5,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "normal",
  },
  section1: {
    // border: '1px solid #f2f1f1',
    background: "#f7f7f7",

    marginBottom: 20,
  },
  section2: {
    // border: '1px solid #f2f1f1',
    background: "#ffffff",

    marginBottom: 20,
  },
}));

function ItemPageBottomSection(props) {
  const classes = useStyles();
  const { item } = props;
  const [expanded, setExpanded] = React.useState(false);
  const { event } = props;
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const {
    data: _event,
    status: eventStatus,
    error: eventError,
  } = useEvent(item.event);

  /*
  const {
    data: items,
    status: itemsStatus,
    error: itemsError,
  } = useItemsByOwner(item.owner);
*/

  const {
    data: auctions,
    status: auctionsStatus,
    error,
  } = useAuctionsByFeatured();
  const [filteredAuctions, setFilteredAuctions] = useState([]);

  const [processedItems, setProcessedItems] = useState();

  const auth = useAuth();
  const isDeveloper = auth?.user?.developer?.enabled;

  const [count, setCount] = useState();

  function handleCardCount(c) {
    setCount(c);
  }

  /*
  useEffect(() => {
    if (!items) {
      return;
    }
    items.filter((item) => {
      if (item.knockedDownAt === false) {
        return false;
      }
      return true;
    });

    setProcessedItems(items);
  }, [items]);
*/

  useEffect(() => {
    console.log("ItemPageBottomSection auctions start");
    if (!auctions) {
      return;
    }
    console.log("ItemPageBottomSection auctions", auctions);
    console.log("AuctionsHome test");

    const a = auctions.filter((auction) => {
      //        console.log("AuctionsHome auction", auction.test, isDeveloper)
      //        if (auction.test === false && !isDeveloper) {return false;}
      return auction.featured === true && auction.published === true;
    });
    setFilteredAuctions(a);
  }, [auctions]);
  /*
  useEffect(() => {
    console.log("ItemPageBottomSection filteredAuctions", filteredAuctions);
  }, [filteredAuctions]);

useEffect(() =>{

if (processedItems == null) {return null;}

console.log("ItemPageBottomSection processedItems", processedItems);

}, [processedItems]);
*/

  if (eventStatus !== "success") {
    return null;
  }

  // If the event doesn't exist (or no longer exists)
  // the hook will return null.
  // So don't return an item - because there is no corresponding event.
  if (_event === null) {
    return null;
  }

  return (
    <Box my={3}>
      {item && event && (
        <ItemDetailsSection bgColor="light" item={item} event={event} />
      )}

    <NewLadyTown />

      <FeaturedItems showItem={false} item={item} />
    </Box>
  );
}

export default ItemPageBottomSection;
