import React, { useState, useEffect, useRef } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { Container } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import ItemCards from "./ItemCards";


const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "no-wrap",
    justifyContent: "space-around",
    overflow: "hidden",
  },
  horizontal: {
    display: "flex",
    flexWrap: "no-wrap",
    justifyContent: "space-around",
    overflow: "auto",
    padding: "0.5rem",

  },
  imageList: {
    flexWrap: "nowrap",
    transform: "translateZ(0)",
  },
  ImageListItem: {
    height: "100%",
  },
  listItem: {
    height: "100%",
  },
  demo: {
    alignItems: "flex-end",
  },
  paperItems: {
    minHeight: "300px",
  },
  featured: {
    backgroundColor:
      theme.palette.type === "dark" ? theme.palette.action.selected : "#fdf8c2",
  },
  starFeatured: {
    color: theme.palette.warning.main,
  },
}));

function ItemsGrid(props) {
  const { filter, items, event, searchQuery, maxItems, flavour, filterType } = props;
  const enableScroll = true;
  const [count, setCount] = useState();
  const classes = useStyles();


  useEffect(() => {
    if (props.onCountChange) {
      props.onCountChange(count);
    }
  }, [count]);

  const loadingContext = false;

  function isItemVisible(item) {
    return item.name !== undefined && item.accepted === true;
  }

  useEffect(() => {
    if (items == null) {return;}
    console.log("ItemsGrid items", items)
    const c = calculateVisibleItems(items);
    setCount(c);
  }, [items]);

  const calculateVisibleItems = (items) => {
    var count = 0;
    items?.map((item) => {
      if (isItemVisible(item)) {
        count += 1;
      }
    });
    return count;
  };

  const breakpointColumnsObj = {
    default: 4,
    1400: 4,
    960: 2,
    // 500: 2
  };

  const ref = useRef(null);
  const scroll = (scrollOffset) => {
    ref.current.scrollLeft += scrollOffset;
  };
  const gridType = "non_masonry";
  //  if (items === undefined) {
  //    return null;
  //  }



  function handleCardCount(c) {
    console.log("ItemsGrid handleCardCount", c);
    if (props.onCardCount) {
      props.onCardCount(c);
    }
  }



  return (
    <>
   <Grid container className={enableScroll ? classes.horizontal : classes.root}>
      <Grid item xs={12}>
        <Container maxWidth="lg" style={{ paddingBottom: "1.4rem" }}>
          <>
            <div key={event?.id}>
              <Skeleton
                key={"itemcards_skeleton_" + event?.id}
                variant="rect"
                height={800}
                style={{
                  display: loadingContext ? "block" : "none",
                  backgroundColor: "gold",
                  transitionDelay: "0ms",
                }}
              />

              <div
                style={{
                  display: loadingContext ? "none" : "block",
                  transitionDelay: "0ms",
                }}
              >
                {items && (
                  <>
                    <Grid
                      container
                      className={classes.demo}
                      direction="row"
                      spacing={2}
                      alignItems="stretch"
                      ref={enableScroll ? ref : null}
                    >
                   
                      <ItemCards
                        flavour={flavour}
                        key={"itemcards_" + event?.id}
                        items={items}
                        gridType="non_masonry"
                        searchQuery={searchQuery}
                        onCardCount={(c) => handleCardCount(c)}
                        maxItems={maxItems}
                      />
                    </Grid>
                  </>
                )}
              </div>
            </div>
          </>
        </Container>
      </Grid>
    </Grid>
    </>
  );
}

export default ItemsGrid;


