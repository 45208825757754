import React, { useState, useEffect } from "react";
import { useAuth } from "./../util/auth.js";
import { createMessage } from "./../util/db.js";
import { updateUser } from "./../util/db.js";

//import { console } from "./../util/log.js";

import { makeStyles } from "@material-ui/core/styles";

import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

import {
  Button,
  Divider,
  TextField,
  Box,
  Slide,
  Hidden
} from "@material-ui/core";
import { getCustomerByCustomer } from "../util/stripe.js";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import {} from "@material-ui/core";
import { useForm } from "react-hook-form";
import CircularProgress from "@material-ui/core/CircularProgress";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import ItemCard from "./ItemCard.js";
import { getFunctions, httpsCallable } from "firebase/functions";
import { humanPrice } from "../util/price.js";
import { isPostal } from "../util/delivery.js";
import { useCart } from "../util/cart.js";
import Section from "./Section.js";
import { Skeleton } from "@material-ui/lab";
const functions = getFunctions();

const errorMessages = {
  processing:
    "Processing Error: No charge has been made to your card. Please contact customer service for help.",
};

const processStripeClientSecret = httpsCallable(
  functions,
  "processStripeClientSecret"
);

const processStripeCustomerUpdate = httpsCallable(
  functions,
  "processStripeCustomerUpdate"
);

const startButtonDisabled = true;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const processStripePaymentItems = httpsCallable(
  functions,
  "processPaymentItems"
);

const processStripeEstimateItems = httpsCallable(
  functions,
  "processEstimateItems"
);

const processStripeQuoteItems = httpsCallable(functions, "processQuoteItems");

const getCustomerPaymentMethods = httpsCallable(
  functions,
  "getCustomerPaymentMethods"
);

const updatePaymentMethod = httpsCallable(functions, "updatePaymentMethod");

const useStyles = makeStyles((theme) => ({
  textField: {
    "&&": {
      marginBottom: "10px",
    },
    "& input:disabled": {
      // boxShadow: '#2ee519',
      "-webkit-box-shadow": "0 0 0 1000px #e8e8e8 inset",
    },
  },

  checkedout: {
    backgroundColor: "#000000",
  },

  paperItems: {
    minHeight: "300px",
  },
  item: {
    justifyContent: "space-between",
    display: "flex",
  },
  featured: {
    backgroundColor:
      theme.palette.type === "dark" ? theme.palette.action.selected : "#fdf8c2",
  },
  starFeatured: {
    color: theme.palette.warning.main,
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    marginTop: "2em",
    // padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  dialogTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    textAlign: "right",
  },
  warning: {
    color: theme.palette.error.main,
  },
}));


 function StripeAddPaymentCard (props) {
    const stripe = useStripe();
    const elements = useElements();
  
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
    const { cards, paymentProcessor, cartId } = props;
  
    //const { stripe, elements } = paymentProcessor;
  
    const [taxStatus, setTaxStatus] = useState();
  
    const { disabled } = props;
    const { items, totalPrice, cartCount } = props;
    const classes = useStyles();
  
    const [customerId, setCustomerId] = useState();
  
    const [existingPaymentMethod, setExistingPaymentMethod] = useState();
  
    //
  
    const [addCardDisabled, setAddCardDisabled] = useState();
    const [clientSecret, setClientSecret] = useState();
  
    const auth = useAuth();

  
    useEffect(() => {
      console.log(
        "StripeElementsChargeCard getCustomerPaymentMethods customerId",
        customerId
      );
      if (customerId === undefined) {
        return;
      }
      getPaymentMethod(customerId);
    }, [customerId]);
  
    useEffect(() => {
      console.log(
        "StripeElementsChargeCard existingPaymentMethod",
        existingPaymentMethod
      );
    }, [existingPaymentMethod]);
  
    function getPaymentMethod() {
      console.log("merp");
      //    getCustomerPaymentMethods({ customer: customerId })
      getCustomerPaymentMethods()
        .then((result) => {
          console.log(
            "StripeElementsChargeCard getCustomerPaymentMethods result",
            result
          );
          if (result.data.data.length > 0) {
            setExistingPaymentMethod(result.data.data[0]);
          }
        })
        .catch((error) => {
          console.error(
            "StripeElementsChargeCard getCustomerPaymentMethods error",
            error
          );
        });
    }
  
    useEffect(() => {
      processStripeClientSecret()
        .then(function (clientSecretObject) {
          //console.log("clientSecret Object", clientSecretObject);
          const tempClientSecret = clientSecretObject.data.client_secret;
          console.log("tempClientSecret", tempClientSecret);
          setClientSecret(tempClientSecret);
          // Stripe is always more authorative about the cus_ number
          // than Lady Bidwell.
          const customer = clientSecretObject.data.customer;
  
          setCustomerId(customer);
          console.log("banana process clientScret");
          getCustomerByCustomer(customer)
            .then((customerData) => {
              //console.log("banana", customerData);
              setTaxStatus(customerData.data.tax_exempt);
            })
            .catch((error) => {
              console.error(
                "StripeElementsChargeCard getCustomerByCustomer",
                error
              );
            });
          //console.log("StripeElementsChargeCard customer", customer);
        })
        .catch((error) => {
          //console.log("banana", error);
          console.error(
            "StripeElementsChargeCard processClientSecret error",
            error
          );
        });
    }, []);
  
    useEffect(() => {
      var initialState = true;
      if (props.disabled !== undefined) {
        initialState = props.disabled;
      }
      if (cards === undefined) {
        return;
      }
      setAddCardDisabled(initialState);
    }, [cards, props.disabled]);



    const {
      register,
      formState: { errors },
      getValues,
      setError,
      clearErrors,
      watch,
    } = useForm({ mode: "onChange" });

    register("stripe");

    const { clearCart } = useCart();

    const [totalDetails, setTotalDetails] = useState({ status: "loading" });
    const [updateTax, setUpdateTax] = useState(false);
    const currentZipCode = watch("zipcode");
    const currentName = watch("name");
    const currentTelephone = watch("telephone");

    const [isProcessing, setIsProcessing] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(startButtonDisabled);
    const [stripeMessage, setStripeMessage] = useState();

    const isTaxCalculating =
      totalDetails && totalDetails.amount_tax_formatted === "calculating";

    const [isReady, setIsReady] = useState(false);

    //const [formDisabled, setFormDisabled] = useState(false);
    const formDisabled = false;
    const [isEdited, setIsEdited] = useState({});
    const [open, setOpen] = React.useState();
    const [tax, setTax] = React.useState();

    const [isCheckedOut, setIsCheckedOut] = useState(false);

    const [inputReady, setInputReady] = useState(false);

    const cardElement = elements.getElement(CardElement);

    const SpinnerAdornment = (props) => <CircularProgress size={20} />;

    useEffect(() => {
      if (items === undefined) {
        return;
      }
      // Is it a "proper" zip code.
      //     if (!isPostal(currentZipCode)) {
      //       return;
      //     }
      //     updateQuote(items);

      if (isPostal(currentZipCode)) {
        updateQuote(items);
      }
    }, [items, currentZipCode]);

    function updateQuote(items) {
      console.log("StripeElementsChargeCard updateQuote");
      // Consider adding code here to reject unnecessary quote updates
      if (!totalDetails) {
        setTotalDetails({
          ...totalDetails,
          amount_tax_formatted: "calculating",
          amount_subtotal: null,
          // amount_subtotal_formatted: "computing",
          amount_total: null,
          //amount_total_formatted: "computing",
          status: "loading",
        });
      } else {
        if (updateTax) {
          setTotalDetails({
            ...totalDetails,
            amount_tax_formatted: "calculating",
            status: "loading",
          });
          setUpdateTax(false);
        } else {
          setTotalDetails({ ...totalDetails, status: "loading" });
        }
      }

      processStripeQuoteItems({ items: items })
        .then((result) => {
          console.log(
            "StripeElementsChargeCard processStripeQuoteItems result",
            result
          );
          const formattedTotalPrice = humanPrice({
            amount: result.data.data.amount_total,
            currency: "USD",
            showCents: true,
          });

          const formattedSubTotalPrice = humanPrice({
            amount: result.data.data.amount_subtotal,
            currency: "USD",
            showCents: true,
          });

          const formattedAmountTax = humanPrice({
            amount: result.data.data.total_details.amount_tax,
            currency: "USD",
            showCents: true,
          });

          setTotalDetails({
            ...result.data.data.total_details,
            amount_tax_formatted: formattedAmountTax,
            amount_subtotal: result.data.data.amount_subtotal,
            amount_subtotal_formatted: formattedSubTotalPrice,
            amount_total: result.data.data.amount_total,
            amount_total_formatted: formattedTotalPrice,
            status: "success",
          });
        })
        .catch((error) => {
          console.error(
            "StripeElementsChargeCard CheckoutDisplay error",
            error
          );
          // amount_shipping
          // amount_tax
          // amount_discount
        });
    }

    useEffect(() => {
      //const price = getCartPrice(items);
      //console.log("StripeElementsChargeCard totalPrice", totalPrice);

      const formattedTotalPrice = humanPrice({
        amount: totalPrice.amount,
        currency: totalPrice.currency,
        showCents: true,
      });

      const formattedSubTotalPrice = humanPrice({
        amount: totalPrice.amount,
        currency: totalPrice.currency,
        showCents: true,
      });

      setTotalDetails({
        //          ...result.data.data.total_details,
        //          amount_tax_formatted: formattedAmountTax,
        amount_subtotal: totalPrice.amount,
        amount_subtotal_formatted: formattedSubTotalPrice,
        amount_total: totalPrice.amount,
        amount_total_formatted: formattedTotalPrice,
        status: "success",
      });
    }, []);

    const TaxPanel = (props) => {
      //const currentZipCode = watch("zipcode");
      return (
        <>
          TAX PANEL{props.zipCode}
          {tax}
        </>
      );
    };

    function enableCheckout() {
      setIsProcessing(false);
      setButtonDisabled(false);
    }

    const handleChange = (changeValue, event) => {
      const isValidated = validateInput(changeValue, event);
      if (isValidated === false) {
        setStripeMessage();
      }
    };

    function validateInput(changeValue, event) {
      console.log("StripeElementsChargeCard validateInput", changeValue);
      var isValid = true;
      //    const handleChange = (changeValue, event) => {
      //const changeValue = event.target.value;
      console.log("changeValue", changeValue);
      setUpdateTax(false);
      if (changeValue === "name") {
        setIsEdited({ ...isEdited, name: true });
        const name = getValues("name");

        if (name === null) {
          isValid = false;
          setError("name", {
            type: "manual",
            message: "Card holder name required",
          });
          //nameComplete = false;
        } else if (name.length < 1) {
          isValid = false;
          setError("name", {
            type: "manual",
            message: "Card holder name required",
          });
          //nameComplete = false;
        } else {
          //setCardholderName(name);
          clearErrors("name");
        }
      }

      if (changeValue === "zipcode") {
        setIsEdited({ ...isEdited, zipCode: true });
        const zipCode = getValues("zipcode");
        setUpdateTax(true);

        /*
        if (!isPostal(zipCode)) {
          setError("zipcode", {
            type: "manual",
            message: "Zip/Postal code required",
          });
        } else {
          clearErrors("zipcode");
          //updateStripeCustomer();
        }
        */

        if (zipCode === null || zipCode.length === 0) {
          isValid = false;
          setError("zipcode", {
            type: "manual",
            message: "Zip/Postal code required",
          });
        } else {
          clearErrors("zipcode");
          //updateStripeCustomer();
        }
      }

      if (changeValue === "telephone") {
        setIsEdited({ ...isEdited, telephone: true });
        const telephone = getValues("telephone");
        //        setUpdateTax(true);

        if (telephone === null || telephone.length === 0) {
          isValid = false;
          setError("telephone", {
            type: "manual",
            message: "Telephone number required to the seller can contact you",
          });
        } else {
          clearErrors("telephone");
        }
      }

      if (changeValue === "stripe") {
        setIsEdited({ ...isEdited, stripe: true });

        if (event.error && event.error.message) {
          isValid = false;
          setStripeMessage(event.error.message);
          setError("stripe", { type: "manual", message: "Card incomplete" });
        } else if (event.complete === false) {
          isValid = false;
          setStripeMessage("Card details not complete");
          setError("stripe", {
            type: "manual",
            message: "Card details not complete",
          });
        } else if (event.error === undefined) {
          clearErrors("stripe");
          setStripeMessage();
        }
      }

      if (errors.stripe) {
        isValid = false;
        //if (errors.stripe || errors.name || errors.zipcode || errors.telephone) {
        setButtonDisabled(true);
      } else {
        if (
          isEdited.stripe === true &&
          isEdited.name === true &&
          isEdited.telephone === true &&
          isEdited.zipCode === true
        ) {
          setButtonDisabled(false);
          //setStripeMessage();
        }
      }

      return isValid;
    }

    useEffect(() => {
      console.log("isEdited", isEdited);

      if (
        isEdited.stripe &&
        isEdited.zipCode &&
        isEdited.name &&
        isEdited.telephone
      ) {
        setButtonDisabled(false);
        return;
      }
      setButtonDisabled(true);
    }, [isEdited]);

    useEffect(() => {
      if (!customerId) {
        return;
      }

      updateStripeCustomer("zipcode");
    }, [currentZipCode]);

    useEffect(() => {
      if (!customerId) {
        return;
      }

      updateStripeCustomer("telephone");
    }, [currentTelephone]);

    useEffect(() => {
      updateStripeCustomer("name");
      //updatePaymentMethod();
    }, [currentName]);

    const updateStripeCustomer = (parameter) => {
      //console.log("StripeElementsChargeCard currentZipCode currentName", currentZipCode, currentName)
      if (!currentZipCode) {
        return;
      }

      if (!isPostal(currentZipCode)) {
        return;
      }
      //     if (!currentName) {return;}
      console.log(
        "StripeElementsChargeCard updateStripeCustomer customerId currentZipCode currentName",
        customerId,
        currentZipCode,
        currentName,
        currentTelephone
      );

      // Requires minimum country and postal code.

      //const name = getValues("name");
      //console.log("name", name)
      processStripeCustomerUpdate({
        customer: customerId,
        datagram: {
          name: currentName,
          address: {
            country: "US",
            postal_code: currentZipCode,
          },
          phone: currentTelephone,
        },
      })
        .then((result) => {
          if (parameter === "zipcode") {
            updateQuote(items);
          }
          console.log(
            "StripeElementsChargeCard updateStripeCustomer result",
            result
          );
        })
        .catch((error) => {
          console.error(
            "StripeElementsChargeCard processStripeCustomerUpdate updateStripeCustomer error",
            error
          );
        });
    };
// async(event)
    const handleSubmit = async (event) => {
      event.preventDefault();
      if (!stripe || !elements) {
        return;
      }

      if (elements == null) {
        return;
      }

      setIsProcessing(true);
      setButtonDisabled(true);

      //setCanEdit(true);
      //setProcessCheckout(true);
      return createPaymentMethod()
        .then((result) => {
          //console.log("StripeElementsChargeCard createPaymentMethod promise resolve handleSubmit", result)
          console.log("StripeElementsChargeCard result", result);

          if (result.error && result.error !== null) {
            console.log("StripeElementsChargeCard result.error", result.error);
            //setCanEdit(true);
            setStripeMessage(result.error);
            setError("stripe", {
              type: "manual",
              message: "Card incomplete (1008)",
            });
            enableCheckout();
            //setIsProcessing(false);
            //setButtonDisabled(false);
            return;
          }
          const paymentMethodId = result.data.paymentMethod.id;

          console.log("paymentMethodId", paymentMethodId);
          return chargePaymentMethod(paymentMethodId)
            .then((chargeResult) => {
              console.log(
                "StripeElementsChargeCard chargePaymentMethod chargeResult",
                chargeResult
              );

              if (chargeResult && !chargeResult.data) {
                const errorText =
                  chargeResult.error !== null
                    ? chargeResult.error.message
                    : "Unknown error.";
                setStripeMessage(errorText);
                setError("stripe", {
                  type: "manual",
                  message: errorText,
                });

                if (chargeResult.error.nextStep !== "confirm_order") {
                  enableCheckout();
                  return;
                }
              }

              if (chargeResult === undefined) {
                setStripeMessage(errorMessages.processing);
                // setError("stripe", {
                //   type: "manual",
                //   message: "Undefined chrgeresult(1009)",
                // });
                enableCheckout();
                return;
              }

              //setIsCheckedOut(true);
              //if (props.onCheckout) {props.onCheckout();}

              console.log(
                "StripeElementsChargeCard telephone",
                currentTelephone
              );
              updateUser(auth.user.id, { telephone: currentTelephone });

              window.onbeforeunload = null;
              if (chargeResult.error === null) {
                clearCart();
                window.location.href =
                  "/order-summary/order_" + chargeResult.data.id;
              } else {
                // Check whether the clearCart is needed here too.
                clearCart();
                window.location.href =
                  "/order-summary/order_" + chargeResult.data.id;
              }

              if (props.onCheckout) {
                props.onCheckout();
              }

              return;
            })
            .catch((error) => {
              //flerpd
              setStripeMessage(
                "There was a problem processing your payment. Please contact us.(ed30)"
              );

              const subject = "Error with payment flerpD";

              const datagram = {
                subject: subject,
                text: "Error with payment flerpD",
                //html: html,
                // item: i,
                // from: "system",
                from: auth.user.id,
                //to: auth.user.id,
                to: "auctioneer",
                //template: "accepted",
                transport: "email",
              };

              createMessage(datagram, auth.user.id);
              /*
              createUserMessage({
                from: "system",
                to: uid,
                item: item.id,
                subject: "Could not buy test item.",
              });
*/

              console.log(
                "StripeElementsChargeCard chargePaymentMethod error",
                error
              );

              setError("stripe", {
                type: "manual",
                message:
                  "There was a problem processing your payment. Please contact us. Do not try again.",
              });

              setIsProcessing(false);
              setButtonDisabled(false);
              Promise.reject("bcda");
              //setCanEdit(true);
            });

          //console.log("StripeElementsChargeCard createpaymentMehtod promise resolve after chargePaymentMethod")
          //setProcessCheckout(false);
        })
        .catch((error) => {
          setStripeMessage("Card Error. Your card has not been charged. error:1002");
          console.error("StripeElementsChargeCard handleSubmit error", error);
          setError("stripe", {
            type: "manual",
            message: "Card incomplete (1002)",
          });
          setIsProcessing(false);
          setButtonDisabled(false);
          //setCanEdit(true);
        });
    };

    const chargePaymentMethod = (paymentMethodId) => {
      console.log("chargePaymentMethod state errors", errors);
      return processStripePaymentItems({
        items: items,
        paymentMethodId: paymentMethodId,
      })
        .then((result) => {
          // https://stackoverflow.com/questions/63702600/how-to-remove-the-alert-of-leave-site-in-javascript

          const response = result.data;
          console.log("StripeElementsChargeCard result", result);
          return { data: response.data, error: response.error };
        })
        .catch((error) => {
          console.error(
            "StripeElementsChargeCard chargePaymentMethod error",
            error
          );

          // console.error(
          //   "StripeElementsChargeCard chargePaymentMethod error",
          //   error
          // );

          setStripeMessage("Unable to charge card (ref:1007).");
          setError("stripe", {
            type: "manual",
            message: "Card Processing Error (1007)",
          });
          enableCheckout();
          //   setIsProcessing(false);
          //    setButtonDisabled(false);
          Promise.reject("Card Not OK.");
        });
    };
// async ()


    const createPaymentMethod = async () => {
      if (!stripe || !elements) {
        return { error: "No stripe." };
      }


      

      //  //  const cardElement = elements.getElement(CardElement);

      //const data = {name:name};// Get a reference to a mounted CardElement. Elements knows how
      // to find your CardElement because there can only ever be one of
      // each type of element.

      //const cardElement = elements.create('card');
      // Use your card Element with other Stripe.js APIs

      const zipCode = getValues("zipcode");
      const name = getValues("name");
      const telephone = getValues("telephone");

      const isDefaultCard = getValues("isDefaultCard");

      if (!name) {
        console.error(
          "StripeElementsChargeCard createPayementMethod no name provided"
        );
        return { error: "No name." };
        //return null;
      }
      if (!zipCode) {
        console.error(
          "StripeElementsChargeCard createPayementMethod no zipCode provided"
        );
        return { error: "No zip." };
        //return null;
      }

      if (!telephone) {
        console.error(
          "StripeElementsChargeCard createPayementMethod no telephone provided"
        );
        return { error: "No telephone number." };
        //return null;
      }

      console.log(
        "StripeElementsChargeCard existingPaymentMethod",
        existingPaymentMethod
      );

      if (existingPaymentMethod !== undefined) {
        const billingDetails = {
          name: name,
          email: auth.user && auth.user.email ? auth.user.email : null,
          address: { postal_code: zipCode },
        };

        // Code here to pull existing paymentMethod so it can be updated.

        updatePaymentMethod({
          paymentMethodId: existingPaymentMethod.id,
          billingDetails: billingDetails,
        })
          .then((result) => {
            console.log(
              "StripeElementsChargeCard updatePaymentMethod result",
              result,
              result.data.id
            );

            return {
              data: { paymentMethod: result.data.id },
              error: null,
              status: "success",
            };
          })
          .catch((error) => {
            console.log(
              "StripeElementsChargeCard updatePaymentMethod error",
              error
            );
          });
      }

      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        //card: elements.getElement(CardElement),
        card: cardElement,
        //        card: null,
        billing_details: {
          name: name,
          email: auth.user && auth.user.email ? auth.user.email : null,
          address: { postal_code: zipCode },
        },
      });

      // Find a test that triggers this.
      if (error) {
        if (error.type === "validation_error") {
          //setActiveButton(true);
          return { error: "Validation error." };
        }

        props.onStatus({
          type: "error",
          message: error.message,
        });
        //setStripeMessage(error.message);
        return { error: "Some error." };
      }

      //return {error:null}
      // Deltete this????
      // I think we do need this ... because this is the only
      // way we an report a validation probelm to the user
      return stripe
        .confirmCardSetup(clientSecret, {
          payment_method: paymentMethod.id,
        })
        .then(function (result) {
          //return;
          console.log("confirmCardSetup", result);

          if (result && result.error) {
            props.onStatus({
              type: "error",
              message: result.error.message,
            });
            setStripeMessage(result.error.message);

            return { data: null, error: result.error.message };
          }

          //  stripe.paymentMethods.attach(
          //    paymentMethod.id,
          //    {customer: 'cus_JQjlJrXDsgNLlN'}
          //  );

          console.log("paymentMethod", paymentMethod);
          return {
            data: { paymentMethod: paymentMethod },
            error: null,
            status: "success",
          };

        })

        .catch((error) => {
          //return;
          props.onStatus({
            type: "info",
            message: "Could not set-up that card.",
          });
          console.error("Card Setup Confirmation error", error);
          return { error: "Card Setup Confirmation error." };
        });
    };

    const handleFocus = (e) => {
      console.log("StripeElementsChargeCard handleFocus", e);
    };



    useEffect(() => {
      if (!auth.user) {
        return;
      }
      if (!items) {
        return;
      }
      if (!customerId) {
        return;
      }
      if (!taxStatus) {
        return;
      }
      if (elements === null) {
        return;
      }

      setIsReady(true);
    }, [auth, items, customerId, taxStatus, elements]);

/*
    useEffect(() =>{
      if (createPaymentMethod && createPaymentMethod.isReady) {
        return;
        // createPaymentMethod is ready
      }
      return () => {createPaymentMethod?.unsubscribe();}
      
      }, [createPaymentMethod])
*/


    // if (!isReady) {
    //   return <PageLoader />;
    // }

    if (!isReady) {
      return (

<>
{/* <Container>
  <Grid container >
    <Grid item xs={12}><Skeleton variant="rect" height='250' /></Grid>
    <Grid item xs={8}><Skeleton variant="rect" height='250' /></Grid>
    <Grid item xs={8}><Skeleton variant="rect" height='250' /></Grid>
    <Grid item xs={6}><Skeleton variant="rect" height='250' /></Grid>
    <Grid item xs={4}><Skeleton variant="rect" height='250' /></Grid>
</Grid>
</Container> */}

<>  <Grid container spacing={2}>
    <Grid item xs={12}>
      <Skeleton variant="rect" width={'100%'} height={200} />
      </Grid>
      <Grid item xs={12}>
      <Skeleton variant="rect" width={'100%'} height={300} />
      </Grid>
      <Grid item xs={12}>
      <Skeleton variant="rect" width={'100%'} height={300} />
   </Grid>
  </Grid></>


</>
)
    }


    return (
      <>
        {isProcessing || isCheckedOut ? (
          <Box
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "#ffffff",
              opacity: 0.9,
              zIndex: 9999,
              height: "100%",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box display="block" textAlign="center">
              <CircularProgress size="5em" color="secondary" />

              <Box my={3}>
                <Typography variant="h4">
                  Payment Processing. Please Do Not Reload Page.{" "}
                </Typography>
              </Box>
            </Box>
          </Box>
        ) : null}


        <Section size="large" style={{ paddingTop: "0px", minHeight: "100vh" }}>
          <form onSubmit={handleSubmit}>
            {/* BILLING DETAILS SECTION  */}
            <Grid container spacing={2}>
              <Grid item xs={12} md={8}>
                <Grid container item spacing={1}>
                  <Grid item xs>
                    <Box p={2} style={{ border: "1px solid #c4c4c4" }}>
                      <Typography gutterBottom variant="h6">
                        Billing Details
                      </Typography>

                      <TextField
                        // disabled={formDisabled}
                        className={classes.textField}
                        disabled={!stripe || !elements || isProcessing}
                        fullWidth
                        variant="outlined"
                        type="text"
                        // label="Name on Card"
                        name="zipcode"
                        placeholder="Cardholder's Zip/Postal Code"
                        defaultValue={null}
                        onChange={(e) => handleChange("zipcode", e)}
                        error={errors.zipcode ? true : false}
                        helperText={errors.zipcode && errors.zipcode.message}
                        // fullWidth={true}
                        // required={"Please enter your zipcode"}
                        inputRef={register({
                          required: "Please enter your zipcode",
                        })}
                      />

                      <TextField
                        // disabled={formDisabled}
                        className={classes.textField}
                        disabled={!stripe || !elements || isProcessing}
                        fullWidth
                        variant="outlined"
                        type="text"
                        // label="Name on Card"
                        name="telephone"
                        placeholder="Cardholder's Telephone Number"
                        defaultValue={null}
                        onChange={(e) => handleChange("telephone", e)}
                        error={errors.telephone ? true : false}
                        helperText={
                          errors.telephone && errors.telephone.message
                        }
                        // fullWidth={true}
                        // required={"Please enter your zipcode"}
                        inputRef={register({
                          required: "Please enter your telephone number",
                        })}
                      />

                      <TextField
                        className={classes.textField}
                        //disabled={formDisabled}
                        disabled={!stripe || !elements || isProcessing}
                        fullWidth
                        variant="outlined"
                        type="text"
                        // label="Name on Card"
                        name="name"
                        placeholder="Cardholder's Name"
                        defaultValue={null}
                        onChange={(e) => handleChange("name", e)}
                        error={errors.name ? true : false}
                        helperText={errors.name && errors.name.message}
                        // fullWidth={true}
                        // required={"Please enter your name"}
                        inputRef={register({
                          required: "Please enter your name",
                        })}
                      />

                      <Box
                        p={1}
                        py={2}
                        sx={{
                          backgroundColor: isProcessing ? "#e8e8e8" : "",
                          border: "1px solid #c4c4c4",
                        }}
                      >
                        <CardElement
                          id="card-element"
                          onChange={(e) => handleChange("stripe", e)}
                          onFocus={(e) => {
                            handleFocus(e);
                          }}
                          options={{
                            style: {
                              base: {
                                fontSize: "16px",
                                color: "#424770",
                                "::placeholder": {
                                  color: "#aab7c4",
                                },
                              },
                              invalid: {
                                color: "#9e2146",
                              },
                            },
                            disabled: !stripe || !elements || isProcessing,
                            hidePostalCode: true,
                            classes: { invalid: ".StripeElement--complete" },
                          }}
                        />
                      </Box>

                      <Box pt={1}>
                        <Typography
                          className={classes.warning}
                          variant="subtitle1"
                        >
                          <b>{stripeMessage}</b>
                        </Typography>
                      </Box>
                    </Box>

                    {/* ORDER REVIEW */}
                    <Box py={2}>
                      <Divider />
                    </Box>

                    <Grid item xs>
                      <Box p={2} style={{ border: "1px solid #c4c4c4" }}>
                        <Typography variant="h6" gutterBottom>
                          Order Review
                        </Typography>

                        {items &&
                          items.map((item, index) => {
                            return (
                             
                                <ItemCard
                                  key={item.id}
                                  item={item}
                                  flavour="cart"
                                  spice={["noremove"]}
                                />
                         
                            );
                          })}
                      </Box>
                    </Grid>

                    {/* PLACE ORDER */}

                    <Hidden smDown>
                      <Box py={3}>
                        <Grid item xs style={{ background: "#f5f5f5" }}>
                          <Box p={3} display="flex">
                            <Button
                              // fullWidth
                              label="Place Order"
                              size="large"
                              variant="contained"
                              color="primary"
                              disableElevation
                              type="submit"
                              disabled={
                                !stripe ||
                                buttonDisabled ||
                                formDisabled ||
                                isTaxCalculating
                              }
                              style={{ whiteSpace: "nowrap", minWidth: "auto" }}
                              endIcon={isProcessing ? <SpinnerAdornment /> : ""}
                            >
                              <Typography
                                variant="subtitle2"
                                style={{ color: "#ffffff" }}
                              >
                                Place Order
                              </Typography>

                              {/* {formDisabled ? (
                              <CircularProgress
                                m={3}
                                size={20}
                                color="secondary"
                              />
                            ) : null} */}
                            </Button>

                            <Box ml={2}>
                              <Typography variant="h6">
                                {totalDetails && (
                                  <>
             
                                  </>
                                )}
                
                                Order Total:{" "}
                                {totalDetails &&
                                  totalDetails.amount_total_formatted}
                                {/*                            Order Total: {formattedTotalPrice} */}
                              </Typography>

                              <Typography variant="body2">
                                I authorise Lady Bidwell to send instructions to
                                the financial institution.
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                      </Box>
                    </Hidden>
                  </Grid>
                </Grid>
              </Grid>

              {/* ORDER SUMMARY */}

              <Grid item xs={12} md={4}>
                <Box
                  p={2}
                  style={{
                    position: "sticky",
                    top: "1rem",
                    bottom: "1rem",

                    background: "#f5f5f5",
                  }}
                >
                  <Box textAlign="left" m={2}>
                    <Box pb={2}>
                      <Button
                        fullWidth
                        size="large"
                        variant="contained"
                        color="primary"
                        disableElevation
                        type="submit"
                        disabled={
                          !stripe ||
                          buttonDisabled ||
                          formDisabled ||
                          isTaxCalculating
                        }
                        style={{ whiteSpace: "nowrap", minWidth: "auto" }}
                        endIcon={isProcessing ? <SpinnerAdornment /> : ""}
                      >
                        <Typography
                          // variant="subtitle2"
                          style={{ color: "#ffffff" }}
                        >
                          {/* Place Orderx {isProcessing ? <SpinnerAdornment /> : ""} */}
                          Place Order
                        </Typography>
                        {/* {formDisabled ? (
                        <CircularProgress m={3} size={20} color="secondary" />
                      ) : null} */}
                      </Button>
                    </Box>

                    <Typography variant="h6">Order Summary</Typography>
                    <Box style={{ display: "flex" }}>
                      <Typography variant="subtitle1">
                        <b>
                          {" "}
                          {cartCount} {cartCount === 1 ? "item" : "items"}{" "}
                        </b>{" "}
                        in cart.
                      </Typography>
                    </Box>

                    <Box style={{ display: "flex" }}>
                      <Typography>
                        Cart Subtotal:
                        <b>
                          {" "}
                          {totalDetails &&
                            totalDetails.amount_subtotal_formatted}
                          {}{" "}
                        </b>{" "}
                      </Typography>
                    </Box>

                    <Box style={{ display: "flex" }}>
                      {taxStatus && taxStatus !== "exempt" && (
                        <Typography>
                          Estimated Tax:{" "}
                          {!(
                            totalDetails && totalDetails.amount_tax_formatted
                          ) &&
                            taxStatus &&
                            taxStatus !== "exempt" && (
                              <b>
                                {taxStatus &&
                                  taxStatus !== "exempt" &&
                                  "to be calculated"}
                              </b>
                            )}{" "}
                          {totalDetails && totalDetails.amount_tax_formatted}
                        </Typography>
                      )}

                      {taxStatus && taxStatus === "exempt" && (
                        <Typography>
                          <b>Estimated Tax: $0.00 (Tax Exempt)</b>
                        </Typography>
                      )}
                    </Box>

                    <Box py={1}>
                      <Divider />
                    </Box>

                    <Box style={{ display: "flex" }}>
                      <Typography variant="h6">
                        Order Total:{" "}
                        <b>
                          {" "}
                          {totalDetails &&
                            totalDetails.amount_total_formatted}{" "}
                        </b>{" "}
                        {/*                      Order Total:<b> {formattedTotalPrice} </b>{" "} */}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </form>
        </Section>


      </>
    );
  };

  export default StripeAddPaymentCard;