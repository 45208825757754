import React from "react";
import { useAuth } from "./../util/auth.js";
import Button from "@material-ui/core/Button";
import { updateLot } from "../util/db.js";
import { addMinute } from "../util/time.js";

export default function LotDetails(props) {
  const auth = useAuth();
  const isAuctioneer = auth.user.role && auth.user.role === "auctioneer";

  const { item ,lot, event} = props;



  //const { data:lot, status } = useLotByItem(item.id);

  var isOwner = false;
  var isWinner = false

  //if (status == 'success') {
  var isOwner = auth.user.id === (lot && lot.owner);
  var isWinner = auth.user.id === (lot && lot.bidOwner);
//}




  function handleExtendLotClose() {
    const datestring = addMinute(lot.endsAt);

    updateLot(item.id, { endsAt: datestring });
  }
//  if (status !== "success") {
 //   return null;
  //}



  return (
    <>
      {isAuctioneer && (
      <Button onClick={handleExtendLotClose} variant="outlined">Extend Lot Close</Button>
)}


      </>
  );
}

