import React, { useEffect, useState } from "react";


import {
  getLot,
  getItem,
} from "./../util/db.js";
// https://medium.com/@justynazet/passing-props-to-props-children-using-react-cloneelement-and-render-props-pattern-896da70b24f6
//function HydratedItems({children}) {

function HydratedItems(props) {
  const [hydratedItems, setHydratedItems] = useState([]);
  const [status, setStatus] = useState('loading')
  console.log(
    "HydratedItems props.filterType props.items",
    props.filterType,
    props.items
  );

  useEffect(() => {
    const getHydratedItems = async () => {
      const hydratedItems = await Promise.all(
        props.items.map(async (item) => {


          // Todo: Don't make the getItem call if we have an item.

          //var h = item;
          //if (item.name == null) {
            const h = await getItem(item.id);
          //}
          // else {
            //const h = item;

         // }

          const lot = await getLot(item.id);

          return { ...h, id: item.id, item: h, lot: lot };
        })
      );
      setStatus('success')
      setHydratedItems(hydratedItems);
    };

    getHydratedItems();
  }, [props.items]);

  useEffect(() => {
    console.log("HydratedItems hydratedItems", hydratedItems);
  }, [hydratedItems]);

  const updateWithprops = React.Children.map(props.children, (child, i) => {
    return React.cloneElement(child, {
      color: "red",
      items: hydratedItems,
    });
  });

if (status !== 'success') { return <></>}

  return <div>{updateWithprops}</div>;
}

export default HydratedItems;
