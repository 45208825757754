
import React from "react";
import { useEvent } from "./../util/db.js";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";


const useStyles = makeStyles((theme) => ({
    gridItem: {
      // Add border that contrasts lightly with background color.
      // We use boxShadow so that it's hidden around outer edge
      // due to container <Card> having overflow: hidden
      // boxShadow: `1px 1px 0 0 ${emphasize(theme.palette.background.paper, 0.08)}`,
      textAlign: "left",
    },
    imageContainer: {
      margin: "0 auto",
      maxWidth: "200px",
      marginBottom: "30px",
      "& img": {
        width: "100%",
      },
    },
  }));

export default function ItemEventAddress(props) {
    const {item} = props;
    const classes = useStyles();

    const {
        data: eventData,
        status: eventStatus,
        error: eventError,
      } = useEvent(item.event);


  

      if (eventStatus !== 'success') {return null}
      if (eventData === null) {return ("Auction not found.")}
return (


                <Box p={3}>     


                                 
                  <Typography variant="body1">
        
                  {eventData && eventData.address ? eventData.address : "No address"}
                  </Typography>

                </Box>
             

             



             
)


}