import React, { useEffect, useState } from "react";
import { Box, Button, Checkbox, FormControlLabel, Card, CardContent, Typography, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link, useRouter } from "./../util/router.js";
import { useAuth } from "./../util/auth.js";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

const useStyles = makeStyles((theme) => ({
    modal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        background: 'white',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    },
    button: {
      fontWeight: 600,
      paddingTop: "15px",
      paddingBottom: "15px",
      // marginTop: "10px",
    },
    labelStyle: {
    // display: 'flex',
    // alignItems: 'flex-start'
  },
}));

function CheckoutTickbox({ termsAccepted, setTermsAccepted }) {
  return (
    <Box py={1}>
      <Card elevation={0} variant="outlined" style={{ background: "whitesmoke" }}>
        <CardContent>
          <Grid container alignItems="center">
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={termsAccepted}
                    onChange={(e) => setTermsAccepted(e.target.checked)}
                    color="primary"
                  />
                }
                label={<Typography><b>I agree to seller pickup & delivery terms</b></Typography>}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography>
                By continuing to checkout, you accept the seller's pick-up and
                delivery terms, acknowledging that failure to comply will result in
                forfeiture of your purchase without compensation. If you have any
                questions about pickup, delivery, or item condition, contact the
                seller before proceeding.
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
}

export default CheckoutTickbox;