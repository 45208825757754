import React, { useState, useEffect } from "react";
import { Button } from "@material-ui/core";
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';


function ClipboardCopy({ copyText, flavour }) {
    const [isCopied, setIsCopied] = useState(false);
    const [timerID, setTimerID] = useState(null);

    useEffect(() => {
      return () => {
        if (timerID) {
          clearTimeout(timerID);
        }
      };
    }, [timerID]);

    // This is the function we wrote earlier
    async function copyTextToClipboard(text) {
      if ('clipboard' in navigator) {
        return await navigator.clipboard.writeText(text);
      } else {
        return document.execCommand('copy', true, text);
      }
    }
  
    // onClick handler function for the copy button
    const handleCopyClick = () => {
      copyTextToClipboard(copyText)
        .then(() => {
          setIsCopied(true);
          const newTimerID = setTimeout(() => {
            setIsCopied(false);
          }, 10500);
          setTimerID(newTimerID);
        })
        .catch((err) => {
          console.log(err);
        });
    };


    return (
      <Button  onClick={handleCopyClick} >
<FileCopyOutlinedIcon /> &nbsp; &nbsp;
      <span>{isCopied ? 'Link Copied To Clipboard' : "Copy Link"}</span>
    
    </Button>
    )
};

export default ClipboardCopy;
