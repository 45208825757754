import React, { useState, useEffect, useRef } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Edit";
import EditItemModal from "./EditItemModal";
//import MessagesItem from "./MessagesItem";
import { useAuth } from "./../util/auth.js";
import { updateItem, updateLot, createMessage } from "./../util/db.js";
import { makeStyles } from "@material-ui/core/styles";

import { requireAuth } from "./../util/auth.js";

import { useForm } from "react-hook-form";
import { debugFlag } from "../util/dev";

const useStyles = makeStyles((theme) => ({
  paperItems: {
    minHeight: "300px",
  },

  featured: {
    backgroundColor:
      theme.palette.type === "dark" ? theme.palette.action.selected : "#fdf8c2",
  },
  starFeatured: {
    color: theme.palette.warning.main,
  },
  accepted: {
    backgroundColor:
      theme.palette.type === "dark" ? theme.palette.action.selected : "#fdf8c2",
  },
  tickAccepted: {
    color: theme.palette.success.main,
  },
  deleteDisabled: {
    color: theme.palette.action.disabledBackground,
  },
  editDisabled: {
    color: theme.palette.action.disabledBackground,
  },
  notAccepted: {
    color: theme.palette.error.dark,
  },
  hourWaiting: {
    color: theme.palette.warning.dark,
  },
  ListItemSecondaryAction: {
    minWidth: "120px",
  },
  button: {
    margin: theme.spacing(1),
  },
  deleteDivider: {
    marginTop: "1em",
  },
}));

function ItemEdit(props) {
  const classes = useStyles();

  const { item, lot } = props;

  const auth = useAuth();
  const [choice, setChoice] = useState();

  const [updatingItemId, setUpdatingItemId] = useState();

  const updatingItemIdRef = useRef({});
  updatingItemIdRef.current = updatingItemId;

  useEffect(() => {
    console.log("ItemEdit start");
  }, []);

  const [pending, setPending] = useState(false);

  const [lotUpdate, setLotUpdate] = useState({ item: item.id });
  const canUseStar =
    auth.user.planIsActive &&
    (auth.user.planId === "pro" || auth.user.planId === "business");

  const canUseAccept = auth.user.role && auth.user.role === "auctioneer";

  const isAuctioneer = auth.user.role && auth.user.role === "auctioneer";

  const isAccepted = item && item.accepted === undefined ? null : item.accepted;

  const isOwner = auth.user.id && auth.user.id === item.owner;

  const isWithdrawn =
    item && item.withdrawn === undefined ? false : item.withdrawn;

  const isKnockedDown =
    lot && lot.knockedDownAt && lot.knockedDownAt !== false ? true : false;

  const isPaid = lot && lot.paid === true ? true : false;

  const isConsigned =
    item && item.consigned === undefined ? false : item.consigned;

  // Auctioneer can edit item in the
  // space between it being submitted and being accepted.
  // const canEdit = (isConsigned && item.accepted !== true && isAuctioneer) || ((!isConsigned) || (isConsigned && item.accepted === false)) && (isOwner || isAuctioneer);
  // const canEdit = (isConsigned && item.accepted !== true && isAuctioneer) || ((!isConsigned) || (isConsigned && item.accepted === false)) && (isOwner || isAuctioneer) || (item.listingType === "marketplace_price");
  const canEdit =
    (isConsigned && item.accepted !== true && isAuctioneer) ||
    ((!isConsigned || (isConsigned && item.accepted === false)) &&
      (isOwner || isAuctioneer)) ||
    item.listingType === "marketplace_price" || (isKnockedDown && !isPaid);

  // const canEdit = true;  
  // }
  const onSubmit = (data) => {
    setPending(true);

    var accept = false;
    if (choice === "reject") {
      accept = false;
    }
    if (choice === "accept") {
      accept = true;
    }

    if (canUseAccept) {
      if (item.accepted === undefined) {
        item.accepted = accept;
      }

      updateLot(item.id, {
        ...lotUpdate,
        item: item.id,
        accepted: accept,
        auction: item.auction,
      });
      updateItem(item.id, { accepted: accept });
    } else {
      alert("LadyBidwell's choice");
    }
    // This will be backwards - need to review/fix
    /*
    var message = data.message;
    var datagram = {
      subject: message,
      item: item.id,
      from: "auctioneer",
      to: item.owner,
    };
    createMessage(datagram, item.owner);
*/
    // This will be backwards - need to review/fix
    var message = "";
    var datagram = {};
    if (accept === true) {
      message = "Item Accepted into Collection.";
      datagram = {
        subject: message,
        item: item.id,
        from: "auctioneer",
        to: item.owner,
      };
      createMessage(datagram, item.owner);
    }

    if (accept === false) {
      message = "Item not accepted into Collection.";
      datagram = {
        subject: message,
        item: item.id,
        from: "auctioneer",
        to: item.owner,
      };
      createMessage(datagram, item.owner);
    }
    // This will be backwards - need to review/fix

    message = data.message;
    datagram = {
      subject: message,
      item: item.id,
      from: "auctioneer",
      to: item.owner,
    };
    createMessage(datagram, item.owner);

    setPending(false);
  };

  // *************************************
  // USE MessagesItem.js as messaging base.
  // ***********************************

  function handleClick() {
    console.log("ItemEdit handleClick item.id", item.id);
    setUpdatingItemId(item.id);
  }

  useEffect(() => {
    console.log("ItemEdit updateingItemId", updatingItemId);
  }, [updatingItemId]);

  const { register, handleSubmit, errors, control } = useForm();

  return (
    <>
      {debugFlag && (
        <>
          {isKnockedDown ? "IS KNOCKEDDOWN" : "IS NOT KNOCKEDDOWN"}
          {isWithdrawn ? "IS WITHDRAWN" : "IS NOT WITHDRAWN"}
          {canEdit ? "CAN EDIT" : "CANNOT EDIT"}
        </>
      )}

      <div className={classes.ListItemSecondaryAction}>
        <Box display="flex" alignItems="center">
          <Box flexGrow="1">
            {canEdit ? (
              <Button
              disableElevation
                variant="contained"
                aria-label="update"
                onClick={() => handleClick()}
                startIcon={<EditIcon />}
                fullWidth
              >
                Edit Item
              </Button>
            ) : (
              <Button
                variant="outlined"
                aria-label="update"
                className={classes.editDisabled}
                startIcon={<EditIcon />}
                disabled={true}
                fullWidth
              >
                Edit Item
              </Button>
            )}
          </Box>
        </Box>

        <Box></Box>
        <div />

        <div></div>

        {updatingItemId && (
          <EditItemModal
            id={updatingItemIdRef.current}
            onDone={() => {
              console.log("ItemEdit EditItemModal onDone");
              setUpdatingItemId(null);
            }}
          />
        )}
      </div>
    </>
  );
}

export default requireAuth(ItemEdit);
