import React from "react";
import Section from "./Section";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { Link } from "./../util/router.js";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Box } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  // Increase <Container> padding so it's
  // at least half of <Grid> spacing to
  // avoid horizontal scroll on mobile.
  // See https://material-ui.com/components/grid/#negative-margin
  container: {
    padding: `0 ${theme.spacing(3)}px`,
    textAlign: "center",
    // backgroundColor: '#eee7ff',

  },
  
}));

function CtaSection(props) {
  const classes = useStyles();

  return (
    <Section
      // style={{backgroundColor:'#af95fd'}}
      size={props.size}
      //bgImage={props.bgImage}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container className={classes.container}>
        <Grid container={true} alignItems="center" justify="center" spacing={5} >
 
          <Grid item={true} xs={12} >
          
            {/* <SectionHeader
              style={{fontSize:'2em', fontWeight:'900', color:'#ffffff', overflowWrap:'anywhere'}}
              title={props.title}
              subtitle={props.subtitle}
              size={4}
            /> */}
  <Box py={8}>
            <Typography variant='h3' style={{ fontFamily: '"Cormorant Garamond", "serif"', fontWeight:'700'}}>{props.subtitle}</Typography>
            {/* <br />
            <Typography variant='h6' style={{ fontSize:'2em', fontWeight:'500', lineHeight:'1.2em'}}>{props.subtitle}</Typography>
<br />
<br /> */}
             <Button
              style={{backgroundColor:'#a687f3', boxShadow:'none', fontWeight:'600', color:'#ffffff'}}
              variant="contained"
              size="large"
              
              component={Link}
              to={props.buttonPath}
            >
              {props.buttonText}
            </Button>
            </Box>
          </Grid>
   
        </Grid>
      </Container>
    </Section>
  );
}

export default CtaSection;
