import React from 'react';
import {
    Box,
    Card,
    CardContent,
    Container,
    Grid,
    Typography,
  } from '@material-ui/core';
  import { makeStyles } from '@material-ui/core/styles';
import searchphone from "./../images/searchphone.jpg";

const useStyles = makeStyles((theme) => ({
    title: {
      textAlign: 'left',
      fontFamily: '"Cormorant Garamond", "serif"',
      [theme.breakpoints.down('sm')]: {
      textAlign:'center',

      },
    },
    message: {
      textAlign: 'left',
      [theme.breakpoints.down('sm')]: {
       textAlign:'center',
      },
    },
    image: {
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(2),
        maxHeight:'200px',
        width:'auto',

      },
    },
    textContainer: {
        display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      },
    },
    imageGrid: {
        order: 0,
        textAlign:'center',
        [theme.breakpoints.up('md')]: {
          order: 1,
          justifyContent:'center',
        },
      },
      textGrid: {
        order: 1,
        [theme.breakpoints.up('md')]: {
          order: 0,
        },
      },
      card: {
        display: 'flex',
        alignItems: 'center', // Center the content vertically
      },
  }));

function NoSearchResults() {
  const classes = useStyles();

  return (
<div className={classes.root}>
  <Container>
    <Box py={3}>
      <Card variant="outlined" className={classes.card}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              md={6}
              className={`${classes.textContainer} ${classes.textGrid}`}
            >
              <Typography
                variant="h3"
                component="h1"
                gutterBottom
                className={classes.title}
              >
                Oh, golly gosh!
              </Typography>
              <Typography
                variant="body1"
                component="p"
                className={classes.message}
              >
                We've searched high and low, we even tried calling Auntie Marjorie. But that sneaky item you're hunting
                for is playing hide-and-seek with us, or simply doesn't exist
                in this week's sale. Tweak your search a little and we are sure
                you will find something that takes your fancy.
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} className={classes.imageGrid}>
              <img
           
                src={searchphone}
                alt="Your image description"
                className={classes.image}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  </Container>
</div>

  );
}

export default NoSearchResults;