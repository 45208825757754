import React, { useState, useEffect } from "react";
import { useAuth } from "./../util/auth.js";


import { makeStyles } from "@material-ui/core/styles";





import Button from "@material-ui/core/Button";
//import AddPaymentCard from "./../components/AddPaymentCard";
import RemovePaymentCard from "./../components/RemovePaymentCard";
import DefaultPaymentCard from "./../components/DefaultPaymentCard";
import Grid from "@material-ui/core/Grid";
import { Box } from "@material-ui/core";
import CheckBoxOutlinedIcon from "@material-ui/icons/CheckBoxOutlined";



const useStyles = makeStyles((theme) => ({
  paperItems: {
    minHeight: "300px",
  },
  featured: {
    backgroundColor:
      theme.palette.type === "dark" ? theme.palette.action.selected : "#fdf8c2",
  },
  starFeatured: {
    color: theme.palette.warning.main,
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    marginTop: "1em",
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    border: "1px solid #e0e0e0",
  },
}));

function PaymentCardStrip(props) {
  const { card, customer } = props;
  const { filter } = props;
  const classes = useStyles();

  const auth = useAuth();

  /*
Option 1 listen to Firebase
  const auth = useAuth();
const user = auth.user;
const payment_method = auth.user && auth.user.payment && auth.user.payment.payment_method ? auth.user.payment_method : null;
*/
  const [isDefault, setIsDefault] = useState(false);

/*
Check customers default_payment_method and set default appropriately.
*/


  useEffect(() => {
  
    if (card === undefined) {
      return;
    }
    //if (customer === undefined) {
    //  return;
   // }
    /*
    if (customer.invoice_settings === undefined) {
      return;
    }
    */

    var paymentMethod = auth?.user?.payment?.payment_method;

    if (
      card.payment_method === paymentMethod
    ) {
      setIsDefault(true);
    } else {
      setIsDefault(false);
    }
  }, [customer, card]);


//  if (customer === undefined) {
//    return null;
//  } // Or loading?

if (card === undefined) {

  return (<>LOADING CARD</>);

}

  return (
    <>
      <Box className={classes.paper}>
        <Grid container spacing={2}>
          
        <Grid container alignItems="center" >
       


          <Grid item container xs={9} alignItems='center'>
            {isDefault ? (
              <>
                <Button
                size='small'
                  disabled
                  style={{ color: "black" }}
                  startIcon={<CheckBoxOutlinedIcon />}
                >
                  DEFAULT CARD
                </Button>
              </>
            ) : (
              <DefaultPaymentCard
                card={card}
                customer={customer}
                refreshCustomer={() => props.refreshCustomer()}
                refreshCards={() => props.refreshCards()}
                onStatus={(s) => props.onStatus(s)}
              />
            )}
          </Grid>


          <Grid container item  xs style={{justifyContent:'flex-end'}}>
            <RemovePaymentCard
              card={card}
              refreshCards={() => props.refreshCards()}
              refreshCustomer={(s) => props.refreshCustomer(s)}
              onStatus={(s) => props.onStatus(s)}
            />
          </Grid>
          
          </Grid>

          <Grid container item xs={12} style={{justifyContent:'flex-start'}}  >
            <b>
              {card.brand}****
              {card.last4}
            </b>
            &nbsp;&nbsp;&nbsp;&nbsp;
            Expires: {card.exp_month}/{card.exp_year}

          </Grid>
          {/* <Grid container item xs={4} sm={4} style={{justifyContent:'flex-end'}}>
         
          </Grid> */}

         
        </Grid>
      </Box>
    </>
  );
}

export default PaymentCardStrip;

