import React from 'react';
import Button from '@material-ui/core/Button';
import { updateItem } from '../util/db';

function MarkSoldButton({ item }) {
  const toggleSoldStatus = () => {
    const newStatus = item.soldStatus === 'sold' ? 'unsold' : 'sold';
    updateItem(item.id, { soldStatus: newStatus })
      .then(() => console.log(`Item marked as ${newStatus}`))
      .catch((error) => console.log(error));
  }

  return (
    <Button disableElevation fullWidth variant="contained" size="medium" color="primary" onClick={toggleSoldStatus}>
      {item.soldStatus === 'sold' ? 'Mark As Unsold' : 'Mark As Sold'}
    </Button>
  );
}

export default MarkSoldButton;
