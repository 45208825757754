import React from "react";
import {
//  useImpressionsByItem,
  useCountImpressionsByItem,
} from "./../util/db.js";
import { Box } from "@material-ui/core";

function ImpressionCount(props) {
  const { item } = props;

  //  const {
  //    status: impressionsStatus,
  //    data: impressions,
  //    error: impressionsError,
  //  } = useImpressionsByItem(item.id);

  const {
    status: countImpressionsStatus,
    data: countImpressions,
    error: countImpressionsError,
  } = useCountImpressionsByItem(item.id);

  // code to be used for when we want to add "someone else is also looking at this item"

  //   useEffect(() => {
  //     console.log ("impressions", impressions);
  //     if (!impressions) {return;}
  //     if (!impressions.counter) {return;}
  //     if (impressions === null) {return}
  //     if (!props.onChange) {return;}

  // toast("Toast with Marmalade")

  //     props.onChange(impressions.counter);

  //   },[impressions])

  return (
    <>
      <Box
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {/* <VisibilityIcon fontSize="medium" /> */}

        {/*  
{countImpressionsStatus === 'success' && countImpressions && countImpressions.count}    
*/}
        {countImpressionsStatus === "success" &&
          countImpressions.count === 0 && (
            <>&nbsp;You are the first person to look at this item </>
          )}
        {countImpressionsStatus === "success" &&
          countImpressions.count === 1 && (
            <>&nbsp;One other person is interested in this item </>
          )}
        {countImpressionsStatus === "success" && countImpressions.count > 1 && (
          <>
           &nbsp; Viewed&nbsp;{countImpressions.count}&nbsp;times
          </>
        )}

        {/*
    {impressionsStatus === "success" && impressions.count === 0 && (<>&nbsp;You are the first person to look at this item.  </>)}
    {impressionsStatus === "success" && impressions.count === 1 && (<>&nbsp;One other person is interested in this item.  </>)}
    {impressionsStatus === "success" && impressions.count > 1 && (<>&nbsp;{impressions.count}&nbsp;other people are interested in this item.  </>)}
*/}
      </Box>
    </>
  );
}

export default ImpressionCount;
