import React from "react";
import { requireAuth, useAuth } from "./../util/auth.js";
import BuyerItemList from "../components/BuyerItemList";
import HeroSection2 from "./../components/HeroSection2";

function DashboardPage(props) {
  const auth = useAuth();

  const isAuctioneer = auth.user.role && auth.user.role === "auctioneer";

  const isSeller =
    auth.user &&
    auth.user.seller &&
    auth.user.seller.approvedAt &&
    auth.user.seller.approvedAt !== false;

  const { filter, mode } = props;

  //if (!(isSeller || isAuctioneer)) {return( <><NotFoundPage /></>)}

  return (
    <>
      {filter === "selling" && isSeller ? (
        <HeroSection2
          bgColor="sell"
          size="medium"
          // bgImage="https://cdn.pixabay.com/photo/2017/08/04/11/28/dog-2579885_960_720.jpg"
          // bgImageOpacity={0.8}
          title="Discard. Sell. Profit."
          subtitle="That thing. Is money..."
        />
      ) : null}





      {filter === "liked" ? (
        <HeroSection2
          // bgColor="black"
          size="normal"
          // bgImage="https://images.unsplash.com/photo-1516980907201-943c13a8d03c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2340&q=80"
          // bgImageOpacity={0.8}
          title="Your true love(s)"
          // subtitle="Heart it. Buy It. "
        />
      ) : null}



      {filter === "bids" ? (
        <HeroSection2
          bgColor="bid"
          size="medium"
          title="Get. Your. Bid On."
          subtitle="Your past bids. Your losing bids. Your current bids. Your winning bids."
        />
      ) : null}

      {filter === "invoices" ? (
        <HeroSection2
          bgColor="delivery"
          size="medium"
          title="Invoices"
          subtitle="Find invoices by date (some invoices may include pickup from multiple events, so make sure to use the orders page for pickup details)."
        />
      ) : null}

      {filter === "orders" ? (
        <HeroSection2
          // bgColor="black"
          size="normal"
          // bgImage="https://images.unsplash.com/photo-1516980907201-943c13a8d03c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2340&q=80"

          title="Your Orders"
          subtitle="It's yours, hurray. The pickup address can be found below (if you are picking up). If you agreed upon delivery prior to purchase, please coordinate with the seller directly."
        />
      ) : null}

      <BuyerItemList filter={filter} mode={mode} />
    </>
  );
}

export default requireAuth(DashboardPage);
