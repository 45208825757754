import React, { useState } from "react";
import { requireAuth } from "./../util/auth.js";
import { useSetupIntent } from "../util/stripe.js";
import {
  Container,
  Box,
  Typography,
  makeStyles,
} from "@material-ui/core";
import CheckoutDisplay from "../components/CheckoutDisplay";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import Section from "../components/Section.js";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  wrapIcon: {
    verticalAlign: "middle",
    display: "inline-flex",
  },
  sticky: {
    // marginTop: "auto",
    // bottom:'0',
    // position:'absolute'
    // paddingTop: '5em',
  },
}));

function StripeElementsCheckoutPage(props) {
  const classes = useStyles();
  const clientSecret = useSetupIntent();
  const [uuid, setUuid] = useState();

  const [status, setStatus] = useState("loading");

  const [stripePromise, setStripePromise] = useState(() =>
    loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)
  );

  const options = {
    //   // passing the client secret obtained from the server
    clientSecret: clientSecret,
  };

  function handleStatus(e) {
    console.log("StripeElementsCheckoutPage handleStatus", e);
    setStatus(e);
  }


  return (
    <>
      {/*status === 'loading' && (<PageLoader />)*/}
      {
        <Section style={{minHeight:'100vh'}} >
          <Container maxWidth="lg">
            {clientSecret && (
              <>
      
                <Elements stripe={stripePromise} options={options}>
                  <CheckoutDisplay onStatus={(e) => handleStatus(e)} />
                </Elements>
             
              </>
            )}

            {!clientSecret && <>
              <Container maxWidth='sm'>
              <Box style={{
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)', 
      backgroundColor:'#ffffff', 
      opacity:.9,
      zIndex: 9999,
      height:'100%',
      width:'100%',
      display:'flex',
      justifyContent:'center', 
      alignItems:'center'
      }}> 
    
    <Box display='block' textAlign='center'> 
 
    <CircularProgress size='5em' color='secondary' /> 
    <Box my={3}>
                <Typography variant="h4">
                Loading Secure Checkout.{" "}
                </Typography>
              </Box>
    </Box>
    </Box>  
            </Container>
           </>}
          </Container>
        </Section>
      }
    </>
  );
}

export default requireAuth(StripeElementsCheckoutPage);
