export function isPostal(text) {
//  if (isCAPostal(text)) {return true;}
  if (isUSZip(text)) {return true;}
  return false;
}
/*
function isCAPostal(pcVal) {
   return ^[A-Za-z][0-9][A-Za-z]\s{0,1}[0-9][A-Za-z][0-9]$/.test(pcVal);
}
*/

function isUSZip(sZip) {
   return /^\d{5}(-\d{4})?$/.test(sZip);
}
