import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { Box, Typography, Card, CardContent } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import { Link } from "./../util/router";
import sidecartrans from "./../images/sidecartrans.png";

const useStyles = makeStyles((theme) => ({
  title: {
    textAlign: 'left',
    fontFamily: '"Cormorant Garamond", "serif"',
    [theme.breakpoints.down('xs')]: {
    textAlign:'center',

    },
  },
  message: {
    textAlign: 'left',
    paddingTop:'5px',
    [theme.breakpoints.down('xs')]: {
     textAlign:'center',
    },
  },
  image: {
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2),
      maxHeight:'250px',
      width:'100%',

    },
  },
  textContainer: {
      display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
    paddingLeft:'50px',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      marginTop:'-30px',
 
    },
  },
  imageGrid: {
      order: 0,
      textAlign:'center',

      [theme.breakpoints.up('sm')]: {
        order: 1,
        justifyContent:'center',
      },
    },
    textGrid: {
      order: 1,
      
      [theme.breakpoints.up('sm')]: {
        order: 0,
      },
    },
    card: {
      // display: 'flex',
      alignItems: 'center', // Center the content vertically
      border:'4px solid',
      justifyContent:'center',
      borderRadius:'10px',
      borderColor: theme.palette.primary.main,
    },
}));

function NewLadyTown() {
const classes = useStyles();

return (
<div className={classes.root}>
<Container>
  <Box py={3}>
    <Card variant="outlined" className={classes.card}>
      <CardContent>
        <Grid container spacing={4}>
          <Grid
            item
            xs={12}
            sm={6}
            className={`${classes.textContainer} ${classes.textGrid}`}
          >
            <Typography
              variant="h2"
              component="h1"
            //  gutterBottom
              className={classes.title}
            >
             <b> Say sayonara,</b><br/> to your sidecar. 
            </Typography>
            <Typography
              variant="h6"
              component="p"
              className={classes.message}
            >
        No more waiting in estate sale lines or driving around all Saturday and Sunday. Simply bid, pickup (unless the seller offers delivery), and enjoy.
            </Typography>

<Box py={2}>
<Button
 component={Link}
 disableElevation={true}
 variant="outlined"
//  color="secondary"
//  style={{color:'white'}}
 to={{
   pathname: "/about",
   state: { scrollToTop: true },
 }}

>

  Get to know us a little better
</Button>
</Box>

          </Grid>
          <Grid item xs={12} sm={6} className={classes.imageGrid}>
            <img
         
              src={sidecartrans}
              alt="Your image description"
              className={classes.image}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  </Box>
</Container>
</div>

);
}

export default NewLadyTown;


