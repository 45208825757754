import React, { useState, useEffect, useRef } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { useAuth } from "./../util/auth.js";
import { useForm } from "react-hook-form";
//import SellerBadge from "./../components/SellerBadge";




import { useLotsByAffiliate } from "./../util/db.js";


// Useful. Appropriate?
import { forceCheck } from "react-lazyload";

const prefix = process.env.REACT_APP_WEB_PREFIX;


function ShareItemAffiliate(props) {
  const auth = useAuth();

  const endpointUrl = window.location.pathname; //href gives full url

  const [copyStatus, setCopyStatus] = useState("Copy");
  const textFieldRef = useRef(null);

  // Count successful affiliations.
  const [count, setCount] = useState();

  const [affiliations, setAffiliations] = useState();
  const [affiliationsMessage, setAffiliationsMessage] = useState();

  const handleCopy = () => {
    if (typeof auth?.user?.affiliate?.affiliateCode !== "undefined") {
      //    if (textFieldRef && textFieldRef.current) {
      //      const textFieldValue = textFieldRef.current.value;

      const textFieldValue = auth?.user?.affiliate?.affiliateCode;

      navigator.clipboard.writeText(endpointUrl + "&ladybcode=" + textFieldValue);
      setCopyStatus("Copied");
      setTimeout(() => setCopyStatus("Copy"), 10000);
    }
  };
  const [pending, setPending] = useState(false);

  const { register, handleSubmit, errors, getValues } = useForm();

  const {
    data: affiliatedLots,
    status: affiliatedLotsStatus,
    error: affiliatedLotsError,
  } = useLotsByAffiliate(auth?.user?.id);

  useEffect(() => {
if (affiliatedLots == null) {return;}
setCount(affiliatedLots.length);    

console.log("SettingsAffiliate affiliatedLots", affiliatedLots);
  }, [affiliatedLots]);

// Discuss: forceCheck or state variable update

useEffect(() =>{
  forceCheck();
},[auth?.user?.affiliate?.affiliateCode]);



  return (
    <Grid container={true} spacing={1}>
      <Grid container item xs={12} spacing={2}>
                <Button
                  disableElevation
                  variant="contained"
                  color={props.buttonColor}
                  size="medium"
                  type="submit"
                  onClick={handleCopy}
                >
                  {copyStatus}
                </Button>
{count === 0 ? "" : count}
      </Grid>
    </Grid>
  );
}

export default ShareItemAffiliate;
