import { useEffect, useState } from "react";
import { useItemImages } from "./../util/db.js";
import { headlineEventPeriod } from "./../util/time.js";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { Hidden, Typography, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PriceDropCountdown from "./PriceDropCountdown.js";
import LearnMoreDrawer from "./../components/LearnMoreDrawer";
import abstract1 from "../images/abstract1.jpg";
import { useAuctionByEvent } from "./../util/db.js";
import { isPastTime, humanTime } from "../util/time";
import PriceDropClockTime from "./PriceDropClockTime.js";

const useStyles = makeStyles((theme) => ({
  // Increase <Container> padding so it's
  // at least half of <Grid> spacing to
  // avoid horizontal scroll on mobile.
  // See https://material-ui.com/components/grid/#negative-margin

  root: {
    width: "fit-content",
    display: "flex",
    // border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    //backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
    "& svg": {
      marginLeft: theme.spacing(1),
    },
    // "& hr": {
    //   margin: theme.spacing(0, 1),
    // },
  },

  cardRoot: {
    position: "relative",
  },
  font: {
    position: "absolute",
    zIndex: "99",
    top: "20%",
    width: "100%",
    // textAlign: "left",
  },

  container: {
    padding: `0 ${theme.spacing(3)}px`,
    // backgroundColor:'#F4F4F4',
    background:
      "conic-gradient(from 223.05deg at 74.52% 40.49%, rgba(255, 255, 255, 0) 0deg, rgba(247, 247, 247, 0.874386) 180deg, rgba(239, 239, 239, 0.73) 360deg)",
  },
  image: {
    // margin: "0 auto",
    // maxWidth: 570,
    // display: "block",
    // height: "auto",
    // width: "100%",
  },

  title: {
    fontFamily: '"Cormorant Garamond", "serif"',
  },
  subtitle: {
    fontWeight: 400,
    // lineHeight: "1.25em",
    // fontFamily: '"Cormorant Garamond", "serif"',
  },
  mobiletitle: {
    // fontWeight: 500,
    lineHeight: "1.25em",
    fontFamily: '"Cormorant Garamond", "serif"',
  },
  mobilesubtitle: {
    lineHeight: "1.25em",
  },

  // divider: {
  //   position: "relative",
  //   marginTop: "90px",
  //   height: "1px",
  // },
  divider: {
    // marginLeft: "-5%",
    // marginRight: "-5%",
    // background:'#f8f72',
    // opacity:'.2',
    // backgroundColor: theme.palette.secondary.main,
  },

  transparent: {
    "&::before": {
      content: "",
      position: "absolute",
      top: "0",
      left: "5%",
      right: "5%",
      width: "90%",
      height: "1px",
      backgroundImage:
        "linear-gradient(to right, transparent, rgb(48,49,51), transparent)",
    },
  },

  desktopImageStyle: {
    position: "relative",
    left: "-20%",
    width: "120%",
    objectFit: "cover",
    overflow: "hidden",
  },

  arrowdown: {
    "&::after": {
      content: "",
      position: "absolute",
      zIndex: "999",
      top: "-7px",
      left: "calc(50% - 7px)",
      width: "14px",
      height: "14px",
      transform: "rotate(45deg)",
      backgroundColor: "white",
      borderBottom: "1px solid #d6b8fd",
      borderRight: "1px solid rgb(48,49,51)",
    },
  },
}));

function HeroSection(props) {
  const classes = useStyles();
  const { event, collection } = props;

  const { width } = props;
  let imageHeight;

  if (width === "xs") {
    imageHeight = "300px";
  } else if (width === "sm") {
    imageHeight = "400px";
  } else {
    imageHeight = "500px";
  }

  const [loadingContext, setLoadingContext] = useState(true);

  let collectionImages = collection === undefined ? [] : collection.images;
  const [itemCount, setItemCount] = useState();

  const {
    data: images,
    status: imagesStatus,
    error: imagesError,
  } = useItemImages(collectionImages);

  // Check if the auction is running.
  const { data: collectionData } = useAuctionByEvent(collection.id);

  const isRunning = collectionData && collectionData.state;

  const isCollectionEnded = collection && isPastTime(collection.endTime); // Not as important for decling price
  const isCollectionStarted = collection && isPastTime(collection.startTime);

  const showPriceDropTime =
    isRunning && !isCollectionEnded && isCollectionStarted;
  const showPreviewNotice = isRunning && !isCollectionStarted;

  const [processedImages, setProcessedImages] = useState([]);

  useEffect(() => {
    console.log(
      "HeroSection collection isRunning isCollectionStarted not isCollectionEnded",
      collection,
      isRunning,
      isCollectionStarted,
      !isCollectionEnded
    );
  }, [isRunning, isCollectionStarted, isCollectionEnded]);

  const collectionUrls = images && images.imageUrls;

  const loading = imagesStatus === "waiting" || imagesStatus === "loading";

  console.log("image status hero", imagesStatus);

  const backgroundImage1 =
    imagesStatus === "success" &&
    images &&
    images.imageUrls &&
    images.imageUrls.length > 0 &&
    images.imageUrls[0];

  console.log("Background image 1 is loaded", backgroundImage1);

  const [backgroundImage1Loaded, setBackgroundImage1Loaded] = useState(false);

  useEffect(() => {
    console.log("images main page", images);

    if (backgroundImage1) {
      const img = new window.Image();
      img.onload = () => {
        setBackgroundImage1Loaded(true);
      };

      img.src = backgroundImage1;
    }
  }, [backgroundImage1]);

  useEffect(() => {
    console.log("imges main page", images);
  }, [images]);

  function handleItemCount(count) {
    console.log("handleItemCount", count);
    setItemCount(count);
  }

  const MarkDown = (text) => {};

  const defaultImageHeight = 250;

  useEffect(() => {
    console.log("HeroSection collection", collection);
  }, [collection]);

  if (loading) {
    return null;
  }

  return (
    <>
      <Box style={{ overflow: "hidden" }}>
        {/* MOBILE VIEW       */}

        <Hidden mdUp>
          <Box style={{ backgroundColor: collection.colour }}>
            <Container>
              <Grid container>
                <Grid item xs={12}>
                  {backgroundImage1 ? (
                    <img
                      src={backgroundImage1}
                      style={{
                        opacity: backgroundImage1Loaded ? 1 : 0,
                        aspectRatio: "3/2",
                        width: "100%",
                        objectFit: "cover",
                        overflow: "hidden",
                      }}
                    />
                  ) : (
                    <img
                      src={abstract1}
                      style={{
                        aspectRatio: "3/2",
                        width: "100%",
                        objectFit: "cover",
                        overflow: "hidden",
                      }}
                    />
                  )}
                </Grid>

                <Grid item xs={12}>
                  <Box
                    py={1}
                    sx={{
                      textAlign: { xs: "left", md: "left" },
                    }}
                  >
                    <Typography
                      variant="h4"
                      className={classes.title}
                      style={{ fontWeight: "500" }}
                      gutterBottom
                    >
                      {collection.name}
                      </Typography>

                      <Typography
                      variant="h4"
                      className={classes.title}
                      style={{ fontWeight: "300" }}
                      gutterBottom
                    >
                      <span>{headlineEventPeriod(collection)}</span>
                 
                      </Typography>

                  
                    {showPreviewNotice && (
                          <Typography variant='h4' 
                          className={classes.title}
                          style={{ fontWeight: "300" }}
                          gutterBottom>
                      <>Event Starts {humanTime(collection.startTime)}</>
                      </Typography>
                    )}
               


                    {showPriceDropTime && (
                      <>
                         <Divider className={classes.divider} />
                        <Box py={1}>
                       
                          <Box
                            display="flex"
                            flexWrap="no-wrap"
                            alignItems="center"
                            py={.5}
                          >
                            <div className="blob" />

                            <Typography
                              variant="subtitle1"
                              style={{
                                marginLeft: "10px",
                                fontWeight: "500",
                              }}
                            >
                              Next Price Drop: <PriceDropCountdown />
                            </Typography>
                          </Box>

                          <Box >
                            <Typography
                              variant="subtitle2"
                              style={{
                                display: "inline-block",

                                fontWeight: "500",
                              }}
                            >
                              Price drops daily at <PriceDropClockTime />, on every item, until sold.{" "} &nbsp;
                              <span style={{ display: "inline-block" }}>
                                <LearnMoreDrawer />
                              </span>
                            </Typography>
                          </Box>

                        </Box>

                        <Divider className={classes.divider} />
                      </>
                    )}
                    <Box py={.5}>
                    <Typography
                      variant="subtitle1"
                      color="textPrimary"
                      className={classes.subtitle}
                      style={{
                        fontWeight: "300",
                        marginBottom: "1.5em",
                        lineHeight: "1.5em",
                      }}
                    >
                      {collection.headline}
                    </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>
        </Hidden>

        {/* DESKTOP VIEW */}
        <Hidden smDown>
          <Box style={{ backgroundColor: collection.colour }}>
            <Container
              style={{
                overflow: "visible",
                height: imageHeight,
              }}
            >
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  {backgroundImage1 ? (
                    <img
                      src={backgroundImage1}
                      className="object-fit"
                      style={{
                        opacity: backgroundImage1Loaded ? 1 : 0,
                        objectFit: "cover",
                        position: "relative",
                        left: "-20%",
                        height: imageHeight,
                        width: "120%",
                        overflow: "hidden",
                      }}
                    />
                  ) : (
                    <img
                      src={abstract1}
                      className="object-fit"
                      style={{
                        objectFit: "cover",
                        position: "relative",
                        left: "-20%",
                        height: imageHeight,
                        width: "120%",
                        overflow: "hidden",
                      }}
                    />
                  )}
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box
                    p={5}
                    sx={{
                      textAlign: { xs: "left", md: "left" },
                      // background: "#f7f7f7",
                      // opacity: ".8",
                      height: "100%",
                    }}
                  >
                    <Typography
                      variant="h4"
                      className={classes.title}
                      style={{ fontWeight: "500", marginBottom: ".75em" }}
                    >
                      <b>{collection.name}</b> <br />
                      <span>{headlineEventPeriod(collection)}</span>
                    </Typography>
                    {showPriceDropTime && (
<>
                    <Divider className={classes.divider} />
                    <Box
                      display="flex"
                      flexWrap="wrap"
                      alignItems="center"
                      py={1}
                    >
                      <div className="blob" />

                      <Typography
                        variant="h6"
                        style={{
                          display: "inline-block",
                          marginLeft: "10px",
                          fontWeight: "500",
                        }}
                      >
                        Next Price Drop: <PriceDropCountdown /> 
                        {/* <span style={{ display: "inline-block" }}>
                          <LearnMoreDrawer />
                        </span> */}
                      </Typography>
                      <Box pt={1}>
                        <Typography
                          variant="subtitle1"
                          style={{
                            display: "inline-block",

                            fontWeight: "500",
                          }}
                        >
                          Price drops daily at <PriceDropClockTime />, on every item, until sold.{" "}
                          <span style={{ display: "inline-block" }}>
                            <LearnMoreDrawer />
                          </span>
                        </Typography>
                      </Box>

                      
                    </Box>
                    <Divider className={classes.divider} />
</>
                    )}



                    <Box pt={2}>
                      <Typography
                        variant="subtitle1"
                        color="textSecondary"
                        className={classes.subtitle}
                        style={{
                          fontWeight: "400",
                          marginBottom: "1.5em",
                          lineHeight: "1.5em",
                        }}
                      >
                        {collection.headline}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>
        </Hidden>
      </Box>
    </>
  );
}

export default HeroSection;
