import React, { useState, useEffect } from "react";
import { useAuth } from "./../util/auth.js";
import {
  useLotsByWinner,
} from "./../util/db.js";
import { makeStyles } from "@material-ui/core/styles";
import ItemsGroupList from "./../components/ItemsGroupList";
//import firebase from "../util/firebase";
import RequestPayment from "./../components/RequestPayment";
import EventGroupHeader from "./../components/EventGroupHeader";

//import { firebaseApp } from "../util/firebase";



//import { processInvoice} from "../util/stripe.js";

import { getById, useLotsByUser } from "../util/db.js";
function rand() {
  return Math.round(Math.random() * 20) - 10;
}

Object.defineProperty(Array.prototype, "group", {
  enumerable: false,
  value: function (key) {
    let map = {};
    this.map((e) => ({ k: key(e), d: e })).forEach((e) => {
      map[e.k] = map[e.k] || [];
      map[e.k].push(e.d);
    });
    return Object.keys(map).map((k) => ({ event: k, items: map[k] }));
  },
  configurable: true
});

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

//const serverTimestamp = firebaseApp.firestore.FieldValue.serverTimestamp;



/*
const processStripeClientSecret = firebase
  .functions()
  .httpsCallable("processStripeClientSecret");
  */
//const processPaymentAuthorization = firebase.functions().httpsCallable("processPaymentAuthorization");
//const getInvoices = firebase.functions().httpsCallable("getInvoices");

const useStyles = makeStyles((theme) => ({
  paperItems: {
    minHeight: "300px",
  },
  featured: {
    backgroundColor:
      theme.palette.type === "dark" ? theme.palette.action.selected : "#fdf8c2",
  },
  starFeatured: {
    color: theme.palette.warning.main,
  },

  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

function ItemsListDelivery(props) {
  const { filter } = props;
  const classes = useStyles();

  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const SimpleModal = () => {
    return <>Here goes teh contents of the modal</>;
  };

  const Body = (props) => {
    const { invoice } = props;
    return (
      <div style={modalStyle} className={classes.paper}>
        <h2 id="simple-modal-title">Text in a modal</h2>
        <p id="simple-modal-description">
          <RequestPayment />
          {invoice.id}
          <iframe
            src="https://invoice.stripe.com/i/acct_1InobcCwAArHtQSX/invst_JTNFLmkT23hFGPCCuJ7llOB5YefelD0"
            title="Stripe"
          ></iframe>
        </p>
        <SimpleModal />
      </div>
    );
  };

  // const stripePromise = loadStripe("pk_test_6pRNASCoBOKtIshFeQd4XMUh");
  /*
  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
  );
  */

  const auth = useAuth();

  const customerId =
    auth.user && auth.user.payment && auth.user.payment.customer;

  //const [invoices, setInvoices] = useState();
  //const [invoicesStatus, setInvoicesStatus] = useState();
  const [groups, setGroups] = useState();
  const [groupsStatus, setgroupsStatus] = useState();
  const [items, setItems] = useState();
  const [itemsStatus, setItemsStatus] = useState("loading");

  const {
    data: lots,
    status: lotsStatus,
    error: lotsError,
  } = useLotsByWinner(auth.user.id);

  const {
    data: lotsUser,
    status: lotsUserStatus,
    error: lotsUserError,
  } = useLotsByUser(auth.user.id);

  const isAuctioneer = auth.user.role && auth.user.role === "auctioneer";

  const itemTypeWordSingular = isAuctioneer ? "Auction" : "Collection";
  const itemTypeWordPlural = isAuctioneer ? "Auctions" : "Collections";

  useEffect(() => {
    if (lotsStatus !== "success") {
      return true;
    }
    if (lotsUserStatus !== "success") {
      return true;
    }

    const tempUserItems = lotsUser.filter((lotUser) => {
      if (lotUser.paid === true) {
        return true;
      } else {
        return false;
      }
    });

    const userItemIds = tempUserItems.map((tempUserItem) => {
      return tempUserItem.id;
    });

    const tempItems = lots.filter((lot) => {
      if (lot.paid === true) {
        return true;
      }
      return false;
    });

    // Merge the two array

    const itemIds = tempItems.map((tempItem) => {
      return tempItem.id;
    });

    const c = itemIds.concat(userItemIds);
    const d = c.filter((item, pos) => c.indexOf(item) === pos);

    const t = getById("items", d);
    t.then((results) => {
      // inject in metadata item to help
      const i = results.map((result) => {
        return { ...result, item: result.id, metadata: { item: result.id } };
      });

   
      let g = i.group((item) => item.event);

      setGroups(g);
      setgroupsStatus("success");

      setItems(i);
      setItemsStatus("success");
    });
  }, [lots, lotsStatus, lotsUser, lotsUserStatus]);

  if (itemsStatus !== "success") {
    return null;
  }

  return (
    <>
      {groups.map((group) => (
        <>
          <div>
            <EventGroupHeader event={group.event} />

            <ItemsGroupList items={group.items} />
          </div>
        </>
      ))}
    </>
  );
}

export default ItemsListDelivery;
