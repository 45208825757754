// Map our custom plan IDs ("basic", "premium", etc) to Stripe price IDs
const stripePriceIds = {
  starter: process.env.REACT_APP_STRIPE_PRICE_STARTER,
  pro: process.env.REACT_APP_STRIPE_PRICE_PRO,
  business: process.env.REACT_APP_STRIPE_PRICE_BUSINESS,
};

// Get Stripe priceId
export function getStripePriceId(planId) {
  return stripePriceIds[planId];
}

// Get friendly plan ID ("basic", "premium", etc) by Stripe plan ID
// Used in auth.js to include planId in the user object
export function getFriendlyPlanId(stripePriceId) {
  return Object.keys(stripePriceIds).find(
    (key) => stripePriceIds[key] === stripePriceId
  );
}

export function textPrice(price) {
console.log("price",price)
  if (price === undefined) {return true;}
  if (price.amount === undefined) {return true;}
  if (price.currency === undefined) {return true;}
  if (price.currency === NaN) {return true;}

  if (price.currency.toLowerCase() === 'usd') {return "$"+price.amount.toFixed(2)}
  return true;

}