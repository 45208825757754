import React, { useEffect } from "react";
import { useAuth } from "./../util/auth.js";
import {
  useLotsByWinner
} from "./../util/db.js";
import { makeStyles } from "@material-ui/core/styles";
import { loadStripe } from "@stripe/stripe-js";
import {CardElement, useStripe, useElements} from '@stripe/react-stripe-js';
//import firebase from "../util/firebase";
import { getFunctions, httpsCallable } from "firebase/functions";

const functions = getFunctions();


const processStripeClientSecret = httpsCallable(functions,"processStripeClientSecret");



const useStyles = makeStyles((theme) => ({
  paperItems: {
    minHeight: "300px",
  },
  featured: {
    backgroundColor:
      theme.palette.type === "dark" ? theme.palette.action.selected : "#fdf8c2",
  },
  starFeatured: {
    color: theme.palette.warning.main,
  },
}));

function RequestPayment(props) {
  const {filter} = props;
  const classes = useStyles();

  // const stripePromise = loadStripe("pk_test_6pRNASCoBOKtIshFeQd4XMUh");
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
  

const auth = useAuth(); 

  const {data: items, status:itemsStatus, error: itemsError} = useLotsByWinner(auth.user.id);
 
  const isAuctioneer = auth.user.role && auth.user.role === "auctioneer";

  const itemTypeWordSingular = isAuctioneer ? "Auction" : "Collection";
  const itemTypeWordPlural = isAuctioneer ? "Auctions" : "Collections";

useEffect(() =>{

console.log(itemsError)
},[itemsError])


const StripeRequestPayment = () => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);

    // Use your card Element with other Stripe.js APIs
    const {error, paymentMethod} = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      console.log('[error]', error);
    } else {
/*
const customer = await stripe.setupIntents.create();
console.log("customer",customer)
const setupIntent = await stripe.setupIntents.create({customer:customer.id})
console.log("setupIntent", setupIntent)
      console.log('[PaymentMethod]', paymentMethod);
*/

// Make a call to backend to get client secret
// For now use server.js locally obtained.
// TODO: Build firebase endpoint to do this.

const paymentMethodDefault = {card: cardElement,
  billing_details: {name:'Jenny Rosen'}
};



console.log("payment",paymentMethod);

processStripeClientSecret()
//processPaymentAuthorization({paymentMethod:paymentMethod})
.then(function(clientSecretObject) {
  console.log("clientSecretStuff",clientSecretObject)
//const clientSecret = 'seti_1InuSsCwAArHtQSXoLafuKSI_secret_JQm0HMDC4zCUetS7tuh7xG3asx9sPwC';
const clientSecret = clientSecretObject.data.client_secret;

/*
      const {error, cardSetup} = await stripe.confirmCardSetup(
        clientSecret, {payment_method:paymentMethod.id}
      )*/

      stripe.confirmCardPayment(
        clientSecret, {payment_method:
         paymentMethod.id,
        },
      )
      .then(function(result) {
console.log(result)
//const setupIntent = result.setupIntent;
//updateUser(auth.user.id, {payment:{id:setupIntent.id, payment_method:setupIntent.payment_method}})

      })
      .catch(function(error) {console.log(error)})

      //.then(() => {
        /*
        if (error) {
          console.log('[error]', error);
        } else {
      console.log(cardSetup)
        }
        */
      //})


    })
    .catch(function(error) {console.log(error)})
  };
}
  return (
    <form onSubmit={handleSubmit}>
      <CardElement />
      <button type="submit" disabled={!stripe}>
        Request Payment
      </button>
    </form>
  );
};


if (itemsStatus !== 'success') {return null;}

  return (
    <>

{/*<Elements stripe={stripePromise}>*/}

<StripeRequestPayment />


{/*</Elements>*/}

    </>
  );
}

export default RequestPayment;


/*

            {items
              .filter((item) => (item.name !== undefined && item.accepted === true))
              .map((item, index) => (


*/
