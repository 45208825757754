import { useEffect, useState } from "react";

import { zuluTime, indexedTime } from "../util/time.js";
import {
  updateLot,
  getLotsByCollection,
  getItemsByCollection,
  getAuction,
  getProfile
} from "../util/db.js";
import { pickHighest, findItem } from "../util/array.js";

import { getFunctions, httpsCallable } from "firebase/functions";

const functions = getFunctions();
const getSellerAccountStatus = httpsCallable(
  functions,
  "getSellerAccountStatus"
);

//  const {
//    data: lotsData,
//    status: lotsStatus,
//    error: lotsError,
//  } = useLotsByAuction(event.id);

export function isItemLotBuyable(itemLot) {
  // Figure out rules.
  // Probably need to bring in lot to get knockeddown.
  // return Promise.resolve(true);

  if (!itemLot) {
    console.log("lot isItemLotBuyable not itemLot")
    return Promise.resolve(false);
  }

  if (
    itemLot &&
    itemLot.lot &&
    itemLot.lot.knockedDownAt &&
    itemLot.lot.knockedDownAt !== false
  ) {
    console.log("lot isItemLotBuyable", itemLot.id, itemLot.lot.knockedDownAt);
    return Promise.resolve(false);
  }

  // test 29 sep 2022
  if (
    itemLot &&
    itemLot.lot &&
    itemLot.lot.delistedAt &&
    itemLot.lot.delistedAt !== false
  ) {
    console.log(
      "lot isItemLotBuyable delistedAt",
      itemLot.id,
      itemLot.lot.delistedAt
    );

    return Promise.resolve(false);
  }

  console.log(
    "lot isItemLotBuyable not knockeddown or delisted",
    itemLot.id,
    itemLot.lot
  );

  if (itemLot?.item?.accepted !== true) {
    return Promise.resolve(false);
  }
  //if (itemLot && itemLot.lot && itemLot.lot.knockedDownAt !== false) {
  //  if (itemLot && itemLot.lot && itemLot.lot.knockedDownAt === false) {
  //    return true;
  //  }

  console.log("lot isItemLotBuyable auction", itemLot.id, itemLot.item.auction);
  //return dynamicBuyable(itemLot);

  return getAuction(itemLot.item.auction)
    .then((result) => {
     
      console.log("lot isItemLotBuyable getAuction", itemLot.id, result);
      // If the auction is stopped, the auction state is false.
      if (result.state === false) {
        return false;
      }

 //     return getSellerAccountStatus({ id: itemLot.item.owner })
 return getProfile(itemLot.item.owner)
 .then((accountResult) => {
   console.log("isItemLotBuyable accountResult", accountResult)

// Todo: test by changing charges_enabled for specific seller in Firestore.

   // Quick check to make sure the variable exists.
   // This will be the case if the seller has
   // not completed the stripe onboarding.
   if (accountResult.charges_enabled == null) {
    return false;
   }

   // Otherwise return the current value.
   return accountResult.charges_enabled;
          /*
          // looks much faster without the cloud function call
          // return Promise.resolve(true);
          console.log(
            "lot isItemLotBuyable sellerStatus",
            itemLot.id,
            accountResult.data.data
          );

          return accountResult.data.data;
          */
        })
        .catch((error) => {
          console.error("lot isItemLotBuyable error", itemLot.id, error);
          return false;
        });
    })
    .catch((error) => {
      return false;
    });

  //  return false;
}

export function isBuyer(itemLot, userId) {

  // Figure out rules.
  // Probably need to bring in lot to get knockeddown.
  if (itemLot && itemLot.lot && itemLot.lot.knockedDownAt !== false) {
    //  if (itemLot.paid === false) {return false;}
    if (itemLot.lot.bidOwner === userId) {
      return true;
    }
  }

  return false;
}

export function useLot(itemLot) {
  // Figure out rules.
  // Probably need to bring in lot to get knockeddown.
  //return true;
  const [isBuyable, setIsBuyable] = useState();
  const isBought = itemLot && itemLot.paid;

  useEffect(() => {
    return isItemLotBuyable(itemLot)
      .then((result) => {
        setIsBuyable(result);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [itemLot]);

  return { isBought: isBought, isBuyable: isBuyable };
}

export async function makeLots(event) {
  // I think what this line does
  // is to create an auction if it dowesn't exist.
  // Either way. Set the auction state to false.
  // Auction state is what? (running / not running)
  // createAuction(event.id, { state: false });

console.log("lot makeLots called event", event)

  return getItemsByCollection(event.id)
    .then((items) => {
      console.log("lot.js items", items);

      return getLotsByCollection(event.id)
        .then((lotsData) => {
          console.log("lot.js makeLots items lotsData", items, lotsData);
          const closesAt = event.endTime;
          const startsAt = event.startTime;

          const maxIndex = pickHighest(lotsData);
          //setMaxIndex(i);
          //const maxIndex = 10;
          var count = maxIndex;

          console.log("lot.js foo");

          var index;
          for (index in items) {
            // Check the conditions for allowing an item to become a lot.

            // Does it have an auction ID.
            // If not ignore.
            if (items[index].auction === undefined) {
              console.log("lot makeLots auction not defined", items[index].id);
              continue;
            }

            // Has it been consigned.
            // If not ignore.
            if (items[index].consigned !== true) {
              console.log("lot makeLots consigned not true", items[index].id);
              continue;
            }

            // Has it been accepted.
            // If not ignore.
            if (items[index].accepted !== true) {
              console.log("lot makeLots accepted not true", items[index].id);
              continue;
            }
            // Process all items with an auciton ID, that have been consigned and accepted.
            console.log("CreateLots makeLots lotsData", lotsData);
            const m = findItem(lotsData, items[index].id);
            var i = -1;
            if (m.index === -1) {
              count += 1;
              i = count;
            } else {
              if (m.item.index === undefined) {
                count += 1;
                i = count;
              } else {
                i = m.item.index;
              }
            }
            console.log("CreateLots makeLots m", m);
            const lotIndex = i;

            // Anything here is a valid item to make a lot from.

            // 10-004
            const lotId = count.toString();

            // All items in the colelction start at the the same same time.
            // They finish in 1 minute intervals starting at the close time..
            const type = event && event.type ? event.type : null;

            const zuluStartsAt = zuluTime(startsAt);

            var zuluEndsAt = zuluTime(closesAt);
            if (type === "increasing_bid") {
              zuluEndsAt = indexedTime(zuluTime(closesAt), lotIndex, 0);
            }
            //var lotId = count;
            var endAt = null;

            //console.log(items[index])
            // This resets EVERYTHING.
            // Including knockedDown times.
            console.log("CreateLots event", event);

            const xdata = {
              type: type,
              index: lotIndex,
              //        lot: lotId,
              //        knockedDownAt: false,
              //        item: items[index].id,
              auction: items[index].auction,
              startsAt: zuluStartsAt,
              endsAt: zuluEndsAt,
            };

            console.log("lot.js makeLots Zulu startsat and endsat", xdata)

            console.log("lot.js bar");

            //        if (!test) {
            console.log("lot.js items index", items[index]);
            console.log("lot.js items[index].id", items[index].id);

            updateLot(items[index].id, xdata)
              .then((result) => {
                console.log("lot.js result", result);
              })
              .catch((error) => {
                console.error("lot.js makeLots updateLot error", error);
              });
            //  createPrice({ item: items[index].id, amount: 99.99 });
            //      }
          }
        })
        .catch((error) => {
          console.error("lot.js makeLots lots error", error);
        });
    })
    .catch((error) => {
      console.error("lot.js makeLots items error", error);
    });
}
