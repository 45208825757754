
export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function withCentsFormatPrice(amount) {

  const fixedNumber = Number.parseFloat(amount).toFixed(2);
  return String(fixedNumber).replace(/\B(?=(\d{3})+(?!\d))/g, ",");

}

export function noCentsFormatPrice(amount) {

  const fixedNumber = Number.parseFloat(amount).toFixed(0);
  return String(fixedNumber).replace(/\B(?=(\d{3})+(?!\d))/g, ",");

}

