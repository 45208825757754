import { useEffect, useState } from "react";
import { useAuctionsByFeatured, getAllItems } from "./../util/db";
import WeeklyPost from "./WeeklyPost";
import { Typography, useScrollTrigger } from "@material-ui/core";
import HeroSection from "./HeroSection";
import { useAuth } from "./../util/auth.js";
import Search from "./Search";
import { debugFlag } from "./../util/dev.js";
import { forceCheck } from "react-lazyload";
import NewsletterSection from "./NewsletterSection";
import NoSearchResults from "./NoSearchResults";
import NewLadyTown from "./NewLadyTown";
import { useQuery } from 'react-query';
import ItemsGrid from "./ItemsGrid";

function MarketplaceHome(props) {
  const [items, setItems] = useState([]);
  const [searchQuery, setSearchQuery] = useState();
  const [countItems, setCountItems] = useState();
  
  
  useEffect(() => {
    const fetchItems = async () => {
        const allItems = await getAllItems();
        console.log("allItems", allItems);  // Add this line for debugging
        setItems(allItems);
    };

    fetchItems();
}, []);

function handleRequestSearch(c) {
  console.log("Profilepage handleSearch c", c);
  setSearchQuery(c);
}

function handleChangeSearch(c) {
  console.log("Profilepage handleChange c", c);
  setSearchQuery(c);
}

useEffect(() => {
  console.log("WeeklyPost searchQuery", searchQuery);
  //setItemsCount(0);
}, [searchQuery]);

function handleCardCount(c) {
  console.log("AuctionsHome handleCardCount c", c);
  console.log("set count items", c);
  setCountItems(c);
  forceCheck();
}


  return (
    <>

<Search
                onRequestSearch={(s) => handleRequestSearch(s)}
                onChangeSearch={(s) => handleChangeSearch(s)}
                countItems={countItems}
              />


   <ItemsGrid items={items} flavour="marketplace_price"     searchQuery={searchQuery}
              onCardCount={(c) => handleCardCount(c)}/>

   
  {/* <div>
            {items.map((item) => (
                <div key={item.id}>
                    <h2>{item.title}</h2>
                    <p>{item.description}</p>
               
                </div>
            ))}
        </div> */}

    </>
  );
}

export default MarketplaceHome;
