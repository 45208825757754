import React, { useState, useEffect } from "react";
import { useAuth, requireAuth } from "./../util/auth.js";
import { createMessage } from "./../util/db.js";
import { makeStyles } from "@material-ui/core/styles";
import { isPastTime } from "./../util/time.js";
import ConfirmDialog from "./ConfirmDialog.js";
import { IconButton } from "@material-ui/core";
import Delete from "@material-ui/icons/Delete";


import {
  deleteItem,
} from "./../util/db.js";

const useStyles = makeStyles((theme) => ({
  deleteButton: {
    backgroundColor: "#000000",
    color: "#ffffff",
  },
}));

export function ItemDelete(props) {
  const classes = useStyles();
  const auth = useAuth();

  const { item, mode, event, lot } = props;

  const isOwner = auth.user.id && auth.user.id === item.owner;

  const isAuctioneer = auth.user.role && auth.user.role === "auctioneer";

  const [canConsign, setCanConsign] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const isPaid = lot && lot.paid === true ? true : false;

  //const item.accepted ===  null || item.accepted === undefined ? "Submit" : "Resubmit"}
/*
  const {
    data: auctionData,
    status: auctionStatus,
    error: auctionError,
  } = useEvent(item && item.auction ? item.auction : undefined);
*/

const isKnockedDown =
lot && lot.knockedDownAt && lot.knockedDownAt !== false ? true : false;

  const isAccepted =
    item && item.accepted === undefined ? false : item.accepted;
  const wasConsigned =
    item && item.wasConsigned === undefined ? false : item.wasConsigned;
  const isConsigned =
    item && item.consigned === undefined ? false : item.consigned;

    const isWithdrawn =
    item && item.withdrawn === undefined ? false : item.withdrawn;


    const canEdit =
    (isConsigned && item.accepted !== true && isAuctioneer) ||
    ((!isConsigned || (isConsigned && item.accepted === false)) &&
      (isOwner || isAuctioneer)) ||
    item.listingType === "marketplace_price" || (isKnockedDown && !isPaid);

// You can delete an item if:
// You are the collection owner
// You are the item owner and it is not consigned
// You are the item owner and it is rejected
// You are the item owner and it is neither accepted or rejected

    // It is consigned and not yet accepted or rejected.
    // It has been consigned and accepted
    // It has not already been withdrawn

    //const canWithdraw = !isWithdrawn && ((isConsigned && item.accepted === null) || (isConsigned && isAccepted)) 


// Old code before the below change
//const canDelete = isAuctioneer || (isOwner && ((!isConsigned || isWithdrawn) && !isAccepted))


//New Code MT to address being able to delete an item if it is withdrawn of is a marketplace item

const canDelete = isAuctioneer || (isOwner && (!isConsigned || item.accepted === null || (!isAccepted) || isWithdrawn || canEdit) )


useEffect(() =>{

// console.log("auctionData",auctionData);

// (!isAuctioneer && item.accepted === true
// isStarted.
var c=true;
if (event && isPastTime(event.startTime)) {
  c=(false);
}

setCanConsign(c);
 
}, [event])

  // Consigned the item and allow it to be withdrawn
  // if the item has not yet been accepted.

  const handleDeleteItem = (item) => {
    if (isOwner) {

  // Uncomment this to activate deleting. ********************************
      deleteItem(item.id)

      var message = "Owner deleted item from the sale.";

  
      createMessage({
        subject: message,
        item: item.id,
        from: auth.user.id,
        to: "auctioneer",
      });
    } else {
      alert("You must be the Owner of the item to use this feature");
    }
  };

if (mode === 'view') {return (<></>)}

  if (!isOwner) {
    return null;
  }

  return (
    <>

{isOwner ? (<>
        <IconButton
        
        variant='outlined'
        disabled={!canDelete}
          checked={item.consigned}
          onClick={() => {setConfirmOpen(true)
           
          }}
          name="checkedA"
          inputProps={{ "aria-label": "secondary checkbox" }}
        >
          <Delete />
        </IconButton>
        <ConfirmDialog
        title="Delete Item?"
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={() =>{handleDeleteItem(item)}}
      >
        Are you sure you want to delete this item?
      </ConfirmDialog></>
      ) : null} 
 
    </>
  );
}

export default requireAuth(ItemDelete);


