import { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import { useForm } from "react-hook-form";

import { makeStyles } from "@material-ui/core/styles";
import { isPastTime } from "../util/time.js";

import BuyItemButton from "./../components/BuyItemButton";
import AddToCartButton from "./../components/AddToCartButton";
import OfferItemButton from "./OfferItemButton.js";
import { getFunctions, httpsCallable } from "firebase/functions";
import EmailSellerModal from "./EmailSellerModal.js";

const functions = getFunctions();

const processBid = httpsCallable(functions, "processBid");
const processKnockDown = httpsCallable(functions, "processKnockDown");

const useStyles = makeStyles((theme) => ({
  root: {
    border: "none",
    boxShadow: "none",
    background: "none",
    // maxWidth: 345,
    // display: "flex",
    // "& > * + *": {
    //   marginLeft: theme.spacing(2),
    // },
  },

  formControl: {
    background: "white",
    minWidth: 140,
  },

  content: {
    paddingLeft: "0px",
    paddingBottom: 24,
  },

  button: {
    fontWeight: "600",
    paddingTop: "15px",
    paddingBottom: "15px",
    marginTop: "10px",
    fontSize: "1.25em",
  },

  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: "theme.transitions.duration.shortest",
    }),
  },

}));

function BuyItem(props) {


  const { item, lot } = props;


  var { palette } = item;

  if (palette === undefined) {
    palette = { darkMuted: "#ffffff" };
  }
useEffect(() =>{

  console.log("BuyItem item",item)

}, [item])

useEffect(() =>{

  console.log("BuyItem lot",lot)

}, [lot])

  const {
    register,
    handleSubmit,
    errors,
    control,
    getValues,
    watch,
    reset,
    setValue,
  } = useForm();

  const isKnockedDown =
    lot && lot.knockedDownAt && lot.knockedDownAt !== false ? true : false;

 
  // Calculate and set the Buyer's Next Bid.


  const [expanded, setExpanded] = useState(false);
  const [isLotEnded, setIsLotEnded] = useState(false);

  const isBiddable = false;
/*
  const {
    data: auction,
    status: auctionStatus,
    error: auctionError,
  } = 
useAuctionByEvent(item.auction);

  var auctionState = auction && auction.state ? auction.state : null;

  var isMessagingOn = false;

  var isAuctionRunning = auction && auction.state;
*/




  const watchAmount = watch("amount");

  // useEffect(() => {
  //   if (watchAmount === undefined) {
  //     return;
  //   }
  //   setBuyerBidNext(watchAmount);
  // }, [watchAmount]);

  useEffect(() => {
    const timer2 = setInterval(() => {
      // console.log("tick")
      if (lot === undefined || lot === null) {
        return;
      }
      if (isLotEnded === true) {
        clearInterval(timer2);
      }
      if (isKnockedDown) {
        return;
      }

      if (isPastTime(lot.endsAt)) {
        console.log("BidItem call handleKnockDown");
        handleKnockDown();
        setIsLotEnded(true);
      }
    }, 1000);
    // Clear timeout if the component is unmounted
    return () => clearTimeout(timer2);
  }, [lot]);

  function handleKnockDown() {
    processKnockDown({ item: item.id })
      .then((result) => {
        console.log(result);
        // Read result of the Cloud Function.
        //var sanitizedMessage = result.data.text;
      })
      .catch((error) => {
        console.log(error);

        // ...
      });
  }
  useEffect(() => {
    console.log("BuyItem isLotEnded", isLotEnded);
  }, [isLotEnded]);



  useEffect(()=>{

    console.log("BuyItem start")
    
    },[])


  return (
    <>
 
      {!isLotEnded && ( 
  
        <>
{lot.type === 'declining_price' && (<>
          <Box pb={1}>       
          <AddToCartButton item={item} lot={lot} />
          </Box>
        
          <BuyItemButton item={item} lot={lot} />
        
</>)}




{item.listingType === 'marketplace_price' && (<>
  <Box pb={1}>      

  <EmailSellerModal item={item} />
{/*    
          <AddToCartButton item={item} lot={lot} /> */}
          </Box>
          {/* <BuyItemButton item={item} lot={lot} /> */}
          {/*
          <OfferItemButton item={item} lot={lot} />
          <FullOfferItemButton item={item} lot={lot} />
*/}
          </>)}

        </>
      )}
    </>
  );
}

export default BuyItem;
