import { Box, Button, Typography } from "@material-ui/core";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { isPastTime } from "../util/time";
import { useCart, useInCart } from "../util/cart";

import LotPrice from "./LotPrice";

const BuyItemButton = (props) => {
  const { item, lot } = props;
  const { addItemLot } = useCart();

  const { found: itemAvailable } = useInCart(item);

  //const [itemAvailable, setItemAvailable] = useState();
  const isLotEnded = lot && isPastTime(lot.endsAt);
  const isLotStarted = lot && isPastTime(lot.startsAt);

  const isKnockedDown =
    lot && lot.knockedDownAt && lot.knockedDownAt !== false ? true : false;

  const isDelisted =
    lot && lot.delistedAt && lot.delistedAt !== false ? true : false;

  const canBuy =
    !itemAvailable &&
    !isKnockedDown &&
    !isDelisted &&
    isLotStarted &&
    !isLotEnded &&
    lot &&
    lot.type &&
    lot.type === "declining_price";

  useEffect(() => {
    console.log("BuyItemButton start");
  }, []);

  function addItemToCart(item) {
    addItemLot(item, lot);
    // window.scrollTo({ top: 0});
  }

  return (
    <>
      <Box
        sx={{
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/* <img src={image} /> */}

        <Button
          onClick={() => addItemToCart(item)}
          //onClick={() => addItem({ ...conditionedItem })}
          // disabled={!canBuy}
          component={Link}
          to={{ pathname: `/cart`, state: { scrollToTop: true } }}
          // to={`/cart`}
          variant="contained"
          color="primary"
          style={{ color: "white" }}
          // style={{ backgroundColor: item.palette.darkVibrant, color:'white' }}
          // className={classes.button}
          size="large"
          elevation={0}
          fullWidth
        >
          {itemAvailable !== undefined && lot && (
            <>
   <div style={{ display: "inline-flex", alignItems: "center" }}>
  <Typography variant="subtitle1" style={{ marginRight: "16px", color:'#ffffff' }}>
    Buy Now
  </Typography>
  <Typography variant="h6" style={{ color: "#ffffff" }}>
    <LotPrice lot={lot} flavour="today_price" />
  </Typography>
</div>
            </>
          )}
        </Button>
      </Box>
    </>
  );
};

export default BuyItemButton;

// {
//     name: 'Bananas',
//     sku: 'sku_GBJ2Ep8246qeeT',
//     price: 400,
//     image: 'https://www.fillmurray.com/300/300',
//     currency: 'USD',
//   },
