import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import { Typography, Box, Container, Grid } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";

const useStyles = makeStyles((theme) => ({
  // list: {
  //   width: '380px',
  //   [theme.breakpoints.down("xs")]: {
  //     width: '100vw'
  //   },
  // },
  fullList: {
    width: "auto",
  },
  fontFamily: {
    fontFamily: '"Cormorant Garamond", "serif"',
    // fontWeight: 500,
  },

  toolbar: {
    justifyContent: "space-between",
  },
  title: {
    flexGrow: 1,
    textAlign: "center",
  },
  linebreak: {
    fontWeight: 300,
  },
  
  paper: {
    width: "380px",
    borderRadius: "15px 0px 0px 15px",
    [theme.breakpoints.down("xs")]: {
      borderRadius: "0px",
      width: "100vw",
    },
  },
}));

export default function PriceDropGuideDrawer(props) {
  const classes = useStyles();
  const { event } = props;

  const [state, setState] = React.useState({
    // top: false,
    // left: false,
    // bottom: false,
    right: false,
  });

  const removeMd = require("remove-markdown");
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const items = [
    {
      description: "A starting price and lowest price is set by the seller.",
      number: 1,
      iconColor: "primary.main",
    },
    {
      description: (
        <>
          Each day for the duration of the sale, the price will drop,
          sometimes by hundreds or thousands of dollars a day.
        </>
      ),
      number: 2,
      iconColor: "primary.main",
    },
    {
      description: (
        <>
          The price decreases everyday, until someone (hopefully you) buys it.
          Or put more succinctly, the first person to hit the buy button and
          make a payment, gets it.
        </>
      ),
      number: 3,
      iconColor: "primary.main",
    },
  ];

  useEffect(() => {
    console.log("event", event);
  }, [event]);

  return (
    <div>
      {["right"].map((anchor) => (
        <React.Fragment key={anchor}>



          <div
            style={{cursor:'pointer', textDecoration:'underline dotted'}}
            onClick={toggleDrawer(anchor, true)}
        
          >

              Learn More
      
          </div>


            {/* <IconButton style={{padding:'0px'}} onClick={toggleDrawer(anchor, true)}>
    
              <InfoOutlinedIcon />
              </IconButton>
        */}


          <Drawer
            classes={{
              paper: classes.paper,
            }}
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            <Toolbar className={classes.toolbar}>
              <Typography className={classes.title}>
                Price Drop Guide
              </Typography>
              <IconButton onClick={toggleDrawer(anchor, false)}>
                <CloseIcon />
              </IconButton>
            </Toolbar>
            <Box pb={1}>
              <Divider />
            </Box>

            {/* <Box justifyContent="center" display="flex">
              <img height="200px" src={clock} />
            </Box> */}
            <Container>
              {/* <Typography
                variant="h4"
                gutterBottom
                style={{ fontFamily: '"Cormorant Garamond", "serif"' }}
              >
                An expeditious guide to LadyBidwell's fabulous declining price
                sales
              </Typography> */}

              {items.map((item, index) => (
                <Grid
                  className={classes.row}
                  item={true}
                  container={true}
                  direction="row"
                  justify="center"
                  alignItems="center"
                  spacing={2}
                  key={index}
                >
                  <Grid item={true} xs="auto">
                    <Box
                      // color={item.iconColor}
                      display="flex"
                      justifyContent="center"
                      fontSize={30}
                      width={30}
                      height={30}
                    >
                      {item.number}
                    </Box>
                  </Grid>
                  <Grid item={true} xs={true}>
                    <Typography
                      variant="h4"
                      className={classes.title}
                      gutterBottom={true}
                    >
                      {item.title}
                    </Typography>
                    <Typography variant="body1" className={classes.linebreak}>
                      {item.description}
                    </Typography>
                    <Typography
                      variant="h4"
                      gutterBottom
                      style={{ fontFamily: '"Cormorant Garamond", "serif"' }}
                    ></Typography>
                  </Grid>
                </Grid>
              ))}

              {/* <Typography variant="body1" className={classes.linebreak}>
                LadyBidwell is not one to do like others do, the same goes for
                her auctions.
              </Typography>
              LadyBidwell is not one to do like others do, the same goes for her
              auctions. <p />
              <b>Here's how it works.</b>
              <p />
              <b>1.</b> A lowest price and starting price is set by the seller.
              <p />
              <b>2.</b> Each day for the duration of the auction, the price will
              drop, sometimes by hundreds or thousands of dollars a day.
              <p />
              <b>3.</b> The price will decrease everyday, until someone
              (hopefully you) buys it. Or put more succinctly, the first person
              to hit the buy button and make a payment, gets it.
              <p />
              <b>
                So when you see a price that strikes your fancy (wallet), act
                quick, because it might also be someone elses fancy.{" "}
              </b> */}
            </Container>
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
