import React, { useEffect, useState } from "react";

import ItemCard from "./ItemCard";
import { debugFlag } from "./../util/dev.js";
import { useAuth } from "./../util/auth.js";

function isItemVisible(item) {

  return item.name !== undefined && item.accepted === true;
}

const ItemCards = (props) => {
  const { gridType, items, flavour, searchQuery, maxItems:inputMaxItems } = props;

  const auth = useAuth();

  const maxItems = inputMaxItems == null ? items.length : inputMaxItems;

  const isDeveloper = auth?.user?.developer?.enabled;
  const [visibleCards, setVisibleCards] = useState();

  const [matchedItems, setMatchedItems] = useState([]);

  const [metaCards, setMetaCards] = useState({});

  function handleIsCardVisible(c) {
    console.log("ItemCards handleIsCardVisible c", c);

    const _metaCards = metaCards;

    _metaCards[c.id] = c.visibility;
    setMetaCards({ ..._metaCards });
  }

  useEffect(() => {
    const keys = Object.keys(metaCards);

    if (keys == null) {
      console.log("ItemCards null keys");
      return;
    }
    if (!Array.isArray(keys)) {
      console.log("ItemCards Not array keys");
      return;
    }

    console.log("ItemCards keys", keys);
    const matchCount = keys.filter((key) => {
      return metaCards[key];
    }).length;

    setVisibleCards(matchCount);
    if (props.onCardCount) {
      props.onCardCount(matchCount);
    }
  }, [metaCards]);

  return (
    <>
      {debugFlag && <>ITEM CARDS COUNT{' '}{visibleCards}<p/></>}

      {items &&
        items
          .filter((item) => isItemVisible(item))
          .slice(0,maxItems)
          .map((item, index) => (        
         
            <ItemCard
              key={gridType + "_itemcards_" + item.id}
              item={item}
              flavour={flavour}
              gridType={gridType}
              searchQuery={searchQuery}
              isCardVisible={(c) => handleIsCardVisible(c)}
            />
        
          ))}
    </>
  );
};

export default ItemCards;
