import React, { useState, useEffect } from "react";
import { Divider, Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";

import { useAuth } from "./../util/auth.js";

import { makeStyles } from "@material-ui/core/styles";
import { isPastTime } from "../util/time";
import LotOrder from "./../components/LotOrder.js";
import {
  firebaseTime,
} from "./../util/time.js";
import { getPrice, getFormattedPrice } from "./../util/price";
import { debugFlag } from "../util/dev.js";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
  },

  content: {
    paddingBottom: 24,
  },

  cardHighlightedFont: {
    fontWeight: "700",
  },

  letterSpacing: {
    letterSpacing: "0.08em",
  },

  priceBox: {
    background: "#f2efef",
   
    borderRadius: "4px",
    // width:'fit-content',
    // margin:'0 auto',
  },
}));

function LotPrice(props) {
  const classes = useStyles();
  const auth = useAuth();
  // const isAuctioneer = auth.user.role && auth.user.role === "auctioneer";

  const { lot, item } = props;
  const { flavour } = props;
  const { spice } = props;

  /*
Provide access to three lot status variabes updated via hook.
  isLotStarted: isLotStarted,
  isPaid: isPaid,
  isKnockedDown: isKnockedDown,
  */

  const [price, setPrice] = useState();

  const isKnockedDown =
    lot && lot.knockedDownAt && lot.knockedDownAt !== false ? true : false;

  const isDelisted =
    lot && lot.delistedAt && lot.delistedAt !== false ? true : false;

  const isPaid = lot && lot.paid && lot.paid === true ? true : false;

  const isLotStarted = lot && isPastTime(lot.startsAt);


  // Use this to determine the type of price to be generated
  // declining_price / increasing_bid
  //const { data: lotData, status: lotStatus, error: lotError } = useLotByItem(item.id);
  // const { data: pricesData, status: pricesStatus, error: pricesError } = usePricesByItem(item.id);

  /*
Some notes for the future..

const spices = [{variant:"XXX", otherThing:"YYY"},
{variant:"XXX", otherThing:"YYY"},
]

Read prop and set these.
Otherwise for now apply spice rule to parts of this code which show up in ItemCard component.
Unspiced rule is prime.

*/

  const lotStatus = "success";
  //const prices = pricesData && pricesData.prices;
  const prices = lot && lot.prices ? lot.prices : false;

  useEffect(() => {
    if (lot === undefined) {
      return;
    }

    if (lot === null) {
      return;
    }
    //if (!("prices" in lot)) {return;}

    if (!lot.prices) {
      return;
    }

    setPrice(getPrice(lot));
  }, [lot]);

  useEffect(() => {
    if (price === undefined) {
      return;
    }
    console.log("prices price", price);
  }, [price]);

  if (lot && lot.prices && flavour === "checkout") {
    return <>Checkout Price</>;
  }

  if (lotStatus !== "success") {
    return null;
  }

  /////////////////////////////////////// delisted (withdrawn)

  if (isKnockedDown && isDelisted) {
    if (spice && spice.includes("item_card")) {
      return (
        <>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            py={0.5}
            className={classes.priceBox}
          >
            <Typography
              className={classes.cardHighlightedFont}
              variant="subtitle1"
              color="primary"
            >
              Withdrawn
            </Typography>
          </Box>
        </>
      );
    }


      if (spice && spice.includes("simple")) {
        return (<>Withdrawn</>)
      };
    

    return (
      <>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>
            <Typography
              variant="subtitle1"
              className={classes.cardHighlightedFont}
            >
              Withdrawn
            </Typography>
          </Box>
        </Box>
      </>
    );
  }

  /////////////////////////////////////// unsold price

  if (isKnockedDown && !(lot && lot.bidAmount)) {
    if (spice && spice.includes("item_card")) {
      return (
        <>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            py={0.5}
            className={classes.priceBox}
          >
            <Typography
              className={classes.cardHighlightedFont}
              variant="subtitle1"
              color="primary"
            >
              Unsold
            </Typography>
          </Box>
        </>
      );
    }

    if (isKnockedDown && !(lot && lot.bidAmount)) {
      if (spice && spice.includes("simple")) {
        return (<>Unsold</>)
      };
    }

    return (
      <>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>
            <Typography variant="h6" className={classes.cardHighlightedFont}>
              Unsold
            </Typography>
          </Box>
        </Box>
      </>
    );
  }

  ///////////////////////// sold price

  if (
    (isPaid || isKnockedDown || flavour === "sold") &&
    spice &&
    spice.includes("order")
  ) {
    if (spice && spice.includes("item_card")) {
      return (
        <>
          <Box display="flex" alignItems="center">
            <Box>
              <Typography
                variant="subtitle1"
                color="primary"
                style={{ fontWeight: "700" }}
              >
                Price paid:
              </Typography>
            </Box>

            <Typography
              className={classes.cardHighlightedFont}
              variant="subtitle1"
              color="primary"
            >
              <span>
                &nbsp;{getFormattedPrice("USD", lot && lot.bidAmount)}
              </span>
            </Typography>
          </Box>

          <Box py={1}>
            <Divider />
          </Box>
          <LotOrder lot={lot} />
        </>
      );
    }
  }

  ///////////////////////// sold price

  if (
    (isPaid || isKnockedDown || flavour === "sold") &&
    spice &&
    spice.includes("simple")
  ) {
    if (spice && spice.includes("simple")) {
      return <>{getFormattedPrice("USD", lot && lot.bidAmount)}</>;
    }
  }

  ///////////////////////// sold price

  if (isPaid || isKnockedDown || flavour === "sold") {

    if (spice && spice.includes("simple")) {
      return (<>SOLD{" "}
      <span style={{ textDecoration: "line-through" }}>
        {" "}
        {getFormattedPrice("USD", lot && lot.bidAmount)}
      </span></>)
    };

    if (spice && spice.includes("item_card")) {
      return (
        <>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            py={0.5}
            className={classes.priceBox}
            style={{background: '#cab5f863' }}
          >
            <Typography
              variant="subtitle1"
              color="textSecondary"
              style={{ fontWeight: "700" }}
            >
              SOLD{" "}
              <span style={{ textDecoration: "line-through" }}>
                {" "}
                {getFormattedPrice("USD", lot && lot.bidAmount)}
              </span>
            </Typography>
          </Box>
        </>
      );
    }



    //////////////////////// sold (but above conditions not met.)

    return (
      <>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>
            <Typography variant="h6" style={{ fontWeight: "600" }}>
              Sold &nbsp;
              <span style={{ textDecoration: "line-through" }}>
                {" "}
                {getFormattedPrice("USD", lot && lot.bidAmount)}
              </span>
            </Typography>
          </Box>
        </Box>

  
      </>
    );
  }


    ///////////////////////// marketplace price



    if (flavour === "marketplace_price" && (item.soldStatus === "unsold" || item.soldStatus == null))  {
      if (item && item.sellerStartingPrice == null) {
        return null;
      }
  
      if (spice && spice.includes("item_cardhorizontal")) {
        return (
          <>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              py={0.5}
              className={classes.priceBox}
              style={{marginLeft:'-5px', marginRight:"-5px"}}
            >
              <Typography variant="subtitle2" style={{paddingLeft:'5px'}} >
               Price&nbsp;
              </Typography>
  
              <Typography
                // className={classes.letterSpacing}
                variant="subtitle1"
                color="primary"
                style={{ fontWeight: "700", paddingRight:'5px' }}
              >
                {
                    item?.sellerStartingPrice
                  }
              </Typography>
            </Box>
          </>
        );
      }
  
  
      if (spice && spice.includes("item_card")) {
        return (
          <>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              py={0.5}
              className={classes.priceBox}
            >
              <Typography variant="subtitle2" className={classes.letterSpacing}>
                Price: &nbsp;
              </Typography>
  
              <Typography
                className={classes.letterSpacing}
                variant="subtitle1"
                color="primary"
                style={{ fontWeight: "700" }}
              >
             {
                   ("$" + item?.sellerStartingPrice)
                  }
              </Typography>
            </Box>
          </>
        );
      }
  
     if (spice && spice.includes("simple")) {
       return(<>
 
       {("$" + item?.sellerStartingPrice)}
   </>)  }
      
      return (
        <>
          <Box display="flex" alignItems="center">
            <Box flexGrow={1}>
              <Typography variant="h6" style={{ fontWeight: "700" }}>
                Price
              </Typography>
            </Box>
            <Typography variant="h5" style={{ fontWeight: "600" }}>
          
              {("$" + item?.sellerStartingPrice)}
              
            </Typography>
          </Box>
        </>
      );
    }


    if (flavour === "marketplace_price" && item?.soldStatus === "sold" ) {
      if (item && item.sellerStartingPrice == null) {
        return null;
      }
  
      if (spice && spice.includes("item_cardhorizontal")) {
        return (
          <>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              py={0.5}
              className={classes.priceBox}
              style={{marginLeft:'-5px', marginRight:"-5px"}}
            >
              <Typography variant="subtitle2" style={{paddingLeft:'5px'}} >
            Sold
              </Typography>
  
        
            </Box>
          </>
        );
      }
  
  
      if (spice && spice.includes("item_card")) {
        return (
          <>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              py={0.5}
              className={classes.priceBox}
            >
              <Typography variant="subtitle2" className={classes.letterSpacing}>
             Sold
              </Typography>
  
            </Box>
          </>
        );
      }
  
     if (spice && spice.includes("simple")) {
       return(<>
 
      Sold
   </>)  }
      
      return (
        <>
          <Box display="flex" alignItems="center">
            <Box flexGrow={1}>
              <Typography variant="h6" style={{ fontWeight: "700" }}>
          Sold
              </Typography>
            </Box>
  
          </Box>
        </>
      );
    }

  ///////////////////////// starting price

  if (flavour === "starting_price") {
    if (lot && lot.startingPrice == null) {
      return null;
    }

    if (spice && spice.includes("item_cardhorizontal")) {
      return (
        <>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            py={0.5}
            className={classes.priceBox}
            style={{marginLeft:'-5px', marginRight:"-5px"}}
          >
            <Typography variant="subtitle2" style={{paddingLeft:'5px'}} >
              Starting Price&nbsp;
            </Typography>

            <Typography
              // className={classes.letterSpacing}
              variant="subtitle1"
              color="primary"
              style={{ fontWeight: "700", paddingRight:'5px' }}
            >
              {price &&
                price.current &&
                getFormattedPrice(
                  "USD",
                  lot.startingPrice
                )}
            </Typography>
          </Box>
        </>
      );
    }


    if (spice && spice.includes("item_card")) {
      return (
        <>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            py={0.5}
            className={classes.priceBox}
          >
            <Typography variant="subtitle2" className={classes.letterSpacing}>
              Starts at&nbsp;
            </Typography>

            <Typography
              className={classes.letterSpacing}
              variant="subtitle1"
              color="primary"
              style={{ fontWeight: "700" }}
            >
              {price &&
                price.current &&
                getFormattedPrice(
                  "USD",
                  lot.startingPrice
                )}
            </Typography>
          </Box>
        </>
      );
    }

   if (spice && spice.includes("simple")) {
     return(<>
     {getFormattedPrice("USD", lot.startingPrice)}
 </>)  }
    
    return (
      <>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>
            <Typography variant="h6" style={{ fontWeight: "700" }}>
              Starting Price
            </Typography>
          </Box>
          <Typography variant="h5" style={{ fontWeight: "600" }}>
            {getFormattedPrice("USD", lot.startingPrice)}
          </Typography>
        </Box>
      </>
    );
  }

  ////////////// ending price

  if (flavour === "ending_price") {
    if (lot.prices[lot.price.length - 1]) {
       return null;
    }

    if (spice && spice.includes("item_card")) {
      return (
        <>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            py={0.5}
            className={classes.priceBox}
          >
            <Typography
              className={classes.letterSpacing}
              variant="h6"
              color="primary"
              style={{ fontWeight: "700" }}
            >
              {lot &&
                lot.prices &&
                getFormattedPrice("USD", lot.prices[lot.price.length - 1])}
            </Typography>

            <Typography variant="subtitle2" color="primary">
              LAST OFFER
            </Typography>
          </Box>
        </>
      );
    }

    return (
      <>
        <Box display="flex" alignItems="center" py={0.5}>
          <Box flexGrow={1}>
            <Typography variant="h6" style={{ fontWeight: "700" }}>
              LAST OFFER
            </Typography>
          </Box>
          <Typography variant="h6" style={{ fontWeight: "600" }}>
            {lot &&
              lot.prices &&
              getFormattedPrice("USD", lot.prices[lot.price.length - 1])}
          </Typography>
        </Box>
      </>
    );
  }

  ///////////////////////// no price found

  if (price === false) {
     return null;
  }

  if (price == null) {
     return null;
  }


  ///////////////////////// yesterday's price

  if (flavour === "yesterday_price") {

    if (price && price.yesterday == null) {
      return null;
    }


    if (spice && spice.includes("item_card")) {
      return (
        <>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            py={1}
          >
            <Typography
              className={classes.letterSpacing}
              color="textSecondary"
              variant="subtitle2"
              style={{ fontSize: ".85em" }}
            >
              {price &&
                price.yesterday &&
                getFormattedPrice("USD", price.yesterday.amount)}{" "}
              YESTERDAY
            </Typography>
          </Box>
        </>
      );
    }

    <Box display="flex" alignItems="center" justifyContent="center">
      <Box flexGrow={1}>
        <Typography variant="h6" style={{ fontWeight: "300" }}>
          Yesterday
        </Typography>
      </Box>
      <Typography variant="h6" style={{ fontWeight: "300" }}>
        {price &&
          price.yesterday &&
          getFormattedPrice("USD", price.yesterday.amount)}
      </Typography>
    </Box>;

    return (
      <>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Box flexGrow={1}>
            <Typography
              color="textSecondary"
              variant="body1"
              style={{ fontWeight: "300" }}
            >
              Yesterday
            </Typography>
          </Box>
          <Typography
            color="textSecondary"
            variant="body1"
            style={{ fontWeight: "300" }}
          >
            {price &&
              price.yesterday &&
              getFormattedPrice("USD", price.yesterday.amount)}
          </Typography>
        </Box>

        {/* 
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>
            <Typography className={classes.letterSpacing} style={{ fontWeight: "300" }} variant="h6">
              Yesterday  {price &&
              price.yesterday &&
              getFormattedPrice("USD", price.yesterday.amount)}
            </Typography>
          </Box>
   
        </Box> */}
      </>
    );
  }

  ///////////////////////// plain current price

  if (flavour === "today_price") {
    if (price && price.current == null) {
      return null;
    }


    if (spice && spice.includes("item_card")) {
      return (
        <>
          <Box display="flex" alignItems="center">
            <Box flexGrow={1}>
              <Typography variant="subtitle2">Today's Price</Typography>
            </Box>
            <Typography variant="subtitle2">
              {price &&
                price.current &&
                getFormattedPrice("USD", price.current.amount)}
            </Typography>
          </Box>
        </>
      );
    }

    return (
      <>
      {price &&
          price.current &&
          getFormattedPrice("USD", price.current.amount)}
      </>
    );
  }

  ///////////////////////// tomorrow's price

  if (flavour === "tomorrow_price") {
    if (price && price.tomorrow == null) {
      return null;
    }

    if (spice && spice.includes("item_card")) {
      return (
        <>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            py={1}
          >
            <Typography
              variant="subtitle2"
              className={classes.letterSpacing}
              style={{ fontSize: "0.85em" }}
            >
              {" "}
              {price &&
                price.tomorrow &&
                getFormattedPrice("USD", price.tomorrow.amount)}{" "}
              TOMORROW
            </Typography>
          </Box>
        </>
      );
    }

    return (
      <>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>
            <Typography variant="body1" style={{ fontWeight: "300" }}>
              Tomorrow
            </Typography>
          </Box>
          <Typography variant="body1" style={{ fontWeight: "300" }}>
            {price &&
              price.tomorrow &&
              getFormattedPrice("USD", price.tomorrow.amount)}
          </Typography>
        </Box>
      </>
    );
  }

  ///////////////////////// horizon price rules

  if (flavour === "horizon_price") {
    console.log("LotPrice price", price, prices, lot && lot.item);

    return (
      <>
      
        <div>
          {/* Yesteray {price && price.yesterday}<br /> */}
          {/* Current {price && price.current}<br /> */}
          {/* Tomorrow {price && price.tomorrow}<br /> */}
        </div>


      {/* before start of auction */}

      {!isLotStarted && (<>

<LotPrice flavour={"starting_price"} spice={spice} lot={lot} />

</>)}

        {/* start of auction */}





        {isLotStarted && price &&
          price.yesterday === false &&
          price.current !== false &&
          lot.prices.length !== 1 && (
            <>
              {debugFlag && <>start of auction</>}
              {/* <LotPrice flavour={"starting_price"} spice={spice} lot={lot} /> */}
              <LotPrice flavour={"current_price"} spice={spice} lot={lot} />
              <LotPrice flavour={"tomorrow_price"} spice={spice} lot={lot} />
              {/* <LotPrice flavour={"tomorrow_price"} spice={spice} lot={lot} /> */}
            </>
          )}

        {/* auction day 2 onwards */}

        {isLotStarted && price &&
          price.yesterday !== false &&
          price.current !== false &&
          price.tomorrow !== false && (
            <>
              {debugFlag && <> day 2 onwards</>}
              <LotPrice flavour={"yesterday_price"} spice={spice} lot={lot} />
              <LotPrice flavour={"current_price"} spice={spice} lot={lot} />
              <LotPrice flavour={"tomorrow_price"} spice={spice} lot={lot} />
            </>
          )}

        {/* auction final day */}
        {isLotStarted && price && price.tomorrow === false && (
          <>
            {debugFlag && <>LAST DAY TO BUY</>}
            {lot.prices.length > 1 && (
              <LotPrice flavour={"yesterday_price"} spice={spice} lot={lot} />
            )}
            <LotPrice flavour={"current_price"} spice={spice} lot={lot} />
          </>
        )}
      </>
    );
  }

  if (flavour === "current_price") {
    if (price && price.current == null) {
      return null;
    }


    if (spice && spice.includes("item_card")) {
      // Item card needs a special spice. A specific treatment.
      return (
        <>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            py={0.5}
            className={classes.priceBox}
          >
            <Typography
              className={classes.letterSpacing}
              variant="h6"
              color="primary"
              style={{ fontWeight: "700" }}
            >
              {price &&
                price.current &&
                getFormattedPrice(
                  "USD",
                  price && price.current && price.current.amount
                )}
            </Typography>

            <Typography variant="subtitle2" className={classes.letterSpacing}>
              &nbsp; NOW
            </Typography>
          </Box>
        </>
      );
    }

    if (flavour === "current_price") {
      if (price && price.current == null) {
        return null;
      }


      if (spice && spice.includes("simple")) {
        return <>{price &&
          price.current &&
          getFormattedPrice(
            "USD",
            price && price.current && price.current.amount
          )}</>;
      }

      if (spice && spice.includes("marketplace_price")) {
        return <><b>{price &&
          price.current &&
          getFormattedPrice(
        
            "USD",
            price && price.current && price.current.amount
    
          )}</b></>;
      }

    }

    if (price && price.current == null) {
      return null;
    }


    return (
      <>
        <Box display="flex" alignItems="center">

        {spice && spice.includes("item_cardhorizontal") ? (
          <>

        <Box flexGrow={1} className={classes.priceBox} style={{marginLeft:'-5px', }}>
    
            <Typography variant="body1" style={{ fontWeight: "600", paddingLeft:'5px' }}>
              Now  
            </Typography>
            </Box>


          <Typography className={classes.priceBox}  variant="body1" style={{ fontWeight: "600", marginRight:'-5px', paddingRight:'5px'}}>
          {price &&
            price.current &&
            getFormattedPrice(
              "USD",
              price && price.current && price.current.amount
            )}
        </Typography>   
    
   
        </>
        )
:
<>
          <Box flexGrow={1}  >
            <Typography variant="h6" style={{ fontWeight: "600" }}>
              Now 
            </Typography>
          </Box>
          <Typography variant="h6" style={{ fontWeight: "600" }}>
            {price &&
              price.current &&
              getFormattedPrice(
                "USD",
                price && price.current && price.current.amount
              )}
          </Typography>   
          
          </>
          }
        </Box>

           

      </>
    );
  }

  if (flavour === "history_price") {
    return (
      <>
        <div>
          PRICE HISTORY
          {prices && prices.map((_price) => {
            return (
              <>
                <div>
                  {_price && _price.amount}
                  {" -- "}
                  {firebaseTime(_price.validAt)}
                  <br />
                </div>
              </>
            );
          })}
        </div>
      </>
    );
  }
  return <>ALL SPICE</>;
}

export default LotPrice;
