import React, { useState, useEffect } from "react";
import { useAuth } from "./../util/auth.js";
import {} from "./../util/db.js";
import { makeStyles } from "@material-ui/core/styles";

import {
  getPaymentMethodsByCustomer,
} from "../util/stripe.js";

// import firebase from "../util/firebase";
import {
  serverTimestamp,
} from "firebase/firestore";

import PaymentCardStrip from "./../components/PaymentCardStrip";
import { Box } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { getFunctions, httpsCallable } from "firebase/functions";

const functions = getFunctions();
//const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp;

const processStripeClientSecret = httpsCallable(functions, "processStripeClientSecret");

const useStyles = makeStyles((theme) => ({
  paperItems: {
    minHeight: "300px",
  },
  featured: {
    backgroundColor:
      theme.palette.type === "dark" ? theme.palette.action.selected : "#fdf8c2",
  },
  starFeatured: {
    color: theme.palette.warning.main,
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    marginTop: "2em",
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    border: "1px solid #e0e0e0",
  },
}));

function PaymentCards(props) {
  const { customer, watch } = props;
  const { filter } = props;
  const classes = useStyles();

  const auth = useAuth();

  const [cards, setCards] = useState();
  const [refreshedAt, setRefreshedAt] = useState();

  const [newUser, setNewUser] = useState();

  //const [customerId, setCustomerId] = useState();
  // When do we request a refresh.
  /*
First load 
When a card is added. Told by client.

*/

  useEffect(() => {
    //refreshCustomerId();

    refreshPaymentMethods();
  }, []);

  /*
function getCustomerId() {

  var c = auth?.user?.payment?.customer;

  if (customer && customer.id !== undefined) {
    c = customer.id;
  }



}
*/

  // Client refresh request.
  useEffect(() => {

    refreshPaymentMethods();
  //}, [customer?.invoice?.default_payment_method]);
}, [customer?.payment_method]);

useEffect(() => {

  refreshPaymentMethods();
//}, [customer?.invoice?.default_payment_method]);
}, [auth?.user?.payment?.customer]);


useEffect(() => {
refreshPaymentMethods();
//}, [customer?.invoice?.default_payment_method]);
}, [auth?.user?.payment?.payment_method]);


// This signal is quite important to tell react client quickly
// that customer has changed.
useEffect(() => {

  // Customer changed
  // Trigger update 
console.log("customer",customer)
  refreshPaymentMethods();

}, [customer]);

  function refreshPaymentMethods() {
console.log("PaymentCards refreshPaymentMethods")
    var customerId = null;
    if (auth?.user?.payment?.customer) { customerId = auth.user.payment.customer;}

    var paymentMethod = null;
    if (auth?.user?.payment?.payment_method) { paymentMethod = auth.user.payment.payment_method;}

    //customerId = auth.user.payment.customer;

//    if (customerId === null && customer && customer.customer) {customerId = customer.customer;}
   // if (customerId === null ) {
   // const stripeCustomerId = getCustomerId();

   // if (stripeCustomerId === undefined) {return;}
   // customerId = stripeCustomerId;
   // }

  
  console.log("customerId",customerId);

    if (customerId === null) {
      props.onStatus({
        type: "info",
        message: "Welcome. You need to add a card to start bidding.",
      });
setNewUser(true);
          //  props.refreshCustomer();
      return;
    }
setNewUser(false);
    const paymentMethods = getPaymentMethodsByCustomer(customerId);

    paymentMethods
      .then((result) => {

        var foundDefault = false;
        var i = result.data.data.map((f) => {
          const card = f.card;
         // const isDefault =
         //   f.id === customer &&
          //  customer.invoice_settings &&
        //    customer.invoice_settings.default_payment_method;
       console.log(f.id, paymentMethod);
        const isDefault =
            f.id === paymentMethod;
          if (isDefault) {
            foundDefault = true;
          }
          return { ...card, payment_method: f.id, isDefault: isDefault };
        });
  
        //if (i === undefined) {i = [];}
        setCards(i);
        console.log("setCards", i)
props.refreshCards(i);
        setRefreshedAt(serverTimestamp());
console.log("foundDefault",foundDefault ? "true" : "false")
        // Messagin
        if (i.length === 0) {
          props.onStatus({
            type: "info",
            message: "You need to add a card to bid.",
          });

          return;
        }

        if (foundDefault !== true) {
          props.onStatus({
            type: "info",
            message: "You need to set a default card to bid."
                    });

          return;
        }

        if (foundDefault === true) {

         // Do not have to do anything here because the 
         // onStatus alert bay is only listening for problems.

         // props.onStatus({
         //   type: "success",
         //   message: "You are all set up."
           //         });

                    // props.onStatus({
                    //   type: "success",
                    //   message: "All set-up." });    

                      //props.onStatus({
                      //  type: "silent",
                      //  message: "" }); 

          return;
        }


        return;
      })
      .catch((error) => {
        console.log("PaymentCards error", error);
setCards(true);
        props.onStatus({
          type: "error",
          message:
            "Could not update your card details from Stripe. Try refreshing this page." +
            error,
        });

        //setIsValidUser(false);
      });
  }

  function handleRefreshCards() {
    refreshPaymentMethods();
  }
/*
  if (newUser === undefined) {
    return (
      <>
        LOADING BECUASE WE DONOT HAVE NEW USER
      
        <Box py={3}>
          <Skeleton variant="rect" height={218} />
        </Box>
        OOM
      </>
    );



  }
  */
 
  if (newUser === true) {
    return (
      <>
   
        <Box my={3} height={218} style={{ border: "1px dashed #b1b1b1" }}>

Your payment cards will appear here.

        </Box>
      </>
     
    );

  }

  if (cards === undefined) {
    return (
      <>
      CARDS UNDEFINED
      
        <Box py={3}>
          <Skeleton variant="rect" height={218} />
        </Box>
        OOM
      </>
    );
  }

  // start here tomorrow

  if (cards.length === 0 && auth?.user?.payment?.payment_method === null) {
    return (
      <>NO CARDS Loading for 1st time
        <Box my={3} height={218} style={{ border: "1px dashed #b1b1b1" }}></Box>
      </>
    );
  }


// auth?.user?.payment?.payment_method
  if (cards.length === 0 && auth?.user?.payment?.payment_method !== undefined) {
    return (
      <>NO CARDS
        <Box my={3} height={218} style={{ border: "1px dashed #b1b1b1" }}></Box>
      </>
    );
  }

  return (
    <>
      
      {cards
        ? cards.map((card, index) => (
            <>
              
              <PaymentCardStrip
                key={card.payment_method}
                card={card}
                customer={customer}
                refreshCards={() => handleRefreshCards()}
                refreshCustomer={(s) => props.refreshCustomer(s)}
                onStatus={(s) => props.onStatus(s)}
              />
           
            </>
          ))
        : null}
 
      {/* </Container>
      </Section> */}
    </>
  );
}

export default PaymentCards;

/*

            {items
              .filter((item) => (item.name !== undefined && item.accepted === true))
              .map((item, index) => (


*/
