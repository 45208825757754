import React, { useMemo } from "react";
import ShoppingBasketOutlinedIcon from "@material-ui/icons/ShoppingBasketOutlined";
import { Badge, IconButton, Tooltip } from "@material-ui/core";
import { NavLink } from "./../util/router.js";
import { useCart } from "./../util/cart.js";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  
  
  root: {
    justifyContent: "flex-start",
  },

  button: {
    padding: '8px',
    "&.active": {
      color:"#ffffff",
      background: theme.palette.secondary.main,
    },
  },

}));


export default function ShoppingBasketIcon() {


///MT ADJUSTED CODE 

const classes = useStyles();
const { data: cartData, status: cartStatus, error: error } = useCart();

const cartCount = useMemo(() => {
    if (!cartData) {
        return 0;
    }
    return cartData.cartCount || 0;
}, [cartData])

const linkTarget = {
    pathname: "/cart",
    state: {
        applied: true,
    },
};


  return (

      <>
      <Tooltip title="Cart" arrow>
        <IconButton color="inherit" to="/cart" className={classes.button} component={NavLink} style={{ marginRight: "10px" }}>
          <Badge
            badgeContent={cartCount}
            color="secondary"
            overlap="rectangular"
          >
            <ShoppingBasketOutlinedIcon />
          </Badge>
        </IconButton>
      </Tooltip>
      </>

  );
}
