import { useState, useRef } from "react";
import { Link } from "react-router-dom";
import { Box } from "@material-ui/core/";
import { Skeleton } from "@material-ui/lab";
import Typography from "@material-ui/core/Typography";

import Divider from "@material-ui/core/Divider";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useIdentity } from "./../util/identity.js";
import { useAuth } from "./../util/auth.js";
import { useAuctionByEvent, useLotByItem, useLotStatus } from "./../util/db.js";
import ItemThumbnail from "./../components/ItemThumbnail";
import { makeStyles } from "@material-ui/core/styles";

// import { forceCheck } from 'react-lazyload';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import ItemStar from "./../components/ItemStar";
import LotBid from "./../components/LotBid";
import LotPrice from "./../components/LotPrice";
import ImpressionCounter from "./ImpressionCounter.js";
import LazyLoad from "react-lazyload";
import { getSlug } from "../util/slug.js";
import TestIndicator from "./TestIndicator.js";

import { debugFlag, privateCatchallFlag } from "./../util/dev.js";

const useStyles = makeStyles((theme) => ({
  root: {
    // minWidth: 275,
    // height: 140,
    // boxShadow: "1px 0px 4px rgb(0 2 4 / 2%), 0 7px 20px rgb(1 1 1 / 3%)",
    padding: "15px",
    // backgroundColor: "#f5f5f5",

    // [theme.breakpoints.down("sm")]: {
    //   backgroundColor: "transparent",
    //   border: "1px solid #c0c0c0",
    // },
    // boxShadow: '3px 10px 16px -6px rgb(230 230 230)',
    border: "1px solid #0000001f",
    "&:hover": {
      // borderColor: "#0000001f",
      // border: "1px solid",
      // backgroundColor: '#f7f7f7',
      boxShadow: "0 0.2rem 0.3rem rgb(0 0 0 / 5%)",
    },
  },

  rootHorizontal: {
    // minWidth: 275,
    // height: 140,
    // boxShadow: "1px 0px 4px rgb(0 2 4 / 2%), 0 7px 20px rgb(1 1 1 / 3%)",
    padding: "15px",
    marginRight: theme.spacing(1),
    // backgroundColor: "#f5f5f5",

    // [theme.breakpoints.down("sm")]: {
    //   backgroundColor: "transparent",
    //   border: "1px solid #c0c0c0",
    // },
    // boxShadow: '3px 10px 16px -6px rgb(230 230 230)',
    border: "1px solid #0000001f",
    "&:hover": {
      // borderColor: "#0000001f",
      // border: "1px solid",
      // backgroundColor: '#f7f7f7',
      boxShadow: "0 0.2rem 0.3rem rgb(0 0 0 / 5%)",
    },
  },

  // cardcontent: {
  //   padding:'0px',
  //   "&:last-child": {
  //     paddingBottom: 5
  //   }
  // },

  cardheader: {
    paddingBottom: "5px",
    opacity: "0.5",
    marginBottom: "-25px",
  },

  estimate: {
    justifyContent: "space-between",
    // paddingLeft: '16px',
    // paddingRight: '16px',

    display: "flex",
    alignItems: "center",
  },

  media: {
    height: 140,
    // minHeight: '300px',
    // height: "auto",
    alignItems: "flex-end",
    width: "auto",
    justifyContent: "center",
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: 110,

      // paddingTop: '56.25%', // 16:9
    },

    // minHeight: '200px',
  },


  mediaHorizontal: {
    // height: 240,
    // minHeight: '300px',
    // height: "auto",
    alignItems: "flex-end",
    width: 240,
    justifyContent: "center",
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      // width: "100%",
      // height: 100,
      width: 150,
      // paddingTop: '56.25%', // 16:9
    },

    // minHeight: '200px',
  },

  featured: {
    backgroundColor:
      theme.palette.type === "dark" ? theme.palette.action.selected : "#fdf8c2",
  },
  starFeatured: {
    color: theme.palette.warning.main,
  },
  name: {
    overflow: "hidden",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    lineHeight: "inherit",
    WebkitBoxOrient: "vertical",
    height: "3em",
    [theme.breakpoints.up("md")]: {
      lineHeight: "1.5em",
      minHeight: "3em",
    },
  },
}));

const ItemCardUnframed = (props) => {
  const { item, flavour, event } = props;
  const classes = useStyles();
  const { isAuctioneer } = useIdentity();
  const auth = useAuth();
  const isDeveloper = auth?.user?.developer?.enabled;

  const [updatingItemId, setUpdatingItemId] = useState();
  const updatingItemIdRef = useRef({});
  updatingItemIdRef.current = updatingItemId;

  const {
    data: lot,
    status: lotStatus,
    error: lotError,
  } = useLotByItem(item && item.id);

  const itemIsEmpty = !item;

  const {
    data: auction,
    status: auctionStatus,
    error: auctionError,
  } = useAuctionByEvent(item && item.auction);

  const { isLotStarted } = useLotStatus({ ...lot, ...item });
  //const isVisible = (isOwner || isAuctioneer) || isLotStarted;
  const isCardVisible = isLotStarted;
  const handleStatus = (e) => {
    console.log("handleStatus", e);

  };


  return (
    <>
      {(lotStatus === "loading" || itemIsEmpty) && (
        <>
          {lotStatus === "loading" && (
            // <CircularProgress color="secondary" size={32} />

            <Skeleton height={288} width="100%" variant="rect" />
          )}
          <Box py={5} px={3} align="center">
            {lotStatus !== "loading" && itemIsEmpty && (
              <Typography variant="h4">Add your first item.</Typography>
            )}
          </Box>
        </>
      )}

      {lotStatus !== "loading" && item && (
        <Card
          elevation={0}
    
          className={flavour === "horizontal_grid" ? classes.rootHorizontal : classes.root}
          key={item.id}
          // variant="outlined"
          component={Link}
          to={`/item/${getSlug(item.name)}/${item.id}`}
          style={{
            borderRadius: "0.5em",
            textDecoration: "none",
            // height: "100%",
            height: "auto",
            position: "relative",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {debugFlag && <>SCORE {item.score}</>}

          {!isCardVisible && <></>}
          <LazyLoad height={300} offset={400}>
            <ImpressionCounter lot={item}>
              {/* <div className="fade"> */}
              <Box style={{ position: "absolute", right: 10, top: 10 }}>
                {/* <UserItemHeart item={item} /> */}

                {isAuctioneer ? <ItemStar item={item} /> : null}
              </Box>
              <TestIndicator item={item} />

              <CardMedia
                className={flavour === "horizontal_grid" ? classes.mediaHorizontal : classes.media }
                
                // component={Link}
                // to={`/item/${getSlug(item.name)}/${item.id}`}
              >
                <ItemThumbnail item={item} onStatus={(e) => handleStatus(e)} />
              </CardMedia>

              {/* </div> */}

              <Box pt={1}>
                <CardContent
                  style={{ padding: "0px", textDecoration: "none" }}
                  // component={Link}
                  // to={`/item/${getSlug(item.name)}/${item.id}`}
                >
                  <Box display="flex">
                    <Box flexGrow={1} pt={1}>
                      <Typography
                        variant="subtitle1"
                        color="primary"
                        gutterBottom
                        className={classes.name}
                        style={{
                          // lineHeight: "1.25em",
                          fontWeight: "400",
                          // lineHeight: '1.3em',
                          // fontFamily: '"Cormorant Garamond", "serif"',
                        }}
                      >
                        {item.name}
                      </Typography>
                    </Box>
                  </Box>
                  {privateCatchallFlag === true &&
                    event &&
                    event.private &&
                    event.private === true && (
                      <Typography variant="subtitle2" color="textSecondary">
                        Location: {event.location}
                      </Typography>
                    )}
                </CardContent>
              </Box>

              <Box py={1}>
                <Divider />
              </Box>

              <Box
                // minHeight={110}
                // height="100px"
                display="flex"
                flexDirection="column"
                justifyContent="center"
              >
              
                <LazyLoad height={300} offset={400}>
             
                {lot && lot.type === "declining_price" && item.listingType !== "marketplace_price" && (
                 
                  <LotPrice
                    lot={lot}
                    flavour="horizon_price"
                    // flavour={flavour}
                    spice={["item_card"]}
                  />  
                )}

{item && item.listingType === "marketplace_price" && (
  <>
<Typography >
    <LotPrice
    item={item}
    flavour="marketplace_price"
    spice={["simple"]}
  />
</Typography>
  </>
)}

                  {lot && lot.type === "increasing_bid" && (
                    <>
                      {" "}
                      <Box>
                        {flavour !== "simple" && <LotBid item={item} />}
                      </Box>{" "}
                    </>
                  )}

          
                </LazyLoad>
              </Box>
            </ImpressionCounter>
          </LazyLoad>
        </Card>
      )}
    </>
  );
};

export default ItemCardUnframed;
