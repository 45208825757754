import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    // Add bottom margin if element below
    "&:not(:last-child)": {
      marginBottom: "2rem",
    },
  },
  subtitle: {
    // Subtitle text generally isn't very long
    // so usually looks better to limit width.
    maxWidth: 700,
    fontWeight: 300,
    // So we can have max-width but still
    // have alignment controlled by text-align.
    display: "inline-block",

    // fontSize: '1.4em',
    
  },
  title: {
    // Subtitle text generally isn't very long
    // so usually looks better to limit width.
    maxWidth: 700,
    // So we can have max-width but still
    // have alignment controlled by text-align.
    display: "inline-block",
    // fontSize: '1.8em',
    fontFamily: '"Cormorant Garamond", "serif"',
    fontWeight: 500,
    
  },
}));

function SectionHeader(props) {
  const classes = useStyles();

  const { subtitle, title, size, className, ...otherProps } = props;

  // Render nothing if no title or subtitle
  if (!title && !subtitle) {
    return null;
  }

  return (
    <Box
      component="header"
      className={classes.root + (props.className ? ` ${props.className}` : "")}
      {...otherProps}
    >
      {title && (
        <Typography
          className={classes.title + (props.className ? ` ${props.className}` : "")}
          variant={`h${size}`}
          gutterBottom={props.subtitle ? true : false}
          color="textPrimary"
        >
          {title}
        </Typography>
      )}
<br/>
      {subtitle && (
        <Typography 
        className={classes.subtitle + (props.className ? ` ${props.className}` : "")}
        variant="h5"
        color="textPrimary"
         >
          {subtitle}
        </Typography>
      )}
    </Box>
  );
}

export default SectionHeader;
