import React, { useState, useEffect } from "react";
import {
  useAuctionByEvent,
  useLotStatus,
} from "./../util/db.js";
import { useAuth } from "./../util/auth.js";

import { getPaymentMethodStatus } from "./../util/stripe.js";

import { makeStyles } from "@material-ui/core/styles";


import LotDetails from "./../components/LotDetails";

import { isPastTime } from "../util/time";
import ItemReview from "./../components/ItemReview";

const defaultPalette = { lightVibrant: "#cccccc", darkMuted: "#000000" };

const useStyles = makeStyles((theme) => ({
  root: {
    // minWidth: 275,
    // boxShadow: "1px 0px 4px rgb(0 2 4 / 2%), 0 7px 20px rgb(1 1 1 / 3%)",
    padding: "15px",
    borderColor: "transparent",
    // border: '1px solid',
    "&:hover": {
      borderColor: "#0000001f",
      border: "1px solid",
    },
  },
  "&:hover": {
    borderColor: "red",
  },

  estimate: {
    justifyContent: "space-between",
    // paddingLeft: '16px',
    // paddingRight: '16px',

    display: "flex",
    alignItems: "center",
  },

  media: {
    height: "200px",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },

  paperItems: {
    minHeight: "300px",
  },
  // featured: {
  //   backgroundColor:
  //     theme.palette.type === "dark" ? theme.palette.action.selected : "#fdf8c2",
  // },
  starFeatured: {
    color: theme.palette.warning.main,
  },
  name: {
    overflow: "hidden",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
  },
  StipTitle: {
    textDecoration: "none",
    color: "black",
    fontWeight: 600,
  },
}));

function AuctioneerReviewItem(props) {
  const { likes, event, tab, item, lot, mode } = props;

  const debugFlag = process.env.REACT_APP_DEBUG === "on" ? true : false;
  //const debugFlag = true;
  const classes = useStyles();

  const [canBid, setCanBid] = useState();

  const auth = useAuth();

  // Check if the auction is running.
  const { data: auctionData } = useAuctionByEvent(item.auction);
  const isRunning = auctionData && auctionData.state;

  if (item && item.palette === undefined) {
    item.palette = defaultPalette;
  }

  const isAdministrator =
    auth.user && auth.user.role && auth.user.role === "auctioneer";
  const isOwner = auth.user && auth.user.id === (item && item.owner);
  const isWinner = auth.user && auth.user.id === (item && item.bidOwner);
  const isPaid = lot && lot.paid === true ? true : false;

  const isKnockedDown =
    lot && lot.knockedDownAt && lot.knockedDownAt !== false ? true : false;

  const isLotEnded = lot && isPastTime(lot.endsAt); // Not as important for decling price

  const { isLotStarted } = useLotStatus(lot, event);

  //const isLotEnded = lot && isPastTime(lot.endsAt);
  //const isLotStarted = lot && isPastTime(lot.startsAt);

  //  const isKnockedDown =
  //   lot && lot.knockedDownAt && lot.knockedDownAt !== false ? true : false;

  const isDelisted =
    lot && lot.delistedAt && lot.delistedAt !== false ? true : false;

  const canBuy =
    isRunning &&
    !isKnockedDown &&
    !isDelisted &&
    isLotStarted &&
    !isLotEnded &&
    lot &&
    lot.type &&
    lot.type === "declining_price";

  //const isLotStarted = true;

  const matchesTab = applyTabs(tab);

  function handleStatus(e) {
    if (props.onStatus) {
      props.onStatus(e);
    }
  }

  function applyTabs(tab) {
    var matchesTab = true;

    if (tab === "all") {
      return true;
    }

    if (tab === "active") {
      if (!isOwner && isPastTime(lot.endsAt)) {
        matchesTab = false;
      }
      if (isOwner && lot && lot.paid === true) {
        matchesTab = false;
      }
    }

    if (tab === "past") {
      //matchesTab = false;
      if (lot === undefined) {
        matchesTab = false;
        return false;
      }
      if (!isOwner && !isPastTime(lot.endsAt)) {
        matchesTab = false;
      }
      if (isOwner && !isPastTime(lot.endsAt)) {
        matchesTab = false;
      }
    }
    return matchesTab;
  }

useEffect(() =>{
console.log("AuctioneerReviewItem start")

}, [])

  useEffect(() => {
    if (canBid === undefined) {
      // Call the stripe function
      const c = getPaymentMethodStatus();
      setCanBid(c);
    }
  }, []);

  useEffect(() =>{

console.log("AuctioneerReviewItem event", event)
  
  }, [event])

    return (
      <>
        {/* <Button onClick={(s) => handleUpdateItemPrices(s)}>
          Update prices (test)
        </Button> */}
        {/*isAuctioneer && (<LotPrices item={item} />) */}
        {item && isAdministrator && !isLotStarted && (
          <>
            <ItemReview
              key={item.id}
              event={event}
              item={item}
              lot={lot}
              onStatus={(e) => handleStatus(e)}
            />{" "}
          </>
        )}
        {item &&
          item.accepted &&
          isAdministrator &&
          isLotStarted &&
          !isLotEnded && (
            <>
              {" "}
              <LotDetails item={item} lot={lot} />
            </>
          )}
      </>
    );


  //    return <>{debugFlag && <>NO ITEM</>}</>;
  //  };
}

export default AuctioneerReviewItem;
