
export const debugFlag = process.env.REACT_APP_DEBUG && process.env.REACT_APP_DEBUG === "on" ? true : false;

export const privateCatchallFlag = process.env.REACT_APP_DEBUG && process.env.REACT_APP_PRIVATE_CATCHALL === "on" ? true : false;

// Test catch all off
//export const privateCatchallFlag = false;

export function debugConsole(text) {

if (!debugFlag) {return;}
console.log(text);

}

