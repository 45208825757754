import React, { useState } from "react";
import { Link } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import EditEventModal from "./EditEventModal";
import { useAuth } from "./../util/auth.js";
import { updateEvent } from "./../util/db.js";
import { makeStyles } from "@material-ui/core/styles";
import EventDelete from "./../components/EventDelete";
import { Grid, Box, Button } from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
const prefix = process.env.REACT_APP_WEB_PREFIX;
const useStyles = makeStyles((theme) => ({
  paperItems: {
    minHeight: "300px",
  },
  featured: {
    backgroundColor:
      theme.palette.type === "dark" ? theme.palette.action.selected : "#fdf8c2",
  },
  starFeatured: {
    color: theme.palette.warning.main,
  },

}));

function EditEventPanel(props) {
  const classes = useStyles();

  const { event, seller } = props;


  const auth = useAuth();

  const isAuctioneer = auth.user.role && auth.user.role === "auctioneer";

  const eventTypeWordSingular = isAuctioneer ? "Auction" : "Collection";
  const eventTypeWordPlural = isAuctioneer ? "Auctions" : "Collections";

  const [creatingEvent, setCreatingEvent] = useState(false);

  const [updatingEventId, setUpdatingEventId] = useState(null);

  const eventIsEmpty = !event || event === undefined;
  const eventStatus = event === undefined ? "loading" : "success";

  const canUseStar =
    auth.user.planIsActive &&
    (auth.user.planId === "pro" || auth.user.planId === "business");

  const canUsePublish = isAuctioneer;


  const hasEventLink = event && event.slug ? true : false;
  var copyLink = prefix;
  if (hasEventLink) {
    copyLink = prefix + "/#" + event.slug;
  }

 
  const handleStarEvent = (event) => {
    if (canUseStar) {
      updateEvent(event.id, { featured: !event.featured });
    } else {
      alert("You must upgrade to the pro or business plan to use this feature");
    }
  };


  if (eventStatus !== "success") {
    return null;
  }

  return (
    <>


      <Grid container direction="row" justifyContent='flex-end' alignItems="center" style={{}}>


      <Box px={1} >
    
<Button  variant="contained"
            size="normal"
            color="secondary"
         
            component={Link}
            disableElevation
            startIcon={<AddCircleOutlineIcon/>}
            to={`/client/${event.id}`}

            >
  {"Add & Manage Items"}
</Button>

</Box>
 
      <IconButton
        aria-label="update"
        onClick={() => setUpdatingEventId(event.id)}
      >
        <EditIcon />
      </IconButton>

      <EventDelete event={event}/>

      {/* </ListItemSecondaryAction> */}

      {updatingEventId && (
        <EditEventModal
          seller={seller}
          id={updatingEventId}
          onDone={() => setUpdatingEventId(null)}
        />
      )}
            </Grid>
    </>
  );
}

export default EditEventPanel;
