import React, { useEffect, useState } from "react";
import AppRouter from "./../components/AppRouter";
import "./../util/analytics.js";
import { AuthProvider } from "./../util/auth.js";
import { ThemeProvider } from "./../util/theme.js";
import SimpleReactLightbox from "simple-react-lightbox";
import { getFirebaseCurrentTime } from "../util/time.js";
import { CartProvider } from "use-shopping-cart";
import { useAuth } from "./../util/auth.js";

import { QueryClientProvider } from "./../util/db";
import { getFunctions, httpsCallable } from "firebase/functions";
import Chat from "../components/Chat";
import { createBrowserHistory as createHistory } from "history";
import "react-toastify/dist/ReactToastify.css";
import UnsupportedBrowserMessage from "../components/UnsupportedBrowserMessage";


const engineState = process.env.REACT_APP_ENGINE_STATE;

//const isPreLaunch = engineState === 'pre_launch';

const functions = getFunctions();

const processStripeClientSecret = httpsCallable(
  functions,
  "processStripeClientSecret"
);



function App(props) {
  // For now
  const checkoutProvider = "stripe elements";



  //const {useLocation} = useRouter();

  // const location = useLocation();

  // Get value from query string (?postId=123) or route param (/:postId)
  //console.log(router.query.postId);

  // Get current pathname
  //console.log(router.pathname)

  //const r = router.pathname;

  const [showFrame, setShowFrame] = useState();
  const auth = useAuth();
  const history = createHistory();
  const user = auth && auth.user && auth.user.uid ? auth.user.uid : null;

  // Trigger a timesync on App load

  const isLoggedIn = true;

  useEffect(() => {
    var t = getFirebaseCurrentTime(true);
  }, []);
  

  useEffect(() => {
    console.log("_app auth.user", auth?.user);
  }, [auth]);

  // Trigger call to Firebase to wakeup Firebase and Stripe.
  const StripePreLoader = () => {
    useEffect(() => {
      // Dummy call to Firebase hook
      console.log("_app auth?.user", auth?.user);

      if (auth?.user == null) {
        return;
      }

      processStripeClientSecret()
        .then((result) => {
          console.log("_app processStripeClientSecret result", result);
        })
        .catch((error) => {
          console.log("_app processStripeClientSecret error", error);
        });
    }, []);
    return <></>;
  };

  // Variable to control pre-launch state.
  // False = site is fully visible. As it always has been.
  // True = Shutter is down. Need to authenticate to see any of site.
  //const isPreLaunch = false;



  if (engineState === "maintenance") {
    return <>Site is in maintenance</>;
  }


  return (
    // <ScrollManager history={history}>
    //    <WindowScroller>
    //      <ElementScroller>
    <>

    <QueryClientProvider>
      {/* <IntlProvider locale="en"> */}

      <CartProvider
        mode="payment"
        cartMode="client-only"
        stripe={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}
        successUrl={process.env.REACT_APP_STRIPE_SUCCESSURL}
        cancelUrl={process.env.REACT_APP_STRIPE_CANCELURL}
        currency="USD"
        allowedCountries={["US", "GB", "CA"]}
        billingAddressCollection={true}
      >
        <StripePreLoader />

        <SimpleReactLightbox>
          <ThemeProvider>
            {/* <ToastContainer limit={1} /> */}

            <AuthProvider>
              <Chat />

     

<AppRouter />

            </AuthProvider>
          </ThemeProvider>
        </SimpleReactLightbox>
      </CartProvider>
      {/* </IntlProvider> */}
    </QueryClientProvider>
    </>
    //  </ElementScroller>
    //    </WindowScroller>
    //  </ScrollManager>
  );
}

export default App;
