import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { useAuth } from "./../util/auth.js";
import { useForm } from "react-hook-form";
import {
  useBidsByItem,
  useBidsByItemOwner,
  useAuctionByEvent,
} from "./../util/db.js";

import { isPastTime, humanTime } from "./../util/time.js";
import { makeStyles } from "@material-ui/core/styles";
import { v4 as uuidv4 } from "uuid";
import Typography from "@material-ui/core/Typography";
import LotCountdown from "./../components/LotCountdown";
import { Divider } from "@material-ui/core";
// import firebase from "../util/firebase";
import ReactTextTransition from "react-text-transition";
import { Link } from "./../util/router.js";
import { bidTable } from "../util/auction.js";
import { getFunctions, httpsCallable } from "firebase/functions";

const functions = getFunctions();

const processBid = httpsCallable(functions, "processBid");

const useStyles = makeStyles((theme) => ({
  root: {
    border: "none",
    boxShadow: "none",
    background: "none",
    // maxWidth: 345,
    // display: "flex",
    // "& > * + *": {
    //   marginLeft: theme.spacing(2),
    // },
  },

  formControl: {
    background: "white",
    minWidth: 140,
  },

  content: {
    paddingLeft: "0px",
    paddingBottom: 24,
  },

  button: {
    fontWeight: "600",
    paddingTop: "15px",
    paddingBottom: "15px",
    marginTop: "10px",
    fontSize: "1.25em",
  },

  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: "theme.transitions.duration.shortest",
    }),
  },

  // expandOpen: {
  //   transform: 'rotate(180deg)',
  // },
}));

function LotBid(props) {
  const debug = false; // true to show debuging information in render

  const { item, lot } = props;


  const classes = useStyles();

  const auth = useAuth();

  const isAuctioneer =
    auth.user && auth.user.role && auth.user.role === "auctioneer";

  const userId = auth.user && auth.user.id ? auth.user.id : null;

  const [auctionMode, setAuctionMode] = useState("false");

  const [editImagesKey, setEditImagesKey] = useState(uuidv4());

  const [auctioneerBuyerMessage, setAuctioneerBuyerMessage] = useState(
    "Ready for your bid."
  );

  const [buyerBidNext, setBuyerBidNext] = useState(); // Holds the bid the Buyer is about to place
  const [buyerBidMinimum, setBuyerBidMinimum] = useState(); // The current minimum the Buyer may place

  const isCart = props.flavour === "cart" ? true : false;

  //var { palette } = props;

  const [pending, setPending] = useState(false);
  const [formAlert, setFormAlert] = useState(null);

  const {
    register,
    handleSubmit,
    errors,
    control,
    getValues,
    watch,
    reset,
    setValue,
  } = useForm();

  const [itemId, setItemId] = useState(item.id);


  const isOwner =
    auth.user && auth.user.id && auth.user.id === (item && item.owner);

  var { palette } = item;

  if (palette === undefined) {
    palette = { darkMuted: "#000000" };
  }

  const {
    data: auction,
    status: auctionStatus,
    error: auctionError,
  } = useAuctionByEvent(item.auction);

  var auctionState = auction && auction.state ? auction.state : null;
  var isAuctionRunning = auction && auction.state;
  var auctionRunningText = isAuctionRunning ? "RUNNING" : "STOPPED";

  var lotBidAmount = null;
if (lot) {
  lotBidAmount = lot && lot.bidAmount ? lot.bidAmount : lot.startingPrice;
}
  useEffect(() => {
    console.log("LotBid lot", lot);
    console.log("LotBid item", item);
  }, [lot, item]);
  const isLot =
    lot && lot.startingPrice && lot.estimatePrice && lot.id ? true : false;

  const isKnockedDown =
    lot && lot.knockedDownAt && lot.knockedDownAt !== false ? true : false;

  const typeLot = lot && lot.type ? lot.type : "declining_price";

  const [isBiddable, setIsBiddable] = useState();

/*
   Let's talk about running.
   Running is a switch the auctioneer has control over which makes all the
   items in a collection available (or unavailable) to bid on.
   It has two states - "play" or "stop".
   When playing - any item which has a a lot in the valid timeframe can be bid on.
   When stopped - no item can be bid on.
*/
  const { status: buyerLotStatus, data: buyerLotData } = useBidsByItemOwner(
    item.id,
    userId
  );

  const [isLotStarted, setIsLotStarted] = useState(false);

  function updateBiddableState() {
    var biddableFlag = false;

    if (lot === undefined || lot === null) {
      setIsBiddable(biddableFlag);
      return;
    }

    if (auctionState === true) {
      biddableFlag = true;
    }

    if (!isPastTime(lot.startsAt)) {
      biddableFlag = false;
    }

    if (isPastTime(lot.endsAt)) {
      biddableFlag = false;
    }

    if (isKnockedDown === true) {
      biddableFlag = false;
    }

    setIsBiddable(biddableFlag);
  }

  useEffect(() => {
    updateBiddableState();
  }, [lot, isLotStarted, isKnockedDown, auctionState]);

  var buyerBidAmount = 0;
  if (buyerLotStatus === "success" && buyerLotData.length > 0) {
    buyerBidAmount = buyerLotData[0].amount;
  }

  useEffect(() => {
    const timer2 = setInterval(() => {
      if (lot === undefined || lot === null) {
        return;
      }
      if (isLotStarted === true) {
        clearInterval(timer2);
      }

      if (isPastTime(lot.startsAt)) {
        //if (isAuctionRunning === true && isKnockedDown !== true) {setIsBiddable(true);}
        setIsLotStarted(true);
      }
      // else {

      //setIsBiddable(false);

      // }
    }, 1000);
    // Clear timeout if the component is unmounted
    return () => clearTimeout(timer2);
  }, [lot]);

  // Calculate the bidding table value available
  useEffect(() => {
    const dropdownSize = 100;

    if (lot === undefined || lot == null) {
      return;
    }

    var bidAmount = 0;
    bidAmount = parseFloat(lot.startingPrice);

    if (lotBidAmount !== 0) {
      bidAmount = parseFloat(lotBidAmount);
    }

    let t = [];

    var bidDropDownAmount = bidAmount;
    var bidDropDownAmount = 500;
    for (var i = 0; i < dropdownSize; i++) {
      t.push(bidDropDownAmount);
      bidDropDownAmount = bidDropDownAmount + bidIncrement(bidDropDownAmount);
    }

  }, [lot, buyerBidAmount, buyerBidMinimum]);

  const watchAmount = watch("amount");

  useEffect(() => {
    if (watchAmount === undefined) {
      return;
    }

    setBuyerBidNext(watchAmount);
  }, [watchAmount]);

  // Calculate and set the Buyer's Minimum Bid.
  // This is the minimum bid the buyer is allowed to place.
  useEffect(() => {
    if (buyerLotStatus !== "success") {
      return;
    }

    setBuyerBidMinimum(lotBidAmount + bidIncrement(lotBidAmount));
  }, [lot, buyerBidAmount, buyerLotStatus]);

  useEffect(() => {
    if (buyerLotStatus !== "success") {
      return;
    }

    var amount = lotBidAmount;

    if (buyerLotData.length > 0) {
      amount = Math.max(lotBidAmount, buyerLotData[0].amount);
    }

    setBuyerBidNext(amount + bidIncrement(amount));
    setValue("amount", amount + bidIncrement(amount));
  }, [lot, buyerBidAmount, buyerLotStatus, buyerLotData]);

  // Calculate and set the Buyer's Next Bid.
  // This is the Bid the buyer is about to place.

  const bids = useBidsByItem(itemId);
  const { event } = props;

  const [expanded, setExpanded] = useState(false);

  function bidIncrement(num) {
    var increment = 10;
    for (var i = 0; i < bidTable.length; i++) {
      if (num < bidTable[i].bid) {
        break;
      }
      increment = bidTable[i].increment;
    }

    return increment;
  }

  const LotBiddingMessage = () => {
    if (!isPastTime(lot.startsAt)) {
      return null;
    }

    if (isBiddable === false && auctionState !== true) {
      return "Item can not be bid on because Lady Bidwell has stopped bidding on this item.";
    }

    return null;
  };

  const LotBidAmount = () => {
    var lotBidText = "Starting Bid";
    if (lot && lot.bidAt) {
      lotBidText = "Current Bid";
    }
    if (isKnockedDown) {
      lotBidText = "Highest Bid";
    }

    return (
      <>
        <Box display="flex" pt={1}>
          <Box flexGrow={1}>
            <Typography
              variant="subtitle1"
              style={{ fontWeight: "400", color: palette.darkMuted }}
            >
              {lotBidText}
            </Typography>
          </Box>

          <Typography variant="h5" style={{ fontWeight: "500" }}>
            <Box display="flex">
              $
              <ReactTextTransition
                text={lotBidAmount.toLocaleString()}
                springConfig={{ stiffness: 50, damping: 20 }}
                noOverflow
              />
            </Box>
          </Typography>
        </Box>
        <Typography>
          {lot && lot.bidCount ? lot.bidCount + " bids" : "No bids"}

          {/*lot && lot.reserveMet ? "Reserve met" : "Reserve not met"*/}
        </Typography>
      </>
    );
  };

  const DebugBid = () => {
    if (debug === false) {
      return null;
    }
    return (
      <>
        buyerBidAmount {buyerBidAmount} buyerBidMinimum {buyerBidMinimum}{" "}
        buyerBidNext {buyerBidNext}
        lotBidAmount{lotBidAmount}
        {lot.reserveMet ? "Met reserve" : "Did not meet reserve"}
      </>
    );
  };

  const BidItemResult = () => {
    if (
      isKnockedDown &&
      auth.user &&
      auth.user.id === lot.bidOwner &&
      lot.reserveMet === false
    ) {
      return (
        <>
          You were the highest bidder, but the item did not meet the seller's
          reserve.
        </>
      );
    }

    if (isKnockedDown && auth.user && auth.user.id === lot.bidOwner) {
      return (
        <>
          You have won this item
          <Button component={Link} to="/dashboard">
            Dashboard
          </Button>
        </>
      );
    }

    if (
      isKnockedDown !== false &&
      auth.user &&
      auth.user.id === item.owner &&
      lot.reserveMet === false
    ) {
      return (
        <>
          {lot.reserveMet ? "true" : "false"}
          This item did not meet your reserve.
        </>
      );
    }

    if (
      isKnockedDown !== false &&
      auth.user &&
      auth.user.id === item.owner &&
      lot.reserveMet === true
    ) {
      return (
        <>
          You have sold this item
          <Button component={Link} to="/dashboard">
            Dashboard
          </Button>
        </>
      );
    }

    if (isKnockedDown) {
      return <>You did not win this item</>;
    }

    if (!isKnockedDown) {
      return null;
    }

    return null;
  };

  if (lotBidAmount === undefined) {
    return (<>No Lot Bid Amount defined</>);
  }

  if (!isLot) {
    return <>This item has not been assigned a lot.</>;
  } // This item is not in an auction.

  if (auth.user && buyerBidNext === undefined) {
    return null;
  }

  if (auctionState === false) {
    return null;
  }

  var lotBidText = "Starting Bid";
  if (lot && lot.bidAt) {
    lotBidText = "Current Bid";
  }
  if (isKnockedDown) {
    lotBidText = "Highest Bid";
  }

//  if (buyerLotStatus !== "success") {
//    return null;
//  }

  if (typeLot === "declining_price") {
    return (
      <>
        DECLINING PRICE SALE
        {/*isLotVisible ? "VISIBLE" : "NOT VISIBLE"*/}
        {isLotStarted && isAuctionRunning ? (
          <>
            <Box display="flex" py={1}>
              <Box flexGrow={1}>
                <Typography
                  variant="subtitle1"
                  style={{ fontWeight: "400", color: palette.darkMutedh }}
                >
                  Available Until (or until sold):
                </Typography>
              </Box>

              <Box minWidth="100px" textAlign="right">
                <Typography
                  variant="subtitle1"
                  style={{ fontWeight: "400", paddingRight: "5px" }}
                >
                  {/*humanTime(lot && lot.endsAt)} <br /> */}
                  <LotCountdown
                    lot={lot}
                    type="timestamp"
                    flavour={props.flavour}
                  />
                </Typography>
                {!isCart ? (
                  <Typography
                    variant="subtitle1"
                    style={{ fontWeight: "400", paddingRight: "5px" }}
                  >
                    {/*humanTime(lot && lot.endsAt)} <br /> */}
                    <LotCountdown
                      lot={lot}
                      type="numbers"
                      flavour={props.flavour}
                    />
                  </Typography>
                ) : null}
              </Box>
            </Box>

            <Box pb={1}>
              <div style={{ height: "15px" }}>
                <LotCountdown lot={lot} type="bar" flavour={props.flavour} />
              </div>
            </Box>
            <Divider />

            {/*} <LotBidAmount /> */}

            <Box display="flex" py={1}>
              <Box flexGrow={1}>
                <Typography
                  variant="subtitle1"
                  style={{ fontWeight: "500", color: palette.darkMuted }}
                >
                  {lotBidText}
                </Typography>
              </Box>

              <Typography
                variant="h5"
                style={{ fontWeight: "500", alignSelf: "center" }}
              >
                <Box display="flex">
                  $
                  <ReactTextTransition
                    text={lotBidAmount.toLocaleString()}
                    springConfig={{ stiffness: 50, damping: 20 }}
                    noOverflow
                  />
                </Box>
              </Typography>
            </Box>

            <Divider />
          </>
        ) : (
          <>
            <Box display="flex" py={1}>
              <Box flexGrow={1}>
                <Typography
                  variant="subtitle1"
                  style={{ fontWeight: "500", color: palette.darkMuted }}
                >
                  Starts:
                </Typography>
              </Box>
              <Typography
                variant="subtitle1"
                style={{ fontWeight: "400", color: palette.darkMuted }}
              >
                {lot.startsAt === undefined
                  ? "In Preview"
                  : humanTime(lot.startsAt)}
              </Typography>
            </Box>
          </>
        )}
      </>
    );
  }

  return (
    <>
      <Box display="flex">
        <Box flexGrow={1}>
          {/*isKnockedDown ? "KNOCKED DOWN" :"NOT KNOCKED DOWN"*/}
          <Typography
            variant="subtitle1"
            style={{ fontWeight: "400", color: palette.darkMuted }}
          >
            Estimated Price:
          </Typography>
        </Box>

        <Typography
          variant="subtitle1"
          style={{ fontWeight: "400", color: palette.darkMuted }}
        >
          ${""}
          {lot && lot.estimatePrice
            ? lot.estimatePrice.toLocaleString()
            : "No estimated price"}
        </Typography>
      </Box>
      {isAuctioneer ? auctionRunningText : null}
      <LotBiddingMessage />
      <br />
      {/*isLotVisible ? "VISIBLE" : "NOT VISIBLE"*/}
      {isLotStarted && isAuctionRunning ? (
        <>
          <Box display="flex" py={1}>
            <Box flexGrow={1}>
              <Typography
                variant="subtitle1"
                style={{ fontWeight: "400", color: palette.darkMutedh }}
              >
                Lot Closes:
              </Typography>
            </Box>

            <Box minWidth="100px" textAlign="right">
              <Typography
                variant="subtitle1"
                style={{ fontWeight: "400", paddingRight: "5px" }}
              >
                {/*humanTime(lot && lot.endsAt)} <br /> */}
                <LotCountdown
                  lot={lot}
                  type="timestamp"
                  flavour={props.flavour}
                />
              </Typography>

              {!isCart ? (
                <Typography
                  variant="subtitle1"
                  style={{ fontWeight: "400", paddingRight: "5px" }}
                >
                  {/*humanTime(lot && lot.endsAt)} <br /> */}
                  <LotCountdown
                    lot={lot}
                    type="numbers"
                    flavour={props.flavour}
                  />
                </Typography>
              ) : null}
            </Box>
          </Box>

          <Box pb={1}>
            <div style={{ height: "15px" }}>
              <LotCountdown lot={lot} type="bar" flavour={props.flavour} />
            </div>
          </Box>
          <Divider />

          {/*} <LotBidAmount /> */}

          <Box display="flex" py={1}>
            <Box flexGrow={1}>
              <Typography
                variant="subtitle1"
                style={{ fontWeight: "500", color: palette.darkMuted }}
              >
                {lotBidText}
              </Typography>

              <Typography
                variant="subtitle1"
                style={{ fontWeight: "400", color: palette.darkMuted }}
              >
                (
                {lot && lot.bidCount
                  ? lot.bidCount === 1
                    ? "1 bid"
                    : lot.bidCount + " bids"
                  : "No bids"}
                )
              </Typography>
            </Box>

            <Typography
              variant="h5"
              style={{ fontWeight: "500", alignSelf: "center" }}
            >
              <Box display="flex">
                $
                <ReactTextTransition
                  text={lotBidAmount.toLocaleString()}
                  springConfig={{ stiffness: 50, damping: 20 }}
                  noOverflow
                />
              </Box>
            </Typography>
          </Box>

          {/* <Typography>
        {lot && lot.bidCount ? lot.bidCount + " bids" : "No bids"}
      </Typography> */}

          <Divider />
        </>
      ) : (
        <>
          <Box display="flex" py={1}>
            <Box flexGrow={1}>
              <Typography
                variant="subtitle1"
                style={{ fontWeight: "500", color: palette.darkMuted }}
              >
                Bidding Starts:
              </Typography>
            </Box>
            <Typography
              variant="subtitle1"
              style={{ fontWeight: "400", color: palette.darkMuted }}
            >
              {lot.startsAt === undefined
                ? "In Preview"
                : humanTime(lot.startsAt)}
            </Typography>
          </Box>
        </>
      )}
    </>
  );

//  if (item === undefined) {
//    return null;
//  }
}

export default LotBid;
