import React, { useState, useEffect } from "react";
import { useRouter } from "./../util/router.js";
import {
  makeStyles,
} from "@material-ui/core";
import { useAuctionsByFeatured } from "../util/db";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import ReactMarkdown from "react-markdown";

import {useDropTime} from "../util/time.js";

const useStyles = makeStyles((theme) => ({
  alert: {
    // Let <Section> handle colors
    backgroundColor: "#2b2b2bf2",
    color: "#ffffff",
    borderRadius: 0,
  },

  message: {
    width: "100%",
    textAlign: "center",
  },
}));

function PriceDropCountdown(props) {
  const router = useRouter();
  const path = router.pathname;
  const { flavour } = props;
  //const [showAnnouncement, setShowAnnouncement] = useState(false);

const [dropDateTime, setDropDateTime] = useState();

  const {
    data: auctions,
    status: auctionsStatus,
    error,
  } = useAuctionsByFeatured();

const {data:dropTime,status:dropTimeStatus, error:dropTimeError} = useDropTime();

useEffect(()=>{

console.log("PriceDropCountdown dropTime", dropTime);

},[dropTime]);

  useEffect(() => {
    if (!auctions) {
      return;
    }
    if (auctions.length === 0) {
      return;
    }

    var s = path === "/";
    if (s) {
      setOpen(true);
      return;
    }

    s = path.startsWith("/item/");
    if (s) {
      setOpen(true);
      return;
    }

    setOpen(false);
  }, [path, auctions]);

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const {
    onClose,
    bgColor,
    textColor,
    text,
    linkPath,
    linkText,
    ...otherProps
  } = props;

//  const [duration, setDuration] = useState();

  // const [index, setIndex] = useState(0);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setIndex(index + 1);
  //   }, 3000);
  //   return () => clearInterval(interval);
  // });

  // const loopArr = [
  //   "Next Price Drop " + duration,
  //   "The Best Items from This Week's Hamptons Estate Sales.",
  // ];

  // const announcements = [{announcement:"[Ttst]"}, { announcement: "Next Price Drop - " + duration }, { announcement: "The Finest Items from This Week's Hamptons Estate Sales and Shops." } ];                                                                                                                                 uncements = [{announcement:'<>Ttst</>'},{ announcement: "Next Price Drop - " + duration }, { announcement: "The Finest Items from This Week's Hamptons Estate Sales and Shops." } ];

  // const announcements = [{ announcement: "Next Price Drop - " + duration }, { announcement: "The Finest Items from This Week's Hamptons Estate Sales and Shops." } ];                                                                                                                                 uncements = [{announcement:'<>Ttst</>'},{ announcement: "Next Price Drop - " + duration }, { announcement: "The Finest Items from This Week's Hamptons Estate Sales and Shops." } ];

  // const announcements = [{ announcement: `Next Price Drop in ${duration}` }, { announcement: "**Curated Finds from Hamptons Estate Sales, Private Sellers and Shops.**" }, { announcement: "**Declining Price Auction on Every Item for 7 Days. [Learn More.](https://ladybidwell/learnmore)** " }];

  const announcements = [
    { announcement: `Next Price Drop in ${dropTime && dropTime.duration}` },
    {
      announcement:
        "Curated Finds from Estate Sales, Private Sellers and Shops.",
    },
    {
      announcement:
        "Price drops on every item for 7 Days. [Learn More.](https://ladybidwell/learnmore) ",
    },
  ];

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  const [currentAnnouncementIndex, setCurrentAnnouncementIndex] =
    React.useState(0);

  React.useEffect(() => {
    const id = setTimeout(
      () =>
        setCurrentAnnouncementIndex(
          (currentAnnouncementIndex + 1) % announcements.length
        ),
      6000
    );
    return () => {
      clearInterval(id); // removes React warning when gets unmounted
    };
  }, [currentAnnouncementIndex]);

  if (flavour === "navbar") {
    return (
      <>
        <ReactMarkdown disallowedElements={["p"]} unwrapDisallowed={true}>
          {announcements[currentAnnouncementIndex].announcement}
        </ReactMarkdown>

      </>
    );
  }

  return (
  
    <>
{/*dropTime && dropTime.zuluDropTime*/}
{dropTime && dropTime.duration}
{/*dropDateTime*/}
{/*dropDateTime.toISOString()*/}{' '}
{/*duration*/}
              {/* {announcements[currentAnnouncementIndex].announcement} */}
              </>
  
  );
}

export default PriceDropCountdown;
