import React from 'react';
import {
    Box,
    Card,
    CardContent,
    Container,
    Grid,
    Typography,
  } from '@material-ui/core';
  import { makeStyles } from '@material-ui/core/styles';
  import { Link } from '../util/router';
import searchphone from "./../images/searchphone.jpg";

const useStyles = makeStyles((theme) => ({
    title: {
      textAlign: 'left',
      fontFamily: '"Cormorant Garamond", "serif"',
      [theme.breakpoints.down('sm')]: {
      textAlign:'center',

      },
    },
    message: {
      textAlign: 'left',
      [theme.breakpoints.down('sm')]: {
       textAlign:'center',
      },
    },
    image: {
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(2),
        maxHeight:'200px',
        width:'auto',

      },
    },
    textContainer: {
        display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      },
    },
    imageGrid: {
        order: 0,
        textAlign:'center',
        [theme.breakpoints.up('md')]: {
          order: 1,
          justifyContent:'center',
        },
      },
      textGrid: {
        order: 1,
        [theme.breakpoints.up('md')]: {
          order: 0,
        },
      },
      card: {
        display: 'flex',
        alignItems: 'center', // Center the content vertically
      },
  }));

function NotFoundPage() {
  const classes = useStyles();

  return (
<div className={classes.root}>
  <Container>
    <Box py={3}>
      <Card variant="outlined" className={classes.card}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              md={6}
              className={`${classes.textContainer} ${classes.textGrid}`}
            >
                <Typography
                variant="h3"
                component="h1"
                gutterBottom
                className={classes.title}
              >
                404 Not Found.
              </Typography>
              <Typography
                variant="body1"
                component="p"
                className={classes.message}
              >  We are normally really good at finding the most interesting and unique furniture... so this is pretty embarassing for us. We even called Auntie Marjorie, and she also has no idea where this page went.  <br/><br/>So in the meantime, while we make a few more calls, please go back or go to the <Box  style={{textDecoration:'underline'}} component={Link} to={'/'} fontWeight='600'>LadyBidwell Homepage.</Box></Typography>

            </Grid>
            <Grid item xs={12} md={6} className={classes.imageGrid}>
              <img
           
                src={searchphone}
                alt="Your image description"
                className={classes.image}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  </Container>
</div>

  );
}

export default NotFoundPage;