import { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { useAuth } from "./../util/auth.js";
import { useForm } from "react-hook-form";
import {
  useBidsByItem,
  useLotByItem,
  useBidsByItemOwner,
  useAuctionByEvent,
} from "./../util/db.js";
import EventName from "./EventName.js";
import { isPastTime, humanTime } from "./../util/time.js";
import { makeStyles } from "@material-ui/core/styles";
import { v4 as uuidv4 } from "uuid";
import ImpressionCount from "./ImpressionCount.js";
import { Skeleton } from "@material-ui/lab";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import ReactTextTransition from "react-text-transition";
import { bidTable } from "../util/auction.js";
import LotPrice from "./LotPrice.js";
import { getFunctions, httpsCallable } from "firebase/functions";
import ItemAction from "./ItemAction.js";
import LotPricesTable from "./LotPricesTable.js";
import TrendingDownIcon from "@material-ui/icons/TrendingDown";
import ScheduleIcon from "@material-ui/icons/Schedule";
import PeopleIcon from "@material-ui/icons/People";
import PriceDropGuideDrawer from "./LearnMoreDrawer.js";
import PriceDropClockTime from "./PriceDropClockTime.js";
import StorefrontIcon from "@material-ui/icons/Storefront";
const functions = getFunctions();

const processBid = httpsCallable(functions, "processBid");

const useStyles = makeStyles((theme) => ({
  root: {
    // border: "#e5e8eb",
    boxShadow: "none",
    background: "none",
    // maxWidth: 345,
    // display: "flex",
    // "& > * + *": {
    //   marginLeft: theme.spacing(2),
    // },
  },
  actionArea: {
    "&:hover $focusHighlight": {
      opacity: 0,
    },
  },
  focusHighlight: {},

  card: {
    border: "1px solid #e5e8eb",
  },

  cardHeader: {
    background: "#ffffff",
    borderBottom: "1px solid #e5e8eb",
  },

  cardContent: {
    background: "#fbfcff",
  },

  section1: {
    border: "1px solid #f2f1f1",
    background: "#ffffff",
    marginBottom: 20,
  },
  section2: {
    border: "1px solid #f2f1f1",
    // background: '#f7f7f7',

    marginBottom: 20,
  },

  formControl: {
    background: "white",
    minWidth: 140,
  },

  content: {
    paddingLeft: "0px",
    paddingBottom: 24,
  },

  button: {
    fontWeight: "600",
    paddingTop: "15px",
    paddingBottom: "15px",
    marginTop: "10px",
    fontSize: "1.25em",
  },

  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: "theme.transitions.duration.shortest",
    }),
  },
}));

function LotBuy(props) {
  //const debug = false; // true to show debuging information in render
  const { flavour } = props;
  const classes = useStyles();

  const auth = useAuth();

  const isAdministrator =
    auth.user && auth.user.role && auth.user.role === "auctioneer";

  const [auctionMode, setAuctionMode] = useState("false");

  const [editImagesKey, setEditImagesKey] = useState(uuidv4());

  const [auctioneerBuyerMessage, setAuctioneerBuyerMessage] = useState(
    "Ready for your bid."
  );

  const [buyerBidNext, setBuyerBidNext] = useState(); // Holds the bid the Buyer is about to place
  const [buyerBidMinimum, setBuyerBidMinimum] = useState(); // The current minimum the Buyer may place

  //var { palette } = props;

  const [pending, setPending] = useState(false);
  const [formAlert, setFormAlert] = useState(null);

  const {
    register,
    handleSubmit,
    errors,
    control,
    getValues,
    watch,
    reset,
    setValue,
  } = useForm();

  const [itemId, setItemId] = useState(props.item.id);

  const { item, mode } = props;

  const isOwner =
    auth.user && auth.user.id && auth.user.id === (item && item.owner);

  var { palette } = props.item;

  if (palette === undefined) {
    palette = { darkMuted: "#000000", lightVibrant: "#0000ffff" };
  }
  //const auction = props.auction ? props.auction : null;
  const {
    data: auction,
    status: auctionStatus,
    error: auctionError,
  } = useAuctionByEvent(item.auction);
  console.log("item auction", auction);
  var auctionState = auction && auction.state ? auction.state : null;

  var isAuctionRunning = auction && auction.state;

  var auctionRunningText = isAuctionRunning ? "RUNNING" : "STOPPED";

  const { data: lot, status: lotStatus } = useLotByItem(item.id);
  //const lot = data;

  var lotBidAmount = 0;

  if (lot && lotStatus === "success") {
    lotBidAmount = lot && lot.bidAmount ? lot.bidAmount : lot.startingPrice;
  }
  const isLot =
    lot && lot.startingPrice && lot.estimatePrice && lot.id ? true : false;

  const isKnockedDown =
    lot && lot.knockedDownAt && lot.knockedDownAt !== false ? true : false;

  const isPaid = lot && lot.paid && lot.paid === true ? true : false;

  //    const [isBiddable, setIsBiddable] = useState(isKnockedDown === true || isAuctionRunning === false ? false : null);
  const [isBiddable, setIsBiddable] = useState();
  //const [isLotStopped, setIsLotStopped] = useState();

  // Let's talk about running.
  // Running is a switch the auctioneer has control over which makes all the
  // items in a collection available (or unavailable) to bid on.
  // It has two states - "play" or "stop".
  // When playing - any item which has a a lot in the valid timeframe can be bid on.
  // When stopped - no item can be bid on.

  const userId = auth.user && auth.user.id;

  const { status: buyerLotStatus, data: buyerLotData } = useBidsByItemOwner(
    item.id,
    userId
  );

  const [isLotStarted, setIsLotStarted] = useState();

  useEffect(() => {
    console.log("LotBuy xkcd start");
  }, []);

  useEffect(() => {
    console.log("LotBuy xkcd isKnockedDown isPaid", isKnockedDown, isPaid);
  }, [isKnockedDown, isPaid]);

  function handleImpressionsChange(e) {}
  function updateBiddableState() {
    var biddableFlag = false;

    if (lot === undefined || lot === null) {
      setIsBiddable(biddableFlag);

      return;
    }

    if (auctionState === true) {
      biddableFlag = true;
      //lotStoppedFlag = auctionState;
    }

    if (!isPastTime(lot.startsAt)) {
      biddableFlag = false;
    }

    if (isPastTime(lot.endsAt)) {
      biddableFlag = false;
    }

    if (isKnockedDown === true) {
      biddableFlag = false;
    }

    setIsBiddable(biddableFlag);
  }

  useEffect(() => {
    updateBiddableState();
  }, [lot, isLotStarted, isKnockedDown, auctionState]);

  var buyerBidAmount = 0;
  if (buyerLotStatus === "success" && buyerLotData.length > 0) {
    buyerBidAmount = buyerLotData[0].amount;
  }

  useEffect(() => {
    if (lot && isPastTime(lot.startsAt)) {
      setIsLotStarted(true);
      // props.onStatus('ready');
      return;
    }

    const timer2 = setInterval(() => {
      if (lot === undefined || lot === null) {
        return;
      }
      if (isLotStarted === true) {
        clearInterval(timer2);
      }

      if (isPastTime(lot.startsAt)) {
        //if (isAuctionRunning === true && isKnockedDown !== true) {setIsBiddable(true);}
        setIsLotStarted(true);
        return;
      }
      setIsLotStarted(false);
      // else {

      //setIsBiddable(false);

      // }
    }, 1000);
    // Clear timeout if the component is unmounted
    return () => clearTimeout(timer2);
  }, [lot]);

  // Calculate the bidding table value available
  useEffect(() => {
    if (lotStatus !== "success") {
      return true;
    }
    const dropdownSize = 100;

    if (lot === undefined || lot == null) {
      return;
    }

    var bidAmount = 0;
    /*
    bidAmount =
      lot && lot.startingPrice
        ? bidTablePlacedAt(lot.startingPrice) - bidIncrement(lot.startingPrice)
        : bidAmount;
        */
    bidAmount = parseFloat(lot.startingPrice);

    if (lotBidAmount !== 0) {
      bidAmount = parseFloat(lotBidAmount);
    }

    let t = [];

    var bidDropDownAmount = bidAmount;
    var bidDropDownAmount = 500;
    for (var i = 0; i < dropdownSize; i++) {
      t.push(bidDropDownAmount);
      bidDropDownAmount = bidDropDownAmount + bidIncrement(bidDropDownAmount);
    }

    //setLotBidAt(startAt);
    //setAmountList(t);
  }, [lot, buyerBidAmount, buyerBidMinimum]);

  const watchAmount = watch("amount");

  useEffect(() => {
    if (watchAmount === undefined) {
      return;
    }

    setBuyerBidNext(watchAmount);
  }, [watchAmount]);

  // Calculate and set the Buyer's Minimum Bid.
  // This is the minimum bid the buyer is allowed to place.
  useEffect(() => {
    if (lotStatus !== "success") {
      return;
    }
    if (buyerLotStatus !== "success") {
      return;
    }

    setBuyerBidMinimum(lotBidAmount + bidIncrement(lotBidAmount));
  }, [lot, buyerBidAmount, lotStatus, buyerLotStatus]);

  useEffect(() => {
    if (lotStatus !== "success") {
      return;
    }

    if (buyerLotStatus !== "success") {
      return;
    }

    var amount = lotBidAmount;

    if (buyerLotData.length > 0) {
      amount = Math.max(lotBidAmount, buyerLotData[0].amount);
    }

    setBuyerBidNext(amount + bidIncrement(amount));
    setValue("amount", amount + bidIncrement(amount));
  }, [lot, buyerBidAmount, lotStatus, buyerLotStatus, buyerLotData]);

  // Calculate and set the Buyer's Next Bid.
  // This is the Bid the buyer is about to place.

  const itemData = props.item;

  const bids = useBidsByItem(itemId);
  const { event, spice } = props;

  const [expanded, setExpanded] = useState(false);

  function bidIncrement(num) {
    var increment = 10;
    for (var i = 0; i < bidTable.length; i++) {
      if (num < bidTable[i].bid) {
        break;
      }
      increment = bidTable[i].increment;
    }

    return increment;
  }

  console.log("isLotStarted", isLotStarted);
  useEffect(() => {
    if (isLotStarted === undefined) {
      return;
    }
    if (props.onStatus) {
      props.onStatus("ready");
    }
  }, [isLotStarted]);
  const LotBidAmount = () => {
    var lotBidText = "Starting Bid";
    if (lot && lot.bidAt) {
      lotBidText = "Current Bid";
    }
    if (isKnockedDown) {
      lotBidText = "Highest Bid";
    }

    return (
      <>
        <Box display="flex" pt={1}>
          <Box flexGrow={1}>
            <Typography
              variant="subtitle1"
              style={{ fontWeight: "400", color: palette.darkMuted }}
            >
              {lotBidText}
            </Typography>
          </Box>

          <Typography variant="h5" style={{ fontWeight: "500" }}>
            <Box display="flex">
              $
              <ReactTextTransition
                text={lotBidAmount.toLocaleString()}
                springConfig={{ stiffness: 50, damping: 20 }}
                noOverflow
              />
            </Box>
          </Typography>
        </Box>
        <Typography>
          {lot && lot.bidCount ? lot.bidCount + " bids" : "No bids"}
        </Typography>
      </>
    );
  };

  if (lotStatus !== "success") {
    return null;
  }

  if (lotBidAmount === undefined) {
    return null;
  }

  if (!isLot) {
    return <> This item has not been assigned a lot.</>;
  } // This item is not in an auction.

  if (auth.user && buyerBidNext === undefined) {
    return (
      <>
        {" "}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Skeleton variant="rect" width={"100%"} height={100} />
          </Grid>
          <Grid item xs={12}>
            <Skeleton variant="rect" width={"100%"} height={300} />
          </Grid>
          <Grid item xs={12}>
            <Skeleton variant="rect" width={"100%"} height={300} />
          </Grid>
        </Grid>
      </>
    );
  }

  if (auctionState === false) {
    return null;
  }
  var lotBidText = "Starting Price";

  if (auction === null) {
    return null;
  }

  //// CART DISPLAY

  if (flavour === "cart") {
    return (
      <>
        {isLotStarted && isAuctionRunning && !isKnockedDown && !isPaid && (
          <>
            <LotPrice flavour={"current_price"} lot={lot} />
          </>
        )}{" "}
      </>
    );
  }

  return (
    <>
      {isLotStarted && isLotStarted === false && (
        <>
          <Box display="flex" py={1}>
            <Box flexGrow={1}>
              <Typography variant="subtitle1" style={{ fontWeight: "500" }}>
                Sale starts at:
              </Typography>
            </Box>
            <Typography variant="subtitle1" style={{ fontWeight: "400" }}>
              {lot.startsAt === undefined
                ? "In Preview"
                : humanTime(lot.startsAt)}
            </Typography>

            <Typography
              variant="subtitle1"
              style={{ fontWeight: "400", paddingRight: "5px" }}
            >
              {" "}
            </Typography>
          </Box>
        </>
      )}

      <Box py={1}>
        <Card variant="outlined" className={classes.card}>
          {lot &&
            isLotStarted &&
            isAuctionRunning &&
            !isKnockedDown &&
            !isPaid && (
              <CardHeader
                className={classes.cardHeader}
                title={
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    {lot.type === "declining_price" && (
                      <>
                        <ScheduleIcon />
                        &nbsp;
                        <Typography variant="h6">
                          {" "}
                          Next Price Drop <PriceDropClockTime />
                        </Typography>
                      </>
                    )}

                    {item?.listingType === "marketplace_price" && (
                      <>
                        <StorefrontIcon />
                        &nbsp;
                        <Typography variant="h6">
                          {" "}
                          <EventName event={item.auction} />
                        </Typography>
                      </>
                    )}
                  </div>
                }
              />
            )}

          <CardContent className={classes.cardContent}>
            <Box>
              {lot &&
                lot.accepted === true &&
                lot.type === "declining_price" && (
                  <>
                    <LotPrice flavour={"horizon_price"} lot={lot} />
                  </>
                )}

              {lot &&
                lot.accepted === true && item &&
                item.listingType === "marketplace_price" && (
                  <>
                    <Box display="flex" alignItems="center">
                      <Typography variant="h5" style={{ fontWeight: "700" }}>
                        <LotPrice
                          flavour={"marketplace_price"}
                          spice={"simple"}
                          lot={lot}
                          item={item}
                        />{" "}
                      </Typography>

                    
                      {(item.soldStatus === "unsold" || item.soldStatus == null) && (
                      <Typography
                        variant="subtitle1"
                        color="textSecondary"
                        style={{ marginLeft: "5px" }}
                      >
                        | Asking Price
                      </Typography>
                      )}
                    </Box>

                
                  </>
                )}
            </Box>

            <Box pt={3}>
              <ItemAction item={item} event={event} lot={lot} mode={mode} />
            </Box>

            {!(isKnockedDown || isPaid) && (
              <Box pt={1}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                    justifyContent: "center",
                  }}
                >
                  <PeopleIcon />
                  <Typography variant="subtitle1">
                    <ImpressionCount
                      item={item}
                      onChange={(e) => handleImpressionsChange(e)}
                    />
                  </Typography>
                </div>
              </Box>
            )}
          </CardContent>
        </Card>
      </Box>

      {!(isKnockedDown || isPaid) && (
        <>
          {lot.type === "declining_price" && (
            <>
              <Box pt={1}>
                <Card variant="outlined" className={classes.card}>
                  <CardHeader
                    className={classes.cardHeader}
                    title={
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexWrap: "wrap",
                        }}
                      >
                        <TrendingDownIcon />
                        &nbsp; <Typography variant="h6">Price Drops</Typography>
                      </div>
                    }
                    subheader={
                      <Typography
                        variant="subtitle1"
                        style={{
                          display: "inline-block",
                          // alignItems: "center",
                          // flexWrap: "wrap",
                        }}
                      >
                        Price reduced everyday at <PriceDropClockTime />, until
                        sold. &nbsp;
                        <span style={{ display: "inline-block" }}>
                          <PriceDropGuideDrawer />
                        </span>
                      </Typography>
                    }
                  />

                  <CardContent className={classes.cardContent}>
                    <LotPricesTable lot={lot} />
                  </CardContent>
                </Card>
              </Box>
            </>
          )}
        </>
      )}

      {isLotStarted && isAuctionRunning && <></>}
    </>
  );

  //  if (itemData === undefined) {
  //    return null;
  //  }
}

export default LotBuy;
