import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { Typography, Box, useTheme, useMediaQuery } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import DescriptionIcon from "@material-ui/icons/Description";
import EventIcon from "@material-ui/icons/Event";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";

const useStyles = makeStyles((theme) => ({
  // list: {
  //   width: '380px',
  //   [theme.breakpoints.down("xs")]: {
  //     width: '100vw'
  //   },
  // },
  endIcon: {
    margin: 0,
  },
  fullList: {
    width: "auto",
  },
  fontFamily: {
    fontFamily: '"Cormorant Garamond", "serif"',
    // fontWeight: 500,
  },

  toolbar: {
    justifyContent: "space-between",
  },
  title: {
    flexGrow: 1,
    textAlign: "center",
  },
  paper: {
    width: "380px",
    borderRadius: "15px 0px 0px 15px",
    [theme.breakpoints.down("xs")]: {
      borderRadius: "0px",
      width: "100vw",
    },
  },
}));

export default function SellerInfoDrawer(props) {
  const classes = useStyles();
  const theme = useTheme();
  // const { event } = props;
  const { profileData } = props;
  const seller = props.profileData;
  //const { title, subtitle, pickupandshipping, review, startTime, endTime, event, location } = props.event;
  const isSmallOrLess = useMediaQuery(theme.breakpoints.up("md"));

  const [state, setState] = React.useState({
    // top: false,
    // left: false,
    // bottom: false,
    right: false,
  });

  const removeMd = require("remove-markdown");
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  useEffect(() => {
    console.log("profile data", profileData);
  }, [profileData]);

  return (
    <>
      {["right"].map((anchor) => (
        <React.Fragment key={anchor}>
          <span
            style={{ cursor: "pointer", textDecoration: "underline dotted" }}
            onClick={toggleDrawer(anchor, true)}
          >
            {profileData?.name}
          </span>

          <Drawer
            classes={{
              paper: classes.paper,
            }}
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            <Toolbar className={classes.toolbar}>
              <Typography className={classes.title}>Seller Info</Typography>
              <IconButton onClick={toggleDrawer(anchor, false)}>
                <CloseIcon />
              </IconButton>
            </Toolbar>
            <Box pb={1}>
              <Divider />
            </Box>
            <List>
              <ListItem>
                <ListItemIcon>
                  <DescriptionIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Seller"
                  secondary={removeMd(seller?.name)}
                />
              </ListItem>

              <ListItem>
                <ListItemIcon>
                  <DescriptionIcon />
                </ListItemIcon>
                <ListItemText primary="About" secondary={seller?.headline} />
              </ListItem>

              <ListItem>
                <ListItemIcon>
                  <LocalShippingIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Contact Name"
                  secondary={removeMd(seller?.contactName)}
                />
              </ListItem>

              <ListItem>
                <ListItemIcon>
                  <LocalShippingIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Phone"
                  secondary={removeMd(seller?.contactNumber)}
                />
              </ListItem>

              <ListItem>
                <ListItemIcon>
                  <EventIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Email"
                  secondary={removeMd(seller?.profileEmail)}
                />
              </ListItem>
            </List>
          </Drawer>
        </React.Fragment>
      ))}
    </>
  );
}
