import { useState, useEffect} from "react";
import { useRouter } from "./../util/router.js";
import { Box, Typography, Divider, Container } from "@material-ui/core";
import { useAuth } from "./../util/auth.js";
import { useCart } from "./../util/cart.js";
import OrderInformation from "./OrderInformation.js";
import ItemIdCard from "./../components/ItemIdCard";
import { Button, Grid } from "@material-ui/core";
// import LoginModal from "./LoginModal";
import { Link } from "./../util/router.js";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Section from "./Section";
//import { useItem, getItemById, getPriceById } from "./../util/db.js";
import { useUserOrderById } from "./../util/db.js";
import DashboardPage from "./../pages/dashboard";

const OrderSummary = (props) => {
  const { orderId } = props;
  const { clearCart } = useCart();
  const auth = useAuth();
const userId = auth.user.uid;
  const router = useRouter();
  const [cartCount, setCartCount] = useState();
  const [missedCount, setMissedCount] = useState();
  const [skippedCount, setSkippedCount] = useState();

  const [itemLots, setItemLots] = useState();
  const [missedItemLots, setMissedItemLots] = useState();
  const [skippedItemLots, setSkippedItemLots] = useState();

  const [invoiceId, setInvoiceId] = useState();

  const [cartItemLots, setCartItemLots] = useState();

  const [status, setStatus] = useState();

  const [totalDetails, setTotalDetails] = useState();

  const {
    data: order,
    status: orderStatus,
    error: orderError,
  } = useUserOrderById(parseInt(orderId), userId);




  // Keep track of number of updates.
  // So they can be limited if needs be.
  const [count, setCount] = useState(0);
  useEffect(() => {
    console.log("OrderSummary data", orderId, userId, order, orderStatus, orderError);
  }, [orderId, order, orderError, userId, orderStatus]);

  useEffect(() => {
    console.log("OrderSummary missedItems", missedItemLots);
  }, [missedItemLots]);

  useEffect(() => {
    console.log("OrderSummary order", order);
    if (order === undefined) {
      return;
    }

    if (order.length !== 1) {
      return;
    }

    

setInvoiceId(order[0].invoice.id)

    if (order[0].items === undefined) {
      return;
    }

    const items = order[0].items;
    console.log("OrderSummary items", items);

    items.map((item) => {
      console.log("OrderSummary item", item);
    });

    const missed = items.filter((item) => {
      return item.status === "missed";
    });

    const skipped = items.filter((item) => {
      return item.status === "skipped";
    });

    const cart = items.filter((item) => {
      return item.status === "processed";
    });

    setCartCount(items.length);
    setMissedCount(missed.length);
    setSkippedCount(skipped.length);

    setCartItemLots(cart);
    setMissedItemLots(missed);
    setSkippedItemLots(skipped);

    console.log("OrderSummary cart", cart);
    console.log("OrderSummary missed", missed);
    console.log("OrderSummary skipped", skipped);

    setStatus("success");
  }, [order]);



useEffect(() =>{
console.log("OrderSummary cartItemLots", cartItemLots)

}, [cartItemLots]);
useEffect(() =>{

  console.log("OrderSummary order", order)
  //const matching

}, [order])

  function handleClearShoppingCart() {
    clearCart();
  }


  // Is the person authenticated. They should see any orders.
  // So they should get the sign-in page.


  
  // cart code?


  // Not their order.


// This is the no-order-found condition.

// if (orderStatus === 'success' && order.length === 0) {
//    return (<NotFoundPage/>)
// }

if (orderStatus === 'success' && order.length === 0) {
  return (<DashboardPage filter={"orders"} />)
}


  if (status !== "success") {
    return (null);
  }

  


  if (cartCount + missedCount !== 0) {
    return (
      <Section>
      <Container disableGutters>
        {orderId === "out-of-stock" && <>OUT OF STOCK</>}
        <Box
          sx={{
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >



 <Box pb={2} display='flex' justifyContent='flex-end'>
<Button variant="outlined" size="small" component={Link} to={"/orders"} endIcon={<ExitToAppIcon />}>
        View Orders and Invoices
      </Button>
      </Box>

          <Grid container spacing={5}>
            <Box clone order={{ xs: 2, sm: 2, md: 1 }}>
              <Grid item xs={12} sm={12} md={12}>
                {cartItemLots && cartItemLots.length > 0 && (
                <Typography gutterBottom variant="h5">
                Order placed, thank you. 
              </Typography>
                )}

                <Typography gutterBottom variant="subtitle1">
                  Order Number: {orderId}
                </Typography>
{false && invoiceId && (
                <OrderInformation  group={{invoice:invoiceId}} />) }
{false && invoiceId && (<>{invoiceId}</>)}
{/*
{cartItemLots && (
<ItemsIds
                      items={cartItemLots.map((cartItemLot)=>{return {id:cartItemLot.id}})}
                      spice={["address", "noremove"]}
                    />
)}
*/}


                {cartItemLots.map((cartItemLot, index) => {
                  console.log("ShoppingCart item", cartItemLot);
                  return (
                    <>



                      <ItemIdCard
                        itemId={cartItemLot.id}
                        flavour="payment-confirmation"
                        spice={["address", "noremove"]}
                      />
                    </>
                  );
                })}
              


              </Grid>
            </Box>
          </Grid>
          {missedItemLots && missedItemLots.length > 0 && (
            <>
              <Box display="inline-flex" alignItems="baseline" pt={3}>
                <Typography variant="subtitle2">
                  Sorry, these items just sold to someone else.
                  <Typography variant="subtitle2" color="textSecondary">
                    (This happens if another order was placed just ahead of
                    yours. No charge will be made to your card for these items.)
                  </Typography>
                </Typography>
              </Box>
            </>
          )}
          {missedItemLots &&
            missedItemLots.length > 0 &&
            missedItemLots.map((missedItemLot, index) => {
              if (missedItemLot === undefined) {
                return;
              }
              //console.log("items missed item", missedItemLot.item);
              return (
                <>
                  <Box>
                    <ItemIdCard
                      itemId={missedItemLot.id}
                      flavour="payment-confirmation"
                    />
                  </Box>
                </>
              );
            })}
          {skippedItemLots && skippedItemLots.length === 1 && (
            <>
              <Box pt={1}>
                <Typography variant="subtitle2">
                  Sorry there was an issue with purchasing the item below.
                  Please contact customer support for assistance.
                </Typography>
              </Box>
            </>
          )}

          {skippedItemLots && skippedItemLots.length > 1 && (
            <>
              <Box pt={1}>
                <Typography variant="subtitle2">
                  Sorry there was an issue with purchasing the items below.
                  Please contact customer support for assistance.
                </Typography>
              </Box>
            </>
          )}

          {skippedItemLots &&
            skippedItemLots.length > 0 &&
            skippedItemLots.map((skippedItemLot, index) => {
              if (skippedItemLot === undefined) {
                return;
              }
              //console.log("items missed item", missedItemLot.item);
              return (
                <>
                  <Box>
                    <ItemIdCard
                      itemId={skippedItemLot.id}
                      flavour="payment-confirmation"
                    />
                  </Box>
                </>
              );
            })}
        </Box>

        <Box pt={4}>
          <Typography variant="subtitle1" >
            Pickup and Shipping 
          </Typography>
          <Box pb={1}>
          <Divider />
          </Box>
          <Typography variant="body1" >
          For pickup & shipping details and seller contact information, please visit your <Box style={{textDecoration:'underline'}} component={Link} to="/orders" > orders and invoices page</Box>. Please contact the seller directly regarding pickup & shipping.
          </Typography>
        </Box>

        {/*          <ItemsListDelivery /> */}
      </Container>
      </Section>
    );
  }

  return (
    <>
      <Box py={4} sx={{ textAlign: "left" }}>
        <Typography variant="h4">Page no longer exists.</Typography>
      </Box>
    </>
  );
};

export default OrderSummary;
