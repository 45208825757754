import { memo } from 'react'
import { Skeleton } from '@material-ui/lab'

const styles = {
  display: 'inline-block',
  transform: 'rotate(-2deg)',
  WebkitTransform: 'rotate(-2deg)',
}
export const BoxDragPreview = memo(function BoxDragPreview({ title }) {

  return (
    <div style={styles}>
      <Skeleton width={150} height={150} />
    </div>
  )
})
