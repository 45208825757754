import React from "react";
import ReactDom from "react-dom";
// import '@formatjs/intl-datetimeformat/polyfill'
// import '@formatjs/intl-datetimeformat/locale-data/en' // locale-data for en
// import '@formatjs/intl-datetimeformat/add-all-tz' // Add ALL tz data

import App from "./pages/_app";
import * as serviceWorker from "./serviceWorker";
import "./index.css";
import 'core-js/stable';



// replace console.* for disable log on production
 if ((process.env.REACT_APP_ENGINE_STATE === 'production') || (process.env.REACT_APP_ENGINE_STATE === 'pre_launch')) {
    console.log = () => {}
    console.error = () => {}
    console.debug = () => {}
    console.warn = () => {}
 }


ReactDom.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
