import React, { useEffect } from "react";


import { cutoffItemsByScore } from "./../util/sort.js";


function CutoffItems(props) {

const {minimumScore, minimumItems, maximumItems, items} = props;


  const updateWithprops = React.Children.map(props.children, (child, i) => {

const cutoffItems = cutoffItemsByScore(props.items, minimumScore, minimumItems, maximumItems);

    return React.cloneElement(child, { color: "red", items: cutoffItems });
  });



useEffect(()=>{

console.log("CutoffItems props.items", props.items);

}, [props.items]);



  return <div>{updateWithprops}</div>;
}

export default CutoffItems;
