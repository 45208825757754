import React, { useEffect, useState } from "react";
import { useAuth } from "./../util/auth.js";

import { Button } from "@material-ui/core";
import { useForm } from "react-hook-form";
//import ConnectPayment from "./../components/ConnectPayment";

import PaymentReceiveSettings from "./../components/PaymentReceiveSettings";
import PaymentSendSettings from "./../components/PaymentSendSettings";
import { Divider } from "@material-ui/core";
//import firebase from "../util/firebase";
import { getFunctions, httpsCallable } from "firebase/functions";

const functions = getFunctions();

const processStripePortal = httpsCallable(functions, "processStripePortal");

function SettingsBilling(props) {
  const auth = useAuth();

  const [showSkeleton, setShowSkeleton] = useState();

  const [pending, setPending] = useState(false);

  const { register, handleSubmit, errors } = useForm();
  const isEnabledSeller = auth.user && auth.user.isEnabledSeller;

  const hasCredit = false;
  const {message} = props;
  /*

*/
  const [url, setUrl] = useState();

  useEffect(() => {
    const u = processStripePortal()
      .then((data) => {
        console.log("dta", data);
        setUrl(data.data.url);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const StripeCustomerPortalButton = (props) => {
    if (url === undefined) {
      return <>LOADING</>;
    }

    return (
      <Button>
        <a href={url}>CARD SETUP</a>
      </Button>
    );
  };

  useEffect(() => {
    console.log("url", url);
  }, [url]);

  const onSubmit = (data) => {
    // Show pending indicator
    setPending(true);

    return auth
      .updateProfile(data)
      .then(() => {
        // Set success status
        props.onStatus({
          type: "success",
          message: "Your profile has been updated",
          source:'billing',
        });
      })
      .catch((error) => {
        if (error.code === "auth/requires-recent-login") {
          props.onStatus({
            type: "requires-recent-login",
            source: "billing",
            // Resubmit after reauth flow
            callback: () => onSubmit(data),
          });
        } else {
          // Set error status
          props.onStatus({
            type: "error",
            message: error.message,
            source:"billing",
          });
        }
      })
      .finally(() => {
        // Hide pending indicator
        setPending(false);
      });
  };

  function handleStatus(s) {
    console.log("SettingsBilling handleStatus", s);
    props.onStatus({...s, source:"billing"});
    setShowSkeleton(s === undefined);
  }

  if (pending) {
    return <>LOADIG SPNNER NOTHING HERE YET ETC</>;
  }

  return (
    <>
      {(message === 'connect-expired') && (<></>)}
      {/* <Container > */}
      {auth.user && auth.user.seller && auth.user.seller.enabled && (
        <>
          <PaymentReceiveSettings onStatus={(s) => handleStatus(s)} />
          <Divider />
        </>
      )}

      {/* (<>ASK US ABOUT SELLING?<Divider /></>)} */}

      {/* <SettingsBillingAddress /> */}

      {/* <StripeCustomerPortalButton /> */}


      {hasCredit && (<PaymentSendSettings onStatus={(s) => handleStatus(s)} />)}
{auth && auth.seller && auth.seller.customer}
      {/* <ConnectPayment /> */}
      {/* </Container> */}
    </>
  );
}
export default SettingsBilling;
