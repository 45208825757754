import React from "react";
import Section from "./Section";
import Container from "@material-ui/core/Container";
import { Box, Divider, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  // Increase <Container> padding so it's
  // at least half of <Grid> spacing to
  // avoid horizontal scroll on mobile.
  // See https://material-ui.com/components/grid/#negative-margin
  container: {
    padding: `0 ${theme.spacing(3)}px`,
  },
  image: {
    margin: "0 auto",
    // maxWidth: 570,
    display: "block",
    height: "auto",
    width: "100%",
  },
  title: {
    fontWeight:400,
    fontFamily: '"Cormorant Garamond", "serif"',
    textAlign: 'left',
    letterSpacing: '-1px',
   // color:'#ffffff',
  },
  subtitle: {
    textAlign:'left',
    // fontWeight: 300,
    // lineHeight: "1.25em",
    // color:'#ffffff',
  },
  mobiletitle: {
    // fontWeight: 500,
    lineHeight: "1.25em",
    // fontFamily: '"Cormorant Garamond", "serif"',
  },
  mobilesubtitle: {

    lineHeight: "1.25em",
  },
}));


function HeroSection2(props) {
  const classes = useStyles();
  return (

    <Box pt={2}>
    <Section
      // style={{backgroundColor:'#e9a41e8f'}}
      bgColor={props.bgColor}
      size="auto"
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      
    >
      <Container 
   
      >
        <Box textAlign="left" >
        <Typography
                variant="h4"
                className={classes.title}
                gutterBottom={props.subtitle ? true : false}
                
              >
                {props.title}
              </Typography>

              {props.subtitle && (
                <Typography variant="h6" color="textSecondary" className={classes.subtitle}>
                  {props.subtitle}
                </Typography>
              )}


        </Box>
        <Box py={1}>
 <Divider style={{height:'1px'}} />

 </Box>
 <Divider style={{height:'1px'}} />
      </Container>
    </Section>
    </Box>
  );
}

export default HeroSection2;
