import React, { useMemo, useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import { useAuth } from "./../util/auth.js";
import { useItemsByEvent, useProfile } from "./../util/db.js";
import { makeStyles } from "@material-ui/core/styles";
import ItemsGrid from "./../components/ItemsGrid";


import { privateCatchallFlag } from "./../util/dev.js";

const useStyles = makeStyles((theme) => ({
  paperItems: {
    minHeight: "300px",
  },
  featured: {
    backgroundColor:
      theme.palette.type === "dark" ? theme.palette.action.selected : "#fdf8c2",
  },
  starFeatured: {
    color: theme.palette.warning.main,
  },
}));

const initialItems = [
  {
    id: false,
    name: "noname",
    accepted: true,
  },
  {
    id: false,
    name: "noname",
    accepted: true,
  },
  {
    id: false,
    name: "noname",
    accepted: true,
  },
  {
    id: false,
    name: "noname",
    accepted: true,
  },
];

function EventItemsGrid(props) {
  const { filter, onEmpty, items:injectItems } = props;

  var { flavour, event, searchQuery } = props;
  //  flavour = flavour === undefined ? "standard" : "simple";

  const classes = useStyles();

  const auth = useAuth();
  const isDeveloper = auth?.user?.developer?.enabled;

  //const [thumbStatus, setThumbStatus] = useState("loading");

  //const auth = useAuth();

  const eventId = event && event.id;



  // Is this the right call.
  const {
    data: items,
    status: itemsStatus,
    error: itemsError,
  } = useItemsByEvent(eventId);
const {
  data: profileData,
  status: profileStatus,
  error: profileError,
} = useProfile(event.owner);

  const firstRender = useMemo(
    () => console.log("EventItemsGrid eventId first render", eventId),
    []
  );

  const [filteredItems, setFilteredItems] = useState();
  const [filteredInjectedItems, setFilteredInjectedItems] = useState();

  // const [filteredItemsStatus, setFilteredItemsStatus] = useState("loading");
  /*
  function order(a, b) {
    const aInt = intFromBase64String(a.id);
    const bInt = intFromBase64String(b.id);
    return aInt < bInt ? -1 : (aInt > bInt ? 1 : 0);
}
*/
  useEffect(() => {
    if (items === undefined) {
      return;
    }
    if (items && itemsStatus !== "success") {
      return;
    }


  //  console.log("EventItemsGrid items", items);
    var catchallItems = [];
    var tempItems = items;

    if (filter?.type ==='auction') {
tempItems = items.filter((item)=>{return (filter.id === item.auction);});
    }
    
    //var tempItems = items.sort((a,b) => a.id < b.id ?1:-1);
    tempItems.sort((a, b) => (a.id < b.id ? 1 : -1));
    if (filter === "star") {
      //tempItems = items;
      console.log("items", items);
      tempItems = items.filter((item) => item.starred === true);

    }
    /*
    const sortItems = tempItems.sort((a,b)=>{
      const aInt = intFromBase64String(a.id);
      const bInt = intFromBase64String(b.id);
      return aInt < bInt ? -1 : (aInt > bInt ? 1 : 0);  
      
    })
*/


console.log("EventItemsGrid test event", event);
if (privateCatchallFlag === true && event.private === true) {
console.log("EventItemsGrid event private");
catchallItems = tempItems;
tempItems = [];


}

if (privateCatchallFlag === true && catchallItems && catchallItems.length > 0 && props.onCatchall) {

if (profileData && profileData.event !== eventId) {
// TODO: catchall events. Start from here Causes a runaway.
// So see why this is getting triggered frequently.
props.onCatchall(catchallItems);
}

if (props.onSeller) {

props.onSeller(event.owner);

}


}


// For the default Event

//if (typeof injectItems !== 'undefined') {
//tempItems = [...tempItems, ...injectItems];
//}
//if (props.onCatchall) {

// TODO: catchall events. Start from here Causes a runaway.
//props.onCatchall(catchallItems);

//}

//tempItems = [...tempItems, ...injectItems];


    setFilteredItems(tempItems);

  }, [event,items, itemsStatus]);

useEffect(() =>{
if (filteredItems == null) {return;}
if (injectItems == null) {setFilteredInjectedItems(filteredItems); return;}
setFilteredInjectedItems([...filteredItems, ...injectItems]);

}, [filteredItems, injectItems]);

useEffect(()=>{
if (injectItems == null) {return;}

console.log("EventItemsGrid injectItems", injectItems);

}, [injectItems]);

useEffect(() =>{

console.log("EventItemsGrid searchQuery", searchQuery);

}, [searchQuery]);


   useEffect(() => {
     console.log("EventItemsGrid items eventId", eventId);
     console.log("EventItemsGrid items", filteredItems);
     console.log("EventItemsGrid filteredItems?length", filteredItems?.length)

   }, [filteredItems]);

function handleCountChange(d) {
  if (!props.onEmpty) {return;}
  if (d === 0) {props.onEmpty(true);} else {
    props.onEmpty( false);
    }

}

function handleCardCount(c) {
console.log("EventItemsGrid handleCardCount", c);
if (props.onCardCount) {

props.onCardCount(c);

}

}

   useEffect(() => {
    
    console.log("EventItemsGrid event", event);
  }, [event]);



  // const [height, setHeight] = useState(0)
  // const ref = useRef(null)
  
  // useEffect(() => {
  //   setHeight(ref.current.clientHeight)
  //   console.log("height", height)
  // })


//return (<>HEY</>);

//if (!isDeveloper && event.test !== true) {
//return null;

//}

  return (
    <>

 {/* {isDeveloper ? "IS DEVELOPER" : "IS NOT DEVELOPER"}
 {event.test ? "IS TEST" : "IS NOT TEST"} */}
      {itemsError && (
        <Box mb={3}>
          <Alert severity="error">{itemsError.message}</Alert>
        </Box>
      )}

      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      ></Box>

      <>
        {/*<LazyLoad
        height={200}
        offset={0}
        placeholder={<Skeleton variant="rect" height={200} />}
      > */}
{/* <div ref={ref}> */}
        <ItemsGrid items={filteredInjectedItems} flavour={flavour} event={event} onCardCount={(c)=>handleCardCount(c)} onCountChange={(d)=>handleCountChange(d)} searchQuery={searchQuery} />
        {/* </div> */}
        {/*   </LazyLoad> */}
      </>

      {/* </Container> */}
      {/* </Section> */}
    </>
  );
}

export default EventItemsGrid;
