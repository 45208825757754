import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import List from "@material-ui/core/List";
import EditEventModal from "./EditEventModal";
import { useAuth } from "./../util/auth.js";
import { updateEvent, useEventsByOwner } from "./../util/db.js";
import { makeStyles } from "@material-ui/core/styles";
import { forceCheck } from "react-lazyload";
import Event from "./Event";
import PrivateEventDialog from "./PrivateEventDialog";
import { privateCatchallFlag } from "./../util/dev.js";
import { useMediaQuery } from "@material-ui/core";


const prefix = process.env.REACT_APP_WEB_PREFIX;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paperItems: {
    minHeight: "300px",
    background: "transparent",
  },
  featured: {
    backgroundColor:
      theme.palette.type === "dark" ? theme.palette.action.selected : "#fdf8c2",
  },
  starFeatured: {
    color: theme.palette.warning.main,
  },
  collections: {
    display: "flex",
    borderBottom: "0px",
  },
  flexContainer: {
    display: "flex",
    flexDirection: "row",
    padding: 0,
  },
}));

function EventList(props) {
  const classes = useStyles();

  const auth = useAuth();

  const isAuctioneer = auth.user.role && auth.user.role === "auctioneer";

  const eventTypeWordSingular = isAuctioneer ? "Sale" : "Collection";
  const eventTypeWordPlural = isAuctioneer ? "Sales" : "Collections";
  const isXsScreen = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const {
    data: events,
    status: eventsStatus,
    error: eventsError,
  } = useEventsByOwner(auth.user.uid);

  // const {
  //   data: profileData,
  //   status: profileStatus,
  //   error: profileError,
  // } = useProfile(auth.user.uid);

  // useEffect(() => {
  //   console.log("profileData", profileData);
  // }, [profileData]);

  const [creatingEvent, setCreatingEvent] = useState(false);

  const [updatingEventId, setUpdatingEventId] = useState(null);

  const eventsAreEmpty = !events || events.length === 0;

  const canUseStar =
    auth.user.planIsActive &&
    (auth.user.planId === "pro" || auth.user.planId === "business");

  // const canUsePublish = isAuctioneer;

  const handleStarEvent = (event) => {
    if (canUseStar) {
      updateEvent(event.id, { featured: !event.featured });
    } else {
      alert("You must upgrade to the pro or business plan to use this feature");
    }
  };

  return (
    <>
     {eventsError && (
        <Box mb={3}>
          <Alert severity="error">{eventsError.message}</Alert>
        </Box>
      )}

      <Box
        my={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography className={classes.text} variant="h5"></Typography>
        <Button
          variant="contained"
          size="large"
          color="primary"
          disableElevation
          fullWidth={isXsScreen}
          onClick={() => setCreatingEvent(true)}
        >
          {"Create new " + eventTypeWordSingular}
        </Button>
      </Box>

   


{/* <Box py={2}>
      <TextField variant="outlined"  placeholder="CATCH-ALL COLLECTION HERE"  /> 
      </Box> */}

{privateCatchallFlag === true && (
      <PrivateEventDialog onUpdate={()=>{forceCheck()}}  />
)}

      {(eventsStatus === "loading" || eventsAreEmpty) && (
        <Box py={5} px={3} align="center">
          {eventsStatus === "loading" && <CircularProgress size={32} />}

          {eventsStatus !== "loading" && eventsAreEmpty && (
            <Typography variant="h5">Add your first event.</Typography>
          )}
        </Box>
      )} 

      {eventsStatus !== "loading" && events && events.length > 0 && (
         <List disablePadding={true}>
    
          {events.map((event, index) => (<Event key={index} event={event} />

          ))}
      
        </List>
      )}



          {/* {events && events.map((event, index) => 
             (
            <LazyLoad height="100%" offset={100}><Box style={{height:'400px', width:'100%', border:'1px solid black'}}>This is a box</Box></LazyLoad>
            )
          )} */}
 
      {creatingEvent && (
        <EditEventModal onDone={() => setCreatingEvent(false)} />
      )}

      {updatingEventId && (
        <EditEventModal
          id={updatingEventId}
          onDone={() => setUpdatingEventId(null)}
        />
      )}
    </>
  );
}

export default EventList;
