import { useState, useEffect } from "react";
import { Button } from "@material-ui/core";
import { useCart } from "./../util/cart.js";
import { capitalizeFirstLetter } from "./../util/text.js";
import { debugFlag } from "./../util/dev.js";
import { createItem, createLot, useAuctionsByFeatured, useLotByItem, useItem } from "./../util/db.js";
import { useAuth } from './../util/auth.js';

import { isItemLotBuyable } from "./../util/lot.js";


import { toast } from "react-toastify";

//const debugFlag = process.env.REACT_APP_DEBUG && process.env.REACT_APP_DEBUG === "on" ? true : false;
//const debugFlag = true;
const ShoppingCartTest = () => {


  const [itemId, setItemId] = useState();
  const [ready, setReady] = useState();

  const {
    data: cartData,
    status: cartStatus,
    error: error,
    clearCart,
    addItemLot,
    //} = useCart(10000, 20);
  } = useCart();



  const {
    data: lot,
    status: lotStatus,
    error: lotError,
  } = useLotByItem(itemId)

  const {
    data: item,
    status: itemStatus,
    error: itemError,
  } = useItem(itemId)



  const {
    data: auctions,
    status: auctionsStatus,
    error: auctionsError,
  } = useAuctionsByFeatured();

  const auth = useAuth();

  // const [itemLots, setItemLots] = useState();
  // const [cartItemLots, setCartItemLots] = useState();

  const cartItemLots = cartData && cartData.cartItems;
  const missedItemLots = cartData && cartData.missedItems;
  const unavailableItemLots = cartData && cartData.unavailableItems && cartData.unavailableItems
  .filter((unavailableItem)=>{

    if (unavailableItem.lot == null) {return true;}
    if (unavailableItem.lot.bidOwner === auth.user.uid) {return false;}
    return true});

  //  const cartItemLots = cartData && cartData.cartItems;
  const allItemLots = cartData && cartData.allItems;

  const cartCount = cartData && cartData.cartCount;

  const { cartTotal, cartFormattedTotal } = cartData;

  const [status, setStatus] = useState();
  const [cartUpdateFlag, setCartUpdateFlag] = useState();
  const [totalDetails, setTotalDetails] = useState();

  useEffect(() => {
    console.log("ShoppingCartTest cartData", cartData);
  }, [cartData]);
  // Keep track of number of updates.
  // So they can be limited if needs be.
  //const [count, setCount] = useState(0);


  function handleCheckoutTest() {
if (ready === false) {
toast('already creating');
return;}
setReady(false);
    const adjectives = ["oak", "beech", "white", "pine", "red", "blue", "green", "yellow", "purple", "shiny"];
    const nouns = [
      "table",
      "chair",
      "drawers",
      "mirror",
      "seat",
      "armchair",
      "book",
      "rug",
      "light",
      "desk",
      "chaise lounge",
      "bench",
      "sidetable",
      "cupboard"
    ];

    const adjective = adjectives[Math.floor(Math.random() * adjectives.length)];
    const noun = nouns[Math.floor(Math.random() * nouns.length)];

    const name =
      "Test " +
      capitalizeFirstLetter(adjective) +
      " " +
      capitalizeFirstLetter(noun);

    const price =
      Math.floor(Math.random() * 100000) + Math.floor(Math.random() * 5000);

    const testItem = {name:name};

    console.log("ShoppingCartTest useAuctionsByFeatured auctions", auctions);

    createItem(testItem)
      .then((result) => {
        //console.log("ShoppingCart handleCheckoutTest result", result.data());
        console.log("ShoppingCart handleCheckoutTest result.id", result.id);

setItemId(result.id);

        const d = new Date();
//const d = firebase.firestore.FieldValue.serverTimestamp();

        //const price=Math.random() * 100000 + Math.random() * 5000;
        //    const price = 19900;
/*
getFirebaseTime().then((result)=>{

console.log("ShoppingCartTest result", result);

}).catch((error)=>{

console.log("ShoppingCartTest error", error);

});
*/
const testLot = {
          item: result.id,
          auction: "F2gE07nuCdg5ykcQDStA",
          lot: result.id,
          accepted: true,
          knockedDownAt: false,
          prices: [
{amount:price, day:2, validAt:d},
//             {amount:2000, day:2, validAt:d.toISOString()},
//            { amount: price, day: 2, validAt: serverTimestamp() },
          ],
          bidOwner:auth.user.id,
          bidAmount:price
        };

/*


            const xdata = {
              type: type,
              index: lotIndex,
              //        lot: lotId,
              //        knockedDownAt: false,
              //        item: items[index].id,
              auction: items[index].auction,
              startsAt: zuluStartsAt,
              endsAt: zuluEndsAt,
            };
            console.log("lot.js bar");

            //        if (!test) {
            console.log("lot.js items index", items[index]);
            console.log("lot.js items[index].id", items[index].id);

            updateLot(items[index].id, xdata)
              .then((result) => {
                console.log("lot.js result", result);
              })
              .catch((error) => {
                console.error("lot.js makeLots updateLot error", error);
              });




*/




        const lot = createLot(testLot);
        lot
          .then((response) => {
            console.log("ShoppingCartTest handleCheckoutTest createLot response", response);

            const testItem2 = {
              ...testItem,
              sku: result.id,
              price: 1,
              test:true,
              //  currency:"USD"
            };

            console.log("ShoppingCartTest handleCheckoutTest testItem2 testLot", testItem2, testLot);

toast("Test item and lot created.");


          })
          .catch((createLotError) => {
            console.error("ShoppingCartTest handleCheckoutTest createLot error", createLotError);
          });
      })
      .catch((createItemError) => {
        console.error("ShoppingCartTest handleCheckoutTest createItemError", createItemError);
      });
    // Use default item set.
    // Make items.
    // Attempt to check them out.
  }

useEffect(() =>{

if (item == null) {return;}
if (lot == null) {return;}
if (ready === true) {return;}

isItemLotBuyable({item:item,lot:lot}).then((result) =>{
console.log("ShoppingCartTest result", result);
}).catch((error)=>{
console.log("ShoppingCartTest error", error);

});

console.log("ShoppingCartTest item lot", item, lot, isItemLotBuyable({item:item,lot:lot}));

toast("Added itemlot to cart.");



            addItemLot(item, lot);
setReady(true);

}, [item && item.id ,lot && lot.id]);


  function handleClearShoppingCart() {
    clearCart();
  }

  return (
    <>
          {debugFlag && (
            <>
{itemId && (<>Made ITEMID {itemId}.</>)}
              <Button onClick={() => handleCheckoutTest()}>
                CLICK TO MAKE A TEST ITEM TO BUY
              </Button>
              <Button onClick={() => handleClearShoppingCart()}>
                CLICK TO CLEAR CART
              </Button>
            </>
          )}
     </>
  );
  //  }
};

export default ShoppingCartTest;
