
import { getFunctions, httpsCallable } from "firebase/functions";
const functions = getFunctions();

const getAffiliations = httpsCallable(
  functions,
  "processDeveloperTest3"
);


export function extractAffiliate(text) {

var query = text.substr(1);
  var result = {};
  query.split("&").forEach(function(part) {
    var item = part.split("=");
    result[item[0]] = decodeURIComponent(item[1]);
  });

console.log("affiliate extractAffiliate result", result);
if (result['ladybcode']) {
    return result['ladybcode'];}

return false;


}

