import React, { useEffect } from "react";
import { Alert } from "@material-ui/lab";
import { humanTime } from "../util/time.js";
import { useAuth } from "./../util/auth.js";
import { makeStyles } from "@material-ui/core/styles";
import { useIdentity } from "../util/identity.js";
import {
  useLotStatus,
  useUncachedAuctionByEvent,
  useSellerAccountStatusByUser,
  useItemLotStatus,
} from "./../util/db.js";
import { forceCheck } from "react-lazyload";

const defaultPalette = { lightVibrant: "#cccccc", darkMuted: "#000000" };

const useStyles = makeStyles((theme) => ({
  root: {
    // minWidth: 275,
    // boxShadow: "1px 0px 4px rgb(0 2 4 / 2%), 0 7px 20px rgb(1 1 1 / 3%)",
    padding: "15px",
    borderColor: "transparent",
    // border: '1px solid',
    "&:hover": {
      borderColor: "#0000001f",
      border: "1px solid",
    },
  },
  "&:hover": {
    borderColor: "red",
  },

  estimate: {
    justifyContent: "space-between",
    // paddingLeft: '16px',
    // paddingRight: '16px',

    display: "flex",
    alignItems: "center",
  },

  media: {
    height: "220px",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },

  paperItems: {
    minHeight: "300px",
  },

  starFeatured: {
    color: theme.palette.warning.main,
  },
  name: {
    overflow: "hidden",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
  },
  StipTitle: {
    textDecoration: "none",
    color: "black",
    fontWeight: 600,
  },
}));

function ItemIssueMessage(props) {
  const { lot, item } = props;

  const {
    status: auctionStatus,
    data: auction,
    error: auctionError,
  } = useUncachedAuctionByEvent(item && item.auction);

  const {
    data: sellerAccountData,
    status: sellerAccountStatus,
    error: sellerAccountError,
  } = useSellerAccountStatusByUser(item.owner);

  const { isLotStarted, isLotEnded } = useLotStatus({ ...lot, ...item });

  const auth = useAuth();
  const { isAuctioneer, isSeller } = useIdentity();
  const isAdministrator =
    auth.user && auth.user.role && auth.user.role === "auctioneer";

  // This code is mirrored in ItemStatus
  // Refactored with the line being tested below.

  /*
const [isSaleAvailable, setIsSaleAvailable] = useState();

const isSaleMissed = item.consigned === true && item.accepted !== true && !lot && !isSaleAvailable;

const {data:eventData, status:eventStatus, error:eventError} = useEvent(item && item.auction);

useEffect(()=>{

if (eventStatus !== 'success') {return;}
setIsSaleAvailable(!isPastTime(eventData.startTime));

}, [eventData, eventStatus]);
*/

  const { isSaleMissed, isSaleAvailable } = useItemLotStatus(item, lot);

  const isDelisted =
    lot && lot.delistedAt && lot.delistedAt !== false ? true : false;

  const isCurator = isAdministrator;

  const isWithdrawn = item && item.withdrawn === true ? true : false;

  if (item && item.palette === undefined) {
    item.palette = defaultPalette;
  }

  const isRunning = auction?.state;
  //const isSellerGood = sellerAccountData === true ? true : false;

  // Assume they are good during loading.
  // Then look for a true response = good standing.
  // A null response means no Stripe onboarding has started.
  const isSellerGood =
    //    sellerAccountData !== null &&
    sellerAccountStatus === "loading" ||
    (sellerAccountStatus === "success" && sellerAccountData === true)
      ? true
      : false;

  //  const isSellerStripeGood = (sellerAccountData === null);

  useEffect(() => {
    console.log(
      "ItemIssueMessage sellerAccountData sellerAccountData",
      sellerAccountData
    );
  }, [sellerAccountData]);

  useEffect(() => {
    console.log("ItemIssueMessage lot", lot);
    //forceCheck();
  }, [lot]);
  // Consider: == null instead.
  // Probably a great of example of chatGPT code.
  //  if (sellerAccountData == null || typeof isDelisted === "undefined" || typeof isLotEnded === "undefined" || typeof isRunning === "undefined" || typeof isLotStarted === "undefined" || typeof isSellerGood === "undefined") {
  //    return null;
  //  }

  // if (sellerAccountData == null) {
  //   return null;
  // }

  // Test here whether component is ready.

  if (lot == null) {
    //  if (lot == null && !isSaleAvailable ) {

    if (isCurator && isSaleMissed) {
      // Item missed sale cutoff. Please withdraw and enter into next sale.
      return (
        <>
          <Alert severity="warning">
            Sale started. Item not approved or rejected.
          </Alert>
        </>
      );
    }

    if (isSeller && isSaleMissed && item.listingType !== "marketplace_price") {
      return (
        <>
          <Alert severity="error">
            Item missed sale cutoff. Please withdraw and enter into next sale.
          </Alert>
        </>
      );
    }

    // Mostly an empty lot isn't a problem.


    return null;
  }

  if (auctionStatus !== "success") {
    return null;
  }
  if (sellerAccountStatus !== "success") {
    return null;
  }

  if (isDelisted) {
    return (
      <>
        <Alert severity="warning">Item removed by seller.</Alert>
      </>
    );
  }

// Need to pick up on the case where lot.accepted is false,
// because the last action by the auctioneer was to reject it,
// but it has just been resubmitted.

// So there should not be a not accepted message. 

  if (lot && lot.accepted === false && item.consigned !== true) {
    return (
      <>
        <Alert severity="warning">Item not accepted by Lady Bidwell.</Alert>
      </>
    );
  }

  if (isWithdrawn) {
    return null;
  }

//// SALE ISSUE MESSAGES /// 

if (item.listingType !== "marketplace_price") {

if (lot && (lot.startsAt !== false && lot.endsAt !== false)) {

  if (isLotEnded) {
    return (
      <>
        <Alert severity="warning"> Sale ended.</Alert>
      </>
    );
  }

  if (!isRunning && isLotStarted && !isLotEnded) {
    return (
      <>
        <Alert severity="error"> Sale paused. Please check back soon.</Alert>
      </>
    );
  }

  if (!isLotStarted) {
//  if (!isLotStarted && lot && lot.startsAt !== false) {
    //console.log("ItemIssueMessage !isLotStarted lot startsAt", lot.item, lot.startsAt)
    if (lot && lot.startsAt) {
      return (
        <>
          <Alert severity="info">
            {" "}
            Sale not started. Sale starts {humanTime(lot.startsAt)}
          </Alert>
        </>
      );
    }

    // If withdrawn, then startsAt should be false.
    // Is it?

    console.log("ItemIssueMessage lot startsAt", lot, lot.startsAt);

    // Is this a good exception to not null but undefined.
    // Looking specifically for the return from Firestore to be showing
    // that the lot creation is incomplete.

    // This will
    // if (!lot) {

    //   return (<>We do not have a lot.</>)

    // }
    //if (typeof window !== "undefined") {

    // if ( typeof (lot && lot.startsAt !== "undefined")) {

    if (lot && lot.startsAt !== undefined) {
      // But there could be the case where the lot is actually being created.
      // ...

      return (
        <>
          <Alert severity="warning">
            {" "}
            Sale started. Withdraw item to enter into a future sale.
          </Alert>
        </>
      );
    }

    return (
      <>
        <Alert severity="info"> Sale not started.</Alert>
      </>
    );
  }

}

}



  if (lot && lot.accepted && !isSellerGood) {

    if (isOwner) {

      return (
        <>
          <Alert severity="warning">Item Currently Unavailable. Issue with your account.</Alert>
        </>
      ); 
    }

    return (
      <>
        <Alert severity="warning">Item Currently Unavailable.</Alert>
      </>
    );
  }

  return null;
}

export default ItemIssueMessage;
