import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Box from "@material-ui/core/Box";
import {
  updateItemPrices,
  useAuctionByEvent,
  useLotStatus,
  useSellerAccountStatusByUser,
} from "./../util/db.js";
import Button from "@material-ui/core/Button";
import { useAuth } from "./../util/auth.js";
import ItemWithdraw from "./ItemWithdraw.js";
import { getPaymentMethodStatus } from "./../util/stripe.js";

import { makeStyles } from "@material-ui/core/styles";
import ItemEdit from "./ItemEdit.js";
import OffersItem from "./../components/OffersItem";


import BuyerBidEdit from "./../components/BuyerBidEdit";
import BuyItem from "./../components/BuyItem";
import PaymentMethodStatus from "./../components/PaymentMethodStatus";

import LotDetails from "./../components/LotDetails";
import SellerReserveEdit from "./../components/SellerReserveEdit";

import { isPastTime } from "../util/time";
import ItemEventAddress from "./../components/ItemEventAddress";
import ItemReview from "./../components/ItemReview";
import SignInButton from "./SignInButton";
import PaymentIcon from "@material-ui/icons/Payment";
import PaymentCardMethodButton from "./../components/PaymentCardMethodButton";
import ItemUnlist from "./../components/ItemUnlist";
import ItemCancel from "./ItemCancel.js";
import AuctioneerReviewItem from "./AuctioneerReviewItem.js";
import { Typography } from "@material-ui/core";
import { ItemResolution } from "./ItemResolution.js";

import OwnerWithdrawItem from "./OwnerWithdrawItem";
import OwnerUnlistItem from "./OwnerUnlistItem";
import { useItemLotStatus } from "./../util/db.js";

const defaultPalette = { lightVibrant: "#cccccc", darkMuted: "#000000" };

const useStyles = makeStyles((theme) => ({
  root: {
    // minWidth: 275,
    // boxShadow: "1px 0px 4px rgb(0 2 4 / 2%), 0 7px 20px rgb(1 1 1 / 3%)",
    padding: "15px",
    borderColor: "transparent",
    // border: '1px solid',
    "&:hover": {
      borderColor: "#0000001f",
      border: "1px solid",
    },
  },
  "&:hover": {
    borderColor: "red",
  },

  estimate: {
    justifyContent: "space-between",
    // paddingLeft: '16px',
    // paddingRight: '16px',

    display: "flex",
    alignItems: "center",
  },

  media: {
    height: "200px",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },

  paperItems: {
    minHeight: "300px",
  },
  // featured: {
  //   backgroundColor:
  //     theme.palette.type === "dark" ? theme.palette.action.selected : "#fdf8c2",
  // },
  starFeatured: {
    color: theme.palette.warning.main,
  },
  name: {
    overflow: "hidden",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
  },
  StipTitle: {
    textDecoration: "none",
    color: "black",
    fontWeight: 600,
  },
}));

function ItemActionRouter(props) {
  const { likes, event, tab, item, lot, mode } = props;

  const debugFlag = process.env.REACT_APP_DEBUG === "on" ? true : false;
  //const debugFlag = true;
  const classes = useStyles();

  const [canBid, setCanBid] = useState();

  const auth = useAuth();

  // Check if the auction is running.
  const { data: auctionData, status: auctionStatus } = useAuctionByEvent(
    item.auction
  );
  const isRunning = auctionData && auctionData.state;

  if (item && item.palette === undefined) {
    item.palette = defaultPalette;
  }

  const isAdministrator =
    auth.user && auth.user.role && auth.user.role === "auctioneer";
  const isOwner = auth.user && auth.user.id === (item && item.owner);
  const isWinner = auth.user && auth.user.id === (item && item.bidOwner);
  const isPaid = lot && lot.paid === true ? true : false;

  const isKnockedDown =
    lot && lot.knockedDownAt && lot.knockedDownAt !== false ? true : false;

  const isLotEnded = lot && isPastTime(lot.endsAt); // Not as important for decling price

  const { isLotStarted } = useLotStatus(lot, event);

  const {
    data: sellerAccountData,
    status: sellerAccountStatus,
    error: sellerAccountError,
  } = useSellerAccountStatusByUser(item.owner);

  //const isLotEnded = lot && isPastTime(lot.endsAt);
  //const isLotStarted = lot && isPastTime(lot.startsAt);

  //  const isKnockedDown =
  //   lot && lot.knockedDownAt && lot.knockedDownAt !== false ? true : false;

  const isDelisted =
    lot && lot.delistedAt && lot.delistedAt !== false ? true : false;
  // SellerAccountData contains just whetehr teh seller has charges_enabled.
  // This comes from a direct call to Stripe.
  // Assume seller is BAD.

  const isSellerGood =
    sellerAccountStatus === "loading" ||
    (sellerAccountStatus === "success" && sellerAccountData === true)
      ? true
      : false;

  const [openDialog, setOpenDialog] = useState(false); // add a state variable to track the open/close state of the dialog

  const {isSaleMissed, isSaleAvailable} = useItemLotStatus(item, lot);

  const handleOpenDialog = () => {
    setOpenDialog(true); // open the dialog
  };

  const handleCloseDialog = () => {
    setOpenDialog(false); // close the dialog
  };

  // Or Assume selller is GOOD until proven otherwise.
  //  const isSellerGood = sellerAccountData == null ? true : (sellerAccountData === true);

  const canBuy =
    isRunning &&
    !isKnockedDown &&
    !isDelisted &&
    isLotStarted &&
    !isLotEnded &&
    lot &&
    lot.type &&
    (lot.type === "declining_price" || lot.type === "marketplace_price") &&
    isSellerGood === true;

  const canOffer = 
    isRunning &&
    !isKnockedDown &&
    !isDelisted &&
    isLotStarted &&
    !isLotEnded &&
    lot &&
    lot.type &&
    lot.type === "marketplace_price" &&
    isSellerGood === true;


  //const isLotStarted = true;

  useEffect(() => {
    console.log("ItemActionRouter sellerAccountData", sellerAccountData);
  }, [sellerAccountData]);

  const matchesTab = applyTabs(tab);

  function handleStatus(e) {
    if (props.onStatus) {
      props.onStatus(e);
    }
  }

  function applyTabs(tab) {
    var matchesTab = true;

    if (tab === "all") {
      return true;
    }

    if (tab === "active") {
      if (!isOwner && isPastTime(lot.endsAt)) {
        matchesTab = false;
      }
      if (isOwner && lot && lot.paid === true) {
        matchesTab = false;
      }
    }

    if (tab === "past") {
      //matchesTab = false;
      if (lot === undefined) {
        matchesTab = false;
        return false;
      }
      if (!isOwner && !isPastTime(lot.endsAt)) {
        matchesTab = false;
      }
      if (isOwner && !isPastTime(lot.endsAt)) {
        matchesTab = false;
      }
    }
    return matchesTab;
  }

useEffect(() =>{
console.log("ItemActionRouter start", item && item.id)

}, [])

  useEffect(() => {
    if (canBid === undefined) {
      // Call the stripe function
      const c = getPaymentMethodStatus();
      setCanBid(c);
    }
  }, []);

  // This is the "right hand" of the 3 (or 4?) panels.
  // It houses the actions the user can take.

  const OwnerPayItem = () => {
    return (
      <>
        {isOwner && isPaid && (
          <>
            <Button fullWidth variant="outlined" onClick={handleOpenDialog}>
              Report Issue
            </Button>{" "}
            {/* add a button that triggers the open dialog function */}
            <ItemResolution
              item={item}
              open={openDialog}
              onDone={handleCloseDialog}
            />{" "}
            {/* pass the open state and close function to the dialog component */}
            <Box py={2}>
              <Typography gutterBottom variant="subtitle1">
                See "More Details" below for buyer information.
              </Typography>
            </Box>
          </>
        )}

        {/* {isOwner && !isPaid && <>Did not sell.</>} */}
      </>
    );
  };

  const WinnerPayItem = () => {
    if (!isWinner) {
      return null;
    }
    if (isPaid) {
      return (
        <>
          {isWinner && isPaid ? (
            <>
              You have paid for this item. Here is the address for pickup.
              <ItemEventAddress item={item} />
            </>
          ) : null}
          <br></br>bidOwner: {item.bidOwner}
          <br></br>owner: {item.owner}
        </>
      );
    }

    return (
      <Link to={"/payment"}>
        <Button variant="outlined" fullWidth endIcon={<PaymentIcon />}>
          You have won. Pay Now
        </Button>
      </Link>
    );
  };

  const WinnerCollectItem = () => {
    return (
      <>
        {isWinner && isPaid ? (
          <>
            You have paid for this item. Here is the address for pickup.
            <ItemEventAddress item={item} />
          </>
        ) : null}
      </>
    );
  };

  const AdministratorCancelItem = () => {
    return (
      <>
        {isAdministrator && isPaid ? (
          <>
            <ItemCancel item={item} lot={lot} />
            {/*EventAddress item={item} />*/}
          </>
        ) : null}
      </>
    );
  };

  const handleUpdateItemPrices = (s) => {
    console.log("ItemActionRouter handleUpdateItemPrices item", item);
    updateItemPrices(item.id);
  };
  const deprecate_AuctioneerReviewItem = () => {
    //return (<>OOBAR</>)
    console.log(
      "AuctioneerReviewItem called",
      item,
      isAdministrator,
      isLotStarted
    );
   
   
 
   
    return (
      <>

      
        {/* <Button onClick={(s) => handleUpdateItemPrices(s)}>
          Update prices (test)
        </Button> */}
        {/*isAuctioneer && (<LotPrices item={item} />) */}
        {item && isAdministrator && !isLotStarted && (
          <>
        
            <ItemReview
              key={item.id}
              event={event}
              item={item}
              lot={lot}
              onStatus={(e) => handleStatus(e)}
            />{" "}
          </>
        )}
        {item &&
          item.accepted &&
          isAdministrator &&
          isLotStarted &&
          !isLotEnded && (
            <>
              {" "}
              <LotDetails item={item} lot={lot} />
            </>
          )}
      </>
    );
  };

  /*
              OWNER - [LOWER RESERVE]
              <OwnerReserveItem />{" "}
              */

  const OwnerSellItem = () => {
    if (mode && mode === "view") {
      return null;
    }
    return (
      <>
{/*       
      {item && item.listingType === "marketplace_price" && (<> 
          <Box flexGrow="1" pb={1}>
            
            <ItemEdit key={item.id} item={item} lot={lot} />
          </Box>
          
          </>)} */}

        {isOwner && !isAdministrator && !isLotEnded && isLotStarted && <OwnerUnlistItem mode={mode} item={item} lot={lot} />}

        {isOwner &&
          !isAdministrator &&
          lot &&
          lot.type === "increasing_bid" &&
          !isLotEnded && (
            <>
              {" "}
              <OwnerReserveItem />{" "}
            </>
          )}
      </>
    );
  };


  const DeprecateOwnerWithdrawItem = () => {
    if (mode && mode === "view") {
      return null;
    }

    return (
      <>
        {!isDelisted && isOwner && !isAdministrator && !isLotStarted && (
          <>
            <ItemWithdraw item={item} lot={lot} />
          </>
        )}
      </>
    );
  };

  const OwnerReserveItem = () => {
    if (mode && mode === "view") {
      return null;
    }
    return (
      <>
        {isOwner && !isAdministrator && !isLotEnded ? (
          <SellerReserveEdit item={item} />
        ) : null}
      </>
    );
  };

  const handlePaymentMethodStatusChange = (s) => {
    if (s === undefined) {
      return;
    }
  };

  const UserBidItem = () => {
    if (auth.user === undefined) {
      return <SignInButton />;
    }

    return (
      <>
        <PaymentMethodStatus
          input={"null ok message"}
          onChange={(s) => handlePaymentMethodStatusChange(s)}
          onStatus={(s) => handleStatus(s)}
        />
        {canBid === false ? (
          <Box py={2}>
            <PaymentCardMethodButton />
          </Box>
        ) : (
          "CANBID NOT FALSE"
        )}
        {!isOwner &&
          !isAdministrator &&
          !isLotEnded &&
          canBid &&
          auth.user &&
          auth.user.buyer &&
          auth.user.buyer.enabled && <BuyerBidEdit item={item} lot={lot} />}
        {/*   <BuyerBidEdit item={item} lot={lot} /> */}
        {/*   <LotBid item={{ ...item, palette: palette }} lot={lot} /> */}
        {/*   <LotBid item={item} lot={lot} /> */}
        {/*   <BidItem item={item} lot={lot} /> */}
        TEST WITHOUT BUYING PRIVILEGE CHECK (BECAUSE BUYER TEST ACCOUNT IS NOT
        ENABLED)
        {!isOwner && !isAdministrator && !isLotEnded && canBid && auth.user && (
          <BuyerBidEdit item={item} lot={lot} />
        )}
      </>
    );
  };

  const AuctioneerSellItem = () => {
    if (mode && mode === "view") {
      return null;
    }
    return (
      <>
        <LotDetails item={item} lot={lot} />
      </>
    );
  };

  useEffect(() => {
    console.log("ItemActionRouter start");
  }, []);

  //const ItemActionRouter = () => {
  if (isPaid) {
    if (mode && mode === "view") {
      return <>{debugFlag && <>VIEW MODE [VIEWER - ITEM PAID]</>}</>;
    }

    if (isAdministrator) {
      //return (<>ADMINISTRATOR - ITEM PAID FOR</>)
      return (
        <>
          {debugFlag && <>ADMINISTRATOR [ITEM PAID - NO ACTION]</>}
          <AdministratorCancelItem />
        </>
      );
    }
    if (isWinner) {
      return (
        <>
          {debugFlag && <>WINNER [ITEM PAID - COLLECT]</>}
          <WinnerCollectItem />
        </>
      );
    }
    if (isOwner) {
      return (
        <>
          {debugFlag && <>OWNER [ITEM PAID - ACKNOWLEDGE COLLECTION]</>}
          <OwnerPayItem />
        </>
      );
    }
    return <>{debugFlag && <>USER - NO ACTION - ITEM SOLD</>}</>;
  }

  if (isLotEnded) {
    if (mode && mode === "view") {
      return <>{debugFlag && <>VIEW MODE [VIEWER - LOT ENDED]</>}</>;
    }
    if (isAdministrator) {
      return <>{debugFlag && <>AUCTIONEER [LOT ENDED - NO ACTION]</>}</>;
    }
    if (isWinner) {
      return (
        <>
          {debugFlag && <>WINNER [NEED TO PAY]</>}
          <WinnerPayItem />
        </>
      );
    }
    if (isOwner) {
      return (
        <>
          {debugFlag && <>OWNER [LOT ENDED - NO ACTION]</>}
          <ItemReview
              key={item.id}
              event={event}
              item={item}
              lot={lot}
              onStatus={(e) => handleStatus(e)}
            />
            
          <OwnerPayItem />
        </>
      );
    }
    return <>USER - [NO ACTION]</>;
  }

if (lot && lot.accepted === false) {

    if (mode && mode === "view") {
      //if (canBuy && mode && mode ==='view') {
      return (
        <>
          {debugFlag && <>VIEW MODE [VIEWER - UNACCEPTED LOT]</>}
        </>
      )
}


}


if (isLotStarted && item.listingType === "marketplace_price") {
  if (mode && mode === "view") {
    return  (   <BuyItem item={item} lot={lot} />)
  }

  if (isOwner) {
  return (
    <>
  
  <ItemReview
  key={"item_review_" + item.id}
  item={{ ...item }}
  lot={lot}
  event={event}
/>
</>
  )
}

}
//  }


  //if (true) {return (<>HEY</>)}
  if (isLotStarted) {
    if (lot == null) {
      return null;
    }
    if (auctionStatus !== "success") {
      return null;
    }


 
    if (mode && mode === "view") {
      //if (canBuy && mode && mode ==='view') {
      return (
        <>
          {debugFlag && <>VIEW MODE [VIEWER - BUY ITEM]</>}

          {!isLotEnded && !isKnockedDown && canBuy && (
 <> 
            <BuyItem item={item} lot={lot} />
 </>
          )}

          {/* {!isLotEnded && !isKnockedDown && !canBuy && (
              <Paper variant='outlined'>
                <Box p={2}>
                  <Typography variant='h6'>
                  Item currently unavailable to buy. Check back later.
                  </Typography>
                  </Box>
                  </Paper>
               
            )} */}
        </>
      );
    }

    // Google Cloud delay noticeable in browser.
    if (sellerAccountStatus !== "success") {
      return null;
    }

    if (auth.user === false) {
      if (mode && mode === "view") {
     
        return null;
      }
      return (
        <>
          {debugFlag && <>NOT USER [SIGN-IN]</>}
          <SignInButton />
        </>
      );
    }

    if (isAdministrator) {
      if (mode && mode === "view") {
        return null;
      }

      return (
        <>
        
          <ItemUnlist item={item} lot={lot} />

          {lot && lot.type === "increasing_bid" && (
            <>
              {debugFlag && <>AUCTIONEER [LOT CONTROLS]</>}
              <AuctioneerSellItem />
            </>
          )}

          {lot && lot.type === "decreasing_price" && (
            <>{debugFlag && <>NO CONTROLS AVAILABLE</>}</>
          )}
        </>
      );
    }


    
    // if (isOwner) {return (<>OWNER - [LOWER RESERVE] </>)}
    if (isOwner) {
      return (
        <>
          {debugFlag && <>OWNER - [ITEM CONTROLS]</>}

          <ItemReview
              key={item.id}
              event={event}
              item={item}
              lot={lot}
              onStatus={(e) => handleStatus(e)}
            />

          {isLotStarted && canOffer && (<>
<OffersItem item={item} lot={lot} event={event} />
</>
 )}
          <OwnerSellItem />
          {/*  <OwnerReserveItem />{" "} */}
        </>
      );
    }
    //

    if (canBuy) {
      if (lot == null) {
        return null;
      }
      if (auctionStatus !== "success") {
        return null;
      }
      if (sellerAccountStatus !== "success") {
        return null;
      }

      return (
        <>
          {debugFlag && <>BUYER [BUY ITEM]</>}
          <BuyItem key={item.id} item={item} lot={lot} />
        </>
      );
    }

    return (
      <>
        {debugFlag && <>[USER BID]</>}
        <UserBidItem />
      </>
    );
  }

  if (mode && mode === "view") {
    return <>{debugFlag && <>VIEW MODE [VIEWER - NO ACTIONS PROVIDED]</>}</>;
  }

  if (isAdministrator) {

// Pick up situation where Administrator/Curator
// has not approved or rejected an item submitted for sale.
///// xkcd

// Perhaps backwards.

 if (isSaleMissed) {

 return (<>Contact seller to help submit to future sale.</>);

 }

    return (
      <>
        {debugFlag && <> AUCTIONEER - [REVIEW ITEM]</>}
        <AuctioneerReviewItem item={item} event={event} />
      </>
    );



  }

  if (isOwner) {
    return (
      <>
        {debugFlag && <>OWNER - [REVIEW ITEM]</>}
  
        {!isLotStarted ? (
          <>
          
            <ItemReview
              key={"item_review_" + item.id}
              //item={{...item, withdrawn:true}}
              item={{ ...item }}
              lot={lot}
              event={event}
            />
          </>
        ) : (
          <>NOT REVIEWABLE</>
        )}

        
        <br/>
        {isLotStarted && canOffer && (<>

<OffersItem item={item} lot={lot} event={event} />
</>
 )}

        <OwnerWithdrawItem mode={mode} lot={lot} item={item} event={event} />
      </>
    );
  }
  return (
    <>
      {debugFlag && (
        <>
          USER - [NO ACTION]
          {item ? "ITEM PROVIDED" : "NO ITEM"}
        </>
      )}
    </>
  );

  //    return <>{debugFlag && <>NO ITEM</>}</>;
  //  };
}

export default ItemActionRouter;
