import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Box, Divider, Button } from "@material-ui/core";

import Typography from "@material-ui/core/Typography";

import CircularProgress from "@material-ui/core/CircularProgress";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useAuth } from "./../util/auth.js";
import { getSlug } from "./../util/slug.js";
import {
  useEvent,
  useAuctionByEvent,
  useLotByItem,
  loadState,
  saveState,
  starItem,
} from "./../util/db.js";
import ItemThumbnail from "./../components/ItemThumbnail";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Grid from "@material-ui/core/Grid";
import LotBid from "./../components/LotBid";
import LotPrice from "./../components/LotPrice";
import { CardHeader } from "@material-ui/core";
import RemoveFromCartButton from "./RemoveFromCartButton.js";
import LotBuy from "./LotBuy.js";
import ImpressionCounter from "./ImpressionCounter.js";
import { getPrice, humanPrice } from "../util/price.js";
import { Skeleton } from "@material-ui/lab";
import ItemCardUnframed from "./ItemCardUnframed.js";
import ItemStatus from "./ItemStatus.js";
import DebugReport from "./DebugReport.js";
import TestIndicator from "./TestIndicator.js";
import ItemCardHorizontal from "./ItemCardHorizontal.js";
import OrderPickupDelivery from "./OrderPickupDelivery.js";
import { useMediaQuery, useTheme } from "@material-ui/core";

import { debugFlag } from "../util/dev.js";

import {isSearchMatch} from "../util/search.js";
import {scoreTextItem} from "../util/item.js";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: "15px",

    [theme.breakpoints.down("sm")]: {
      border: "1px solid #c0c0c0",
    },

    "&:hover": {
      border: "#f5f5f5",
      borderStyle: "solid",
      borderWidth: "1px",
    },
  },

  clippedText: {
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },

  cardheader: {
    paddingBottom: "5px",
    opacity: "0.5",
    marginBottom: "-25px",
  },

  estimate: {
    justifyContent: "space-between",

    display: "flex",
    alignItems: "center",
  },

  media: {
    height: "auto",

    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      height: "auto",
    },

    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },

  paperItems: {
    minHeight: "300px",
  },
  featured: {
    backgroundColor:
      theme.palette.type === "dark" ? theme.palette.action.selected : "#fdf8c2",
  },
  starFeatured: {
    color: theme.palette.warning.main,
  },
  name: {
    overflow: "hidden",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    lineHeight: "inherit",
    height: "3em",
  },
  seeMoreButton: {
    justifyContent: "flex-end",
  },
}));

function ItemCard(props) {
  const classes = useStyles();
  const theme = useTheme();
  const loadingContext = false;

  const isReady = false;

  const auth = useAuth();

  const removeMd = require("remove-markdown");

  const [searchMatch, setSearchMatch] = useState();

  const isAuctioneer =
    auth && auth.user && auth.user.role && auth.user.role === "auctioneer";
  const isUser = auth && auth.user !== false;

  const isDeveloper = auth?.user?.developer?.enabled;
  const isAffiliate = auth?.user?.affiliate?.enabled;

  const isCart = props.flavour === "cart" ? true : false;
  const isPaymentConfirmation =
    props.flavour === "payment-confirmation" ? true : false;

  const [price, setPrice] = useState();

  const { item, order, gridType, searchQuery } = props;

// dev

const score = scoreTextItem(searchQuery, item);





  var { flavour, spice } = props;

  const [showFullText, setShowFullText] = useState(false);

  const toggleFullText = () => {
    setShowFullText(!showFullText);
  };

  // This will make a call for "profiles" on every card view.
  /*
    const {
      data: profileData,
      status: profileStatus,
      error: profileError,
    } = useProfile(item && item.owner);
  */

  const isSmallOrLess = useMediaQuery(theme.breakpoints.down("xs"));

  const {
    data: auction,
    status: auctionStatus,
    error: auctionError,
  } = useAuctionByEvent(item && item.auction);
  /*
    const {
      data: event,
      status: eventStatus,
      error: eventError,
    } = useEvent(item && item.auction);
  */

  useEffect(() => {
    console.log("Spice", spice);
  }, [spice]);

  const [isCardVisible, setIsCardVisible] = useState();

  const {
    data: lot,
    status: lotStatus,
    error: lotError,
  } = useLotByItem(item && item.id);

  const {
    data: event,
    status: eventStatus,
    error: eventError,
  } = useEvent(item && item.event);
  const isKnockedDown =
    lot && lot.knockedDownAt && lot.knockedDownAt !== false ? true : false;

  const isPaid = lot && lot.paid && lot.paid === true ? true : false;

  // Determine the logic for "does it have a price"
  // Which translates to do I show a buyable Card.
  // Or not.
  //const hasPrice = false;
  const hasPrice = item && item.name && item.name.toLowerCase() !== "x";

  const itemIsEmpty = !item;

  const itemStatus = "success";

  const canUseStar =
    auth.user &&
    auth.user.planIsActive &&
    (auth.user.planId === "pro" || auth.user.planId === "business");

  const isOwner =
    auth.user && auth.user.id && auth.user.id === (item && item.owner);

  //const isVisible = (isOwner || isAuctioneer) || isLotStarted;
  //  const isCardVisible = isLotStarted;
  //Might need this second line.
  //const isCardVisible =  (isLotStarted && isItemBuyerVisible);

  //  const isCardVisible = null;

  useEffect(() => {
    const c = isSearchMatch(item, searchQuery);
    console.log("ItemCard item.id searchQuery", item.id, searchQuery, c);

    setSearchMatch(c);
    //setSearchMatch(false);
  }, [item, searchQuery]);
/*
  function isSearchMatch(item, searchQuery) {
    if (item == null) {
      return true;
    }
    if (searchQuery == null) {
      return true;
    }
    const haystack = item.name + " " + item.description;
    console.log("ItemCard haystack", haystack);
    //return true;
    return test(haystack, searchQuery, true);
  }
*/
  function buildRegEx(str, keywords) {
    return new RegExp(
      "(?=.*?\\b" + keywords.split(" ").join(")(?=.*?\\b") + ").*",
      "i"
    );
  }

  function test(str, keywords, expected) {
    var result = buildRegEx(str, keywords).test(str) === expected;
    console.log(result ? "Passed" : "Failed");
    return result;
  }

  // const itemColor = (item && item.palette && item.palette) ? item.palette.lightVibrant  + "26" : "#f5f5f5";

  //useEffect(() => {
  //  console.log("ItemCard isCardVisible", isCardVisible);
  //}, [isCardVisible]);

  useEffect(() => {
    if (item === undefined) {
      return;
    }
    if (!(auth.user && auth.user.uid)) {
      return;
    }

    const localStar = loadState("star");
    if (localStar !== null) {
      starItem({ ...localStar, owner: auth.user.uid });

      saveState("star", null);
    }
  }, [item]);

  useEffect(() => {
    if (!lot) {
      return;
    }

    const price = getPrice(lot);

    if (!price) {
      return;
    }
    if (!price.current) {
      return;
    }
    if (!price.current.amount) {
      return;
    }
    const amount = price.current.amount;
    const formattedTotalPrice = humanPrice({
      amount: amount,
      currency: "USD",
      showCents: true,
    });

    setPrice({
      amount_total: amount,
      amount_total_formatted: formattedTotalPrice,
    });
  }, [lot]);

  const handleRemoveItem = (itemId) => {
    console.log("ItemCard handleRemoveItem", itemId);

    if (props.removeCard) {
      props.removeCard(itemId);
    }
  };

  const handleStatus = (e) => {
    console.log("handleStatus", e);
  };

  useEffect(() => {
    if (lotStatus === "success") {
      handleStatus("success");
    }
  }, [lotStatus]);

  useEffect(() => {
    if (lotStatus !== "success") {
      return;
    }
    if (isCardVisible === undefined) {
      return;
    }

    if (loadingContext === true) {
      return;
    }

    //setIsReady(false);
  }, [loadingContext, lotStatus, isCardVisible]);

  // Test
  // THis is in the wrong place. And needs to be thought about.
  useEffect(() => {
    console.log("ItemCard item", item);
  }, [item]);
  //if (item === undefined || (item && item.id === false)) {

  //useEffect(() => {
  //  console.log("isCardVisible", isCardVisible);
  //}, [isCardVisible]);

  // isDeveloper typically false
  // if there is a test item ... and isDeveloper is true ... then show it.
  // otherwise hide the item.

  // so if isDeveloper is false and test is true. Hide item.
  // iDeveloper is true and test is true. SHow item.
  // isDeveloper is false and test is false. SHow item.
  // isDeveloper is true and test is false. SHow item.

  useEffect(() => {
    console.log("ItemCard change");
    if (!isDeveloper && item && item.test && item.test === true) {
      setIsCardVisible(false);
      return;
    }

    if (!searchMatch) {
      setIsCardVisible(false);
      return;
    }
    setIsCardVisible(true);
  }, [item, searchMatch]);

  useEffect(() => {
    console.log("ItemCard isCardVisible", item.name, item.id, isCardVisible);
    if (props.isCardVisible) {
      props.isCardVisible({ id: item.id, visibility: isCardVisible });
    }
  }, [isCardVisible]);

  if (!isCardVisible) {
    //return null;
    return null;
  }

  if (isSmallOrLess && !isPaymentConfirmation && !isCart && flavour !== "horizontal_grid") {
    return (
      <Grid xs={12}>
        <Box py={0.5}>
          <div style={{ display: loadingContext ? "none" : "block" }}>
      
            <ItemCardHorizontal
              event={event}
              flavour={flavour}
              key={"itemcard_unframed_non_masonry_" + item.id}
              item={item}
              lot={lot}
            />
          </div>
        </Box>
      </Grid>
    );
  }

  if (gridType === "non_masonry") {
    return (
      <>
        <Grid key={item.id} item {...(flavour === "horizontal_grid" ? {} : {xs: 12, sm: 6, md: 4, lg: 3})}>
          <Skeleton
            variant="rect"
            height="346px"
            style={{
              display: loadingContext ? "block" : "none",
              backgroundColor: "gold",
            }}
          />

          <div style={{ display: loadingContext ? "none" : "block" }}>
           
            <ItemCardUnframed
              event={event}
              flavour={flavour}
              key={"itemcard_unframed_non_masonry_" + item.id}
              item={item}
              lot={lot}
            />
          </div>
        </Grid>
      </>
    );
  }

  if (!item) {
    return <>NO ITEM PROVIDED</>;
  }

  if (!hasPrice) {
    return (
      <Card
        elevation={0}
        key={item.id}
        className={classes.root}
        style={{
          textDecoration: "none",
          // height: "100%",
          height: "auto",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          padding: "15px",
          backgroundColor:
            item && item.palette && item.palette
              ? item.palette.lightVibrant + "26"
              : "#f5f5f5",
        }}
      >
        <TestIndicator item={item} />

        <CardMedia className={classes.media}>
          <ItemThumbnail item={item} onStatus={(e) => handleStatus(e)} />
        </CardMedia>
      </Card>
    );
  }
  
  // in cart display of items
  if (isCart || flavour === "invoice") {
    return (
      <>
        {debugFlag && (
          <>
            <DebugReport object={item} />
            <DebugReport object={lot} />
          </>
        )}

        {(lotStatus === "loading" || itemIsEmpty) && (
          <Box py={5} px={3} align="center">
            {lotStatus === "loading" && <CircularProgress size={32} />}

            {lotStatus !== "loading" && itemIsEmpty && <>No items in cart.</>}
          </Box>
        )}
        {/* {event?.pickupandshipping} */}

        {lotStatus !== "loading" && item && (
          <Grid container direction={"row"} spacing={3}>
            <Grid item xs={12}>
              <Card
                variant="outlined"
                style={{
                  minHeight: "120px",
                  // backgroundColor:
                  //   item && item.palette && item.palette
                  //     ? item.palette.lightVibrant + "26"
                  //     : "#f5f5f5",
                }}
                className={item.featured && classes.featured}
                elevation={0}
              >
                <CardHeader
                  // title={<Link to={"/item/" + item.id}>{item.name}</Link>}
                  className={classes.cardheader}
                  href={`/item/${getSlug(item.name)}/${item.id}`}
                  subheader={
                    isCart &&
                    spice &&
                    spice.includes("showStatus") && (
                      <ItemStatus item={item} lot={lot} />
                    )
                  }
                  action={
                    <>
                      {spice && spice.includes("noremove") ? null : (
                        <RemoveFromCartButton
                          item={item}
                          onRemove={(c) => handleRemoveItem(c)}
                        />
                      )}
                    </>
                  }
                />

                <CardContent className={classes.cardcontent}>
                  <Grid
                    container
                    spacing={2}
                    direction="row"
                    // style={{ justifyContent: "space-between" }}
                  >
                    <Grid item xs={3} sm={3}>
                      <Link to={`/item/${getSlug(item.name)}/${item.id}`}>
                        <ItemThumbnail
                          item={item}
                          flavour="cart"
                          onStatus={(e) => handleStatus(e)}
                        />
                      </Link>
                    </Grid>

                    <Grid item xs={8} sm={8}>
                      <Typography variant="h6">
                        <Link
                          to={"/item/" + getSlug(item.name) + "/" + item.id}
                        >
                          {item.name}
                        </Link>
                      </Typography>

                      {/* {price && price.amount_total_formatted} */}

                      {flavour !== "simple" &&
                      lot &&
                      lot.type == "increasing_bid" ? (
                        <LotBid item={item} flavour="cart" />
                      ) : null}

                      {flavour !== "simple" &&
                        lot &&
                        lot.type == "declining_price" && (
                          <>
                            <LotBuy
                              item={item}
                              flavour="cart"
                              spice={["cart"]}
                            />
                          </>
                        )}

                      {spice && spice.includes("order") && (
                        <>
                          <LotPrice
                            lot={lot}
                            order={order}
                            flavour="horizon_price"
                            spice={["order", "item_card"]}
                          />
                        </>
                      )}

               
                      {spice && spice.includes("publicAddress") && (
                        <Box py={1}>
                          <Typography
                            variant="subtitle1"
                            color="textPrimary"
                            component="span"
                          >
                            Pickup and Shipping Details:
                          </Typography>

                          <Typography
                            variant="body2"
                            className={!showFullText ? classes.clippedText : ""}
                          >
                            {event?.pickupandshipping}
                          </Typography>

                          <Box pt={1} display="flex" justifyContent="flex-end">
                            <Button
                              fullWidth
                              endIcon={<ExpandMoreIcon />}
                              style={{ borderTop: "1px solid #0000003b" }}
                              size="small"
                              onClick={toggleFullText}
                              className={classes.seeMoreButton}
                              component="span"
                            >
                              {showFullText ? "Show less" : "Show more"}
                            </Button>
                          </Box>
                        </Box>
                      )}

                      {spice && spice.includes("address") && (
                        <OrderPickupDelivery item={item} event={event} />
                      )}

                      {/* <ProfileInformation profile={{ id: event.owner.contactNumber }} /> */}

                      {/* {sellerProfile?.contactNumber} */}

                      {lot && lot.cancelled === true && (
                        <>
                          <Typography variant="h6">
                            PURCHASE CANCELLED
                          </Typography>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        )}
      </>
    );
  }

  // Payment Confirmation Page
  if (isPaymentConfirmation) {
    return (
      <>
        <ImpressionCounter lot={item}>
          <TestIndicator item={item} />
          {(lotStatus === "loading" || itemIsEmpty) && (
            <Box py={5} px={3} align="center">
              {lotStatus === "loading" && <CircularProgress size={32} />}
            </Box>
          )}

          {lotStatus !== "loading" && item && (
            <>
              <Grid container direction="row" spacing={3}>
                <Grid item xs={12}>
                  <Card
                    style={{
                      minHeight: "120px",
                      // backgroundColor:
                      //   item && item.palette && item.palette
                      //     ? item.palette.lightVibrant + "26"
                      //     : "#f5f5f5",
                    }}
                    className={item.featured && classes.featured}
                    elevation={0}
                  >
                    <CardContent className={classes.cardcontent}>
                      <Grid
                        container
                        spacing={2}
                        direction="row"
                        style={{ justifyContent: "flex-start" }}
                      >
                        <Grid item xs={3} sm={3} style={{ display: "flex" }}>
                          <Link to={`/item/${getSlug(item.name)}/${item.id}`}>
                            <ItemThumbnail
                              item={item}
                              flavour="cart"
                              onStatus={(e) => handleStatus(e)}
                            />
                          </Link>
                        </Grid>

                        <Grid item xs={9}>
                          <Link to={`/item/${getSlug(item.name)}/${item.id}`}>
                            <Box display="flex">
                              <Box flexGrow={1}>
                                <Typography
                                  variant="h6"
                                  color="primary"
                                  className={classes.name}
                                >
                                  {item.name}
                                </Typography>

                                <Typography variant="subtitle1">
                                  <b>Price: </b>
                                  <LotPrice
                                    lot={lot}
                                    flavour="sold"
                                    spice="simple"
                                  />
                                </Typography>

                                {spice && spice.includes("address") && (
                                  <>
                                    <OrderPickupDelivery
                                      item={item}
                                      event={event}
                                    />
                                  </>
                                )}
                              </Box>
                            </Box>
                          </Link>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
              <Divider />
            </>
          )}
        </ImpressionCounter>
      </>
    );
  }

  // default grid
  return (
    <>
      {!item && <>SKELETON</>}
      {item && item.id && (
        <Grid key={item.id} item md={4} lg={3}>
          <Skeleton style={{ display: loadingContext ? "block" : "none" }} />

          <ItemCardUnframed
            flavour={flavour}
            key={"itemcard_unframed_default_" + item.id}
            style={{ display: loadingContext ? "none" : "block" }}
          />
        </Grid>
      )}
    </>
  );
}
//}
export default ItemCard;
