import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useAuth } from "./../util/auth.js";
import { textPrice } from "./../util/prices.js";
import { makeStyles } from "@material-ui/core/styles";
import { updateUser } from "./../util/db.js";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { serverTimestamp } from "firebase/firestore";
import Button from "@material-ui/core/Button";

import {
  getAccountLinkByAccount,
  getDashboardLinkByAccount,
  getAccountByAccount,
  getBalanceByAccount,
  useSellerCustomer,
  pushStripeCustomer,
} from "../util/stripe.js";
import {
  Grid,
  Typography,
  TextField,
  Card,
  CardContent,
  InputAdornment,
  Divider
} from "@material-ui/core";
import Stripe from "./../images/stripe.svg";
import Box from "@material-ui/core/Box";
import { Skeleton } from "@material-ui/lab";
import { getFunctions, httpsCallable } from "firebase/functions";
import CircularProgress from "@material-ui/core/CircularProgress";

const functions = getFunctions();

const processStripeAccountsCreate = httpsCallable(
  functions,
  "processStripeAccountsCreate"
);

// getCustomerByCustomer(customerId)

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    marginTop: "1em",
    padding: theme.spacing(2),
    // textAlign: "center",
    color: theme.palette.text.secondary,
    border: "1px solid #e0e0e0",
    background: "#ffffff",
  },
  item: {
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "column",
    alignItems: "flex-end",
  },

  image: {
    width: 50,
    height: 50,
    marginRight: ".5em",
  },

  img: {
    // margin: 'auto',
    // display: 'block',
    maxWidth: "100%",
    maxHeight: "75px",
  },

  paperItems: {
    minHeight: "300px",
  },
  featured: {
    backgroundColor:
      theme.palette.type === "dark" ? theme.palette.action.selected : "#fdf8c2",
  },
  starFeatured: {
    color: theme.palette.warning.main,
  },
}));

function StripeConnectedAccount(props) {
  const auth = useAuth();

  const { filter, customer } = props;
  const classes = useStyles();

  const { register, errors, getValues, setError, clearErrors, watch } =
    useForm();

  const seller = auth.user.seller;
  const customerId = seller.customer;

  const [isProcessing, setIsProcessing] = useState(false);

  const [priorSellerName, setPriorSellerName] = useState();

  const [accountId, setAccountId] = useState(
    auth.user && auth.user.seller && auth.user.seller.account
      ? auth.user.seller.account
      : null
  );

  const canCharge =
    auth.user && auth.user.seller && auth.user.seller.charges_enabled
      ? auth.user.seller.charges_enabled
      : false;

  const canPayout =
    auth.user && auth.user.seller && auth.user.seller.payouts_enabled
      ? auth.user.seller.payouts_enabled
      : false;

  const accountConnected = canCharge && canPayout;
  const SpinnerAdornment = (props) => (
    <CircularProgress size={20} style={{ color: "#ffffff" }} />
  );
  const isAuctioneer = auth.user.role && auth.user.role === "auctioneer";

  const [connectLink, setConnectLink] = useState(null);
  const [dashboardLink, setDashboardLink] = useState(null);
  const [balance, setBalance] = useState();
  const [active, setActive] = useState(false);

  const [nameReady, setNameReady] = useState(false);

  const [sellerName, setSellerName] = useState("");

  const [formMessage, setFormMessage] = useState();

  const sellerCustomer = useSellerCustomer();

  useEffect(() => {
    if (!sellerCustomer) {
      console.log("StripeConnectedAccount sellerCustomer", sellerCustomer);
      return;
    }

    console.log("StripeConnectedAccount sellerCustomer", sellerCustomer);

    setSellerName(sellerCustomer);
    setPriorSellerName(sellerCustomer);

    if (sellerCustomer === null) {
      setActive(false);
    } else if (sellerCustomer.length < 1) {
      setActive(false);
    } else {
      setActive(true);
    }
    setNameReady(true);
  }, [sellerCustomer]);

  useEffect(() => {
    console.log("StripeConnectedAccount auth.user", auth.user);
    if (!auth.user.seller) return;
    if (!auth.user.seller.sellerName) return;
  }, [auth && auth.user]);

  const handleChange = (changeValue, event) => {
    setActive(false);
    if (changeValue === "sellerName") {
      console.log("StripeConnectedAccount change", changeValue, event);

      const n = event.target.value;

      setSellerName(n);
      //        setActive(true);

      if (n === null) {
        setError("sellerName", {
          type: "manual",
          message: "Seller business name required",
        });
        setActive(false);
        //nameComplete = false;
      } else if (n.length < 3) {
        setError("sellerName", {
          type: "manual",
          message: "Seller business name must be at least three letters",
        });
        setActive(false);
        //nameComplete = false;
      } else {
        clearErrors("sellerName");
      }
    }

    //   }
  };

  useEffect(() => {
    if (!sellerName) {
      return;
    }
    if (sellerName === null) {
      return;
    }
    if (sellerName === priorSellerName) {
      setActive(true);
      return;
    }
  }, [sellerName]);

useEffect(async () =>{

  if (accountId == null) {

    //var acc = accountId;
    //if (accountId === null) {
      const result = await processStripeAccountsCreate();
      const acc = result.data.id;
      setAccountId(acc);
    //}

  }


}, [accountId])

  useEffect(() => {
    console.log("StripeConnectedAccount trigger accountId", accountId);
    console.log("StripeConnectedAccount trigger accountConnected", accountConnected);

    console.log("StripeConnectedAccount trigger firestore", auth?.user?.seller?.account);

    // This will create an account if accountId is null.
 //   if (auth?.user?.seller?.account == null) {

    const link = getOnboardlink()
      .then((result) => {
        setConnectLink(result);
      })
      .catch((error) => {
        console.error("StripeConnectedAccount getOnboardLink error", error);
      });
 //   }

    const dashlink = getDashboardlink()
      .then((result) => {
        setDashboardLink(result);
      })
      .catch((error) => {
        console.log(error);
      });
    const account = getAccountByAccount(accountId)
      .then((result) => {
        if (result && result.data && result.data.data) {
        } else {
          return;
        }

        const b = result && result.data && result.data.data.balance;
        setBalance(b);
        const payoutsEnabled =
          result &&
          result.data &&
          result.data.data &&
          result.data.data.payouts_enabled;

        updateUser(auth.user.id, {
          seller: {
            payouts_enabled: payoutsEnabled,
            refreshedAt: serverTimestamp(),
          },
        });
        console.log("StripeConnectedAccount account result", result);
      })
      .catch((error) => {
        console.error("StripeConnectedAccount account error", error);
      });

    const stripeBalance = getBalanceByAccount(accountId)
      .then((result) => {
        console.log(
          "StripeConnectedAccount getBalanceByAccount result",
          result
        );
        // Platform balance as seen September 14, 2021.
        //setBalance(result.data.available[0]);
        // Believed to be platfrom user balance.
        setBalance(result.data.available[0]);
      })
      .catch((error) => {
        console.error(
          "StripeConnectedAccount getCustomerByCustomer error",
          error
        );
      });

    return;
  }, [accountId, accountConnected]);

  const handleTestAccountCreate = async () => {
    const result = await processStripeAccountsCreate();
  };

  const getOnboardlink = async () => {
    //event.preventDefault();
    var acc = accountId;
    if (accountId === null) {
      return null;
      /*
      const result = await processStripeAccountsCreate();
      acc = result.data.id;
      setAccountId(acc);
      */
    }

    const result2 = await getAccountLinkByAccount(acc);
    if (result2 === null) {
      return null;
    }

    return result2.data.url;
  };

  const getDashboardlink = async () => {
    //event.preventDefault();

    if (accountId === null) {
      return null;
    }

    const result2 = await getDashboardLinkByAccount(accountId);
    if (result2 === null) {
      return null;
    }

    return result2.data.url;
  };

  const ConfirmCard = () => {
    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async (event) => {
      // Block native form submission.
      event.preventDefault();

      if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return;
      }

      // Get a reference to a mounted CardElement. Elements knows how
      // to find your CardElement because there can only ever be one of
      // each type of element.
      const cardElement = elements.getElement(CardElement);

      // Use your card Element with other Stripe.js APIs
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
      });

      if (error) {
        console.error("StripeConnectedAccount handleSubmit error", error);
      } else {
        const account = "merp";
      }
    };
    return (
      <form onSubmit={handleSubmit}>
        <CardElement />
        <button type="submit" disabled={!stripe}>
          Validate
        </button>
      </form>
    );
  };

  const StripeConnectButton = () => {
    // MT LOADING ADDITION
    if (!connectLink) {
      {
        return (
          <div>
        <Button
          onClick={handleConnectClick}
          disabled={true}
          variant="contained"
          size="large"
          disableElevation
        >
          CONNECT STRIPE
        </Button><br/>
        {/* <Typography color="error" variant="subitle1">Add name above.</Typography> */}
      </div>
        );
      }
    }

    if (connectLink === null) {
      return null;
    }
    console.log("StripeConnectedAccount StripeConnectButton", connectLink);

    return (
      <div>
        <Button
          onClick={handleConnectClick}
          disabled={!active}
          variant="contained"
          size="large"
          disableElevation
          color='primary'
        >
          CONNECT STRIPE
        </Button>
      </div>
    );
  };

  const StripeBalance = () => {
    if (balance === undefined) {
      return (
        <>
          <Skeleton variant="text" width={100} />
        </>
      );
    }
    if (balance.currency === undefined) {
      return (
        <>
          <Skeleton variant="text" width={100} />
        </>
      );
    }
    if (balance.amount === undefined) {
      return (
        <>
          <Skeleton variant="text" width={100} />
        </>
      );
    }
    //return <Typography variant='subtitle2'>Balance: <b> Balance: {textPrice({amount:balance.amount, currency:balance.currency})}{balance}</b></Typography>;
    const decimalAmount = parseInt(balance.amount) / 100;
    return (
      <>
        <Typography variant="subtitle1">
          Balance:{" "}
          {textPrice({ amount: decimalAmount, currency: balance.currency })}
        </Typography>
      </>
    );
  };

  function handleSellerNameSaveClick() {
    setIsProcessing(true);
    setActive(false);
    console.log("StripeConnectedAccount customerId", customerId);
    if (!customerId) {
      setIsProcessing(false);
      setError("sellerName", {
        type: "manual",
        message: "No customer id.",
      });
      return;
    }

    // A reminder that it is just a case of building this array.

    const d = {
      name: sellerName,
      //          address: {
      //            country: "US",
      //            postal_code: currentZipCode,
      // }
    };

    const a = pushStripeCustomer(customerId, d);

    a.then((result) => {
      console.log("StripeConnectedAccount pushStripeCustomer result", result);

      if (result === true) {
        setIsProcessing(false);
        setError("sellerName", {
          type: "manual",
          message: "Could not save seller name.",
        });
        return;
      }

      setIsProcessing(false);
      setPriorSellerName(sellerName);
      setActive(true);
    }).catch((error) => {
      console.error("StripeConnectedAccount pushStripeCustomer error", error);

      setIsProcessing(false);
      setError("sellerName", {
        type: "manual",
        message: "Could not save seller name.",
      });
    });
  }

  useEffect(() => {
    if (!isProcessing) {
      return;
    }

    if (isProcessing === true) {
      setActive(false);
      return;
    }
  }, [isProcessing]);

  function handleDashboardClick() {
    window.open(dashboardLink, "_blank", "noopener,noreferrer");
    //window.onbeforeunload = null;
    //window.location.href = dashboardLink;
  }

  function handleConnectClick() {
    window.onbeforeunload = null;
    window.location.href = connectLink;
  }
  useEffect(() => {
    console.log("StripeConnectedAccount sellerName", sellerName);
  }, [sellerName]);

  const StripeDashboardButton = () => {
    if (!dashboardLink) {
      return null;
    }
    if (dashboardLink === null) {
      return null;
    }
    console.log("StripeConnectedAccount StripeConnectButton", dashboardLink);

    return (
      <div>
        {/* <a href={dashboardLink} style={{ textDecoration: "none" }}>*/}
        <Box py={1}>
          <Button
            onClick={handleDashboardClick}
            variant="outlined"
            size="large"
          >
            Dashboard
          </Button>
        </Box>
        {/*   </a>*/}
      </div>
    );
  };

  //https://stripe.com/docs/api/accounts/

  return (
    <>
      <Box className={classes.paper}>


     
          <Typography variant="h6" gutterBottom>Stripe Payouts</Typography>


          <div className={classes.root}>
            <Card variant="outlined">
   
              <CardContent style={{ textAlign: "left" }}>
<Typography variant="subtitle1" gutterBottom>Get paid to your local bank account automatically. No minimum
                  threshold. Free to setup.</Typography>
              <form>

            {false && <>STRIPE SKELETON GOES HERE</>}
            {true && (
              <>
                <Grid container={true} spacing={2} alignItems="center">
                  <Grid item={true} xs={12}>
                    <TextField
                      className={classes.textField}
                      fullWidth
                      variant="outlined"
                      type="text"
                      placeholder=" Name is required in-order to setup Stripe Payouts. e.g. Lovejoy Antiques"
                      name="sellerName"
                      value={sellerName}
                      onChange={(e) => handleChange("sellerName", e)}
                      error={errors.sellerName ? true : false}
                      helperText={
                        errors.sellerName && errors.sellerName.message
                      }
                      InputLabelProps={{ shrink: true }}
                      inputRef={register({
                        required: "Please enter your seller name",
                      })}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                             <div position="relative">
                        {isProcessing && (
                          <div
                            style={{
                              background: "rgba(255, 255, 255, 0.5)",
                              position: "absolute",
                              top: 0,
                              left: 0,
                              width: "100%",
                              height: "100%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              zIndex: 99,
                            }}
                          >
                            <CircularProgress
                              size={24}
                              style={{ color: "#000" }}
                            />
                          </div>
                        )}

                        <Button
                          disabled={
                            sellerName.trim() === "" || priorSellerName === sellerName || errors.sellerName
                          }
                          fullWidth
                          // label="Place Order"
                          size="large"
                          variant="contained"
                          color="primary"
                          onClick={(d) => handleSellerNameSaveClick(d)}
                        >
                          <Typography
                            variant="subtitle2"
                            style={{ color: "#ffffff" }}
                          >
                            Save
                          </Typography>
                        </Button>
                      </div>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                </Grid>
              </>
            )}

        </form>

<Box py={3}>
<Divider />
</Box>
                <Grid container={true} spacing={4}>
                  <Grid item={true} xs={true}>
                    <img className={classes.img} alt="complex" src={Stripe} />
                  </Grid>

                  <Grid item={true} xs="auto">
                    {dashboardLink !== undefined && dashboardLink !== null ? (
                      <>
                        <StripeDashboardButton />

                        {canPayout ? (
                          <StripeBalance />
                        ) : (
                          <Typography
                            variant="subtitle1"
                            style={{ color: "red" }}
                          >
                            Further Details Needed by Stripe
                          </Typography>
                        )}
                      </>
                    ) : (
                      <>
                        <StripeConnectButton />
                      </>
                    )}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </div>
    
      </Box>

      <Box pt={4} textAlign="left">
        <Typography variant="body1" color='textSecondary' style={{ textDecoration: "underline" }}>
          Admin only:
        </Typography>
        
        <Typography variant="body2" color='textSecondary'>
        Account:{" "}
        {auth && auth.user && auth.user.seller && auth.user.seller.account}
        <br />
        Customer:{" "}
        {auth && auth.user && auth.user.seller && auth.user.seller.customer}
        </Typography>
      </Box>
    </>
  );
}

export default StripeConnectedAccount;
