import React, { useState, useEffect } from "react";
import { useItemByUser } from "../util/db";
import { isPastTime } from "../util/time";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useAuth } from "./../util/auth.js";
import { useForm } from "react-hook-form";
import {
  useLotByItem,
} from "./../util/db.js";
import { makeStyles } from "@material-ui/core/styles";

import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { useAuctionByEvent } from "./../util/db.js";
import { Controller } from "react-hook-form";

import Select from "@material-ui/core/Select";
import clsx from "clsx";
import Collapse from "@material-ui/core/Collapse";
import Typography from "@material-ui/core/Typography";


import { Divider } from "@material-ui/core";
// import firebase from "../util/firebase";

import SignInButton from "./../components/SignInButton";
// import { database } from "firebase-admin";
import { availableReserves, bidIncrement } from "../util/auction.js";
import { getFunctions, httpsCallable } from "firebase/functions";

const functions = getFunctions();


const processReserve = httpsCallable(functions,"processReserve");



const useStyles = makeStyles((theme) => ({
  root: {
    border: "none",
    boxShadow: "none",
    background: "none",
  },

  formControl: {
    background: "white",
    minWidth: 140,
  },

  content: {
    paddingLeft: "0px",
    paddingBottom: 24,
  },

  button: {
    fontWeight: "600",
    paddingTop: "15px",
    paddingBottom: "15px",
    marginTop: "10px",
    fontSize: "1.25em",
  },

  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: "theme.transitions.duration.shortest",
    }),
  },

}));



export default function SellerReserveEdit(props) {
  const { item } = props;
//  const { status, data, error } = useItemByUser(item);

const [sellerReserveStatus, setSellerReserveStatus]  = useState("waiting")

  const [sellerReserveNext, setSellerReserveNext] = useState(false);
  const { status:itemStatus, data:itemData, error } = useItemByUser(item);
  //const itemData = data;
  const sellerReserveAmount = (itemStatus === 'success' && itemData && itemData.reservePrice ? itemData.reservePrice : false);
  const { data:lotData, status:lotStatus } = useLotByItem(item.id);
  const { data:auctionData, status:auctionStatus, error:auctionError } = useAuctionByEvent(
    (lotData === null || lotData === undefined)? undefined : lotData.auction
  );

  


//  const [sellerReserve, setSellerReserve] = useState();

  //const [buyerBidMinimum, setBuyerBidMinimum] = useState();
  //const [lotBidAt, setLotBidAt] = useState(); // Do want this number --- get fromprops.

  const isKnockedDown =
  lotData && lotData.knockedDownAt && lotData.knockedDownAt !== false ? true : false;

  const isBiddingStarted = lotData && lotData.startsAt && isPastTime(lotData.startsAt);

useEffect(() => {
  if (itemStatus !== 'success') {return;}
 // if (lot === undefined) {return;}
 if (lotStatus !== 'success') {return;}
  //setSellerReserveNext(sellerReserveAmount - bidIncrement(sellerReserveAmount));
  //setValue("reserve", sellerReserveAmount - bidIncrement(sellerReserveAmount));

  var r = sellerReserveAmount - bidIncrement(sellerReserveAmount);
 // if (r<)
 if (r < lotData.startingPrice) {r=0;}
  if (r<0) {r=0;}

  setSellerReserveNext(r);
  setValue("reserve", r);

  setSellerReserveStatus("success")


}, [itemData, itemStatus, lotData, lotStatus, error])

  const {
    register,
    handleSubmit,
    errors,
    control,
    getValues,
    watch,
    reset,
    setValue,
  } = useForm();

  const auth = useAuth();
  const classes = useStyles();

  const [pending, setPending] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const watchAmount = watch("reserve");

  useEffect(() => {

    if (watchAmount === undefined) {
      return;
    }

    setSellerReserveNext(watchAmount);
    //props.onReserveChange(watchAmount);
  }, [watchAmount]);

  function handlePlaceReserve(reserve) {
 
    processReserve(reserve)
      .then((result) => {

   
        if ('error' in result) {console.log("There was a problem")}
        // Read result of the Cloud Function.
        //var sanitizedMessage = result.data.text;
      })
      .catch((error) => {
        
        console.error("SellerReserveEdit error", error)
        //   console.log("handlePlaceBid error")
        //   console.log(error)
        // Getting the Error details.
        alert(
          "There was a problem changing your reserve. It has not been acknowledged " +
            error.code
        );
        var code = error.code;
        var message = error.message;
        var details = error.details;
        // ...
      });
  }


function handleNextReserveChange() {




}

const onSubmit = (data, e) => {

  setPending(true);
  setExpanded(false);


handlePlaceReserve({ reserve: sellerReserveNext, item: props.item.id });
setPending(false);
setExpanded(false);


  
};


  //if (itemStatus !== "success") {
    if (sellerReserveStatus !== "success") {

    return null;

  }
  
  if (lotData === undefined) {return null}
  
if (sellerReserveNext === false) {return null}

const reservePrice = parseFloat(itemData && itemData.reservePrice);
const bidAmount = parseFloat(lotData && lotData.bidAmount);

//var isReserveMet = ((data && data.reservePrice) < lot.bidAmount) ? true : false;
var isReserveMet = reservePrice < bidAmount || reservePrice === 0 ? true : false;

const ReserveInformation = () => {

if (isReserveMet) {
return (<Typography variant='h6' style={{color:'black'}}>
  <div>  
  {reservePrice ===0 ? ("NO RESERVE") : (<>RESERVE OF  {' '}
  {"$" + reservePrice}{' '} MET.</>)}
  </div>
  </Typography>
  )

}

return (  <Typography variant='h6' style={{color:'black'}}><div>
  RESERVE CURRENTLY SET AT  {' '}
  {"$" + reservePrice}{' '}
  </div>
  </Typography>
  )
 
}


if (itemData && auth.user && auth.user.id === itemData.owner && lotData && isReserveMet) {
return (<>
<ReserveInformation />
</>
);
}

// If the lot end time is in the past...
// Or 
//if (lot && lot.knockedDown !== false || isPastTime(lot.endsAt) === true) {return null;}

if (isKnockedDown || (lotData && isPastTime(lotData.endsAt))) {return null;}

//if (!isBiddingStarted) {return (<>Bidding on your item has not started yet. Come back later.</>)}


if (auctionStatus !== 'success') {return null;}

//if (auctionStatus === 'success' && auctionData && auctionData.state === undefined) {return null;}

if (auctionStatus === 'success' && auctionData && auctionData.state === false) {return null;}




  return (
    <>

<ReserveInformation />


{itemData && auth.user && auth.user.id === itemData.owner ? (
          <>
               <form
        onSubmit={handleSubmit(onSubmit)}
        //onChange={handleChange}
      >

            <br />
            <Divider />
            <br/>
            <Box>



                  <Grid item={true} xs={12}>
                    <FormControl
                      className={classes.formControl}
                      fullWidth
                      variant="outlined"
                    >
                      {/* <InputLabel>Choose an auction</InputLabel> */}
                      <Controller
                        // required
                        value={sellerReserveNext}
                        defaultValue={sellerReserveNext}
                        fullWidth
                        //onChange={handleNextReserveChange}
                        //defaultValue={amountList[0]}
                        control={control}
                        name="reserve"
                        //innerRef={register("amount")}

                        as={
                          <Select id="reserve">
                            {availableReserves(sellerReserveAmount,lotData.bidAmount,lotData.startingPrice).map((_reserve) => (
                              <MenuItem value={_reserve}>
                                {"$" + _reserve.toLocaleString()}
                              </MenuItem>
                            ))}
                          </Select>
                        }
                      />
                    </FormControl>
                  </Grid>
  

            </Box>


            <Collapse in={expanded} timeout={300} unmountOnExit></Collapse>
            {expanded === false ? (
              <>
                <SignInButton />
                {lotData && lotData.reserveMet ? (
                  <Button
                    disabled
                    disableElevation
                    fullWidth
                    className={clsx(classes.expand, {
                      [classes.expandOpen]: expanded,
                    })}
                    style={{ backgroundColor: "black", color: "white" }}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    size="large"
                    aria-label="show more"
                    variant="contained"
                    color="secondary"
                    className={classes.button}

                    //  style={{ backgroundColor: palette.darkMuted }}
                  >
                    Your reserve has been met
                  </Button>
                ) : (
                  <Button
                    disableElevation
                    fullWidth
                    className={clsx(classes.expand, {
                      [classes.expandOpen]: expanded,
                    })}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    size="large"
                    aria-label="show more"
                    variant="contained"
                    // color="primary"
                    className={classes.button}
                    style={{ backgroundColor: "black", color: "white" }}
                  >
                    Reduce Reserve to ${sellerReserveNext.toLocaleString()}
                  </Button>
                )}

                <br />

                <Box py={2}>
 
         
                      <Typography
                        variant="subtitle1"
                        p={1}
                        style={{ lineHeight: "1.35em" }}
                      >
                        You can reduce (but not raise) your reserve until it is met.
                      </Typography>
      
    
                </Box>
              </>
            ) : (
              <Box></Box>
            )}

            <Collapse in={expanded} timeout={300} unmountOnExit>
              <Button
                className={classes.button}
                disableElevation
                fullWidth
                type="submit"
                size="large"
                disabled={pending}
                style={{ color: "white", backgroundColor: "#004225" }}
                // onClick={handleExpandClick}
                //       onClick="success"
                // onClick="success"
              >
                {!pending && (
                  <span>Confirm Reduction in Reserve to ${sellerReserveNext.toLocaleString()}</span>
                )}
                {/* {!pending && <span>Confirm Bid {getValues("amount")}</span>} */}

                {pending && <CircularProgress size={28} />}
              </Button>

              <Button
                fullWidth
                variant="outlined"
                className={clsx(classes.expand, {
                  [classes.expandOpen]: expanded,
                })}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
                className={classes.button}
              >
                Cancel
              </Button>
            </Collapse>

            {/*}     <LotBiddingAt item={item} /> */}
        </form>
        </>
        ) : (
          <SignInButton />
        )}

    </>
  );
}
