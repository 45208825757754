import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../util/auth.js";
import { sortBy, groupBy } from "../util/array.js";
import {
  useLotsByBidOwner,
  useUserOrders,
  getDocumentsByIds,
} from "../util/db.js";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Card, CardContent, Divider, Box } from "@material-ui/core";
import ItemCard from "./ItemCard";
import RequestPayment from "./RequestPayment";
import { debugFlag } from "../util/dev.js";
import ListSkeleton from "./ListSkeleton.js";
import LazyLoad from "react-lazyload";

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

Object.defineProperty(Array.prototype, "group", {
  enumerable: false,
  value: function (key) {
    let map = {};
    this.map((e) => ({ k: key(e), d: e })).forEach((e) => {
      map[e.k] = map[e.k] || [];
      map[e.k].push(e.d);
    });
    return Object.keys(map).map((k) => ({ event: k, items: map[k] }));
  },
  configurable: true,
});

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paperItems: {
    minHeight: "300px",
  },
  featured: {
    backgroundColor:
      theme.palette.type === "dark" ? theme.palette.action.selected : "#fdf8c2",
  },
  starFeatured: {
    color: theme.palette.warning.main,
  },

  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

function ItemsListOrderLocation(props) {
  const { filter } = props;
  const classes = useStyles();

  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const SimpleModal = () => {
    return <>Here goes teh contents of the modal</>;
  };

  const Body = (props) => {
    const { invoice } = props;
    return (
      <div style={modalStyle} className={classes.paper}>
        <h2 id="simple-modal-title">Text in a modal</h2>
        <p id="simple-modal-description">
          <RequestPayment />
          {invoice.id}
          <iframe
            src="https://invoice.stripe.com/i/acct_1InobcCwAArHtQSX/invst_JTNFLmkT23hFGPCCuJ7llOB5YefelD0"
            title="Stripe"
          ></iframe>
        </p>
        <SimpleModal />
      </div>
    );
  };

  const auth = useAuth();

  const customerId =
    auth.user && auth.user.payment && auth.user.payment.customer;

  const [events, setEvents] = useState();
  const [groups, setGroups] = useState();
  const [groupsStatus, setgroupsStatus] = useState();
  const [items, setItems] = useState();
  const [itemsStatus, setItemsStatus] = useState("loading");

  const [eventItems, setEventItems] = useState();
  const [status, setStatus] = useState("loading");

  const [eventItemsStatus, setEventItemsStatus] = useState("loading");
  const [eventItemsError, setEventItemsError] = useState();

  const [eventsStatus, setEventsStatus] = useState("loading");
  const [eventsError, setEventsError] = useState("loading");

  const [itemOrders, setItemOrders] = useState();
  const [itemOrdersStatus, setItemOrdersStatus] = useState("loading");

  const [expandEvents, setExpandEvents] = useState();

  const {
    data: lots,
    status: lotsStatus,
    error: lotsError,
  } = useLotsByBidOwner(auth.user.id);

  const {
    data: orders,
    status: ordersStatus,
    error: ordersError,
  } = useUserOrders(auth.user.id);

  useEffect(() => {
    if (lotsStatus !== "success") {
      return true;
    }

    const itemIds = lots.map((tempItem) => {
      return tempItem.item;
    });

    console.log("ItemsListOrders itemIds", itemIds);

    const items = getDocumentsByIds("items", itemIds);
    items.then((m) => {
      const s = sortBy(m, "id", "asc", false);

      const e = groupBy(s, "event");

//console.log("ItemsListOrderLocation items", e)

      setEventItems(e);
      setEventItemsStatus("success");
    });
  }, [orders, ordersStatus, ordersError]);

  useEffect(() => {
    if (eventItemsStatus !== "success") {
      return true;
    }

    console.log("ItemsListOrders eventItem", eventItems);

    const eventIds = Object.keys(eventItems).map((eventId) => {
      return eventId;
    });

    console.log("ItemsListOrders eventIds", eventIds);

    const e = getDocumentsByIds("events", eventIds);

    e.then((m) => {
      console.log("ItemsListOrders e", m);
      const s = sortBy(m, "endTime", "asc", true);

      // Develop here to expand/collapse individual events
      setEvents(s);

      const j = s.map((event) => {
        const expanded = event.expanded ? event.expanded : false;
        return { id: event.id, expanded: expanded };
      });

      setExpandEvents(j);
      setEventsStatus("success");

    });
  }, [eventItems, eventItemsStatus, eventItemsError]);

  useEffect(() => {
    if (ordersStatus !== "success") {
      return true;
    }

    console.log("ItemsListOrders orders", orders);

    const itemIds = [];
    const itemOrders = [];
    orders.map((order) => {
      order.items.map((item) => {

        // Only get itemIds for successful orders
        if (item.error !== null) {return;}
        itemIds.push({ ...item, invoice:order.invoice, orderId: order.id });
      });
    });

    const e = groupBy(itemIds, "id");

    console.log("ItemsListOrders itemOrders", e);
    setItemOrders(e);
    setItemOrdersStatus("success");
  }, [orders, ordersStatus, ordersError]);

  useEffect(() => {
    if (itemOrders === undefined) {
      return;
    }
  }, [itemOrders]);
/*
  const EventItem = (props) => {
    const { eventItem } = props;
    return <>MERP</>;
  };
*/

/*

Component to render and expandable
list of items for each event.

*/

  const EventItems = (props) => {
    const { eventItems } = props;
  
    const [expanded, setExpanded] = useState(false);
    const handleExpand = () => {
      if (expanded === undefined) {
        return;
      }
      console.log("ItemsListOrders click expanded", expanded);
      const e = !expanded;
      setExpanded(e);
    };
    if (itemOrders === undefined) {
      return null;
    }
    if (eventItems === undefined) {
      return null;
    }
    if (expanded === undefined) {
      return null;
    }

    return (
      <>
        {/* <Button onClick={() => handleExpand()}>
          {collapsed ? "Hide Items" : "Show Items"}
        </Button> */}

        {
       
          eventItems.map((item) => {
            return (
          
                 <LazyLoad key={item.id} offset={200}>

{itemOrders && itemOrders[item.id] && (<>

                <ItemCard
            //      key={item.id}
                  item={item}
                  flavour="cart"
                  spice={["noremove","order"]}
                />
            
         
                {itemOrders &&
                  itemOrders[item.id] &&
                  itemOrders[item.id][0] && (
                    <>
                    <OrderItem itemOrder={itemOrders[item.id][0]} />
     </>
                  )}
{/* 
{itemOrders && !itemOrders[item.id] && (<>Not present on an order</>)} */}
</>)}
                </LazyLoad>
          //    </>
            );
          })}
   
      </>
    );
  };

/*

Component to render each item.
Along with orer information.

*/
  const OrderItem = (props) => {
    const { itemOrder } = props;

    return (
      <div key={itemOrder.id}>
       {debugFlag && (<>
        Item ID: {itemOrder.id ? itemOrder.id : "NA"}
        <br />
      
        "Invoice Number":{" "}
        {itemOrder.invoice && itemOrder.invoice.number
          ? itemOrder.invoice.number
          : "NA"}
        <br />
        </>)}
        {itemOrder.orderId && (
          <>
           <br />
          <Link to={`/order-summary/order_${itemOrder.orderId}`} style={{textDecoration:'underline'}}>
        Order Number: {itemOrder.orderId ? itemOrder.orderId : "NA"}
        </Link>
        </>
        )}
       
        {/* {itemOrder.orderId && (
          <Link to={`/order-summary/order_${itemOrder.orderId}`}>
            {itemOrder.orderId} 
          </Link>
        )} */}
      </div>
    );
  };

  if (eventsStatus !== "success") {
    return <ListSkeleton listsToRender={8} />;
  }

  if (itemOrdersStatus !== "success") {
    return null;
  }

  // Componentry goes here below.

  return (
    <>

{events && events.length === 0 && (
  <>
  <Card variant="outlined" style={{height:'90vh'}}>
  <CardContent>
    <Typography variant="h6"> No orders placed yet.</Typography>
  </CardContent>
</Card>
  </>)}

      {events && events.length !== 0 && (
        <>
       
          {events.map((event) => {
            return (
              <Box key={event.id}>

                <Card variant='outlined' style={{backgroundColor:'#ffffff'}}>
    
                <CardContent>

                <Typography variant="h5">{event.name}</Typography>
                {/* {event.description}
                <br /> */}
                 <Typography variant="subtitle1"><b>Location: </b>{event.location}</Typography>
               
           
                {/* Event end time: {event.endTime}
                <br /> */}
                 <Typography variant="subtitle1"><b>Pickup and Shipping Details:</b> {event.pickupandshipping}</Typography>
                 <Typography variant="subtitle1"><b>Pickup Address (if applicable):</b> {event.address}</Typography>
                 {/*<Typography variant="subtitle1"> <b>Item count:</b> {eventItems[event.id].length}</Typography>*/}
                 <Typography variant="subtitle1"> <b>Contact:</b> add owner contact details here </Typography>

             
                <br />
                <Card variant='outlined'>
                  <CardContent  >
                <EventItems eventItems={eventItems[event.id]} />
                </CardContent>
                </Card>
                </CardContent>
                </Card>
<Box py={2}>
                <Divider />
                </Box>

              </Box>
            );
          })}
  
        </>
        
      )}

    

    </>
  );

}

export default ItemsListOrderLocation;
