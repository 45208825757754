import React, { useState, useEffect } from "react";
import { useAuth, requireAuth } from "./../util/auth.js";
import { createMessage } from "./../util/db.js";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { isPastTime } from "./../util/time.js";
import ConfirmDialog from "./ConfirmDialog.js";

import { getFunctions, httpsCallable } from "firebase/functions";

const functions = getFunctions();

const processWithdrawItem = httpsCallable(functions,"withdrawItem");


const useStyles = makeStyles((theme) => ({
  deleteButton: {
    backgroundColor: "#000000",
    color: "#ffffff",
  },
}));

export function ItemWithdraw(props) {
  const classes = useStyles();
  const auth = useAuth();

  const { item, event } = props;

  const isOwner = auth.user.id && auth.user.id === item.owner;

  const isAuctioneer = auth.user.role && auth.user.role === "auctioneer";

  const [canConsign, setCanConsign] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);

  //const item.accepted ===  null || item.accepted === undefined ? "Submit" : "Resubmit"}
{/*
  const {
    data: auctionData,
    status: auctionStatus,
    error: auctionError,
  } = useEvent(item && item.auction ? item.auction : undefined);
*/}
  const isAccepted =
    item && item.accepted === undefined ? false : item.accepted;
  const wasConsigned =
    item && item.wasConsigned === undefined ? false : item.wasConsigned;
  const isConsigned =
    item && item.consigned === undefined ? false : item.consigned;

    const isWithdrawn =
    item && item.withdrawn === undefined ? false : item.withdrawn;

    // You can withdrawn an item if:
    // It has been consigned and not yet accepted or rejected.
    // It has been consigned and accepted
    // It has not already been withdrawn

    // If the item is not part of a currently ? auction.

    // const canWithdraw = !isWithdrawn && ((isConsigned && item.accepted === null) || (isConsigned && isAccepted)) 
    //const canWithdraw = isOwner && (item.withdrawn !== false) && (!item.consigned === false) && (item.accepted !== false);

  //  const canWithdraw = isOwner && (item.withdrawn === true) && (item.consigned === true);
// const canWithdraw = true;

const canWithdraw = isOwner && (item.withdrawn === false) && (item.consigned === true) && canConsign;

useEffect(() =>{
console.log("ItemWithdraw event", event)
//if (auctionStatus !=='success') {return;}
// console.log("auctionData",auctionData);const const

// (!isAuctioneer && item.accepted === true
// isStarted.
var c=true;
if (event && isPastTime(event.startTime)) {
  c=(false);
}

setCanConsign(c);
 
}, [event])

  // Consigned the item and allow it to be withdrawn
  // if the item has not yet been accepted.

 

  const withdrawItem = (item) => {
    if (isOwner) {
      //  if (isOwner && item.accepted !== true) {

      //updateItem(item.id, { consigned: false, accepted: null, withdrawn: true });
processWithdrawItem(item).then((result)=>{
console.log("ItemWithdraw result", result)
var message = "Owner withdrew item from the sale.";

// createMessage
// Creates a message in the users collection under messages with a unique reference
// return firestore.collection("users").doc(datagram.from).collection("messages").doc().set({

createMessage({
  subject: message,
  item: item.id,
  from: auth.user.id,
  to: "auctioneer",
});

}).catch((error)=>{
  alert("You must be the Owner of the item to use this feature");
  console.log("ItemWithdraw error", error)});
    }
  };

  if (!isOwner) {
    return null;
  }

  // accepted
  // consigned

  // if (auctionStatus !== 'success') {return null;}

  return (
    <>
   
      {isOwner ? (<>
        <Button
        fullWidth
          variant="outlined"
          disabled={
            (!canWithdraw)}
          checked={item.consigned}
          onClick={() => {setConfirmOpen(true)
           
          }}
          name="checkedA"
          inputProps={{ "aria-label": "secondary checkbox" }}
        >
          Withdraw Item
        </Button>
        <ConfirmDialog
        title="Withdraw Item?"
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={() =>{withdrawItem(item)}}
      >
        You are about to withdraw this item that has been submitted. Are you sure?
      </ConfirmDialog></>
      ) : null} 

      {/*item.consigned ? (<div>Submitted</div>) : (<div>Submit for approval</div>)*/}
    </>
  );
}

export default requireAuth(ItemWithdraw);
