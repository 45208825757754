import { memo, useState, useEffect, useCallback } from "react";
import { Card } from "./Card";
import { Grid, Box } from "@material-ui/core";
import update from "immutability-helper";
import { useDrop } from "react-dnd";
import { ItemTypes } from "./ItemTypes";
import { FullscreenExitTwoTone } from "@material-ui/icons";
import { wrap } from "analytics/lib/analytics.cjs";

const style = {
  //minHeight: 200,
  // maxWidth: 800,
  //width: '100%',
  //  display: 'flex',
  // spacing:'1'

 
};

// FLERP under 4 past here.
const ITEMS = [
  {
    index: 1,
    // id:1,
    text: "Write a cool JS library",
  },
  {
    index: 2,
    //  id:2,
    text: "Make it generic enough",
  },
  {
    index: 3,
    // id:3,
    text: "Write README",
  },
  {
    index: 4,
    //id:4,
    text: "Create some examples",
  },
  {
    index: 5,
    // id:5,
    text: "Spam in Twitter and IRC to promote it",
  },
  {
    index: 6,
    //id:6,
    text: "???",
  },
  {
    index: 7,
    // id:7,
    text: "PROFIT",
  },
];
export const Container = memo(function Container(props) {
  //const [cards, setCards] = useState(ITEMS);
  const [cards, setCards] = useState(props.images);
  //console.log("cards", cards);
  const findCard = useCallback(
    (id) => {
      //console.log("id", id);
      const card = cards.filter((c) => `${c.index}` === id)[0];
      return {
        card,
        index: cards.indexOf(card),
      };
    },
    [cards]
  );

  const moveCard = useCallback(
    (id, atIndex) => {
      const { card, index } = findCard(id);
      setCards(
        update(cards, {
          $splice: [
            [index, 1],
            [atIndex, 0, card],
          ],
        })
      );
    },
    [findCard, cards, setCards]
  );

  const deleteCard = useCallback(
    (id, atIndex) => {
//console.log("deleteCard id", id);
//console.log("deleteCard atIndex", atIndex);
      const { card, index } = findCard(id);

      //      const deleteCard = cards[deleteIndex];
            setCards(update(cards, {
                $splice: [[index,1]],
            }));
      //console.log("Container deleteCard cards", deleteIndex)
      //props.onReorderImages(cards)
    },
    [cards]
  );

  useEffect(() => {
    const reindexedCards = cards.map((image, i) => {
      return { ...image, index: i };
    });
     props.onReorderImages(reindexedCards);
  }, [cards, setCards]);

  const [, drop] = useDrop(() => ({ accept: ItemTypes.CARD }));




  
  return (


    <div ref={drop} style={style} >
     
   
     <Grid container spacing={2} direction="row" >
    


      {cards.map((card) => (
   
    // <div key={card.id}>  to avoid refresh
    
         <Card 
       
          key={card.id}
          id={`${card.index}`}
          card={card}
          text={card.text}
          moveCard={moveCard}
          deleteCard={deleteCard}
          findCard={findCard}
        />
   


      ))}
     
       </Grid>
    

      </div>


    
   
  );
});

export default Container;
