import { Link } from "./../util/router.js";
import Button from "@material-ui/core/Button";
import { useAuth } from "./../util/auth.js";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  logo: {
    height: 28,
    marginRight: theme.spacing(2),
  },
  drawerList: {
    width: 250,
  },
  spacer: {
    flexGrow: 1,
  },
  navbarBackground: {
    background: 'black',
  }, 
  button: {
    fontWeight: 600,
    paddingTop: "15px",
    paddingBottom: "15px",
    marginTop: "10px",
  },

}));

function SignInButton(props) {
  const history = useHistory();
  const classes = useStyles();
  const endpointUrl = window.location.pathname; //href gives full url
  const auth = useAuth();

  //function handleClick() {
  //  history.push(endpointUrl);
  //}

  return (
   
            <>
              {!auth.user && (
                <Button style={{ backgroundColor: "black", color: "white" }} className={classes.button} variant="contained" size="large" elevation={0} fullWidth component={Link} to={"/auth/signin?next="+endpointUrl}>
                  Sign in to place a bid
                </Button>
              )}
              </>


  );
}

export default SignInButton;
