import React, { useState } from "react";
import { humanTime } from "./../util/time.js";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import EditEventModal from "./EditEventModal";
import { useAuth } from "./../util/auth.js";
import { useEventsByOwner } from "./../util/db.js";
import { makeStyles } from "@material-ui/core/styles";
import { Container } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paperItems: {
    minHeight: "300px",
  },
  featured: {
    backgroundColor:
      theme.palette.type === "dark" ? theme.palette.action.selected : "#fdf8c2",
  },
  starFeatured: {
    color: theme.palette.warning.main,
  },
  collections: {
    display: "flex",
  },
  flexContainer: {
    display: "flex",
    flexDirection: "row",
    padding: 0,
  },
  listText: {
    maxWidth: "90%",
  },
}));

function ProfileEventList(props) {
  const classes = useStyles();

  const auth = useAuth();

  // const isAuctioneer = auth.user.role && auth.user.role === "auctioneer";

  // const eventTypeWordSingular = isAuctioneer ? "Collection" : "Client";
  // const eventTypeWordPlural = isAuctioneer ? "Collections" : "Clients";

  const {
    data: events,
    status: eventsStatus,
    error: eventsError,
  } = useEventsByOwner(props.seller.id);

  const [creatingEvent, setCreatingEvent] = useState(false);

  const [updatingEventId, setUpdatingEventId] = useState(null);

  const eventsAreEmpty = !events || events.length === 0;
  


  return (
    <>
      {eventsError && (
        <Box mb={3}>
          <Alert severity="error">{eventsError.message}</Alert>
        </Box>
      )}



    

        {eventsStatus !== "loading" && events && events.length > 0 && (
           
      
         
          <List disablePadding={true}>
            {events.map((event, index) => (
              
              
              <>
      <Container maxWidth="md">
      
          <Paper >
          <Box m={3} p={3}>  
      
        <div>
          <Typography
            variant="h4"
            gutterBottom
            style={{ fontFamily: '"Cormorant Garamond", "serif"' }}
          >
            <span>{event.name}</span>
          </Typography>
        </div>

        <Typography variant="body2" gutterBottom>
          {event.review}
        </Typography>

        <Divider style={{ margin: "10px" }} />

        <Box mt={1} >
          <Box display="flex" pb={0.5}>
            <Box flexGrow={1}>
              <Typography variant="body2" style={{ paddingRight: "15px" }}>
                Starts:
              </Typography>
            </Box>

            <Typography variant="body2">
              {
                humanTime(event.startTime)}
            </Typography>
          </Box>

          <Box display="flex" pb={0.5}>
            <Box flexGrow={1}>
              <Typography variant="body2" style={{ paddingRight: "15px" }}>
                Ends:
              </Typography>
            </Box>
            <Typography variant="body2">
              {
                humanTime(event.endTime)}
            </Typography>
          </Box>

        
            <>
              <Box display="flex" pb={0.5}>
                <Box flexGrow={1}>
                  <Typography variant="body2" style={{ paddingRight: "15px" }}>
                    Location:
                  </Typography>
                </Box>
                <Typography
                  variant="body2"
                  style={{ textDecoration: "underline", textAlign: "right" }}
                >
                  <a
                    target="_blank"
                    href={"https://maps.google.com?q=" + event.address} rel="noreferrer"
                  >
                    {event.address}
                  </a>
                </Typography>
              </Box>
            </>
      

     
        </Box>
        </Box>
        </Paper>
            

      </Container>

    </>
                    
            ))}
            
          </List>
         
           
        )}
    
     
      {creatingEvent && (
        <EditEventModal onDone={() => setCreatingEvent(false)} />
      )}

      {updatingEventId && (
        <EditEventModal
          id={updatingEventId}
          onDone={() => setUpdatingEventId(null)}
        />
      )}
    </>
  );
}

export default ProfileEventList;
