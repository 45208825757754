import { useState, useEffect } from "react";
import axios from "axios";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { useForm } from "react-hook-form";
import { useAuth } from "./../util/auth.js";
import { InputAdornment } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: '"Cormorant Garamond", "serif"',
    fontWeight: "600",
    lineHeight: 1,
  },
}));

function NewsletterSubscribe(props) {
  const classes = useStyles();
  const auth = useAuth();
  const [email, setEmail] = useState("");
  const userEmail = auth && auth.user && auth.user.email;
  const [subscribed, setSubscribed] = useState(false);
  const { flavour } = props;
  const [userSubscribed, setUserSubscribed] = useState(false);
  const listId = "5";

  const { register, errors } = useForm();
  const [errorMessage, setErrorMessage] = useState("");
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!email.includes("@")) {
      console.log("Invalid email address");
      setErrorMessage("Invalid email address");
      return;
    }
    if (!email.includes("@")) {
      console.log("Invalid email address");
      setErrorMessage("Invalid email address");
      return;
    }
    try {
      await axios.post(
        "https://api.sendinblue.com/v3/contacts",
        { email, listIds: [5] },
        {
          headers: {
            "Content-Type": "application/json",
            "api-key": process.env.REACT_APP_SEND_IN_BLUE_KEY,
          },
        }
      );
      setSubscribed(true);
      toast("🎉 Congratulations. You are on the list.",
      {
        toastId: "newsletterId"
      }
      );
    } catch (error) {
      console.error(error);
      console.log(error.response.data);
      setErrorMessage(error.response.data.message);
    }
  };

  useEffect(() => {
    const checkSubscription = async () => {
      const userEmail = auth && auth.user && auth.user.email;
      try {
        const response = await axios.get(
          `https://api.sendinblue.com/v3/contacts/lists/${listId}/contacts`,
          {
            headers: {
              "Content-Type": "application/json",
              "api-key": process.env.REACT_APP_SEND_IN_BLUE_KEY,
            },
          }
        );
        const emailList = response.data.contacts.map(
          (contact) => contact.email
        );
        if (emailList.includes(userEmail)) {
          setUserSubscribed(true);
        }
      } catch (error) {
        console.error(error);
      }
    };
    checkSubscription();
  }, []);

  return (
    <>
      <div>
        {/* {subscribed || userSubscribed ? ( */}
        {subscribed || userSubscribed ? null : (
          <>
   {flavour === "no_sales" && (
    <Box pb={1}>
<Typography
              variant="h6"
              component="p"
              color="textSecondary"
              className={classes.message}
            >
        Sign up now, and you will be first to know about our upcoming monthly Hampton's sales.  
            </Typography>
            </Box>
   )}


          {flavour === "copy_buylike" && (
            <Box py={1} style={{textAlign:'center'}}>
              <Typography variant="h4" gutterBottom className={classes.title}>
                Buy{" "}
                <span style={{ fontStyle: "italic", fontWeight: "300" }}>
                  (like a Hamptons)
                </span>{" "}
                Local
              </Typography>
              <Typography
                variant="subtitle1"
                color="textSecondary"
                style={{ lineHeight: 1.2 }}
              >
                Be the first to know about upcoming LadyBidwell sales in the
                Hamptons.
              </Typography>
            </Box>
)}
            <form onSubmit={handleSubmit}>
              <Grid container={true} alignItems="center">
                <Grid item={true} xs={true}>
                  <TextField
                    variant="outlined"
                    type="email"
                    name="email"
                    placeholder="fitz.darcy@example.com"
                    error={errors.email ? true : false}
                    helperText={
                   
                      <span style={{ color: "red" }}>
                     {errorMessage}
                      </span>
                
                    }
                    fullWidth={true}
                    style={{ background: "#ffffff" }}
                    inputRef={register({
                      required: "Please enter your email",
                    })}
                    onChange={(event) => setEmail(event.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Button
                            disableElevation
                            variant="contained"
                            color={props.buttonColor}
                            size="medium"
                            type="submit"
                          >
                            {props.buttonText}
                          </Button>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </form>
          </>
        )}
      </div>



    </>
  );
}

export default NewsletterSubscribe;
