import React, { useState, useEffect } from "react";
import { useRouter } from "./../util/router.js";
import { useLocation } from "react-router-dom";
import Section from "./Section";
import AppBar from "@material-ui/core/AppBar";
import Container from "@material-ui/core/Container";
import Toolbar from "@material-ui/core/Toolbar";
import { Link, NavLink } from "./../util/router.js";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { useAuth } from "./../util/auth.js";
import useDarkMode from "use-dark-mode";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import SearchIcon from "@material-ui/icons/Search";
import Box from "@material-ui/core/Box";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import FavoriteIcon from "@material-ui/icons/Favorite";
import GavelIcon from "@material-ui/icons/Gavel";
import StoreIcon from "@material-ui/icons/Store";
import { Tooltip } from "@material-ui/core";
import ShoppingBasketIcon from "./ShoppingBasketIcon";
import StorefrontIcon from "@material-ui/icons/Storefront";
import Logo from "./Logo";
import LockIcon from "@material-ui/icons/Lock";
import Announcement from "./Announcement.js";
import SettingsIcon from "@material-ui/icons/Settings";
import ReceiptIcon from "@material-ui/icons/Receipt";
import { MenuList, Divider } from "@material-ui/core";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import AccountCircleSharpIcon from "@material-ui/icons/AccountCircleSharp";
import { HashLink } from "react-router-hash-link";
import AuthDialog from "./AuthDialog.js";

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: "flex-start",
  },

  button: {
    padding: "8px",
    [theme.breakpoints.up("md")]: {
      padding: "8px",
    },
    "&.active": {
      // color:theme.palette.primary.main,
      color: "#ffffff",
      background: theme.palette.secondary.main,
    },
  },

  logo: {
    height: 28,
    marginRight: theme.spacing(2),
  },
  drawerList: {
    width: 250,
  },
  spacer: {
    flexGrow: 1,
  },
  navbarBackground: {
    background: "#ffffff",
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "#e2e2e2",
    color: "black",
    marginRight: 1,
    marginLeft: 10,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(2),
      width: "auto",
      flexGrow: "1",
    },
  },

  // menuItem: {
  //   paddingRight: '0px'
  // },
  // link: {
  //   position: 'relative',
  //   display: 'inline-block',
  //   '&:after': {
  //     content: '""',
  //     position: 'absolute',
  //     width: '100%',
  //     height: '2px',
  //     bottom: '-2px', // Adjust this value to offset the underline
  //     left: '0',
  //     backgroundColor: 'currentColor',
  //     transform: 'scaleX(0)',
  //     transformOrigin: 'right',
  //     transition: 'transform 0.3s ease-out',
  //   },
  //   '&.active:after': {
  //     transform: 'scaleX(1)',
  //     transformOrigin: 'left',
  //   },
  // },

  

  link: {
    padding: theme.spacing(1, 2),
    cursor: "pointer",
    position: 'relative',
    '&:after': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '2px',
      bottom: '0px', // Adjust this value to offset the underline
      left: '0',
      backgroundColor: 'currentColor',
      transform: 'scaleX(0)',
      transformOrigin: 'right',
      transition: 'transform 0.3s ease-out',
    },
    '&.active:after': {
      transform: 'scaleX(1)',
      transformOrigin: 'left',
    },
    '&.active': {
      textDecoration: "none",
    },
  },

  // firstLink: {
  //   paddingLeft: 0
  // },

  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    // justifyContent: "center",
    color: "black",
  },
  wrapIcon: {
    verticalAlign: "middle",
    display: "inline-flex",
  },

  inputInput: {
    color: "black",
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    // height: '28px',
    [theme.breakpoints.up("md")]: {
      width: "35ch",
    },
  },

  toolbar: {
    zIndex: "99",
  },

  buttonActive: {
    color: theme.palette.primary.main,
    background: theme.palette.secondary.main, // Or any color you want for the active state
    color: "#ffffff",
    "&:hover": {
      backgroundColor: theme.palette.secondary.main, // Or any color you want for the hover state
    },
  },

  // toolbar: theme.mixins.toolbar,
}));

// function HideOnScroll(props) {
//   const { children, window } = props;
//   // Note that you normally won't need to set the window ref as useScrollTrigger
//   // will default to window.
//   // This is only being set here because the demo is in an iframe.
//   const { trigger } = useScrollTrigger;

// };

function Navbar(props) {
  const classes = useStyles();
  const router = useRouter();
  const [activeItem, setActiveItem] = useState("sale");
  const [isOpen, setIsOpen] = useState(false);

  const location = useLocation();
  const path = router.pathname;
  const showFrame = !(path === "/checkout");
  const searchEnabled = true; // Algolia / Elastic search. Pay to play. Disable for launch.
  const queryNext = router.query.next;
  var afterAuthPage = "/?next=" + path;

  if (path.startsWith("/auth")) {
    //console.log("path",path);
    console.log("queryNext", queryNext);
    // var parts = path.split('?next=');
    //console.log("parts",parts);
    afterAuthPage = "?next=" + queryNext;
  }

  // anything with auth at the beginning.
  // http://localhost:3000/auth/signin/?next=/
  // http://localhost:3000/auth/signup/?next=/

  /*
  useEffect(() =>{

console.log(router.pathname)
console.log(router.query)

  }
  
  ,[])
  */

  const auth = useAuth();

  //const history = useHistory()
  const isMarketplace = Boolean(location.pathname.match(/\/(.*)\/marketplace/));

  const isHomepage = location.pathname === "/" || isMarketplace ;
  const isItemPage = location.pathname.includes("/item");
  const darkMode = useDarkMode();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [menuState, setMenuState] = useState(null);
  const isSeller =
    auth &&
    auth.user &&
    auth.user.seller &&
    auth.user.seller.enabled &&
    auth.user.seller.enabled === true;
  //const isSeller = true;

  const [authCommand, setAuthCommand] = useState("signup");

  const isAuctioneer =
    auth.user && auth.user.role && auth.user.role === "auctioneer";
  const isDeveloper = auth?.user?.developer?.enabled;
  // Use inverted logo if specified
  // and we are in dark mode
  const logo =
    props.logoInverted && darkMode.value ? props.logoInverted : props.logo;

  const handleOpenAuth = (text) => {
    console.log("Navbar handleOpenAuth text", text);
    setAuthCommand(text);
    setIsOpen(true);
    setIsOpen(!isOpen);
    //setIsOpen(false);
  };

  useEffect(() => {
    console.log("Navbar authCommand", authCommand);
  }, [authCommand]);

  const handleOpenMenu = (event, id) => {
    // Store clicked element (to anchor the menu to)
    // and the menu id so we can tell which menu is open.
    setMenuState({ anchor: event.currentTarget, id });
  };

  const signout = async () => {
    //window.onbeforeunload = null;
    //window.location.href = `${window.location.origin}/`;

    //await history.push("/")

    return auth.signout();
  };

  const SellingIcon = () => {
    if (!isSeller) {
      return null;
    }

    return (
      <>
        {path !== "/selling" ? (
          <IconButton
            color="inherit"
            component={Link}
            to="/selling"
            // style={{ opacity: 0.6 }}
          >
            <StoreIcon />
          </IconButton>
        ) : (
          <IconButton color="inherit" component={Link} to="/selling">
            <StoreIcon color="secondary" />
          </IconButton>
        )}
      </>
    );
  };

  const EventsLinkMobile = () => {
    if (isAuctioneer) {
      return (
        <>
          <ListItem button={true} component={Link} to="/clients">
            <ListItemText>Your Collections</ListItemText>
          </ListItem>
        </>
      );
    }

    if (isSeller) {
      return (
        <>
          <ListItem button={true} component={Link} to="/clients">
            <ListItemText>Add Items</ListItemText>
          </ListItem>
        </>
      );
    }

    return null;
  };

  useEffect(() => {
    console.log("Navbar isOpen", isOpen);
  }, [isOpen]);

  function handleCloseAuth() {
    setIsOpen(false);
  }

  const user = auth?.user;

  useEffect(() => {
    if (auth.user == null) {
      return;
    }

    // User is logged in.
    // So auth dialog should not be showing.
    setIsOpen(false);
  }, [user]);

  const handleCloseMenu = () => {
    setMenuState(null);
  };

  if (!showFrame) {
    return (
      <>
        <Section bgColor={props.color} size="auto">
          <AppBar
            position="static"
            color="transparent"
            elevation={0}
            style={{ borderBottom: "1px solid #e8e8e8" }}
          >
            <Container>
              <Toolbar
                disableGutters={true}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Link to="/" style={{ textDecoration: "none" }}>
                  <Logo flavour="fulllogo" />
                </Link>

                <Typography variant="subtitle1" className={classes.wrapIcon}>
                  <LockIcon /> &nbsp; Secure Checkout
                </Typography>
              </Toolbar>
            </Container>
          </AppBar>
        </Section>
      </>
    );
  }

  return (
    <>
      {isHomepage || isItemPage ? <Announcement /> : null}

      {/* <GlobalLoadingIndicator /> */}
      <Section bgColor={props.color} size="auto">
        <AppBar
          position="relative"
          color="transparent"
          elevation={0}
          // style={{ background: "#ffffff" }}
          className={classes.toolbar}
          style={{ borderBottom: "1px solid #e8e8e8" }}
        >
          {isDeveloper && (
            <>
              <Box py={0.5} style={{ background: "yellow" }}>
                Developer{" "}
              </Box>{" "}
            </>
          )}
          {/* <Hidden smUp>
            <Box style={{ background: "#2b2b2b" }} py={1}>
              <Container>
                <Typography
                  variant="subtitle2"
                  style={{
                    fontSize: ".75em",
                    letterSpacing: "3px",
                    fontWeight: "300",
                    color: "white",
                  }}
                >
                  HAMPTONS, NY
                </Typography>
  
              </Container>
            </Box>
          </Hidden> */}

          <Container>
            <Toolbar disableGutters={true}>
              <Link
                to={{ pathname: "/", state: { doNotRestoreScroll: true } }}
                style={{ textDecoration: "none" }}
              >
                {/* <img src={logo} alt="Logo" className={classes.logo} /> */}

                {/* Desktop view */}

                <Hidden xsDown={true} implementation="css">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    <Logo flavour="fulllogo" />
                    <Box px={2}>
                      <Typography
                        variant="subtitle2"
                        style={{ letterSpacing: "3px", fontWeight: "300" }}
                      >
                        HAMPTONS, NY
                      </Typography>
                    </Box>
                  </div>
                </Hidden>

                {/* Mobile view */}

                <Hidden smUp={true} implementation="css">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    <Logo flavour="mobile" />
                  </div>
                </Hidden>
              </Link>

              <div className={classes.spacer} />

              {!auth.user && (
                <>
                  <Hidden mdUp>
                    <Tooltip title="SignIn/SignUp" arrow>
                      <IconButton
                        style={{
                          textTransform: "none",
                          fontWeight: "400",
                          fontSize: "1.1em",
                        }}
                        color="inherit"
                        //component={Link}
                        //to={"/auth/signin" + afterAuthPage}
                        onClick={() => handleOpenAuth("signup")}
                      >
                        <AccountCircleSharpIcon />
                      </IconButton>
                    </Tooltip>
                  </Hidden>
                  <Hidden smDown>
                    <Button
                      style={{
                        textTransform: "none",
                        fontWeight: "400",
                        fontSize: "1.1em",
                      }}
                      color="inherit"
                      component={Link}
                      //to={"/auth/signin" + afterAuthPage}
                      onClick={() => handleOpenAuth("signin")}
                    >
                      Sign In
                    </Button>

                    {" | "}

                    <Button
                      style={{
                        textTransform: "none",
                        fontWeight: "400",
                        fontSize: "1.1em",
                      }}
                      color="inherit"
                      component={Link}
                      //to={"/auth/signup" + afterAuthPage}
                      onClick={() => handleOpenAuth("signup")}
                    >
                      Create Account
                    </Button>
                  </Hidden>
                </>
              )}

              <AuthDialog
                isDialogOpened={isOpen}
                handleCloseDialog={() => handleCloseAuth()}
                authCommand={authCommand}
                // subtitle="Put all your loves in a safe place..."
              />

              {auth.user && (
                <>
                  {isAuctioneer && (
                    <>
                      <Button
                        className={classes.button}
                        component={NavLink}
                        to="/clients"
                      >
                        <GavelIcon fontSize="medium" />
                      </Button>
                    </>
                  )}

                  {!isAuctioneer && isSeller && (
                    <>
                      <Tooltip title="Seller Dashboard" arrow>
                        <IconButton
                          className={classes.button}
                          component={NavLink}
                          to="/clients"
                        >
                          <StorefrontIcon fontSize="medium" />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}

                  <HashLink to="/#search">
                    <IconButton color="inherit" className={classes.button}>
                      <SearchIcon />
                    </IconButton>
                  </HashLink>

                  <Tooltip title="Your account" arrow>
                    <IconButton
                      className={`${classes.button} ${
                        path === "/orders" || path.startsWith("/settings/")
                          ? classes.buttonActive
                          : ""
                      }`}
                      color="inherit"
                      aria-label="Account"
                      aria-controls="account-menu"
                      aria-haspopup="true"
                      onClick={(event) => {
                        handleOpenMenu(event, "account-menu");
                      }}
                    >
                      {!(
                        path === "/orders" || path.startsWith("/settings/")
                      ) ? (
                        <AccountCircleSharpIcon />
                      ) : (
                        <AccountCircleSharpIcon />
                      )}
                    </IconButton>
                  </Tooltip>

                  <Menu
                    id="account-menu"
                    open={
                      menuState && menuState.id === "account-menu"
                        ? true
                        : false
                    }
                    anchorEl={menuState && menuState.anchor}
                    getContentAnchorEl={undefined}
                    onClick={handleCloseMenu}
                    onClose={handleCloseMenu}
                    keepMounted={true}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    PaperProps={{
                      elevation: 0,

                      style: {
                        overflow: "visible",
                        background: "#ffffff",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,

                        "& .MuiAvatarRoot": {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        "&:before": {
                          content: '""',
                          display: "block",
                          position: "absolute",
                          top: 0,
                          right: 14,
                          width: 10,
                          height: 10,
                          bgcolor: "background.paper",
                          transform: "translateY(-50%) rotate(45deg)",
                          zIndex: 0,
                        },
                      },
                    }}
                  >
                    <MenuList>
                      <MenuItem
                        className={classes.root}
                        component={Link}
                        to="/orders"
                      >
                        <ListItemIcon>
                          <ReceiptIcon />
                        </ListItemIcon>
                        <Typography variant="subtitle1">
                          Your Purchases
                        </Typography>
                      </MenuItem>

                      {/* <MenuItem component={Link} to="/invoices">
                      Your Invoices
                    </MenuItem> */}

                      <MenuItem
                        className={classes.root}
                        component={Link}
                        to="/settings/general"
                      >
                        <ListItemIcon>
                          <SettingsIcon />
                        </ListItemIcon>

                        <Typography variant="subtitle1">
                          Account Settings
                        </Typography>
                      </MenuItem>

                      {!isSeller && (
                        <MenuItem
                          className={classes.root}
                          component={Link}
                          to="/sell/apply"
                        >
                          <ListItemIcon>
                            <StorefrontIcon />
                          </ListItemIcon>

                          <Typography variant="subtitle1">Sell</Typography>
                        </MenuItem>
                      )}

                      <Box pt={2} />
                      <Divider />
                      <MenuItem
                        className={classes.root}
                        onClick={(event) => {
                          console.log("Navbar signout clicked");
                          // console.log("Navbar auth.signout result", result)
                          // window.onbeforeunload = null;
                          // window.location.href = `${window.location.origin}/`;
                          // auth.signout().then((result) => {
                          return signout()
                            .then((result) => {
                              console.log("Navbar signout result", result);
                              // Aded because oltherwise the auth signin pops up.
                              //window.onbeforeunload = null;
                              //window.location.href = "/";
                            })
                            .catch((error) => {
                              console.error("Navbar signout error", error);
                            });
                        }}
                      >
                        <ListItemIcon>
                          <ExitToAppIcon />
                        </ListItemIcon>
                        <Typography variant="subtitle1">Signout</Typography>
                      </MenuItem>
                    </MenuList>
                  </Menu>

                  {path !== "/liked" ? (
                    <Tooltip title="Likes" arrow>
                      <IconButton
                        color="inherit"
                        className={classes.button}
                        component={NavLink}
                        to="/liked"
                      >
                        <FavoriteBorderIcon />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <IconButton
                      color="inherit"
                      component={NavLink}
                      to="/liked"
                      className={classes.button}
                    >
                      <FavoriteIcon fontSize="medium" />
                    </IconButton>
                  )}
                </>
              )}

              <ShoppingBasketIcon />

              {!isSeller && !isAuctioneer ? (
                <>
                  <Hidden mdUp>
                    <Button
                      component={Link}
                      to="/sell/apply"
                      disableElevation
                      variant="contained"
                      size="small"
                    >
                      Sell
                    </Button>
                  </Hidden>
                  <Hidden smDown>
                    <Button
                      component={Link}
                      to="/sell/apply"
                      disableElevation
                      variant="contained"
                      size="small"
                    >
                      Sell
                    </Button>
                  </Hidden>
                </>
              ) : null}
            </Toolbar>
          </Container>

      
            <Box style={{ background: "#ffffff", borderTop:'1px solid #e0e0e0',
             }} >

{/* <Hidden smUp> 


<Container>

      <Typography variant="subtitle2"
        style={{
          // fontSize: ".75em",
          letterSpacing: "3px",
          // fontWeight: "300",
          color: "black",
        }} 
        textAlign="center"
      >
        HAMPTONS, NY 
      </Typography>
  
    </Container>
         

</Hidden> */}

         <Container disableGutters>
                <Toolbar> 
   


        <Typography
          variant="h6"
          component={Link}
          onClick={() => setActiveItem('sale')}
          className={`${classes.link} ${activeItem === 'sale' ? "active" : ""} ${classes.firstLink}`}
          to={{ pathname: "/", state: { doNotRestoreScroll: true } }}
        >
          Sale
        </Typography>
 
        <Typography
          variant="h6"
          onClick={() => setActiveItem('marketplace')}
          className={`${classes.link} ${activeItem === 'marketplace' ? "active" : ""}`}
          component={Link}
          to={{ pathname: "/hamptons/marketplace", state: { doNotRestoreScroll: true } }}
        >
          Marketplace
        </Typography>
  

                </Toolbar>
                </Container>  
  
           
            </Box>


        </AppBar>
      </Section>
    </>
  );
}

export default Navbar;
