import React, { useState, useEffect } from "react";
//import { useAuth } from "./../util/auth.js";
//import Button from "@material-ui/core/Button";
import { useAuctionByEvent } from "../util/db.js";
import {
  getFirebaseCurrentTime,
  humanTime,
  timetogoTime,
  firebaseTime,
  secondsDifference,
  secondsToReadableString,
  countdownTime,
} from "../util/time.js";
import LinearProgress from "@material-ui/core/LinearProgress";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  MuiThemeProvider,
  createTheme,
} from "@material-ui/core/styles";

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 10,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#e01212de",
  },
}))(LinearProgress);

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
});

export const theme = createTheme({
  // direction: 'rtl',

  typography: {
    useNextVariants: true,
  },
});

const ltrTheme = {
  ...theme,
  direction: "rtl",
};

function LotCountdown(props) {
  const classes = useStyles();

  const isCart = props.flavour === "cart" ? true : false;

  // TODO
  //const currentTime = useFirebaseCurrentTime();
  const { lot } = props;
  const { type } = props;
  const { data, status } = useAuctionByEvent(
    lot === null ? undefined : lot.auction
  );

  const calculateTimeLeft = () => {
    if (lot === null) {
      return null;
    }
    const currentTime = getFirebaseCurrentTime();

    var t = secondsDifference(lot.endsAt, currentTime);
    return t;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    // Clear timeout if the component is unmounted
    return () => clearTimeout(timer);
  });

  const TextCountDown = () => {
    /*
Auction is stopped: Show "STOPPED"
Auction is running and lot close is in future: Show "Countdown"
Auction is running and lot close is in past: Show "Not yet knocked down"
Auction is running and lot is knocked down: Show knocked down time.

    */

    // Question for the discord
    // const [data, status] = useRandomHookName()
    // what is pattern to do
    // const [customVariableName, customVariableStatus] = useRandomHookName();

    var text = "Error";

    // Is the auction running.
    if (data && data.state === true) {
      text = "Running";
      //var text = "Running - " + humanTime(firebaseTime(lot.endsAt));

      if (type === undefined) {
        text = "Ends in " + secondsToReadableString(timeLeft);
      }

      if (type === "timestamp") {
        //return <span>{secondsToReadableString(timeLeft)}</span>;
        if (timeLeft > 5 * 60) {
          return <> {humanTime(lot && lot.endsAt)}</>;
        }
        if (timeLeft >= 0) {
          return (
            <>
              Ending soon
              <br />
            </>
          );
        }
        return (
          <>
            <span>Ended</span>
          </>
        );
      }

      if (type === "numbers" && timeLeft >= 0) {
        if (timeLeft > 5 * 60) {
          return (
            <>
              {" "}
              <span>{timetogoTime(lot.endsAt)}</span>{" "}
            </>
          );
        }

        return (
          <>
            <span>{countdownTime(lot.endsAt)}</span>
          </>
        );
      }

      if (timeLeft >= 0 && timeLeft <= 60 && type === "bar") {
        const percent = (timeLeft / 60) * 100;

        return (
          <MuiThemeProvider theme={ltrTheme}>
            <div dir="ltr">
              <BorderLinearProgress variant="determinate" value={percent} />
            </div>
          </MuiThemeProvider>
        );
      }
      if (type === "bar") {
        return null;
      }

      if (timeLeft < 0) {
        text = "Ended";
      }
      if (lot.knockedDownAt !== false) {
        //text = lot.knockedDownAt;
        if (lot && lot.knockedDownAt === undefined) {
          text = "Not knocked down.";
        } else {
          text = "Ended at " + humanTime(firebaseTime(lot.knockedDownAt));
        }
      }
    } else {
      text = "Not running";
      if (timeLeft > 0) {
        text = "Bidding halted";
      }
    }
    if (type === "bar") {
      return null;
    }

    return <>{text}</>;
  };
/*
  if (status !== "success" || lot === undefined || lot === null) {
    return null;
  }
*/
  return (
    <>
      <TextCountDown />
      <div className={classes.root}></div>
    </>
  );
}

export default LotCountdown;
