import React from "react";
import { useItemsByIds } from "./../util/db.js";
import ItemCard from "./ItemCard.js";
import { Box } from "@material-ui/core";

function ItemsIds(props) {
  const { items, children, flavour, spice } = props;

  const { data: itemsData, status, error } = useItemsByIds(items);
  const hydratedItems = itemsData && itemsData.items;
  /*
  if (status !== "success") {s    return <>SKELETON</>;
  }
*/
  /*
Intended as a wrapper. However, no tim to develop that.
So Item Ids hardcodes a call to the car view of an ItemCard.
Refactor to pass props down to wrapper childen and allow children to call in hydrated items.
One day.
*/

  return (
    <>
      {children}

      {hydratedItems &&
        hydratedItems.map((item) => {
          return (
       
              <Box key={item.id}>
                {/* See ItemsIds and ItemsListPayment. */}
                <ItemCard
                  item={item}
                  flavour={flavour ? flavour : "cart"}
                  spice={spice ? spice : "noremove"}
                />
              </Box>
    
          );
        })}
    </>
  );
}

export default ItemsIds;
