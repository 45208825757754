import React from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { requireAuth } from "../util/auth";
import { useAuth } from "./../util/auth.js";
import PublishEvent from "./../components/PublishEvent";
import { useAuctionByEvent } from "./../util/db.js";
import RunAuction from "./../components/RunAuction";

import CreateLots from "./../components/CreateLots";

const useStyles = makeStyles((theme) => ({

    paperItems: {
      minHeight: "300px",
      
    },
  

  }));



function LotsControl(props) {
    const auth = useAuth();
    const classes = useStyles();
    const {items} = props;
    const {event} = props;
     const isAuctioneer =
    auth.user.role &&
    (auth.user.role === "auctioneer");

    const {status, data} = useAuctionByEvent(event.id);



if (status !== 'success') {return null}


return (<>

              {isAuctioneer ? (<>
              <Grid container spacing={1} justifyContent="flex-end">
              <CreateLots event={event} items={items} />
              {/*<ResetAuction event={event} />*/}
              <PublishEvent event={event} />
              <RunAuction event={event}/>
              </Grid>
              </>
              ) : null}




  </>
  )
}

export default requireAuth(LotsControl);
