import React from "react";
import ContentCardsSection from "./../components/ContentCardsSection";

function ContentCardsPage(props) {
  return (
    <ContentCardsSection
      bgColor="default"
      size="medium"
      bgImage=""
      bgImageOpacity={1}
      title="Featured Content"
      subtitle=""
    />
  );
}

export default ContentCardsPage;
