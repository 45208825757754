import { memo } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { ItemTypes } from './ItemTypes';
import FirebaseStorageImage from "./../components/FirebaseStorageImage";
import {Grid, Box, IconButton} from "@material-ui/core/"
import { makeStyles, useTheme } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
const useStyles = makeStyles((theme) => ({
   editItemModal : {
        maxHeight: '60px'
   },
   button: {
    padding:'5px',
    bottom:-5,
    right:-5,
    borderRadius:'50%',

  }
  }));


const style = {
    // boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',

   
    // marginRight: '1rem',

    // backgroundColor: 'white',
    //  cursor: 'move',

    // display: 'flex',
 
    // alignSelf: 'flex-end',
    // textAlign: 'right',
    // height:'100%'

};

export const Card = memo(function Card({ id, card, text, moveCard, deleteCard, findCard, }) {
    
    const classes = useStyles();

    const originalIndex = findCard(id).index; //index
    const [{ isDragging }, drag] = useDrag(() => ({
        type: ItemTypes.CARD,
        item: { id, originalIndex },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        end: (item, monitor) => {
            const { id: droppedId, originalIndex } = item;
            const didDrop = monitor.didDrop();
            if (!didDrop) {
                moveCard(droppedId, originalIndex);
            }
        },
    }), [id, originalIndex, moveCard]);
    const [, drop] = useDrop(() => ({
        accept: ItemTypes.CARD,
        canDrop: () => false,
        hover({ id: draggedId }) {
            if (draggedId !== id) {
                const { index: overIndex } = findCard(id);
                moveCard(draggedId, overIndex);
            }
        },
    }), [findCard, moveCard]);
    const opacity = isDragging ? 0 : 1;

    function handleDelete() {
        //console.log("Saw delete index", id)
        deleteCard(id)
        
            }

    return (

       
          <Grid item xs={3} sm={2}  ref={(node) => drag(drop(node))} style={{ ...style, opacity }}>
        
            <Box style={{border:'1px solid #7d7d7d', borderRadius:'8px', padding:'5px', height:'80px', display:'flex', cursor:'move', justifyContent:'center', position:'relative'}} > 
            <FirebaseStorageImage allowDelete={false} image={card} onDelete={handleDelete}  /> 
            <div
          className={classes.button}
          style={{ background: "#ffffff", position:'absolute' }}
          aria-label="delete"
          onClick={() => handleDelete()}
        >
          <DeleteIcon />
        </div>

 

            </Box>

   
    

            </Grid>
    
        );
})

