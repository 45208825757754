import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {
  DialogActions,
  Typography,
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useAuth } from "./../util/auth.js";
import { useForm } from "react-hook-form";
import {
  createMessage,
} from "./../util/db.js";


import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Slide,
  useMediaQuery,
} from "@material-ui/core";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const useStyles = makeStyles((theme) => ({
  textarea: {
    resize: "both",
  },

  select: {
    display: "flex",
    minWidth: 250,
    // background: 'white',
    borderStyle: "none",
    borderRadius: 8,
    // paddingTop: 14,
    // paddingBottom: 15,
    marginBottom: 15,
    boxShadow: "none",
    background: "#00000014",
    "&:focus": {
      borderRadius: 8,
      backgroundColor: "none",
      // background: 'white',
    },
    '&[aria-expanded="true"]': {
      background: "none",
    },
    "& > div": {
      display: "inline-flex", // this shows the icon in the SelectInput but not the dropdown
      alignItems: "center",
    },
  },

  listIcon: {
    minWidth: "40px",
    paddingLeft: "10px",
  },

  content: {
    paddingBottom: 24,
  },
  dialogTitle: {
    textAlign: "center",
    fontFamily: '"Cormorant Garamond", "serif"',
  },
  backDrop: {
    backdropFilter: "blur(3px)",
    backgroundColor: "rgba(0,0,30,0.4)",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export function ItemResolution(props) {
  const { item } = props;
  const auth = useAuth();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const classes = useStyles();
  const [pending, setPending] = useState(false);
  const { register, handleSubmit, watch, errors, control } = useForm();
  const onSubmit = (data) => {
    console.log(data);

    // Set `pending` to true to show the loading spinner
    setPending(true);

    var userId = "unknown";
    if (auth.user.id !== undefined) {
      userId = auth.user.id;
    }
    const issueDetails = data.issue; // Extract the issue details from the form data
    const subject = "Item Resolution Request: " + item.name;
    const text =
      "Item resolution requested by\n" +
      auth.user.name +
      " (" +
      userId +
      ")\n" +
      item.id +
      "\n\nIssue Details:\n" +
      issueDetails; // Include the issue details in the email body
  

    const datagram = {
      to: "administrator",
      subject: subject,
      text: text,
      transport: "email",
    };

    createMessage(datagram, userId);

    try {
      // Your code for submitting the form

      // Create a Toastify success message
      toast.success("Item resolution request submitted!");

      // Close the dialog after a short delay to allow time for the Toastify message to display
      setTimeout(() => {
        props.onDone();
      }, 1000);
    } catch (error) {
      console.error(error);

      // Create a Toastify error message
      toast.error("Error submitting item resolution request.");
    } finally {
      // Set `pending` back to false to hide the loading spinner
      setPending(false);
    }

    console.log("ItemResolution created message", datagram);
  };

  return (
    <>
      <Dialog
        TransitionComponent={Transition}
        fullScreen={fullScreen}
        disableBackdropClick="true"
        open={props.open}
        BackdropProps={{
          classes: {
            root: classes.backDrop,
          },
        }}
        onClose={props.onDone}
        maxWidth="md"
      >
        <Box p={1}>
          <DialogTitle>
            <Typography variant="h4" className={classes.dialogTitle}>
              {!props.id && <>Report Issue</>}
            </Typography>
          </DialogTitle>

          <form formId="seller_enquiry" onSubmit={handleSubmit(onSubmit)}>
            <DialogContent className={classes.content}>
              {/* {formAlert && (
              <Box mb={4}>
                <Alert severity={formAlert.type}>{formAlert.message}</Alert>
              </Box>
            )} */}

              <Grid container={true} spacing={2}>
                <>
                  <Grid item={true} xs={12}>
                    <TextField
                      disabled
                      fullWidth
                      defaultValue={item.name}
                      variant="outlined"
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item={true} xs={12}>
                    <TextField
                      multiline
                      minRows={8}
                      variant="outlined"
                      type="text"
                      //   label="Issue"
                      placeholder="Please provide details regarding the issue."
                      name="issue"
                      //   defaultValue={"Please provide details regarding the issue."}
                      error={errors.issue ? true : false}
                      //   helperText={errors.name && errors.name.message}
                      fullWidth={true}
                      autoFocus={true}
                      InputLabelProps={{ shrink: true }}
                      inputRef={register({
                        required: "Please provide details regarding the issue.",
                      })}
                    />
                  </Grid>
                </>
              </Grid>
              <Box py={1}>
                <Typography gutterBottom>
                  Once submitted we will get in-contact with you to help resolve
                  any issue you may be having.
                </Typography>
              </Box>
            </DialogContent>

            <DialogActions
              style={{
                position: "sticky",
                bottom: "0",
                background: "#ffffff",
                // borderTop: "2px solid #c4c4c4",
              }}
            >
              <Box>
                <Button
                  variant="outlined"
                  color="primary"
                  size="large"
                  onClick={props.onDone}
                  disabled={pending}
                  style={{ justifyContent: "flex-start" }}
                >
                  <span>Cancel</span>
                </Button>
              </Box>

              <Button
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                disabled={pending}
              >
                {!pending && <span>Submit</span>}

                {pending && <CircularProgress size={28} />}
              </Button>
            </DialogActions>
          </form>
        </Box>
      </Dialog>
    </>
  );
}
