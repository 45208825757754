import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "./../util/auth.js";
import {
  useStarsByOwner,
} from "./../util/db.js";
import { makeStyles } from "@material-ui/core/styles";
import ItemsList from "./../components/ItemsList";
import { Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
  paperItems: {
    minHeight: "300px",
  },
  featured: {
    backgroundColor:
      theme.palette.type === "dark" ? theme.palette.action.selected : "#fdf8c2",
  },
  starFeatured: {
    color: theme.palette.warning.main,
  },
}));

function ItemsListWatch(props) {
  const {filter, tab, mode} = props;
  const classes = useStyles();


  const auth = useAuth();




const {data: stars, status:starsStatus, error:itemsError} = useStarsByOwner(auth.user.id);

  const isAuctioneer = auth.user.role && auth.user.role === "auctioneer";

  const itemTypeWordSingular = isAuctioneer ? "Auction" : "Collection";
  const itemTypeWordPlural = isAuctioneer ? "Auctions" : "Collections";

  var isReady = starsStatus === 'success'
  var filteredItems = [];
if (isReady) {

  // ToDo: Deduplicated stars (which will have multiple entries for the same star/heart)
  // And only use the latest created star/heart.

filteredItems = stars
  .filter(star => star.featured === true)
  .map((star, index) => {return {id:star.item}});

}

useEffect(() =>{
console.log("ItemsListWatch stars",stars)
}, [stars])

useEffect(()=>{

  console.log("ItemsListWatch filteredItems", filteredItems)

}, [filteredItems])

if (!isReady) {return null;}

if (filteredItems && filteredItems.length ===0 ) {return (
<>
<Box textAlign="center" display="flex"  alignItems="center" justifyContent="center" style={{minHeight:'60vh', }} >
  

<Typography variant="h3" gutterBottom>💔 You haven't loved anything yet.


</Typography>

</Box>
</>
)}



  return (
    <>
         <ItemsList likes={true} createItem={false} items={filteredItems} tab={tab} mode={mode} filterType={"all"} /> 
    </>
  );
}

export default ItemsListWatch;


/*

            {items
              .filter((item) => (item.name !== undefined && item.accepted === true))
              .map((item, index) => (


*/
