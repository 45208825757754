import React, { useEffect, useState } from 'react';
import ItemsList from "./../components/ItemsList";
import Pagination from '@material-ui/lab/Pagination';
import { Box } from '@material-ui/core';

// Example items, to simulate fetching from another resources.
/*
const items = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];

function Items({ currentItems }) {
  return (
    <>
      {currentItems &&
        currentItems.map((item) => (
          <div>
            <h3>Item #{item}</h3>
          </div>
        ))}
    </>
  );
}
*/






function PaginatedItems(props) {

//function PaginatedItems({ items, itemsPerPage, filterType }) {

const {items, spice, flavour, itemsPerPage, filterType} = props;

  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  // Simulate fetching items from another resources.
  // (This could be items from props; or items loaded in a local state
  // from an API endpoint with useEffect and useState)
  const endOffset = itemOffset + itemsPerPage;
  console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  const currentItems = items.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(items.length / itemsPerPage);
const [page, setPage] = useState(1);
  // Invoke when user click to request another page.

  useEffect(()=>{
handlePageClick({}, 1)
    //setPage(1);
    //forceCheck();

  }, [filterType])

  const handlePageClick = (event, _page) => {
    //nt code
    // const newOffset = (event.selected * itemsPerPage) % items.length;


    //mt code
    const newOffset = (_page - 1) * itemsPerPage;
    //console.log(
    //  `User requested page number ${event.selected}, which is offset ${newOffset}`
    //);
    setItemOffset(newOffset);
setPage(_page);
  window.scrollTo({
    top: 0,
  });
  };

  return (
    <>
{/*      <ItemsList items={currentItems} /> */}

      <ItemsList
        likes={false}
        createItem={false}
        items={currentItems}
        flavour={flavour}
        spice={spice}
        filterType={filterType}
        showSkeleton={false}
      />


{/*{props.children}*/}


<Box py={2} display='flex' justifyContent='center'>
<Pagination page={page} count={pageCount} color="primary" onChange={handlePageClick} />
</Box>



      {/* <ReactPaginate
        breakLabel="..."
        nextLabel="next >"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel="< previous"
        renderOnZeroPageCount={null}
      /> */}
    </>
  );
}

export default PaginatedItems;




// Add a <div id="container"> to your HTML to see the componend rendered.
//ReactDOM.render(
//  <PaginatedItems itemsPerPage={4} />,
//  document.getElementById('container')
//);
