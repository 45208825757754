import React from "react";
import LinkMui from "@material-ui/core/Link";
import { Link } from "./../util/router.js";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Box } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: "0.9rem",
    textAlign: "center",
    marginTop: theme.spacing(3),
    "& > a": {
      margin: `0 ${theme.spacing(1)}px`,
    },
  },
}));

function AuthFooter(props) {
  const classes = useStyles();

  const afterAuthPath = props.afterAuthPath ? "?next=" + props.afterAuthPath : "";

  if (props.hideFooter === true) {return null;}


  

  return (
    <div className={classes.root}>
      {props.type === "signup" && (
        <>
           <Typography variant="body1">
          Have an account already? &nbsp;
          <LinkMui component={Link} to={"/auth/signin" + afterAuthPath}>
            {props.typeValues.linkTextSignin}
          </LinkMui>
          </Typography>
<Box py={2}>
<Typography color="textSecondary" variant="body2">By joining LadyBidwell, you agree to the    <LinkMui component={Link} to={"/legal/terms-of-service"} > Terms of Service, </LinkMui> <LinkMui component={Link} to={"/legal/privacy-policy"}>  Privacy Policy, </LinkMui> and to receive promotional emails.</Typography>
</Box>
        </>
      )}

      {props.type === "signin" && (
        <>
          <LinkMui component={Link} to={"/auth/signup" + afterAuthPath}>
            {props.typeValues.linkTextSignup}
          </LinkMui>

          <LinkMui component={Link} to={"/auth/forgotpass"+ afterAuthPath}>
            {props.typeValues.linkTextForgotpass}
          </LinkMui>
        </>
      )}
    </div>
  );
}

export default AuthFooter;
