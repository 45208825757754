export const asyncFilter = async (arr, predicate) =>
  Promise.all(arr.map(predicate)).then((results) =>
    arr.filter((_v, index) => results[index])
  );


export const pickHighest = (arr) => {
  var maxIndex = 0;
  arr.forEach((el) => {
    const { index } = el;
    if (index > maxIndex) {
      maxIndex = index;
    }
  });
  return maxIndex;
};

export const findId = (cards, id) => {
  //console.log("id", id);
  const card = cards.filter((c) => `${c.id}` === id)[0];
  return {
    card,
    index: cards.indexOf(card),
  };
};

export const findItem = (cards, id) => {
  //console.log("findItem", cards);
  //console.log("id", id);
  const item = cards.filter((c) => `${c.item}` === id)[0];
  return {
    item,
    index: cards.indexOf(item),
  };
};

export const sortByISOCreatedAt = (arr=[],  ascending = "asc") => {

  const m = arr.sort((a,b) => {

    //    const m = messages.slice().sort((a,b) => {
    
    //return compareFirebaseTimes(b.createdAt, a.createdAt));
    //return true;
    
    // < Newest to Oldest
    //return Date(b.createdAt) > Date(a.createdAt);
    // ISO so do lexographical sort
    if (ascending === "asc") {
      return (a.createdAt < b.createdAt) ? -1 : ((a.createdAt > b.createdAt) ? 1 : 0);

    
    }
    return (a.createdAt > b.createdAt) ? -1 : ((a.createdAt < b.createdAt) ? 1 : 0);
    });

    return m;

}

// Some timestrings are text, some are firebase objects.
// Some input is text, some is numbers.

export const sortBy = (
  arr,
  keyName = "createdAt",
  ascending = "asc",
  parseTime = false
) => {
  const orderAscending = ascending === "asc";
  //console.log("sortBy order ", orderAscending);
  const sortedArr = arr.slice().sort(function (a, b) {


    

    // Put items with no readable sort key at bottom of sort.

    if (a[keyName] === undefined) {
      return true;
    }
    if (b[keyName] === undefined) {
      return false;
    }

    var aConditioned = a;
    var bConditioned = b;
    if (parseTime) {
      aConditioned = parseInt(Date.parse(a[keyName]));
      bConditioned = parseInt(Date.parse(b[keyName]));
      //console.log("sortBy sw parseTime")

      if (orderAscending) {
        //console.log("sortBy a<b")
        return aConditioned - bConditioned;
      }
      // else {
        return bConditioned - aConditioned;

    }


    // Not time parsing. So probably not numbers?
        //console.log("sortBy", aConditioned, bConditioned);
    if (orderAscending) {
      //console.log("sortBy a<b")
      return aConditioned < bConditioned;
    }
    // else {
      return aConditioned > bConditioned;
   // }
  });
  //console.log("sortBy sortedArr", sortedArr);
  return sortedArr;
};

export const groupBy = (arr = [], keyName = "id") => {
  let result = [];
  result = arr.reduce((r, a) => {
    r[a[keyName]] = r[a[keyName]] || [];
    r[a[keyName]].push(a);
    return r;
  }, Object.create(null));
  return result;
};


export const filterBy = (
  arr,
  keyName = "createdAt",

) => {
  console.log("TERS", arr)
  const filteredArr = arr.filter(function (a) {
    console.log('array.js filterBy a', a)

    if (a[keyName] === true) {
      return true;
    }
    return false;


  });
  //console.log("sortBy sortedArr", sortedArr);
  return filteredArr;
};



