import { useState, useEffect } from "react";
import { useAuth } from "./../util/auth.js";

function DebugReport({object}) {

  const auth = useAuth();

  //const isDeveloper = auth?.user?.developer?.enabled;
//  const {developer:isDeveloper, debug:isDebug} = auth?.user?.developer;
const [isDebug, setIsDebug] = useState();
const [closed, setClosed] = useState();


useEffect(() =>{
if (auth.user == null) {return;}
if (auth.user.developer == null) {return;}
//console.log("DebugReport developer", auth.user.developer);

if (typeof auth.user.developer.debug !== 'undefined') {
setIsDebug(auth.user.developer.debug);
}


}, [auth && auth.user && auth.user.developer]);

useEffect(() =>{

console.log("DebugReport isDebug", isDebug);
}, [isDebug]);



useEffect(() =>{

console.log("DebugReport object", object);

}, [object]);

// auth.user.developer.debug
//if (debugFlag == null) {return null;}
//if (!isDeveloper) {return null;}


//return null;
//if (isDebug !== true) {return null;}

return (<>
DEBUG REPORT<br />
{JSON.stringify(object)}
/*
{object && (<>DESCRIPTION {JSON.stringify(object.description)}<br /></>)}
*/
/*
{object && (<>NAME {object.name}<br /></>)}   
{object && (<>OWNER {object.owner}<br /></>)}   
{object && (<>ACCEPTED {object.accepted}<br /></>)}   
{object && (<>AUCTION {object.auction}<br /></>)}  
{object && (<>EVENT {object.event}<br /></>)}    
{object && (<>KNOCKEDDOWNAT {object.knockedDownAt}<br /></>)}   


{object && (<>ID {object.id}<br /></>)}   

{object && object.lot && (<>LOT<br /></>)}   
{object && object.lot && (<>LOT LOTREF {object.lot.lotRef}<br /></>)}   

{object && object.item && (<>ITEM<br /></>)}   
{object && object.item && (<>ITEM NAME {object.item.name}<br/></>) }
*/

{object && (<><br/></>) }
</>);

}

export default DebugReport;


