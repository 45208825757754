import { useState, useEffect } from "react";

import Box from "@material-ui/core/Box";

import Typography from "@material-ui/core/Typography";

import { useAuth } from "./../util/auth.js";
import { makeStyles } from "@material-ui/core/styles";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import WatchLaterIcon from "@material-ui/icons/WatchLater";
import PauseCircleFilledRoundedIcon from '@material-ui/icons/PauseCircleFilledRounded';
import PublishIcon from "@material-ui/icons/Publish";
import ErrorOutlinedIcon from "@material-ui/icons/ErrorOutlined";
import { useItemLotStatus, useLotStatus, useProfile } from "./../util/db.js";
import { useIdentity } from "../util/identity.js";



const useStyles = makeStyles((theme) => ({
  paperItems: {
    minHeight: "300px",
  },

  featured: {
    backgroundColor:
      theme.palette.type === "dark" ? theme.palette.action.selected : "#fdf8c2",
  },
  starFeatured: {
    color: theme.palette.warning.main,
  },
  accepted: {
    backgroundColor:
      theme.palette.type === "dark" ? theme.palette.action.selected : "#fdf8c2",
  },
  tickAccepted: {
    color: theme.palette.success.main,
  },
  tickHold: {
    color: theme.palette.warning.main,
  },
  deleteDisabled: {
    color: theme.palette.action.disabledBackground,
  },
  editDisabled: {
    color: theme.palette.action.disabledBackground,
  },
  notAccepted: {
    color: theme.palette.error.dark,
  },
  hourWaiting: {
    color: theme.palette.warning.dark,
  },
  notSubmitted: {
    color: theme.palette.warning.dark,
  },

  ListItemSecondaryAction: {
    minWidth: "120px",
    textAlign: "left",
  },
}));

function ItemStatus(props) {
  const classes = useStyles();

  const { item, lot, mode } = props;

  const auth = useAuth();

  const [choice, setChoice] = useState();
  const [creatingItem, setCreatingItem] = useState(false);
  const { isAuctioneer, isSeller } = useIdentity();
  const [updatingItemId, setUpdatingItemId] = useState(null);
  const [pending, setPending] = useState(false);
  //  const [isLotStarted, setIsLotStarted] = useState();
  //  const [isLotEnded, setIsLotEnded] = useState();
  //const itemsAreEmpty = !items || items.length === 0;

  // const {
  //   data: lot,
  //   status: lotStatus,
  //   error: lotError,
  // } = useLotByItem(item.id);

  const canUseStar =
    auth.user.planIsActive &&
    (auth.user.planId === "pro" || auth.user.planId === "business");
  const canUseAccept = auth.user.role && auth.user.role === "auctioneer";

  const isAdministrator = auth.user.role && auth.user.role === "auctioneer";
  //const visibleAuctions = useEventsByOwner("curator", "future");

  const isAccepted = item && item.accepted === undefined ? null : item.accepted;
  const isOwner = auth.user.id && auth.user.id === item.owner;
  /*
  const isKnockedDown =
  lot && lot.knockedDownAt && lot.knockedDownAt !== false ? true : false;

  const isPaid = lot && lot.paid && lot.paid === true ? true : false;
*/
  const {
    isKnockedDown,
    isPaid,
    isCancelled,
    isLotEnded,
    isLotStarted,
    isDelisted,
  } = useLotStatus({ ...lot, ...item });



  useEffect(() => {
    console.log("ItemStatus isKnockedDown item lot", isKnockedDown, item, lot);
  }, [isKnockedDown, item, lot]);

const { 
  data: profileData,
  status: profileStatus,
  error: profileError
} = useProfile(item.owner);


// const isAvailable = !(profileData && profileData.charges_enabled == null) ||
// (profileData && profileData.charges_enabled && profileData.charges_enabled === true);






// This code is mirrored in ItemIssue Message
// Refactor:???

const isAvailable = 
profileData?.charges_enabled && profileData.charges_enabled === true;





// This code is mirrored in ItemIssue Message
// Refactor:???


const {isSaleMissed, isSaleAvailable} = useItemLotStatus(item, lot);




useEffect(() =>{

  console.log("ItemsStatus profileData", profileData, profileData && profileData.charges_enabled)

}, [profileData])

useEffect(()=>{

  console.log("ItemsStatus isAvailable", isAvailable, isAvailable ? "TRUE":"FALSE")

}, [isAvailable])

  // *************************************
  // USE MessagesItem.js as messaging base.
  // ***********************************

  //if (mode == 'view') {return (<></>)}

//useEffect(() =>{

// Start here. See what visible auctions looks like.
// Create a state variable which is something like
// isSaleAvailable
// which will display TWO WORDS in the render.

// Re-factor EditItemModal to re-use the visibleAcuctions code to 
// figure out if start of item.auction is past.

// Item

//March 31, 2023

//console.log("ItemStatus visibleauctions", visibleAuctions)

//}, [visibleAuctions])


  if (profileStatus !== "success") {
    return null;
  }

  if (isDelisted) {
    return (
      <>
        <Box flexGrow={1} pr={2}>
          <Typography
            variant="subtitle1"
            color="textSecondary"
            style={{ textAlign: "right" }}
          >
            Unlisted
          </Typography>
        </Box>
        {/* What should this say? */}
        {(isOwner || isAdministrator) && (
          <ErrorOutlinedIcon className={classes.notAccepted} />
        )}
      </>
    );
  }

  if (isCancelled) {
    return (
      <>
        <Box flexGrow={1} pr={2}>
          <Typography
            variant="subtitle1"
            color="textSecondary"
            style={{ textAlign: "right" }}
          >
            Purchase Cancelled
          </Typography>
        </Box>

        {(isOwner || isAdministrator) && (
          <ErrorOutlinedIcon className={classes.notAccepted} />
        )}
      </>
    );
  }

  if (
    !isKnockedDown &&
    lot &&
    !lot.reserveMet &&
    item.listingType === "marketplace_price" &&
    item?.soldStatus !== "sold" &&
    item.holdStatus === "on_hold"
  ) {
    return (
      <>
        <Box flexGrow={1} pr={2}>
          <Typography
            variant="subtitle1"
            color="textSecondary"
            style={{ textAlign: "right" }}
          >
            On Hold
          </Typography>
        </Box>

        {(isOwner || isAdministrator) && (
          <PauseCircleFilledRoundedIcon className={classes.tickHold} />
        )}
      </>
    );
  }

  if (isKnockedDown && lot && lot.reserveMet || (item?.listingType === "marketplace_price" && item?.soldStatus === "sold")) {
    return (
      <>
        <Box flexGrow={1} pr={2}>
          <Typography
            variant="subtitle1"
            color="textSecondary"
            style={{ textAlign: "right" }}
          >
            Sold
          </Typography>
        </Box>

        {(isOwner || isAdministrator) && (
          <CheckCircleIcon className={classes.tickAccepted} />
        )}
      </>
    );
  }

  if (isPaid) {
    return (
      <>
        <Box flexGrow={1} pr={2}>
          <Typography
            variant="subtitle1"
            color="textSecondary"
            style={{ textAlign: "right" }}
          >
            Sold
          </Typography>
        </Box>

        {(isOwner || isAdministrator) && (
          <CheckCircleIcon className={classes.tickAccepted} />
        )}
      </>
    );
  }

  if (isKnockedDown) {
    return (
      <>
        <Box flexGrow={1} pr={2}>
          <Typography
            variant="subtitle1"
            color="textSecondary"
            style={{ textAlign: "right" }}
          >
            Ended
          </Typography>
        </Box>

        {(isOwner || isAdministrator) && (
          <CheckCircleIcon className={classes.tickAccepted} />
        )}
      </>
    );
  }

  //if ((profileData && ((profileData.charges_enabled == null) ||
  //  (profileData.charges_enabled && profileData.charges_enabled === false))) 
 //  && !(isOwner || isAdministrator)
 //   ) {

if ((!isAvailable) && !(isOwner || isAdministrator)) {
    return (
      <>
        <Box flexGrow={1} pr={2}>
          <Typography
            variant="subtitle1"
            color="textSecondary"
            style={{ textAlign: "right" }}
          >
            {/*
            {(isOwner) && (<>Submitted</>)}
            {(isAdministrator) && <>Review seller account</>}
            {!(isOwner || isAdministrator) && <>Currently Unavailable</>}
    */}
    Currently Unavailable
            </Typography>
        </Box>

        {(isOwner || isAdministrator) && (
          <ErrorOutlinedIcon className={classes.notAccepted} />
        )}
      </>
    );
  }

  if (lot && item && item.accepted === true && !isLotStarted) {
    return (
      <>
        <Box flexGrow={1} pr={2}>
          <Typography
            variant="subtitle1"
            color="textSecondary"
            style={{ textAlign: "right" }}
          >
            Accepted
          </Typography>
        </Box>

        {(isOwner || isAdministrator) && (
          <CheckCircleIcon className={classes.tickAccepted} />
        )}
      </>
    );
  }



  if (lot && item && item.accepted === true && item.listingType === "marketplace_price") {
    return (
      <>
        <Box flexGrow={1} pr={2}>
          <Typography
            variant="subtitle1"
            color="textSecondary"
            style={{ textAlign: "right" }}
          >
            Active
          </Typography>
        </Box>

        {(isOwner || isAdministrator) && (
          <CheckCircleIcon className={classes.tickAccepted} />
        )}
      </>
    );
  }


  if (lot && item && item.accepted === true) {
    return (
      <>
        <Box flexGrow={1} pr={2}>
          <Typography
            variant="subtitle1"
            color="textSecondary"
            style={{ textAlign: "right" }}
          >
            {mode !== "view" && !isOwner && <>Active</>}
            {mode !== "view" && isOwner && <>Accepted</>}
          </Typography>
        </Box>

        {(isOwner || isAdministrator) && (
          <CheckCircleIcon className={classes.tickAccepted} />
        )}
      </>
    );
  }

  if (item.accepted === undefined) {
    // return <Typography variant="h6">Not Yet Submitted</Typography>
    return (
      <>
        <Box flexGrow={1} pr={2}>
          <Typography
            variant="subtitle1"
            color="textSecondary"
            className={classes.Accepted}
            style={{ textAlign: "right" }}
          >
            Not Submitted
          </Typography>
        </Box>

        <PublishIcon className={classes.notSubmitted} />
        {/* <NotesIcon className={classes.notSubmitted} /> */}
      </>
    );
  }

// Test of whether the start date for the auction id in the item has past or not.


  if (isSaleMissed ) {

  return <> <Box flexGrow={1} pr={2}>
  <Typography
    variant="subtitle1"
    color="textSecondary"
    style={{ textAlign: "right" }}
  >
   Missed Sale
  </Typography>
</Box>

<ErrorOutlinedIcon className={classes.notAccepted} />
</>

 



}



  if (item.accepted === null && item.consigned === true) {
    return (
      <>
        <Box flexGrow={1} pr={2}>
          <Typography
            variant="subtitle1"
            color="textSecondary"
            style={{ textAlign: "right" }}
          >
            In Review
          </Typography>
        </Box>

        <WatchLaterIcon className={classes.hourWaiting} />
      </>
    );
  }

  if (item.accepted === true) {
    return (
      <>
        <Box flexGrow={1} pr={2}>
          <Typography
            variant="subtitle1"
            color="textSecondary"
            style={{ textAlign: "right" }}
          >
            Accepted
          </Typography>
        </Box>

        <CheckCircleIcon className={classes.tickAccepted} />
      </>
    );
  }

  if (item.accepted === false) {
    return (
      <>
        <Box flexGrow={1} pr={2}>
          <Typography
            variant="subtitle1"
            color="textSecondary"
            style={{ textAlign: "right" }}
          >
            {" "}
            Not Accepted
          </Typography>
        </Box>

        <CancelIcon className={classes.notAccepted} />
      </>
    );
  }

  if (item.accepted === null && item.wasConsigned === true) {
    return (
      <>
        <Box flexGrow={1} pr={2}>
          <Typography
            variant="subtitle1"
            color="textSecondary"
            style={{ textAlign: "right" }}
          >
            {" "}
            Withdrawn
          </Typography>
        </Box>

        <CancelIcon className={classes.withdrawn} />
      </>
    );
  }

  return (
    <>
      <CancelIcon className={classes.notAccepted} />
      Wait for it - neeeds hourglass
    </>
  );
}

export default ItemStatus;
