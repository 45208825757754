import React from "react";
import { useAuth } from "./../util/auth.js";
import {
  useItemsByAuction,
} from "./../util/db.js";

import { requireAuth } from "./../util/auth.js";

import EventItemList from "./../components/EventItemList";


function CuratorEventItemList(props) {


  const { event } = props;

  const auth = useAuth();
  //const [items, setItems] = useState();
  const {
    data: items,
    status: itemsStatus,
    error: itemsError,
  } = useItemsByAuction(props.event.id);

  return (
    <>
              {itemsError && (
       {/*     <Box mb={3}>
              <Alert severity="error">{itemsError.message}</Alert>
              </Box> */}
          )}
       
          {true && <EventItemList items={items} event={event} />}
    </>
  );
}

export default requireAuth(CuratorEventItemList);
