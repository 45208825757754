/////THIS IS THE ORIGINAL IMAGEUPLOADER USING MATERIALUI DROPZONE. WORKS BUT CHANGED TO USING DROPZONE DUE TO ERROR MESSAGING ISSUES NOT BEING ABLE TO CHANGE

import React, { useEffect, useState } from "react";
import { DropzoneAreaBase } from "material-ui-dropzone";
import {
  Box,
  Grid,
  Typography,
  CircularProgress,
} from "@material-ui/core";
// import firebase, { storage } from "./../util/firebase";
// import {
//   serverTimestamp,
// } from "firebase/firestore";
import { v4 as uuidv4 } from "uuid";
import CameraAltOutlinedIcon from "@material-ui/icons/CameraAltOutlined";
import { getStorage, ref, uploadBytes } from "firebase/storage";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  dropZone: {
    height: "200px",
    minHeight: "200px",
    border: "1px solid #7d7d7d",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "10px",
    position: "relative",
  },
}));

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};

function ImageUploader(props) {
  const classes = useStyles();
  const [uuids, setUuids] = useState([]);
  const [files, setFiles] = useState([]);
  const [status, setStatus] = useState("idle");

  const imageBucket = props.id === undefined ? uuidv4() : props.id;

  const [urls, setUrls] = useState([]);


  useEffect(() => {
    let isMounted = true;
  
    async function spoolUpGenerateThumbnailer() {
      const storage = getStorage();
      const storageRef = ref(storage, "spoolup_file");
  
      const uploadPromise = uploadBytes(storageRef, "null");
  
      if (isMounted) {
        try {
          await uploadPromise;
          console.log("ImageUploader Uploaded a dummy file!");
        } catch (error) {
          console.error(error);
        }
      }
    }
  
    spoolUpGenerateThumbnailer();
  
    return () => {
      isMounted = false;
    };
  }, []);


  useEffect(() => {
    console.log("ImageUploader spoolUpGenerateThumbnailer");
    spoolUpGenerateThumbnailer();
  }, []);

  function spoolUpGenerateThumbnailer() {
    //var filename = uuidv4();

    const storage = getStorage();
    const storageRef = ref(
      storage,
      // "images/items/" + imageBucket + "/" + filename
      "spoolup_file"
    );

    return uploadBytes(storageRef, "null").then((snapshot) => {
      console.log("ImageUploader Uploaded a dummy file!");
    });
  }

  const thumbnail = (file) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img src={URL.createObjectURL(file)} style={img} />
      </div>
    </div>
  );

  //const SortableItem = sortableElement
  const thumbs = files.map((file) => <>{thumbnail(file)}</>);


  function deleteImages(deletedFile) {
    console.log("deleteImages");
    console.log(deletedFile);
  }



  function handleAdd(droppedFiles) {
    setStatus("loading");
    Promise.all(
      droppedFiles.map(async (item, index) => {
        const t = await uploadImage(item);
      })
    ).then(() => {
      setStatus("idle");
    });
  }

  useEffect(() => {
    if (props.onStatus) {
      console.log("ImageUploader status", status);

      props.onStatus(status);
    }
  }, [status]);

  function uploadImage(file) {
    var filename = uuidv4();

    const storage = getStorage();
    const storageRef = ref(
      storage,
      "images/items/" + imageBucket + "/" + filename
    );

    return uploadBytes(storageRef, file.file).then((snapshot) => {
      console.log("Uploaded a file!");

      var image = {
        location:
          "FirebaseStorage://" + "images/items/" + imageBucket + "/" + filename,
        filename: filename,
        file: file,
        bucket: imageBucket,
      };

      // Call parent component with details of the image just uploaded
      props.onImage(image);
      //setStatus('idle')
      //console.log("ImageUploader returning now");
      //Promise.resolve("success");
    });
  }

  function deleteAll() {
    setFiles([]);
  }

  return (
    <>
      <Grid container spacing={5} direction="row">
        <Grid item xs={6}>
          <div className={classes.dropZone}>
            {status === "loading" && (
              <div
              style={{
                background: "rgba(255, 255, 255, 0.9)",
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                zIndex: 99,
              }}
              >
                <CircularProgress /> 
              </div>
            )}


            <DropzoneAreaBase
              dropzoneClass={classes.dropZone}
              onAdd={handleAdd}
              onDelete={deleteImages}
              maxFileSize={12000000}
              files={files}
              filesLimit={12}
              Icon={CameraAltOutlinedIcon}
              dropzoneText={"Add Images (up to 12)"}
              acceptedFiles={["image/*"]}
            />
          </div>
        </Grid>

        <Grid item xs={6}>
          <Box>
            <Typography variant="body2">
              Include pictures of any wear and tear or damage. Drag images to
              re-arrange order. The 1st image will be the featured image.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default ImageUploader;
