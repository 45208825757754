import React, { useState, useEffect } from "react";
import List from "@material-ui/core/List";
import { makeStyles } from "@material-ui/core/styles";
import ItemStrip from "./../components/ItemStrip";


const useStyles = makeStyles((theme) => ({
  paperItems: {
    minHeight: "300px",
  },
  featured: {
    backgroundColor:
      theme.palette.type === "dark" ? theme.palette.action.selected : "#fdf8c2",
  },
  starFeatured: {
    color: theme.palette.warning.main,
  },
  collections: {
    display: "inline-block",
  },
}));

function ItemsGroupList(props) {
  const classes = useStyles();
  const { items } = props;
  const { likes } = false;
  const [itemIds, setItemIds] = useState();
  const [itemIdsStatus, setItemIdsStatus] = useState("loading");

  useEffect(() => {
    const i = items.map((f) => f.id);

    setItemIds(i);
    setItemIdsStatus("success");
  }, [items]);

  if (itemIdsStatus !== "success") {
    return null;
  }

  return (
    <>
      <List disablePadding={true}>
        {itemIds.map((itemId, index) => (
          <ItemStrip key={itemId} likes={likes} itemId={itemId} item={null} />
        ))}
      </List>
    </>
  );
}

export default ItemsGroupList;
