import React, { useEffect } from "react";


import { sortItemsByScore } from "./../util/sort.js";

function SortedItems(props) {

const {sortType, items} = props;


  const updateWithprops = React.Children.map(props.children, (child, i) => {

const sortedItems = sortItemsByScore(props.items);

    return React.cloneElement(child, { color: "red", items: sortedItems });
  });



useEffect(()=>{

console.log("FilteredItems props.items", props.items);

}, [props.items]);



  return <div>{updateWithprops}</div>;
}

export default SortedItems;
