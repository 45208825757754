import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  Typography,
  Box,
  Grid,
  useTheme,
  useMediaQuery,
  Chip,
} from "@material-ui/core";
import { useEvent, useProfile } from "../util/db";
import ProfileInformation from "./../components/ProfileInformation";
import InfoDrawer from "./../components/InfoDrawer";
import Section from "./Section.js";
import { useAuth } from "../util/auth";
import LocationOnIcon from "@material-ui/icons/LocationOn";

import { privateCatchallFlag } from "./../util/dev.js";

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: theme.shape.borderRadius,

    color: theme.palette.text.secondary,
    "& svg": {
      marginLeft: theme.spacing(1),
    },
  },

  // section: {
  //   display: "block",
  //   position: "sticky",
  //   top: "50px",
  //   zIndex: "90",
  // },

  noPaddingLeft: {
    paddingLeft: 0,
  },

  container: {
    background: "#f7f7f7",
  },

  chip: {
    color: "#ffffff",
  },

  image: {
    backgroundSize: "cover",
  },
  // section: {
  //   paddingTop:'22px',
  // }
}));

export default function SellerWeeklyEventDetails(props) {
  const { event } = props;
  const classes = useStyles();
  //const { data: event, status, error } = useEvent(eventId);
  const theme = useTheme();
  const auth = useAuth();
  const isSmallOrLess = useMediaQuery(theme.breakpoints.up("md"));
  const [isOpen, setIsOpen] = useState(false);
  const removeMd = require("remove-markdown");
  const handleOpen = () => {
    //setIsOpen(!isOpen);
    setIsOpen(true);
  };
  const hashCode = (input) => {
    var base64Input = btoa(input);
    console.log("hashCode base64Input", base64Input);

    var integerInput = getIntFromBase64String(base64Input);
    console.log("hashCode integerInput", integerInput);
    return integerInput;
  };

  const {
    data: profileData,
    status: profileStatus,
    error: profileError,
  } = useProfile(event && event.owner);

  useEffect(() => {
    console.log(
      "SellerWeeklyEventDetails profileData profileStatus profileError",
      profileData,
      profileStatus,
      profileError
    );
  }, [profileData, profileStatus, profileError]);

  const [parentEventId, setParentEventId] = useState();
  const {
    data: parentEventData,
    status: parentEventStatus,
    error,
  } = useEvent(parentEventId);

  useEffect(() => {
    console.log(
      "SellerWeeklyEventDetails event profileData",
      event,
      profileData
    );

    if (event == null) {
      console.log("SellerWeeklyEventDetails event is null");
      return;
    }
    if (profileData == null) {
      console.log("SellerWeeklyEventDetails profileData is null");
      return;
    }

    console.log(
      "SellerWeeklyEventsDetails event.id event.private event",
      event.id,
      event.private,
      event
    );

    if (typeof event.private !== undefined) {
      if (privateCatchallFlag === true && event.private) {
        setParentEventId(profileData.event);
      }
    }

    //setDisplayEvent(event);
  }, [event, profileData]);

  useEffect(() => {
    console.log("SellerWeeklyEventDetails parentEventId", parentEventId);
  }, [parentEventId]);

  const [eventName, setEventName] = useState();

  useEffect(() => {
    if (event == null) {
      return;
    }

    if (event.private == null) {
      setEventName(event.name);
      return;
    }

    // if (parentEventData == null) {

    // return;
    // }

    if (privateCatchallFlag === true && event.private === true) {
      if (parentEventData == null) {
        return;
      }
      setEventName(parentEventData.name);
      return;
    }
// If the .env flag for catchall is off
// Ignore the private event flag
    if (privateCatchallFlag === false && event.private === true) {

      setEventName(event.name);
      return;
    }

console.log("SellerWeeklyEventDetails xkcd event", event)
    if (event.private === false) {
      setEventName(event.name);
    }



  }, [parentEventData, event]);

  function getIntFromBase64String(input) {
    //Console.WriteLine(output.Length);

    const arrayInput = input.split("");
    var rslt = 0;
    for (let i = 0; i < arrayInput.Length; i++) {
      rslt <<= 8;
      rslt += arrayInput[i];
    }
    return rslt;
  }

  return (
    <>


      {event && event.slug && event.slug !== "" && (
        <>
          <div style={{ position: "relative", top: "-20px" }} id={event.slug} />
        </>
      )}
     
      <Section
        bgColor="light"
        size="auto"
        bgImage={props.bgImage}
        bgImageOpacity={props.bgImageOpacity}
        className={classes.section}
      >
        <Container maxWidth="lg" className={classes.container}>
          <Grid container>
            <Grid item xs={12} sm={12} md={10}>
              <Box className={classes.centerBox} py={1}>
                <Typography
                  variant="h5"
                  gutterBottom={true}
                  style={{
                    textTransform: "capitalize",
                    fontFamily: '"Cormorant Garamond", "serif"',
                  }}
                >
                  <span>{eventName && eventName}</span>
                  {/*      <span>{event && event.name}</span> */}
                </Typography>

                <Typography
                  variant="subtitle1"
                  // gutterBottom={true}
                  color="textSecondary"
                  //  style={{ fontWeight: "300",}}
                >
                  Seller: {"  "}{" "}
                  <ProfileInformation spice={["drawer"]} profile={{ id: event?.owner }} />
                </Typography>

          
                  {profileData && event.id === profileData.event && (
                          <Box pb={0.5}>
                    <Chip
                      icon={<LocationOnIcon />}
                      label="Multiple Pickup Locations"
                      style={{ backgroundColor: "#abf7ed" }}
                    />
                      </Box>
                  )}
              
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "center",
                  }}
                >
                  <Typography
                  color="textSecondary"
                    variant="subtitle1"
                    style={{ paddingRight: "8px" }}
                  >
                    {event?.location} |
                  </Typography>

                  <Typography
                  color="textSecondary"
                    variant="subtitle1"
                    style={{ paddingRight: "8px" }}
                  >
                    {event?.deliveryoptions === "pickup_only" && (
                      <>Pickup Only |</>
                    )}
                    {event?.deliveryoptions === "pickupand_shipping" && (
                      <>Pickup or Shipping | </>
                    )}
                    {event?.deliveryoptions === "shipping_only" && (
                      <>Shipping Only |</>
                    )}
                  </Typography>

                  <InfoDrawer event={event} flavour="bottom" />
                </div>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Section>
      {/* <Box mb={1}  style={{background:'#ffffff'}} >
<div className="divider div-transparent div-arrow-down" />
</Box> */}
    </>
  );
}
