
export function sortItemsByScore(items) {
  //if (items === undefined) {return [];}
  //if (items.length === 0) {return [];}
  const sortedItems = items.sort(function (a, b) {
    return b.score - a.score;
  });

  return sortedItems;
}


//cutoffItemsByScore(props.items, minimumScore)

export function cutoffItemsByScore(items, minimumScore, minimumItems, maximumItems) {
  //if (items === undefined) {return [];}
  //if (items.length === 0) {return [];}
  const cutoffItems = items.filter((item)=>{
if (item.score >= minimumScore) {return true;}
    return false;
  })

var cutoffItems2 = cutoffItems;
if (cutoffItems.length < 4) {

cutoffItems2 = cutoffItems.slice(0,4);

}

const cutoffItems3 = cutoffItems2.slice(0,maximumItems);



  return cutoffItems3;
}
