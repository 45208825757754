import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { Typography, Box, Container } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import LaunchIcon from "@material-ui/icons/Launch";
import MessageCards from "./MessageCards";
import { sortByISOCreatedAt } from "../util/array";

const useStyles = makeStyles((theme) => ({
  // list: {
  //   width: '380px',
  //   [theme.breakpoints.down("xs")]: {
  //     width: '100vw'
  //   },
  // },
  fullList: {
    width: "auto",
  },
  fontFamily: {
    fontFamily: '"Cormorant Garamond", "serif"',
    // fontWeight: 500,
  },

  toolbar: {
    justifyContent: "space-between",
  },
  title: {
    flexGrow: 1,
    textAlign: "center",
  },
  paper: {
    width: "380px",
    borderRadius: "15px 0px 0px 15px",
    [theme.breakpoints.down("xs")]: {
      borderRadius: "0px",
      width: "100vw",
    },
  },
}));

export default function MessagesDrawer(props) {
  const classes = useStyles();
  const { messages, user } = props;

  //const auth = useAuth();
  //const userID = user === undefined ? auth.user.id : user.id;
  // const userID = auth.user.id;

  const [filteredMessages, setFilteredMessages] = useState();
  //const { status: messagesStatus, data: messages } = useMessagesByItemUser(
  //  item,
  //  userID
  //);
  const [state, setState] = React.useState({
    // top: false,
    // left: false,
    // bottom: false,
    right: false,
  });

  const removeMd = require("remove-markdown");
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  useEffect(() => {
    if (!messages) {
      console.log("MessagesDrawer messages bang");
      return;
    }

    console.log("MessagesDrawer messages count", messages.length);
    console.log("MessagesDrawer messages", messages);
    const m = messages.filter((message) => {
      if (
        message.from === "system" &&
        message.subject === "Price table updated."
      ) {
        return false;
      }
      return true;
    });
    var s = sortByISOCreatedAt(m, "asc");
    // var s = m.sort(function (

    console.log("MessagesDrawer filtered sorted messages", s);

    setFilteredMessages(s);
  }, [messages]);

  return (
    <div>
      {["right"].map((anchor) => (
        <React.Fragment key={anchor}>
          <div
            style={{cursor:'pointer', textAlign:'right'}}
            onClick={toggleDrawer(anchor, true)}
          >
               <Button aria-label="settings" endIcon={  <LaunchIcon />}>
            <Typography color="textSecondary" variant="subtitle2" style={{textTransform:'none'}}>
              View all
              </Typography>
              
              </Button>
          
          </div>

          <Drawer
            classes={{
              paper: classes.paper,
            }}
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            <Toolbar className={classes.toolbar}>
              <Typography className={classes.title}>Item Messages</Typography>
              <IconButton onClick={toggleDrawer(anchor, false)}>
                <CloseIcon />
              </IconButton>
            </Toolbar>
            <Box pb={1}>
              <Divider />
            </Box>

            {/* <Box justifyContent="center" display="flex">
              <img height="200px" src={bikesummary} />
            </Box> */}
            <Container>
              {filteredMessages && filteredMessages.length > 0 && (
                <MessageCards messages={filteredMessages} />
              )}
            </Container>
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
