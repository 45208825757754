import { useEffect, useState } from "react";
import { useAuctionsByFeatured, getLimitedItems } from "./../util/db";
import WeeklyPost from "./WeeklyPost";
import {
  Divider,
  MenuItem,
  Toolbar,
  useScrollTrigger,
  Chip,
  Hidden,
} from "@material-ui/core";
import HeroSection from "./HeroSection";
import { useAuth } from "./../util/auth.js";
import Search from "./Search";
import { debugFlag } from "./../util/dev.js";
import { forceCheck } from "react-lazyload";
import NewsletterSection from "./NewsletterSection";
import NoSearchResults from "./NoSearchResults";
import NewLadyTown from "./NewLadyTown";
import TypographyHeroSection from "./TypographyHeroSection";
import { Box, Typography, Container, makeStyles } from "@material-ui/core";
import { isPastTime, humanTime } from "../util/time";
import { NotListedLocation } from "@material-ui/icons";
import ItemsGridHorizontal from "./ItemsGridHorizontal";
import MonthlySaleNewsletter from "./MonthlySaleNewsletter";
import { Link } from "./../util/router.js";
import HeroSectionLearn from "./HeroSectionLearn";
import HeroSectionSaleTypography from "./HeroSectionSaleTypography";

const useStyles = makeStyles((theme) => ({
  link: {
    padding: theme.spacing(1, 2),
    cursor: "pointer",
    fontSize: 14,
    position: "relative",
    "&:after": {
      content: '""',
      position: "absolute",
      width: "100%",
      height: "2px",
      bottom: "0px", // Adjust this value to offset the underline
      left: "0",
      backgroundColor: "currentColor",
      transform: "scaleX(0)",
      transformOrigin: "right",
      transition: "transform 0.3s ease-out",
    },
    "&.active:after": {
      transform: "scaleX(1)",
      transformOrigin: "left",
    },
    "&.active": {
      textDecoration: "none",
    },
  },

  // firstLink: {
  //   paddingLeft: 0
  // },
}));

function AuctionsHome(props) {
  const classes = useStyles();
  const { location } = props;
  const {
    data: auctions,
    status: auctionsStatus,
    error,
  } = useAuctionsByFeatured();
  const [filteredAuctions, setFilteredAuctions] = useState([]);
  const [items, setItems] = useState([]);
  const [auctionImagesLoaded, setAuctionImagesLoaded] = useState(false);

  const auth = useAuth();
  const isDeveloper = auth?.user?.developer?.enabled;

  const [metaAuctions, setMetaAuctions] = useState({});

  const [countItems, setCountItems] = useState();

  const [searchQuery, setSearchQuery] = useState();
  const [showCurrent, setShowCurrent] = useState(true);
  const [upcomingAuctions, setUpcomingAuctions] = useState([]);
  const [pastAuctions, setPastAuctions] = useState([]);
  const [activeItem, setActiveItem] = useState("current sale");

  const CollectionStatus = (collection) => {
    return <>{collection.endedAt === undefined ? "NOT CLOSED" : "CLOSED"}</>;
  };

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 400,
  });

  useEffect(() => {
    const fetchItems = async () => {
      const allItems = await getLimitedItems(8);
      console.log("allItems", allItems); // Add this line for debugging
      setItems(allItems);
    };

    fetchItems();
  }, []);

  useEffect(() => {
    //if (auctionsStatus !== 'success') {return;}
    //const id = hash.replace("#", "");
    const id = location.hash.replace("#", "");
    //const id = location;
    console.log("AuctionsHome id", id);

    if (id === "") {
      return;
    }

    const element = document.getElementById(id);

    if (element) {
      console.log("AuctionsHome element found", element);
      element.scrollIntoView({ behavior: "smooth" });
    } else {
      console.log("AuctionsHome element not found", id);
    }
  }, [auctionsStatus]);

  useEffect(() => {
    if (!auctions) {
      return;
    }

    console.log("AuctionsHome test", auctions);

    const a = auctions.filter((auction) => {
      return auction.featured === true && auction.published === true;
    });
    setFilteredAuctions(a);
  }, [auctions]);

  const runningAuctions = filteredAuctions.filter(
    (collection) =>
      collection &&
      // isPastTime(collection.startTime) &&
      !isPastTime(collection.endTime)
  );

  console.log("runningAuctions length: ", runningAuctions.length);
  console.log("runningAuctions: ", runningAuctions);

  function handleRequestSearch(c) {
    console.log("AuctionsHome handleSearch c", c);
    setSearchQuery(c);
  }

  function handleChangeSearch(c) {
    console.log("AuctionsHome handleChange c", c);
    setSearchQuery(c);
  }

  function handleCardCount(c) {
    console.log("AuctionsHome handleCardCount c", c);

    const _metaAuctions = metaAuctions;

    _metaAuctions[c.id] = c.count;
    setMetaAuctions({ ..._metaAuctions });

    setCountItems(c.count);
    forceCheck();
  }

  useEffect(() => {
    console.log("AuctionsHome metaAuctions", metaAuctions);
  }, [metaAuctions]);

  if (auctionsStatus !== "success") {
    // return (<Box style={{height:'500px', width:'500px', border:'1px solid black'}} /> );

    return null;
  }

  return (
    <>
      {/* <div style={{ position: "fixed", top: 0, zIndex: 9, width: "100%" }}>
     
>
      </div> */}

      <div>
        {/* {filteredAuctions && filteredAuctions.length === 0 && (
          <>
            No Auctions Happening
            <NewsletterSection flavour="simple" />
          </>
        )} */}

        {debugFlag && <>ALL ITEMS MATCHING SEARCH COUNT {countItems}</>}

        <Container>
          <Box py={3} textAlign="left">
            <Typography variant="h4" gutterBottom>
              <div>Monthly Sale</div>
            </Typography>

            <Typography variant="subtitle1" gutterBottom>
              LadyBidwell's monthly sale is a collection of hand-selected curated items monthly
              from the finest estates, private homes and sellers in the
              Hamptons. Enjoy our innovative declining price sale, where prices
              drop daily. Be the first to click "buy" and secure your favorite
              pieces, from antique marvels to contemporary art.
            </Typography>
          </Box>
        </Container>

        <Container disableGutters>
          <Toolbar>
            <Box >
              <Chip
           
                label="Current Sale"
                clickable
                // variant="outlined"
                size="large"
                color={activeItem === "current sale" ? "primary" : "default"}
                onClick={() => {
                  setShowCurrent(true);
                  setActiveItem("current sale");
                }}
                className={classes.link}
              />
            </Box>

            <Box m={1}>
              <Chip
                label="Past Sales"
                clickable
                // variant="outlined"
                color={activeItem === "past sales" ? "primary" : "default"}
                onClick={() => {
                  setShowCurrent(false);
                  setActiveItem("past sales");
                }}
                className={classes.link}
              />
            </Box>
          </Toolbar>
        </Container>
        <Divider />

        <>
          {runningAuctions.length === 0 && showCurrent && (
            <>
              <MonthlySaleNewsletter />

              <Container>
                <Divider />

                <Box py={6}>
                  <Box pb={3}>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography variant="h5">
                        Curated Picks: Hampton's Marketplace
                      </Typography>

                      <Typography
                        style={{ textDecoration: "underline" }}
                        component={Link}
                        to={{
                          pathname: "/hamptons/marketplace",
                          state: { scrollToTop: true },
                        }}
                        variant="h6"
                      >
                        View All Items
                      </Typography>
                    </Box>
                    <Hidden xsDown>
                      <Typography variant="h5" color="textSecondary">
                        Shop LadyBidwell's selects in the Hampton's Local
                        Marketplace
                      </Typography>
                    </Hidden>
                  </Box>
                  <ItemsGridHorizontal
                    items={items}
                    flavour="marketplace_price"
                  />
                </Box>

                <Box py={6}>
                  <HeroSectionLearn />
                </Box>
              </Container>
            </>
          )}

          {filteredAuctions.map((collection, index) => {
            const isCollectionEnded =
              collection && isPastTime(collection.endTime);
            const isCollectionStarted =
              collection && isPastTime(collection.startTime);

            // Render logic for current/upcoming sales
            if (!isCollectionEnded && showCurrent) {
              return (
                <div key={collection.id}>
                  <HeroSection collection={collection} />
                  <Search
                    onRequestSearch={(s) => handleRequestSearch(s)}
                    onChangeSearch={(s) => handleChangeSearch(s)}
                    countItems={countItems}
                  />
                  {/* If the search has no matching items a prompt to search further */}
                  {searchQuery && countItems === 0 && <NoSearchResults />}
                  <WeeklyPost
                    key={"auctions_home_" + collection.id}
                    post={collection.slug}
                    searchQuery={searchQuery}
                    onCardCount={(c) => handleCardCount(c)}
                  />
                </div>
              );
            }
          })}
        </>

        {filteredAuctions.map((collection, index) => {
          const isCollectionEnded =
            collection && isPastTime(collection.endTime);
          const isCollectionStarted =
            collection && isPastTime(collection.startTime);

          // // Render logic for upcoming sales
          // if (!isCollectionEnded) {
          //   return (
          //     <div key={collection.id}>
          //       <HeroSection collection={collection} />
          //       <Search
          //         onRequestSearch={(s) => handleRequestSearch(s)}
          //         onChangeSearch={(s) => handleChangeSearch(s)}
          //         countItems={countItems}
          //       />
          //       {/* If the search has no matching items a prompt to search further */}
          //       {searchQuery && countItems === 0 && <NoSearchResults />}
          //       <WeeklyPost
          //         key={"auctions_home_" + collection.id}
          //         post={collection.slug}
          //         searchQuery={searchQuery}
          //         onCardCount={(c) => handleCardCount(c)}
          //       />
          //     </div>
          //   );
          // }

          // Render logic for past sales
          if (isCollectionEnded && !showCurrent) {
            return (
              <div key={collection.id}>
                {/* <HeroSection collection={collection} /> */}
                <HeroSectionSaleTypography collection={collection} />
                {/* <Search
                  onRequestSearch={(s) => handleRequestSearch(s)}
                  onChangeSearch={(s) => handleChangeSearch(s)}
                  countItems={countItems}
                /> */}
                {/* If the search has no matching items a prompt to search further */}
                {searchQuery && countItems === 0 && <NoSearchResults />}
                <WeeklyPost
                  key={"auctions_home_" + collection.id}
                  post={collection.slug}
                  searchQuery={searchQuery}
                  onCardCount={(c) => handleCardCount(c)}
                  flavour="past"
                />
              </div>
            );
          }
          // Return null for other cases
          return null;
        })}
      </div>
    </>
  );
}

export default AuctionsHome;
