import {
  useQuery,
  hashQueryKey,
  QueryClient,
  QueryClientProvider as QueryClientProviderBase,
} from "react-query";
import { useReducer, useEffect, useRef, useState } from "react";
// import { storage, database } from "./firebase";
import { v4 as uuidv4 } from "uuid";

import {
  firebaseTime,
  clientZuluTimestamp,
  isPastTime,
} from "./../util/time.js";

import {extractItem} from "./../util/item.js";
import {extractAffiliate} from "./../util/affiliate.js";


import { customAlphabet } from "nanoid/non-secure";

import {
  getStorage,
  ref,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";

import { useLocation } from 'react-router-dom';
// import { set } from "immer/dist/internal";
import { sortBy } from "./array.js";
import {
  getFirestore,
  onSnapshot,
  doc,
  collection,
  query,
  where,
  orderBy,
  limit,
  getDoc,
  getDocs,
  setDoc,
  updateDoc,
  addDoc,
  deleteDoc,
  serverTimestamp,
} from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import brokenImage from "./../images/brokenImage.png";

// import {
//  getFirestore, onSnapshot, serverTimestamp
//} from "./firebase";
import { firebaseApp } from "./firebase";

const affiliateFlag = true;

// Initialize Firestore
const db = getFirestore(firebaseApp);

// React Query client
const client = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const debugFlag = process.env.REACT_APP_DEBUG === "on" ? true : false;

const functions = getFunctions();

const deleteImagesById = httpsCallable(functions, "deleteImagesById");
const processImpression = httpsCallable(functions, "processImpression");
const countStoredImpressions = httpsCallable(functions, "countImpressions");

const updatePricesByItem = httpsCallable(functions, "processItemPrices");
const getItemPrices = httpsCallable(functions, "getItemPrices");

const getSellerAccountStatus = httpsCallable(
  functions,
  "getSellerAccountStatus"
);

export function useSellerAccountStatusByUser(userId) {
  const [response, setResponse] = useState({
    data: null,
    status: "loading",
    error: null,
  });
  useEffect(() => {
    return getSellerAccountStatus({ id: userId })
      .then((accountResult) => {
        setResponse({
          data: accountResult.data.data,
          status: "success",
          error: null,
        });
      })
      .catch((error) => {
        setResponse({ data: null, status: "loading", error: error });
      });
  }, [userId]);

  return response;
}

export function updateItemPrices(itemId) {
  return updatePricesByItem({ id: itemId })
    .then((result) => {})
    .catch((error) => {
      console.error("updateItemPrices error", error);
    });
}

export function createImpression(itemId) {
  //return null;
  //console.log(itemId);
  return processImpression({ id: itemId, interaction: { type: "impression" } })
    .then((result) => {
      if (debugFlag) {
        console.log("createImpression result", result, itemId);
      }
    })
    .catch((error) => {
      console.log("createImpression error", error, itemId);
    });
}

export function useAffiliation(itemId, url) {
const [response, setResponse]  = useState({data:null, status:'loading', error:null});
    //const r = router.pathname;
useEffect(() =>{

  if (url == null) {return;
  }  

  if (url.toLowerCase().includes("ladybcode")) {
  // Add link to LadyBidwell affiliates.
  const i = extractItem(url);

  if (i === false || i === true) {
setResponse({...response, status:'error',error:{message:"No Item Id found."}});
return;
  }

  const a = extractAffiliate(url);



if (a=== false || a ===true) {
setResponse({...response, status:'error',error:{message:"No Affiliate Id found."}});
return;

}

if (affiliateFlag) {
  console.log("affiliate extractAffiliate affiliateFlag result", a)
  localStorage.setItem("ladybcode",a)
}

  //const i = "IAWfJllSq8WBGc89pX6H";
console.log("database useAffiliation", i, url);
  createAffiliation(i, {affiliateId:a, url:url});
  setResponse({...response, status:'success'})
  return;
  }


},[url]);
return response;
}
export function createAffiliation(itemId, affiliation) {

  const affiliateId = affiliation.affiliateId; // extract the affiliate ID from the provided affiliation.
  //return null;
  console.log("createAffiliation", itemId, affiliation);
  return processImpression({ id: itemId, interaction: { type: "affiliate", affiliation:affiliation } })
    .then((result) => {
      if (debugFlag) {
        console.log("createAffiliation result", result, itemId);
      }
    })
    .catch((error) => {
      console.log("createAffiliation error", error, itemId);
    });
}

export const loadState = (key) => {
  try {
    const serializedState = localStorage.getItem(key);
console.log("db loadState serializedState",serializedState);

    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    console.error("db loadState err", err);

    return undefined;
  }
};

export const saveState = (key, state) => {
  try {
    const serializedState = JSON.stringify(state);
    const t = localStorage.setItem(key, serializedState);
console.log("db saveState t",t);
  } catch (err) {
    console.error("db saveState err", err);
    return undefined;
  }
};

export const starItem = (star) => {
  console.log("db starImte star", star);
  if (star.id === undefined || star.id === false) {
    //console.log("localStar request to createStar star", star)
    var t = createStar({ ...star });
    return t
      .then((result) => {
        console.log("localStar result", result);
        //console.log("localStar star id", result.id)
        return result;
      })
      .catch((error) => {
        console.log("localStar  error", error);
        return true;
      });
    // return;
  }
  //console.log("localStar request to updateStar")
  updateStar(star.id, { ...star });
};

export function usePricesByItem(itemId) {
  const [response, setResponse] = useState({
    data: null,
    status: "loading",
    error: null,
  });

  //const storage = getStorage();

  //const thumbsRef = ref(storage, thumbPath);
  // getPricesByItem
  useEffect(() => {
    const fetchUrl = async () => {
      getItemPrices({ id: itemId })
        //getDownloadURL(thumbsRef)
        .then((resUrl) => {
          console.log("usePricesByItem result", resUrl);
          setResponse({ data: resUrl.data, status: "success", error: null });
        })
        .catch((error) => {
          console.log("usePricesByItem error", error);

          setResponse({ data: null, status: "error", error: error });
        });

      //setClientSecret("");
    };
    fetchUrl();
  }, []);

  return response;
}

/*
There is lso  function called useLotStatus
Refactor to remove one hok.
? There is alos another lot status hook. So track that down.
*/

// Developmental hook. To explore timed interval
// to update ItemPage, ItemCard etc live for the user.
// Challenging as of Spe 22, 2022 and the code below
// triggers a 'runaway'.
// So use useLotStatus instead for prod.

export function useLotStatus(lot) {
  //export function useDynamicLotStatus2(lot) {

  // const {data:auction, status:auctionStatus, error:auctionError} = useAuctionByEvent(lot.auction);

  const [isKnockedDown, setIsKnockedDown] = useState();
  const [isPaid, setIsPaid] = useState();
  const [isCancelled, setIsCancelled] = useState();
  const [isLotStarted, setIsLotStarted] = useState();
  const [isLotEnded, setIsLotEnded] = useState();
  const [isDelisted, setIsDelisted] = useState();



  function updateLotStatus() {
    //console.log("useLotStatus updateLotStatus check", lot);
    setIsKnockedDown(
      lot && lot.knockedDownAt && lot.knockedDownAt !== false ? true : false
    );

    setIsPaid(lot && lot.paid && lot.paid === true ? true : false);

    setIsCancelled(
      lot && lot.cancelled && lot.cancelled === true ? true : false
    );

    setIsDelisted(
      lot && lot.delistedAt && lot.delistedAt !== false ? true : false
    );

    var started = false;
    var ended = false;

    if (lot && lot.endsAt && isPastTime(lot.endsAt)) {
      ended = true;
    }

    if (lot && lot.startsAt && isPastTime(lot.startsAt)) {
      started = true;
    }

    setIsLotStarted(started);
    setIsLotEnded(ended);
  }

  useEffect(() => {
    updateLotStatus();
    const timer2 = setInterval(() => {
      updateLotStatus();
    }, 1000);
    // Clear timeout if the component is unmounted
    return () => clearTimeout(timer2);
  }, [lot]);

  const response = {
    isPaid: isPaid,
    isCancelled: isCancelled,
    isKnockedDown: isKnockedDown,
    isLotStarted: isLotStarted,
    isLotEnded: isLotEnded,
    isDelisted: isDelisted,
  };

  return response;
}

export function useItemLotStatus(item, lot) {

const {data:eventData, status:eventStatus, error:eventError} = useEvent(item && item.auction);

const [isSaleAvailable, setIsSaleAvailable] = useState();
const isSaleMissed = item.consigned === true && item.accepted !== true && !lot && !isSaleAvailable && (["declining_price","increasing_bid"]).includes(lot?.type);


useEffect(()=>{

if (eventStatus !== 'success') {return;}

if (eventData == null) {setIsSaleAvailable(false);
return;}

setIsSaleAvailable(!isPastTime(eventData.startTime));

}, [eventData, eventStatus]);

return {isSaleAvailable:isSaleAvailable, isSaleMissed:isSaleMissed}

}



export function useDynamicLotStatus(inputLot, event) {
  var lot = inputLot;
  if (event) {
    const t =
      inputLot?.startsAt && event?.type !== "declining_price"
        ? inputLot.startsAt
        : event?.startTime;
    const t2 = inputLot ? { ...inputLot, startsAt: t } : inputLot;
    lot = t2;
  }

  const [response2, setResponse2] = useState({
    data: null,
    status: "loading",
    error: null,
  });
  //
  //const [data, setData] = useState({isPaid:true, isKnockedDown:true, isLotStarted:false});
  //  const [data, setData] = useState();
  //  const [status, setStatus] = useState();
  //  const [error, setError] = useState();
  // const [isLotStarted, setIsLotStarted] = useState();
  //const storage = getStorage();

  //const thumbsRef = ref(storage, thumbPath);
  // getPricesByItem

  //if (!(isOwner || isAuctioneer) && item && !isLotStarted) {
  //  visible = false;
  //}

  async function checkStatus() {
    //  console.log("db.js useStatusByLot checkStatus lot", lot);
    if (lot === undefined || lot === null) {
      return;
    }

    const isKnockedDown =
      lot && lot.knockedDownAt && lot.knockedDownAt !== false ? true : false;

    const isPaid = lot && lot.paid && lot.paid === true ? true : false;
    //if (isLotStarted === true) {
    //  clearInterval(timer2);
    //}
    if (debugFlag)
      console.log("db.js useStatusByLot lot.startsAt", lot.startsAt);
    const isLotStarted = isPastTime(lot.startsAt);
    //const isLotStarted = true;
    const data = {
      isLotStarted: isLotStarted,
      isPaid: isPaid,
      isKnockedDown: isKnockedDown,
    };
    const status = "success";
    const r = { data: data, status: status, error: null };
    //setResponse2({...r})
  }

  useEffect(() => {
    if (debugFlag) console.log("db.js useLotByStatus lot", lot);
    const r = checkStatus();
    setResponse2(r);
  });

  return response2;
}

export function useImpressionsByItem(itemId) {
  const [response, setResponse] = useState({
    data: null,
    status: "loading",
    error: null,
  });

  useEffect(() => {
    console.log("useImpressionsByItem start");
    const fetchUrl = async () => {
      countStoredImpressions({ id: itemId })
        .then((resUrl) => {
          console.log("useImpressionsByItem result", resUrl);
          setResponse({ data: resUrl.data, status: "success", error: null });
        })
        .catch((error) => {
          console.log("useImpressionsByItem error", error);

          setResponse({ data: null, status: "error", error: error });
        });

      //setClientSecret("");
    };
    fetchUrl();
  }, []);

  return response;
}

const storage = getStorage();
const storageRef = ref(storage);

export function useHasLotStarted(inputLot, event) {
  var lot = inputLot;
  if (event) {
    const t =
      inputLot?.startsAt && event?.type !== "declining_price"
        ? inputLot.startsAt
        : event?.startTime;
    const t2 = inputLot ? { ...inputLot, startsAt: t } : inputLot;
    lot = t2;
  }

  const [isLotStarted, setIsLotStarted] = useState();
  useEffect(() => {
    const fetchUrl = async () => {
      const timer2 = setInterval(() => {
        if (lot === undefined || lot === null) {
          return;
        }
        if (inputLot === undefined) {
          return;
        }
        if (!lot.startsAt) {
          return;
        }

        if (isLotStarted === true) {
          clearInterval(timer2);
        }

        if (isPastTime(lot.startsAt)) {
          setIsLotStarted(true);
          return;
        }
        setIsLotStarted(false);
      }, 1000);
      // Clear timeout if the component is unmounted
      return () => clearTimeout(timer2);
    };
    fetchUrl();
  });

  return { isLotStarted: isLotStarted };
}
// NOT USED.
// NOT SURE HOW TO LOAD A FIREBASE STORAGE FILE INTO MEMORY.
export async function downloadFirebaseStorageFile(fileName) {
  var pathRef = storageRef.child("email.json");
  var download = pathRef.getBytes(1024 * 1024);
  //var fileRef = firebase.storage().ref('email.json');
  //var jsonFile = fileRef.getBytes(1024*1024);
  console.log(download);
  /*
  storage.bucket().file("email.json")
  .download(function(err, contents) {
  console.log("download");
  console.log(contents);
  console.log(err);
    if (!err) {
      var jsObject = JSON.parse(contents.toString('utf8'))
       console.log(jsObject);
       return jsObject;
    } 
  return null;
  });
  */
}

// https://stackoverflow.com/questions/46723130/promises-all-with-additional-values
export function getById(path, itemIds) {
  //  console.log("getById", itemIds)
  var items = [];
  itemIds.forEach((itemId) => {
    const p = getItemById(itemId);
    //    console.log("getById",p);
    //items.push({ id: itemId, promise: db.collection(path).doc(itemId).get() });
    items.push({ id: itemId, promise: p });
  });

  return Promise.all(
    items.map((item) => {
      return item.promise.then((result) => {
        return { ...result.data(), id: item.id };
      });
    })
  );
}

export function getDocumentsByIds(path, itemIds) {
  console.log("getById", itemIds);
  var items = [];
  itemIds.forEach((itemId) => {
    const p = getDocumentById(path, itemId);
    console.log("getById", p);
    //items.push({ id: itemId, promise: db.collection(path).doc(itemId).get() });
    items.push({ id: itemId, promise: p });
  });

  return Promise.all(
    items.map((item) => {
      return item.promise.then((result) => {
        return { ...result.data(), id: item.id };
      });
    })
  );
}

export function useItemImages(images) {
  const [response, setResponse] = useState({
    data: null,
    status: "loading",
    error: null,
  });

  const initialImages = [{ id: "merp" }];

  const promises = [];
  //const uuids = [];
  const [imageURLS, setImageURLS] = useState([]);
  const [thumbURLS, setThumbURLS] = useState([]);
  const [responseImages, setResponseImages] = useState([]);

  const [status, setStatus] = useState("waiting");

  useEffect(() => {
    //    const fetchUrl = async () => {
    const newImages = images;

    if (images && images.length === 0) {
      setResponse({
        data: {
          lightboxImages: [],
          carouselImages: [],
          imageUrls: [],
          thumbUrls: [],
          images: [],
        },
        status: "success",
        error: null,
      });
      return;
    }

    if (images === undefined) {
      setResponse({
        data: { imageUrls: null, thumbUrls: null, images: null },
        status: "waiting",
        error: null,
      });
      return;
    }

    images.map((item, index) => {
      let location = item.location;

      item.thumbUrl = getFirebaseStorageDownloadThumbURL(location);
      item.imageUrl = getFirebaseStorageDownloadImageURL(location);
      return item;
    });

    var ximageURLS = [];
    var xthumbURLS = [];

    for (const [index, image] of newImages.entries()) {
      if (image.imageUrl !== undefined) {
        ximageURLS.push(image.imageUrl);
      }
      if (image.thumbUrl !== undefined) {
        xthumbURLS.push(image.thumbUrl);
      }
    }

    const carouselImages = [];
    const lightboxImages = [];
    const muiImages = [];

    for (const [index, image] of images.entries()) {
      carouselImages.push({
        original: image.imageUrl,
        thumbnail: image.thumbUrl,
      });
      lightboxImages.push(image.imageUrl);
      muiImages.push({ imgPath: image.imageUrl });
    }

    setResponse({
      data: {
        lightboxImages: lightboxImages,
        carouselImages: carouselImages,
        muiImages: muiImages,
        imageUrls: ximageURLS,
        thumbUrls: xthumbURLS,
        images: newImages,
      },
      status: "success",
      error: null,
    });
  }, [images]);

  return response;
}

export function useItemImagesList(item) {
  if (item.images === undefined) {
    return [[], [], "waiting"];
  }

  const ximageURLS = [];
  const xthumbURLS = [];
  if (item.images !== undefined) {
    for (const [index, image] of item.images.entries()) {
      if (image.imageUrl !== undefined) {
        ximageURLS.push(image.imageUrl);
      }
      if (image.thumbUrl !== undefined) {
        xthumbURLS.push(image.thumbUrl);
      }
    }
  }
  return [ximageURLS, xthumbURLS, "success"];
  // },[images])
  // return [[],[]]
}

/**** USERS ****/

// Fetch user data (hook)
// This is called automatically by auth.js and merged into auth.user
//4P7vsOtlNWgN2gj31eohYphSxiD2
export function useUser(uid) {
  //return useQuery(uid && firestore.collection("users").doc(uid));

  if (uid && uid.length === 10) {
  }

  return useQuery(
    // Unique query key: https://react-query.tanstack.com/guides/query-keys
    ["user", { uid }],
    // Query function that subscribes to data and auto-updates the query cache
    createQuery(() => doc(db, "users", uid)),
    // Only call query function if we have a `uid`
    { enabled: !!uid }
  );
}

// Work here
export function useItemsByIds(items) {
  const [response, setResponse] = useState({
    data: null,
    status: "loading",
    error: null,
  });

  useEffect(() => {
    if (items === undefined) {
      return;
    }
    //console.log("itemIds", itemIds)

    const itemIds = items.map((tempItem) => {
      console.log("db", tempItem);
      return tempItem.id;
    });

    console.log("ItemsListOrders itemIds", itemIds);

    const retrievedItems = getDocumentsByIds("items", itemIds);
    console.log("ItemsListOrders retrievedItems", retrievedItems);

    retrievedItems.then((m) => {
      const s = sortBy(m, "id", "asc", false);

      //const e = groupBy(s, "event");

      setResponse({ data: { items: s }, status: "success", error: null });
    });
  }, [items]);

  return response;
}
// Fetch all items by owner (hook)
export function useItemsByShortId(shortId) {
  /*
  return useQuery(
    shortId &&
      db
        .collection("users")
        .where("shortid", "==", shortId)
  );
*/
  return useQuery(
    // Unique query key: https://react-query.tanstack.com/guides/query-keys
    ["user", { shortId }],
    // Query function that subscribes to data and auto-updates the query cache
    createQuery(() => doc(db, "users", shortId)),
    // Only call query function if we have a `uid`
    { enabled: !!shortId }
  );
}

/*
Hook to take a an imagepath and return the storage download url.
*/


export function useWordPresspostById(postId) {
  const [response, setResponse] = useState({
    data: null,
    status: "loading",
    error: null,
  });

  useEffect(() => {
    const fetchUrl = async () => {
      const response = await fetch(
        "https://alittlehelp.io/wp-json/wp/v2/posts" + "/" + postId 
      );
      if (!response.ok) {
        // oups! something went wrong
        setResponse({ data: null, status: "error", error: "Merp" });
        return;
      }

      const c = await response.json();
      //setPost(c);
      setResponse({ data: c, status: "success", error: null });
    };
    fetchUrl();
  }, []);

  return response;
}

export function useWordPressPostBySlug(slugName) {
  const [response, setResponse] = useState({
    data: null,
    status: "loading",
    error: null,
  });

  useEffect(() => {
    const fetchUrl = async () => {
      const url = "https://alittlehelp.io/wp-json/wp/v2/posts?slug=" + slugName;

      console.log("db useWordpressPostBySlug asking for ", url);

      fetch(url, { crossDomain: true })
        .then((res) => {
          res
            .json()
            .then((jsonResponse) => {
              //const c = await res.json();
              //console.log("foo",c);
              // This call returns an array.
              // Get the first entry.
              const post = jsonResponse[0];

              setResponse({ data: post, status: "success", error: null });
            })
            .then((error) => {
              setResponse({
                data: null,
                status: "error",
                error: "The was a JSON problem.",
              });
              console.log(error);
              // jsn error
            });
        })
        .catch((error) => {
          setResponse({
            data: null,
            status: "error",
            error: "The was a problem.",
          });
          console.log(error);
        });

      /*
//try {
        const res = await fetch(url, {crossDomain:true});
    
//} catch (e) {

     //     console.log("db useWordpressPostBySlug caught error ", e);
     //     setResponse({ data: null, status: "error", error: 'Wordpress fetch call failed.' });
 // return;
//}


        
        if(!res.ok) {
            // oups! something went wrong
            console.log("foo response not ok",res.ok);
            setResponse({ data: null, status: "error", error: 'The was a problem.' });
            return;
        } 

        const c = await res.json();
        console.log("foo",c);
        // This call returns an array.
        // Get the first entry.
        const post = c[0];

        setResponse({ data: post, status: "success", error: null });
        */
    };
    fetchUrl();
  }, []);

  return response;
}

export function useWordPressPosts() {
  const [response, setResponse] = useState({
    data: null,
    status: "loading",
    error: null,
  });

  useEffect(() => {
    const fetchUrl = async () => {
      const url = "https://alittlehelp.io/wp-json/wp/v2/posts?_embed";

      const response = await fetch(url);
      if (!response.ok) {
        // oups! something went wrong
        setResponse({
          data: null,
          status: "error",
          error: "The was a problem.",
        });
        return;
      }

      const posts = await response.json();
      console.log("posts", posts);
      // This call returns an array.
      // Get the first entry.

      setResponse({ data: posts, status: "success", error: null });
    };
    fetchUrl();
  }, []);

  return response;
}

function bucketFileFromLocation(location) {
  let path = location.split("://")[1];
  let parts = path === undefined ? ["", "", ""] : path.split("/");
  let imageBucket = parts[2];
  let imageFile = parts[3];

  return { bucket: imageBucket, file: imageFile };
}

export function getFirebaseStorageDownloadThumbURL(location) {
  // const nullImageUrl = "https://via.placeholder.com/200x200";

  const bucketFile = bucketFileFromLocation(location);
  let thumbPath =
    "images/items/" + bucketFile.bucket + "/thumb_" + bucketFile.file;

  // var imageUrl = nullImageUrl;
  var imageUrl = brokenImage;
  if (!thumbPath.endsWith("undefined")) {
    imageUrl =
      "https://firebasestorage.googleapis.com/v0/b/auctionsite-5b377.appspot.com/o/images%2Fitems%2F" +
      bucketFile.bucket +
      "%2Fthumb_" +
      bucketFile.file +
      "?alt=media";
  }

  return imageUrl;
}

export function getFirebaseStorageDownloadImageURL(location) {
  // const nullImageUrl = "https://via.placeholder.com/200x200";
  const nullImageUrl = brokenImage;
  const bucketFile = bucketFileFromLocation(location);
  let thumbPath = "images/items/" + bucketFile.bucket + "/" + bucketFile.file;

  var imageUrl = nullImageUrl;
  if (!thumbPath.endsWith("undefined")) {
    imageUrl =
      "https://firebasestorage.googleapis.com/v0/b/auctionsite-5b377.appspot.com/o/images%2Fitems%2F" +
      bucketFile.bucket +
      "%2F" +
      bucketFile.file +
      "?alt=media";
  }

  return imageUrl;
}

export function useFirebaseStorageThumbURL(location) {
  const [response, setResponse] = useState({
    data: null,
    status: "loading",
    error: null,
  });

  useEffect(() => {
    if (location === undefined) {
      return;
    }

    const imageUrl = getFirebaseStorageDownloadThumbURL(location);
    setResponse({ data: imageUrl, status: "success", error: null });
  }, [location]);

  return response;
}

export function useFirebaseStorageImageURL(location) {
  const [response, setResponse] = useState({
    data: null,
    status: "loading",
    error: null,
  });

  useEffect(() => {
    if (location === undefined) {
      return;
    }

    const imageUrl = getFirebaseStorageDownloadImageURL(location);

    setResponse({ data: imageUrl, status: "success", error: null });
  }, [location]);

  return response;
}

/*

This is probably not much use now
But then what is "long-lived"?
*/

export function useDownloadURL(thumbPath) {
  const [response, setResponse] = useState({
    data: null,
    status: "loading",
    error: null,
  });

  const storage = getStorage();
  const thumbsRef = ref(storage, thumbPath);

  useEffect(() => {
    if (thumbPath.endsWith("undefined")) {
      setResponse({
        data: null,
        status: "error",
        error: "Requested undefined imge.",
      });
      return;
    }

    const fetchUrl = async () => {
      getDownloadURL(thumbsRef)
        .then((resUrl) => {
          setResponse({ data: resUrl, status: "success", error: null });
        })
        .catch((error) => {
          setResponse({ data: null, status: "error", error: error });
        });
    };
    fetchUrl();
  }, []);

  return response;
}

export function useProfile(uid) {
  //return useQuery(uid && db.collection("profiles").doc(uid));

  return useQuery(
    ["profile", { uid }],
    createQuery(() => doc(db, "profiles", uid)),
    { enabled: !!uid }
  );
}

export async function getUser(uid) {
  if (uid === undefined) {
    return false;
  }
  var docRef = await db.collection("users").doc(uid);

  return docRef.get();
}

// export async function deprecate_getItemPrimaryThumbnail(item) {
//   const emptyThumbnail =
//     "https://getuikit.com/v2/docs/images/placeholder_200x100.svg";

//   if (item === undefined) return emptyThumbnail;
//   if (item.images === undefined) return emptyThumbnail;
//   if (item.images.length === 0) return emptyThumbnail;

//   const image = item.images[0];
//   let location = image.location;

//   let path = location.split("://")[1];

//   let parts = path.split("/");
//   let imageBucket = parts[2];
//   let imageFile = parts[3];

//   if (imageFile === undefined) {
//     return "https://cdn.pixabay.com/photo/2017/11/06/11/49/texture-2923443_960_720.jpg";
//   }

//   let thumbPath = "images/items/" + imageBucket + "/thumb_" + imageFile;

//   let thumbquery = storageRef.child(thumbPath);

//   const downloadURL = await thumbquery
//     .getDownloadURL()
//     .then(function (reqResult) {
//       return reqResult;
//     })
//     .catch((err) => {
//       console.log(err);
//       return emptyThumbnail;
//     });
// }

// Update an existing user
export function updateUser(uid, data) {
  //return firestore.collection("users").doc(uid).update(data);
  //  return db.collection("users").doc(uid).set(data, {merge:true});

  const userRef = doc(db, "users", uid);
  return setDoc(userRef, data, { merge: true });
}

// Update an existing user
// export function updateProfile(uid, data) {
//   //  return db.collection("profiles").doc(uid).set(data, {merge:true});



//   const profileRef = doc(db, "profiles", uid);
//   return setDoc(profileRef, data, { merge: true });
// }

export function updateProfile(uid, data, profileImageUrl) {
  const profileRef = doc(db, "profiles", uid);
  return setDoc(profileRef, {...data, profileImageUrl: profileImageUrl}, { merge: true });
}

// Create a new user
export function createUser(uid, data) {
  const nanoid = customAlphabet("1234567890ABCDEFGHIJKLMNOPQRSTUVXYZ", 10);
  const shortId = nanoid();
  data = { ...data, shortid: shortId };
  /*
  return db
    .collection("users")
    .doc(uid)
    .set({ uid, ...data }, { merge: true });
*/

  const userRef = doc(db, "users", uid);
  return setDoc(userRef, { uid, ...data }, { merge: true });
}

/**** ITEMS ****/
/* Example query functions (modify to your needs) */

// Fetch all items by owner (hook)
export function useItemsByOwner(owner) {
  /*
  return useQuery(
    owner &&
      db
        .collection("items")
        .where("owner", "==", owner)
        .orderBy("createdAt", "desc")
  );
*/

  return useQuery(
    ["items", { owner }],
    createQuery(() =>
      query(
        collection(db, "items"),
        where("owner", "==", owner),
        orderBy("createdAt", "desc")
      )
    ),
    { enabled: !!owner }
  );
}

// Fetch all bids for an item (hook)
export function useBidsByItem(item) {
  // TODO Build out to limit horizon of which auctions a particular owner can see.

  // Auctioneers owner id.
  /*
  return useQuery(
    item &&
      db
        .collection("bids")
        .where("item", "==", item)
        .orderBy("createdAt", "desc")
        
  );
  */
  return useQuery(
    ["bids", { item }],
    createQuery(() =>
      query(
        collection(db, "bids"),
        where("item", "==", item),
        orderBy("createdAt", "desc")
      )
    ),
    { enabled: !!item }
  );
}

// Fetch all bids for an item (hook)
/*
export function useOrderById(orderId) {

  return useQuery(
    ["orders", { orderId }],
    createQuery(() =>
      query(
        collection(db, "orders"),
        where("id", "==", orderId),
        orderBy("createdAt", "desc")
      )
    ),
    { enabled: !!orderId }
  );
}
*/

// Fetch all bids for an item (hook)
export function useUserOrderById(orderId, userId) {
  return useQuery(
    ["orders", "user", { orderId, userId }],
    createQuery(() =>
      query(
        collection(db, "users", userId, "orders"),
        where("id", "==", orderId),
        orderBy("createdAt", "desc")
      )
    ),
    { enabled: !!orderId }
  );
}

// Fetch all bids for an item (hook)
export function useUserOrders(userId) {
  return useQuery(
    ["orders", "user", { userId }],
    createQuery(() =>
      query(
        collection(db, "users", userId, "orders"),
        orderBy("createdAt", "desc")
      )
    ),
    { enabled: !!userId }
  );
}

/*
// Fetch all lots by event (hook)
export function useMessagesByItem(item) {
  const itemId = item.id;
  return useQuery(
    ["messages", "item", { itemId }],
    createQuery(() =>
      query(
        collection(db, "items", itemId, "messages"),
        orderBy("createdAt", "desc")
      )
    ),
        { enabled: !!itemId }
    //{ enabled: true}
   // { enabled: !!itemId , staleTime:0}
  );
}


*/

// Fetch all bids for an item (hook)
/*
export function deprecate_usePricesByItem(itemId) {
  // TODO Build out to limit horizon of which auctions a particular owner can see.

  // Auctioneers owner id.

  return useQuery(
    ["prices", { itemId }],
    createQuery(() =>
      query(
        collection(db, "prices"),
        where("item", "==", itemId),

        orderBy("createdAt", "desc")
      )
    ),
    { enabled: !!itemId }
  );
}
*/
// Fetch all bids for an specific item by the owner of the bids (hook)
export function useBidsByItemOwner(item, owner) {
  // TODO Build out to limit horizon of which auctions a particular owner can see.

  // Auctioneers owner id.
  /*
  return useQuery(
    item && owner &&
      db
        .collection("bids")
        .where("item", "==", item)
        .where("owner", "==", owner)
        .orderBy("createdAt", "desc")
       // .then()
       // .catch((error)=>{console.log("useBidsByItemOwner", error)})
  );
*/
  return useQuery(
    ["bids", { item, owner }],
    createQuery(() =>
      query(
        collection(db, "bids"),
        where("item", "==", item),
        where("owner", "==", owner),
        orderBy("createdAt", "desc")
      )
    ),
    { enabled: !!owner && !!item }
  );
  // Not sure abut enabled lien ????/
}

// Fetch all events by owner (hook)
export function useAuctionsByFeatured() {
  // TODO Build out to limit horizon of which auctions a particular owner can see.
  // Auctioneers owner id.
  const owner = "l2ccahsQ4Bdruk7Zs0SxxYzraos2";

  return useQuery(
    ["events", "owner", { owner }],
    createQuery(() =>
      query(
        collection(db, "events"),
        where("owner", "==", owner),
        orderBy("createdAt", "desc")
      )
    ),
    { enabled: !!owner, refetchOnMount: false }
  );
}

export function useLoadingContext(items) {
  const [itemsStatus, setItemsStatus] = useState({});
}

export function useCollectionsByEvent(eventID) {
  // Get all the lots which refernce that eventId

  const items = useQuery(
    ["items", "collections", { eventID }],
    createQuery(() =>
      query(
        collection(db, "items"),
        where("auction", "==", eventID),
        orderBy("createdAt", "desc")
      )
    ),
    { enabled: !!eventID, refetchOnMount: false }
  );

  const rawEvents =
    items &&
    items.data &&
    items.data.map((item) => {
      return item.event;
    });
  let events = [...new Set(rawEvents)];
  console.log("useCollectionsByEvent events", events);
  items.data = events;
  return items;
  //return {data:"merp", status:"success", error:null, id:667}
}

export function useCountImpressionsByItem(itemId) {
  // Get all the lots which refernce that eventId
  const [count, setCount] = useState();
  const [response, setResponse] = useState({
    data: null,
    error: null,
    status: "loading",
  });

  const {data, status, error} = useQuery(
    ["counters", { itemId }],
    createQuery(() => query(collection(db, "counters", itemId, "shards"))),
    { enabled: !!itemId }
  );

  useEffect(() => {
    if (status !== "success") {
      return;
    }

    let count = 0;
    for (const shardCount of data) {
      count += shardCount.count;
    }
    setResponse({ data: { count: count }, status: 'success', error: null });
  }, [data, status]);

  return response;
}

// Fetch all events by owner (hook)
export function useEventsByOwner(ownerID, filter) {
  // TODO Build out to limit horizon of which auctions a particular owner can see.

  // Auctioneers owner id.
  if (ownerID === "curator") {
    ownerID = "l2ccahsQ4Bdruk7Zs0SxxYzraos2";
  }

  /*
  const auctions =   useQuery(
    ownerID &&
      db
        .collection("events")
        .where("owner", "==", ownerID)
        .orderBy("createdAt", "desc")
  );
*/
  const auctions = useQuery(
    ["events", "owner", { ownerID }],
    createQuery(() =>
      query(
        collection(db, "events"),
        where("owner", "==", ownerID),
        orderBy("createdAt", "desc")
      )
    ),
    { enabled: !!ownerID }
  );

  var filteredAuctions = auctions;
  if (auctions.data !== undefined) {

//console.log("db useEventsByOwner auctions data", auctions.data);
    if (filter === "future") {
      filteredAuctions.data = auctions.data.filter(
        (auction) => !isPastTime(auction.startTime)
      );
    }

    if (filter === "marketplace") {
      filteredAuctions.data = auctions.data.filter(
        (auction) => (auction.type === 'marketplace_price')
      );
    }

  }

  return filteredAuctions;
}

export function useEventsBySlug(slug, filter) {
  const collections = useQuery(
    ["events", "slug", { slug }],
    createQuery(() =>
      query(
        collection(db, "events"),
        where("collection", "==", slug),
        orderBy("createdAt", "desc")
      )
    ),
    { enabled: !!slug }
  );

  var filteredCollections = collections;
  if (collections.data !== undefined) {
    if (filter === "future") {
      filteredCollections.data = collections.data.filter(
        //(auction) => !isPastTime(auction.startTime)
        (auction) => true
      );
    }
  }

  return filteredCollections;
}

export function useEventBySlug(slug, filter) {
  const events = useQuery(
    ["event", "slug", { slug }],
    createQuery(() =>
      query(
        collection(db, "events"),
        where("slug", "==", slug),
        orderBy("createdAt", "desc")
      )
    ),
    { enabled: !!slug }
  );
  console.log("db useEventBySlug", events);
  if (events && events.data) {
    if (events.data.length > 0) {
      console.log("events", events);
      events.data = events.data[0];
    } else {
      events.data = null;
    }
  }
  return events;

  /*
  //return collections;
  console.log("slug", slug);
console.log("collections", collections);


  var filteredCollections = collections;
  if (collections.data !== undefined) {
    if (filter === "future") {
      filteredCollections.data = collections.data.filter(
        //(auction) => !isPastTime(auction.startTime)
        (auction) => true
      );
    }
  }

//  const collection = collections.data.length > 0 ? collections.data[0] : null;
return filteredCollections;
//  return {data:collection, status:'success', error:null};
*/
}

// Fetch all events by owner (hook)
/*
export function useEventsByOwner(owner) {
  return useQuery(
    owner &&
      firestore
        .collection("events")
        .where("owner", "==", owner)
        .orderBy("createdAt", "desc")
  );
}
*/

// Fetch all items by event (hook)
// takes an eventId
export function useItemsByEvent(event) {
  //const auth = useAuth();

  // Refactor this
  // Need to review the places the auctioneer reuiqres
  // this specific use of this hook.
  /*
  var field = "event";
  if (auth.user && auth.user.role && auth.user.role === "auctioneer") {
    field = "auction";
  }
 */
var events = [event];
if (Array.isArray(event)) {events = event;}

//https://cloud.google.com/firestore/docs/samples/firestore-query-filter-array-contains-any

//console.log("db useItemsByEvent", event);
//const events = [event];

console.log("db useItemsByEvent events", events);


  const q = useQuery(
    ["items", "event", { ...events }],
    createQuery(() =>
      query(
        collection(db, "items"),
//        where("event", "==", event)
        where("event", "in", events)
        //     orderBy("createdAt", "desc")
      )
    ),
    {
      enabled: !!event,
      refetchOnMount: false,
      cacheTime: 0, // Otherwise call in items individually.
    }
  );

  console.log("db useItemsByEvent q", q);

  return q;
}

// Fetch all items by event (hook)
// takes an eventId

// Fetch all items by auction (hook)
export function useItemsByAuction(event) {
  return useQuery(
    ["items", "auction", { event }],
    createQuery(() =>
      query(
        collection(db, "items"),
        where("auction", "==", event),
        orderBy("createdAt", "desc")
      )
    ),
    { enabled: !!event, cacheTime: 0 }
  );
  /*
  return useQuery(
    event &&
      db
        .collection("items")
        .where('auction', "==", event)
        .orderBy("createdAt", "desc")
  );
  */
}

export function useImagesbyItem(item) {
  const [dishes, setDishes] = useState([]);
  const { images } = item !== undefined ? item : false;

  //const path ="/images/items/" + imageBucket;
  /*
  useEffect(() => {


      const storage = firebase.storage()
      const storageRef = storage.ref(path)
      const promises = snapshot.docs.map(doc => storageRef.child(doc.data().imageString).getDownloadURL());
      Promise.all(promises).then(downloadURLs => {
        const newDish = snapshot.docs.map((doc, index) => ({
          id: doc.id,
          ...doc.data(),
          getDownloadURLs: downloadURLs[index]
        }))
        setDishes(newDish)
      })
}, [])

  return dishes
*/
  return null;
}

/////// Product Calls ///////

export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

// Fetch item data
export function useItemStatic(id) {
  const defaultPalette = { lightVibrant: "#cccccc", darkMuted: "#000000" };
  //var response = useQuery(id && db.collection("items").doc(id));

  var response = useQuery(
    ["item", "static", { id }],
    createQuery(() => doc(db, "items", id)),
    { enabled: !!id, staleTime: Infinity }
  );

  if (response.data && response.data.palette === undefined) {
    response.data = { ...response.data, palette: defaultPalette };
  }
  //return useQuery(id && firestore.collection("items").doc(id));
  //console.log("response", response);
  //console.log("id", id);
  return response;
}

// Fetch item data
export function useItem(id) {
  const defaultPalette = { lightVibrant: "#cccccc", darkMuted: "#000000" };
  //var response = useQuery(id && db.collection("items").doc(id));

  var response = useQuery(
    ["item", { id }],
    createQuery(() => doc(db, "items", id)),
    { enabled: !!id, cacheTime: 0 }
  );

  if (response.data && response.data.palette === undefined) {
    response.data = { ...response.data, palette: defaultPalette };
  }
  //return useQuery(id && firestore.collection("items").doc(id));
  // console.log("response", response);
  // console.log("id", id);
  return response;
}

// Fetch item data
/*
export function useItemLive(id) {
  const defaultPalette = { lightVibrant: "#cccccc", darkMuted: "#000000" };
  //var response = useQuery(id && db.collection("items").doc(id));

  var response = useQuery(
    ["item", { id }],
    createQuery(() => doc(db, "items", id)),
    { enabled: !!id, 
      refetchOnMount: "always",
    }
  );

  if (response.data && response.data.palette === undefined) {
    response.data = { ...response.data, palette: defaultPalette };
  }
  //return useQuery(id && firestore.collection("items").doc(id));
  // console.log("response", response);
  // console.log("id", id);
  return response;
}
*/

// Fetch item data
export function useItemBasic(itemId) {
  if (itemId === undefined) {
    return true;
  }
  const defaultPalette = { lightVibrant: "#cccccc", darkMuted: "#000000" };
  //  const docRef = doc(db, "items", id);
  const docRef = doc(db, "items", itemId);
  const docSnap = getDoc(docRef);
  return docSnap
    .then((result) => {
      const data = result.data();
      console.log("data", data);
      return { data: data, status: "success", error: null };
    })
    .catch((error) => {
      return { data: null, status: "error", error: true };
    });

  //const docSnap = await getDoc(docRef);

  //  var response = null;
  /*
if (docSnap.exists()) {
  const response = docSnap.data();
  if (response.data && response.data.palette === undefined) {
    response.data = { ...response.data, palette: defaultPalette };
  }

} else {
response = true;
  // doc.data() will be undefined in this case
  console.log("No such document!");
}
*/
  //  return response;
}

// Update an item

export function updateItem(id, data) {
  //  return db.collection("items").doc(id).update(data);

  const itemRef = doc(db, "items", id);
  return updateDoc(itemRef, data);
}

// Fetch all starred items by owner (hook)
export function useStarsByOwner(ownerId) {
  /*
  return useQuery(
    owner &&
      db
        .collection("stars")
        .where("owner", "==", owner)
        .orderBy("createdAt", "desc")
  );
  */
  return useQuery(
    ["stars", { ownerId }],
    createQuery(() =>
      query(
        collection(db, "stars"),
        where("owner", "==", ownerId),
        orderBy("createdAt", "desc")
      )
    ),
    { enabled: !!ownerId }
  );
}

export function useNotifications(itemId, ownerId) {
  /*
  return useQuery(
    owner &&
      db
        .collection("stars")
        .where("owner", "==", owner)
        .orderBy("createdAt", "desc")
  );
  */
  return useQuery(
    ["notifications", { ownerId, itemId }],
    createQuery(() =>
      query(
        collection(db, "notifications"),
        where("owner", "==", ownerId),
        where("item", "==", itemId)
      )
    ),
    { enabled: !!ownerId && !!itemId}
  );
}

// Fetch a star given the item and owner (hook)
export function useStarByItemOwner(item, owner) {
  /*
  return useQuery(
    owner && item &&
      collection(db,"stars")
        .where("owner", "==", owner)
        .where("item", "==", item)
        .orderBy("createdAt", "desc")
        .limit(1)
  );
*/
  return useQuery(
    ["star", { owner, item }],
    createQuery(() =>
      query(
        collection(db, "stars"),
        where("owner", "==", owner),
        where("item", "==", item),
        orderBy("createdAt", "desc"),
        limit(1)
      )
    ),
    { enabled: !!owner && !!item }
  );
}

/*
There is also a hook call useStatusByLot.
So bare that in mind.
*/

export function useLotStatusWorking(inputLot, event) {
  var lot = inputLot;
  if (event && event?.type === "declining_price") {
    //const start = inputLot?.startsAt ? inputLot.startsAt : event?.startTime;
    //const end = inputLot?.endsAt ? inputLot.endsAt : event?.endTime;

    // Ignore any startsAt or EndsAt found in the lot record
    const start = event?.startTime;
    const end = event?.endTime;

    const t2 = inputLot
      ? { ...inputLot, startsAt: start, endsAt: end }
      : inputLot;

    lot = t2;
  }

  //const [isLotStarted, setIsLotStarted] = useState();

  const isKnockedDown =
    lot && lot.knockedDownAt && lot.knockedDownAt !== false ? true : false;

  const isPaid = lot && lot.paid && lot.paid === true ? true : false;

  const isCancelled =
    lot && lot.cancelled && lot.cancelled === true ? true : false;
  // Hook doesn't update (because lot and or event does not change
  // so this needs to be called on a setInterval.
  // See useDynamicLotStatus.

  const isLotStarted = lot && isPastTime(lot.startsAt);

  //if (i===undefined) {i = null;}

  const response = {
    isPaid: isPaid,
    isCancelled: isCancelled,
    isKnockedDown: isKnockedDown,
    isLotStarted: isLotStarted,
  };

  return response;
}

// Create a new item
export function createItem(data) {
  const nanoid = customAlphabet("ABCDEFGHIJKLMNOPQRSTUVXYZ", 4);
  const itemShortRef = "item_" + nanoid();

  return addDoc(collection(db, "items"), {
    ...data,
    itemRef: itemShortRef,
    createdAt: serverTimestamp(),
  });
}

// Update an star
export function updateStar(id, data) {
  //  return db.collection("stars").doc(id).update(data);

  return updateDoc(doc(db, "stars", id), data);
}

// Create a new star
export function createStar(data) {
  return addDoc(collection(db, "stars"), {
    ...data,
    createdAt: serverTimestamp(),
  });
}

// Create a bid
export function createBid(data) {
  if (data.amount === undefined || data.amount <= 0 || isNaN(data.amount)) {
    return Promise.reject(new Error("Invalid bid amount"));
  }

  return addDoc(collection(db, "bids"), {
    ...data,
    createdAt: serverTimestamp(),
  });
}


// Create an affiliate code
export function createAffiliate(userId, requestedAffiliateCode) {

  if (requestedAffiliateCode == null) {
    return Promise.reject(new Error("Undefined affiliate code"));
  }

  const affiliationRef = doc(db, "affiliations", requestedAffiliateCode);
  return setDoc(affiliationRef, {
    affiliate:userId,
    affiliateId:requestedAffiliateCode,
    createdAt: serverTimestamp(),
  });

}


// Create a notification
export function createNotification(data) {
//const auth = useAuth();
  //const owner = auth.user.uid;
  const {price, itemId, ownerId  } = data;
//  if (isNaN(data.amount)) {
//    return Promise.reject(new Error("Bid amount not a number " + data.amount));
//  }

//  if (data.amount <= 0) {
//    return Promise.reject(new Error("Bid amount less than zero"));
 // }

//  if (data.amount === undefined) {
//    return Promise.reject(new Error("Undefined bid amount"));
//  }

//        const timeAgoSeconds = 24 * 60 * 60;
const timeAgoSeconds = 0;
        const notifyAt = price.validAt.toDate();
        notifyAt.setSeconds(notifyAt.getSeconds() - timeAgoSeconds);

const currentAt = new Date();

        console.log("createNotification notifyAt", notifyAt);


if (currentAt > notifyAt) {return Promise.resolve({error:{message:"Date greater"}});}

        const notificationId = null;
       const notification = {
        owner: ownerId,
        item: itemId,
        notification: notificationId,
        price: price.id,
        notifyAt: notifyAt,
       // createdAt: serverTimestamp,
        notifiedAt:false}

const notificationRef = doc(collection(db,"notifications"));

        return setDoc(notificationRef, {
          ...notification,
          notification: notificationRef.id,
          createdAt: serverTimestamp(),
        });

/*
  return addDoc(collection(db, "notifications"), {
    ...data,
    createdAt: serverTimestamp(),
  });
*/
}

// Delete an event
export function deleteNotification(id) {
  return deleteDoc(doc(db, "notifications", id));
}



// Create a price
export function createPrice(data) {
  if (isNaN(data.amount)) {
    return Promise.reject(new Error("Bid amount not a number " + data.amount));
  }

  if (data.amount <= 0) {
    return Promise.reject(new Error("Bid amount less than zero"));
  }

  if (data.amount === undefined) {
    return Promise.reject(new Error("Undefined bid amount"));
  }

  /*

 return db.collection("prices").add({
   ...data,
   createdAt: serverTimestamp(),
 });
*/
  return addDoc(collection(db, "prices"), {
    ...data,
    createdAt: serverTimestamp(),
  });
}

/*
  const storage = getStorage();

  const thumbsRef = ref(storage, thumbPath);
 
processAffiliationsTestMessage
  useEffect(() => {
    const fetchUrl = async () => {
      getDownloadURL(thumbsRef)
      */

// Delete an item
export function deleteItem(itemId) {
  // Remve this bit of code when we are giving Ites a docid on creation.
  /// For now we have to handle the uuids that are created.
  // And that means getting the uniqueimage bucket references and exeuting a bucket delete or each of them.

  return getItemById(itemId)
    .then((result) => {
      // it.push({ id: sku, ...result.data() });
      //console.log("flerp",result.data());
      const images = result.data().images;
      var buckets = [];
      images.map((image) => {
        let imagePath = image.location.split("://")[1];

        let parts = imagePath.split("/");
        let imageBucket = parts[2];
        let imageFile = parts[3];
        //console.log("imageBucket",imageBucket);
        buckets.push(imageBucket);
      });

      console.log("buckets", buckets);
      var uniqueBuckets = buckets.filter((v, i, a) => a.indexOf(v) === i);

      console.log("buckets", uniqueBuckets);
      if (uniqueBuckets && uniqueBuckets.length > 0) {
        uniqueBuckets.map((bucket) => {
          deleteImagesById({ id: bucket });
        });
      }
      return deleteDoc(doc(db, "items", itemId));
    })
    .catch((error) => {
      console.log("Problem deleteing item", error);
      return true;
    });
}
export function deleteBucketImage(location) {
  return deleteItemImage(location);
}
// Delete an item
export function deleteItemImage(location) {
  let imagePath = location.split("://")[1];

  let parts = imagePath.split("/");
  let imageBucket = parts[2];
  let imageFile = parts[3];

  let thumbPath = "images/items/" + imageBucket + "/thumb_" + imageFile;

  /*
    const storage = getStorage();

  const thumbsRef = ref(storage, thumbPath);
 

  useEffect(() => {
    const fetchUrl = async () => {
      getDownloadURL(thumbsRef)
        .then((resUrl) => {
          */
  //let ref = firebaseApp.storage().ref();

  const storage = getStorage();
  const thumbRef = ref(storage, thumbPath);
  const imageRef = ref(storage, imagePath);

  deleteObject(thumbRef).then(function () {
    deleteObject(imageRef)
      .then(function () {})
      .catch(function (error) {
        console.log("there has been an error");
        // There has been an error
      });

    //}).catch(function(error) {
    //  console.log("there has been an error deleting an image", location)
    // There has been an error
  });
}

// Delete an item
export function deprecategetItemImage(location) {
  if (location === null) {
    console.log("null location");
    return new Promise((resolve, reject) => {
      resolve(null);
    });
  }

  let imagePath = location.split("://")[1];

  let parts = imagePath.split("/");
  let imageBucket = parts[2];
  let imageFile = parts[3];

  let path = "images/items/" + imageBucket + "/" + imageFile;

  //let storageRef = firebaseApp.storage().ref();

  const storage = getStorage();
  const imageRef = ref(storage, imagePath);

  return getDownloadURL(imageRef)
    .then(function (reqResult) {
      console.log("HELLO");
      return reqResult;
    })
    .catch((err) => {
      console.log("image error");
    });
}

/////// Event Calls ///////

// Fetch all items by event (hook)
export function useProductByEvent(event) {
  return useQuery(
    ["product", { event }],
    createQuery(() =>
      query(
        collection(db, "products"),
        where("event", "==", event),
        orderBy("createdAt", "desc")
      )
    ),
    { enabled: !!event }
  );
}

// Fetch all lots by event (hook)
export function useLotsByAuction(auction) {
  /*
  return useQuery(
    auction &&
      db
        .collection("lots")
        .where("auction", "==", auction)
 //       .orderBy("createdAt", "desc")
  );
*/
  return useQuery(
    ["lot", { auction }],
    createQuery(() =>
      query(
        collection(db, "lots"),
        where("auction", "==", auction)
        //        orderBy("createdAt", "desc")
      )
    ),
    { enabled: !!auction }
  );
}

// Fetch all active lots.
// THis will still need a post filter to remove
// ones that shouldn't show.
export function useActiveLots() {
  return useQuery(
    ["lot", "active"],
    createQuery(() =>
      query(
        collection(db, "lots"),
        where("accepted", "==", true),
        where("knockedDownAt", "==", false)
        //        orderBy("createdAt", "desc")
      )
    ),
    { enabled: true }
  );
}


// Fetch all lots by event (hook)
export function useLotsByWinner(userId) {
  // console.log("WHOIS", userId)
  /*
  return useQuery(
    userId &&
      db
        .collection("lots")
        .where("bidOwner", "==", userId)
        .where("reserveMet", "==", true)
        .orderBy("createdAt", "desc")
  );
  */

  return useQuery(
    ["lot", { userId }],
    createQuery(() =>
      query(
        collection(db, "lots"),
        where("bidOwner", "==", userId),
        where("reserveMet", "==", true),
        orderBy("createdAt", "desc")
      )
    ),
    { enabled: !!userId }
  );
}

// Fetch all lots by event (hook)
export function useLotsByBidOwner(userId) {
  return useQuery(
    ["lot", { userId }],
    createQuery(() =>
      query(
        collection(db, "lots"),
        where("bidOwner", "==", userId)
        //   where("reserveMet", "==", true),
        // orderBy("bidAt", "desc")
      )
    ),
    { enabled: !!userId }
  );
}

export function useMessagesByItemUser(item, userId) {
  //console.log("useMessagedbyUserItem triggered", item.id, item.name)
  const itemId = item.id;
  const {
    status: messagesItemStatus,
    data: messagesItem,
    error: messagesItemError,
    isFetching: messagesItemIsFetching,
  } = useMessagesByItem(item);

  const {
    status: messagesUserItemStatus,
    data: messagesUser,
    error: messagesUserError,
    isFetching: messagesUserIsFetching,
  } = useMessagesByUserItem(userId, item);

  const [messages, setMessages] = useState();
  const [status, setStatus] = useState("loading");
  const [error, setError] = useState(null);
  //const [messagesStatus, setMessagesStatus] = useState("loading");
  //const [response, setResponse] = useState({data:null, status:'loading', error:null});

  useEffect(() => {
    if (messagesItemStatus === "loading") {
      //setMessages([]);

      console.log("useMessagesbyItemUser messagesUserItemStatus loading");

      setStatus("loading");

      return;
    }

    if (messagesUserItemStatus === "loading") {
      //setMessages([]);

      console.log("useMessagesbyItemUser messagesUserItemStatus loading");
      setStatus("loading");
      return;
    }

    console.log(
      "useMessagesbyItemUser useEffect triggered",
      item.id,
      item.name
    );
    function retrieveMessages() {
      //setMessagesStatus("loading");
      var visibleMessagesItem = [];
      if (messages && messages.length > 0) {
        visibleMessagesItem = [...messages];
      }
      //    var visibleMessagesItem = [];

      if (messagesItem && messagesItemStatus !== "loading") {
        var newFilteredMessagesItem = messagesItem
          //   .filter(() => (true))
          .map((message, index) => {
            return { ...message, broadcast: "room" };
          });
        visibleMessagesItem = [
          ...visibleMessagesItem,
          ...newFilteredMessagesItem,
        ];
      }

      if (messagesUser && messagesUserItemStatus !== "loading") {
        var filteredMessagesUser = messagesUser
          .filter((messagesUserItem) => messagesUserItem.item === itemId)
          .map((message, index) => {
            return { ...message, broadcast: "user" };
          });
        visibleMessagesItem = [...visibleMessagesItem, ...filteredMessagesUser];
      }

      visibleMessagesItem = visibleMessagesItem.map((i) => {
        //return i;

        if (i.createdAt === null) {
          if (i.clientCreatedAt !== null) {
            return { ...i, createdAt: i.clientCreatedAt };
          }

          return null;
        }
        //console.log("xkcd", i);

        if (typeof i.createdAt === "string" || i.createdAt instanceof String) {
          return i;
        }

        return { ...i, createdAt: firebaseTime(i.createdAt) };
      });

      console.log("useMessagesbyItemUser Processing", item.name, item.id);
      console.log(
        "useMessagesbyItemUser db.js messsages",
        item.name,
        messagesItem,
        messagesUser
      );
      console.log(
        "useMessagesbyItemUser db.js error",
        item.name,
        messagesItemError,
        messagesUserError
      );

      //      console.log("db.js ids", userId, itemId);
      // Check if ready.
      /*
      var readyFlag = false;
      if (messagesUserStatus === "error" && messagesItemStatus === "success") {
        readyFlag = true;
      }

      if (messagesUserStatus === "success" && messagesItemStatus === "error") {
        readyFlag = true;
      }

      if (
        messagesUserStatus === "success" &&
        messagesItemStatus === "success"
      ) {
        readyFlag = true;
      }

      if (readyFlag === false) {
        //setMessagesStatus('loading')
        setMessages(null);
        setStatus("loading");
        return;
        // return {data:null, status:'loading'};
      }

      */

      var countList = [];

      var deduplicatedMessagesItem = visibleMessagesItem.filter(function (obj) {
        countList[obj.id] = (countList[obj.id] || 0) + 1;
        if (countList[obj.id] === 1) {
          return true;
        }
        return false;
      });
      console.debug(
        "useMessagesbyItemUser deduplicatedMesagesItem",
        item.id,
        item.name,
        deduplicatedMessagesItem
      );

      deduplicatedMessagesItem.map((message) => {
        console.debug(
          "useMessagesbyItemUser age",
          item.name,
          message.id
          // age(message.createdAt)
        );
      });
      //   var sortedVisibleMessagesItem = deduplicatedMessagesItem;

      var sortedVisibleMessagesItem = deduplicatedMessagesItem.sort(function (
        a,
        b
      ) {
        //return true;
        //return new Date.parse(a.createdAt) < new Date.parse(b.createdAt);
        //        return age(a.createdAt) < age(b.createdAt);
        //return a.createdAt.seconds < b.createdAt.seconds;
        return Date(a.createdAt) - Date(b.createdAt);
      });

      //const sortedVisibleMessagesItem = deduplicatedMessagesItem.slice().sort((a, b) => b.date.compareTo(a.date));

      setError(null);
      setStatus("success");
      setMessages(sortedVisibleMessagesItem);
      //return {data:sortedVisibleMessagesItem, status:'success'};
    }
    retrieveMessages();

    //},[userId,itemId]);
    // }, [messagesUser, messagesItem, messagesItemStatus, messagesUserStatus]);
    //}, [messagesUser, messagesItem, messagesItemStatus, messagesUserStatus]);
    //}, [messagesItemStatus, messagesUserStatus]);
  }, [messagesItem, messagesUser]);

  //},[item]);
  //console.log("db.js messages", messages);

  return { data: messages, status: status, error: error };
}

// Fetch all lots by event (hook)
export function useMessagesByItem(item) {
  const itemId = item.id;
  return useQuery(
    ["messages", "item", { itemId }],
    createQuery(() =>
      query(
        collection(db, "items", itemId, "messages"),
        orderBy("createdAt", "desc")
      )
    ),
    { enabled: !!itemId, staleTime: 0, cacheTime: 0 }
    //{ enabled: true}
    // { enabled: !!itemId , staleTime:0}
  );
}

// Fetch all lots by event (hook)
export function useMessagesByUserItem(userId, item) {
  const itemId = item.id;
  return useQuery(
    ["messages", "user", "item", { userId, itemId }],
    createQuery(() =>
      query(
        collection(db, "users", userId, "messages"),
        where("item", "==", itemId),
        orderBy("createdAt", "desc")
      )
    ),
    // { enabled: true}
    // { enabled: !!(userId || itemId) , staleTime:0}
    { enabled: !!(userId || itemId), cacheTime: 0, staleTime: 0 }
  );
}

// Fetch all lots by event (hook)
export function useMessagesByUser(userId) {
  return useQuery(
    ["messages", "user", { userId }],
    createQuery(() =>
      query(
        collection(db, "users", userId, "messages"),
        orderBy("createdAt", "desc")
      )
    ),
    //{ enabled: true}
    //{ enabled: !!userId,  staleTime:0 }
    { enabled: !!userId }
  );
}

// Fetch all lots by event (hook)
export function useLotsByUser(userId) {
  /*
  return useQuery(
    userId &&
      db
        .collection("lots")
        .where("bidOwner", "==", userId)
        .orderBy("createdAt", "desc")
  );
*/
  return useQuery(
    ["lot", { userId }],
    createQuery(() =>
      query(
        collection(db, "lots"),
        where("bidOwner", "==", userId),
        orderBy("createdAt", "desc")
      )
    ),
    { enabled: !!userId }
  );
}


// Fetch all lots by event (hook)
export function useLotsByAffiliate(affiliateId) {
  /*
  return useQuery(
    userId &&
      db
        .collection("lots")
        .where("bidOwner", "==", userId)
        .orderBy("createdAt", "desc")
  );
*/
console.log("db useLotsByAffiliate affiliateId", affiliateId);

  return useQuery(
    ["lot", "affiliate", { affiliateId }],
    createQuery(() =>
      query(
        collection(db, "lots"),
        where("affiliate", "==", affiliateId),
//        orderBy("knockedDownAt", "desc")
      )
    ),
    { enabled: !!affiliateId }
  );
}



// Fetch all items by event (hook)
// Gets auction - with auction state (running/not running)
// id is the reference to the Event.
export function useAuctionByEvent(eventId) {
  return useQuery(
    // Unique query key: https://react-query.tanstack.com/guides/query-keys
    ["auction", { eventId }],
    // Query function that subscribes to data and auto-updates the query cache
    createQuery(() => doc(db, "auctions", eventId)),
    // Only call query function if we have a `uid`
    { enabled: !!eventId }
  );
}

export function useUncachedAuctionByEvent(eventId) {
  return useQuery(
    // Unique query key: https://react-query.tanstack.com/guides/query-keys
    ["auction", { eventId }],
    // Query function that subscribes to data and auto-updates the query cache
    createQuery(() => doc(db, "auctions", eventId)),
    // Only call query function if we have a `uid`
    { enabled: !!eventId, cacheTime: 0 }
  );
}

// A lot is an item with an auction slot (lot end time),
// a lot id, an auction, and an item id.
// Create a new lot
export function createAuction(id, data) {
  const auctionRef = doc(db, "auctions", id);
  return setDoc(auctionRef, {
    ...data,
    createdAt: serverTimestamp(),
  });
}

// Update an lot
export function updateAuction(id, data) {
  const auctionRef = doc(db, "auctions", id);
  return setDoc(auctionRef, data, { merge: true });
}

// Delete an event
export function deleteAuction(id) {
  return deleteDoc(doc(db, "auctions", id));
}

// A lot is an item with an auction slot (lot end time),
// a lot id, an auction, and an item id.

// Fetch lot by item (hook)
export function useLotByItem(itemId) {
  return useQuery(
    // Unique query key: https://react-query.tanstack.com/guides/query-keys
    ["lot", "item", { itemId }],
    // Query function that subscribes to data and auto-updates the query cache
    createQuery(() => doc(db, "lots", itemId)),
    // Only call query function if we have a `uid`
    { enabled: !!itemId, refetchOnMount: false, staleTime: 50000 }
    // 6 january 2023. Conisder: test for immediate query
    //    { enabled: !!itemId, refetchOnMount: true, cacheTime: 0 }
  );
}

// Fetch lot by item (hook)
export function useLotByItemLive(itemId) {
  return useQuery(
    // Unique query key: https://react-query.tanstack.com/guides/query-keys
    ["lot", "item", { itemId }],
    // Query function that subscribes to data and auto-updates the query cache
    createQuery(() => doc(db, "lots", itemId)),
    // Only call query function if we have a `uid`
    { enabled: !!itemId, refetchOnMount: false }
  );
}

// Fetch lot by item (hook)
export function useLotByItemLiveRefetch(itemId) {
  return useQuery(
    // Unique query key: https://react-query.tanstack.com/guides/query-keys
    ["lot", "item", { itemId }],
    // Query function that subscribes to data and auto-updates the query cache
    createQuery(() => doc(db, "lots", itemId)),
    // Only call query function if we have a `uid`
    { enabled: !!itemId, refetchOnMount: true, cacheTime: 0 }
  );
}

// Fetch lot by item (hook)
export function getItemByLot(lotId) {
  const docRef = doc(db, "items", lotId);
  const docSnap = getDoc(docRef);
  return docSnap;
}

// Fetch item by item (hook)
export function getItemById(itemId) {
  //  console.log("getItemById", itemId);
  const docRef = doc(db, "items", itemId);
  const docSnap = getDoc(docRef);
  return docSnap;
}

// Fetch item by item (hook)
export function getDocumentById(collectionName, documentId) {
  //  console.log("getDocuentById", collectionName, documentId);
  const docRef = doc(db, collectionName, documentId);
  const docSnap = getDoc(docRef);
  return docSnap;
}

/*
// Fetch item by item (hook)
export async function getItem(itemId) {
  const docRef = doc(db, "items", itemId);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    console.log("Document data:", docSnap.data());
    return docSnap.data();
  } else {
    // doc.data() will be undefined in this case
    console.log("No such document!");
  }


}
*/

// Get Lot and Item by Id.
export function getItemLotById(itemId) {
  const itemRef = doc(db, "items", itemId);
  const lotRef = doc(db, "lots", itemId);

  const docSnap = getDoc(itemRef);

  return docSnap
    .then((doc) => {
      const lotSnap = getDoc(lotRef);
      return lotSnap
        .then((lotDoc) => {
          return { lot: lotDoc.data(), item: doc.data() };
        })
        .catch((error) => {
          console.log("db lot error", error);
        });
    })
    .catch((error) => {
      console.log("db item error", error);
    });

  //return docSnap;
}

// Fetch lot by item (hook)
export async function getPriceById(itemId) {
  const q = query(
    collection(db, "prices"),
    where("item", "==", itemId),
    orderBy("createdAt", "desc")
  );

  const querySnapshot = await getDocs(q);

  /*
querySnapshot.forEach((doc) => {
  // doc.data() is never undefined for query doc snapshots
  console.log(doc.id, " => ", doc.data());
});
*/
  return querySnapshot.docs[0].data();
}

// Fetch lot by item (hook)

export async function getAuction(eventId) {
  const itemRef = doc(db, "auctions", eventId);
  const docSnap = await getDoc(itemRef);

  return docSnap.data();
}


export async function getProfile(userId) {
  const itemRef = doc(db, "profiles", userId);
  const docSnap = await getDoc(itemRef);

  return docSnap.data();
}

export async function getLot(id) {
  const ref = doc(db, "lots", id);
  const docSnap = await getDoc(ref);

  return docSnap.data();
}

export async function getEvent(id) {
  const ref = doc(db, "events", id);
  const docSnap = await getDoc(ref);

  return docSnap.data();
}

export async function getItem(id) {
  const ref = doc(db, "items", id);
  const docSnap = await getDoc(ref);

  return docSnap.data();
}

export async function getUserItem(userId, itemId) {

  const ref = doc(db, "users", userId, "items", itemId);

  const docSnap = await getDoc(ref);
  return docSnap.data();


}



export async function getAllItems() {
  try {
      const itemsRef = collection(db, 'items');
      console.log('itemsRef', itemsRef);
      const q = query(itemsRef, where("listingType", "==", "marketplace_price"), where("accepted", "==", true));
      console.log('q', q);
      const snapshot = await getDocs(q);
      console.log('snapshot', snapshot);
      const items = snapshot.docs.map(doc => ({...doc.data(), id: doc.id}));
      return items;
  } catch (error) {
      console.error('Error getting items:', error);
  }
}

// Function to get limited items
export async function getLimitedItems(limitValue = null) {
  try {
    const itemsRef = collection(db, 'items');
    console.log('itemsRef', itemsRef);

    let q;
    
    if (limitValue !== null) {
      q = query(itemsRef, where("listingType", "==", "marketplace_price"), where("accepted", "==", true), limit(limitValue));
    } else {
      q = query(itemsRef, where("listingType", "==", "marketplace_price"), where("accepted", "==", true));
    }
    
    console.log('q', q);
    const snapshot = await getDocs(q);
    console.log('snapshot', snapshot);
    const items = snapshot.docs.map(doc => ({...doc.data(), id: doc.id}));
    return items;
  } catch (error) {
    console.error('Error getting items:', error);
  }
}




export function useHydratedItems(items) {


const [response, setResponse] = useState({data:null, status:'loading', error:null});

useEffect(() =>{

const hydratedItems = items.map((item) =>{

return getHydratedItem(item.id);

})

setResponse({data:hydratedItems, status:'success', error:null});

}, [items]);
return response;

}

export async function getHydratedItem(itemId) {
  const item = await getItem(itemId);
  const lot = await getLot(itemId);
  const event = await getEvent(item.event);
  const userItem = await getUserItem(item.owner,itemId);

  const hydratedItem = {id:itemId, item:item, lot:lot, event:event, userItem:userItem}
console.log("db getHydratedItem", hydratedItem);
  return hydratedItem;
}



export function hydrateItem(itemId) {

  return getItem(itemId).then((item)=>{

   return getLot(itemId).then((lot)=>{

   return getEvent(itemId).then((event)=>{

   return getUserItem(item.owner, itemId).then((userItem)=>{

  return {id:itemId, item:item, lot:lot, event:event, userItem:userItem}


  }).catch((getUserItemError)=>{console.error("hydrateItem getUserItemError",getUserItemError);});


  }).catch((getEventError)=>{console.error("hydrateItem getEventError",getEventError);});


  }).catch((getLotError)=>{console.error("hydrateItem getLotError",getLotError);});



  }).catch((getItemError)=>{console.error("hydrateItem getItemError",getItemError);});

}





export async function getLotsByCollection(collectionId) {
  const q = query(
    collection(db, "lots"),
    where("auction", "==", collectionId)
    //    orderBy("createdAt", "desc")
  );

  const querySnapshot = await getDocs(q);

  /*
querySnapshot.forEach((doc) => {
  // doc.data() is never undefined for query doc snapshots
  console.log(doc.id, " => ", doc.data());
});
*/
  const lots = querySnapshot.docs.map((doc) => {
    return { ...doc.data(), id: doc.id };
  });

  return lots;
}

export async function getItemsByCollection(collectionId) {
  const q = query(
    collection(db, "items"),
    where("auction", "==", collectionId)
    //    orderBy("createdAt", "desc")
  );

  const querySnapshot = await getDocs(q);

  /*
querySnapshot.forEach((doc) => {
  // doc.data() is never undefined for query doc snapshots
  console.log(doc.id, " => ", doc.data());
});
*/
  const items = querySnapshot.docs.map((doc) => {
    return { ...doc.data(), id: doc.id };
  });

  return items;
}

// Create a new lot
export function createLot(data) {
  const id = data.item;

  // Stamp item creation with a unique alpha key and createdAt timestamp
  const nanoid = customAlphabet("ABCDEFGHIJKLMNOPQRSTUVXYZ", 4);
  const lotShortRef = "lot_" + nanoid();

  const lotRef = doc(db, "lots", id);
  return setDoc(lotRef, {
    ...data,
    lotRef: lotShortRef,
    createdAt: serverTimestamp(),
  });
}

// Update an lot
export function updateLot(id, data) {
  // July 28, 2021. Testing merge true to create item if does not exist.

  const lotRef = doc(db, "lots", id);
  return setDoc(lotRef, data, { merge: true });
}

// Delete a lot
// export function deleteLot(id) {
//   return db.collection("lots").doc(id);
// }


export async function deleteLot(id) {
  // const db = getFirestore();
  try {
    console.log("delete lot id", id)
    await deleteDoc(doc(db, "lots", id));

  } catch (error) {
    console.error("Error deleting document: ", error);
  }
}



// Remove lots and auction associated with a auction.
export function deleteLotsByAuction(auction) {
  db.collection("lots")
    .where("auction", "==", auction)
    .get()
    .then(function (querySnapshot) {
      querySnapshot.forEach(function (doc) {
        doc.ref.delete();

        // THIS IS NOT YET WORKING

        const datagram = {
          subject: "Auction deleted.",
          item: doc.data().item,
          from: "auctioneer",
          to: "auctioneer",
        };
      });
      deleteAuction(auction);
    });
}

// Create a new message
export function createMessage(datagram, userID) {
  
  console.log('Debug inside createMessage function, input data:', datagram);
  if (userID === undefined) {
    userID = datagram.from;
  }



  const messageRef = collection(db, "users", userID, "messages");
 
  return addDoc(messageRef, {
    ...datagram,
    flerp: "flerp",
    clientCreatedAt: clientZuluTimestamp(),
    createdAt: serverTimestamp(),
  });
}

// Create a new message
export function updateUserItem(item) {
  /*
  return db
    .collection("users")
    .doc(item.owner)
    .collection("items")
    .doc(item.id)
    .set({
      //return firestore.collection("messages").doc(datagram.from).collection("userMessages").doc().set({
      ...item,
      updatedAt: serverTimestamp(),
    });
*/
  const itemRef = doc(db, "users", item.owner, "items", item.id);
  return setDoc(itemRef, {
    ...item,
    updatedAt: serverTimestamp(),
  });
}

// Fetch lot by item (hook)
export function useItemByUser(item) {
  //if (item === undefined) {return;}
  const itemId = item && item.id ? item.id : uuidv4();
  return useQuery(
    // Unique query key: https://react-query.tanstack.com/guides/query-keys
    ["item", "user", { itemId }],
    // Query function that subscribes to data and auto-updates the query cache
    createQuery(() => doc(db, "users", item.owner, "items", item.id)),
    // Only call query function if we have a `uid`
    {
      enabled: !!item,
      //skip: !item,
      //staleTime: 0,
      cacheTime: 0,
    }
  );
}

// Create a new event
export function createEvent(data) {
  /*
  return db.collection("events").add({
    ...data,
    createdAt: serverTimestamp(),
  });
*/

  return addDoc(collection(db, "events"), {
    ...data,
    createdAt: serverTimestamp(),
  });


}

// Update an event
export function updateEvent(id, data) {
  const newData = { ...data, refreshedAt: serverTimestamp() };
  //  return db.collection("events").doc(id).update(newData);

  const eventRef = doc(db, "events", id);
  return updateDoc(eventRef, newData);
}

// Delete an event
export function deleteEvent(id) {
  // return db.collection("events").doc(id).delete();
  return deleteDoc(doc(db, "events", id));
}

// Fetch event data
//export function useEvent(id) {
//  return useQuery(id && firestore.collection("events").doc(id));
//}

export function useEvent(eventId) {
  //const query = useQuery(id && db.collection("events").doc(id));

  return useQuery(
    // Unique query key: https://react-query.tanstack.com/guides/query-keys
    ["event", { eventId }],
    // Query function that subscribes to data and auto-updates the query cache
    createQuery(() => doc(db, "events", eventId)),
    // Only call query function if we have a `uid`
    { enabled: !!eventId, refetchOnMount: false }
  );

  // return query;
}

// Fetch item data
export function useUncachedEvent(id) {
  return useQuery(
    ["event", "static", { id }],
    createQuery(() => doc(db, "events", id)),
    //    { enabled: !!id, skip: !id, cacheTime: 0 }
    { enabled: !!id, cacheTime: 0 }
   // { enabled: !!id, cacheTime: 0, refetchOnMount:'always' }
  );
}

/**** HELPERS ****/

// Reducer for useQuery hook state and actions
export const reducer = (state, action) => {
  switch (action.type) {
    case "idle":
      return { status: "idle", data: undefined, error: undefined };
    case "loading":
      return { status: "loading", data: undefined, error: undefined };
    case "success":
      return { status: "success", data: action.payload, error: undefined };
    case "error":
      return { status: "error", data: undefined, error: action.payload };
    default:
      throw new Error("invalid action");
  }
};

// Using useQuery as base.
function useStorage(query) {
  // Our initial state
  // Start with an "idle" status if query is falsy, as that means hook consumer is
  // waiting on required data before creating the query object.
  // Example: useQuery(uid && firestore.collection("profiles").doc(uid))
  const initialState = {
    status: query ? "loading" : "idle",
    data: undefined,
    error: undefined,
  };

  // Setup our state and actions
  const [state, dispatch] = useReducer(reducer, initialState);

  // Gives us previous query object if query is the same, ensuring
  // we don't trigger useEffect on every render due to query technically
  // being a new object reference on every render.

  // Passing in a promise from Firebase Storage.
  // Develop without caching. As useMemoCompare uses filestore isEqual.

  /*
  const queryCached = useMemoCompare(query, (prevQuery) => {
    // Use built-in Firestore isEqual method to determine if "equal"
    return prevQuery && query && query.isEqual(prevQuery);
  });
*/

  useEffect(() => {
    // Return early if query is falsy and reset to "idle" status in case
    // we're coming from "success" or "error" status due to query change.
    //  if (!queryCached) {
    //    dispatch({ type: "idle" });
    //    return;
    //  }
    if (query === undefined) {
      return;
    }

    // Template code to identify if promise is resolved.
    // Untested.
    var done = false;
    var retVal = false;

    if (!query) {
      query
        .catch(() => {})
        .then(() => {
          done = true;
        });
      retVal = true;
    }

    if (done) {
      query
        .catch(() => {})
        .then(() => {
          done = true;
        });
      retVal = true;
    }

    // Test retVal to assess promise state.
    // Untested.

    if (retVal === false) {
      dispatch({ type: "loading" });
      return;
    }
    if (retVal === true) {
      // Get value of promise.
      // Not sure if this is the way.
      // Not tested.
      query.then((response) => {
        dispatch({ type: "success", payload: response });
      });
    }

    // Not cached. How to test for actionable changes in the Firebase Storage promise.
  }, [query]); // Only run effect if queryCached changes

  return state;
}

// Custom React hook that subscribes to a Firestore query
function useDepricatedQuery(query) {
  // Our initial state
  // Start with an "idle" status if query is falsy, as that means hook consumer is
  // waiting on required data before creating the query object.
  // Example: useQuery(uid && firestore.collection("profiles").doc(uid))
  const initialState = {
    status: query ? "loading" : "idle",
    data: undefined,
    error: undefined,
  };

  // Setup our state and actions
  const [state, dispatch] = useReducer(reducer, initialState);

  // Gives us previous query object if query is the same, ensuring
  // we don't trigger useEffect on every render due to query technically
  // being a new object reference on every render.
  const queryCached = useMemoCompare(query, (prevQuery) => {
    // Use built-in Firestore isEqual method to determine if "equal"
    return prevQuery && query && query.isEqual(prevQuery);
  });

  useEffect(() => {
    // Return early if query is falsy and reset to "idle" status in case
    // we're coming from "success" or "error" status due to query change.
    if (!queryCached) {
      dispatch({ type: "idle" });
      return;
    }

    dispatch({ type: "loading" });

    // Subscribe to query with onSnapshot
    // Will unsubscribe on cleanup since this returns an unsubscribe function
    return queryCached.onSnapshot(
      (response) => {
        // Get data for collection or doc
        // console.log('getDocData response.docs', getDocData(response));
        const data = response.docs
          ? getCollectionData(response)
          : getDocData(response);

        dispatch({ type: "success", payload: data });
      },
      (error) => {
        //console.log(error)
        dispatch({ type: "error", payload: error });
      }
    );
  }, [queryCached]); // Only run effect if queryCached changes

  return state;
}

// Get doc data and merge doc.id
function getDocData(doc) {
  return doc.exists === true ? { id: doc.id, ...doc.data() } : null;
}

// Get array of doc data from collection
function getCollectionData(collection) {
  return collection.docs.map(getDocData);
}

// Used by useQuery to store Firestore query object reference
export function useMemoCompare(next, compare) {
  // Ref for storing previous value
  const previousRef = useRef();
  const previous = previousRef.current;

  // Pass previous and next value to compare function
  // to determine whether to consider them equal.
  const isEqual = compare(previous, next);

  // If not equal update previousRef to next value.
  // We only update if not equal so that this hook continues to return
  // the same old value if compare keeps returning true.
  useEffect(() => {
    if (!isEqual) {
      previousRef.current = next;
    }
  });

  // Finally, if equal then return the previous value
  return isEqual ? previous : next;
}

// Index on ImageUploads EditItem Modal
export function useAsyncState(initialValue) {
  const [value, setValue] = useState(initialValue);
  const setter = (x) =>
    new Promise((resolve) => {
      setValue(x);
      resolve(x);
    });
  return [value, setter];
}

//Get Server Time
/*
export function useFirebaseCurrentTime() {

  var offsetRef = rtdb.ref(".info/serverTimeOffset");
  offsetRef.once("value", (snap) => {
    var offset = snap.val();
    var estimatedServerTimeMs = new Date().getTime() + offset;
  });


}
*/

export function useFirebaseCurrentTime() {
  /*
  const initialState = {
    status: "loading",
    data: undefined,
    error: undefined,
  };
*/

  // Setup our state and actions
  //const [state, dispatch] = useReducer(reducer, initialState);

  const timestampRef = doc(db, "timestamp", "time");
  return (
    updateDoc(timestampRef, {
      timestamp: serverTimestamp(),
    })
      //   return db.collection('timestamp').doc('time').update({
      //      "timestamp": serverTimestamp(),
      //    })
      .then((response) => {
        db.collection("timestamp")
          .doc("time")
          .get()
          .then((doc) => {
            if (doc.exists) {
              return doc.data();

              // dispatch({ type: "success", payload: doc.data() });
            } else {
              return false;
            }
          })
          .catch((error) => {
            //console.log("Error getting document:", error);
            return error;
          });
      })
  );
}

// Gabes new stuff

/**** HELPERS ****/

// Store Firestore unsubscribe functions
const unsubs = {};

function createQuery(getRef) {
  // Create a query function to pass to `useQuery`
  return async ({ queryKey }) => {
    let unsubscribe;
    let firstRun = true;
    // Wrap `onSnapshot` with a promise so that we can return initial data
    const data = await new Promise((resolve, reject) => {
      unsubscribe = onSnapshot(
        getRef(),
        // Success handler resolves the promise on the first run.
        // For subsequent runs we manually update the React Query cache.
        (response) => {
          const data = format(response);
          if (firstRun) {
            firstRun = false;
            resolve(data);
          } else {
            client.setQueryData(queryKey, data);
          }
        },
        // Error handler rejects the promise on the first run.
        // We can't manually trigger an error in React Query, so on a subsequent runs we
        // invalidate the query so that it re-fetches and rejects if error persists.
        (error) => {
          if (firstRun) {
            firstRun = false;
            reject(error);
          } else {
            client.invalidateQueries(queryKey);
          }
        }
      );
    });

    // Unsubscribe from an existing subscription for this `queryKey` if one exists
    // Then store `unsubscribe` function so it can be called later
    const queryHash = hashQueryKey(queryKey);
    unsubs[queryHash] && unsubs[queryHash]();
    unsubs[queryHash] = unsubscribe;

    return data;
  };
}

// Automatically remove Firestore subscriptions when all observing components have unmounted
client.queryCache.subscribe(({ type, query }) => {
  if (
    type === "observerRemoved" &&
    query.getObserversCount() === 0 &&
    unsubs[query.queryHash]
  ) {
    // Call stored Firestore unsubscribe function
    unsubs[query.queryHash]();
    delete unsubs[query.queryHash];
  }
});

// Format Firestore response
function format(response) {
  // Converts doc into object that contains data and `doc.id`
  const formatDoc = (doc) => ({ id: doc.id, ...doc.data() });
  if (response.docs) {
    // Handle a collection of docs
    return response.docs.map(formatDoc);
  } else {
    // Handle a single doc
    return response.exists() ? formatDoc(response) : null;
  }
}

// React Query context provider that wraps our app
export function QueryClientProvider(props) {
  return (
    <QueryClientProviderBase client={client}>
      {props.children}
    </QueryClientProviderBase>
  );
}


export function makeObservable(target) {
  let listeners = []; // initial listeners can be passed an an argument aswell
  let value = target;

  function get() {
    return value;
  }

  function set(newValue) {
    if (value === newValue) return;
    value = newValue;
    listeners.forEach((l) => l(value));
  }

  function subscribe(listenerFunc) {
    listeners.push(listenerFunc);
    return () => unsubscribe(listenerFunc); // will be used inside React.useEffect
  }

  function unsubscribe(listenerFunc) {
    listeners = listeners.filter((l) => l !== listenerFunc);
  }

  return {
    get,
    set,
    subscribe,
  };
}



export function useScrollToTop() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
}



// export function useUserName(userId) {
//   const [userName, setUserName] = useState(null);

//   useEffect(() => {
//     const db = firebase.firestore();
//     const usersCollection = db.collection("users");

//     usersCollection.doc(userId).get()
//       .then((doc) => {
//         if (doc.exists) {
//           const name = doc.data().name;
//           setUserName(name);
//         } else {
//           console.log("No user found with ID: ", userId);
//         }
//       })
//       .catch((error) => {
//         console.error(error);
//       });
//   }, [userId]);

//   return userName;
// }


