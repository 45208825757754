import AuctionsHome from "./../components/AuctionsHome";
import { useEffect, useState } from "react";
import { useRouter } from "./../util/router.js";
import { useQuery } from "react-query";
import {
  useLocation,
} from "react-router-dom";
import { Box, Container, Typography } from "@material-ui/core";
import Section from "../components/Section";
import Meta from "./../components/Meta";
import MarketplaceHome from "../components/MarketplaceHome";
import Search from "../components/Search";
import NoSearchResults from "../components/NoSearchResults";

function MarketplacePage(props) {
  const router = useRouter();
  const path = router.query.path;


  const [searchQuery, setSearchQuery] = useState();
  const location = useLocation();
  const { pathname, hash, key } = location;

  return (
    <>
   <Meta 
title="LadyBidwell - Local Marketplace"
/>

<Section style={{minHeight:'100vh', paddingTop:'0px'}}>





<Container >

      <Box py={3} textAlign="left">
        <Typography variant="h4"  gutterBottom>
          <div>
           Marketplace
          
          </div>
        </Typography>


        <Typography variant="subtitle1" gutterBottom>
        Explore unique and interesting pieces with LadyBidwell's local curated marketplace in the Hamptons. We offer daily fresh selections from the area's top estate sales, artisans, and verified professional sellers. Our eclectic range spans antique treasures to modern art and bespoke handmade items. With LadyBidwell, enjoy your favorite sellers while continually discovering new ones.
        </Typography>






      </Box>
    </Container>




<MarketplaceHome

/>

</Section>



    </>
  );
}

export default MarketplacePage;
