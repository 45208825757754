import React from "react";
import { Typography } from "@material-ui/core";

function Logo(props) {
  const { flavour } = props;
  /*
functions and variables
*/

  if (flavour === "fulllogo") {
    return (
      <>
        <Typography
          color="primary"
          style={{
            fontSize: "2.5em",

            fontFamily: '"Cormorant Garamond", "serif"',
            letterSpacing: "-1px",
            opacity: 1,
          }}
        >
          <span style={{ fontStyle: "italic", fontWeight: "300" }}> lady</span>
          <span style={{ fontWeight: "600", letterSpacing: "-2px" }}>
            bidwell
          </span>
          {/* <span style={{fontSize:'.75em'}}> */}

          {/* </span> */}
        </Typography>
      </>
    );
  }

  if (flavour === "mobile") {
    return (
      <>
        <Typography
          color="primary"
          style={{
            fontSize: "2.2em",

            fontFamily: '"Cormorant Garamond", "serif"',
            letterSpacing: "-1px",
            opacity: 1,
          }}
        >
          <span style={{ fontStyle: "italic", fontWeight: "300" }}> lady</span>
          <span style={{ fontWeight: "600", letterSpacing: "-2px" }}>
            bidwell
          </span>
          {/* <span style={{fontSize:'.75em'}}> */}

          {/* </span> */}
        </Typography>
      </>
    );
  }

  return (
    <>
      <Typography
        color="primary"
        style={{
          fontSize: "2.5em",

          fontFamily: '"Cormorant Garamond", "serif"',
          letterSpacing: "-1px",
          // textAlign: "left",
          // position: "absolute",
          // top: "50%",
          // left: "50%",
          // transform: "translate(-50%, -50%)",
        }}
      >
        <span style={{ fontStyle: "italic", fontWeight: "600" }}>lady</span>
        <span style={{ fontWeight: "600" }}>bidwell</span>
      </Typography>
    </>
  );

  /*

You always need this final block ...
For when there is no flavour to add.

*/
}

export default Logo;
