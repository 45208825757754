import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

import Switch from "@material-ui/core/Switch";

import { useAuth } from "./../util/auth.js";
import { updateUser } from "./../util/db.js";
import { getFunctions, httpsCallable } from "firebase/functions";

import { useForm } from "react-hook-form";

const functions = getFunctions();

const processDeveloperTest = httpsCallable(functions, "processDeveloperTest");
const processDeveloperTest2 = httpsCallable(functions, "processDeveloperTest2");
const processDeveloperTest3 = httpsCallable(functions, "processDeveloperTest3"); // Find affiliate for a sale
const processNotifications = httpsCallable(functions, "processNotifications"); // Find affiliate for a sale
const processRemoveNotificationsByItemUser = httpsCallable(functions, "processRemoveNotificationsByItemUser"); // Find affiliate for a sale

const processSendNotification = httpsCallable(functions, "processSendNotification"); // Find affiliate for a sale

//// FOR NICK TO BUILD LATER

// exports.removeOrphanedNotificationsByLotUser = functions.https.onCall(
//   (data, context) => {
//     //  const datagram = { ...data, to: "administrator" };
//     const uid = context.auth.uid;
//     var lot = null;
//     if (data && data.lot) {
//       lot = data.lot;
//     }



// This can be moved to affiliate tab ... eventually

const processCreateAffiliateId = httpsCallable(
  functions,
  "processCreateAffiliateId"
);

function SettingsDeveloper(props) {
  const auth = useAuth();

  const isDeveloper = auth?.user?.developer?.enabled;

  const [pending, setPending] = useState(false);

  const [message, setMessage] = useState();

  const [affiliateMessage, setAffiliateMessage] = useState();

  const [sendNotificationMessage, setSendNotificationMessage] = useState();

  const [sendNotificationsMessage, setSendNotificationsMessage] = useState();
  const [createAffiliateMessage, setCreateAffiliateMessage] = useState();

const [removeNotificationsByItemUserMessage, setRemoveNotificationsByItemUserMessage] = useState();

  const [attributeAffiliationMessage, setAttributeAffiliationMessage] =
    useState();

  const { register, handleSubmit, errors, reset, getValues } = useForm();

  const handleChange = (data) => {
    if (data === "developer.debug") {
      console.log(
        "SettingsDeveloper handleChange data",
        data,
        auth.user.developer,
        auth.user.id
      );

      var newDebugState = true;
      if (auth.user.developer && auth.user.developer.debug) {
        newDebugState = !auth.user.developer.debug;
      }

      updateUser(auth.user.id, {
        developer: { debug: newDebugState },
      })
        .then((result) => {
          console.log("SettingsDeveloper updateUser result", result);
        })
        .catch((updateUserError) => {
          console.error("SettingsDeveloper debug error", updateUserError);
        });
    }

    return;
  };
  function handleButtonClick() {
    processDeveloperTest({
      itemIds: [
        "reMtLVsupHqQJTl6J1WP",
        "bQGTHkySXHoWvvA7sDdK",
        "KqTKLvP69tEoTlkc9aZx",
        "uQOQbsRutZ9cfzYL0xmv",
      ],
    })
      .then((result) => {
        console.log("SettingsDeveloper result", result);

        if (result !== undefined) {
          console.log("SettingsDeveloper result", result);

          if (result.data !== undefined) {
            console.log("SettingsDeveloper result.data", result.data);

            if (result.data.data !== undefined) {
              console.log(
                "SettingsDeveloper result.data.data",
                result.data.data
              );

              if (result.data.data.message) {
                setMessage(result.data.data.message);
              }
            }
          }
        }
      })
      .catch((error) => {
        console.log("SettingsDeveloper error", error);

        if (error.message) {
          setMessage(error.message);
        }
      });
  }



  function handleAffiliateButtonClick() {
    const datagram = {
      to: "administrator",
      subject: "merp",
      text: "merp",
      item: "IAWfJllSq8WBGc89pX6H",
    };

    console.log("SettingsDeveloper handleAffiliateButtonClick");
    processDeveloperTest2(datagram)
      .then((result) => {
        console.log("SettingsDeveloper affiliate result", result);

        if (result !== undefined) {
          console.log("SettingsDeveloper affiliate result", result);

          if (result.data !== undefined) {
            console.log("SettingsDeveloper affiliate result.data", result.data);

            if (result.data.data !== undefined) {
              console.log(
                "SettingsDeveloper result.data.data",
                result.data.data
              );

              if (result.data.data.message) {
                setAffiliateMessage(result.data.data.message);
              }
            }
          }
        }
      })
      .catch((error) => {
        console.log("SettingsDeveloper affilate error", error);

        if (error.message) {
          setAffiliateMessage(error.message);
        }
      });
  }

//handleSendNotificationButtonClick()
// processRemoveOrphanedNotificationsByItemUser
function handleSendNotificationButtonClick() {
     const datagram = {
      to: "administrator",
      subject: "merp",
      text: "merp",
//      item: "IAWfJllSq8WBGc89pX6H",
//item: "kQ6AHQpVs9z8wzduHoZm",
    };

    console.log("SettingsDeveloper handleRemoveNotificationsClick");
    processSendNotification(datagram)
      .then((result) => {
        console.log("SettingsDeveloper removeNotifications result", result);

        if (result !== undefined) {
          console.log("SettingsDeveloper removeNotifications result", result);

          if (result.data !== undefined) {
            console.log("SettingsDeveloper removeNotifications result.data", result.data);

            if (result.data.data !== undefined) {
              console.log(
                "SettingsDeveloper removeNotifications result.data.data",
                result.data.data
              );

              if (result.data.data.message) {
                setSendNotificationMessage(result.data.data.message);
              }
            }
          }
        }
      })
      .catch((error) => {
        console.log("SettingsDeveloper removeNotifications error", error);

        if (error.message) {
          setSendNotificationMessage(error.message);
        }
      });
  }







// processRemoveOrphanedNotificationsByItemUser
function handleRemoveNotificationsByItemUserButtonClick() {
     const datagram = {
      to: "administrator",
      subject: "merp",
      text: "merp",
//      item: "IAWfJllSq8WBGc89pX6H",
item: "kQ6AHQpVs9z8wzduHoZm",
    };

    console.log("SettingsDeveloper handleRemoveNotificationsClick");
    processRemoveNotificationsByItemUser(datagram)
      .then((result) => {
        console.log("SettingsDeveloper removeNotifications result", result);

        if (result !== undefined) {
          console.log("SettingsDeveloper removeNotifications result", result);

          if (result.data !== undefined) {
            console.log("SettingsDeveloper removeNotifications result.data", result.data);

            if (result.data.data !== undefined) {
              console.log(
                "SettingsDeveloper removeNotifications result.data.data",
                result.data.data
              );

              if (result.data.data.message) {
                setRemoveNotificationsByItemUserMessage(result.data.data.message);
              }
            }
          }
        }
      })
      .catch((error) => {
        console.log("SettingsDeveloper removeNotifications error", error);

        if (error.message) {
          setRemoveNotificationsByItemUserMessage(error.message);
        }
      });
  }





  function handleSendNotificationsButtonClick() {
 
    console.log("SettingsDeveloper handleSendNotificationsClick");
    processNotifications()
      .then((result) => {
        console.log("SettingsDeveloper notifications result", result);

        if (result !== undefined) {
          console.log("SettingsDeveloper notifications result", result);

          if (result.data !== undefined) {
            console.log("SettingsDeveloper notifications result.data", result.data);

            if (result.data.data !== undefined) {
              console.log(
                "SettingsDeveloper result.data.data",
                result.data.data
              );

              if (result.data.data.message) {
                setSendNotificationsMessage(result.data.data.message);
              }
            }
          }
        }
      })
      .catch((error) => {
        console.log("SettingsDeveloper notifications error", error);

        if (error.message) {
          setSendNotificationsMessage(error.message);
        }
      });
  }


  function handleAffiliateButtonClick() {
    const datagram = {
      to: "administrator",
      subject: "merp",
      text: "merp",
      item: "IAWfJllSq8WBGc89pX6H",
    };

    console.log("SettingsDeveloper handleAffiliateButtonClick");
    processDeveloperTest2(datagram)
      .then((result) => {
        console.log("SettingsDeveloper affiliate result", result);

        if (result !== undefined) {
          console.log("SettingsDeveloper affiliate result", result);

          if (result.data !== undefined) {
            console.log("SettingsDeveloper affiliate result.data", result.data);

            if (result.data.data !== undefined) {
              console.log(
                "SettingsDeveloper result.data.data",
                result.data.data
              );

              if (result.data.data.message) {
                setAffiliateMessage(result.data.data.message);
              }
            }
          }
        }
      })
      .catch((error) => {
        console.log("SettingsDeveloper affilate error", error);

        if (error.message) {
          setAffiliateMessage(error.message);
        }
      });
  }

  function handleAttributeAffiliationButtonClick() {
    const datagram = {
      to: "administrator",
      subject: "merp",
      text: "merp",
      //      item: "IAWfJllSq8WBGc89pX6H",
      //bidOwner: "4AcXG3MlojbgNrF0lYnNpPAlQYg1",
      bidOwner: auth.user.id,
    };

    console.log("SettingsDeveloper handleAffiliateButtonClick");
    processDeveloperTest3(datagram)
      .then((result) => {
        console.log("SettingsDeveloper affiliate result", result);

        if (result !== undefined) {
          console.log("SettingsDeveloper affiliate result", result);

          if (result.data !== undefined) {
            console.log("SettingsDeveloper affiliate result.data", result.data);

            if (result.data.data !== undefined) {
              console.log(
                "SettingsDeveloper result.data.data",
                result.data.data
              );

              if (result.data.data.message) {
                setAttributeAffiliationMessage(result.data.data.message);
              }
            }
          }
        }
      })
      .catch((error) => {
        console.log("SettingsDeveloper affilate error", error);

        if (error.message) {
          setAttributeAffiliationMessage(error.message);
        }
      });
  }

  function handleCreateAffiliateButtonClick() {
    const inputTestAffiliateId = "MERP9";
    const affiliateId = inputTestAffiliateId.toLowerCase();

    const datagram = {
      to: "administrator",
      subject: "merp",
      text: "merp",
      affiliateId: affiliateId,
    };

    console.log("SettingsDeveloper handleCreateAffiliateButtonClick");
    processCreateAffiliateId(datagram)
      .then((result) => {
        console.log("SettingsDeveloper createAffiliate result", result);

        if (result !== undefined) {
          console.log("SettingsDeveloper createAffiliate result", result);

          if (result.data !== undefined) {
            console.log(
              "SettingsDeveloper createAffiliate result.data",
              result.data
            );

            if (result.data.error !== undefined) {
              setCreateAffiliateMessage(result.data.error.message);
              return;
            }

            setCreateAffiliateMessage("Test OK");
          }
        }
      })
      .catch((error) => {
        console.log("SettingsDeveloper createAffilate error", error);

        if (error.message) {
          setCreateAffiliateMessage(error.message);
        }
      });
  }

  useEffect(() => {
    console.log("SettingsGeneral auth.user", auth.user);
  }, [auth && auth.user]);

  //return (<>HEY</>)
  return (
    <>
      {isDeveloper && (
        <>
          <Switch
            defaultChecked={
              auth.user && auth.user.developer && auth.user.developer.debug
            }
            onChange={(e) => handleChange("developer.debug", e)}
            name="dev-debug"
            //inputProps={{ "aria-label": "secondary checkbox" }}
            //inputRef={register()}
          />{" "}
          SHOW DEBUG REPORTS
        </>
      )}

      <Grid item={true} xs={12}>
        <br />
        BACK END TEST. This calls for a test order confirmation to be sent to the current user's email.  A set of four hard coded items are used for consistency. Consider: better selection of test set.
        <br />
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={() => handleButtonClick()}
          //type="submit"
          //disabled={pending}
          //fullWidth={true}
        >
          Test Order Event
        </Button>
      </Grid>
      {message}

      <Grid item={true} xs={12}>
        <br />
        BACK END TEST. This calls for affiliate events related to the current
        user for a hard-coded item id. It is a test of the back-end code which
        will be called on the uid and itemId when an item is bought. See console
        for SettingsDeveloper affiliate.
        <br />
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={() => handleAffiliateButtonClick()}
          //type="submit"
          //disabled={pending}
          //fullWidth={true}
        >
          Test Affiliate Event
        </Button>
      </Grid>
      {affiliateMessage}

      <Grid item={true} xs={12}>
        <br />
        BACK END TEST. This calls for affiliate events related to a for your
        user ID {auth.user.id}. It is a test of the back-end code which will be
        called on bidOwner user id when an item has bought. See console for
        SettingsDeveloper.
        <br />
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={() => handleAttributeAffiliationButtonClick()}
          //type="submit"
          //disabled={pending}
          //fullWidth={true}
        >
          Test Attribute Affiliation Event
        </Button>
      </Grid>
      {attributeAffiliationMessage}


      <Grid item={true} xs={12}>
        <br />
        BACK END TEST. Send notification early to current user.

        <br />
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={() => handleSendNotificationButtonClick()}
          //type="submit"
          //disabled={pending}
          //fullWidth={true}
          disabled={false}
        >
          Test Send Notification Event
        </Button>
      </Grid>
      {sendNotificationMessage}



      <Grid item={true} xs={12}>
        <br />
        BACK END TEST. Send notifications.

        <br />
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={() => handleSendNotificationsButtonClick()}
          //type="submit"
          //disabled={pending}
          //fullWidth={true}
          // disabled={true}
        >
          Test Send Notifications Event
        </Button>
      </Grid>
      {sendNotificationsMessage}

      <Grid item={true} xs={12}>
        <br />
        BACK END TEST. Remove all notifications from a hardcoded item for the current user.

        <br />
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={() => handleRemoveNotificationsByItemUserButtonClick()}
          //type="submit"
          //disabled={pending}
          //fullWidth={true}
          disabled={false}
        >
          Test remove notifications for a specific item and user event
        </Button>
      </Grid>
      {removeNotificationsByItemUserMessage}



{/*
      <Grid item={true} xs={12}>
        <br />
        FRONT END TEST. This creates an affiliate Code (Id) where one does not
        exist. Returning an error if already found. Need to decide format of
        affiliate codes. And generation algorithm.
        <br />
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={() => handleCreateAffiliateButtonClick()}
          //type="submit"
          //disabled={pending}
          //fullWidth={true}
        >
          Test Create Affiliate Event
        </Button>
      </Grid>
      {createAffiliateMessage}
*/}
    </>
  );
}

export default SettingsDeveloper;
