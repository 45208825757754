import React from "react";
import Section from "./Section";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import BlockIcon from '@material-ui/icons/Block';
import RedeemIcon from '@material-ui/icons/Redeem';

const useStyles = makeStyles((theme) => ({
  // Increase <Container> padding so it's
  // at least half of <Grid> spacing to
  // avoid horizontal scroll on mobile.
  // See https://material-ui.com/components/grid/#negative-margin
  container: {
    padding: `0 ${theme.spacing(4)}px`,
  },
  title1: {
    fontFamily: '"Cormorant Garamond", "serif"',
    textAlign: 'center',
    // fontWeight:'600',
    // marginBottom: '1em',
  },
  title: {
    fontFamily: '"Cormorant Garamond", "serif"',
    
  },
}));

function WhyJoinTrade(props) {
  const classes = useStyles();

  const items = [
    {
      title: "Tax Exemption",
      subtitle:
        "When approved we will automatically apply tax exemption to your purchases. Please note this can not be applied to past purchases.",
      icon: BlockIcon,
      iconColor: "primary.main",
    },
    {
      title: "Affiliate Commision (coming soon)",
      subtitle:
        "When a buyer purchases an item from a link you post on social media, an email list or wherever really, you will get a 2.5% of the total sale price. It's as simple as that. Learn more here.",
      icon: AttachMoneyIcon,
      iconColor: "primary.main",
    },
    {
      title: "Surprises",
      subtitle:
        "Everyone loves a little surprise pressie. So every month, we will send one to some of our members. It our way of saying thanks. Who knows what it might be (that's the surprise bit), but hint hint, we have sent everything from discount codes to furniture ;)",
      icon: RedeemIcon,
      iconColor: "primary.main",
    },

    // {
    //     title: "Auction Local",
    //     subtitle:
    //       "Buy Local, Eat Local and well you heard it here first, Auction Local. All our auctions are hyper-local, which means selling to a bevy of motivated local buyers, who are ready and willing to pick-up (unless you negotiated shipping with them)",
    //     icon: Brightness4Icon,
    //     iconColor: "#00d1b2",
    //   },

    // {
    //     title: "No Hassle",
    //     subtitle:
    //       "Dark mode is so last year. We have a light mode and turquoise mode. Either way, your eyes are going to hurt. Don't forget to stay charged!",
    //     icon: Brightness4Icon,
    //     iconColor: "#00d1b2",
    //   },
  ];

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container className={classes.container} >
      <Grid item={true} xs={12} md={12}>
      <Typography variant='h3'  className={classes.title1} >Why Join the Trade Program?</Typography>
    </Grid>
    <Box mt={6}>
        <Grid container={true} justify="center" spacing={4} >
       
          {items.map((item, index) => (
            <Grid item={true} xs={12} md={4} key={index}>
              <Box textAlign="center">
                <Box color={item.iconColor} fontSize="2rem">
                  <item.icon fontSize="inherit" />
                </Box>
                <Typography variant="h4"  className={classes.title} gutterBottom={true}>
                  {item.title}
                </Typography>
                <Box mt={1}>
                  <Typography variant="body1">{item.subtitle}</Typography>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
        </Box>
      
      </Container>

    </Section>
  );
}

export default WhyJoinTrade;
