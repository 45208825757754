import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useEvent, useProfile } from "./../util/db.js";
import SellerBadge from "./SellerBadge.js";
import EventItemsGrid from "./EventItemsGrid.js";
import {
  Box,
} from "@material-ui/core";
import SellerWeeklyEventDetails from "./SellerWeeklyEventDetails.js";
import { useAuth } from "./../util/auth.js";

const useStyles = makeStyles((theme) => ({
  // root: {
  //   display: "flex",

  //   flexWrap: "wrap",
  //   "& > *": {
  //     margin: theme.spacing(0.5),
  //   },
  //   [theme.breakpoints.down("sm")]: {
  //     display:'flex', alignItems:'center', textAlign:'center', justifyContent:'center'
  //   },
  // },

  root: {
    width: "fit-content",
    // border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    //backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
    "& svg": {
      margin: theme.spacing(1.5),
    },
    "& hr": {
      margin: theme.spacing(0, 1),
    },
  },

  chip: {
    color: "#ffffff",
  },

  image: {
    // backgroundImage: `url("https://cdn.pixabay.com/photo/2014/09/16/01/19/girl-447701_1280.jpg")`,
    backgroundSize: "cover",
  },
  // centerBox: {
  //   [theme.breakpoints.down("sm")]: {
  //     display:'flex', alignItems:'center', textAlign:'center', justifyContent:'center', flexDirection:'column'
  //   },
  // }
}));

export default function SellerWeeklyEvent(props) {
  const auth = useAuth();
  const isDeveloper = auth?.user?.developer?.enabled;

  const { eventId, eventIndex, flavour, auction, seller, searchQuery, items:injectItems } = props;
  const classes = useStyles();

//if (eventId == null) {

const {data:profile, status:statusProfile, error:errorProfile} = useProfile(seller);

//}

  const { data: event, status, error } = useEvent(eventId == null ? profile && profile.event : eventId);
  const [isEmpty, setIsEmpty] = useState();
  // const [isOpen, setIsOpen] = useState(false);
  const removeMd = require("remove-markdown");
  const [itemsCount, setItemsCount] = useState();



useEffect(()=>{

console.log("SellerWeeklyEvent items", injectItems);

if (injectItems == null) {return;}

if (injectItems && injectItems.length > 0) {
setIsEmpty(false);
//const c = itemsCount + injectItems.length;
//    setItemsCount(c);
//    if (props.onCardCount) {
//      props.onCardCount({ id: event.id, count: c });
//    }


}

}, [injectItems]);

useEffect(() =>{
if (profile == null) {return;}
console.log("SellerWeeklyEvent profile", profile, statusProfile, errorProfile);

}, [profile, statusProfile, errorProfile]);

useEffect(() =>{
//if (eventId == null) {return;}
console.log("SellerWeeklyEvent eventId", eventId);

}, [eventId]);

useEffect(() =>{
if (seller == null) {return;}
console.log("SellerWeeklyEvent seller", seller);

}, [seller]);


useEffect(() =>{
if (event == null) {return;}
console.log("SellerWeeklyEvent event", event);

}, [event]);



  /*
  const handleOpen = () => {
    //setIsOpen(!isOpen);
    setIsOpen(true)
  };
  */
  const hashCode = (input) => {
    var base64Input = btoa(input);
    console.log("hashCode base64Input", base64Input);

    var integerInput = getIntFromBase64String(base64Input);
    console.log("hashCode integerInput", integerInput);
    return integerInput;
  };

  function getIntFromBase64String(input) {
    //Console.WriteLine(output.Length);
    const arrayInput = input.split("");
    var rslt = 0;
    for (let i = 0; i < arrayInput.Length; i++) {
      rslt <<= 8;
      rslt += arrayInput[i];
    }
    return rslt;
  }
  function handleOnEmpty(d) {
    console.log("handleOnEmpty", d);
    setIsEmpty(d);
  }
  useEffect(() => {
    console.log("SellerWeeklyEvent event", event);
    console.log("error", error);
  }, [event, status, error]);


function handleCatchallItems(i) {
console.log("SellerWeeklyEvent catchall items", i);
// Add these to the default event.
// To display only.


if (props.onCatchall) {
//if (auth && auth.user && auth.user.event !== event.id) {
props.onCatchall(i);
//}

}



}

function handleSeller(s) {
console.log("SellerWeeklyEvent handleSeller s", s);
if (props.onSeller) {
// Make sure this event is 
//if (auth && auth.user && auth.user.event !== event.id) {

console.log("SellerWeeklyEvent handleSeller call parent");
props.onSeller(s);
//}

}


}


  function handleCardCount(c) {
    console.log("SellerWeeklyEvent handleCardCount", c, event.id);

    setItemsCount(c);
    if (props.onCardCount) {
      props.onCardCount({ id: event.id, count: c });
    }
  }

  // skeleton to be added below if needed
  if (status !== "success") {
    return <></>;
  }

  if (event && event.test === true && !isDeveloper) {
    return null;
  }
  if (seller && seller !== event.owner) {
    return null;
  }

  

  return (
    <>

      {!isEmpty &&
        status === "success" &&
        event &&
        event.slug &&
        event.slug !== "" && (
          <div style={{ position: "relative" }} id={event.slug} />
        )}

      <Box style={{ display: !isEmpty && itemsCount > 0 ? "block" : "none" }}>
        
        
        {!isEmpty && (
      <>

      {window.location.pathname === '/' && (<>
        <div
            style={{
              position:'sticky',
              top: flavour === "past" ? "0px" : "90px",
              display: "block",
              zIndex: "90",
            }}
          >
         
            <SellerWeeklyEventDetails event={event} />
          </div>
        </>)}

        {window.location.pathname !== '/' && (<>
          <div
            style={{
              position:'relative',
            }}
          >
       
          <SellerWeeklyEventDetails event={event} />
          </div>
            {/* <SellerWeeklyEventDetails event={event} /> */}
            </>
    )}

          </>
        )}

        <Box py={3} >
          {false && auth?.user?.isAdministrator && (
            <SellerBadge user={{ id: event.owner }} flavour="icon" />
          )}


          <EventItemsGrid
            key={eventId}
            onEmpty={(d) => handleOnEmpty(d)}
//            event={{...event, id: eventId, index: eventIndex }}
            event={{...event, index: eventIndex }}
            filter={{ type: "auction", id: auction.id }}
            searchQuery={searchQuery}
            onCardCount={(c) => handleCardCount(c)}
            onCatchall={(catchallItems)=>handleCatchallItems(catchallItems)}
            onSeller={(seller)=>handleSeller(seller)}
            items={injectItems}
          />
          
          </Box>
      </Box>
    </>
  );
}

