import React, { useEffect } from "react";
import AuthSection from "./../components/AuthSection";
import { useRouter } from "./../util/router.js";
import { Container } from "@material-ui/core";
import Meta from "./../components/Meta";


function AuthPage(props) {
  const router = useRouter();

  // const endpointUrl = window.location.pathname; //href gives full url


//   useEffect(() => {

// console.log("auth.js endpointUrl", endpointUrl)

//   }, [endpointUrl])

//const engineState = process.env.REACT_APP_ENGINE_STATE;
//const isPreLaunch = engineState === 'pre_launch';

//if (isPreLaunch && router.query.type === 'signup') {return null;}

  return (
    <>
<Meta 
title="Sign In"
/>


<Container maxWidth="xs"  >
    <AuthSection  
       
      bgColor="default"
      size="normal"
      //bgImage="https://images.unsplash.com/photo-1460776960860-7adc30a4e69d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1548&q=80"
      bgImageOpacity={1}
      type={router.query.type}
      providers={["google"]}
      afterAuthPath={router.query.next || "/"}

    />
</Container>
</>
  );
}

export default AuthPage;
