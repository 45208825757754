import React, { useState } from "react";
import { useAuth } from "./../util/auth.js";
import { useItemsByEvent } from "./../util/db.js";

import { requireAuth } from "./../util/auth.js";

import EventItemList from "./../components/EventItemList";

function SellerEventItemList(props) {
  const { event } = props;
  //const [dateSortItems, setDateSortItems] = useState();
  const auth = useAuth();
  //const [items, setItems] = useState();
  const [triggerItemsUpdate, setTriggerItemsUpdate] = useState();
  const {
    data: items,
    status: itemsStatus,
    error: itemsError,
    refetch,
  } = useItemsByEvent(props.event.id);

  function handleAddItem() {
    refetch();
    //setTriggerItemsUpdate(!triggerItemsUpdate)
  }

  /*
useEffect(()=>{
if (items === undefined) {return;}
  const d = sortBy(items);
  setDateSortItems(d);

},[items])
*/
  //if (dateSortItems === undefined) {return null;}
  return (
    <>
      {itemsError &&
        {
          /*     <Box mb={3}>
              <Alert severity="error">{itemsError.message}</Alert>
              </Box> */
        }}

      {true && (
     
        <EventItemList
          items={items}
          event={event}
          sortType={"date"}
          onAddItem={handleAddItem}
        />
      )}
    </>
  );
}

export default requireAuth(SellerEventItemList);
