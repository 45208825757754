import React, { useState, useEffect } from "react";
import { Typography, Card, CardContent } from "@material-ui/core";

import { getFunctions, httpsCallable } from "firebase/functions";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import LotPrice from "./LotPrice";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const functions = getFunctions();

const processBuyerInformation = httpsCallable(functions, "getBuyerInformation");

const BuyerInformation = (props) => {
  const classes = useStyles();
  const { lot, item, flavour } = props;
  const [data, setData] = useState();

  useEffect(() => {
    if (!lot) return;
    if (!lot.bidOwner) return;
    const buyerId = lot && lot.bidOwner;

    const { data: data, error: error } = processBuyerInformation({
      itemId: lot.item,
      userId: buyerId,
    })
      .then((result) => {
        console.log("BuyerInformation result", result);
        setData(result.data.data);
      })
      .catch((error) => {
        console.log("BuyerInformation error", error);
      });
  }, [lot]);

  useEffect(() => {
    console.log("BuyerInformation data", data);
  }, [data]);

  // Make a backend call.
  // Give backend your uid. And the item id.

  //(get)bidOwnerInformation(itemId)

  // Use the item id to get the lot in the backend. Get the bidOwner from the lot.
  // And what?

  // If the bidOwner is the same as the requesters uid.
  // Then authoriize to return a respnse
  // {name: xxx, email xxx}

  //    console.log("BuyerInformation buyerUser", u && u.data);
  function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }

  const rows = [createData(data && data.email, 159, 6.0, 24, 4.0)];

  return (
    <>
      {flavour === "simple" && (
        <>
          <TableContainer >
            <Table className={classes.table} aria-label="simple table">
        
   
              <colgroup>
                <col style={{ width: "10%" }} />
                <col style={{ width: "10%" }} />
                <col style={{ width: "20%" }} />
                <col style={{ width: "20%" }} />
                <col style={{ width: "20%" }} />
                <col style={{ width: "15%" }} />
                <col style={{ width: "5%" }} />
              </colgroup>

              <TableBody>
                <TableRow>
                  <TableCell align="left">
                    {"ref: " +
                      (item && item.itemRef
                        ? item.itemRef.replace("item_", "")
                        : "not available")}
                  </TableCell>
                  <TableCell align="left">
                    {" "}
                    <LotPrice
                      lot={lot}
                      item={item}
                      flavour="sold"
                      spice="simple"
                    />
                  </TableCell>
                  <TableCell align="left">{item && item.name}</TableCell>

                  <TableCell align="left">{data && data.name}</TableCell>
                  <TableCell align="left">{data && data.email}</TableCell>
                  <TableCell align="left">{data && data.telephone}</TableCell>

                  <TableCell align="left">
                    <CheckBoxOutlineBlankIcon />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}

      {flavour !== "simple" && (
        <>
          <Card
            variant="outlined"
            style={{ backgroundColor: "#ffffff", height: "100%" }}
          >
            <CardContent>
              <Typography gutterBottom style={{ textDecoration: "underline" }}>
                Buyer Information
              </Typography>

              {lot && lot.paid === true && (
                <>
                  <Typography color="textSecondary" gutterBottom>
                    name: {data && data.name}
                  </Typography>
                  <Typography color="textSecondary" gutterBottom>
                    email: {data && data.email}
                  </Typography>

                  <Typography color="textSecondary" gutterBottom>
                    telephone: {data && data.telephone}
                  </Typography>

                  <Typography color="textSecondary" gutterBottom>
                    message: {data && data.message}
                  </Typography>
                </>
              )}

              {lot && lot.paid !== true && (
                <>
                  <Typography color="textSecondary" gutterBottom>
                    No Buyer.
                  </Typography>
                </>
              )}
            </CardContent>
          </Card>
        </>
      )}
    </>
  );
};

export default BuyerInformation;
