import React, { useState, useEffect, useRef } from "react";

import Grid from "@material-ui/core/Grid";

import { makeStyles } from "@material-ui/core/styles";
import ImageUploader from "./../components/ImageUploader";
import EditImages from "./../components/EditImages";
import { v4 as uuidv4 } from "uuid";
import { deleteItemImage } from "./../util/db.js";


const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
  },

  content: {
    paddingBottom: 24,
  },
  actions: {
    display: "flex",
    height: "80px",
    justifyContent: "space-between",
  },
  dialogTitle: {
    textAlign: "center",
    fontFamily: '"Cormorant Garamond", "serif"',
  },
  backDrop: {
    backdropFilter: "blur(3px)",
    backgroundColor: "rgba(0,0,30,0.4)",
  },
}));

function ImagesManager(props) {
  const classes = useStyles();

  const {item} = props;

  const [editImagesKey, setEditImagesKey] = useState(uuidv4());


//const itemId = item.id;
const itemId = item && item.id ? item.id : uuidv4();


  const [imageIndex, setImageIndex] = useState(0);

  //  const { data: itemData, status: itemStatus } = useItem(itemId);
  const [uploadedImages, setUploadedImages] = useState([]);

  const [unsavedImages, setUnsavedImages] = useState([]);
  const [initialImages, setInitialImages] = useState([]);
  const ref = useRef({ maxIndex: 0 });

  useEffect(() => {

    if (!item) {return;}
    if (!item.images) {return;}

    setUploadedImages(item.images);
    setUnsavedImages(item.images);
    setInitialImages(item.images);
    ref.current.maxIndex = pickHighest(item.images);



  }, [item]);

  useEffect(() =>{

handleUpdate();

  }, [unsavedImages]);

  const handleUpdate = () => {
    //setPending(true);
    console.log("Now going to save the images to Firestore...");
    console.log("ImagesManager onSubmit unsavedImages", unsavedImages);

    const latestImages = unsavedImages.map((image, i) => {
      return { ...image, index: i };
    });

    // Check if there is images in initialImages,
    // which are not in the unsavedImages list.
    // If there are they need to be deleted from the image bucket.

    initialImages.map((image, i) => {
      const found = unsavedImages.find(
        (element) => element.location === image.location
      );
      console.log("Found:", found);
      if (found === undefined) {
        console.log("Deleting ", image.location);
        deleteItemImage(image.location);
      }
    });
    console.log("latestImages", latestImages);
    // Get the key image.
    // Which is the top image of the set.


     if (props.onUpdate) {
        props.onUpdate({latestImages:latestImages, unsavedImages:unsavedImages});
     }

    return;
  };

  useEffect(() => {
    setEditImagesKey(uuidv4());
    //setMaxIndex(i => pickHighest(uploadedImages))
    ref.current.maxIndex = pickHighest(uploadedImages);
  }, [uploadedImages, setUploadedImages]);

  const pickHighest = (arr) => {
    var maxIndex = 0;
    arr.forEach((el) => {
      const { index } = el;
      if (index > maxIndex) {
        maxIndex = index;
      }
    });
    return maxIndex;
  };

  function handleImage(image) {
    console.log("ImagesManager handleImage called", image.location);
    // Get the image meta information to allo image deduplication and deletion/editing.
    const { name, path, lastModifiedDate, size, type } = image.file.file;

    //
    // Not coorectly assigning indexs for mulitple add.
    //
    //const i = uploadedImages.length + 1;
    //const i = maxIndex + 1;
    //setMaxIndex(maxIndex => (maxIndex+1));
    //console.log("i is ", i)
    ref.current.maxIndex += 1;
    //const i = pickHighest(uploadedImages)
    var imageData = {
      index: ref.current.maxIndex,
      id: image.filename,
      location: image.location,
      name: name,
      path: path,
      size: size,
      type: type,
    };
    setEditImagesKey(uuidv4());

    setUploadedImages((uploadedImages) => [...uploadedImages, imageData]);
    setUnsavedImages((unsavedImages) => [...unsavedImages, imageData]);
  }

  function handleReorderImages(images) {
    console.log("EditImageModal handleReorderImages called");
    setUnsavedImages((prevImages) => images);
  }


  return (
    <>
      <Grid item={true} xs={12} sm={12}>
        <ImageUploader id={itemId} onImage={(i)=>handleImage(i)} />
      </Grid>

      <EditImages
        key={editImagesKey}
        images={uploadedImages}
        onReorderImages={(i)=>handleReorderImages(i)}
      />
    </>
  );
}

export default ImagesManager;
