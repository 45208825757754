import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    backgroundColor: '#f5f5f5',
  },
  title: {
    marginBottom: '1rem',
  },
  message: {
    maxWidth: '30rem',
    textAlign: 'center',
  },
});

function UnsupportedBrowser() {
  const classes = useStyles();
  
  return (
    <div className={classes.root}>
      <Typography variant="h4" component="h1" className={classes.title}>
        Unsupported Browser
      </Typography>
      <Typography variant="body1" component="p" className={classes.message}>
        Sorry, your browser is not supported. Please upgrade to a newer version of Chrome, Firefox, or Safari.
      </Typography>
    </div>
  );
}

export default UnsupportedBrowser;
