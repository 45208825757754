import React, { useState, useEffect } from "react";
import { Button, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { requireAuth } from "../util/auth";
import { useAuth } from "./../util/auth.js";
import { useAuctionByEvent, updateAuction } from "./../util/db.js";
import PlayCircleFilledWhiteOutlined from "@material-ui/icons/PlayCircleFilledWhiteOutlined";
import StopOutlinedIcon from "@material-ui/icons/StopOutlined";


const useStyles = makeStyles((theme) => ({
  paperItems: {
    minHeight: "300px",
  },
}));

function RunAuction(props) {
  const auth = useAuth();
  const classes = useStyles();
  const { items } = props;
  const { event } = props;

  //const lots = useLotsByAuction(event.auction)
  const { status: auctionStatus, data: auctionData, error:auctionError } = useAuctionByEvent(event.id);

useEffect(() => {

  console.log("auctionStatus", auctionStatus)
  console.log("auctionData",auctionData);
  console.log("auctionError", auctionError);


}, [auctionStatus, auctionData, auctionError])

  const isAuctioneer = auth.user.role && auth.user.role === "auctioneer";
  const isAuctionRunning = auctionData && auctionData.state !== undefined ? auctionData.state : false;
//const isAuctionRunning = true;
  const [creatingItem, setCreatingItem] = useState(false);
  function stopAuction() {
    console.log("CLICKED STOP",auctionData)
    updateAuction(event.id, { ...auctionData, state: false });
  }
  function playAuction() {
    console.log("CLICKED PLAY",auctionData)
    updateAuction(event.id, { ...auctionData, state: true });
  }

  if (auctionStatus !== "success") {
    return null;
  }

  return (
    <>
      {isAuctioneer ? (
        <>
        <Grid item >
          <Button
            disabled={isAuctionRunning}
            variant="contained"
            size="medium"
            color="primary"
            onClick={playAuction}
            startIcon={<PlayCircleFilledWhiteOutlined />}
          >
            PLAY
          </Button>
          </Grid>

          <Grid item >
          <Button
            disabled={!isAuctionRunning}
            variant="contained"
            size="medium"
            color="primary"
            onClick={stopAuction}
            startIcon={<StopOutlinedIcon />}
          >
            STOP
          </Button>
          </Grid>
      
          {/*data && data.state ? "RUNNING" : "STOPPED"*/}

        </>
      ) : null}
    </>
  );
}

export default requireAuth(RunAuction);

/*

{creatingItem && (
    <EditItemModal
      event={props.event}
      onDone={() => {
        setCreatingItem(false);
        console.log("onDone Creatingitem");
      }}
    />
  )}

  */
