import React from "react";
import Section from "./Section";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  // Increase <Container> padding so it's
  // at least half of <Grid> spacing to
  // avoid horizontal scroll on mobile.
  // See https://material-ui.com/components/grid/#negative-margin
  container: {
    padding: `0 ${theme.spacing(4)}px`,
 
  },
  imageWrapper: {
    // height:'20vh',
    margin: "0 auto",
    maxWidth: 570,
    width: "100%",
    "& > img": {
      width: "100%",
    },
  },
  row: {
    // Spacing between rows
    "&:not(:last-child)": {
      marginBottom: `${theme.spacing(2)}px`,
    },
  },

  title1: {
    fontFamily: '"Cormorant Garamond", "serif"',
    textAlign: 'center',
    // fontWeight:'600',
    // marginBottom: '1em',
  },

  title: {
    fontFamily: '"Cormorant Garamond", "serif"',
    
  },

  linebreak: {
    whiteSpace: "pre-line",
    fontWeight: '400', 
    
  }
}));

function SellSteps(props) {
  const classes = useStyles();
  const { flavour } = props;

const foo = "hello";
  const items = [
    {
      title: "Submit",
      description:
        "Let us know what you are wanting to sell. LadyBidwell and her team will review and confirm your submission within one business day. We are focussed on creating a marketplace of unique and fine furniture, collectables, jewelery and art, so are selective in what we will accept.",
        
      number: 1,
      iconColor: "primary.main",
    },
    {
      title: "List",
      description:
        (<> <b> Individual Sellers </b>: If you are looking to sell a few things you will get your very own LadyBidwell Verified Seller, who will be your primary point of contact. They will contact you to arrange a convenient date and time to photograph and document your items, including pricing. <br /><br/> <b> Professional Sellers:</b> If you are approved to become a LadyBidwell Verified Seller, all you need to do is simply upload your items. You can organize your items by client, style, type, whatever makes your life easier. </>),
      number: 2,
      iconColor: "primary.main",
    },
    {
      title: "Sell",
      description:
        "If the item(s) are approved by LadyBidwell, it will be auctioned in the LadyBidwell sale you have selected. When sold you will get paid, and then you simply need to hand-off the item to the buyer on the date selected for pickup (unless delivery was agreed upon). Any questions please get in-contact with us.",
      number: 3,
      iconColor: "primary.main",
    },
  ];

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
   
      <Container className={classes.container}>
      {/* <Typography variant='h3'  className={classes.title1} >Here's how LadyBidwell works</Typography> */}
        <Grid container={true} alignItems="center" spacing={4}>
          {/* <Grid container={true} item={true} direction="column" xs={12} md={6}>
            <figure className={classes.imageWrapper}>
              <img src={props.image} alt="" />
            </figure>
          </Grid> */}


<Grid container={true} item={true} direction="column" xs={12} md={6}>


{flavour === "about" && (

<Typography variant='h3'  className={classes.title1} >Have something to sell? It's as easy as 1, 2, 3...</Typography>

)}

{flavour !== "about" && (

<Typography variant='h3'  className={classes.title1} >Here's how LadyBidwell works</Typography>
)}
          </Grid> 
          
          <Grid item={true} xs={12} md={6}>
  
            {items.map((item, index) => (
              <Grid
                className={classes.row}
                item={true}
                container={true}
                direction="row"
                justify="center"
                alignItems="center"
                spacing={5}
                key={index}
              >
                <Grid item={true} xs="auto">
                  <Box
                    // color={item.iconColor}
                    display="flex"
                    justifyContent="center"
                    fontSize={50}
                    width={50}
                    height={50}
                  >
                    {item.number}
                  </Box>
                </Grid>
                <Grid item={true} xs={true}>
                  <Typography
                    variant="h4"
                    className={classes.title}
                    gutterBottom={true}
                  >
                    {item.title}
                  </Typography>
                  <Typography variant="body1" className={classes.linebreak}>
                    {item.description}
                  </Typography>
           
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Container>
    </Section>
  );
}

export default SellSteps;
