import React, { useEffect} from "react";
import { Link } from "react-router-dom";

import { useWordPressPosts } from "./../util/db.js";
import { makeStyles } from "@material-ui/core/styles";
import { useAuth } from "./../util/auth.js";

import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },

  cardContent: {
    padding: theme.spacing(3),
  },

  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
    background: "#f5f5f5",
  },
}));

const defaultPalette = { lightVibrant: "#cccccc", darkMuted: "#000000" };

function WeeklyGuides(props) {
  const classes = useStyles();
  //const router = useRouter();
  //const post = router.query.post;

  const auth = useAuth();

  const isAuctioneer =
    auth.user && auth.user.role && auth.user.role === "auctioneer";

  const { data: posts, status, error } = useWordPressPosts();

  const shareUrl = window.location.href;

  useEffect(() => {
    console.log("posts", posts);
    console.log("posts status", status);
    console.log("posts error", error);

    if (status !== 'success') {return null;}

    posts.map((post) => {
    const imageRef = post._embedded["wp:featuredmedia"];
    if (imageRef) {
    const imageRef2 = imageRef[0].media_details;
    if (imageRef2) {
      const imageUrl = imageRef2.sizes.thumbnail.source_url;
    console.log(imageUrl);
  
    }
  }
    
  })
    

  }, [posts, status, error]);

  if (status !== "success") {
    return null;
  }

  return (
    <div>
      {posts.map((post) => {

        //const imageRef = post._embedded["wp:featuredmedia"];
        var imageUrl = null;

        // This is th reference to the specific json variable
        // which comes back from the Wordpress API call.

    //    if (imageRef) {
    //      imageUrl = imageRef[0].source_url;
          //imageUrl = imageRef[0].media_details.sizes["post-thumbnail"].source_url;
     //   }
       // [2]._embedded["wp:featuredmedia"][0].media_details.sizes["post-thumbnail"]


       const imageRef = post._embedded["wp:featuredmedia"];
    if (imageRef) {
    const imageRef2 = imageRef[0].media_details;
    if (imageRef2) {
      imageUrl = imageRef2.sizes.thumbnail.source_url;
    //console.log(imageUrl);
  
    }
  }

        console.log("imageUrl", imageUrl);
        return (
          <>
            <Typography
              color="textSecondary"
              gutterBottom
              dangerouslySetInnerHTML={{ __html: post.title.rendered }}
            />

            <Typography
              color="textSecondary"
              gutterBottom
              dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }}
            />

            {imageUrl !== null && <img src={imageUrl} />}

          <Link to={'weekly-guide/'+post.slug}>This is a link</Link>
          </>
        );
      })}
    </div>
  );
}

export default WeeklyGuides;
