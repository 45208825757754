import React, { useEffect } from "react";

import ShoppingCart from "../components/ShoppingCart";
import { useRouter } from "./../util/router.js";
import { useAuth } from "./../util/auth.js";
import { usePaymentIntent } from "../util/stripe.js";
import Section from "../components/Section";
import Meta from "./../components/Meta";

function CartPage(props) {
  const router = useRouter();
  const endpointUrl = window.location.pathname; //href gives full url
  const auth = useAuth();
  const user = auth && auth.user && auth.user.uid ? auth.user.uid : null;

  const clientSecret = usePaymentIntent(user, null);
  const { timestamp } = props;

  useEffect(() => {
    console.log(timestamp, "timestamp");
  }, [timestamp]);

  const handleStatus = () => {};

  return (
    <>
<Meta title="Cart Page"

/>


      <Section size="large" style={{ paddingTop: "0px" }}>
        
          <ShoppingCart />
     
      </Section>
    </>
  );
}

export default CartPage;
