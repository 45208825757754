import React from "react";
import Section from "./Section";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined';
import StorefrontOutlinedIcon from '@material-ui/icons/StorefrontOutlined';
import GavelIcon from '@material-ui/icons/Gavel';


const useStyles = makeStyles((theme) => ({
  // Increase <Container> padding so it's
  // at least half of <Grid> spacing to
  // avoid horizontal scroll on mobile.
  // See https://material-ui.com/components/grid/#negative-margin
  container: {
    padding: `0 ${theme.spacing(4)}px`,
  },
  title1: {
    fontFamily: '"Cormorant Garamond", "serif"',
    textAlign: 'center',
    // fontWeight:'600',
    // marginBottom: '1em',
  },
  title: {
    fontFamily: '"Cormorant Garamond", "serif"',
    
  },
}));

function AboutSections(props) {
  const classes = useStyles();

  const items = [
    {
      title: "Exceptional Items",
      subtitle:
        "LadyBidwell is a go-to destination for discovering one-of-a-kind items that perfectly fit your style. Our curated selection showcases the finest art, furniture, collectibles, and jewelry – whether antique or modern.",
      icon: StorefrontOutlinedIcon,
      iconColor: "primary.main",
    },
    {
      title: "Easy Speedy",
      subtitle:
        "Our unique declining price auction model allows you to confidently buy items at the price you want. With no hidden buyers fees and a simple, transparent process, you can focus on finding your next treasure.",
      icon: GavelIcon,
      iconColor: "primary.main",
    },
    {
      title: "Trusted Sellers",
      subtitle:
        "When you shop with us, you can trust that you're getting an exceptional piece from vetted and trusted sellers. Plus, our LadyBidwell Verified Sellers are always available to answer any questions or provide additional information about their offerings.",
        
        
        // From fast and free listings to auctions happening, everything we do is fast. Faster than a 4-horse drawn carriage going up Mayfair, trust me, that's fast.",
      icon: VerifiedUserOutlinedIcon,
      iconColor: "primary.main",
    },

    // {
    //     title: "Auction Local",
    //     subtitle:
    //       "Buy Local, Eat Local and well you heard it here first, Auction Local. All our auctions are hyper-local, which means selling to a bevy of motivated local buyers, who are ready and willing to pick-up (unless you negotiated shipping with them)",
    //     icon: Brightness4Icon,
    //     iconColor: "#00d1b2",
    //   },

    // {
    //     title: "No Hassle",
    //     subtitle:
    //       "Dark mode is so last year. We have a light mode and turquoise mode. Either way, your eyes are going to hurt. Don't forget to stay charged!",
    //     icon: Brightness4Icon,
    //     iconColor: "#00d1b2",
    //   },
  ];

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container maxWidth="lg" className={classes.container} >
      <Grid item={true} xs={12} md={12}>
        <Box py={1} style={{borderTop:'1px double #b8b8b8', borderBottom:'1px double #b8b8b8'}} >
      <Typography variant='h3'  className={classes.title1} ><i>"It's high time the fine people of the Hamptons had a better option for buying and selling their fineries."</i> - L. Bidwell</Typography>
      </Box>
    </Grid>
    <Box mt={6}>
        <Grid container={true} justify="center" spacing={4} >
       
          {items.map((item, index) => (
            <Grid item={true} xs={12} md={4} key={index}>
              <Box textAlign="center">
                <Box color={item.iconColor} fontSize="2rem">
                  <item.icon fontSize="inherit" />
                </Box>
                <Typography variant="h4"  className={classes.title} gutterBottom={true}>
                  {item.title}
                </Typography>
                <Box mt={1}>
                  <Typography variant="body1">{item.subtitle}</Typography>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
        </Box>
      </Container>
    </Section>
  );
}

export default AboutSections;
