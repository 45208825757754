import React, {useEffect, useState} from "react";
import MessageCard from "./MessageCard.js";
import {sortByISOCreatedAt} from "../util/array";

const MessageCards = (props) => {
    const { messages, sortBy, hideFirst } = props;
const [sortedMessages, setSortedMessages] = useState();

const hideFirstMessage = hideFirst === undefined ? false : hideFirst;

useEffect(()=>{
console.log("MessageCards started")
}, [])

useEffect(() => {
console.log("MessageCards messages", messages);

// Debug sort

messages.map((message)=>{

console.log("MessageCards sort debug", message.createdAt)


})
//const m = messages;

var m = sortByISOCreatedAt(messages, "asc");
/*
    const m = messages.slice().sort((a,b) => {

      //if (a.createdAt === null) {return true;}
      //if (b.createdAt === null) {return false;}
      //return compareFirebaseTimes(b.createdAt, a.createdAt)

      return Date(b.createdAt) - Date(a.createdAt);

    });
    */
// Remove first message
if (hideFirstMessage && m.length > 1) {
    m.shift();
}
console.log("MessageCards messages length", messages.length )

    setSortedMessages(m);
    // Remove first message card.

}, [messages]);
/*
function compareFirebaseTimes(a, b) {


const diff = new Date(firebaseTime(a)) - new Date(firebaseTime(b));
return diff;
};
*/
//    const sortedMessages = messages.slice().sort((a, b) => b.date.compareTo(a.date));
    return (
      <>
        {sortedMessages && sortedMessages.map((message, index) => (
          
            <MessageCard key={message.id} message={message} broadcast="room" />
      
        ))}
      </>
    );
  };

  export default MessageCards;
