import React, { useState, useEffect } from "react";
import {
  weekdayTime,
  monthdayTime,
  yearTime,
  firebaseTime,
  getFirebaseCurrentTime,
} from "./../util/time.js";
import { getFormattedPrice } from "../util/price.js";
import { Grid, Typography, Button, Box } from "@material-ui/core";
import AddAlertIcon from "@material-ui/icons/AddAlert";
import PriceNotification from "./PriceNotification.js";
import { useAuth } from "./../util/auth.js";
import {
  useNotifications,
  deleteNotification,
  createNotification,
} from "./../util/db.js";
import { useTheme } from "@material-ui/core/styles";
import AuthDialog from "./AuthDialog.js";
//OrderSummary.js:  const auth = useAuth();
import { useMediaQuery } from "@material-ui/core";

const showFeatureNotifications = true;

function LotPricesTable(props) {
  const currentTime = getFirebaseCurrentTime();

  const { lot } = props;
  const [alertStates, setAlertStates] = React.useState({});
  const [lastTwoAlert, setLastTwoAlert] = React.useState(false);

  const auth = useAuth();
  const theme = useTheme();
  const mobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isDeveloper = auth?.user?.developer?.enabled;

  const {
    data: notifications,
    status,
    error,
  } = useNotifications(lot && lot.lot, auth.user.id);

  const [globalAlertState, setGlobalAlertState] = useState(false);

  const [loginRequest, setLoginRequest] = useState(false);
  const [loginOpen, setLoginOpen] = useState(false);
  const [signupOpen, setSignupOpen] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleLogin = (event) => {
    setLoginOpen(true);
  };

  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    console.log("PriceNotification handleOpen");
    // saveState("notification", { price:price.id });

    setLoginRequest(true);
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    //setIsOpen(globalAlertState)
  }, [globalAlertState]);

  const handleToggleAllNotifications = () => {
    setGlobalAlertState((prevState) => !prevState);

    // Loop through all price items and add or remove notifications accordingly
    lot.prices.forEach((price) => {
      // Find the corresponding notification for the price
      const notification = getNotification(price.id);
      console.log(
        "LotPricesTable handleToggleAllNotifications notification",
        notification
      );
      // If globalAlertState is currently true, remove all notifications
      if (globalAlertState) {
        if (notification && !notification.disabled) {
          deleteNotification(notification.id);
        }
      }
      // If globalAlertState is currently false, add notifications to all items
      else {
        if (!notification || notification.disabled) {
          createNotification({
            type: "price",
            ownerId: auth.user.uid,
            price: price,
            itemId: lot.id,
          });
        }
      }
    });
  };

  /*
 TODO 18 December 2022

1. Develop and test generation of new price notifications in firestore. (Working.)
2. Develop adding price id in to the return from lot.prices. (Working.)
3. Develop and test the useNotifications hook. (Working.)
4. Take the notifications and wrap into the .map for prices. (ie setAlertStates);
5. Test deletion of notifications. (Working.)
6. Add in (on functions) regular check for prices within a certain horizon.
   Best to change this to look for notifications in an horizon. Less wasteful.
7. Look up notifications matching that priceid.
   Best make this matching prices.
8. Send out emails.
(9). Perhaps add a flag to the price in prices to indicate whether a notification exists to avoid searching for something that doesn't exist.
     See 6 and 7 notes.
*/

  useEffect(() => {
    if (notifications == null) {
      return;
    }
    console.log("LotPricesTable notifications", notifications, error, status);
  }, [notifications, error, status]);

  useEffect(() => {
    console.log("LotPricesTable lot", lot);
  }, [lot]);

  function handleClickPriceNotification(i) {
    console.log("LotPricesTable handleClickPriceNotification", i);
    setGlobalAlertState(null);
  }

  const handleClick = (id) => {
    setAlertStates((prevStates) => {
      return {
        ...prevStates,
        [id]: !prevStates[id],
      };
    });
  };

  function getNotification(priceId) {
    if (notifications == null) {
      return;
    }

    console.log("LotPricesTable notifications", notifications);

    const matchedNotifications = notifications.filter((notification) => {
      if (notification.price === priceId) {
        return true;
      }
      return false;
    });

    if (matchedNotifications.length === 0) {
      return false;
    }

    if (matchedNotifications.length > 1) {
      return true;
    }

    // If more than one found, return first one for consistency.

    return matchedNotifications[0];
  }

  const handleLastTwoClick = () => {
    setLastTwoAlert((prevState) => !prevState);
  };

  //  if (notifications == null) {
  //    return null;
  //  }



  if (!(lot && lot.prices)) {
    return null;
  }

if (lot && lot.accepted === false) {

return null;

}

  return (
    <>
      <Box pb={1} display="flex" justifyContent="end">
        {!auth.user ? (
          <>
            <AuthDialog
              isDialogOpened={isOpen}
              handleCloseDialog={() => setIsOpen(false)}
              // subtitle="Put all your loves in a safe place..."
            />
            <Button
              size="large"
              disableElevation
              startIcon={<AddAlertIcon />}
              fullWidth
              variant="contained"
              onClick={handleOpen}
              style={{background:'#abf7ed', color:'#000'}}
            >
           <Typography variant="subtitle2">  Sign-In to Add Price Drop Alerts</Typography> 
            </Button>
          </>
        ) : (
          <Button
            startIcon={<AddAlertIcon />}
            variant="contained"
            disableElevation
            size="large"
            onClick={handleToggleAllNotifications}
            fullWidth
            color={globalAlertState ? "" : "secondary"}
          >
            {/*
<IconButton onClick={() => handleClickPriceNotification()} disabled={!canToggleNotification} > 
      */}

            {globalAlertState
              ? "Turn Off All Price Drop Alerts"
              : "Turn On All Price Drop Alerts"}
          </Button>
        )}
      </Box>

      {lot.prices.map((price, index) => {
        // Ensure that the price has an id property
        const id = price.id || index;
        const isLastTwo = index >= lot.prices.length - 2;

        const notification = getNotification(price.id);
        console.log(
          "LotPricesTable price.id notification",
          price.id,
          notification
        );

        return (
          <div key={"item_price_" + lot.item + "_" + index}>
            <Grid container direction={"row"} spacing={1} alignItems="center">
              <Grid item xs={5} sm={8}>
                <Typography variant="subtitle1">
                  {weekdayTime(firebaseTime(price.validAt))}
                </Typography>
                <Typography variant="subtitle2">
                  {monthdayTime(firebaseTime(price.validAt))}
                  {"/"}
                  {yearTime(firebaseTime(price.validAt))}
                </Typography>
                {/*
validAtTime{' '}{firebaseTime(price.validAt)}
{' '}

{millisecondsDifference(currentTime, firebaseTime(price.validAt))}
*/}
              </Grid>

              <Grid item xs={7} sm={4}>
                <Typography variant="subtitle1" style={{ textAlign: "right" }}>
                  <b>{getFormattedPrice("USD", price.amount)}</b>
                  {showFeatureNotifications && (
                    <PriceNotification
                      notification={notification}
                      price={price}
                      lot={lot}
                      globalAlertState={globalAlertState}
                      onClickPriceNotification={() =>
                        handleClickPriceNotification()
                      }
                    />
                  )}
                </Typography>
              </Grid>
            </Grid>
          </div>
        );
      })}
    </>
  );
}

export default LotPricesTable;
