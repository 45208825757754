import React from 'react';
import Button from '@material-ui/core/Button';
import { updateItem } from '../util/db';

function MarkHoldButton({ item }) {
  const toggleHoldStatus = () => {
    const newStatus = item.holdStatus === 'on_hold' ? 'off_hold' : 'on_hold';
    updateItem(item.id, { holdStatus: newStatus })
      .then(() => console.log(`Item marked as ${newStatus}`))
      .catch((error) => console.log(error));
  }

  return (
    <Button variant="outlined" fullWidth  size="medium" color="primary" onClick={toggleHoldStatus} >
      {item.holdStatus === 'on_hold' ? 'Take off Hold' : 'Put on Hold'}
    </Button>
  );
}

export default MarkHoldButton;
