import React from "react";
import { useAuth } from "./../util/auth.js";
import {
  useLotStatus,
} from "./../util/db.js";
import ItemWithdraw from "./ItemWithdraw.js";

  export default function OwnerWithdrawItem(props) {

    const {item, lot, mode, event} = props;

  const auth = useAuth();

  const isDelisted =
    lot && lot.delistedAt && lot.delistedAt !== false ? true : false;

  const isAdministrator =
    auth.user && auth.user.role && auth.user.role === "auctioneer";
  const isOwner = auth.user && auth.user.id === (item && item.owner);

  const { isLotStarted } = useLotStatus(lot, event);




    if (mode && mode === "view") {
      return null;
    }

    return (
      <>
        {!isDelisted && isOwner && !isAdministrator && !isLotStarted && (
          <>
            <ItemWithdraw item={item} lot={lot} />
          </>
        )}
      </>
    );
  };
