import React, { useState} from "react";
import SettingsSection from "./../components/SettingsSection";
import { useRouter } from "./../util/router.js";
import { requireAuth } from "./../util/auth.js";
import Meta from "./../components/Meta";

function SettingsPage(props) {
  const router = useRouter();
  const [flags, setFlags] = useState({
    profile: { flag: 'success' }, // or other default value
    billing: { flag: 'success' }, // or other default value
    // ... other flags you may have
  });

function handleFlags(f) {

setFlags(f);

}

  return (<>
  <Meta 
title="Your Account"
/>

    <SettingsSection
      bgColor="default"
      size="normal"
      bgImage=""
      bgImageOpacity={1}
      section={router.query.section}
      message={router.query.message}
      key={router.query.section}
      flags={flags}
      onFlags={handleFlags}
    />
    </>
  );
}

export default requireAuth(SettingsPage);
