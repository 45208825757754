
import { IconButton, Tooltip } from '@material-ui/core';
import { useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';

import { useCart } from "./../util/cart.js";

const RemoveFromCartButton = props => {
  const {item} = props;
  const { removeItem } = useCart();
const [conditionedItem, setConditionedItem] = useState();  

function removeItemFromCart(item) {
console.log("RemoveFromCartButton removeItemFromCart item", item)

  removeItem(item.id)
  if (props.onRemove) {
    props.onRemove(item.id)
  }
}


if (!item) {return null;}
  return (


     <>
     <Tooltip title="Remove">
      <IconButton
        
        // style={{padding:'0px'}}
        //disabled={itemAvailable}
        onClick={() => removeItemFromCart({ ...item })}
    
      >
        <CloseIcon />
      </IconButton>
      </Tooltip>
      </>
  
  );
};


export default RemoveFromCartButton;

