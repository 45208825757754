import React, { useState, useEffect } from "react";
import {
saveState,
loadState,
  createNotification,
  deleteNotification,
} from "./../util/db.js";
import {
  firebaseTime,
  isoAge,
} from "./../util/time.js";
import {
  IconButton,
  Tooltip,
} from "@material-ui/core";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import AddAlertIcon from "@material-ui/icons/AddAlert";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import { age } from "../util/time.js";
import { debugFlag } from "../util/dev.js";
import AuthDialog from "./AuthDialog.js";
import { useAuth } from "./../util/auth.js";

function PriceNotification(props) {
  const auth = useAuth();
 
  const { lot, price, notification, globalAlertState } = props;
  const [alertState, setAlertState] = React.useState();
  const [disableFlag, setDisableFlag] = React.useState();


  const canUsePriceNotification = auth.user && true;

useEffect(()=>{

console.log("PriceNotification start");


},[]);

// This code listens for an incoming event 
// signaled in browser state.

// Consider: See why PriceNotification is restarting after AuthDialog.

useEffect(() =>{

if (lot == null) {return;}
if (price == null) {return;}

const notificationState = loadState("notification");
//saveState("notification", {price:null});
//console.log("PriceNotification price lot", notificationState, price, lot);

if (notificationState == null) {return;}

if (notificationState.price !== price.id) {
return;
}



if (auth.user) {
console.log("PriceNotification price lot auth.user", notificationState, price, lot, auth.user);


    createPriceNotification(price, lot);
saveState("notification", {price:null});

}


},[price,lot, auth.user]);


useEffect(() => {
  if (notification == null) {
    return;
  }

  console.log("PriceNotification notification", price.id, notification);

  if (globalAlertState) {
    setAlertState(true);
  } else {
    if (notification === false || (typeof notification === 'object' && notification.disabled)) {
      setAlertState(false);
    } else {
      setAlertState(true);
    }
  }
}, [notification, globalAlertState]);


  const [loginRequest, setLoginRequest] = useState(false);
  const [loginOpen, setLoginOpen] = useState(false);
  const [signupOpen, setSignupOpen] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleLogin = (event) => {
    setLoginOpen(true);
  };

  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
      console.log("PriceNotification handleOpen");
      saveState("notification", { price:price.id });


    setLoginRequest(true);
    setIsOpen(!isOpen);
  };

useEffect(()=>{

console.log("PriceNotification loginRequest", notification, loginRequest);

}, [loginRequest]);

  useEffect(() => {

console.log("PriceNotification auth loginRequest", notification, auth, loginRequest);

    if (!auth) {
console.log("PriceNotification test A - no auth",  notification);
      return;
    }
    if (!loginRequest) {
console.log("PriceNotification test B - no login request", notification );
      return;

    }

    if (auth.user === false) {
console.log("PriceNotification test C - loginRequest true auth user false", notification && notification.id );
      return;
    }

console.log("PriceNotification test D - OK");
console.log("PriceNotification createPriceNotification price lot", price, lot);

    createPriceNotification(price, lot);


//    starItem({ ...star, featured: true });
console.log("PriceNotification setLoginRequest false");
    setLoginRequest(false);
  }, [auth && auth.user, loginRequest]);



  useEffect(() => {
    console.log(
      "PriceNotification price validAt",
      price.validAt,
      age(price.validAt)
    );

    const zuluTime = firebaseTime(price.validAt);
    const priceAge = isoAge(zuluTime);
    console.log("PriceNotification zuluTime", zuluTime, priceAge);

    if (priceAge > 0) {
      //    if (age(price.validAt) >= 0) {
      setDisableFlag(true);
    }
  }, [price]);


// Might be a stretch to far.
// But this code will be reused.
function createPriceNotification(price, lot) {

createNotification({
        type: "price",
        ownerId: auth.user.uid,
        price: price,
        itemId: lot.id,
      })
        .then((result) => {
          console.log("PriceNotification createPriceNotification result", result);

if (result == null) {
setAlertState(true);
return;
}


// Remove?
          if (result.data.error == null) {
            setAlertState(true);
            return;
          }

          // Don't change alert state
          //setAlertState(false);
        })
        .catch((error) => {
          console.log("PriceNotification createPriceNotification error", error);
          setAlertState(false);
        });


}

  const handleClickPriceNotification = () => {
//    if (globalAlertState) return;

    console.log("PriceNotification price", price, lot);
    console.log("PriceNotification alertState", alertState);
//console.log("PriceNotification notification", alertState, notification);
    if (globalAlertState) {

if (props.onClickPriceNotification) {

console.log("PriceNotification notification", notification);

deleteNotification(notification.id);
props.onClickPriceNotification(notification);


}

return;}


if (!auth.user) {
    handleOpen();
    return;
}


//console.log("PriceNotification test");
    if (alertState == null || alertState === false) {
      // lotId and itemId are the same

      //https://firebase.google.com/docs/reference/js/v8/firebase.firestore.Timestamp

      //      console.log("PriceNotification notifyAt", notifyAt);
      //      processCreateNotification({ type:'price', priceId: price.id, itemId: lot.id }).then((result)=>{


createPriceNotification(price, lot);


    } else {
      console.log(
        "PriceNotification processRemoveNotification",
        notification.id
      );

      deleteNotification(notification.id);

      setAlertState(false);
    }

    // setAlertState(!alertState);
  };
//  const canToggleNotification = !globalAlertState;
const canToggleNotification = true;
///  const [isOpen, setIsOpen] = useState(false);


  useEffect(() => {
    console.log("priceId", price);
  }, [price]);
  //  if (!(lot && lot.prices)) {
  //    return null;
  //  }


  return (
    <>

{!auth.user && (
         <AuthDialog
           isDialogOpened={isOpen}
           handleCloseDialog={() => setIsOpen(false)}
           // subtitle="Put all your loves in a safe place..."
         />
)}


      {debugFlag && (
        <>
          <br />
          PRICE {price.id}
          <br />
          NOTIFICATION {notification && notification.id}
          <br />
          ALERT {alertState ? "TRUE" : "FALSE"} <br />
          AGE {age(price.validAt)}
          <br />
        </>
      )}

      

      {disableFlag === true ? (
        <IconButton disabled={true}>
          <NotificationsNoneIcon style={{ color: "transparent" }} />
        </IconButton>
      ) : (
        <Tooltip title="Add / Remove Alert" arrow  disabled={!canToggleNotification}>
        <IconButton onClick={() => handleClickPriceNotification()} disabled={!canToggleNotification} > 
          {alertState ? (
            <NotificationsActiveIcon color="secondary" />
          ) : (
            <AddAlertIcon color="primary" />
          )}
        </IconButton>
      </Tooltip>
      )}
    </>
  );
}

export default PriceNotification;

