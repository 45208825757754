import React, { useState, useEffect } from "react";
import IconButton from "@material-ui/core/IconButton";
import { useAuth } from "./../util/auth.js";
import {
  saveState,
  loadState,
  starItem,
  useStarByItemOwner,
} from "./../util/db.js";
import { makeStyles } from "@material-ui/core/styles";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import FavoriteIcon from "@material-ui/icons/Favorite";
import CloseIcon from "@material-ui/icons/Close";
import AuthDialog from "./AuthDialog.js";
import { toast } from "react-toastify";

const defaultStar = { featured: false, type: "item" };

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 270,
  },

  estimate: {
    justifyContent: "space-between",
    paddingLeft: "16px",
    paddingRight: "16px",
    paddingTop: "0px",
  },

  media: {
    height: 180,
    display: "flex",
    justifyContent: "center",
  },

  paperItems: {
    minHeight: "300px",
  },

  favIcon: {
    fontSize: "1.5em",
    stroke: "#ffffff",
    strokeWidth: "1",
  },

  featured: {
    position: "relative",
    opacity: 0.4,
    "&:hover": {
      color: "#000000",
      opacity: 1,
    },
  },

  starFeatured: {
    position: "relative",
    // color: theme.palette.secondary.main,
    "&:hover": {
      color: theme.palette.secondary.main,
      opacity: 1,
    },
  },

  starNotFeatured: {
    opacity: 1,
    position: "relative",
    // color: 'theme.palette.secondary.main',
    "&:hover": {
      color: theme.palette.secondary.main,
      opacity: 1,
    },
  },
}));

function ItemHeart(props) {
  const classes = useStyles();

  const auth = useAuth();
  const endpointUrl = window.location.pathname; //href gives full url

  const { item, type, size } = props;

  const ownerId = auth.user && auth.user.id ? auth.user.id : null;

  const initialStar = { ...defaultStar, item: item.id, owner: ownerId };

  const [star, setStar] = useState(initialStar);

  const { status, data, error } = useStarByItemOwner(item.id, ownerId);

  useEffect(() => {
console.log("UserItemHeart data", data);
    if (status !== "success") {
      return;
    }

    setStar(data[data.length - 1]);
  }, [data, status, error]);

  const canUseStar = auth.user && true;

  const handleClickStar = (star) => {
console.log("UserItemHeart handleClickStar star", star);

    if (canUseStar) {
      var flag = true;
      if (star && star.featured) {
        flag = !star.featured;
      }
      starItem({ ...star, featured: flag });
    } else {
      saveState("star", { ...star, featured: true });
      const l = loadState("star");
    }
    if (star.featured && type === "remove") {
      // Display a toast notification when heart is removed by user
      toast(<span>💔 {item.name} removed from your likes</span>);
    }
  };

  const [loginRequest, setLoginRequest] = useState(false);
  const [loginOpen, setLoginOpen] = useState(false);
  const [signupOpen, setSignupOpen] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleLogin = (event) => {
    setLoginOpen(true);
  };

  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
console.log("UserItemHeart handleOpen");

      saveState("star", { ...star, featured: true });


    setLoginRequest(true);
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (!auth) {
console.log("UserItemHeart test A");
      return;
    }
    if (!loginRequest) {
console.log("UserItemHeart test B");
      return;

    }

    if (auth.user === false) {
console.log("UserItemHeart test C");
      return;
    }

    starItem({ ...star, featured: true });

    setLoginRequest(false);
  }, [auth, loginRequest]);

  if (!auth.user) {
    return (
      <>
        <AuthDialog
          isDialogOpened={isOpen}
          handleCloseDialog={() => setIsOpen(false)}
          // subtitle="Put all your loves in a safe place..."
        />

        <IconButton
          edge="start"
          aria-label="star"
          className={initialStar.featured && classes.starFeatured}
          onClick={() => handleOpen()}
          color="primary"
        >
          <FavoriteBorderIcon className={classes.favIcon} />
        </IconButton>
      </>
    );
  }

  // Otherwise show a standard heart.
  if (star === undefined) {
    return (
      <>
        <IconButton
          edge="start"
          aria-label="star"
          onClick={() => handleClickStar(initialStar)}
          color="primary"
          className={
            initialStar.featured && classes.starFeatured
              ? classes.starFeatured
              : classes.starNotFeatured
          }
        >
          <FavoriteBorderIcon className={classes.favIcon} />
        </IconButton>
      </>
    );
  }

  if (star.featured) {
    return (
      <>
        <IconButton
          edge="start"
          aria-label="star"
          onClick={() => handleClickStar(star)}
          color="secondary"
          className={star.featured && classes.starFeatured}
        >
          {type === "remove" ? (
            <>
              <CloseIcon color="primary" />
            </>
          ) : (
            <>
              <FavoriteIcon className={classes.favIcon} />
            </>
          )}
        </IconButton>
      </>
    );
  }

  return (
    <>
      <IconButton
        edge="start"
        aria-label="star"
        onClick={() => handleClickStar(star)}
        color="primary"
        className={
          star.featured && classes.starFeatured
            ? classes.starFeatured
            : classes.starNotFeatured
        }
      >
        <FavoriteBorderIcon className={classes.favIcon} />
      </IconButton>
    </>
  );
}

export default ItemHeart;
