

// function extractItems(text) {
// if (!text) {return false;}
//   //const itemIds = [];
// var firebaseItemPattern = /\w{20}/g;
// return text.match(firebaseItemPattern);

// //return itemIds;

// }

// export function extractItem(text) {

//   const itemIds = extractItems(text);
//   if (itemIds && itemIds.length === 1) {
//     return itemIds[0];
//   }

//   return false;
// }

// export function scoreHydratedItem(item, referenceItem) {
// var score = 1;
// console.log("item scoreHydratedItem", item, referenceItem);

// if (item.item.event === referenceItem.item.event) {
// score = score  + 10000;
// }

// if (item.item.owner === referenceItem.item.owner) {
// score = score + 1000;
// }


// score = score + scoreItem(item.item.name + " " +item.item.description, referenceItem.item.name + " " +referenceItem.item.description);


// return score;

// }

// export function scoreTextItem(text, item) {

// if (text == null) {return 0;}

//  return scoreItem(text, item.name + " " + item.description);

// }

// export function scoreItem(text, clusterTokens) {
//   const defaultKeywords = null;
//   clusterTokens = clusterTokens
//     ? clusterTokens.toLowerCase().split(" ")
//     : defaultKeywords.toLowerCase().split(" ");

//   const titleTokens = text.toLowerCase().split(" ");

//   let score = titleTokens.length;
//   let count = 0;

// if (clusterTokens.length === 1) {

//   if (titleTokens.includes(clusterTokens[0].toLowerCase())) {
//     count += 1;
//   }

// }


//   clusterTokens.map((word) => {
//     if (titleTokens.includes(word.toLowerCase())) {
//       count += 1;
//     }
//     return true;
//   });

//   titleTokens.map((word) => {
//     if (clusterTokens.includes(word.toLowerCase())) {
//       count += 1;
//     }
//     return true;
//   });

//   // Weight matches in first three tokens of title
//   clusterTokens.map((word) => {
//     if (titleTokens.length === 0) {
//       return true;
//     }

//     if (word.toLowerCase() === titleTokens[0].toLowerCase()) {
//       count += 1;
//     }

//     if (titleTokens.length === 1) {
//       return true;
//     }

//     if (word.toLowerCase() === titleTokens[1].toLowerCase()) {
//       count += 1;
//     }

//     if (titleTokens.length === 2) {
//       return true;
//     }

//     if (word.toLowerCase() === titleTokens[2].toLowerCase()) {
//       count += 1;
//     }
//     return true;
//   });

//   score = count;

//   // NEXT STEP EXTRACT MIXEDs.

//   //const score = LevenshteinDistance(keywords, text);
//   //console.log(text, keywords, score);

//   return score;
// }


function extractItems(text) {
  if (!text) {return [];}
  var firebaseItemPattern = /\w{20}/g;
  return text.match(firebaseItemPattern) || [];
}

export function extractItem(text) {
  const itemIds = extractItems(text);
  return itemIds.length === 1 ? itemIds[0] : false;
}

export function scoreHydratedItem(item, referenceItem) {
  var score = 1;

  if (item?.item?.event === referenceItem?.item?.event) {
    score = score  + 10000;
  }

  if (item?.item?.owner === referenceItem?.item?.owner) {
    score = score + 1000;
  }

  score += scoreItem(item?.item?.name + " " + item?.item?.description, referenceItem?.item?.name + " " + referenceItem?.item?.description);
  
  return score;
}

export function scoreTextItem(text, item) {
  if (!text) return 0;
  return scoreItem(text, item?.name + " " + item?.description);
}

export function scoreItem(text, clusterTokens) {
  if (!text || !clusterTokens) return 0;
  
  const titleTokens = text.toLowerCase().split(" ");
  let score = titleTokens.length;
  let count = 0;
  const defaultKeywords = "";
  
  clusterTokens = clusterTokens ? clusterTokens.toLowerCase().split(" ") : defaultKeywords.toLowerCase().split(" ");

  if (clusterTokens.length === 1 && titleTokens.includes(clusterTokens[0].toLowerCase())) {
    count += 1;
  }

  clusterTokens.forEach((word) => {
    if (titleTokens.includes(word.toLowerCase())) {
      count += 1;
    }
  });

  titleTokens.forEach((word) => {
    if (clusterTokens.includes(word.toLowerCase())) {
      count += 1;
    }
  });

  // Weight matches in first three tokens of title
  clusterTokens.forEach((word) => {
    for(let i=0; i<titleTokens.length && i<3; i++) {
      if (word.toLowerCase() === titleTokens[i].toLowerCase()) {
        count += 1;
      }
    }
  });

  return count;
}

