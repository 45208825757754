import React, {useEffect} from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Stepper, Step } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import AuthSection from "./AuthSection";
import { Box } from "@material-ui/core";
import { Link } from "./../util/router.js";
import LinkMui from "@material-ui/core/Link";


const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  completed: {
    display: "inline-block",
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  stepper: {
    justifyContent: "center",
    paddingTop: "0px",
  },
}));

function getSteps() {
  return ["Signup", "Signin"];
}

function getStepButton(step) {
  switch (step) {
    case 0:
      return (
        <>
          <Box>
            <Button disableRipple style={{ textTransform: "none" }}>
              Create an account
            </Button>
            <Button
              disableRipple
              component={Link}
              to={"/auth/forgotpass"}
              style={{ textTransform: "none" }}
            >
              Forgot Password
            </Button>
          </Box>
        </>
      );
    case 1:
      return (
        <>
          <Box textAlign="center">
            <Typography variant="body1">
              Have an account already?{" "}
              <span style={{ textTransform: "none", cursor:'pointer' }}>
                <b>Sign in</b>
              </span>
            </Typography>
          </Box>
          <Box textAlign="center" py={2}>
            <Typography color="textSecondary" variant="body2">
              By joining LadyBidwell, you agree to the{" "}
              <LinkMui component={Link} to={"/legal/terms-of-service"}>
                {" "}
                Terms of Service{" "}
              </LinkMui>{" "}
              <LinkMui component={Link} to={"/legal/privacy-policy"}>
                {" "}
                Privacy Policy,{" "}
              </LinkMui>{" "}
              and to receive promotional emails.
            </Typography>
          </Box>
        </>
      );
  }
}

function getStepContent(step) {
  switch (step) {
    case 0:
      return (
        <>
    
          <AuthSection
            bgColor="#ffffff"
            size="small"
            bgImage=""
            bgImageOpacity={1}
            type="signup"
            providers={["google"]}
            // afterAuthPath={router.query.next || "/"}
            hideFooter={true}
          />
        </>
      );
    case 1:
      return (
        <AuthSection
          bgColor="#ffffff"
          size="small"
          bgImage=""
          bgImageOpacity={1}
          type="signin"
          providers={["google"]}
          // afterAuthPath={router.query.next || "/"}
          hideFooter={true}
        />
      );
  }
}

export default function AuthStepper(props) {
  const classes = useStyles();

  const {initialStep} = props;
  const [activeStep, setActiveStep] = React.useState(initialStep);
  const steps = getSteps();

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

useEffect(()=>{

  console.log("AuthStepper activeStep", activeStep)


},[activeStep])

  return (
    <div className={classes.root}>
      <div>
        <div>{getStepContent(activeStep)}</div>

        <Stepper nonLinear activeStep={activeStep} className={classes.stepper}>
          {steps.map((step, index) => {

       if (index === activeStep) {

              return <></>;
            }

            return (
              <>
                <Step key={step} onClick={handleStep(index)}>
                  
                  <div>{getStepButton(index)}</div>
                </Step>
              </>
            );
          })}
        </Stepper>
      </div>
    </div>
  );
}
