import React, { useState, useEffect } from "react";
import MessageCard from "./MessageCard.js";
import {sortByISOCreatedAt} from "../util/array";

const CurrentMessage = (props) => {
    const { messages } = props;
    const [message, setMessage] = useState();
    // Do something to t.getTime()ry and trigger renger
    //if (item.id === null) {
    //}

useEffect(()=>{
  if (!messages) {return;}
  //  const sortedMessages = messages.slice().sort(function (a, b) {
 //     return a.createdAt < b.createdAt;
      //        return age(a.createdAt) < age(b.createdAt);
      //return a.createdAt.seconds < b.createdAt.seconds;
  //  });
//const m = messages;

//messages.map((i)=>{

//  console.log("CurrentMessages id createdAt", i.id, i.createdAt, Date(i.createdAt))


//})
const m = sortByISOCreatedAt(messages, "desc");
/*
const m = messages.sort((a,b) => {}
return (a.createdAt > b.createdAt) ? -1 : ((a.createdAt < b.createdAt) ? 1 : 0);
});
*/
//setSortedMessages(m);
console.log("CurrentMessages m", m)
const currentMessage = m[0];
console.log("CurrentMessages currentMessage", currentMessage)
    setMessage(currentMessage);
  },[messages]);



    return (
      <>
        {messages && messages.length === 0 && (<>No messages found.</>)}
        {(message !== undefined) &&(<MessageCard message={message} broadcast="room" />)}
      </>
    );
    }
  export default CurrentMessage;
