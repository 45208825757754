import AuctionsHome from "./../components/AuctionsHome";
import { useRouter } from "./../util/router.js";
import { useQuery } from "react-query";
import {
  useLocation,
} from "react-router-dom";
import Section from "../components/Section";
import Meta from "./../components/Meta";
import MarketplaceHome from "../components/MarketplaceHome";

function IndexPage(props) {
  const router = useRouter();
  const path = router.query.path;

  const location = useLocation();
  const { pathname, hash, key } = location;


//useEffect(()=>{
//console.log("path", path)
//}, [path])
  return (
    <>
   <Meta 
title="LadyBidwell - Curated finds from the Hampton’s Finest Homes and Sellers."
/>


{/* <Hidden xsDown={true} implementation="css"> */}
   {/* <div style={{position:'fixed', top:0, zIndex:9, width:'100%'}}> */}
    {/* <Announcement /> */}

    {/* </div> */}
  {/* </Hidden> */}
<Section style={{minHeight:'100vh', paddingTop:'0px'}}>
{/* <HomePageCarousel /> */}



{/* <HomepageTypographyHero /> 


<NewsletterSection 
title="LadyBidwell's fabulous calendar"
subtitle="Be the first to know about upcoming LadyBidwell collections in the Hamptons." 
buttonText="Subscribe" 
bgColor="light"  
inputPlaceholder="Your Email"
subscribedMessage="You're now on the list." 
buttonColor="primary"
/>

 */}


{/* <MarketplaceHome /> */}



<AuctionsHome location={location} /> 





</Section>



    </>
  );
}

export default IndexPage;
