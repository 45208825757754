import React from "react";
import { requireAuth, useAuth } from "./../util/auth.js";
import NotFoundPage from './not-found';
import Section from "../components/Section";
import { Container, Grid } from "@material-ui/core";
import SectionHeader from "../components/SectionHeader";
import SellerItemList from "../components/SellerItemList";


function SellerItemsPage(props) {


  const auth = useAuth();

  const userId = auth?.user?.uid;


const isEnabledSeller = auth.user && auth.user.isEnabledSeller;
const isCurator = auth.user && auth.user.isCurator;


if (!(isEnabledSeller || isCurator)) {return( <><NotFoundPage /></>)}



  return (
<>


<Section
      bgColor="orange"
      size="medium"
      // bgImage={props.bgImage}
      // bgImageOpacity={props.bgImageOpacity}
      // style={{backgroundColor:'#333'}}
    >
   
      <Container >
     
        <SectionHeader
          title="All Items"
          subtitle="All Items Further"
          size={4}
          textAlign="center"
          // className={classes.title}
        />
    

      </Container>
   
   </Section>
   <Container >
        <Grid container={true} spacing={4}>
          <Grid item={true} xs={12} md={12}>

          <SellerItemList userId={userId}  />
           
          </Grid>
          
        </Grid>
        </Container>
        </>


    

  );
}

export default requireAuth(SellerItemsPage);
