function addParagraphs(text) {
  const paragraphs = text.split('\n\n'); // split text into paragraphs
  return paragraphs.map((paragraph, index) => (
    <p key={index} dangerouslySetInnerHTML={{ __html: paragraph }} />
  ));
}

const longText = `
Privacy Policy

Last Updated: April 20, 2023

Welcome to LadyBidwell, a decline price auction site that provides a unique shopping experience for our users. Protecting your privacy is of utmost importance to us. This Privacy Policy outlines the types of information we collect, how we use it, and the measures we take to safeguard your personal information.

By using LadyBidwell (the "Site"), you agree to the collection, use, and disclosure of your information in accordance with this Privacy Policy. If you do not agree with this Privacy Policy, please do not use the Site.

Information We Collect
1.1 Personal Information
When you register for an account or participate in an auction on LadyBidwell, we may collect the following personal information:

Full name
Email address
Mailing address
Phone number
Payment information (e.g., credit card details)
User preferences and interests
1.2 Non-Personal Information
We may also collect non-personal information, such as:

IP address
Browser type and version
Operating system
Referring and exit pages
Dates and times of site visits
Clickstream data
How We Use Your Information
2.1 Personal Information
We use your personal information to:

Process and manage auction transactions
Communicate with you about our services, promotions, and updates
Provide customer support
Improve our site and services
Comply with legal obligations
2.2 Non-Personal Information
We use non-personal information to analyze and improve our site's performance, user experience, and offerings.

Information Sharing and Disclosure
3.1 We do not sell, trade, or rent your personal information to third parties. However, we may share your personal information with trusted third parties for the following purposes:

Processing payments and completing transactions
Sending promotional emails and newsletters
Providing customer support
Analyzing website usage and improving our services
Enforcing our Terms of Service
3.2 We may also disclose your personal information when required by law or to protect our legal rights.

Data Security
We employ reasonable security measures, such as encryption and secure server connections, to protect your personal information from unauthorized access, alteration, disclosure, or destruction. However, please note that no method of transmission or storage is 100% secure, and we cannot guarantee the absolute security of your information.

Third-Party Links
Our Site may contain links to other websites. We are not responsible for the content or privacy practices of these third-party sites. We encourage you to review the privacy policies of any external websites you visit.

Children's Privacy
LadyBidwell is not intended for children under the age of 13. We do not knowingly collect personal information from children under 13. If we become aware that a child under 13 has provided us with personal information, we will take steps to delete such information and terminate the child's account.

Changes to This Privacy Policy
We may update this Privacy Policy from time to time. We will notify you of any significant changes by posting the new Privacy Policy on this page and updating the "Last Updated" date at the top of this document. We encourage you to review this Privacy Policy periodically to stay informed about our privacy practices.

Contact Us
If you have any questions or concerns about this Privacy Policy or our privacy practices, please contact us at:

LadyBidwell
info@ladybidwell.com
`;

function LegalPrivacy(props) {
  return (
      <div>
        {addParagraphs(longText)}
      </div>
    );
}

export default LegalPrivacy;