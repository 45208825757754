export function getSlug(text) {
    if (text === undefined) {return ""};
    if (text === null) {return ""};
  //  return text.toLowerCase().replace(/\s+/g,"-");
  //  return slugify(text,"-").toLowerCase();
 // console.log("slugtest",text.toLowerCase()
 // .replace(/[^\w ]+/g, ' '));
  /*
    return text
               .toLowerCase()
               .replace(/ -/g, ' ')
               .replace(/[^\w ]+/g, '')
               //change the below - to a + for Amazon. MT
               .replace(/ +/g, '-');
               */
               const rawSlug =  text.toLowerCase()
               .replace('+', ' ')
               .replace('-', ' ')
               .replace(/[^\w ]+/g, ' ')
               .replace(/ +/g, '-');

               const first = rawSlug.charAt(0);
               const last = rawSlug.charAt(rawSlug.length - 1);
var conditionedSlug = rawSlug;
               if (last ==='-') {conditionedSlug = conditionedSlug.slice(0, -1) }
               if (first ==='-') {conditionedSlug = conditionedSlug.slice(1) }
return conditionedSlug;
  }
