  import Box from "@material-ui/core/Box";

  import Typography from "@material-ui/core/Typography";

  import CircularProgress from "@material-ui/core/CircularProgress";

  import { useAuth } from "./../util/auth.js";
  import { useProfile } from "./../util/db.js";
  import { makeStyles } from "@material-ui/core/styles";



  const useStyles = makeStyles((theme) => ({
    root: {
      // minWidth: 275,
      // height: 140,
      // boxShadow: "1px 0px 4px rgb(0 2 4 / 2%), 0 7px 20px rgb(1 1 1 / 3%)",
      padding: "15px",
      // backgroundColor: "#f5f5f5",

      [theme.breakpoints.down("sm")]: {
        // backgroundColor: "transparent",
        border: "1px solid #c0c0c0",
      },

      "&:hover": {
        // borderColor: "#0000001f",
        // border: "1px solid",
        // backgroundColor: '#f7f7f7',
        border: "#f5f5f5",
        borderStyle: "solid",
        borderWidth: "1px",
      },
    },

    // cardcontent: {
    //   padding:'0px',
    //   "&:last-child": {
    //     paddingBottom: 5
    //   }
    // },

    cardheader: {
      paddingBottom: "5px",
      opacity: "0.5",
      marginBottom: "-25px",
    },

    estimate: {
      justifyContent: "space-between",
      // paddingLeft: '16px',
      // paddingRight: '16px',

      display: "flex",
      alignItems: "center",
    },

    media: {
      // minHeight: "200px",
      height: "auto",
      // width:'100%',
      alignItems: "center",
      [theme.breakpoints.down("xs")]: {
        height: "auto",
      },
      // height: "auto",
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
    },

    paperItems: {
      minHeight: "300px",
    },
    featured: {
      backgroundColor:
        theme.palette.type === "dark" ? theme.palette.action.selected : "#fdf8c2",
    },
    starFeatured: {
      color: theme.palette.warning.main,
    },
    name: {
      overflow: "hidden",
      display: "-webkit-box",
      WebkitLineClamp: 2,
      WebkitBoxOrient: "vertical",

      // [theme.breakpoints.up("md")]: {
      //   lineHeight: "1.5em",
      //   minHeight: "3em",
      // },
    },
  }));



  function OrderPickupDelivery(props) {
    const classes = useStyles();


  const removeMd = require("remove-markdown");
    const auth = useAuth();


    const { item, event } = props;
    var { flavour, spice } = props;

    // This will make a call for "profiles" on every card view.
    const {
      data: profileData,
      status: profileStatus,
      error: profileError,
    } = useProfile(item && item.owner);



    const itemIsEmpty = !item;


    // const itemColor = (item && item.palette && item.palette) ? item.palette.lightVibrant  + "26" : "#f5f5f5";
  /*
    if (item != null) {
      return <>NO ITEM PROVIDED</>;
    }
  */
      return (
        <>
            {(profileStatus === "loading") && (
              <Box py={5} px={3} align="center">
                {profileStatus === "loading" && <CircularProgress size={32} />}
              </Box>
            )}

            {profileStatus !== "loading"  && (
    
                                <>
                          <Typography variant="subtitle1" >
                            <b>Pickup Address:</b> {event?.address}
                          </Typography>
                       

                     
                          <Typography variant="subtitle1">
                            <b>Pickup/Shipping Details:</b>{" "}
                            {event?.pickupandshipping}
                          </Typography>
                   
                          <Typography variant="subtitle1">
                            <b>Seller:</b>   &nbsp;{profileData && profileData.name}
                          </Typography>


                          <Typography variant="subtitle1">
                            <b>Phone:</b> 
                            &nbsp;{removeMd(profileData && profileData.contactNumber)}

                          </Typography>
                  
                     

                          <Typography variant="subtitle1">
                            <b>Email:</b> 
                            &nbsp;{removeMd(profileData && profileData.profileEmail)}

                          </Typography>

                         
                          </>
            )}

        </>
      );
    
    }
  //}
  export default OrderPickupDelivery;
