import React from "react";
import Section from "./Section";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { Typography, Button, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import EventName from "./../components/EventName";
import { Link } from "react-router-dom";
import { useAuth } from "./../util/auth.js";
import { headlineEventPeriod } from "./../util/time.js";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';


const useStyles = makeStyles((theme) => ({
  root: {
    // Add bottom margin if element below
    "&:not(:last-child)": {
      marginBottom: "2rem",
    },
  },
  subtitle: {
    // Subtitle text generally isn't very long
    // so usually looks better to limit width.
  
    // So we can have max-width but still
    // have alignment controlled by text-align.
    display: "inline-block",
  },

  fontFamily: {
    fontFamily: '"Cormorant Garamond", "serif"',
    fontWeight: 500,
  },
  link: {
    textDecoration: "underline",
  },
  title: {
    color: "#ffffff",
  },
}));

function CollectionDetailsSection(props) {
  const classes = useStyles();
  const { event } = props;
  const auth = useAuth();
  const isAuctioneer = auth.user.role && auth.user.role === "auctioneer";

  const eventTypeWordSingular = isAuctioneer ? "Collection" : "Client";
  const eventTypeWordPlural = isAuctioneer ? "Sales" : "Collections";

  // console.log(event);

  //const {data:auctionData, status:auctionStatus} = useAuctionByEvent(event.auction)

  //const {data:eventData, status:eventStatus} = useEvent(auctionData && auctionData.id)
  /*
useEffect(() => {
  if (auctionStatus !== 'success') {return;}
  console.log("auctionData")
  console.log(auctionData)

}, [auctionData, auctionStatus]);

if (auctionStatus !== 'success') {return null;}
*/
  return (
    <Section
      // bgColor="grey"
      size="normal"
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
     
      <Container>

<Box pb={2}>
      <Button variant="outlined" component={Link}
                to={`/clients`} startIcon={<ArrowBackIcon />}>go back to all {eventTypeWordPlural}</Button>
      </Box>

        <Box textAlign="left">
          <Grid container spacing={3} className={classes.root}>
           
           
            <Grid item xs={12} sm={12}>
              {isAuctioneer ? (
                <>
                 <Typography variant="h6" color="textSecondary">Sale:</Typography>
                  <Typography variant="h5" className={classes.link}>
                    <Link to={"/collection" + "/" + event.id}>
                      {event?.name}
                    </Link>
                  </Typography>
                </>
              ) : (
                <>
                  <Typography variant="h6" color="textSecondary">
                    Collection:
                  </Typography>
                  <Typography variant="h5">{event?.name}</Typography>

                  <Typography variant="h6" color="textSecondary" className={classes.subtitle}>
               Add and manage items below. Once you have added an item, submit it for approval. If approved it will appear in the LadyBidwell sale you selected, and a price drop table will be created. You will be able to see the status of the item throughout and after the sale.
                </Typography>


                </>
              )}
            </Grid>


            {/* {isAuctioneer ? null : (
            <Grid item xs={12} sm={6}>
              
                <>
                  <Typography variant="subtitle2" color="textSecondary">
                    Memo:
                  </Typography>
                  <Typography variant="subtitle1">{event?.memo}</Typography>
                </>
           
            </Grid>
   )}

            <Grid item xs={12} sm={6}>
              {isAuctioneer ? (
                <>
                  <Typography variant="subtitle2" color="textSecondary">
                    Location:
                  </Typography>
                </>
              ) : (
                <Typography variant="subtitle2" color="textSecondary">
                  General Location for Pickup (visible):
                </Typography>
              )}

              <Typography variant="subtitle1">{event?.location}</Typography>
            </Grid>

            {isAuctioneer ? (
            <Grid item xs={12} sm={6}>
             
                <>
                  <Typography variant="subtitle2" color="textSecondary">
                    Date:
                  </Typography>

                  <Typography variant="subtitle1">
                    {headlineEventPeriod(event)}{" "}
                  </Typography>
                </>
          
            </Grid>
    ) : null}
         
             
             
              {isAuctioneer && (
                <>
                   <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle2" color="textSecondary">
                    Headline:
                  </Typography>

                  <Typography variant="subtitle1">
                    {" "}
                    {event?.headline}{" "}
                  </Typography>
                  </Grid>
                </>
              )}



{!isAuctioneer && (
<>
<Grid item xs={12} sm={6}>
                  <Typography variant="subtitle2" color="textSecondary">
                    Default Sale that items will be added to:{" "}
                  </Typography>

                  {event && event.auction ? (
                    <>
                      <Typography variant="subtitle1">
                        <EventName event={event?.auction} format={"text"} />{" "}
                      </Typography>
                    </>
                  ) : (
                    "No default set."
                  )}
          </Grid>

                  <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle2" color="textSecondary">
                      Pickup and Shipping Details:
                    </Typography>

                    <Typography variant="subtitle1">
                      {event?.pickupandshipping}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle2" color="textSecondary">
                      Address for Buyer Pickup
                    </Typography>
                    <Typography variant="subtitle1">{event?.address}</Typography>
                  </Grid>
                  </>              
)}     */}
            
          </Grid>
        </Box>
        <Box py={1}>
 <Divider style={{height:'1px'}} />

 </Box>
 <Divider style={{height:'1px'}} />
      </Container>
    </Section>
  );
}

export default CollectionDetailsSection;
