import React, { useState, useEffect } from "react";
import { useAuth, requireAuth } from "./../util/auth.js";
import { createMessage, updateLot } from "./../util/db.js";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { isPastTime } from "./../util/time.js";
import ConfirmDialog from "./ConfirmDialog.js";
import {
  serverTimestamp,
} from "firebase/firestore";

const useStyles = makeStyles((theme) => ({
  deleteButton: {
    backgroundColor: "#000000",
    color: "#ffffff",
  },
}));

export function ItemCancel(props) {
  const classes = useStyles();
  const auth = useAuth();

  const { item, lot, event } = props;

  const isOwner = auth.user.id && auth.user.id === item.owner;

  const isAuctioneer = auth.user.role && auth.user.role === "auctioneer";

  const [canConsign, setCanConsign] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const isAdministrator = auth.user.role && auth.user.role === "auctioneer";
  const canCancel = isAdministrator && (lot.cancelled !== true) && (lot.paid === true);


  //const item.accepted ===  null || item.accepted === undefined ? "Submit" : "Resubmit"}
{/*
  const {
    data: auctionData,
    status: auctionStatus,
    error: auctionError,
  } = useEvent(item && item.auction ? item.auction : undefined);
*/}
  const isAccepted =
    item && item.accepted === undefined ? false : item.accepted;
  const wasConsigned =
    item && item.wasConsigned === undefined ? false : item.wasConsigned;
  const isConsigned =
    item && item.consigned === undefined ? false : item.consigned;

    const isWithdrawn =
    item && item.withdrawn === undefined ? false : item.withdrawn;

    // You can withdrawn an item if:
    // It has been consigned and not yet accepted or rejected.
    // It has been consigned and accepted
    // It has not already been withdrawn

    // const canWithdraw = !isWithdrawn && ((isConsigned && item.accepted === null) || (isConsigned && isAccepted)) 
    //const canWithdraw = isOwner && (item.withdrawn !== false) && (!item.consigned === false) && (item.accepted !== false);

  //  const canWithdraw = isOwner && (item.withdrawn === true) && (item.consigned === true);
// const canWithdraw = true;

const canWithdraw = isOwner && (item.withdrawn === false) && (item.consigned === true);

useEffect(() =>{

//if (auctionStatus !=='success') {return;}
// console.log("auctionData",auctionData);const const

// (!isAuctioneer && item.accepted === true
// isStarted.
var c=true;
if (event && isPastTime(event.startTime)) {
  c=(false);
}

setCanConsign(c);
 
}, [event])

  // Consigned the item and allow it to be withdrawn
  // if the item has not yet been accepted.



  const cancelItem = (item) => {
    if (isAdministrator) {
      //  if (isOwner && item.accepted !== true) {

      //updateItem(item.id, { consigned: false, accepted: null, withdrawn: true });
      updateLot(item.id, {
        cancelled: true,
        cancelledAt: serverTimestamp()
      });
      var message = "Administrator withdrew item from the sale.";

      // createMessage
      // Creates a message in the users collection under messages with a unique reference
      // return firestore.collection("users").doc(datagram.from).collection("messages").doc().set({

      createMessage({
        subject: message,
        item: item.id,
        from: auth.user.id,
        to: "auctioneer",
      });
    } else {
      alert("You must be the Administrator use this feature");
    }
  };

  if (!isAdministrator) {
    return null;
  }

  // accepted
  // consigned

  // if (auctionStatus !== 'success') {return null;}

  return (
    <>
   
      {isAdministrator ? (<>
        <Button
        fullWidth
        variant='outlined'
          disabled={        (!canCancel)}
          checked={lot.cancelld}         
           onClick={() => {setConfirmOpen(true)
           
          }}
          name="checkedA"
          inputProps={{ "aria-label": "secondary checkbox" }}
        >
          Cancel Purchase
        </Button>
        <ConfirmDialog
        title="Cancel Item?"
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={() =>{cancelItem(item)}}
      >
        You are about to cancel this item that has been paid for. Are you sure?
      </ConfirmDialog></>
      ) : null} 

      {/*item.consigned ? (<div>Submitted</div>) : (<div>Submit for approval</div>)*/}
    </>
  );
}

export default requireAuth(ItemCancel);
