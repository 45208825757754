import React from 'react';
import { useRouter } from "./../util/router.js";
import { useEvent } from "./../util/db.js";
import SellerEventItemList from "./../components/SellerEventItemList";
import { requireAuth, useAuth } from "./../util/auth.js";



function AuctioneerAuctionsPage(props) {
    const auth = useAuth();
    const router = useRouter();
    const id = router.query.eventid;
    const {
         data: _event,
         status: eventStatus,
         error: eventError,
       } = useEvent(id);


    return (
        <>
        Auctioneer Page
        {/* {(eventStatus === "loading") ? (<><CircularProgress size={32} /></>) : null} */}
        {(eventStatus === "success" && _event!==null) ? (       
            <>          
         
            {_event.title}
       
            {_event.name}
            <SellerEventItemList event={_event} /> 
            </>
        ) : null}

        {(eventStatus === "error") ? (       
            <>          
            No events retrieved. Please try to refresh your browser.
            </>
        ) : null}

        {(eventStatus === "success" && _event===null) ? (       
            <>          
            No event exists with that name. 
            </>
        ) : null}

        
        </>
    )
}

export default requireAuth(AuctioneerAuctionsPage);
