import React, { useState, useEffect } from "react";
import { useAuth } from "./../util/auth.js";
import {
  useMessagesByUser,
} from "./../util/db.js";
import { makeStyles } from "@material-ui/core/styles";
import ItemsList from "./../components/ItemsList";
import { getById } from "../util/db.js";

const useStyles = makeStyles((theme) => ({
  paperItems: {
    minHeight: "300px",
  },
  featured: {
    backgroundColor:
      theme.palette.type === "dark" ? theme.palette.action.selected : "#fdf8c2",
  },
  starFeatured: {
    color: theme.palette.warning.main,
  },
}));

function ItemsListBids(props) {
  const { filter, tab } = props;
  const classes = useStyles();

  //var itemsStatus = 'loading';
  const auth = useAuth();
  const [items, setItems] = useState();
  const [itemsStatus, setItemsStatus] = useState();

  const {
    data: messages,
    status: messagesStatus,
    error: itemsError,
  } = useMessagesByUser(auth.user.id);

  const isAuctioneer = auth.user.role && auth.user.role === "auctioneer";

  //var items = [];
  var isReady = messagesStatus === "success";

  useEffect(() => {
    if (messagesStatus !== "success") {
      return;
    }

    const uniqueItems = [];
    const map = new Map();
    for (const item of messages) {
      if (!map.has(item.item)) {
        map.set(item.item, true); // set any value to Map
        uniqueItems.push({
          ...item,
        });
      }
    }

    /// Only show live bids

    const itemIds = uniqueItems.map((tempItem) => {
      return tempItem.item;
    });

    const t = getById("lots", itemIds);
    t.then((results) => {
      //kncockedDaownAt is false if active bidding.  Empty if not started and a date if ended.

      const f = results.filter((result) => result.knockedDownAt === false);
      // inject in metadata item to help
      const i = f.map((result) => {
        return { ...result, item: result.id, metadata: { item: result.id } };
      });

      setItems(i);
      setItemsStatus("success");
    });
  }, [messages, messagesStatus]);

  if (itemsStatus !== "success") {
    return null;
  }

  return (
    <>
      BIDS
      <ItemsList likes={false} items={items} tab={tab} />
    </>
  );
}

export default ItemsListBids;

/*

            {items
              .filter((item) => (item.name !== undefined && item.accepted === true))
              .map((item, index) => (


*/
