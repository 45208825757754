import { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import { Tabs, Tab } from "@material-ui/core";
import { useAuth } from "./../util/auth.js";
import { useItemsByOwner, useEventsByOwner, useProfile } from "./../util/db.js";
import { makeStyles } from "@material-ui/core/styles";
import { FormControl, MenuItem, Select } from "@material-ui/core";
import ItemsList from "./../components/ItemsList";
import PaginatedItems from "./../components/PaginatedItems";

import HydratedItems from "./../components/HydratedItems";
import FilteredItems from "./../components/FilteredItems";

import { forceVisible, forceCheck } from "react-lazyload";

import { debugFlag, privateCatchallFlag } from "./../util/dev.js";

const useStyles = makeStyles((theme) => ({
  paperItems: {
    minHeight: "300px",
  },
  featured: {
    backgroundColor:
      theme.palette.type === "dark" ? theme.palette.action.selected : "#fdf8c2",
  },
  starFeatured: {
    color: theme.palette.warning.main,
  },
}));

function SellerItemList(props) {
  const { filter, event, userId } = props;

  //  flavour = flavour === undefined ? "standard" : "simple";

  const classes = useStyles();
  const [thumbStatus, setThumbStatus] = useState("loading");

  const auth = useAuth();

  const {
    data: items,
    status: itemsStatus,
    error: itemsError,
  } = useItemsByOwner(userId);

  const [creatingItem, setCreatingItem] = useState(false);

  const [updatingItemId, setUpdatingItemId] = useState(null);
  //// Event selector

  const [selectedEventId, setSelectedEventId] = useState("");
  const itemsAreEmpty = !items || items.length === 0;
  const [filteredItems, setFilteredItems] = useState([]);
  const [filteredItemsStatus, setFilteredItemsStatus] = useState("waiting");
  const [filterType, setFilterType] = useState("all");
  const [value, setValue] = useState(0);
  const [tabChanged, setTabChanged] = useState(false);

  const {
    data: events,
    status: eventsStatus,
    error: eventsError,
  } = useEventsByOwner(auth.user.uid);

  const {
    data: profile,
    status: profileStatus,
    error: errorState,
  } = useProfile(auth.user.uid);

/*
useEffect(() =>{

if (filteredItems == null) {return;}

forceCheck();

}, [filteredItems]);
*/
  // Filter items by event and filter type
  useEffect(() => {
    if (itemsStatus === "success" && eventsStatus === "success") {
      let filteredItems = items;

      console.log("SellerItemList profile.event", profile && profile.event);

      if (selectedEventId) {
        console.log("Selected event ID: ", selectedEventId);

        filteredItems = filteredItems.filter(
          (item) => item.event === selectedEventId
        );
      }

      var filteredItemsx = filteredItems;
console.log("SellerItemList filteredItemsx", filteredItemsx);
      console.log("SellerItemList filteredItemsx", filteredItemsx);



      if (profile && typeof profile.event !== "undefined" && profile.event === selectedEventId) {
        //const matchEvent = events.find(element=>element.id === profile.event);
        //co

        console.log("SellerItemList profile event", profile.event);

        console.log("SellerItemList events", events);

        const matchingEvents = events
          .filter((event) => {
            if (privateCatchallFlag === true && event.private === true) {
              return true;
            }
            return false;
          })
          .map((event) => {
            return event.id;
          });

        console.log("SellerItemList matchingEvents", matchingEvents);

        const matchEventItems = items
          .filter((item) => {
            console.log("SellerItemList item.event", item.event);

            if (matchingEvents.includes(item.event)) {
              return true;
            }
            return false;
          })
          .map((item) => {
            return { ...item, event: profile.event };
          });

        console.log("SellerItemList matchEventItems", matchEventItems);

        const array = [];
        Object.keys(matchEventItems).forEach((key) => {
          array.push(matchEventItems[key]);
        });

        console.log("SellerItemList array", array);

        //filteredItemsx.push({ ...array });
        filteredItemsx = filteredItemsx.concat(array);
        //  })
      }

      console.log("SellerItemList filteredItemsx", filteredItemsx);

      var filteredItemsy = filteredItemsx;
      if (filter === "star") {
        filteredItemsy = filteredItemsx.filter((item) => item.starred);
      }
      console.log("SellerItemList filteredItemsy", filteredItemsy);
      // Todo: Include known filters here to reduce firestore call load.

      setFilteredItems(filteredItemsy);
      setFilteredItemsStatus("success");
    } else {
      setFilteredItems([]);
      setFilteredItemsStatus("waiting");
    }

    if (props.handleItemCount) {
      console.log("Item count: ", items.length);
      props.handleItemCount(items.length);
    }
  }, [itemsStatus, items, eventsStatus, events, selectedEventId, filter]);

  const filteredEvents = events;

  const handleEventSelect = (event) => {
    console.log("Event selected: ", event.target.value);
    setSelectedEventId(event.target.value);
  };

  const [flavour, setFlavour] = useState();

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    switch (newValue) {
      case 0:
        setFilterType("all");
        setFlavour("plain");
        break;
      case 1:
        setFilterType("sold");
        setFlavour("plain");

        break;
      case 2:
        setFilterType("wip");
        setFlavour("plain");
        break;
    }

    setValue(newValue);
  };
  useEffect(() => {
    console.log("SellerItemList flavour", flavour);
  }, [flavour]);

  useEffect(() => {
    console.log("SellerItemList filterType", filterType);
    // forceCheck();
  }, [filterType]);

  /// TABS

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Add a new state variable to keep track of whether the tab has changed

  if (filteredItemsStatus !== "success") {
    return null;
  }

  return (
    <>
      {itemsError && (
        <Box mb={3}>
          <Alert severity="error">{itemsError.message}</Alert>
        </Box>
      )}

      <Box py={3}>
        <FormControl fullWidth>
          <Select
            variant="filled"
            displayEmpty
            value={selectedEventId}
            onChange={handleEventSelect}
          >
            <MenuItem value="">All Collections</MenuItem>
            {filteredEvents.map((event) => (
              <MenuItem key={event.id} value={event.id}>
                {event.name}
{true && (<>
                {" ("}
                {items.filter((item) => item.event === event.id).length}
                {")"}
</>)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Box pb={1}>
        <Tabs
          value={value}
          onChange={handleTabChange}
          aria-label="simple tabs example"
        >
          <Tab
            label="All"
            {...a11yProps(0)}
            //  onFocus={() => {
            //     setFilterType("all");
            //     setValue(0);
            //   }}
          />

          <Tab
            label="Sold"
            {...a11yProps(1)}
            // onFocus={() => {
            //   console.log("Clicked on sold");
            //   setFilterType("sold");
            //   setValue(1);
            // }}
          />

          <Tab
            label="WIP"
            {...a11yProps(2)}
            // onFocus={() => {
            //   setFilterType("wip");
            //   setValue(2);
            // }}
          />
        </Tabs>
      </Box>

      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        padding={1}
      ></Box>

      <HydratedItems items={filteredItems}>
        <FilteredItems filterType={filterType}>
          <PaginatedItems itemsPerPage={10} filterType={filterType} />

          {/*
      <ItemsList
        likes={false}
        createItem={false}
//        items={filteredItems}
        flavour={"plain"}
        filterType={filterType}
        showSkeleton={false}
itemsPerPage={10}
      />
*/}
        </FilteredItems>
      </HydratedItems>

      {/*
<PaginatedItems likes={false} createItem={false} items={filteredItems} flavour={"plain"} filterType={filterType} showSkeleton={false} itemsPerPage={10} /> 
*/}
    </>
  );
}

export default SellerItemList;
