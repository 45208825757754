import React, { useState, useEffect } from "react";
import { useAuth } from "./../util/auth.js";

import { makeStyles } from "@material-ui/core/styles";
import { loadStripe } from "@stripe/stripe-js";

import PaymentCards from "./../components/PaymentCards";

// import firebase from "../util/firebase";


import { getCustomerByCustomer } from "../util/stripe.js";
import {
  getCustomerId,
} from "../util/stripe.js";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { v4 as uuidv4 } from "uuid";
//import AddPaymentCard from "./AddPaymentCard.js";
import PaymentMethodStatus from "./PaymentMethodStatus.js";
import { updateUser } from "../util/db.js";
import { Box } from "@material-ui/core";

import { getFunctions, httpsCallable } from "firebase/functions";

const functions = getFunctions();


const processStripeClientSecret = httpsCallable(functions,"processStripeClientSecret");

const useStyles = makeStyles((theme) => ({
  paperItems: {
    minHeight: "300px",
  },
  featured: {
    backgroundColor:
      theme.palette.type === "dark" ? theme.palette.action.selected : "#fdf8c2",
  },
  starFeatured: {
    color: theme.palette.warning.main,
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    marginTop: "1em",
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));
function useForceUpdate() {
  const [value, setValue] = useState(0);
  return () => setValue((value) => ++value);
}

function refreshPage() {
  //window.location.reload(false);
}

function PaymentSendSettings(props) {
  const { filter } = props;
  const classes = useStyles();

  const [stripePromise, setStripePromise] = useState(() =>
    loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)
  );

  const [uuid, setUuid] = useState(uuidv4());
  const [refreshComplete, setRefreshComplete] = useState(false);
  //const [, forceUpdate] = useReducer((x) => x + 1, 0);
  //const forceUpdate = useForceUpdate();
  //const [cardExpiry, setCardExpiry] = useState();
  const [isValidUser, setIsValidUser] = useState(false);
  const [addCardDisable, setAddCardDisable] = useState(false);
  const auth = useAuth();

  const [refreshCards, setRefreshCards] = useState();
  /*
  const customerId =
    auth.user && auth.user.payment && auth.user.payment.customer
      ? auth.user.payment.customer
      : null;
*/
  /*
      useEffect(() => {

        const customerId =
        auth.user && auth.user.payment && auth.user.payment.customer
          ? auth.user.payment.customer
          : null;

          setCustomer({...customer,id:customerId} )


      }, [])
*/
  const [customer, setCustomer] = useState();
  //  const [customer, setCustomer] = useState({ id: customerId });
  const [cards, setCards] = useState();

  const canAddCard =
    cards &&
    refreshComplete &&
    auth.user &&
    auth.user.buyer &&
    auth.user.buyer.enabled;

  // Should never be a reason to change custoer id mid session
  /*
  useEffect(() => {
    if (auth.user.payment.customer) {
      setCustomer({ ...customer, id: auth.user.payment.customer });
    }
  }, [auth.user?.payment]);
*/

  function getFirebaseStripeCustomerId() {
    if (auth.user && auth.user.payment === undefined) {
      return true;
    }

    if (
      auth.user &&
      auth.user.payment &&
      auth.user.payment.customer === undefined
    ) {
      return true;
    }

    const customerId =
      auth.user && auth.user.payment && auth.user.payment.customer
        ? auth.user.payment.customer
        : null;
    return customerId;
  }

  function updateCustomer(customer) {
    var customerId = null;
    customerId = getFirebaseStripeCustomerId();
    console.log("Got customer id from Firebase", customerId);

    if (customerId === true) {
      customerId = getCustomerId();
      console.log("Got customer id from Stripe", customerId);
    }

    // HERE

    //console.log("NEED TO ADD A RULE HERE TO UPDATE FIREBASE CUSTOMER
    console.log("updateCustomer", customer);
    console.log("Calling getCustomerByCustomer", customerId);
    const customerRecord = getCustomerByCustomer(customerId);
    //console.log(customerRecord)
    customerRecord
      .then((result) => {
        //console.log("customer", result);
        console.log("getCustomerByCustomer result", result);
        if (result.data === null) {
          setIsValidUser(false);
          return;
        }
        setIsValidUser(true);
        /*
        // Update the user record in Firebase
        var t = {};
        t["payment" + ".payment_method"] =
          result.data.invoice_settings.default_payment_method;
        updateUser(auth.user.uid, t);
*/
        updateUser(auth.user.uid, {
          payment: {
            payment_method: result.data.invoice_settings.default_payment_method,
          },
        });

        // Build the customer object.
        //var c = auth.user.payment;
        const c = {
          ...auth.user.payment,
          customer: customerId,
          payment_method: result.data.invoice_settings.default_payment_method,
        };

        setCustomer(c);

        // setCustomer(result.data);
        //updateUser({customer:result.data})
      })
      .catch((error) => {
        console.log("updateCustomer", error);
        setIsValidUser(false);
      });
  }

  useEffect(() => {
    const uuid = uuidv4();
  }, []);

  /*
Listen for a change to customerId on load up.
  */
  /*
  useEffect(() => {
    if (customer === undefined) {
      return;
    }
    if (customer.id === undefined) {
      return;
    }

    updateCustomer();
  }, [customer]);
  */

  useEffect(() => {
    console.log("PaymentSendSettings cards", cards);
    if (cards === undefined) {
      return;
    }
    if (cards.length < 3) {
      setAddCardDisable(false);
    } else {
      setAddCardDisable(true);
    }
    refreshDefault();
  }, [cards]);

  function refreshDefault() {
    if (cards === undefined) {
      return;
    }

    var foundDefault = false;
    const i = cards.map((card) => {
      const isDefault =
        card.payment_method === customer &&
        customer.invoice_settings &&
        customer.invoice_settings.default_payment_method;
      if (isDefault) {
        foundDefault = true;
      }

      // HERE
    });

    /*
    if (foundDefault === false) {
      props.onStatus({
        type: "info",
        message: "A default card is needed to enable bidding.",
      });
    }
    */
  }
  /*
  useEffect(() => {
    console.log("CHANGE SEEN IN CUSTOMER")
    console.log("customer",customer)
    updatePaymentMethods();
  }, [customer?.invoice?.default_payment_method]);

  */
  function handleRefreshCards(incomingCards) {
    //vestigial?
    if (incomingCards === undefined) {
    console.log("handleRefreshCards toggled refreshCards");
    setRefreshCards(!refreshCards);
    }

    setCards(incomingCards);
    //console.log("PaymentSendSettings handleRefreshCards triggered");
    //updatePaymentMethods();
  }

  function handleRefreshCustomer(customer) {
    updateCustomer(customer);
  }

  /*
Figure out defautl card if customer changes.
  */
  useEffect(() => {
    if (customer === undefined) {
      return;
    }
    if (cards === undefined) {
      return;
    }
    if (customer.invoice_settings === undefined) {
      return;
      //props.onStatus({
      //  type: "info",
      //  message: "You need to add a card to bid.",
      //});
    }

    console.log("CUSTOMER CHANGED");
    console.log("customer", customer);
    console.log(
      "default payment method",
      customer.invoice_settings.default_payment_method
    );
    if (customer && customer.invoice_settings.default_payment_method === null) {
      console.log(
        "SAW THERE IS NO DEFAULT PAYMENT SET - DO NOTHING - NOT OUR BUSINESS."
      );
    }
  }, [customer]);

  const handlePaymentMethodStatusChange = (s) => {
    if (s === undefined) {return;}
    console.log("PaymentSendSettings PaymentMethod onChange s", s);
    if (s.state === 'ok') {
// do nothing
    props.onStatus({type:'success', message:"You are ready to join Lady B's current auction."});

  console.log("Saw s.state", s.state)
  return;
    };

    // Ignore everything and just take the current message from handlePaymentMethodStatus.

console.log("handlePaymentMethodStatusChange", s.message)
    props.onStatus({type:'info', message:s.message})


  };

  //return <>NULL</>;

  return (
    <>
      <Box py={2}>
        {/*<Elements stripe={stripePromise}>*/}

        <Grid container style={{ justifyContent: "space-between" }}>
          <Grid item>
            <Typography variant="h6"> Make Bids & Payments </Typography>
          </Grid>

          <Grid item style={{ justifyContent: "flex-end" }}>
            <PaymentMethodStatus
              cards={cards}
              customer={customer}
              watch={auth.user}
              onChange={(s) => handlePaymentMethodStatusChange(s)}
              onStatus={(s) => props.onStatus(s)}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1">Add up to 3 payment cards.</Typography>
          </Grid>

          <Grid container item style={{ justifyContent: "flex-end" }}>
            <Box pt={2}>
              {/* <AddPaymentCardModal
                disabled={addCardDisable && canAddCard}
                key={uuid}
                customer={customer}
                cards={cards}
                refreshCustomer={(customer) => handleRefreshCustomer(customer)}
                refreshCards={handleRefreshCards}
                onStatus={(s) => props.onStatus(s)}
              /> */}
            </Box>
          </Grid>
        </Grid>

        <PaymentCards
          customer={customer}
          cards={cards}
          watch={refreshCards}
          onStatus={(s) => props.onStatus(s)}
          refreshCards={(c) => handleRefreshCards(c)}
          refreshCustomer={(customer) => handleRefreshCustomer(customer)}
          //refreshCards={handleRefreshCards}
        />
      </Box>
      {/* {(cards && cards.length < 3 && refreshComplete && (auth.user && auth.user.buyer && auth.user.buyer.enabled)) ? (
        <>
          <AddPaymentCard
            key={uuid}
            refreshCustomer={handleRefreshCustomer}
            refreshCards={handleRefreshCards}
            onStatus={(s) => props.onStatus(s)}
          />
        </>
      ) : null} */}

      {/*</Elements>*/}
    </>
  );
}

export default PaymentSendSettings;
