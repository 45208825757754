import { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { apiRequest } from "./util";
import { getStripePriceId } from "./prices";
//import {CardElement, useStripe, useElements} from '@stripe/react-stripe-js';
// import firebase from "./firebase.js";

import { getUser } from "./db.js";
//import { useAuth } from './../util/auth.js';
import { useAuth } from "./../util/auth.js";
import { getFunctions, httpsCallable } from "firebase/functions";

const functions = getFunctions();

const processStripeClientSecret = httpsCallable(
  functions,
  "processStripeClientSecret"
);

const getInvoices = httpsCallable(functions, "getInvoices");
const getPaymentMethods = httpsCallable(functions, "getPaymentMethods");
const getCustomer = httpsCallable(functions, "getCustomer");
const getStripeAccountLinksCreate = httpsCallable(
  functions,
  "getStripeAccountLinksCreate"
);
const getStripeLoginLink = httpsCallable(functions, "getStripeLoginLink");
const getAccount = httpsCallable(functions, "getAccount");
const getBalance = httpsCallable(functions, "getBalance");
const processFinalizeInvoice = httpsCallable(
  functions,
  "processFinalizeInvoice"
);
export const processCustomerPaymentMethodUpdate = httpsCallable(
  functions,
  "processCustomerPaymentMethodUpdate"
);
const processCustomerPaymentMethodDetach = httpsCallable(
  functions,
  "processCustomerPaymentMethodDetach"
);
const processStripePaymentIntent = httpsCallable(
  functions,
  "processStripePaymentIntent"
);
const processStripePaymentItems = httpsCallable(
  functions,
  "processPaymentItems"
);
const processStripeEstimateItems = httpsCallable(
  functions,
  "processEstimateItems"
);

const processStripeCustomerUpdate = httpsCallable(
  functions,
  "processStripeCustomerUpdate"
);
//const auth = useAuth();

let stripe;
// Load the Stripe script
loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY, {
  // Pin to specific version of the Stripe API
  apiVersion: "2020-08-27",
}).then((stripeInstance) => {
  // Set stripe so all functions below have it in scope
  stripe = stripeInstance;
})

export function processPaymentMethodUpdateByCustomer(
  customerId,
  paymentMethod
) {
  //console.log("getStripeAccountLinksCreate", accountId)
  return processCustomerPaymentMethodUpdate({
    customer: customerId,
    paymentMethod: paymentMethod,
  })
    .then(function (result) {
      // console.log(result)
      return Promise.resolve(result.data);
    })
    .catch(function (error) {
      //
      return Promise.reject(error);
    });
}

export function processPaymentMethodDetach(paymentMethod) {
  //console.log("getStripeAccountLinksCreate", accountId)
  return processCustomerPaymentMethodDetach({ paymentMethod: paymentMethod })
    .then(function (result) {
      // console.log(result)
      return Promise.resolve(result.data);
    })
    .catch(function (error) {
      //
      return Promise.reject(error);
    });
}

export async function redirectToCheckout(planId) {
  // Create a checkout session
  const session = await apiRequest("stripe-create-checkout-session", "POST", {
    priceId: getStripePriceId(planId),
    successUrl: `${window.location.origin}/dashboard?paid=true`,
    cancelUrl: `${window.location.origin}/pricing`,
  });

  // Ensure if user clicks browser back button from checkout they go to /pricing
  // instead of this page or they'll redirect right back to checkout.
  window.history.replaceState({}, "", "/pricing");

  // Redirect to checkout
  return stripe.redirectToCheckout({
    sessionId: session.id,
  });
}

export async function redirectToBilling() {
  // Create a billing session
  const session = await apiRequest("stripe-create-billing-session", "POST", {
    returnUrl: `${window.location.origin}/settings/general`,
  });

  // Ensure if user clicks browser back button from billing they go to /settings/general
  // instead of this page or they'll redirect right back to billing.
  window.history.replaceState({}, "", "/settings/general");

  // Redirect to billing session url
  window.location.href = session.url;
}
/*
processStripeClientSecret()
          //processPaymentAuthorization({paymentMethod:paymentMethod})
          .then(function (clientSecretObject) {
            console.log("clientSecretStuff", clientSecretObject);
            //const clientSecret = 'seti_1InuSsCwAArHtQSXoLafuKSI_secret_JQm0HMDC4zCUetS7tuh7xG3asx9sPwC';
            const clientSecret = clientSecretObject.data.client_secret;
            const customer = clientSecretObject.data.customer;
*/

/*
export function useInvoicesByCustomer(query) {
 // console.log("useInvoiceByCustomer", customerId)
//const data = undefined;
//const error = undefined;
//const status = 'loading';

const initialState = {
  status: query ? "loading" : "idle",
  data: undefined,
  error: undefined,
};

  // Setup our state and actions
  const [state, dispatch] = useReducer(reducer, initialState);

  const queryCached = useMemoCompare(query, (prevQuery) => {
    // Use built-in Firestore isEqual method to determine if "equal"
    return prevQuery && query && query.isEqual(prevQuery);
  });

  useEffect(() => {
    // Return early if query is falsy and reset to "idle" status in case
    // we're coming from "success" or "error" status due to query change.
    if (!queryCached) {
      dispatch({ type: "idle" });
      return;
    }

    dispatch({ type: "loading" });

    // Subscribe to query with onSnapshot
    // Will unsubscribe on cleanup since this returns an unsubscribe function
    //return queryCached.onSnapshot(
    //  (response) => {
const customerId = query;
    queryCached = getInvoices({customer:customerId})
.then(function(result) {

  //console.log("result",result.data)
  //return {data:result.data, error:false, status:'success'}
  dispatch({ type: "success", payload: result });
  return queryCached;
})
.catch(function(error) {
        //console.log(error)
        dispatch({ type: "error", payload: error });
      }
    );
  }, [queryCached]); // Only run effect if queryCached changes

  return state;
}
*/

export function getPaymentMethodStatus() {
  //console.log("auth",auth)

  //const auth = useAuth();

  //console.log(auth);
  // NEED TO ADD IN HERE GETTING THE auth.user

  return true;
}

export function getInvoicesByCustomer(customerId) {
  return getInvoices({ customer: customerId })
    .then(function (result) {
      // console.log(result)
   //
   console.log("stripe getInvoicesByCustomer result",result)

      return Promise.resolve(result);
    })
    .catch(function (error) {
      //
      console.log("stripe getInvoicesByCustomer error",error)
      return Promise.reject(error);
    });
}

/*
Check what this does with an unknown customerId
*/
export function getCustomerByCustomer(customerId) {
  console.log("getCustomerByCustomer customerId", customerId);

  if (customerId == null) {

    return Promise.reject("No customer id.")

  }
  return getCustomer({ customer: customerId })
    .then(function (result) {
      // console.log(result)
      //      Promise.resolve(result.data);
      return result.data;
    })
    .catch(function (error) {
      console.log("stripe getCustomer error", error)
      //
      return Promise.reject(error);
    });
}

export function getPaymentMethodsByCustomer(customerId) {
  return getPaymentMethods({ customer: customerId })
    .then(function (result) {
      // console.log(result)
      return Promise.resolve(result.data);
    })
    .catch(function (error) {
      //
      return Promise.reject(error);
    });
}

export function getAccountLinkByAccount(accountId) {
  console.log("getStripeAccountLinksCreate", accountId);
  return getStripeAccountLinksCreate({ account: accountId })
    .then(function (result) {
      // console.log(result)
      return Promise.resolve(result.data);
    })
    .catch(function (error) {
      //
      return Promise.reject(error);
    });
}

export function getDashboardLinkByAccount(accountId) {
  console.log("getStripeAccountLinksCreate", accountId);
  return getStripeLoginLink({ account: accountId })
    .then(function (result) {
      // console.log(result)
      return Promise.resolve(result.data);
    })
    .catch(function (error) {
      //
      return Promise.reject(error);
    });
}

export function getAccountByAccount(accountId) {
  console.log("getAccountByAccount", accountId);
  return getAccount({ account: accountId })
    .then(function (result) {
      // console.log(result)
      console.log("getAccountByAccount result", result);
      return Promise.resolve(result.data);
    })
    .catch(function (error) {
      //
      return Promise.reject(error);
    });
}
// THIS IS NOT RIGHT.
export function getBalanceByAccount(accountId) {
  console.log("getBalance accountId", accountId);
  return getBalance({ account: { stripe_account: accountId } })
    .then(function (result) {
      console.log("getBalanceByAccount result", result);
      return Promise.resolve(result.data);
    })
    .catch(function (error) {
      //
      return Promise.reject(error);
    });
}

export function processInvoice(invoiceId) {
  console.log("getBalance", invoiceId);
  return processFinalizeInvoice({ invoice: invoiceId })
    .then(function (result) {
      // console.log(result)
      return Promise.resolve(result.data);
    })
    .catch(function (error) {
      //
      return Promise.reject(error);
    });
}

export function getInvoicesByUserID(userID) {
  console.log("stripe getInvoicesByUserID userID", userID);

  const userPromise = getUser(userID);

  userPromise
    .then((doc) => {
      const user = doc.data();
      console.log("stripe getUser", user);
      return getInvoicesByUser(user);
      /*
const invoices = getInvoicesByUser(user);
console.log("stripe getInvoicesByUserID invoices",invoices);
return invoices;
*/
    })
    .catch((error) => {
      console.log("Error getting document:", error);
    });

  //console.log("stripe getInvoicesByUserID user",user);
  //const invoices = getInvoicesByUser(user);
  //console.log("stripe getInvoicesByUserID invoices",invoices);
  //return invoices;
}

export function getInvoicesByUser(user) {
  const filter = "payment_draft_open";
  const customerId = user && user.payment && user.payment.customer;
  console.log("stripe getInvoicesByUser user", user);
  console.log("stripe getInvoicesByUser customerId", customerId);

  getInvoicesByCustomer(customerId)
    .then(function (result) {
      var invoices = [];
      if (filter === "payment_draft_open") {
        invoices = result.data.data.filter(
          (invoice) => invoice.status === "open" || invoice.status === "draft"
        );
      }
      if (filter === "payment_paid") {
        invoices = result.data.data.filter(
          (invoice) => invoice.status === "paid"
        );
      }
      const g = invoices.map((invoice) => {
        const items = invoice.lines.data.map((line) => {
          return { id: line.metadata.item };
        });
        return { invoice: invoice, items: items };
      });
      return g;
      //setGroups(g);
      //setGroupsStatus("success");
    })
    .catch(function (error) {
      return [];
      //setGroupsStatus("failed");
    });
}

export function getCustomerId() {
  // const auth = useAuth();
  // var c = auth?.user?.payment?.customer;
  //if (c) {return c;}
  //  if (customer && customer.id !== undefined) {
  //    c = customer.id;
  //  }

  // Call processStripeClientSecret
  // This creates a setup intent.

  // Is that right?

  //customerId = getFirebaseStripeCustomerId();

  processStripeClientSecret()
    .then(function (clientSecretObject) {
      const clientSecret = clientSecretObject.data.client_secret;
      const customer = clientSecretObject.data.customer;
      console.log("getCustomerId customer", customer);
      return customer;
    })
    .catch((error) => {
      console.log("getCustomerId error", error);
      return null;
    });
}

export function useSetupIntent() {
  // const auth = useAuth();
  // var c = auth?.user?.payment?.customer;
  //if (c) {return c;}
  //  if (customer && customer.id !== undefined) {
  //    c = customer.id;
  //  }

  // Call processStripeClientSecret
  // This creates a setup intent.

  // Is that right?

  //customerId = getFirebaseStripeCustomerId();
  const [clientSecret, setClientSecret] = useState();
  useEffect(() => {
    const fetchSecret = async () => {
      processStripeClientSecret()
        .then(function (clientSecretObject) {
          const clientS = clientSecretObject.data.client_secret;
          console.log(clientSecretObject);
          setClientSecret(clientS);
          return clientS;
          //const customer = clientSecretObject.data.customer;
          //console.log("getCustomerId customer", customer)
          //return customer;
        })
        .catch((error) => {
          setClientSecret("");
          console.log("stripe fetchSecret error", error);
          Promise.reject("Could not get stripe client secret");
        });

      //setClientSecret("");
    };
    fetchSecret();
  }, []);

  return clientSecret;
}

export function usePaymentIntent(dummyVariable, items) {
  const auth = useAuth();

  const user = auth && auth.user ? auth.user : null;

  const [clientSecret, setClientSecret] = useState();
  useEffect(() => {

    

    const fetchSecret = async () => {
      console.log("fetchsecret");
      processStripePaymentIntent({
        test: dummyVariable,
        items: [{ id: "xl-tshirt" }],
      })
        .then(function (clientSecretObject) {
          const clientS =
            clientSecretObject &&
            clientSecretObject.data &&
            clientSecretObject.data.client_secret
              ? clientSecretObject.data.client_secret
              : true;

          setClientSecret(clientS);
          return clientSecret;
        })
        .catch((error) => {
          setClientSecret(true);

          Promise.reject("Could not get stripe client secret");
        });

      setClientSecret("");
    };
    fetchSecret();
  }, [user]);

  return clientSecret;
}

// Does dummy trigger an update when changed.
export function useSellerCustomer() {
  const auth = useAuth();

  const user = auth && auth.user ? auth.user : null;

  const [response, setResponse] = useState();

  useEffect(() => {
    const fetchCustomer = async () => {
      if (!user) return;
      if (!user.seller) return;
      if (!user.seller.customer) return;

      const customerId = user.seller.customer;
      //console.log("user.seller", user.seller);

      getCustomerByCustomer(customerId)
        .then((result) => {
          console.log("sellerCustomer stripe", result.data, customerId);
          setResponse(result.data.name);
        })
        .catch((error) => {
          console.error("stripe sellerCustomer getCustomer error", error);
        });
    };

    fetchCustomer();
  }, [user]);

  return response;
}

export async function pushStripeCustomer(customerId, datagram) {
  console.log("pushStripeCustomer datagram, customerId", datagram, customerId);

  //const d = {
  //          name: "mmmark",
  //        }
  if (!datagram) return true;
  if (!customerId) return true;

  const a = processStripeCustomerUpdate({
    customer: customerId,
    datagram: datagram,
  })
    .then((result) => {
      console.log("stripe setCustomer result", result);
      return result;
    })
    .catch((error) => {
      console.error("stripe setCustomer error", error);

      Promise.reject("Could not update stripe customer");
    });

  return a;
}
