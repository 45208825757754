import React, { useEffect, useState } from "react";
import { Box, Card, CardContent } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import CircularProgress from "@material-ui/core/CircularProgress";
import List from "@material-ui/core/List";
import EditItemModal from "./EditItemModal";
import { makeStyles } from "@material-ui/core/styles";
import ItemStrip from "./../components/ItemStrip";
import ItemIdStrip from "./../components/ItemIdStrip";
import LazyLoad, { forceVisible, forceCheck } from "react-lazyload";


import { getHydratedItem } from "./../util/db.js";

import { debugFlag } from "./../util/dev.js";

const useStyles = makeStyles((theme) => ({
  paperItems: {
    minHeight: "300px",
  },
  featured: {
    backgroundColor:
      theme.palette.type === "dark" ? theme.palette.action.selected : "#fdf8c2",
  },
  starFeatured: {
    color: theme.palette.warning.main,
  },
  collections: {
    display: "inline-block",
  },
}));

const defaultItemsPerPage = 10;

function ItemsList(props) {
  const classes = useStyles();
  const {
    items,
    likes,
    tab,
    mode,
    flavour,
    spice,
    filterType,
    showSkeleton,
    itemsPerPage: inputItemsPerPage,
  } = props;

  const [itemsPerPage, setItemsPerPage] = useState(
    inputItemsPerPage == null ? defaultItemsPerPage : inputItemsPerPage
  );

  const [itemOffset, setItemOffset] = useState(0);

  // Reasonable assumption to start with.
  const [endOffset, setEndOffset] = useState(itemOffset + itemsPerPage);

  // Simulate fetching items from another resources.
  // (This could be items from props; or items loaded in a local state
  // from an API endpoint with useEffect and useState)
  //  const endOffset = itemOffset + itemsPerPage;
  //console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  const currentItems = items == items.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(items.length / itemsPerPage);

  //const [showSkeleton2, setShowSkeleton2] = useState();

  const { createItem } = props;

  const itemsStatus = "success";
  const itemsListName = props.filter;

  const [creatingItem, setCreatingItem] = useState(false);

  const [updatingItemId, setUpdatingItemId] = useState(null);

  const itemsAreEmpty = !items || items.length === 0;

  //  const [visible, setVisible] = useState();

  const [count, setCount] = useState(0);

  const [page, setPage] = useState(0);

  const [metaItems, setMetaItems] = useState(items);

  const [itemsWindowSize, setItemsWindowSize] = useState(10);

  useEffect(() => {
    console.log("tab", tab);
    // forceVisible();
    // forceCheck();
  }, [items, tab, filterType,]);

  function handleExpandWindow() {
    showMoreItems();
  }

  useEffect(() => {
    forceCheck();
  }, [itemOffset, endOffset]);

  useEffect(() => {
    console.log("ItemsList items", items);
    forceCheck();
  }, [items]);

  const handlePageClick = (event) => {
    //setCount(0);
    setPage(event.selected);
    return;
    /*
    const newOffset = (event.selected * itemsPerPage) % items.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
*/
  };

  useEffect(() => {
    console.log("ItemsList props.filteredItems", props.filtedItems);
  }, [props.filteredItems]);

  function handleItemsWindow() {
    if (count > itemsPerPage * (page + 1)) {
      return;
    }

    console.log("ItemsList handleItemsWindow count", count);

    //const e = endOffset;
    //setEndOffset(e+1);
  }

  function showMoreItems() {
    const i = itemsWindowSize;

    if (items && items.length < i) {
      //setItemsWindowSize(items.length);
      return;
    }

    var n = i;
    if (i + 10 > items.length) {
      n = items.length;
    } else {
      n = n + 10;
    }

    setItemsWindowSize(n);
    console.log("ItemsList itemsWIndowSize", n);
  }

  useEffect(() => {
    //if (itemsAreEmpty) {return;}
    //if (visible === true) {

    //clearInterval(interval);
    //return;}

    //setItemsWindowSize(10);

    const timeout = setTimeout(() => {
      console.log("ItemsList tick");
      showMoreItems();
    }, 10);

    return () => clearTimeout(timeout);
  }, [itemsWindowSize]);

  useEffect(() => {
    console.log("ItemsList itemOffset endOffset", itemOffset, endOffset);
  }, [itemOffset, endOffset]);

  useEffect(() => {
    console.log("ItemsList count", count);
  }, [count]);

  function handleVisible(v, itemIndex, item) {
    //    if (v === false) {
    //      return;
    //    }

    //const isMatch = (element) => element.id === item.id || element.item === item.item;
    //items.findIndex(isMatch);

    //    console.log("ItemsList handleVisible count itemIndex", count, itemIndex, metaItems[itemIndex].filtered);

    const n = getHydratedItem(item.id)
      .then((result) => {
        console.log("ItemsList handleVisible hydratedItem result", result);
        return result;
      })
      .catch((error) => {
        console.log("ItemsList handleVisible hydratedItem error", error);

        return { error: error };
      });
    console.log("ItemsList handleVisible hydratedItem", n);

    const i = { ...items[itemIndex], filtered: true };
    const newItems = items;
    newItems[itemIndex] = i;
    setMetaItems(newItems);

    if (v === false) {
      return;
    }

    if (count > itemsPerPage * (page + 1)) {
      //setEndOffset(itemIndex);
      return;
    }

    const c = count + 1;
    setCount(c);
    //   setVisible(v);

    const e = itemOffset + 1;

    if (e > endOffset) {
      setEndOffset(e);
    }
  }

  useEffect(() => {
    setCount(0);
    //   setVisible(false);

    // Reset meta items visibilities
    setMetaItems(items);

    forceCheck();
    forceVisible();
  }, [filterType]);

  useEffect(() => {
    forceCheck();
  }, [itemsWindowSize]);

  useEffect(() => {
    console.log("ItemsList items xkcd", items);
  }, [items]);

  if (itemsAreEmpty) {
    return (
      <>
        {props.type}{" "}
        <Card variant="outlined">
          <CardContent>
            <Typography variant="h6"> No items</Typography>
          </CardContent>
        </Card>
      </>
    );
  }
  return (
    <>
      <>
        {debugFlag && (
          <>
            FILTERTYPE {filterType}
            <br />
            <br />
            COUNT{"-"}
            {count}
            <br />
            ITEMS WINDOW SIZE{":"}
            {itemsWindowSize}
            <br />
            PAGE{":"}
            {page}
            <br />
          </>
        )}

        {createItem ? (
          <>
            {" "}
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              padding={2}
            >
              <Typography className={classes.text} variant="h5">
                {/* {"Create " + eventTypeWordPlural} */}
                {itemsListName}
              </Typography>
              <Button
                variant="contained"
                size="medium"
                color="primary"
                onClick={() => setCreatingItem(true)}
              >
                {"Create new "}
              </Button>
            </Box>
            <Divider />
          </>
        ) : null}
        {(itemsStatus === "loading" || itemsAreEmpty) && (
          <Box py={5} px={3} align="center">
            {itemsStatus === "loading" && <CircularProgress size={32} />}

            {itemsStatus !== "loading" && itemsAreEmpty && (
              <Typography variant="h4">Add your first item.</Typography>
            )}
          </Box>
        )}
    
        {itemsStatus !== "loading" && items && items.length > 0 && (
          <>
         
            <List disablePadding={true}>
              {items.map((item, index) => {
                console.log("ItemsList key", item.id);
                return (
                  <LazyLoad key={"item_" + item.id} height={300} offset={1000}>
                 <>
                    {item && item.createdAt && (
                      <ItemStrip
                        flavour={flavour}
                        likes={likes}
                        itemId={item.item}
                        item={item}
                        tab={tab}
                        mode={mode}
                        filterType={filterType}
                        onVisible={(v) => {
                          handleVisible(v, index, item);
                        }}
                        //showSkeleton={props.showSkeleton}
                      />
                    )}

                    {item && item.createdAt === undefined && (
                      <ItemIdStrip
                      flavour={flavour}
                        likes={likes}
                        itemId={item.id}
                        tab={tab}
                        mode={mode}
                        filterType={filterType}
                      />
                    )}
                    </>
                 </LazyLoad>
                );
              })}
            </List>
          </>
        )}
      </>

      {createItem && creatingItem && (
       
        <EditItemModal onDone={() => setCreatingItem(false)} />

      )}

      {createItem && updatingItemId && (
        <EditItemModal
          id={updatingItemId}
          onDone={() => setUpdatingItemId(null)}
        />
      )}
    </>
  );
}

export default ItemsList;
