import React, { useState, useEffect, useReducer } from "react";
import { useAuth } from "./../util/auth.js";
import { textPrice } from "./../util/prices.js";
import { makeStyles } from "@material-ui/core/styles";
import { useForm } from "react-hook-form";
import { loadStripe } from "@stripe/stripe-js";
// import firebase from "../util/firebase";

import { getCustomerByCustomer } from "../util/stripe.js";


import StripeConnectedAccount from "./../components/StripeConnectedAccount";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { v4 as uuidv4 } from "uuid";
import { Box } from "@material-ui/core";

import { getFunctions, httpsCallable } from "firebase/functions";

const functions = getFunctions();

const processStripeClientSecret = httpsCallable(
  functions,
  "processStripeClientSecret"
);

const useStyles = makeStyles((theme) => ({
  paperItems: {
    minHeight: "300px",
  },
  featured: {
    backgroundColor:
      theme.palette.type === "dark" ? theme.palette.action.selected : "#fdf8c2",
  },
  starFeatured: {
    color: theme.palette.warning.main,
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    marginTop: "1em",
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

function ConnectPayment(props) {
  const { filter } = props;
  const classes = useStyles();

  const [uuid, setUuid] = useState(uuidv4());

  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  //const forceUpdate = useForceUpdate();
  const [cardExpiry, setCardExpiry] = useState();
  const [isValidUser, setIsValidUser] = useState(false);

  const [customer, setCustomer] = useState();
  // const stripePromise = loadStripe("pk_test_6pRNASCoBOKtIshFeQd4XMUh");
  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
  );
  //const stripe = useStripe();

  const auth = useAuth();


  const { register, handleSubmit, watch, errors, control } = useForm();


  //const isSeller = auth.user && auth.user.seller && auth.user.seller.approvedAt && auth.user.seller.approvedAt !== false;
  const isEnabledSeller = auth.user.isEnabledSeller;

  // const [customerId, setCustomerId] = useState(
  //   auth.user && auth.user.payment && auth.user.payment.customer
  //     ? auth.user.payment.customer
  //     : null
  // );

  const [customerId, setCustomerId] = useState(
    auth.user && auth.user.seller && auth.user.seller.customer
      ? auth.user.seller.customer
      : null
  );

  //const [customer, setCustomer] = useState();

  const isAuctioneer = auth.user.role && auth.user.role === "auctioneer";

  const itemTypeWordSingular = isAuctioneer ? "Auction" : "Collection";
  const itemTypeWordPlural = isAuctioneer ? "Auctions" : "Collections";

  useEffect(() => {
    const customerRecord = getCustomerByCustomer(customerId);

    customerRecord
      .then((result) => {
        if (result.data === null) {
          setIsValidUser(false);
          return;
        }
        setIsValidUser(true);
        setCustomer(result.data);
      })
      .catch((error) => {
        setIsValidUser(false);
      });
  }, [customerId]);

  const handlePayoutMethodStatusChange = (s) => {
    console.log("Heard something");
    console.log(s);
  };

  // https://support.stripe.com/questions/unexpected-1-charge-on-customers-bank-statement
  const CustomerInformation = (props) => {
    if (props.customer === undefined) {
      return null;
    }
    const { customer, dev } = props;

    if (dev === undefined) {
      return (
        <>
          {customerId !== null && isValidUser ? null : "YOU NEED TO ADD A CARD"}
        </>
      );
    }

    console.log("customer", customer);

    return (
      <>
        <Typography>
          Customer status
          {isValidUser ? "OK - Valid user" : "NOT OK - Is not valid user"}
          {customerId === null ? "No CUSTOMER ID" : customerId}
          {customerId !== null && isValidUser
            ? "GREAT YOU HAVE A STRIPE ACCOUNT"
            : "YOU NEED TO ADD A CARD"}
          Customer Billing Details Balance:{" "}
          {textPrice({ amount: customer.balance, currency: customer.currency })}
          Delinquent: {customer.delinquent ? "DELINQUENT" : "IN GOOD STANDING"}
          Name: {customer.name}
          Default invoice payment method:{" "}
          {customer.invoice_settings.default_payment_method}
        </Typography>
      </>
    );
  };

  return (
    <>
      <div>
     
        <Box pb={4}>
          <Grid container style={{ justifyContent: "space-between" }}>
            <Grid item>
              <Typography variant="h5"> Receive Payments </Typography>
            </Grid>
            {isEnabledSeller === true ? (
              <>


                {/* <Grid item style={{ justifyContent: "flex-end" }}>
                  <StripeConnectStatus
                    customer={customer}
                    watch={auth.user}
                    onChange={(s) => handlePayoutMethodStatusChange(s)}
                    onStatus={(s) => props.onStatus(s)}
                  />
                </Grid> */}
                <Grid item xs={12}>
                  <Typography variant="body1" color="textSecondary">
                    Sellers must connect with Stripe (Payment Platform) to get
                    paid for items sold.
                  </Typography>
                </Grid>
                

                <Grid item xs={12}>
                 
                  <StripeConnectedAccount customer={customer} />
                </Grid>
              </>
            ) : null}
          </Grid>
        </Box>
      </div>
    </>
  );
}

export default ConnectPayment;
