import React from "react";
import { Box } from "@material-ui/core";
import Section from "./../components/Section";
import { useRouter } from "./../util/router.js";
import { useUncachedEvent } from "./../util/db.js";
import SellerEventItemList from "./../components/SellerEventItemList";
import CuratorEventItemList from "./../components/CuratorEventItemList";
import { requireAuth, useAuth } from "./../util/auth.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import CollectionDetailsSection from "./../components/CollectionDetailsSection";
import NotFoundPage from "./not-found";
import SellerAnnouncement from "../components/SellerAnnouncement";
import Meta from "./../components/Meta";

function SellerEventPage(props) {
  const auth = useAuth();

  const isEnabledSeller = auth.user && auth.user.isEnabledSeller;
  const isCurator =
    auth.user && (auth.user.isCurator || auth.user.isAuctioneer);
  //    const isSeller = auth.user && auth.user.seller && auth.user.seller.approvedAt && auth.user.seller.approvedAt !== false;

  const router = useRouter();
  const id = router.query.eventid;
  const {
    data: _event,
    status: eventStatus,
    error: eventError,
  } = useUncachedEvent(id);

  if (!(isEnabledSeller || isCurator)) {
    return (
      <>
        <NotFoundPage />
      </>
    );
  }

  return (
    <>


<Meta 
title="Item Management"
/>


    {!isCurator && (<>
    <SellerAnnouncement />
    </>)}

    <Section size="small" style={{ paddingTop: "0px", minHeight: "100vh" }}>
      {(eventStatus === "loading") ? (<>
      
        <Box
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          // backgroundColor: "#ffffff",
          // opacity: 0.9,
          zIndex: 9999,
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box display="block" textAlign="center">
          <CircularProgress size="5em" color="secondary" />
</Box></Box>
      
      </>) : null} 

      {eventStatus === "success" && _event !== null && (
        <>
   
          <CollectionDetailsSection size="medium" event={{ ..._event, id: id }} />
          
          {isCurator ? (
            <>
              <CuratorEventItemList event={_event} />
            </>
          ) : (
            <SellerEventItemList event={_event} />
          )}
        </>
      )}

      </Section>

      {eventStatus === "error" ? (
        <>No events retrieved. Please try to refresh your browser.</>
      ) : null}

      {eventStatus === "success" && _event === null ? (
        <>No event exists with that name.</>
      ) : null}
    </>
  );
}

export default requireAuth(SellerEventPage);
