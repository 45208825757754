import React, { useState } from "react";
import { useAuth } from "./../util/auth.js";

import { makeStyles } from "@material-ui/core/styles";
import {useUserOrderById } from "./../util/db.js";



const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
  },

  content: {
    paddingBottom: 24,
  },

  cardHighlightedFont: {
    fontWeight: "700",
  },
}));

function LotOrder(props) {
  const classes = useStyles();

  const auth = useAuth();
  // const isAuctioneer = auth.user.role && auth.user.role === "auctioneer";

  const { lot, order } = props;
  const { flavour } = props;
  const { spice } = props;

  const userId = auth && auth.user && auth.user.id;
  const orderId= order && order.id;
/*
Provide access to three lot status variabes updated via hok.
  isLotStarted: isLotStarted,
  isPaid: isPaid,
  isKnockedDown: isKnockedDown,
  */

  const { data: statusLot, status: status, error: error } = useUserOrderById(orderId, userId);
  const [price, setPrice] = useState();

  const isKnockedDown =
    lot && lot.knockedDownAt && lot.knockedDownAt !== false ? true : false;

  const isPaid = lot && lot.paid && lot.paid === true ? true : false;

  const lotStatus = "success";
  //const prices = pricesData && pricesData.prices;
  
  // return <>ORDER RELATED CONTENT e.g. related invoice</>;
  return null;
}

export default LotOrder;
