
  export function isSearchMatch(item, searchQuery) {
    if (item == null) {
      return true;
    }
    if (searchQuery == null) {
      return true;
    }
    const haystack = item.name + " " + item.description;
    console.log("search isSearchMatch haystack", haystack);
    //return true;
    return test(haystack, searchQuery, true);
  }

  function buildRegEx(str, keywords) {
    return new RegExp(
      "(?=.*?\\b" + keywords.split(" ").join(")(?=.*?\\b") + ").*",
      "i"
    );
  }

  function test(str, keywords, expected) {
    var result = buildRegEx(str, keywords).test(str) === expected;
    console.log(result ? "Passed" : "Failed");
    return result;
  }

const levenshteinDistance = (s, t) => {
  if (!s.length) return t.length;
  if (!t.length) return s.length;
  const arr = [];
  for (let i = 0; i <= t.length; i++) {
    arr[i] = [i];
    for (let j = 1; j <= s.length; j++) {
      arr[i][j] =
        i === 0
          ? j
          : Math.min(
              arr[i - 1][j] + 1,
              arr[i][j - 1] + 1,
              arr[i - 1][j - 1] + (s[j - 1] === t[i - 1] ? 0 : 1)
            );
    }
  }
  return arr[t.length][s.length];
};
