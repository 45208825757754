import { useState } from "react";
import HeroSection3 from "./../components/HeroSection3";
import ContactSection from "../components/ContactSection";
import WhyJoinTrade from "../components/WhyJoinTrade";
import abstract1 from "./../images/abstract1.jpg";
import spacejoy from "./../images/spacejoy.jpg";
import TradeProgramIntro from "../components/TradeProgramIntro";
import Meta from "./../components/Meta";

function TradePage(props) {

  const [creatingSeller, setCreatingSeller] = useState(false);
const [sellerType, setSellerType] = useState('one off');

const handleCreatingSeller = (type) => {

setSellerType(type);
setCreatingSeller(true);
}

// return (<>
// <div>Foo</div>
// <div>hello</div>
// </>)
  return (
    <>
      {/* <HeroSection2
        bgColor="dark"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Auctions Made JoyFul"
        subtitle="Beautiful items have the power to bring happiness into every home. "
      /> */}

<Meta 
title="Join The Trade Program"
/>




<HeroSection3

bgColor="trade"
        size="medium"
        bgImage={abstract1}
        bgImageOpacity={1}
        
        // buttonText="Apply Now"
        title="Join the Trade Program"
      />

      <TradeProgramIntro
      image={spacejoy}
      />

<WhyJoinTrade
         bgColor="light"
         size="medium"
         bgImage=""
         bgImageOpacity={1}      
        //  image="https://cdn.pixabay.com/photo/2018/01/24/15/08/live-3104077_1280.jpg"
      />


<div id="contact-section">
<ContactSection 
      bgColor="default"
      size="medium"
      bgImage=""
      bgImageOpacity={1}
      title="Apply Now"
      subtitle=""
      buttonText="Submit"
      buttonColor="primary"
      showNameField={true}
      // showBusinessNameField={true}
      showMessageField={true}
      subject="LadyBidwell Trade Enquiry"
/>
</div>


 

  


{/* 
<Button
            variant="contained"
            size="medium"
            color="primary"
            onClick={() => handleCreatingSeller('professional')}
          >
            {"Apply to become a Lady Bidwell Seller"}
          </Button>

          <Button
            variant="contained"
            size="medium"
            color="primary"
            onClick={() => handleCreatingSeller('one off')}
          >
            {"I only have one or two things to sell"}
          </Button>



{creatingSeller && (
        <EditEventModal sellerType={sellerType} onDone={() => setCreatingSeller(false)} />
      )} */}

    </>
  );
}

export default TradePage;
