import { useEffect } from "react";
import Navbar from "./../components/Navbar";
import IndexPage from "./../pages/index";
import AboutPage from "./../pages/about";
import FaqPage from "./../pages/faq";
import PricingPage from "./../pages/pricing";
import ContactPage from "./../pages/contact";
import DashboardPage from "./../pages/dashboard";
import SettingsPage from "./../pages/settings";
import PurchasePage from "./../pages/purchase";
import AuthPage from "./../pages/auth";
import { Switch, Route, Router } from "./../util/router.js";
import NotFoundPage from "./../pages/not-found.js";
import Footer from "./../components/Footer";
import "./../util/analytics.js";
import ContentCardsPage from "./../pages/content-cards";
import AddEventPage from "./../pages/add-event";
import SellerEventPage from "./../pages/seller-event-page";
import CollectionPage from "./../pages/CollectionPage";
import ItemPage from "./../pages/ItemPage";
import AuctioneerAuctionsPage from "./../pages/AuctioneerAuctionsPage";
import { getFirebaseCurrentTime } from "../util/time.js";
import NewSeller from "../pages/NewSeller";
import FaqSeller from "./../pages/faq-seller";
import CartPage from "./../pages/CartPage";
import ProfilePage from "./../pages/ProfilePage";
import TradePage from "./../pages/TradePage";
import { useAuth } from "./../util/auth.js";
import CheckoutPage from "./../pages/CheckoutPage";
import WeeklyGuide from "./../pages/WeeklyGuide";
import WeeklyGuides from "./../pages/WeeklyGuides";
import OrderSummaryPage from "./../pages/OrderSummaryPage";
import StripeElementsCheckoutPage from "./../pages/StripeElementsCheckoutPage";
import LegalPage from "./../pages/legal";
import StripeTestPage from "./../pages/StripeTestPage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PreLaunchPage from "./../pages/PreLaunchPage.js";
import { debugFlag } from "./../util/dev.js";
import UnsupportedBrowser from "./UnsupportedBrowser";
import { useAffiliation } from "./../util/db.js";
import SellerItemsPage from "../pages/SellerItemsPage";

import {CustomView, browserVersion, browserName} from 'react-device-detect';
import { Redirect } from 'react-router-dom';
import MarketplacePage from "../pages/MaketplacePage";


const engineState = process.env.REACT_APP_ENGINE_STATE;

const isPreLaunch = engineState === "pre_launch";
const isProduction = engineState === "production";
const isMaintenance = engineState === "maintenance";

export function AppRouter(props) {
  const auth = useAuth();

  //const isLoggedIn = true;
  const checkoutProvider = "stripe elements";

  const isLoggedIn = auth.user ? true : false;
  // const location = useLocation();
  const pathname = window.location.pathname;
  const x = useAffiliation(null, pathname);
  useEffect(() => {
    var t = getFirebaseCurrentTime(true);
  }, []);

  useEffect(() => {
    console.log("_app auth.user", auth?.user);
  }, [auth]);

  useEffect(() => {
    console.log("AppRoute isPreLaunch", isPreLaunch);
  }, [isPreLaunch]);

  //if (browser.name === 'safari' && browser.versionNumber < 11) {


  const isUnsupportedBrowser = 
  (browserName === "Safari" && browserVersion < 13) ||
  (browserName === "Chrome" && browserVersion < 64) ||
  (browserName === "Internet Explorer") ||
  (browserName === "Opera" && browserVersion < 51) ||
  (browserName === "Firefox" && browserVersion < 58);
 

  if (isMaintenance) {
    return <>Site is in maintenance</>;
  }

  if (auth?.user === undefined) {
    return null;
  }
  if (auth?.user === null) {
    return null;
  }

  return (
    <>

 {/* Render the chat app component only if the current route is not '/no-chat' */}
{/* {pathname === '/clients' && <><Chat />HELLOOO</>}  */}


      {debugFlag && (
        <>
          User Id:
          {auth?.user?.uid} {isLoggedIn ? "LOGGED IN" : "NOT LOGGED IN"}{" "}
          {isPreLaunch ? "PRELAUNCH" : "NOT PRELAUNCH"}{" "}
        </>
      )}
      
      <Router>

      <CustomView condition={isUnsupportedBrowser }>
              <Redirect to="/unsupported-browser" />
</CustomView>


        
        {(isProduction || isLoggedIn) && (
          <>
            <Navbar color="default" />
          </>
        )}

        <Switch>
        <Route exact path="/unsupported-browser" component={UnsupportedBrowser} />
          <Route exact path="/auth/:type" component={AuthPage} />
          {isPreLaunch && !isLoggedIn && (
            <Route exact path="/" component={PreLaunchPage} />
          )}

          {(isProduction || isLoggedIn) && (
            <>
              <Route exact path="/" component={IndexPage} />

              <Route exact path="/about" component={AboutPage} />
              <Route exact path="/sell/apply" component={NewSeller} />
              <Route exact path="/faq" component={FaqPage} />
              <Route exact path="/seller-faq" component={FaqSeller} />
              <Route exact path="/pricing" component={PricingPage} />
              <Route exact path="/trade/apply" component={TradePage} />
              <Route exact path="/contact" component={ContactPage} />

              <Route exact path="/dashboard" component={DashboardPage} />

              <Route exact path="/stripe" component={StripeTestPage} />

              <Route
                exact
                path="/liked"
                render={() => <DashboardPage filter={"liked"} mode={"view"} />}
              />
              <Route
                exact
                path="/bids"
                render={() => <DashboardPage filter={"bids"} />}
              />
              <Route
                exact
                path="/delivery"
                render={() => <DashboardPage filter={"delivery"} />}
              />

              {/* <Route
                exact
                path="/items"
                render={() => <DashboardPage filter={"items"} />}
              /> */}

              <Route
                exact
                path="/orders"
                render={() => <DashboardPage filter={"orders"} />}
              />

              <Route
                exact
                path="/payment"
                render={() => <DashboardPage filter={"payment_draft_open"} />}
              />

              <Route
                exact
                path="/invoices"
                render={() => <DashboardPage filter={"invoices"} />}
              />
              <Route exact path="/legal/:section" component={LegalPage} />
              <Route
                exact
                path="/settings/:section/:message"
                component={SettingsPage}
              />
              <Route exact path="/settings/:section" component={SettingsPage} />

              <Route
                exact
                path="/cart"
                timestamp={Math.random()}
                component={CartPage}
              />
              {checkoutProvider === "stripe checkout" && (
                <Route exact path="/checkout" component={CheckoutPage} />
              )}

              {checkoutProvider === "stripe elements" && (
                <Route
                  exact
                  path="/checkout"
                  component={StripeElementsCheckoutPage}
                />
              )}

              <Route
                exact
                path="/order-summary/:orderId"
                component={OrderSummaryPage}
              />

              <Route exact path="/purchase/:plan" component={PurchasePage} />

              {/* <Route exact path="/item/:id" component={DetailsPage} /> */}

              <Route exact path="/content-cards" component={ContentCardsPage} />

            {/* <Route
                exact
                path="/item/:description/:itemid(\&:ignoreSuffix)?"
                component={ItemPage}
              />  */}

       <Route exact path="/item/:itemid" component={ItemPage} />

               <Route
                exact
                path="/item/:description/:itemid"
                component={ItemPage}
              /> 
              {/*
              <Route
  exact
  path="/item/:description/:itemid&:ignoreSuffix?"
  component={ItemPage}
/>
            */}

       

              <Route exact path="/seller/item/:itemid" component={ItemPage} />

              <Route exact path="/seller/:sellerid" component={ProfilePage} />


              <Route exact path="/clients" component={AddEventPage} />
              <Route exact path="/collections" component={AddEventPage} />
              <Route exact path="/seller/clients" component={AddEventPage} />

              <Route
                exact
                path="/auctioneer/create-auction"
                component={AddEventPage}
              />



<Route
                exact
                path="/hamptons/marketplace"
                component={MarketplacePage }
              />

              {/* <Route exact path="/add-event/:id" component={AddItemsPage} /> */}
              {/* Need to make this work by pointing to CollectionPage */}
              <Route
                exact
                path="/client/:eventid"
                component={SellerEventPage}
              />

              <Route
                exact
                path="/collection/:eventid"
                component={CollectionPage}
              />

              <Route exact path="/items/" component={SellerItemsPage} />

              <Route
                exact
                path="/auctioneer/:eventid"
                component={AuctioneerAuctionsPage}
              />

              <Route
                exact
                path="/weekly-guide/:post/:seller"
                component={WeeklyGuide}
              />

              <Route exact path="/weekly-guide/:post" component={WeeklyGuide} />

              <Route exact path="/weekly-guides" component={WeeklyGuides} />

              {/* <Route
                exact
                path="/firebase-action"
                component={FirebaseActionPage}
              /> */}
              {/* Process seller provided links */}

              {/*
                <Route exact path="/:locator/:path" component={IndexPage} />
                <Route exact path="/:path" component={IndexPage} />
            */}
            </>
          )}

          <Route component={NotFoundPage} />
         
        </Switch>

        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          // draggable
          pauseOnHover
          theme="light"
          style={{ fontSize:'1.2em'}}
        />

        {/* <FirebaseGlobalLoadingWrapper> */}
        {(isProduction || isLoggedIn) && (
          <Footer
            color="light"
            size="small"
            bgImage=""
            bgImageOpacity={1}
            description="Curated finds from the Hampton’s Finest Homes and Sellers."
            copyright="© 2023 LadyBidwell"
            sticky={true}
          />
        )}
        {/* </FirebaseGlobalLoadingWrapper> */}
      </Router>
    </>
  );
}

export default AppRouter;
