import { useState } from "react";
import Box from "@material-ui/core/Box";
import { useAuth } from "./../util/auth.js";
import ItemIssueMessage from "./ItemIssueMessage.js";
import { useUncachedAuctionByEvent } from "./../util/db.js";
import { makeStyles } from "@material-ui/core/styles";
import LotBid from "./../components/LotBid";
//import { loadGetInitialProps } from "next/dist/next-server/lib/utils";
import LotBuy from "./LotBuy.js";
import { useLotStatus } from "./../util/db.js";
import BuyItem from "./BuyItem.js";

const defaultPalette = { lightVibrant: "#cccccc", darkMuted: "#000000" };

const useStyles = makeStyles((theme) => ({
  root: {
    // minWidth: 275,
    // boxShadow: "1px 0px 4px rgb(0 2 4 / 2%), 0 7px 20px rgb(1 1 1 / 3%)",
    padding: "15px",
    borderColor: "transparent",
    // border: '1px solid',
    "&:hover": {
      borderColor: "#0000001f",
      border: "1px solid",
    },
  },
  "&:hover": {
    borderColor: "red",
  },

  estimate: {
    justifyContent: "space-between",
    // paddingLeft: '16px',
    // paddingRight: '16px',

    display: "flex",
    alignItems: "center",
  },

  media: {
    height: "220px",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },

  paperItems: {
    minHeight: "300px",
  },
  starFeatured: {
    color: theme.palette.warning.main,
  },
  name: {
    overflow: "hidden",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
  },
  StipTitle: {
    textDecoration: "none",
    color: "black",
    fontWeight: 600,
  },
}));

function ItemInformation(props) {
  const { likes, item, lot, event, tab, mode } = props;

  const classes = useStyles();

  const [thumbStatus, setThumbStatus] = useState("loading");

  const { isLotStarted, isLotEnded } = useLotStatus({ ...lot, ...item });

  const auth = useAuth();

  const isDelisted =
    lot && lot.delistedAt && lot.delistedAt !== false ? true : false;

  const [itemLot, setItemLot] = useState({});
  const [itemStatus, setItemStatus] = useState("loading");

  if (item && item.palette === undefined) {
    item.palette = defaultPalette;
  }

  const {
    status: auctionStatus,
    data: auction,
    error: auctionError,
  } = useUncachedAuctionByEvent(item && item.auction);

  const isRunning = auction?.state;

  const isOwner =
    auth.user && auth.user.id && auth.user.id === (item && item.owner);
  const isAuctioneer =
    auth.user && auth.user.role && auth.user.role === "auctioneer";

  const handleReady = () => {
    props.onStatus("ready");
  };

  function handleStatus(e) {
    console.log("ItemStrip e", e.messages);
    if (e.messages === "success") {
      console.log("Set item status to success");
      setItemStatus("success");
    }
  }

  return (
    <>
      <Box py={1}>
        {true && (
          <>
            <ItemIssueMessage lot={lot} item={item} />
          </>
        )}
 </Box>
        {!isDelisted && true && (
          <>
            {lot && lot.type && lot.type === "increasing_bid" && (
              <LotBid item={item} lot={lot} />
            )}

            {lot && lot.type && lot.type === "declining_price" && (
            
              <LotBuy
                item={item}
                lot={lot}
                mode={mode}
                onStatus={(e) => handleReady(e)}
              />
            
            )}

{item && item.listingType === "marketplace_price" && (

            
            <LotBuy
              item={item}
              lot={lot}
              mode={mode}
              onStatus={(e) => handleReady(e)}
            />


          )}

          </>
        )}
     
    </>
  );
}

export default ItemInformation;
