import React, { useEffect } from "react";
import { useAuth } from "./../util/auth.js";

import { humanEpochTime } from "./../util/time.js";
import { getFormattedPrice } from "./../util/price.js";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { loadStripe } from "@stripe/stripe-js";

import RequestPayment from "./../components/RequestPayment";
import Button from "@material-ui/core/Button";
import { getFunctions, httpsCallable } from "firebase/functions";

const functions = getFunctions();

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const processStripeClientSecret = httpsCallable(
  functions,
  "processStripeClientSecret"
);

const useStyles = makeStyles((theme) => ({
  paperItems: {
    minHeight: "300px",
  },
  featured: {
    backgroundColor:
      theme.palette.type === "dark" ? theme.palette.action.selected : "#fdf8c2",
  },
  starFeatured: {
    color: theme.palette.warning.main,
  },

  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

function InvoiceGroupHeader(props) {
  const { invoice } = props;
  const { filter } = props;
  const classes = useStyles();

  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const SimpleModal = () => {
    return <>Here goes teh contents of the modal</>;
  };

  const Body = (props) => {
    const { invoice } = props;
    return (
      <div style={modalStyle} className={classes.paper}>
        <h2 id="simple-modal-title">Text in a modal</h2>
        <p id="simple-modal-description">
          <RequestPayment />
          {invoice.id}
          <iframe
            src="https://invoice.stripe.com/i/acct_1InobcCwAArHtQSX/invst_JTNFLmkT23hFGPCCuJ7llOB5YefelD0"
            title="Stripe"
          ></iframe>
        </p>
        <SimpleModal />
      </div>
    );
  };

  // const stripePromise = loadStripe("pk_test_6pRNASCoBOKtIshFeQd4XMUh");
  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
  );

  const auth = useAuth();

  const customerId =
    auth.user && auth.user.payment && auth.user.payment.customer;

  const isAuctioneer = auth.user.role && auth.user.role === "auctioneer";

  const itemTypeWordSingular = isAuctioneer ? "Auction" : "Collection";
  const itemTypeWordPlural = isAuctioneer ? "Auctions" : "Collections";
  /*
  const CheckoutForm = () => {
    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async (event) => {
      // Block native form submission.
      event.preventDefault();

      if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return;
      }

      // Get a reference to a mounted CardElement. Elements knows how
      // to find your CardElement because there can only ever be one of
      // each type of element.
      const cardElement = elements.getElement(CardElement);

      // Use your card Element with other Stripe.js APIs
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
      });

      if (error) {
        console.log("[error]", error);
      } else {
        console.log("[PaymentMethod]", paymentMethod);
      }
    };

    return (
      <form onSubmit={handleSubmit}>
        <CardElement />
        <button type="submit" disabled={!stripe}>
          Pay
        </button>
      </form>
    );
  };
*/

  /*
    const ConfirmCard = () => {
    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async (event) => {
      // Block native form submission.
      event.preventDefault();

      if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return;
      }

      // Get a reference to a mounted CardElement. Elements knows how
      // to find your CardElement because there can only ever be one of
      // each type of element.
      const cardElement = elements.getElement(CardElement);

      // Use your card Element with other Stripe.js APIs
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
      });

      if (error) {
        console.log("[error]", error);
      } else {

        // Make a call to backend to get client secret
        // For now use server.js locally obtained.
        // TODO: Build firebase endpoint to do this.

        const paymentMethodDefault = {
          card: cardElement,
          billing_details: { name: "Jenny Rosen" },
        };

        console.log("payment", paymentMethod);

        processStripeClientSecret()
          //processPaymentAuthorization({paymentMethod:paymentMethod})
          .then(function (clientSecretObject) {
            console.log("clientSecretStuff", clientSecretObject);
            //const clientSecret = 'seti_1InuSsCwAArHtQSXoLafuKSI_secret_JQm0HMDC4zCUetS7tuh7xG3asx9sPwC';
            const clientSecret = clientSecretObject.data.client_secret;


            stripe
              .confirmCardSetup(clientSecret, {
                payment_method: paymentMethod.id,
              })
              .then(function (result) {
                console.log(result);
                const setupIntent = result.setupIntent;
                updateUser(auth.user.id, {
                  payment: {
                    id: setupIntent.id,
                    payment_method: setupIntent.payment_method,
                  },
                });
              })
              .catch(function (error) {
                console.log(error);
              });

            //.then(() => {
            //})
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    };
    return (
      <form onSubmit={handleSubmit}>
        <CardElement />
        <button type="submit" disabled={!stripe}>
          Validate
        </button>
      </form>
    );
  };
*/
  /*
  const handleFinalizeInvoice = (invoice) => {
    processInvoice(invoice.id)
      .then(function (result) {})
      .catch(function (error) {
        console.log("caught error");
      });
  };
*.
/*
  const InvoicePaymentButton = (props) => {
    const { invoice } = props;

    return (
      <>
        {invoice.status === "draft" ? (
          <>
            <Button onClick={() => handleFinalizeInvoice(invoice)}>
              CLOSE AND PAY INVOICE
            </Button>
          </>
        ) : null}

        <button type="button" onClick={handleOpen}>
          Modal Opener Button
        </button>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <Body invoice={invoice} />
        </Modal>
      </>
    );
  };
*/

  useEffect(() => {
    console.log(
      "InvoiceGroupHeader invoice",
      invoice.status_transitions.paid_at
    );
  }, [invoice]);

  return (
    <Box>
      <Box textAlign="right">
        <Box pb={1}>Invoice #{invoice.number} &nbsp;</Box>
        <Button variant="outlined">
          <a href={invoice.invoice_pdf} style={{ textDecoration: "none" }}>
            View Invoice
          </a>
        </Button>
      </Box>

      {/*      INVOICE HEADER */}
      {/*      <InvoicePaymentButton invoice={invoice} /> */}
      <Typography variant="body1">
        {/* <b>Order:</b> #{invoice.number} */}
        <b>Order Placed:</b>{" "}
        {humanEpochTime(invoice.status_transitions.paid_at)}
        <br />
        <b>Status:</b> {invoice.status} <br />
        Total (excl tax): {getFormattedPrice("USD", invoice.subtotal, true)}
          <br />
          Tax: {getFormattedPrice("USD", invoice.tax, true)}
           <br />
        <b>Total (incl tax):</b> {getFormattedPrice("USD", invoice.total, true)}
        <br />
      </Typography>

      {/* <div>
        {" "}
        <a href={invoice.hosted_invoice_url} style={{ textDecoration: "none" }}>
          Hosted Invoice URL Link 
        </a>
      </div> */}

      {/* <div> {invoice.receipt_number} </div> */}
      {/* <Typography variant="body1">{invoice.description}</Typography> */}
      {/*invoice.payment_intent*/}
      {/* <div> {invoice.status} </div> */}
      {/*
<div>  {invoice.hosted_invoice_url} </div>
<div>  {invoice.invoice.pdf} </div>
*/}
    </Box>
  );
}

export default InvoiceGroupHeader;
