import { useState, useEffect } from "react";
import { getPrice, humanPrice } from "./../util/price.js";
import { useShoppingCart } from "use-shopping-cart";
import { isItemLotBuyable } from "./../util/lot.js";
import { useAuth } from "./../util/auth.js";
import { asyncFilter } from "./../util/array.js";

import { getItemLotById } from "./db.js";
import { getAmountPrice } from "../util/price";

function isItemInCart(item, cartDetails) {
  var foundFlag = false;
  Object.keys(cartDetails).map(function (key, index) {
    //console.log(key);
    console.log("cart isInCart key item.id", key, item.id);
    if (key === item.id) {
      foundFlag = true;
    }
  });
  return foundFlag;
}
export function useInCart(item) {
  const {
    cartDetails,
    //clearCart,
    //addItem: addShoppingCartItem,
    //removeItem: removeShoppingCartItem,
  } = useShoppingCart();

  const [flag, setFlag] = useState();

  useEffect(() => {
    if (item === undefined) {
      return;
    }

    if (!item.id) {
      return;
    }

    const foundFlag = isItemInCart(item, cartDetails);
    setFlag(foundFlag);
  }, [cartDetails]);

  return { found: flag };
}




export function useCart(cartUpdateInterval, maxCount) {
  const auth = useAuth();
  const [count, setCount] = useState(0);

  const [timerIntervalId, setTimerIntervalId] = useState();

  const {
    cartDetails,
    clearCart,
    addItem: addShoppingCartItem,
    removeItem: removeShoppingCartItem,
  } = useShoppingCart();

  const [itemLots, setItemLots] = useState();

  useEffect(() => {
    console.log("cart useCart start");
  }, []);

  const [response, setResponse] = useState({
    data: {
      cartCount: null,
      cartItems: null,
      //missedItems: null
    },
    status: "loading",
    error: null,
    clearCart: clearCart,
    //clearCart: clearItems, // Test this with Mike 17 Jan 2023.
    //addItem: addItem, // Deprecate only used in test item
    addItemLot: addItemLot,
    removeItem: removeItem,
    //cartDetails: cartDetails, // All the cart items
  });


  // currently seems only used in test item add
  function clearItems() {
    console.log("cart clearItems");

    clearCart();
    //    updateItemLots();
  }

  // deprecate
  // currently seems only used in test item add
  function addItem(item) {
    console.log("cart addItem item", item);

    addShoppingCartItem(item);
    //    updateItemLots();
  }

  function addItemLot(item, lot) {
    console.log("cart addItemLot item", lot);

    const foundFlag = isItemInCart(cartDetails, item);
    console.log("cart foundFlag", foundFlag);

    if (foundFlag) {
      console.log("cart addItemLot foundFlag seen");
      return;
    }

    const currentPriceAmount = getAmountPrice(lot)

    const cartItem = {
      name: item.name,
      sku: item.id,
      price: currentPriceAmount,
      currency: item.currency,
    };

    console.log("cart addItemLot cartItem", cartItem);

    addShoppingCartItem(cartItem);
  
    console.log("cart addItemLot completed");
  //    updateItemLots();
  }

  function removeItem(id) {
    console.log("cart removeItem id", id);
    const y = removeShoppingCartItem(id);
  }

  




  function updateShoppingCart() {

    console.log("cart updateShoppingCart start");

    const it = [];
    var prom = Object.keys(cartDetails).map(async (sku) => {
      const { name, quantity, price, image } = cartDetails[sku];
console.log("cart updateShoppingCart getItemLotById sku", sku)
      return getItemLotById(sku)
        .then((result) => {
          return { id: sku, ...result };
        })
        .catch((error) => {
          console.log("cart updateShoppingCart error", error);
        });
    });

    return Promise.all(prom).then(function (results) {
      console.log("cart updateShoppingCart results", results);
      return results;
    });
  }

  function updateItemLots() {
    console.log("cart updateItemLots start")
    const r = updateShoppingCart();

    r.then((i) => {
      console.log("cart updateItemLots updateShoppingCart result i", i);

      setItemLots(i);
      return;
    }).catch((error) => {
      console.log("cart updateItemLots error", error);
    });
  }

  // This interval update is not working (as of 4 October 2022)
  // It causes removed items to reappear in the cart after the interval
  // is triggered. It seems that cartDetails is not current.
  // And so the cart is result to the previous state.
  // OK for now.

  useEffect(() => {
    console.log("cart cartUpdateInterval", cartUpdateInterval);
    if (!cartUpdateInterval) {
      return;
    }
    updateItemLots();
    const intervalId = setInterval(() => {
      //      setCartUpdateFlag(true);
      console.log("cart tick");
      console.log("cart tick cartDetails", cartDetails);
      updateItemLots();
      //refreshShoppingCart();
      setCount((prevCount) => prevCount + 1);
    }, cartUpdateInterval);
    setTimerIntervalId(intervalId);

    return () => clearInterval(intervalId);
  }, [cartUpdateInterval]);

  useEffect(() => {
    console.log("count", count);
    if (!maxCount) {
      return;
    }
    if (count > maxCount) {
      clearInterval(timerIntervalId);
    }
  }, [count]);

  useEffect(() => {
    console.log("cart cartDetails", cartDetails);
    updateItemLots();
  }, [cartDetails]);

  async function processItemLots(itemLots) {
    console.log("cart useEffect itemLots");

    // Review these three categories carefully.
    // The item is not buyable, but has not been knocked down, or delisted.

    const unavailable = await asyncFilter(itemLots, async (itemLot) => {
      return isItemLotBuyable(itemLot)
        .then((result) => {
          // if result is true
          // then the lot can be bought
          console.log(
            "cart isLotBuyable unavailable result item, bidOwner, id, uid",
            itemLot.id,
            itemLot.lot.bidOwner,
            auth.user.id,
            auth.user.uid,
          );

          // then the lot can be bought
        /*  console.log(
            "cart isLotBuyable unavailable result",
            itemLot.id,
            result,
            itemLot.item,
            itemLot.item.owner,
            itemLot.lot.bidOwner,
            auth.user.id,
            auth.user.uid,
            auth.user
          );
*/
          if (!auth?.user?.id) {
            return !result;
          }
          //return result;

/*
// Rule to include owner's own items as "unavailable"
          return (
             !result ||
             itemLot.item.owner === auth.user.id ||
             itemLot.lot.bidOwner === auth.user.id
           );
*/

          // Change this rule to allow owner's to buy their own items.

          return (
            !result ||
            itemLot.lot.bidOwner === auth.user.id
          );


        })
        .catch((error) => {
          return true;
        });
    });

    // Someone else bought the item. Or it has been delisted.
    /*
    const missed = await asyncFilter(itemLots, async (itemLot) => {
      if (!auth?.user?.id) {
        return (
          itemLot?.lot?.knockedDownAt !== false &&
          itemLot?.lot?.delistedAt !== undefined
        );
      }

      return (
        itemLot?.lot?.knockedDownAt !== false &&
        itemLot?.lot?.delistedAt !== undefined &&
        itemLot.itemowner !== auth.user.id &&
        itemLot.lot.bidOwner !== auth.user.id
      );
    });
*/
    // https://stackoverflow.com/questions/51140660/react-native-how-to-filter-array-asynchronous-async-await
    // https://advancedweb.hu/how-to-use-async-functions-with-array-filter-in-javascript/
    // Filter out unbuyable items.


    const cartWithoutPrices = await asyncFilter(itemLots, async (itemLot) => {
      return isItemLotBuyable(itemLot)
        .then((result) => {
          if (!auth?.user?.id) {
            return result;
          }


          /*
          return (
            result &&
            itemLot.item.owner !== auth.user.id &&
            itemLot.lot.bidOwner !== auth.user.id
          );
*/

// Include owner's own items in available list.

          return (
            result &&  
            itemLot.lot.bidOwner !== auth.user.id
          );

        })
        .catch((error) => {
          return false;
        });
    });


    const count = cartWithoutPrices.length;



    setResponse({
      ...response,
      cartItems: cartWithoutPrices,
      cartDetails: cartDetails,
      data: {
        cartItems: cartWithoutPrices,
        allItems: itemLots,
        //            missedItems: missed,
        unavailableItems: unavailable,
        cartCount: count,
        //cartTotal: price,
        //cartFormattedTotal: formattedCartPrice,
      },
      status: "in_process",
    });


    console.log("cartWithoutPrices", cartWithoutPrices);

    const cart = cartWithoutPrices.map((itemLot) => {
      const p = getPrice(itemLot.lot);

      return {
        ...itemLot,
        price: p.current.amount,
        id: itemLot.id,
      };
    });

//
    getCartPrice(cart)
      .then((price) => {
        console.log("cart price", price);
        const formattedCartPrice = humanPrice({
          amount: price,
          currency: "USD",
          showCents: true,
        });

        setResponse({
          ...response,
          cartItems: cart,
          cartDetails: cartDetails,
          data: {
            cartItems: cart,
            allItems: itemLots,
            //            missedItems: missed,
            unavailableItems: unavailable,
            cartCount: count,
            cartTotal: price,
            cartFormattedTotal: formattedCartPrice,
          },
          status: "success",
        });
      })
      .catch((error) => {
        console.log("cart error", error);
      });
  }

  useEffect(async () => {
    if (itemLots === undefined) {
      return;
    }
console.log("cart useEffect itemLots")
    processItemLots(itemLots);
  }, [itemLots]);

  return response;
}

async function getCartPrice(cartItemLots) {
  const initialValue = 0;
  return cartItemLots.reduce(
    (previousValue, cartItemLot) =>
      previousValue + getPrice(cartItemLot.lot).current.amount,
    initialValue
  );
}
