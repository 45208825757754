import React, { useState, useEffect, useRef } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useAuth } from "./../util/auth.js";
import { useForm } from "react-hook-form";
import {
  useItem,
  updateItem,
  createItem,
  updateUserItem,
  getFirebaseStorageDownloadImageURL,
  deleteLot,
} from "./../util/db.js";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import ImageUploader from "./../components/ImageUploader";
import EditImages from "./../components/EditImages";
import { v4 as uuidv4 } from "uuid";
import {
  useItemByUser,
  deleteItemImage,
  createMessage,
  // useItemLive
} from "./../util/db.js";

import { centsPrice } from "./../util/price.js";
import { getPalette } from "./../util/colour.js";

import InputAdornment from "@material-ui/core/InputAdornment";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { useEventsByOwner } from "./../util/db.js";
import { Controller } from "react-hook-form";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";

import {
  DialogActions,
  Typography,
  useMediaQuery,
  Slide,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
  Divider
} from "@material-ui/core";
import ConfirmDialog from "./ConfirmDialog.js";
import { debugFlag } from "./../util/dev.js";

import { Switch } from "@material-ui/core";
import { list } from "firebase/storage";

const mobileDebugFlag = false;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
  },

  content: {
    paddingBottom: 24,
    // marginBottom: 100,
  },
  actions: {
    display: "flex",
    height: "80px",
    justifyContent: "space-between",
  },
  dialogTitle: {
    textAlign: "center",
    fontFamily: '"Cormorant Garamond", "serif"',
  },
  backDrop: {
    backdropFilter: "blur(3px)",
    backgroundColor: "rgba(0,0,30,0.4)",
  },

  input: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
}));

function EditItemModal(props) {
  const classes = useStyles();
  const { event, id } = props;
  const auth = useAuth();
  const isAuctioneer = auth.user.role && auth.user.role === "auctioneer";

  const isDeveloper = auth?.user?.developer?.enabled;

  const [editImagesKey, setEditImagesKey] = useState(uuidv4());

  const [canSave, setCanSave] = useState(true);

  const [pending, setPending] = useState(false);
  const [formAlert, setFormAlert] = useState(null);
  const isFirstRun = useRef(true);
  const { register, handleSubmit, errors, control, watch, setValue } = useForm({
    defaultValue: {
      listingType: (itemData && itemData.listingType) || "",
    },
  });

  const listingType = watch("listingType");

  const itemId = id;
  const [imageIndex, setImageIndex] = useState(0);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const { data: itemData, status: itemStatus } = useItem(itemId);
  // const { data: itemData, status: itemStatus } = useItemStatic(itemId);
  const isAdministrator =
    auth.user && auth.user.role && auth.user.role === "auctioneer";
  const isOwner = auth.user && auth.user.id === (itemData && itemData.owner);
  const isReadOnly = isAdministrator;
  const [uploadedImages, setUploadedImages] = useState([]);

  const [unsavedImages, setUnsavedImages] = useState([]);
  const [initialImages, setInitialImages] = useState([]);
  const ref = useRef({ maxIndex: 0 });
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  var isItemUser = false;
  var {
    data: itemUser,
    status: itemUserStatus,
    error: itemUserError,
  } = useItemByUser(itemData);

  const visibleAuctions = useEventsByOwner("curator", listingType === 'declining_price' ? "future" : "market");
  // const visibleAuctions = useEventsByOwner("curator", listingType === 'marketplace_price' ? 'marketplace_price' : "declining_price");

  const [auction, setAuction] = useState(
    event === undefined ? "" : event.auction
);

const [selectedAuction, setSelectedAuction] = useState('');
const [hasListingTypeChanged, setHasListingTypeChanged] = useState("");



useEffect(() => {
  setSelectedAuction("");
  setHasListingTypeChanged(true);
}, [listingType]);

useEffect(() => {
  const auctionExists = visibleAuctions && visibleAuctions.data && 
    visibleAuctions.data.some((_auction) => _auction.id === auction);
  if(auctionExists && !hasListingTypeChanged) {
    setSelectedAuction(auction);
  } else {
    setSelectedAuction("");
  }
  
  // after this hook runs, reset hasListingTypeChanged back to false
  // setHasListingTypeChanged(false);
}, [visibleAuctions, hasListingTypeChanged]);


useEffect(() => {
  console.log("setSelectedauction listingtype, selectedauction", listingType, selectedAuction);
}, [listingType, selectedAuction]);



  if (itemUserStatus === "success" || itemUserStatus === "idle") {
    if (itemUser === undefined) {
      itemUser = { reservePrice: null };
    }
    isItemUser = true;
    //setUploadedImages(itemData.images)
  }

  useEffect(() => {
  console.log("xkcd listingType", listingType);
  if (listingType == null) {return;}
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    
    const lotTypes = ["marketplace_price", "increasing_bid", "declining_price"];
    if (lotTypes.includes(listingType)) {
      console.log("listingtype change", listingType)
      // Do something if the listingType is included in lotTypes
      // For example, you might delete the associated lot
      //  deleteLot(id);  // Assuming `id` is the id of the lot you want to delete.
setValue('auction','');
    }
  }, [listingType]);


  

  const handleEntered = () => {
    window.$crisp.push(["do", "chat:hide"]);
  };

  const handleExited = () => {
    window.$crisp.push(["do", "chat:show"]);
  };

  useEffect(() => {
    console.log("EditItemModal auction", auction);
  }, [auction]);

  useEffect(() => {
    if (itemStatus !== "success") {
      return;
    }

    if (!(itemData.auction == undefined || itemData.auction === null)) {
      setAuction(itemData.auction);
    }
    //setUploadedImages(itemData.images)
    //   setUploadedImages((uploadedImages) => [
    //    ...uploadedImages,
    //     ...itemData.images,
    //  ]);

    setUploadedImages(itemData.images);
    setUnsavedImages(itemData.images);
    setInitialImages(itemData.images);
    ref.current.maxIndex = pickHighest(itemData.images);
  }, [itemData, itemStatus]);



  const onSubmit = (data) => {
    console.log("EditItemModal onSubmit data", data);
    setPending(true);

    //Object.keys(obj).forEach(key => obj[key] === undefined && delete obj[key])

    const latestImages = unsavedImages.map((image, i) => {
      return { ...image, index: i };
    });

    // Check if there is images in initialImages,
    // which are not in the unsavedImages list.
    // If there are they need to be deleted from the image bucket.

    // Go through initial images and remove any not needed
    initialImages.map((image, i) => {
      const found = unsavedImages.find(
        (element) => element.location === image.location
      );

      if (found === undefined) {
        deleteItemImage(image.location);
      }
    });

    // Go through uploaded images and remove any not needed.
    // Including from uploaded and then deleted images.
    uploadedImages.map((image, i) => {
      const found = unsavedImages.find(
        (element) => element.location === image.location
      );

      if (found === undefined) {
        deleteItemImage(image.location);
      }
    });

    // Get the key image.
    // Which is the top image of the set.
    const imageLocation =
      latestImages && latestImages[0] && latestImages[0].location
        ? latestImages[0].location
        : null;

    // And then get it's palette.
    var image_url = "/static/media/abstract1.a589349de9b9d298dcd5.jpg";

    if (imageLocation !== null) {
      image_url = getFirebaseStorageDownloadImageURL(imageLocation);
    }
    //   getItemImage(imageLocation)
    //     .then((image_url) => {
    /*

So here to avoid refactoring ll this code,
I pull in an image to point the palette extractor at.
Ideally this would be refactored and a default palette used.

*/

    if (image_url === null) {
      image_url = "/static/media/abstract1.a589349de9b9d298dcd5.jpg";
    }

    const image = new Image();
    image.crossOrigin = "anonymous";
    image.src = image_url;

    getPalette(image)
      .then((paletteData) => {
        var newItemData = {
          ...data,
          images: latestImages,
          palette: paletteData,
        };

        // Do not save reserve price in public item record
        delete newItemData.reservePrice;
        console.log("EditItemModal handleSubmit newItemData", newItemData);
        const query = itemId
          ? updateItem(itemId, newItemData)
          : createItem({
              owner: auth.user.id,
              event: event.id,
              ...newItemData,
            });

        query
          .then((testResponse) => {
            var i = itemId;
            if (testResponse) {
              i = testResponse.id;
            }

            //const centsReservePrice = Math.floor(data.reservePrice * 100);
            const centsReservePrice = centsPrice(data.reservePrice);
            var privateItem = {
              id: i,
              owner: auth.user.id,
              reservePrice: centsReservePrice,
            };

            updateUserItem(privateItem);

            const subject =
              "Item created. When complete, submit to LadyBidwell for review.";
            const datagram = {
              subject: subject,
              text: "Made item",
              //html: html,
              item: i,
              // from: "system",
              from: auth.user.id,
              //to: auth.user.id,
              to: "auctioneer",
              //template: "accepted",
              //transport: "email",
            };

            createMessage(datagram, auth.user.id);

            // Let parent know we're done so they can hide modal
            props.onDone();
          })
          .catch((error) => {
            if (debugFlag) {
              console.error("EditItemModal error", error);
            }
            // Hide pending indicator
            setPending(false);
            // Show error alert message
            setFormAlert({
              type: "error",
              message: error.message,
            });
          });
        //    })
      })
      .catch((error) => {
        if (debugFlag) {
          console.error("EditEventModal palette error", error);
        }
        // Hide pending indicator
        setPending(false);
        // Show error alert message
        setFormAlert({
          type: "error",
          message: error.message,
        });
      });
  };

  useEffect(() => {
    setEditImagesKey(uuidv4());
    //setMaxIndex(i => pickHighest(uploadedImages))
    ref.current.maxIndex = pickHighest(uploadedImages);
  }, [uploadedImages, setUploadedImages]);

  const pickHighest = (arr) => {
    var maxIndex = 0;
    arr.forEach((el) => {
      const { index } = el;
      if (index > maxIndex) {
        maxIndex = index;
      }
    });
    return maxIndex;
  };

  const handleCancel = () => {
    // Go through initial images and remove any not needed
    unsavedImages.map((image, i) => {
      const found = initialImages.find(
        (element) => element.location === image.location
      );

      if (found === undefined) {
        deleteItemImage(image.location);
      }
    });

    // Go through uploaded images and remove any not needed.
    // Including from uploaded and then deleted images.
    uploadedImages.map((image, i) => {
      const found = initialImages.find(
        (element) => element.location === image.location
      );

      if (found === undefined) {
        deleteItemImage(image.location);
      }
    });

    props.onDone();
  };

  function handleImage(image) {
    console.log('handleimage Image:', image);
    // Get the image meta information to allo image deduplication and deletion/editing.
    const { name, path, lastModifiedDate, size, type } = image.file.file;
  
    ref.current.maxIndex += 1;
  
    var imageData = {
      index: ref.current.maxIndex,
      id: image.filename,
      location: image.location,
      name: name,
      path: path,
      size: size,
      type: type,
    };
  
    setEditImagesKey(uuidv4());
    console.log("EditItemModal handleImage imageData", imageData);
    setUploadedImages((uploadedImages) => [...uploadedImages, imageData]);
    setUnsavedImages((unsavedImages) => [...unsavedImages, imageData]);
    // Update the unsavedImages array with the new image
    setUnsavedImages((prevUnsavedImages) => [...prevUnsavedImages, image]);
  }




  
  

  function handleReorderImages(images) {
    setUnsavedImages((prevImages) => images);
  }





  const handleImageUploaderStatus = (e) => {
    if (e === "loading") {
      setCanSave(false);
      return;
    }

    setCanSave(true);
  };

  if (!itemData && itemId) {
    return null;
  }

  if (isItemUser !== true) {
    return null;
  }

  if (
    (visibleAuctions && visibleAuctions.status !== "success") ||
    auction === false
  ) {
    return null;
  }

  return (
    <Dialog
      TransitionComponent={Transition}
      fullScreen={fullScreen}
      // disableBackdropClick="true"
      open={true}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          props.onDone(event, reason);
        }
      }}
      aria-labelledby="form-dialog-title"
      maxWidth="md"
      // onEntered={handleEntered}
      TransitionProps={{
        onEntered: handleEntered,
      }}
      BackdropProps={{
        classes: {
          root: classes.backDrop,
        },
      }}
    >
      {(debugFlag || mobileDebugFlag) && (
        <>
          DEBUG <br />
          unSavedImages array length {unsavedImages.length}
          <br />
          uploadedImages array length {uploadedImages.length}
          <br />
          initialImages array length {initialImages.length}
          <br />
          UNSAVED IMAGES
          <br />
          {unsavedImages &&
            unsavedImages.map((i) => {
              return (
                <>
                  {i.name}
                  <br />
                </>
              );
            })}
          UPLOADED IMAGES
          <br />
          {uploadedImages &&
            uploadedImages.map((i) => {
              return (
                <>
                  {i.name}
                  <br />
                </>
              );
            })}
          INITIAL IMAGES
          <br />
          {initialImages &&
            initialImages.map((i) => {
              return (
                <>
                  {i.name}
                  <br />
                </>
              );
            })}
          canSave {canSave ? "CAN SAVE" : "UPLOADER ACTIVE"}
        </>
      )}

      <DialogTitle id="form-dialog-title">
        <Typography variant="h4" className={classes.dialogTitle}>
          {itemId && <>Update</>}
          {!itemId && <>Add</>}
          {` `}Item
        </Typography>
      </DialogTitle>
      {(!itemId || itemData) && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent className={classes.content}>
            {formAlert && (
              <Box mb={4}>
                <Alert severity={formAlert.type}>{formAlert.message}</Alert>
              </Box>
            )}

            {isDeveloper && (
              <Switch
                defaultChecked={itemData && itemData.test}
                //onChange={(e) => handleChange("developer.enabled", e)}
                name="test"
                inputProps={{ "aria-label": "secondary checkbox" }}
                inputRef={register()}
              />
            )}

            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12} sm={12}>
                <ImageUploader
                  id={itemId}
                  onImage={handleImage}
                  existingImages={unsavedImages}
                  onStatus={(e) => handleImageUploaderStatus(e)}
                  imageCount={unsavedImages.length} // Add this line
                />
              </Grid>

              <Grid item={true} xs={12} sm={12}>
                <Box pb={6}>
                  <EditImages
                    key={editImagesKey}
                    images={unsavedImages}
                    onReorderImages={handleReorderImages}
                  />
                </Box>
              </Grid>


              <Grid item={true} xs={12}>
              <Box border={1} borderColor="grey.500" borderRadius={4} p={2} style={{ marginTop: '-10px', paddingTop: '20px' }}>
                <FormControl component="fieldset">
             
              
                  <FormLabel component="legend"><Typography variant="subtitle1">Listing Type</Typography></FormLabel>
                 <Divider/>
                  <Controller
                    style={{ flexDirection: "column" }}
                    rules={{ required: "Listing Type is required" }}
                    control={control}
                    defaultValue={itemData?.listingType || ''}
                    // defaultValue={itemData?.listingType}
                    name="listingType"
                    
                    as={
                      <RadioGroup
                      // onChange={(e) => {
                      //   setListingType(e.target.value);
                      // }}
                      >
                        <FormControlLabel
                          value="marketplace_price"
                          control={<Radio />}
                          label="Marketplace Listing"
                        />
                        <FormControlLabel
                          value="declining_price"
                          control={<Radio />}
                          label="Declining Price Sale"
                        />
                      </RadioGroup>
                    }
                  />
                  
                   {/* {errors.listingType && <Typography variant="caption" color="error">{errors.listingType.message}</Typography>} */}
                   <FormHelperText error={Boolean(errors.listingType)}>{errors.listingType?.message}</FormHelperText>
                </FormControl>
               </Box>
              </Grid>

    

<>

              <Grid item={true} xs={12}>
             {listingType && visibleAuctions && (<>
              <FormControl
  className={classes.formControl}
  fullWidth
  variant="outlined"
  disabled={isReadOnly}
>
  <InputLabel
    style={{
      padding: "1px",
      paddingLeft: "2px",
      paddingRight: "2px",
      backgroundColor: "#ffffff",
    }}
  >
    
   
    {selectedAuction ? "LadyBidwell Sale (item will be submitted to this sale)" : "Choose a sale"}
   
  </InputLabel>


    <Controller
       defaultValue={itemData?.auction || ""}  
       fullWidth
       control={control}
       name="auction"
      render={({ onChange, value }) => (
         <Select id="auction-select"value={value}  onChange={
(e) => {onChange(e.target.value)
//setSelectedAuction(value);
}

}>
        
         {/* <MenuItem value="" disabled>Choose a sale</MenuItem> */}

          {listingType && !listingType && <MenuItem disabled>Please select a listing type</MenuItem>}

          {visibleAuctions.data
            .filter(_auction => {
              if (listingType === "marketplace_price") {
                return _auction.type === "marketplace_price";
              } else if (listingType === "declining_price") {
                return _auction.type === "declining_price";
              } else {
                return false; // return all auctions if no specific listingType is selected
              }
            })
            .map((_auction) => (
              <MenuItem key={_auction.id} value={_auction.id}>
                {_auction.name} {_auction.type}
              </MenuItem>
            ))}
        </Select>
             )}
    />
  
</FormControl>

    </>)} 
              </Grid>
              </>

              <Grid item={true} xs={12}>
                <TextField
                  InputProps={{
                    readOnly: isReadOnly,
                  }}
                  variant="outlined"
                  type="text"
                  label="Title"
                  placeholder="e.g. Queen Anne Beveled Glass Pier Mirror, circa 1710"
                  InputLabelProps={{ shrink: true }}
                  name="name"
                  defaultValue={itemData && itemData.name}
                  error={errors.name ? true : false}
                  helperText={errors.name && errors.name.message}
                  fullWidth={true}
                  // autoFocus={true}
                  autoComplete="off"
                  inputRef={register({
                    required: "Please enter a product title",
                  })}
                />
              </Grid>

              <Grid item={true} xs={12}>
                <TextField
                  InputProps={{
                    readOnly: isReadOnly,
                  }}
                  multiline
                  minRows={6}
                  variant="outlined"
                  type="text"
                  label="Description"
                  placeholder="Include all details necessary e.g. provenance, dimensions, features etc.)"
                  InputLabelProps={{ shrink: true }}
                  name="description"
                  defaultValue={itemData && itemData.description}
                  error={errors.name ? true : false}
                  helperText={errors.description && errors.description.message}
                  fullWidth={true}
                  // autoFocus={true}
                  autoComplete="off"
                  inputRef={register({
                    // required: "Please enter a description",
                    required: false,
                  })}
                />
              </Grid>

              <Grid item={true} xs={12}>
                <TextField
                  InputProps={{
                    readOnly: isReadOnly,
                  }}
                  variant="outlined"
                  type="text"
                  label="Item Condition"
                  placeholder="Describe item condition as accurately as possible, highlighting any blemishes/scratches/damage as well as any restoration."
                  InputLabelProps={{ shrink: true }}
                  name="condition"
                  defaultValue={itemData && itemData.condition}
                  error={errors.condition ? true : false}
                  helperText={errors.condition && errors.condition.message}
                  fullWidth={true}
                  // autoFocus={true}
                  autoComplete="off"
                  inputRef={register({
                    // required: "Please enter the item condition",
                    required: false,
                  })}
                />
              </Grid>






{/*/////////////// Declining Price Sale  //////////////////////*/}
{listingType === "declining_price" && ( <>
              {true && (
                <Grid item={true} xs={12}>
                  <TextField
                    className={classes.input}
                    variant="outlined"
                    type="number"
                    label="Starting Price (price item will start at)"
                    name="sellerStartingPrice"
                    defaultValue={
                      itemData ? itemData.sellerStartingPrice : null
                    }
                    error={errors.sellerStartingPrice ? true : false}
                    helperText={
                      errors.sellerStartingPrice &&
                      errors.sellerStartingPrice.message
                    }
                    fullWidth={true}
                    // autoFocus={true}
                    autoComplete="off"
                    inputRef={register({
                      // required: "Please enter a starting price",
                      required: false,
                    })}
                    onFocus={(e) =>
                      e.target.addEventListener(
                        "wheel",
                        function (e) {
                          e.preventDefault();
                        },
                        { passive: false }
                      )
                    }
                    InputProps={{
                      inputProps: { min: 1 },
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                      readOnly: isReadOnly,
                    }}
                  />
                </Grid>
              )}

              <Grid item={true} xs={12}>
                <TextField
                  className={classes.input}
                  variant="outlined"
                  type="number"
                  label="Reserve Price (lowest price item will sell for)"
                  name="reservePrice"
                  defaultValue={
                    itemUser &&
                    itemUser.reservePrice &&
                    itemUser.reservePrice !== null
                      ? (itemUser.reservePrice / 100).toFixed(0)
                      : null
                  }
                  error={errors.reservePrice ? true : false}
                  helperText={
                    errors.reservePrice && errors.reservePrice.message
                  }
                  fullWidth={true}
                  // autoFocus={true}
                  autoComplete="off"
                  onFocus={(e) =>
                    e.target.addEventListener(
                      "wheel",
                      function (e) {
                        e.preventDefault();
                      },
                      { passive: false }
                    )
                  }
                  inputRef={register({
                    // required: "Please enter a reserve price",
                    required: false,
                    validate: (value) => {
                      // Get the value of the first text field
                      const startingPrice = parseFloat(
                        watch("sellerStartingPrice")
                      );
                      if (value >= startingPrice) {
                        return "Reserve price must be less than starting price";
                      }
                    },
                  })}
                  InputProps={{
                    inputProps: { min: 1 },
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                    readOnly: isReadOnly,
                  }}
                />
              </Grid>

              </> )}

{/*/////////////// Marketplace Listing  //////////////////////*/}

{listingType === "marketplace_price" && ( <>
<Grid item={true} xs={12}>
                <TextField
                  className={classes.input}
                  variant="outlined"
                  type="number"
                  label="Price"
                  name="sellerStartingPrice"
          
                  defaultValue={
                    itemData ? itemData.sellerStartingPrice : null
                  }
                  error={errors.sellerStartingPrice ? true : false}
                  helperText={
                    errors.sellerStartingPrice &&
                    errors.sellerStartingPrice.message
                  }





                  fullWidth={true}
                  // autoFocus={true}
                  autoComplete="off"
                  inputRef={register({
                    // required: "Please enter a starting price",
                    required: false,
                  })}
                  onFocus={(e) =>
                    e.target.addEventListener(
                      "wheel",
                      function (e) {
                        e.preventDefault();
                      },
                      { passive: false }
                    )
                  }
                  InputProps={{
                    inputProps: { min: 1 },
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                    readOnly: isReadOnly,
                  }}
                />
              </Grid>
              </>)}
        
            </Grid>
          </DialogContent>

          <DialogActions
            style={{
              position: "sticky",
              bottom: "0",
              background: "#ffffff",
              borderTop: "1px solid #c4c4c4",
              zIndex: "99",
              height: 90,
            }}
          >
 

            <Button
              variant="outlined"
              color="primary"
              size="large"
              onClick={() => {
                setConfirmOpen(true);
              }}
              disabled={pending}
              style={{ justifyContent: "flex-start" }}
            >
              <span>Cancel</span>
            </Button>
            <ConfirmDialog
              title="Cancel"
              open={confirmOpen}
              setOpen={setConfirmOpen}
              onConfirm={() => {
                handleCancel(itemData);
                // window.$crisp.push(["do", "chat:show"]);
              }}
            >
              Are you sure? Any changes you have made will not be saved.
            </ConfirmDialog>

    

            <div style={{ position: "relative" }}>
              {pending && (
                <div
                  style={{
                    background: "rgba(255, 255, 255, 0.5)",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    zIndex: 99,
                  }}
                >
                  <CircularProgress size={20} style={{ color: "#000" }} />
                </div>
              )}

              <Button
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                // onClick={() => {
                //   window.$crisp.push(["do", "chat:show"]);
                // }}
                disabled={pending || !canSave}
              >
                {<span>Save</span>}
              </Button>
            </div>
          </DialogActions>
        </form>
      )}
    </Dialog>
  );
}

export default EditItemModal;


