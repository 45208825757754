import { useEffect, useState } from "react";
import { useItemImages } from "./../util/db.js";
import { headlineEventPeriod } from "./../util/time.js";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { Hidden, Typography, Divider, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PriceDropCountdown from "./PriceDropCountdown.js";
import LearnMoreDrawer from "./../components/LearnMoreDrawer";
import hamptonseaside from "../images/hamptonseaside.jpg";
import { useAuctionByEvent } from "./../util/db.js";
import { isPastTime, humanTime } from "../util/time";
import PriceDropClockTime from "./PriceDropClockTime.js";
import { Link } from "./../util/router.js";


const useStyles = makeStyles((theme) => ({
  // Increase <Container> padding so it's
  // at least half of <Grid> spacing to
  // avoid horizontal scroll on mobile.
  // See https://material-ui.com/components/grid/#negative-margin

  root: {
    width: "fit-content",
    display: "flex",
    // border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    //backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
    "& svg": {
      marginLeft: theme.spacing(1),
    },
    // "& hr": {
    //   margin: theme.spacing(0, 1),
    // },
  },

  cardRoot: {
    position: "relative",
  },
  font: {
    position: "absolute",
    zIndex: "99",
    top: "20%",
    width: "100%",
    // textAlign: "left",
  },

  container: {
    padding: `0 ${theme.spacing(3)}px`,
    // backgroundColor:'#F4F4F4',
    background:
      "conic-gradient(from 223.05deg at 74.52% 40.49%, rgba(255, 255, 255, 0) 0deg, rgba(247, 247, 247, 0.874386) 180deg, rgba(239, 239, 239, 0.73) 360deg)",
  },
  image: {
    // margin: "0 auto",
    // maxWidth: 570,
    // display: "block",
    // height: "auto",
    // width: "100%",
  },

  title: {
    fontFamily: '"Cormorant Garamond", "serif"',
  },
  subtitle: {
    fontWeight: 400,
    // lineHeight: "1.25em",
    // fontFamily: '"Cormorant Garamond", "serif"',
  },
  mobiletitle: {
    // fontWeight: 500,
    lineHeight: "1.25em",
    fontFamily: '"Cormorant Garamond", "serif"',
  },
  mobilesubtitle: {
    lineHeight: "1.25em",
  },

  // divider: {
  //   position: "relative",
  //   marginTop: "90px",
  //   height: "1px",
  // },
  divider: {
    // marginLeft: "-5%",
    // marginRight: "-5%",
    // background:'#f8f72',
    // opacity:'.2',
    // backgroundColor: theme.palette.secondary.main,
  },

  transparent: {
    "&::before": {
      content: "",
      position: "absolute",
      top: "0",
      left: "5%",
      right: "5%",
      width: "90%",
      height: "1px",
      backgroundImage:
        "linear-gradient(to right, transparent, rgb(48,49,51), transparent)",
    },
  },

  desktopImageStyle: {
    position: "relative",
    left: "-20%",
    width: "120%",
    objectFit: "cover",
    overflow: "hidden",
  },

  arrowdown: {
    "&::after": {
      content: "",
      position: "absolute",
      zIndex: "999",
      top: "-7px",
      left: "calc(50% - 7px)",
      width: "14px",
      height: "14px",
      transform: "rotate(45deg)",
      backgroundColor: "white",
      borderBottom: "1px solid #d6b8fd",
      borderRight: "1px solid rgb(48,49,51)",
    },
  },
}));

function HeroSectionLearn(props) {
  const classes = useStyles();


  const { width } = props;
  let imageHeight;

  if (width === "xs") {
    imageHeight = "300px";
  } else if (width === "sm") {
    imageHeight = "400px";
  } else {
    imageHeight = "500px";
  }



  // Check if the auction is running.
  // const { data: collectionData } = useAuctionByEvent(collection.id);


  const MarkDown = (text) => {};

  const defaultImageHeight = 250;



  return (
    <>
      <Box style={{ overflow: "hidden" }}>
        {/* MOBILE VIEW       */}

        <Hidden mdUp>
          <Box style={{ backgroundColor: '#f7f7f7',}} >
          
              <Grid container>
                <Grid item xs={12}>
                 
                    <img
                      src={hamptonseaside}
                      style={{
                        // opacity: backgroundImage1Loaded ? 1 : 0,
                        aspectRatio: "3/2",
                        width: "100%",
                        objectFit: "cover",
                        overflow: "hidden",
                      }}
                    />
                 
                </Grid>

                <Grid item xs={12}>
                  <Box
                    p={3}
                    style={{textAlign:'center'}}  
                  >
                    <Typography
                      variant="h4"
                      className={classes.title}
                      style={{ fontWeight: "500" }}
                      gutterBottom
                    >
                       "It's high time the fine people of the Hamptons had a better option for buying and selling their fineries." - L. Bidwell<br />
                      </Typography>

                      <Box pt={2}>
               <Button variant="outlined" component={Link} to={{
                          pathname: "about",
                          state: { scrollToTop: true },
                        }}>Discover LadyBidwell</Button>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
        
          </Box>
        </Hidden>

        {/* DESKTOP VIEW */}
        <Hidden smDown>
          <Box >
            <Container
              style={{
                overflow: "visible",
                height: imageHeight,
                backgroundColor: '#f7f7f7',
              }}
            >
              <Grid container spacing={3} alignItems="center">

              <Grid item xs={12} md={6}>
                  <Box
                    p={5}
                    sx={{
                      textAlign: { xs: "left", md: "left" },
                      // background: "#f7f7f7",
                      // opacity: ".8",
                      height: "100%",
                    }}
                  >
                    <Typography
                      variant="h4"
                      className={classes.title}
                      style={{ fontWeight: "500", marginBottom: ".75em" }}
                    >
                      "It's high time the fine people of the Hamptons had a better option for buying and selling their fineries." - L. Bidwell<br />
           
                    </Typography>
                   


                    <Box pt={2}>
               <Button variant="outlined" component={Link} to={{
                          pathname: "about",
                          state: { scrollToTop: true },
                        }}>Discover LadyBidwell</Button>
                    </Box>
                  </Box>
                </Grid>




                <Grid item xs={12} md={6}>
               
                    <img
                      src={hamptonseaside}
                      className="object-fit"
                      style={{
                     
                        objectFit: "cover",
                        position: "relative",
                        // left: "-20%",
                        height: imageHeight,
                        width: "120%",
                        overflow: "hidden",
                      }}
                    />
              
                </Grid>
               
              </Grid>
            </Container>
          </Box>
        </Hidden>
      </Box>
    </>
  );
}

export default HeroSectionLearn;
