import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import NewsletterSubscribe from "./NewsletterSubscribe";

const useStyles = makeStyles((theme) => ({
  font: {
    color:'#000000',
    },
    subtitle: {
      fontWeight:'700',
    }
  }

));


function NewsletterSection(props) {
  const {flavour } = props;
  const classes = useStyles();
  const [subscribed, setSubscribed] = useState(false);
  const [userSubscribed, setUserSubscribed] = useState();

  return (
<>
  

<NewsletterSubscribe 
  buttonText="Subscribe"
  buttonColor="secondary"
  inputPlaceholder="Your Email"
  subscribedMessage="You're on the list."
  onSubscribed={() => setSubscribed(true)}
  onUser
  flavour={flavour}
  />


    </>
  );
}

export default NewsletterSection;
