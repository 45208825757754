import { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useAuth } from "./../util/auth.js";
import { updateItem, updateLot, createMessage } from "./../util/db.js";
import { useIdentity } from "./../util/identity.js";
import { makeStyles } from "@material-ui/core/styles";
import { requireAuth } from "./../util/auth.js";
import ConsignItem from "./../components/ConsignItem";
import { useForm } from "react-hook-form";
import TextField from "@material-ui/core/TextField";
import LotEdit from "./../components/LotEdit";
import ItemEdit from "./ItemEdit";
import { updateItemPrices } from "./../util/db.js";
import { makeLots } from "../util/lot.js";
import { forceCheck } from "react-lazyload";
import { zuluTime } from "../util/time.js";
import MarkSoldButton from "./MarkSoldButton.js";
import MarkHoldButton from "./MarkHoldButton.js";


const useStyles = makeStyles((theme) => ({
  paperItems: {
    minHeight: "300px",
  },

  featured: {
    backgroundColor:
      theme.palette.type === "dark" ? theme.palette.action.selected : "#fdf8c2",
  },
  starFeatured: {
    color: theme.palette.warning.main,
  },
  accepted: {
    backgroundColor:
      theme.palette.type === "dark" ? theme.palette.action.selected : "#fdf8c2",
  },
  tickAccepted: {
    color: theme.palette.success.main,
  },
  deleteDisabled: {
    color: theme.palette.action.disabledBackground,
  },
  editDisabled: {
    color: theme.palette.action.disabledBackground,
  },
  notAccepted: {
    color: theme.palette.error.dark,
  },
  hourWaiting: {
    color: theme.palette.warning.dark,
  },
  ListItemSecondaryAction: {
    minWidth: "120px",
  },
  button: {
    margin: theme.spacing(1),
  },
  deleteDivider: {
    marginTop: "1em",
  },
}));

function ItemReview(props) {
  const classes = useStyles();

  const [thumbStatus, setThumbStatus] = useState("loading");
  const { isSeller } = useIdentity();
  // dev not used
  // decided to create a json file on backend only.
  //const test = downloadFirebaseStorageFile("merp");
  //console.log(test);
  const { item, lot, event, likes } = props;

  const auth = useAuth();
  const [choice, setChoice] = useState();

  const [creatingItem, setCreatingItem] = useState(false);

  const [pending, setPending] = useState(false);

  const [lotUpdate, setLotUpdate] = useState();
  //const [lotUpdate, setLotUpdate] = useState();
  const canUseStar =
    auth.user.planIsActive &&
    (auth.user.planId === "pro" || auth.user.planId === "business");

  const canUseAccept = auth.user.role && auth.user.role === "auctioneer";
  const isAuctioneer = auth.user.role && auth.user.role === "auctioneer";

  //åconst isAccepted = item && item.accepted === undefined ? null : item.accepted;
  const isAccepted = item && item.accepted;

  const isOwner = auth.user.id && auth.user.id === item.owner;

  const [canAccept, setCanAccept] = useState(false);

  const isWithdrawn =
    item && item.withdrawn === undefined ? false : item.withdrawn;

  const isConsigned =
    item && item.consigned === undefined ? false : item.consigned;

  useEffect(() => {
    setLotUpdate({
      ...lotUpdate,
      item: item.id,
      startingPrice: item.sellerStartingPrice,
    });
  }, [item]);

  useEffect(() => {
    if (!lotUpdate) {
      //      console.log("LOTUPDATE undefined");
      return;
    }
    //console.log("LOTUPDATE", lotUpdate);

    if (
      lotUpdate.estimatePrice &&
      lotUpdate.startingPrice &&
      (lotUpdate.estimatePrice !== "" || lotUpdate.startingPrice !== "")
    ) {
      setCanAccept(true);
      return;
    }

    if (lotUpdate.estimatePrice === "" || lotUpdate.startingPrice === "") {
      setCanAccept(false);
      return;
    }

    if (item.estimatePrice && lotUpdate.startingPrice) {
      setCanAccept(true);
      return;
    }
    if (item.startingPrice && lotUpdate.estimatePrice) {
      setCanAccept(true);
      return;
    }
    if (item.startingPrice && item.estimatePrice) {
      setCanAccept(true);
      return;
    }
  }, [lotUpdate, setLotUpdate]);

  function handleLot(e) {
    if (e.target.name === "estimatePrice") {
      setLotUpdate({
        ...lotUpdate,
        item: item.id,
        estimatePrice: e.target.value,
      });
    }

    if (e.target.name === "startingPrice") {
      setLotUpdate({
        ...lotUpdate,
        item: item.id,
        startingPrice: e.target.value,
      });
    }

    //console.log("neither estimatePriceor starting Price updated", e)
  }
  const onSubmit = (data) => {
    setPending(true);
    //console.log("data", data);
    var accept = false;
    var consigned = item.consigned;
    if (choice === "reject") {
      accept = false;
      consigned = false;
    }
    if (choice === "accept") {
      accept = true;
    }

    if (canUseAccept) {
      var conditionedLotUpdate = lotUpdate;
      if (conditionedLotUpdate && conditionedLotUpdate.startingPrice) {
        const centsStartingPrice = Math.floor(
          conditionedLotUpdate.startingPrice * 100
        );
        conditionedLotUpdate = {
          ...conditionedLotUpdate,
          startingPrice: centsStartingPrice,
        };
      }

      if (conditionedLotUpdate && conditionedLotUpdate.estimatePrice) {
        const centsEstimatePrice = Math.floor(
          conditionedLotUpdate.estimatePrice * 100
        );
        conditionedLotUpdate = {
          ...conditionedLotUpdate,
          estimatePrice: centsEstimatePrice,
        };
      }

      var closesAt = null;
      var startsAt = null;

      if (event && event.endTime && event.startTime) {
        closesAt = event.endTime;
        startsAt = event.startTime;
      }

      const zuluStartsAt = zuluTime(startsAt);
      var zuluEndsAt = zuluTime(closesAt);
      //    if (type === "increasing_bid") {
      //       zuluEndsAt = indexedTime(zuluTime(closesAt), lotIndex, 0);
      // TODo develop Item Review increasing bid time handling
      //zuluEndsAt = null;
      //     }
      //var lotId = count;
      //    var endAt = null;

      // Note: Review why lot update adds accepted variable.

      if (accept === false) {
        conditionedLotUpdate = {
          ...conditionedLotUpdate,
          prices: [],
          auction: false,
          startsAt: false,
          endsAt: false,
        };
      }

      if (accept === true) {
        conditionedLotUpdate = {
          ...conditionedLotUpdate,
          auction: item.auction,
          startsAt: zuluStartsAt,
          endsAt: zuluEndsAt, 
        };
      }

      updateLot(item.id, {
        ...conditionedLotUpdate,
        item: item.id,
        lot: item.id,
        knockedDownAt: false,
        affiliatedAt: false,
        accepted: accept,
        type:item.listingType,
        //auction: item.auction,
       // startsAt: zuluStartsAt,
       // endsAt: zuluEndsAt,
        //prices: [],
      });

      // Note : refactor to move item.accepted to lot.accepted. See also ConsignItem. And other places.

      updateItem(item.id, { accepted: accept, consigned: consigned });
      /*
      if (accept && lotUpdate.startingPrice) {
        //if (accept && lotUpdate.startingPrice) {
        createPrice({
          item: item.id,
          amount: conditionedLotUpdate.startingPrice,
        });
        // } else if (accept && item.startingPrice) {
        //  createPrice({ item: item.id, amount: item.startingPrice });
      }
*/
      // Deal with  reject where the startingPrice is not set.
      if (!accept && lotUpdate.startingPrice === undefined) {
        delete lotUpdate.startingPrice;
      }
    } else {
      alert("LadyBidwell's choice");
    }
    //var m = getValues("message")
    //    console.log("getvalues", m);
    var datagram = {};
    var text = "";
    var html = "";
    var subject = "";
    /*
    if (data.message === undefined || data.message === "") {
      // Do nothing.
    } else {
      datagram = {
        subject:data.message  + "bnananana",
        item: item.id,
        from: "auctioneer",
        to: item.owner,
      };
      createMessage(datagram, item.owner);
    }
*/
    // This will be backwards - need to review/fix

    if (data.message === undefined || data.message === "") {
      text = "";
      html = "";
    } else {
      text = data.message;
      html = "<p>" + data.message;
    }

    if (accept === true) {
      console.log("ItemReview updatItemPrices item", item);
  
        updateItemPrices(item.id);
      

      subject = "Item Accepted into Collection." + " " + data.message;
      datagram = {
        subject: subject,
        text: text,
        html: html,
        item: item.id,
        from: "system",
        to: item.owner,
        template: "accepted",
        transport: "email",
      };
      createMessage(datagram, item.owner);


    }

    if (accept === false) {
      subject = "Item not accepted into Collection." + " " + data.message;
      datagram = {
        subject: subject,
        item: item.id,
        text: text,
        html: html,
        from: "system",
        to: item.owner,
        template: "notAccepted",
        transport: "email",
      };
      createMessage(datagram, item.owner);
    }

    setPending(false);
  };


  const { register, handleSubmit, errors, control } = useForm();

  useEffect(() => {
    console.log("ItemReview start");
  }, []);

  useEffect(() => {
    console.log("ItemReview canAccept", canAccept);
  }, [canAccept]);

  useEffect(() => {
    console.log("ItemReview lotUpdate", lotUpdate);
  }, [lotUpdate]);

  useEffect(() => {
    console.log("ItemReview pending", pending);
  }, [pending]);

  useEffect(() => {
    console.log("ItemReview lot", lot);
  }, [lot]);

  useEffect(() => {
    console.log("ItemReview item", item);
  }, [item]);

  useEffect(() => {
    console.log("ItemReview event", event);
  }, [event]);


  return (
    <>
      <div className={classes.ListItemSecondaryAction}>
 
        <Box display="flex" alignItems="center">
          <Box flexGrow="1" pb={1}>
         
            <ItemEdit key={item.id} item={item} lot={lot} />
          </Box>
        </Box>

        {/* !lot &&

(<>Sale started. Item not approved or rejected.</>)

*/}



        {!isAccepted && isAuctioneer && (
          <>
   
            <LotEdit
              item={item}
              event={event}
              lot={lot}
              buttons={false}
              onUpdate={handleLot}
              onDone={handleLot}
            />
          </>
        )}



        {isOwner && (
          <>
      
            <ConsignItem
              key={"consign_button_" + item.id}
              item={item}
              event={event}
            />

{isAccepted && item?.listingType === "marketplace_price" && (
  <>
     <Box my={1}>
<MarkSoldButton item={item} />
</Box>
   <Box my={1}>
<MarkHoldButton item={item} />
</Box>
</>
)}
          </>
        )}
        

        <div />

        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            {isAuctioneer && (
              <>
                <Box my={2}>
                  <Box my={1}>
                    <TextField
                      variant="outlined"
                      type="text"
                      label="Message"
                      name="message"
                      error={errors.name ? true : false}
                      helperText={errors.name && errors.name.message}
                      fullWidth={true}
                      // autoFocus={true}
                      autoComplete="off"
                      inputRef={register()}
                    />
                  </Box>

                  <Box display="flex" justifyContent="flex-end">
                    <Box px={1}>
                      <Button
                        variant="outlined"
                        color="primary"
                        size="medium"
                        type="submit"
                        onClick={() => {
                          setChoice("accept");
                        }}
                        disabled={isAccepted === true || canAccept === false}
                      >
                        {!pending && <span>Accept</span>}

                        {pending && <CircularProgress size={28} />}
                      </Button>
                    </Box>

                    <Button
                      variant="outlined"
                      onClick={() => {
                        setChoice("reject");
                      }}
                      color="primary"
                      size="medium"
                      type="submit"
                      disabled={isAccepted === false}
                    >
                      {!pending && <span>Reject</span>}

                      {pending && <CircularProgress size={28} />}
                    </Button>
                  </Box>
                </Box>
              </>
            )}
          </form>

          {/* <Box mt={1} mb={1}>
            <Divider gutterBottom />
          </Box>

          {(isAuctioneer || isSeller) && !likes && (
                            <MessagesItem
                              key={
                                "item_action_router_auctioneer_review_item_messages_item_" +
                                item.id
                              }
                              //itemId={item.id}
                              item={item}
                              user={{ id: item.owner }}
                              collapsed={true}
                              status={true}
                              //            onStatus={(e)=>handleStatus(e)}
                            />
                          )}



          <Box py={1}>
            <ItemWithdraw item={item} event={event} />
          </Box> */}
        </div>
      </div>
    </>
  );
}

export default requireAuth(ItemReview);
