import React from "react";
import { Link } from "./../util/router.js";
import Button from "@material-ui/core/Button";
import { useAuth } from "./../util/auth.js";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  logo: {
    height: 28,
    marginRight: theme.spacing(2),
  },
  drawerList: {
    width: 250,
  },
  spacer: {
    flexGrow: 1,
  },
  navbarBackground: {
    background: 'black',
  }, 
  button: {
    fontWeight: 600,
    paddingTop: "15px",
    paddingBottom: "15px",
    marginTop: "10px",
  },
  // search: {
  //   position: 'relative',
  //   borderRadius: theme.shape.borderRadius,
  //   backgroundColor: fade(theme.palette.common.black, 0.05),
  //   '&:hover': {
  //     backgroundColor: fade(theme.palette.common.black, 0.1),
  //   },
  //   marginRight: theme.spacing(2),
  //   marginLeft: 0,
  //   width: '100%',
  //   [theme.breakpoints.up('sm')]: {
  //     marginLeft: theme.spacing(3),
  //     width: 'auto',
  //   },
  // },
  // searchIcon: {
  //   padding: theme.spacing(0, 2),
  //   height: '100%',
  //   position: 'absolute',
  //   pointerEvents: 'none',
  //   display: 'flex',
  //   alignItems: 'center',
  //   justifyContent: 'center',
  //   color: '#56472e',
  // },

  // inputInput: {
  //   padding: theme.spacing(1, 1, 1, 0),
  //   // vertical padding + font size from searchIcon
  //   paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
  //   transition: theme.transitions.create('width'),
  //   width: '100%',
  //   [theme.breakpoints.up('md')]: {
  //     width: '35ch',
  //   },
  // },
}));

function PaymentCardMethodButton(props) {
  const history = useHistory();
  const classes = useStyles();
  const endpointUrl = window.location.pathname; //href gives full url
  const auth = useAuth();

  //function handleClick() {
  //  history.push(endpointUrl);
  //}

  return (
   
            <>
              {auth.user && (
                <Button style={{ backgroundColor: "black", color: "white" }} className={classes.button} variant="contained" size="large" elevation={0} fullWidth component={Link} to={"/settings/billing?next="+endpointUrl}>
                  Add Credit Card Details
                </Button>
              )}
              </>


  );
}

export default PaymentCardMethodButton;
