import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { Box, Typography, Card, CardContent } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import { Link } from "./../util/router";
import womanbike from "./../images/womanbike.png";
import NewsletterSection from "./NewsletterSection";

const useStyles = makeStyles((theme) => ({
  title: {
    textAlign: 'left',
    // fontFamily: '"Cormorant Garamond", "serif"',
   
  },
  message: {
    textAlign: 'left',
    paddingTop:'5px',
 
  },

root: {
  backgroundColor: '#f7f7f7',
},

  image: {
    width: '100%',
    maxHeight:'450px',
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(2),
      maxHeight:'350px',
      width:'auto',

    },
  },
  textContainer: {
      display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
    // paddingLeft:'50px',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'left',
      // marginTop:'-30px',
 
    },
  },
  imageGrid: {
      // order: 0,
      textAlign:'center',

    },
    textGrid: {
      // order: 1,
  
    },
    card: {
      // display: 'flex',
      backgroundColor: '#f7f7f7',
      alignItems: 'center', // Center the content vertically
      // borderTop:'8px solid #e5749f',
      // borderBottom:'8px solid #e5749f',
      justifyContent:'center',
      borderRadius:'10px',
      // borderColor: theme.palette.primary.main,
    },
}));

function MonthlySaleNewsletter() {
const classes = useStyles();

return (
<div className={classes.root}>
<Container>
  <Box py={6}>
    <Card elevation="none" className={classes.card}>
    
        <Grid container spacing={0}>
          <Grid
            item
            xs={12}
            sm={10}
            className={`${classes.textContainer} ${classes.textGrid}`}
          >
            <Typography
              variant="h3"
              component="h1"
              // gutterBottom
              className={classes.title}
            >
             {/* <b> Codswallop.</b><br/> You caught us right in between declining price sales.   */}
             <b>No sales currently on.</b><br/> 
            </Typography>
  
            </Grid>

            <Grid item xs={12} sm={8} md={6}>
            <Box pt={2}>
            <NewsletterSection flavour="no_sales" />
            </Box>
            </Grid>


        </Grid>

      
 
    </Card>
  </Box>
  </Container>
</div>

);
}

export default MonthlySaleNewsletter;


