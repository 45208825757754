import { useState, useEffect } from "react";
import{ Grid, Box, Typography} from "@material-ui/core"
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from 'react-dnd-touch-backend'
import Container from "./../components/Container";
import { createTransition, MouseTransition } from 'react-dnd-multi-backend'
import { CustomDragLayer } from "./CustomDragLayer";

// import { DndProvider, Preview } from 'react-dnd-multi-backend'
// import { HTML5toTouch } from 'rdndmb-html5-to-touch'

const thumb = {
  // display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 2,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};
const img = {
  // display: "block",
  // width: "auto",
  // height: "100%",
};

function EditImages(props) {
  const { images, item } = props;
//const [currentImages, setCurrentImages] = useState([]);
const [, updateState] = useState();

//const forceUpdate = useCallback(() => updateState({}), []);


useEffect(() => {
    console.log("EditImages images", images);

  }, [images]);

  // This passes the reordered images back up to
  // the controlling component.
  function handleReorderImages(reOrderedImages) {
    props.onReorderImages(reOrderedImages);
  }

  const opts = {
    enableMouseEvents: "true",
  
  }

  const TouchTransition = createTransition('touchstart', (event) => {
    return event.touches != null
  })

  const CustomHTML5toTouch = {
    backends: [
      {
        backend: HTML5Backend,
        transition: MouseTransition,
        preview:false,
        // by default, will dispatch a duplicate `mousedown` event when this backend is activated
      },
      {
        backend: TouchBackend,
        // Note that you can call your backends with options
        options: {enableMouseEvents: true},
        preview: true,
        transition: TouchTransition,
        // will not dispatch a duplicate `touchstart` event when this backend is activated
        skipDispatchOnTransition: true
      }
    ]
  }

  const options = {
    enableMouseEvents: true,
    preview: true,
  }


  return (
    <>
 
{images.length > 0 && (
<Grid item xs={12}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            pb={1}
          >
            <Typography variant="body1">
             Drag to rearrange images. The 1st image
              will be the featured image. Include any pictures of any wear
              and tear or damage. 
            </Typography>
          </Box>
        </Grid>
)}

<DndProvider backend={TouchBackend} options={options}>
 
     <Container images={images} onReorderImages={handleReorderImages}  />
     <CustomDragLayer images={images}  />

   </DndProvider> 
  


    </>
  );
}

export default EditImages;

