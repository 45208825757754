//import {console} from './log.js';
import {
  firebaseTime,
  getFirebaseCurrentTime,
  millisecondsDifference,
} from "./../util/time.js";


import { updateItemPrices, createMessage, getLotsByCollection } from "./../util/db.js";



import { noCentsFormatPrice, withCentsFormatPrice } from "./../util/text.js";

export async function getCartPrice(cartItemLots) {
  const initialValue = 0;
  return cartItemLots.reduce(
    (previousValue, cartItemLot) =>
      previousValue + getPrice(cartItemLot.lot).current.amount,
    initialValue
  );
}

export function centsPrice(zeroZeroPrice) {
  return Math.floor(zeroZeroPrice * 100);
}

export function getFormattedPrice(currency, amount, showCents) {
  if (currency === undefined || amount === undefined) {
    return null;
  }

  const formattedAmount = amount / 100;
  //const currencySymbol = currency;
  var currencySymbol = "X";
  if (currency === "USD") {
    currencySymbol = "$";
  }

  var formattedPrice = currencySymbol + noCentsFormatPrice(formattedAmount);
  if (showCents) {
    formattedPrice = currencySymbol + withCentsFormatPrice(formattedAmount);
  }
  //const formattedPrice = currencySymbol + formattedAmount;
  return formattedPrice;
}

export function humanPrice(price) {
  var showCents = false;
  if (price && price.showCents) {
    showCents = price.showCents;
  }

  return getFormattedPrice(price.currency, price.amount, showCents);
}

export function getAmountPrice(lot) {
  const price = getPrice(lot);
  //console.log("AddToCartButton price",price);
  var currentPriceAmount = false;
  if (price && price.current && price.current.amount) {
    currentPriceAmount = price.current.amount;
  }
  return currentPriceAmount;
}

export function getPrice(lot) {
  const currentTime = getFirebaseCurrentTime();
  if (!(lot && lot.prices)) {
    return true;
  }
  //if (lot.prices === null) {return true;}
  const prices = lot.prices;
  // console.log("prices", prices);
  // console.log("prices currentTime", currentTime);
  var dayIndex = 0;

  /*
Calculate day index
*/
  prices.map((_price) => {

    const validAtTime = firebaseTime(_price.validAt);

    const millisecondsAge = millisecondsDifference(currentTime, validAtTime);
//    console.log("price.js",currentTime, validAtTime, millisecondsAge)
    //console.log("PRICEX milliseconds Age prices", millisecondsAge, _price.day);
    if (millisecondsAge > 0) {
      dayIndex = _price.day;
    }
    return false;
  });

  console.log("price dayIndex", dayIndex)

  const yesterdayIndex = dayIndex - 1 < 0 ? false : dayIndex - 1;
  const tomorrowIndex = lot.prices[dayIndex + 1] ? dayIndex + 1 : false;

  const currentPrice = dayIndex !== false ? lot.prices[dayIndex] : false;
  const yesterdayPrice =
    yesterdayIndex !== false ? lot.prices[yesterdayIndex] : false;
  const tomorrowPrice =
    tomorrowIndex !== false ? lot.prices[tomorrowIndex] : false;

  return {
    current: currentPrice,
    yesterday: yesterdayPrice,
    tomorrow: tomorrowPrice,
    dayIndex: dayIndex
  };
}


export function updatePriceTables(event) {
    console.log("CreateLots updatePriceTables");
    var count = 0;
    var index;
    // Correct event? - yes
   // console.log("CreateLots event", event);
   // console.log("CreateLots items", items);

   // console.log("CreateLots lotsData", lotsData, lotsStatus, lotsError);


      return getLotsByCollection(event.id)
        .then((lotsData) => {



    for (index in lotsData) {
      console.log("CreateLots xkcd", lotsData[index].item);

      const itemId = lotsData[index].item;
      //console.log("CreateLots item updatePriceTables", item)
      updateItemPrices(itemId);

      const subject = "Item Price Table updated.";
      const datagram = {
        subject: subject,
        text: null,
        html: null,
        item: itemId,
        from: "auctioneer",
        to: "group",
        template: null,
        transport: null,
      };
      createMessage(datagram, "auctioneer");

      //      updateLot(lotsData[index].item, { index: count });
      count += 1;
    }

}).catch((error)=>{

console.error("price.js updatePriceTables error", error);

});

  }
