import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  Box,
  IconButton,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import AuthStepper from "./../components/AuthStepper.js";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  topScrollPaper: {
    alignItems: "flex-start",
  },
  topPaperScrollBody: {
    verticalAlign: "top",
  },
  closeIcon: {
    zIndex: 99,
    position: "absolute",
    right: 15,
    top: 10,
    justifyContent: "flex-end",
    // color: 'theme.palette.secondary.main',
  },
  fontFamily: {
    fontFamily: '"Cormorant Garamond", "serif"',
    fontWeight: 500,
  },
}));

export default function AuthDialog({ isDialogOpened, handleCloseDialog, subtitle, authCommand }) {
  const classes = useStyles();

  useEffect(() => {
    handleClickOpen();
  }, []);

const [authStep, setAuthStep] = useState();

useEffect(() =>{
//if (authCommand == null) {return;}
  if (authCommand === 'signin') {setAuthStep(1);}
  if (authCommand === 'signup') {setAuthStep(0);}

  if (authCommand == null) {setAuthStep(0);}

},[authCommand])

  //const classes = useStyles();
  //const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth] = React.useState("sm");

  const handleClickOpen = () => {
    //setOpen(true);
    //setTimeout(() => setOpen(false), 16000);
  };

  const handleClose = () => {
    //setOpen(false);
    //console.log("UserItemHeart AuthDialog")
    handleCloseDialog(false);
    };

  /* const handleMaxWidthChange = event => {
    setMaxWidth(event.target.value);
  }; */

  const handleFullWidthChange = (event) => {
    setFullWidth(event.target.checked);
  };
useEffect(() =>{

console.log("AuthDialog fullWidth maxWidth isDialogOpened", fullWidth, maxWidth, isDialogOpened)

}, [fullWidth, maxWidth, isDialogOpened])

if (authStep == null) {return null;}

return (
    <React.Fragment>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={isDialogOpened}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
        classes={{
          scrollPaper: classes.topScrollPaper,
          paperScrollBody: classes.topPaperScrollBody,
        }}
      >
    
        <DialogTitle style={{textAlign:'center'}}>
          <Typography variant='h4' className={classes.fontFamily}>
          {subtitle}
          </Typography>

          <IconButton className={classes.closeIcon} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <AuthStepper initialStep={authStep} />

      </Dialog>
    </React.Fragment>
  );
}
