import React, { useState } from "react";
import VisibilitySensor from "react-visibility-sensor";
import { createImpression } from "./../util/db.js";

function ImpressionCounter(props) {
  const { lot, partialVisibility } = props;

  const lotId = lot && lot.id ? lot.id : null;

  const handleImpression = (isVisible) => {
    //console.log("ImpressionCounter handleImpression isVisible isSeen lotId", isVisible, isSeen, lotId)
    if (isVisible === undefined) {
      //console.log("ImpressionCounter isVisible undefined")
      return;
    }
    if (isVisible === false) {
      //console.log("ImpressionCounter isVisible false")
      return;
    }

    if (isSeen === true) {
      //console.log("ImpressionCounter isSeen true")
      return;
    }

    if (lotId === null) {
      //console.log("ImpressionCounter lotId null")
      return;}

    createImpression(lotId);
    setIsSeen(true);
    // console.log("handleImpression lot", lotId, isVisible);
    console.log('ImpressionCounter Element ' + lotId + ' was just seen.');
  };

  const [isSeen, setIsSeen] = useState(false);

  return (
    <VisibilitySensor onChange={handleImpression} partialVisibility={partialVisibility} >
      <div className="sensor">{props.children}</div>
    </VisibilitySensor>
  );
}

export default ImpressionCounter;

/*
      {/*} <img
          //alt={}
          //src={}
          style={{
            display: 'block',
            maxWidth: '100%',
            width: '100%',
            height: 'auto',
            opacity: visibility ? 1 : 0.25,
            transition: 'opacity 500ms linear'
          }}
        />*/
