import React, { useState, useEffect } from "react";
import { useAuth } from "./../util/auth.js";
import { makeStyles } from "@material-ui/core/styles";


// import firebase from "../util/firebase";


import {
  getCustomerByCustomer,
  getPaymentMethodsByCustomer,
} from "../util/stripe.js";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { Box } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";


//const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp;

//const processPaymentAuthorization = firebase.functions().httpsCallable("processPaymentAuthorization");

const useStyles = makeStyles((theme) => ({
  paperItems: {
    minHeight: "300px",
  },
  item: {
    justifyContent: "space-between",
    display: "flex",
  },
  featured: {
    backgroundColor:
      theme.palette.type === "dark" ? theme.palette.action.selected : "#fdf8c2",
  },
  starFeatured: {
    color: theme.palette.warning.main,
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    marginTop: "2em",
    padding: theme.spacing(5),
    textAlign: "center",
    color: theme.palette.text.secondary,
    border: "1px solid #c4c4c4",
  },
}));

function PaymentMethodStatus(props) {
  const { filter } = props;
  const { cards, customer } = props;
  const classes = useStyles();
  const auth = useAuth();

  const [status, setStatus] = useState();
  const [priorUser, setPriorUser] = useState();
/*

Init

*/
  useEffect(() => {
    console.log("PaymentMethodStatus start")  
setStatus({...status, state:'loading'})
setPriorUser(auth.user);
handlePaymentMethodStatusChange();
  },[])

useEffect(()=>{

console.log("PaymentMethodStatus Saw a change to cards or customer.")

}, [cards,customer])

  //const [stripePromise, setStripePromise] = useState(() =>
  //  loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)
  //);

  // OK - I ewill start with ok / not ok and will wait on you.
  // statuses =
  // 'valid'
  // 'invalid' -
  // 'no default'?
  // 'none' - You need to add a payment card to bid.

  useEffect(() => {
    // Force initial load?
    console.log("PaymentMethodStatus cards changed")
    handlePaymentMethodStatusChange();


  }, [cards]);

  useEffect(() => {
    // Force initial load?
    console.log("PaymentMethodStatus customer changed")
    handlePaymentMethodStatusChange();


  }, [customer]);

  const handlePaymentMethodStatusChange = () => {
 
    

    if (
      auth.user === undefined
    ) {
      return;
    }
    //console.log("merp");
    if (
      auth.user &&
      auth.user.buyer &&
      auth.user.buyer.enabled === false
    ) {
      console.log("auth.user.buyer.enabled", auth.user.buyer.enabled)
      setStatus({
        state: "not ok",
        message: "Buying privileges not available.",
      });
      return;
    }

    if (!(auth.user && auth.user.payment)) {
      console.log("Saw no cards on record")
      setStatus({ state: "not ok", message: "No payment cards on record" });
      return;
    }

    if (!(auth.user && auth.user.payment && auth.user.payment.customer)) {
      setStatus({
        state: "not ok",
        message: "Stripe customer identity not found.",
      });
      return;
    }
    const customerId = auth.user.payment.customer;

 
 

    console.log("PaymentMethodStatus requesting customer record from Stripe")
    console.log("customerId",customerId);

    getPaymentMethodsByCustomer(customerId)
      .then((paymentMethodData) => {
        console.log("paymentMethodData", paymentMethodData);
        const cardCount = paymentMethodData.data.data.length;
        if (cardCount === 0) {
          setStatus({ state: "not ok", message: "No payment cards on record" });
          return;
        }
        getCustomerByCustomer(customerId)
          .then((customerData) => {
            console.log("customer data", customerData);
            const defaultPaymentMethod =
              customerData.data.invoice_settings.default_payment_method;
console.log("PaymentMethodStatus defaultPaymentMethod", defaultPaymentMethod)
            if (defaultPaymentMethod === null) {
              setStatus({
                state: "not ok",
                message: "No default payment method defined.",
              });

            

                props.onStatus({
                  type: "info",
                  message: "A default card is needed to bid.",
                });
          
  
              return;
            }
// Check if the returned payment method is
// one of the cards we have.
console.log("PaymentMethodStatus cards", cards);
if (cards === undefined) {
//cards.map((card) =>
//{console.log("PaymentMethodStatus card",card)}
//)
setStatus({
  state: "not ok",
  message: "No cards defined.",
});

  props.onStatus({
    type: "info",
    message: "Cards need to be set.",
  });
          }



console.log("OKOKOKOKOKOKOK")
            setStatus({ state: "ok" });
          })
          .catch((error) => {
  
            setStatus({ state: "not ok", message: "error happened see console"});
            console.log("customer error", error);
          });
      })
      .catch((error) => {
        setStatus({ state: "not ok", message: error });
        console.log("error", error);
      });
  };
/*
  useEffect(() => {
    //handlePaymentMethodStatusChange();
    
    if (auth.user.buyer === undefined) {return;}
    if (auth.user && auth.user.buyer === undefined) {
      setStatus({ state: "not ok", message: "Buying not enabled." });
      return;
    }

    if (auth.user.buyer && auth.user.buyer.enabled === false) {
      setStatus({ state: "not ok", message: "Buying not enabledX." });
    }
    
    
  }, [auth?.user?.buyer]);
*/





  useEffect(() => {
    console.log("Saw a thing.")
    handlePaymentMethodStatusChange();
    /*
    if (auth.user.buyer === undefined) {return;}
    
    if (auth.user.buyer.enabled === false) {
      setStatus({ state: "not ok", message: "No buying privileges." });
    }
    */
  }, [auth?.user?.buyer?.enabled]);



  useEffect(() => {
    if (auth && auth.user && !auth.user.buyer) {return;}
    if (auth && auth.user && auth.user.buyer && !auth.user.buyer.bidding) {return;}

    if (priorUser && auth.user.buyer.bidding === priorUser.buyer.bidding) {
      return;
    }
    
    setPriorUser(auth.user);
    handlePaymentMethodStatusChange();
  }, [cards]);

  /*
Listen to changes in state variable (loading, not ok, ok)
  */
  useEffect(() => {


    console.log("Status changed");
   if (props.onChange === undefined) {
      return;
    }

    if (status === undefined) {return;}

    console.log("Status changed to", status.state, status.message) 
    //updateUser(auth.user.uid, {
    //  buyer: { bidding: status.state === "ok" ? true : false },
    //});
    //
/*
    if (status.state === 'ok') {

        props.onStatus({
          type: 'silent',
          message: ""}
        );

}
*/


   
  }, [status?.state]);

useEffect(()=>{
  console.log("PaymentMethodStatus status", status)
  props.onChange(status);

  },[status]);


  const PaymentMethodStatusIcon = () => {


    if (status.state === "ok") {return (
      <CheckCircleIcon style={{ color: "#004225" }} />
    )}

    if (status.state === "not ok") {
      return (<><ErrorIcon style={{ color: "#bd162c" }} /></>);
    }
    return null;
    //return ("X");
     //return (<><Skeleton variant="circle" width={20} height={20} /></>);
      

  }
/*

First the null returns

*/
  if (status === undefined) {
    return null;
  }

  if (props.input === 'null ok' && status.state === 'ok') {return null;}

  if (props.input === 'null not ok' && status.state === 'not ok') {return null;}

// This is the "new user" state. They havent done anything wrong ... yet.
 if (auth?.user?.payment?.payment_method === undefined) {return null;}
/*in 

*/

 //  if (props.input === undefined && status.state === "loading") {
 //    return <><Skeleton variant="circle" width={20} height={20} /></>;
 //  }



  return (

    <>
    <Grid item xs={12} sm>
      <Box
        display="flex"
        style={{ alignItems:'center', justifyContent: "space-between" }}
      >
<Typography variant="subtitle1" style={{fontWeight: "600", color: "#bd162c", textTransform:'uppercase' }}>
{(props.input && props.input.toLowerCase().includes('message')) ? (status.message) : null}
</Typography>
<PaymentMethodStatusIcon />
  
      </Box>
      </Grid>

    </>
  );
}

export default PaymentMethodStatus;
