import React, { SyntheticEvent, useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import CircularProgress from "@material-ui/core/CircularProgress";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import StarIcon from "@material-ui/icons/Star";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import EditItemModal from "./EditItemModal";
import ItemStrip from "./ItemStrip";
import ItemIdStrip from "./ItemIdStrip";
import ItemReview from "./ItemReview";
import { humanTime, firebaseTime } from "../util/time";
import { useAuth } from "./../util/auth.js";
import { updateItem, useItemsByEvent } from "./../util/db.js";
import { makeStyles } from "@material-ui/core/styles";
import { useWatch } from "react-hook-form";
import ItemThumbnail from "./../components/ItemThumbnail";
import { requireAuth } from "./../util/auth.js";
import { sortBy, filterBy } from "../util/array.js";
import Grid from "@material-ui/core/Grid";
import Section from "./Section";
import Container from "@material-ui/core/Container";
import { AppBar, Tabs, Tab, TableHead, TableRow, Chip } from "@material-ui/core";
import PropTypes from "prop-types";
import { useIdentity } from "./../util/identity.js";
import LotsControl from "./LotsControl";
import ListSkeleton from "./ListSkeleton.js";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import { debugConsole } from "./../util/dev.js";
import { Print } from "react-easy-print";
import { setIndexConfiguration } from "@firebase/firestore";
import LazyLoad, { forceCheck, forceVisible } from "react-lazyload";
import { Refresh } from "@material-ui/icons";
import ItemsList from "./ItemsList";
import HydratedItems from "./HydratedItems";
import PaginatedItems from "./PaginatedItems";
import FilteredItems from "./FilteredItems";
import { debugFlag } from "./../util/dev.js";


const useStyles = makeStyles((theme) => ({
  paperItems: {
    minHeight: "300px",
    backgroundColor: "transparent",
    boxShadow: "none",
  },

  featured: {
    backgroundColor:
      theme.palette.type === "dark" ? theme.palette.action.selected : "#fdf8c2",
  },
  starFeatured: {
    color: theme.palette.warning.main,
  },
  accepted: {
    backgroundColor:
      theme.palette.type === "dark" ? theme.palette.action.selected : "#fdf8c2",
  },
  tickAccepted: {
    color: theme.palette.success.main,
  },
  deleteDisabled: {
    color: theme.palette.action.disabledBackground,
  },
  editDisabled: {
    color: theme.palette.action.disabledBackground,
  },
  notAccepted: {
    color: theme.palette.error.dark,
  },
  hourWaiting: {
    color: theme.palette.warning.dark,
  },
  ListItemSecondaryAction: {
    minWidth: "120px",
    textAlign: "center",
  },
  lowerCaseLabel: {
    textTransform: 'none',
  },
  tabsIndicator: {
    display: "none",
  },
}));

function EventItemList(props) {
  const classes = useStyles();

  const [thumbStatus, setThumbStatus] = useState("loading");
  const [filterType, setFilterType] = useState("all");

  const [flavour, setFlavour] = useState();

  const { items, event, mode, sortType } = props;
  const [index, setIndex] = useState(0);
  const itemsStatus = "success";
  const auth = useAuth();

  const [creatingItem, setCreatingItem] = useState(false);
  const [updatingItemId, setUpdatingItemId] = useState(null);

  const itemsAreEmpty = !items || items.length === 0;

  const canUseStar =
    auth.user.planIsActive &&
    (auth.user.planId === "pro" || auth.user.planId === "business");

  const canUseAccept = auth.user.role && auth.user.role === "auctioneer";
  const [arrangedItems, setArrangedItems] = useState();
  const [arrangedItemsStatus, setArrangedItemsStatus] = useState("loading");
  // const isAuctioneer =
  //  auth.user && auth.user.role && auth.user.role === "auctioneer";

  const { isAuctioneer, isSeller } = useIdentity();

  const [activeTab, setActiveTab] = useState("all");


  useEffect(() => {
    console.log("flavour or filterType changed:", flavour, filterType);
    // Added this to forcecheck Sold Buyer List view, but works across all tabs and filters
    forceCheck();
  }, [flavour, filterType]);

  useEffect(() =>{

    console.log("EventItemList flavour", flavour)


  }, [flavour]);

  useEffect(() => {
    // do something when activeTab changes
  }, [activeTab]);

  useEffect(() => {
    if (items === undefined) {
      return;
    }
    console.log("EventItemList items", items);
    var d = items;

    if (sortType === "date") {
      const i2 = d.map((item) => {
        return { ...item, zCreatedAt: firebaseTime(item.createdAt) };
      });

      d = sortBy(i2, "zCreatedAt", "desc", true);
    }

    if (sortType === "name") {
      d = sortBy(d, "name", "asc", false);
    }

    if (sortType === "id") {
      d = sortBy(d, "id", "asc", false);
    }

    setArrangedItems(d);
    setArrangedItemsStatus("success");
  }, [items, sortType, filterType]);

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const [stripKey, setStripKey] = useState(0);

  const [value, setValue] = React.useState(0);




  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    switch (newValue) {
      case 0:
        setFilterType("all");
        setFlavour("plain");
        break;
      case 1:
        setFilterType("wip");
        setFlavour("plain");

        break;
      case 2:
        setFilterType("sold");
        setFlavour("plain");
        break;
    }

    setValue(newValue);
    forceVisible();
  };


  const handldeShowSoldDefault = () => {
    setFilterType("sold");
    setFlavour("plain") ;
    console.log("show simple filtertype flavour", filterType, flavour)
  }

  const handldeShowSoldSimple = () => {
    setFilterType("sold");
    setFlavour("simple") ;
    console.log("show simple filtertype flavour", filterType, flavour)
  }


  const [tabSubValue, setTabSubValue] = useState(0);

  const handleChange = (event, newValue) => {
    setTabSubValue(newValue);
    if (newValue === 0) {
      handldeShowSoldDefault();
    } else if (newValue === 1) {
      handldeShowSoldSimple();
    }
  };


  //const handleChange = (event, newValue) => {
  //  setValue(newValue);
  //};

  const [hasFocus, setFocus] = useState(false);

  if (arrangedItemsStatus !== "success") {
    return <ListSkeleton listsToRender={6} />;
  }

  return (
    <>
      <Section
        bgColor={props.bgColor}
        size={props.size}
        bgImage={props.bgImage}
        bgImageOpacity={props.bgImageOpacity}
      >
        <Container>
          <Paper className={classes.paperItems}>
            <Box
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              my={2}
            >
              {/*<LotTick />*/}
              {/* <Typography className={classes.text} variant="h5">
                Items
              </Typography> */}

              {!isAuctioneer ? (
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  onClick={() => {
                    setCreatingItem(true);
                    props.onAddItem();
                  }}
                >
                  Add Item
                </Button>
              ) : null}

              {isAuctioneer && (
                <LotsControl items={arrangedItems} event={event} />
              )}
            </Box>

            {/* <Divider /> */}

            {(itemsStatus === "loading" || itemsAreEmpty) && (
              <Box py={5} px={3} align="center">
                {itemsStatus === "loading" && <CircularProgress size={32} />}

                {itemsStatus !== "loading" && itemsAreEmpty && (
                  <Typography variant="h4">Add your first item.</Typography>
                )}
              </Box>
            )}

            <Box pb={1}>
              <Tabs
                value={value}
                onChange={handleTabChange}
                aria-label="simple tabs example"
                style={{ borderBottom: "1px solid #e8e8e8" }}
              >
                <Tab color="textPrimary"  disableTouchRipple label="All" {...a11yProps(0)} />
                <Tab  color="textPrimary" disableTouchRipple label="Drafts" {...a11yProps(1)} />
                <Tab color="textPrimary"  disableTouchRipple label="Sold" {...a11yProps(2)} />
                {/* <Tab label="Sold (list view)" {...a11yProps(2)} /> */}
             
              </Tabs>
            </Box>

            {arrangedItemsStatus !== "loading" &&
              arrangedItems &&
              arrangedItems.length > 0 && (
                <>
   



{filterType === "sold" && (<>

  <Box py={1} display="flex" alignItems="center" >

      <Chip
      label="Default View"
      onClick={(event) => handleChange(event, 0)}
      color={tabSubValue === 0 ? "primary" : "default"}
      style={{textTransform:'none', marginRight: '8px'}}
    />
    <Chip
      label="Buyer List"
      onClick={(event) => handleChange(event, 1)}
      color={tabSubValue === 1 ? "primary" : "default"}
      style={{textTransform:'none'}}
    />

      
      </Box>
      </>)}


{debugFlag && (<>
<Box py={2}>

              <Typography variant="subtitle1" color="textSecondary">
                Number of items: {arrangedItems?.length}
              </Typography>
            </Box>
            </>)}



                  <HydratedItems items={arrangedItems
                           .filter(
                            (item) =>
                              // item.name !== undefined &&
                              auth.user.id === item.owner ||
                              (isAuctioneer && item.consigned))
                  
                  } flavour={flavour}>

                    <FilteredItems filterType={filterType} flavour={flavour}>
                      <PaginatedItems
                        // itemsPerPage={10}
                        itemsPerPage={tabSubValue === 1 ? 1000 : 20}
                        filterType={filterType}
                        flavour={flavour}
                      >

                      <ItemsList flavour={flavour} filterType={filterType} />
 
                      </PaginatedItems>
                    </FilteredItems>

                  </HydratedItems>


                </>
              )}
          </Paper>

          {creatingItem && (
         
            <EditItemModal
              event={props.event}
              //   newItem={true}
              onDone={() => {
                setCreatingItem(false);
              }}
            />
        
          )}

          {updatingItemId && (
           
            <EditItemModal
              id={updatingItemId}
              onDone={() => {
                setUpdatingItemId(null);
              }}
            />
               
          )}
        </Container>
      </Section>
    </>
  );
}

export default requireAuth(EventItemList);
