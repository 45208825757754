import React from "react";
import EventSection from "./../components/EventSection";
import { requireAuth, useAuth } from "./../util/auth.js";
import NotFoundPage from './not-found';
import Section from "../components/Section";
import Meta from "./../components/Meta";

function AddEventPage(props) {
  const auth = useAuth();
 // const isAuctioneer =
 // auth.user.role &&
 // (auth.user.role === "auctioneer");


 //const isSeller = auth.user && auth.user.seller && auth.user.seller.approvedAt && auth.user.seller.approvedAt !== false;
const isEnabledSeller = auth.user && auth.user.isEnabledSeller;
const isCurator = auth.user && auth.user.isCurator;
//const isSeller = isEnabledSeller; 
//const eventTypeWord = (isAuctioneer ? "Auction" : "Event")
  const eventTypeWordSingular = (isCurator || isCurator ? "Sale" : "Collection List")
  const eventHelper = (isCurator ? "Create sales for sellers to add items to." : "Organize your items by estate sale, client, period, type. Add as many items as desired to each collection. Please note, that all items within a collection will be associated with the specified pickup address for that collection.")
  const eventTypeWordPlural = (isCurator ? "Sales" : "Collections List")

if (!(isEnabledSeller || isCurator)) {return( <><NotFoundPage /></>)}

  return (
<>

{isCurator && (
<Meta 
title="Collection Dashboard"
/>
)}
{!isCurator && (
<Meta 
title="Seller Dashboard"
/>
)}

    <EventSection
      // bgColor="black"      
      size="normal"
      bgImage=""
      bgImageOpacity={1}
      // title={"Add "+ eventTypeWordSingular}
      title={eventTypeWordPlural}
      subtitle={eventHelper}
    />




</>

    

  );
}

export default requireAuth(AddEventPage);
