import { isPastTime } from "./time.js";
//  const { isBought, isBuyable } = useLot(lot);

export function filterHydratedItem(hydratedItem, filterType) {
console.log("filter expr", filterType)
  const expr = filterType.toLowerCase();
  switch (expr) {
    case "all":
      return isAll(hydratedItem);
    case "wip":
      return isWip(hydratedItem);
    case "sold":
      return IsSold(hydratedItem);
    case "can-buy":
      return canBuy(hydratedItem);

    default:
      return isAll(hydratedItem);
  }

  return true;
}

export function IsSold(hydratedItem) {


  console.log("filter IsSold hydratedItem", hydratedItem)
 
  if (hydratedItem.lot == null) {return false;}

  if (hydratedItem.lot.paid == null) {return false;}
  //const { isBought, isBuyable } = useLot(hydratedItem);
//console.log("filter isBought", isBought)
return true;
//  return isBought;
}
/*

  const isLotEnded = lot && isPastTime(lot.endsAt);
  const isLotStarted = lot && isPastTime(lot.startsAt);

  const isKnockedDown =
    lot && lot.knockedDownAt && lot.knockedDownAt !== false ? true : false;

const isDelisted = 
lot && lot.delistedAt && lot.delistedAt !== false ? true : false;

  const canBuy =
    !itemAvailable &&
    !isKnockedDown &&
    !isDelisted &&
    isLotStarted &&
    !isLotEnded &&
    lot &&
    lot.type &&
    lot.type === "declining_price";


*/

export function canBuy(hydratedItem) {

if (hydratedItem.lot == null) {return false;}

const lot = hydratedItem.lot;

  if (typeof lot.paid !== 'undefined' && lot.paid === true) {return false;}

  const isLotEnded = lot && isPastTime(lot.endsAt);
  const isLotStarted = lot && isPastTime(lot.startsAt);

  const isKnockedDown =
    lot && lot.knockedDownAt && lot.knockedDownAt !== false ? true : false;

const isDelisted = 
lot && lot.delistedAt && lot.delistedAt !== false ? true : false;

  const canBuy =
//    !itemAvailable &&
    !isKnockedDown &&
    !isDelisted &&
    isLotStarted &&
    !isLotEnded &&
    lot &&
    lot.type &&
    lot.type === "declining_price";
//return true;
return canBuy;

}



export function isWip(hydratedItem) {
  console.log("filter isWip", hydratedItem);
  const isEditable =
    hydratedItem.item.accepted === undefined ||
    (hydratedItem.item.accepted && hydratedItem.item.accepted === false);

  return isEditable;
}

export function isAll(hydratedItem) {
  return true;
}

/*
      {filterType === "sold" && flavour === "simple" && isBought && (
        <>


          <LazyLoad height={100} offset={1000}>
            <BuyerInformation item={item} lot={lot} flavour={flavour} />
          </LazyLoad>
        </>
      )}

    

      {((filterType === "sold" && flavour !== "simple" && isBought) ||
        (filterType === "all") || (filterType === "wip" && isEditable)) && (
*/
