import React, { useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  IconButton,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  topScrollPaper: {
    alignItems: "flex-start",
  },
  topPaperScrollBody: {
    verticalAlign: "top",
  },
  // closeIcon: {
  //   zIndex: 99,

  //   right: 15,
  //   top: 10,
 
  //   justifyContent: "flex-end",
  //   // color: 'theme.palette.secondary.main',
  // },
  fontFamily: {
    fontFamily: '"Cormorant Garamond", "serif"',
    fontWeight: 500,
  
  },
  dialogTitle: {
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

export default function AuctionDetailsDialog({ isDialogOpened, handleCloseDialog, subtitle, body, review, startTime, endTime }) {
  const classes = useStyles();

  useEffect(() => {
    handleClickOpen();
  }, []);

  //const classes = useStyles();
  //const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth] = React.useState("sm");

  const handleClickOpen = () => {
    //setOpen(true);
    //setTimeout(() => setOpen(false), 16000);
  };

  const handleClose = () => {
    //setOpen(false);
    handleCloseDialog(false);
  };

  /* const handleMaxWidthChange = event => {
    setMaxWidth(event.target.value);
  }; */

  const handleFullWidthChange = (event) => {
    setFullWidth(event.target.checked);
  };

  return (
    <React.Fragment>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={isDialogOpened}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
    
      >
        <Box style={{textAlign:'right', paddingRight:'10px', paddingTop:'10px'}}>
              <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
          </Box>
   

        <DialogTitle style={{textAlign:'left'}} className={classes.dialogTitle}>
          <Typography variant='h4' className={classes.fontFamily}>
          {subtitle}
          </Typography>

    {review}
        </DialogTitle>

        <DialogContent>


        <Box mt={1} >
          <Box display="flex" pb={0.5}>
            <Box flexGrow={1}>
              <Typography variant="body1" style={{ paddingRight: "15px" }}>
                Starts:
              </Typography>
            </Box>

            <Typography variant="body1">
              {
                (startTime)}
            </Typography>
          </Box>

          <Box display="flex" pb={0.5}>
            <Box flexGrow={1}>
              <Typography variant="body1" style={{ paddingRight: "15px" }}>
                Ends:
              </Typography>
            </Box>
            <Typography variant="body1">
              {
                (endTime)}
            </Typography>
          </Box>
          </Box>



        <Typography
                gutterBottom={true}
                variant="body1"
                style={{whiteSpace: "pre-line" }}
                
              >
   {body}
   </Typography>
   </DialogContent>
     
      </Dialog>
    </React.Fragment>
  );
}
