import { useAuth } from "./../util/auth.js";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    // minWidth: 275,
    // height: 140,
    // boxShadow: "1px 0px 4px rgb(0 2 4 / 2%), 0 7px 20px rgb(1 1 1 / 3%)",
    padding: "15px",
    // backgroundColor: "#f5f5f5",

    [theme.breakpoints.down("sm")]: {
      backgroundColor: "transparent",
      border: "1px solid #c0c0c0",
    },

    "&:hover": {
      // borderColor: "#0000001f",
      // border: "1px solid",
      // backgroundColor: '#f7f7f7',
      border: "#f5f5f5",
      borderStyle: "solid",
      borderWidth: "1px",
    },
  },

  // cardcontent: {
  //   padding:'0px',
  //   "&:last-child": {
  //     paddingBottom: 5
  //   }
  // },

  cardheader: {
    paddingBottom: "5px",
    opacity: "0.5",
    marginBottom: "-25px",
  },

  estimate: {
    justifyContent: "space-between",
    // paddingLeft: '16px',
    // paddingRight: '16px',

    display: "flex",
    alignItems: "center",
  },

  media: {
    // minHeight: "200px",
    height: "auto",
    // width:'100%',
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      height: "auto",
    },
    // height: "auto",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },

  paperItems: {
    minHeight: "300px",
  },
  featured: {
    backgroundColor:
      theme.palette.type === "dark" ? theme.palette.action.selected : "#fdf8c2",
  },
  starFeatured: {
    color: theme.palette.warning.main,
  },
  name: {
    overflow: "hidden",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
   
    [theme.breakpoints.up("md")]: {
      lineHeight: "1.5em",
      minHeight: "3em",
    },
  },
}));

function TestIndicator(props) {
  const classes = useStyles();

  const auth = useAuth();

  const isAuctioneer =
    auth && auth.user && auth.user.role && auth.user.role === "auctioneer";
  const isUser = auth && auth.user !== false;

 const isDeveloper = auth?.user?.developer?.enabled;

  const { item, event } = props;
  var { flavour, spice } = props;

  //flavour = flavour === undefined ? "standard" : "simple";

  //  const [creatingItem, setCreatingItem] = useState(false);

  //  const [updatingItemId, setUpdatingItemId] = useState(null);


  const isOwner =
    auth.user && auth.user.id && auth.user.id === (item && item.owner);
  
    if (event && event.test === true) {
    return "TEST Event";
  }

  if (item && item.test && item.test === true) {
    return "TEST Item";
  }

  return null;

  }

export default TestIndicator;
