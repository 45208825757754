import React, { useEffect } from "react";
import { useAuth } from "./../util/auth.js";
import {
  useItemsByOwner,
} from "./../util/db.js";
import { makeStyles } from "@material-ui/core/styles";
import ItemsList from "./../components/ItemsList";



const useStyles = makeStyles((theme) => ({
  paperItems: {
    minHeight: "300px",
  },
  featured: {
    backgroundColor:
      theme.palette.type === "dark" ? theme.palette.action.selected : "#fdf8c2",
  },
  starFeatured: {
    color: theme.palette.warning.main,
  },
}));

function ItemsListSelling(props) {

  const [page, setMyPage] = React.useState(1); // this an example using hooks
const setPage = (e, p) => {
  setMyPage(p);
}

  const {filter, tab} = props;
  const classes = useStyles();


  const auth = useAuth();




const {data: items, status:itemsStatus, error:itemsError} = useItemsByOwner(auth.user.id);

  const isAuctioneer = auth.user.role && auth.user.role === "auctioneer";

  const itemTypeWordSingular = isAuctioneer ? "Auction" : "Collection";
  const itemTypeWordPlural = isAuctioneer ? "Auctions" : "Collections";

  var isReady = itemsStatus === 'success'
  var filteredItems = [];

  if (isReady) {

filteredItems = items
 // .filter(item => item.featured === true)
  .map((item, index) => {return item});

}
useEffect(() => {

  console.log("filteredItems",filteredItems)
  console.log("items", items)


}, [filteredItems, items])

if (!isReady) {return (<>FLERP</>);}

if (items.length ===0 ) {return (<>No items here.</>)}

  return (
    <>
Number of items:{items.length}
       
        <ItemsList createItem={false} items={items} tab={tab} />

  
    </>
  );
}

export default ItemsListSelling;


/*

            {items
              .filter((item) => (item.name !== undefined && item.accepted === true))
              .map((item, index) => (


*/
