import { Box, Button } from "@material-ui/core";
import { useState } from "react";
import { isPastTime } from "../util/time";
import { useCart, useInCart } from "../util/cart";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { useHistory } from 'react-router-dom';

const AddToCartButton = (props) => {
  const { item, lot } = props;

  const { addItemLot } = useCart();

  const {found:itemAvailable} = useInCart(item);

  //const [itemAvailable, setItemAvailable] = useState();

  const isLotEnded = lot && isPastTime(lot.endsAt);
  const isLotStarted = lot && isPastTime(lot.startsAt);

  const isKnockedDown =
    lot && lot.knockedDownAt && lot.knockedDownAt !== false ? true : false;

const isDelisted = 
lot && lot.delistedAt && lot.delistedAt !== false ? true : false;

  const canBuy =
    !itemAvailable &&
    !isKnockedDown &&
    !isDelisted &&
    isLotStarted &&
    !isLotEnded &&
    lot &&
    lot.type &&
    lot.type === "declining_price";

    let history = useHistory();
    const handleClick = () => { history.push("/cart");
    window.scrollTo({ top: 0});
}

  function addItemToCart(item) {
    addItemLot(item, lot); 
    // toast("Item added to cart!");
    toast(<span> <a onClick={handleClick}> {item.name} added to cart. <strong>Go to cart</strong></a> </span>, {
      toastId: "addtocart-"+item.id
    });
  }

const [isCopied, setIsCopied] = useState(false);

const handleCopyClick = () => {
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 5500);
}


// const options = {
//   onOpen: (props) => console.log(props.foo),
//   onClose: (props) => console.log(props.foo),
//   autoClose: 6000,
//   hideProgressBar: false,
//   position: toast.POSITION.BOTTOM_RIGHT,
//   pauseOnHover: true,
//   delay: 8500,
//   pauseOnFocusLoss: true,
//   // theme: 'colored'
//   // type: toast.TYPE.INFO,
//   // and so on ...
// };

// useEffect(() => {
//   if (addItemToCart === "success") {
//     toast("Hello", options);
//   }
// }, [itemStatus]);

// history.listen(() => {
//   toast.dismiss();
// });

  return (
    <>
    <Box
      style={{
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Button
        disabled={itemAvailable}
        onClick={() => addItemToCart(item)}
        variant="outlined"
        size="large"
        elevation={0}
        fullWidth
      >

 {itemAvailable ? "Added to Cart"  : "Add to Cart" }       

      </Button>
    </Box>
    {/* <ToastContainer 
    position="top-right"
    autoClose={15000}
    hideProgressBar={true}
    newestOnTop={false}
    closeOnClick
    rtl={false}
    pauseOnFocusLoss
    // draggable
    pauseOnHover
    theme="dark"
    /> */}
    </>
  );
};

export default AddToCartButton;
