import React, { useState, useEffect } from "react";
import { useRouter } from "./../util/router.js";
import Alert from "@material-ui/lab/Alert";
import Box from "@material-ui/core/Box";
import {
  makeStyles,
  Typography,
  Hidden,
} from "@material-ui/core";
import { useAuctionsByFeatured } from "../util/db";
import { secondsToReadableString } from "../util/time.js";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import ReactMarkdown from "react-markdown";

const useStyles = makeStyles((theme) => ({
  alert: {
    // Let <Section> handle colors
    backgroundColor: "#2b2b2b",
    color: "#ffffff",
    borderRadius: 0,
    borderBottom:'1px solid white'
  },

  message: {
    width: "100%",
    textAlign: "center",
  },
  headline: {
    // fontFamily: '"Cormorant Garamond", "serif"',
  },
}));

function Announcement(props) {
  const router = useRouter();
  const path = router.pathname;
  const { flavour } = props;
  //const [showAnnouncement, setShowAnnouncement] = useState(false);

  const {
    data: auctions,
    status: auctionsStatus,
    error,
  } = useAuctionsByFeatured();

  useEffect(() => {
    if (!auctions) {
      return;
    }
    if (auctions.length === 0) {
      return;
    }

    var s = path === "/";
    if (s) {
      setOpen(true);
      return;
    }

    s = path.startsWith("/item/");
    if (s) {
      setOpen(true);
      return;
    }

    setOpen(false);
  }, [path, auctions]);

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const {
    onClose,
    bgColor,
    textColor,
    text,
    linkPath,
    linkText,
    ...otherProps
  } = props;

  const [duration, setDuration] = useState();

  useEffect(() => {
    setDuration(getAge());
    const intervalId = setInterval(() => {
      const i = getAge();
      setDuration(i);
    }, 15000);


        // our cleanup will run when the component unmounts
        return () => {
          clearInterval(intervalId);
        }

  }, []);

  function getAge() {
    // Pass zulu timestamps in.
    var startDatetime = new Date(Date.now());
    const dropDatetime = new Date(startDatetime.toISOString());

    //https://stackoverflow.com/questions/1090815/how-to-clone-a-date-object

    const announcement = { text: "Some text to be announced", runAt: "21:00" };

    const parts = announcement.runAt.split(":");

    var dropTimeHours = parts[0];
    var dropTimeMinutes = parts[1];

    dropDatetime.setUTCHours(dropTimeHours);
    dropDatetime.setUTCMinutes(dropTimeMinutes);

    var diff = startDatetime - dropDatetime;

    if (diff > 0) {
      diff = 24 * 60 * 60 * 1000 - diff;
    }

    const togo = secondsToReadableString(diff / 1000, true);
    return togo;
  }


  const announcements = [
    // { announcement: `Next Price Drop in ${duration}` },
    {
      announcement:
        "Curated finds from the Hampton’s Finest Homes.",
    },
    // {
    //   announcement:
    //     "Price drops on every item for 7 Days. [Learn More.](https://ladybidwell/learnmore) ",
    // },
    {
      announcement:
        "Weekly sale with price drops everyday, on every item.",
    },
  ];

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });



  const [currentAnnouncementIndex, setCurrentAnnouncementIndex] =
    React.useState(0);

  React.useEffect(() => {
    const id = setTimeout(
      () =>
        setCurrentAnnouncementIndex(
          (currentAnnouncementIndex + 1) % announcements.length
        ),
      6000
    );
    return () => {
      clearInterval(id); // removes React warning when gets unmounted
    };
  }, [currentAnnouncementIndex]);

  if (flavour === "navbar") {
    return (
      <>
        <ReactMarkdown disallowedElements={["p"]} unwrapDisallowed={true}>
          {announcements[currentAnnouncementIndex].announcement}
        </ReactMarkdown>
      </>
    );
  }

  return (
  <>

      <Alert
        variant="filled"
        icon={false}
        onClose={onClose}
        classes={{
          root: classes.alert,
          message: classes.message,
        }}
      >

  <>
<Hidden smUp>

{/* <Box display='flex'  alignItems='center'>
<Typography

                variant="body1"
                className={classes.headline}
                style={{
                  
                  fontWeight: "300",
                  color: "#ffffff",
                  textAlign:'left',
         
                }}
              >       <ReactMarkdown disallowedElements={["p"]} unwrapDisallowed={true}>
              {announcements[currentAnnouncementIndex].announcement}
            </ReactMarkdown>
                </Typography>
                </Box> */}





      <Typography variant="subtitle2"
        style={{
          // fontSize: ".75em",
          letterSpacing: "3px",
          // fontWeight: "300",
          color: "#ffffff",
          textAlign:'left',
        }} 
        textAlign="center"
      >
        HAMPTONS, NY 
      </Typography>
  
 



                
</Hidden>
</>

<Hidden xsDown>

        <Typography
          variant="subtitle1"
          className={classes.headline}
          style={{color:'#ffffff', fontWeight: '400' }}

        >
          <ReactMarkdown disallowedElements={["p"]} unwrapDisallowed={true}>
            {announcements[currentAnnouncementIndex].announcement}
          </ReactMarkdown>
        </Typography>
        

        </Hidden>
        {/* </Box> */}
      </Alert>


  </>
  );
}

export default Announcement;
