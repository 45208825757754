import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useAuth } from "./../util/auth.js";
import { useForm } from "react-hook-form";
import {
  useBidsByItem,
  useBidsByItemOwner,
  useAuctionByEvent,
} from "./../util/db.js";
import { makeStyles } from "@material-ui/core/styles";
import { v4 as uuidv4 } from "uuid";
import { createBid } from "./../util/db.js";
import InputAdornment from "@material-ui/core/InputAdornment";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { Controller } from "react-hook-form";
import Select from "@material-ui/core/Select";
import clsx from "clsx";
import Card from "@material-ui/core/Card";
import Collapse from "@material-ui/core/Collapse";
import Typography from "@material-ui/core/Typography";


// import firebase from "../util/firebase";
import ReactTextTransition from "react-text-transition";
import SignInButton from "./../components/SignInButton";
// import { database } from "firebase-admin";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";

import SellerReserveEdit from "./SellerReserveEdit.js";
import { isPastTime } from "../util/time.js";
import { bidTable } from "../util/auction.js";
import PaymentIcon from "@material-ui/icons/Payment";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { getFunctions, httpsCallable } from "firebase/functions";

const functions = getFunctions();

const processStripeClientSecret = httpsCallable(
  functions,
  "processStripeClientSecret"
);
const processBid = httpsCallable(functions, "processBid");

const useStyles = makeStyles((theme) => ({
  root: {
    border: "none",
    boxShadow: "none",
    background: "none",
    // maxWidth: 345,
    // display: "flex",
    // "& > * + *": {
    //   marginLeft: theme.spacing(2),
    // },
  },

  formControl: {
    background: "white",
    minWidth: 140,
  },

  content: {
    paddingLeft: "0px",
    paddingBottom: 24,
  },

  button: {
    fontWeight: "600",
    paddingTop: "15px",
    paddingBottom: "15px",
    marginTop: "10px",
    fontSize: "1.25em",
  },

  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: "theme.transitions.duration.shortest",
    }),
  },
  button: {
    fontWeight: 600,
    paddingTop: "15px",
    paddingBottom: "15px",
    marginTop: "10px",
  },

  // expandOpen: {
  //   transform: 'rotate(180deg)',
  // },
}));

function BuyerBidEdit(props) {
  const classes = useStyles();

  const auth = useAuth();

  const [auctionMode, setAuctionMode] = useState("false");

  const [editImagesKey, setEditImagesKey] = useState(uuidv4());

  const [auctioneerBuyerMessage, setAuctioneerBuyerMessage] = useState(
    "Ready for your bid."
  );
  const [buyerBidMinimum, setBuyerBidMinimum] = useState(); // The current minimum the Buyer may place

  const [amountList, setAmountList] = useState([100, 120, 140, 160, 180, 200]);

  const { palette } = props.item;

  const [pending, setPending] = useState(false);
  const [formAlert, setFormAlert] = useState(null);

  const {
    register,
    handleSubmit,
    errors,
    control,
    getValues,
    watch,
    reset,
    setValue,
  } = useForm();

  const [itemId, setItemId] = useState(props.item.id);

  const { item, lot } = props;
  // const { data, status } = useLotByItem(item.id);
  //  const lot = data;

  const [buyerBidNext, setBuyerBidNext] = useState(); // Holds the bid the Buyer is about to place

  //lot && lot.startsAt && isPastTime(lot.startsAt);

  //var lotBidAmount = 0;
  //if (status === "success") {
  //var rawLotBidAmount = status === 'success' &&lot && lot.bidAmount ? data.bidAmount : 0;
  //var lotBidAmount = parseFloat(rawLotBidAmount);

  //  if (lot && status === "success") {
  var lotBidAmount = null;
  if (lot) {
    lotBidAmount =
      lot && lot.bidAmount
        ? parseFloat(lot.bidAmount)
        : parseFloat(lot.startingPrice);
  }
  //  }

  //}
  // const isLot =
  //   lot && lot.startingPrice && lot.estimatePrice && lot.id ? true : false;

  const isLot = lot && lot.startingPrice && lot.id ? true : false;

  const isKnockedDown =
    lot && lot.knockedDownAt && lot.knockedDownAt !== false ? true : false;

  const isBiddingStarted = lot && lot.startsAt && isPastTime(lot.startsAt);

  const isWinner = auth.user.id === (lot && isKnockedDown && lot.bidOwner);

  const [isLotStarted, setIsLotStarted] = useState(false);

  const { status: buyerLotStatus, data: buyerLotData } = useBidsByItemOwner(
    item.id,
    auth.user.id
  );

  var buyerBidAmount = 0;
  if (buyerLotStatus === "success" && buyerLotData.length > 0) {
    buyerBidAmount = buyerLotData[0].amount;
  }

  const {
    data: auctionData,
    status: auctionStatus,
    error: auctionError,
  } = useAuctionByEvent(lot && lot.auction ? lot.auction : undefined);

  // Calculate the bidding table value available
  useEffect(() => {
    const dropdownSize = 100;

    if (lot === undefined || lot === null) {
      return;
    }

    var bidAmount = 0;
    /*
    bidAmount =
      lot && lot.startingPrice
        ? bidTablePlacedAt(lot.startingPrice) - bidIncrement(lot.startingPrice)
        : bidAmount;
*/

    bidAmount = parseFloat(lot.startingPrice);
    //    if (lot && lot.bidAmount) {
    //      bidAmount = lot.bidAmount;
    //    }

    if (lotBidAmount !== 0 && lot.bidOwner !== undefined) {
      if (auth.user.id === lot.bidOwner) {
        // If you are the current highest bidder,
        // You can choose your current bid amount.
        bidAmount = parseFloat(lotBidAmount);
      } else {
        // If you are not currently the highest bidder,
        // you can only bid at the next bid increment.
        bidAmount = parseFloat(lotBidAmount);
        bidAmount = bidAmount + bidIncrement(bidAmount);
      }
    }

    let t = [];

    var bidDropDownAmount = bidAmount;
    for (var i = 0; i < dropdownSize; i++) {
      t.push(bidDropDownAmount);
      bidDropDownAmount = bidDropDownAmount + bidIncrement(bidDropDownAmount);
    }

    //setLotBidAt(startAt);
    setAmountList(t);
  }, [lot, buyerBidAmount, buyerBidMinimum]);

  const watchAmount = watch("amount");

  useEffect(() => {
    if (watchAmount === undefined) {
      return;
    }
    console.log("WATCH Selected " + watchAmount);
    setBuyerBidNext(watchAmount);
  }, [watchAmount]);

  // Calculate and set the Buyer's Minimum Bid.
  // This is the minimum bid the buyer is allowed to place.
  useEffect(() => {
    //  if (status !== "success") {
    //    return;
    //  }
    if (buyerLotStatus !== "success") {
      return;
    }
    if (lot === null) {
      return;
    }

    var amount = parseFloat(lot.startingPrice);

    if (
      parseFloat(lotBidAmount) !== parseFloat(lot.startingPrice) &&
      parseInt(lot.bidCount) > 0
    ) {
      amount =
        parseFloat(lotBidAmount) + parseFloat(bidIncrement(lotBidAmount));
    }

    setBuyerBidMinimum(amount);
  }, [lot, buyerBidAmount, buyerLotStatus]);

  useEffect(() => {
    //   if (status !== "success") {
    //     return;
    //   }
    if (buyerLotStatus !== "success") {
      return;
    }
    if (lot === null) {
      return;
    }

    var amount = parseFloat(lotBidAmount);

    console.log("What does lotBidAmount start at?", amount);
    console.log("Starting lot position", lot);
    if (amount === 0 || lot.bidOwner === undefined) {
      amount = parseFloat(lot.startingPrice);
    } else {
      amount =
        parseFloat(lot.startingPrice) +
        bidIncrement(parseFloat(lot.startingPrice));
    }

    console.log("buyerLotData", buyerLotData);
    // console.log("BuyerBidNext LotData[0]", buyerLotData[0].amount);
    console.log("lotBidAmount", lotBidAmount);

    if (buyerLotData.length > 0) {
      console.log(
        "setBuyerBidNext buyerLotData[0].amount",
        buyerLotData[0].amount
      );
      amount = Math.max(lotBidAmount, buyerLotData[0].amount);
      amount = amount + bidIncrement(amount);
    }

    console.log("setBuyerBidNext amount", amount);
    console.log("setBuyerBidNext lotBidAmount", lotBidAmount);

    console.log("setBuyerBidNext lot.starting", lot.startingPrice);

    //if (amount < buyerBidMinimum) {amount = buyerBidMinimum;}

    console.log("Updated buyerBidNext to ", amount);
    setBuyerBidNext(amount);
    setValue("amount", amount);
  }, [lot, buyerBidAmount, buyerLotStatus, buyerLotData]);

  // Calculate and set the Buyer's Next Bid.
  // This is the Bid the buyer is about to place.

  const itemData = props.item;

  const bids = useBidsByItem(itemId);
  const { event } = props;

  const [expanded, setExpanded] = useState(false);

  function bidTablePlacedAt(num) {
    var bidTable = 0;
    var lastBidTable = 0;
    while (num >= bidTable) {
      lastBidTable = bidTable;
      bidTable = bidTable + bidIncrement(bidTable);
    }
    return lastBidTable;
  }

  function bidIncrement(num) {
    var increment = 10;
    for (var i = 0; i < bidTable.length; i++) {
      if (num < bidTable[i].bid) {
        break;
      }
      increment = bidTable[i].increment;
    }

    return increment;
  }

  function handleExpandClick() {
    setExpanded(!expanded);
  }

  useEffect(() => {
    //if (status !== 'success') {return;}
    if (lot === undefined || lot === null) {
      return;
    }

    var amount = lot.startingPrice;

    // Is the starting price and the bid the same?
    if (parseFloat(lotBidAmount) === parseFloat(lot.startingPrice)) {
      amount = lotBidAmount;
    }

    //if (parseFloat(lotBidAmount) === parseFloat(lot.startingPrice && lot.bidOwner !== auth.user.id) ) {
    //  amount = lotBidAmount + bidIncrement(lotBidAmount);
    //}

    if (
      parseFloat(lotBidAmount) > parseFloat(lot.startingPrice) &&
      parseInt(lot.bidCount) !== 0
    ) {
      amount = lotBidAmount + bidIncrement(lotBidAmount);
    }

    console.log("BuyBidEdit auctionMode change amount", amount);
    console.log("BuyBidEdit auctionMode change lotBidAmount", lotBidAmount);
    console.log(
      "BuyBidEdit auctionMode change lot.startingPrice",
      lot.startingPrice
    );

    if (
      auth.userId !== lot.bidOwner &&
      parseFloat(lotBidAmount) === parseFloat(lot.startingPrice) &&
      parseInt(lot.bidCount) !== 0
    ) {
      console.log("Not the bid owner");
      amount = lotBidAmount + bidIncrement(lotBidAmount);
    }

    setBuyerBidNext(amount);
  }, [auctionMode]);

  const handleAuctionModeChange = () => {
    setAuctionMode(!auctionMode);
  };

  // This will fetch item if props.id is defined
  // Otherwise query does nothing and we assume
  // we are creating a new item.

  // If we are updating an existing item
  // don't show modal until item data is fetched.

  // Either the component has started,
  // Or a new image has been added.
  // Or (next) an image has been deleted.

  // We have an itemData auction set.
  //setAuction(itemData.auction)

  // Used for clicking on the Save button
  // This is what happens when the user gives a "commit"
  // signal to this modal.

  // The modal should also have an "onCancel" handler.

  function handlePlaceBid(bid) {
    processBid(bid)
      .then((result) => {
        // Read result of the Cloud Function.
        //var sanitizedMessage = result.data.text;
      })
      .catch((error) => {
        //   console.log("handlePlaceBid error")
        //   console.log(error)
        // Getting the Error details.
        alert(
          "There was a problem placing your bid. It has not been acknowledged " +
            error.code
        );
        var code = error.code;
        var message = error.message;
        var details = error.details;
        // ...
      });
  }

  const onSubmit = (data, e) => {
    setPending(true);
    setExpanded(false);

    // TODO
    //  setPending(true);
    //  setExpanded(false);
    // auctionMode = false = qucik bid

    console.log("data reserve", data);

    // Are we in Quick Bid Mode?
    if (!auctionMode) {
      //var data = { ...data, amount: buyerBidMinimum };
      var data = { ...data, amount: buyerBidNext };
    }

    //console.log("data", data);

    const query = createBid({
      owner: auth.user.id,
      ...data,
      item: props.item.id,
      auction: props.item.auction,
    });
    e.target.reset();
    query
      .then(function (docRef) {
        console.log("Successfully created a bid.");
        handlePlaceBid({ id: docRef.id, item: props.item.id });

        setPending(false);
        setAuctioneerBuyerMessage("Bid acknowledged.");
      })
      .catch((error) => {
        console.log(error);
        alert("Error");
        // Hide pending indicator
        setPending(false);
        setExpanded(false);
        setAuctioneerBuyerMessage(error.message);
        // Show error alert message
        setFormAlert({
          type: "error",
          message: error.message,
        });
      });
  };

  useEffect(() => {
    if (lot === null) {
      return;
    }
    console.log("lot", lot);
    if (lot !== undefined) {
      console.log("lot.bidOwner", lot.bidOwner);
    }
  }, [lot]);

  const HighestBidNotice = () => {
    if (lot && lot.bidOwner === undefined) {
      return null;
    }

    // current buyers numbers of bid.
    // current buyers bid amount (not placed / is an amount)
    if (buyerBidAmount > lotBidAmount) {
      return null;
    }

    if (auth.user && buyerBidAmount > 0) {
      return (
        //   <Typography variant="h6" style={{ fontWeight: "500" }}>
        //   {lot.bidOwner === auth.user.id
        //     ? "YOU ARE THE HIGHEST BIDDER"
        //     : "YOU ARE NOT THE HIGHEST BIDDER"}{" "}
        //   {lot.bidOwner}
        // </Typography>

        <>
          <Box display="flex" py={1}>
            <Box flexGrow={1} alignSelf="center">
              <Typography variant="subtitle1" style={{ fontWeight: "600" }}>
                {lot.bidOwner === auth.user.id ? (
                  <>
                    <span style={{ color: "#004225" }}>
                      YOU ARE THE HIGHEST BIDDER
                    </span>
                  </>
                ) : (
                  <span style={{ color: "#bd162c" }}>YOU HAVE BEEN OUTBID</span>
                )}
                {/* {lot.bidOwner} */}
              </Typography>
            </Box>

            <Box>
              {lot.bidOwner === auth.user.id ? (
                <CheckCircleIcon style={{ color: "#004225" }} />
              ) : (
                <ErrorIcon style={{ color: "#bd162c" }} />
              )}
            </Box>
          </Box>
        </>
      );
    }

    // Otherwise
    return null;
  };

  useEffect(() => {
    const timer2 = setInterval(() => {
      if (lot === undefined || lot === null) {
        return;
      }
      if (isLotStarted === true) {
        clearInterval(timer2);
      }
      console.log("Check isPastTime lot.startsAt", lot.startsAt);
      if (isPastTime(lot.startsAt)) {
        //if (isAuctionRunning === true && isKnockedDown !== true) {setIsBiddable(true);}
        setIsLotStarted(true);
      }
      // else {

      //setIsBiddable(false);

      // }
    }, 1000);
    // Clear timeout if the component is unmounted
    return () => clearTimeout(timer2);
  }, [lot]);

  //Over to you. Waiting for phone to come back on.

  const PlaceBidButton = () => {
    console.log("PlaceBidButton");
    console.log("buyerBidAmount", buyerBidAmount);
    console.log("lotBidAmount", lotBidAmount);
    // Quick Bid
    if (
      auctionMode === false &&
      auth.user.id === lot.bidOwner &&
      buyerBidAmount !== 0 &&
      lotBidAmount !== 0 &&
      buyerBidAmount + bidIncrement(buyerBidAmount) > lotBidAmount
    ) {
      return (
        <Button
          disabled={true}
          disableElevation
          fullWidth
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          style={{ backgroundColor: "black", color: "white" }}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          size="large"
          aria-label="show more"
          variant="contained"
          color="secondary"
          className={classes.button}
        >
          YOU HAVE THE HIGHEST BID
        </Button>
      );
    }
    // Quick Bid
    if (auctionMode === false && buyerBidNext <= buyerBidAmount) {
      return null;
      // return (<>"Buyers next bid less than current amount</>)
    }

    if (auctionMode === false && buyerBidNext > buyerBidAmount) {
      //if (
      //  auctionMode === false &&
      //  buyerBidAmount + bidIncrement(buyerBidAmount) <= lotBidAmount
      //) {
      return (
        <Button
          disabled={false}
          disableElevation
          fullWidth
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          style={{ backgroundColor: "black", color: "white" }}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          size="large"
          aria-label="show more"
          variant="contained"
          color="secondary"
          className={classes.button}

          //  style={{ backgroundColor: palette.darkMuted }}
        >
          {buyerBidNext > buyerBidAmount && buyerBidAmount === 0
            ? "PLACE A BID OF $" + buyerBidNext.toLocaleString()
            : null}
          {buyerBidNext > buyerBidAmount && buyerBidAmount !== 0
            ? "INCREASE BID TO $" + buyerBidNext.toLocaleString()
            : null}

          {/*buyerBidNext === buyerBidAmount ? "THIS YOUR CURRENT BID " : null*/}
          {/*buyerBidNext < buyerBidAmount
          ? "DECREASE YOUR MAXIMUM BID TO $" + buyerBidNext.toLocaleString()
        : null*/}

          {/*"INCREASE BID TO $" + buyerBidNext.toLocaleString()*/}
        </Button>
      );
    }

    // Maximum bidding (place a bid).

    return (
      <Button
        disabled={buyerBidNext === buyerBidAmount}
        disableElevation
        fullWidth
        className={clsx(classes.expand, {
          [classes.expandOpen]: expanded,
        })}
        style={{ backgroundColor: "black", color: "white" }}
        onClick={handleExpandClick}
        aria-expanded={expanded}
        size="large"
        aria-label="show more"
        variant="contained"
        color="secondary"
        className={classes.button}

        //  style={{ backgroundColor: palette.darkMuted }}
      >
        {buyerBidNext > buyerBidAmount && buyerBidAmount === 0
          ? "PLACE A BID OF $" + buyerBidNext.toLocaleString()
          : null}
        {buyerBidNext > buyerBidAmount && buyerBidAmount !== 0
          ? "INCREASE YOUR MAXIMUM BID TO $" + buyerBidNext.toLocaleString()
          : null}
        {buyerBidNext === buyerBidAmount ? "THIS YOUR CURRENT BID " : null}
        {buyerBidNext < buyerBidAmount
          ? "DECREASE YOUR MAXIMUM BID TO $" + buyerBidNext.toLocaleString()
          : null}
      </Button>
    );
  };
  const MinimumBidAmount = () => {
    return (
      <>
        <TextField
          variant="outlined"
          disabled
          fullWidth
          value={buyerBidMinimum}
          style={{ color: "black", fontWeight: "700" }}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        ></TextField>
      </>
    );
  };

  const ButtonWinner = () => {
    //return (<Link to={"/item/"+item.id}><Button>Winner</Button></Link>);
    return (
      <Link to={"/payment"}>
        <Button variant="outlined" fullWidth endIcon={<PaymentIcon />}>
          You have won. Pay Now
        </Button>
      </Link>
    );
  };

  if (lotBidAmount === undefined) {
    return null;
  }
  if (!isLot) {
    return <>This item has not been assigned a lot.</>;
  } // This item is not in an auction.
  // if (status !== "success") {
  //   return null;
  // }

  if (buyerBidNext === undefined) {
    return null;
  }

  if (buyerBidNext < lot.startingPrice) {
    return null;
  }

  if (isKnockedDown || isPastTime(lot.endsAt)) {
    if (isWinner) {
      return <ButtonWinner />;
    } else {
      return <>This auction has ended.</>;
    }
  }

  if (!isBiddingStarted) {
    return <>Bidding has not started yet. Come back later.</>;
  }

  if (auctionStatus !== "success") {
    return null;
  }
  if (auctionStatus === "success" && auctionData.state === false) {
    return null;
  }

  if (isLotStarted === false) {
    return <>Lot has not started yet. Come back later.</>;
  }
  //Check if is is past the end time.

  return (
    <Card className={classes.root}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        //onChange={handleChange}
      >
        {auth.user && auth.user.id === itemData.owner ? null : (
          <HighestBidNotice />
        )}
        <Box display="flex" py={1}>
          {auth.user && auth.user.id !== itemData.owner ? (
            <>
              {/*<Box display="flex" pt={1}>*/}
              <Box flexGrow={1}>
                <Typography
                  variant="subtitle1"
                  style={{ fontWeight: "400", color: palette.darkMuted }}
                >
                  Your Maximum Bid:
                </Typography>
              </Box>

              <Typography
                variant="subtitle1"
                style={{ fontWeight: "500", color: palette.darkMuted }}
              >
                <Box display="flex">
                  {buyerBidAmount ? "$" : ""}
                  <ReactTextTransition
                    springConfig={{ stiffness: 50, damping: 20 }}
                    noOverflow
                    text={
                      buyerBidAmount
                        ? buyerBidAmount.toLocaleString()
                        : "No maximum set"
                    }
                  />

                  {/* <BuyerBidLot
                    lot={lot}
             
                  /> */}
                </Box>
              </Typography>
            </>
          ) : null}
        </Box>
        {auth.user && auth.user.id === itemData.owner ? (
          <SellerReserveEdit item={item} lot={lot} />
        ) : (
          <>
            <SignInButton />
          </>
        )}

        {auth.user && !auth.user.canBid && auth.user.id !== itemData.owner ? (
          <>
            <Button
              fullWidth
              size="large"
              variant="outlined"
              endIcon={<ArrowForwardIosIcon />}
              component={Link}
              to="/settings/billing"
              style={{ backgroundColor: "black", color: "white" }}
              className={classes.button}
            >
              PLEASE PROVIDE PAYMENT INFO TO BID
            </Button>
          </>
        ) : null}

        {auth.user && auth.user.canBid && auth.user.id !== itemData.owner ? (
          <>
            <Box display="flex">
              <Box flexGrow={1} alignSelf="center">
                <Typography
                  variant="subtitle1"
                  style={{ fontWeight: "400", color: palette.darkMuted }}
                >
                  Select Bid Type:
                </Typography>
              </Box>

              {/* <Button variant="contained" disableElevation onClick={handleAuctionModeChange} 
        endIcon={<UnfoldMoreOutlinedIcon />}        
        >    

          {auctionMode ? "Maximum Bid" : "Quick Bid"}
       
        </Button> */}
              <FormControl className={classes.formControl}>
                <Select
                  value={auctionMode ? "Maximum Bid" : "Quick Bid"}
                  onChange={handleAuctionModeChange}
                  variant="outlined"
                >
                  <MenuItem value={"Maximum Bid"}>Maximum Bid</MenuItem>
                  <MenuItem value={"Quick Bid"}>Quick Bid</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <Box></Box>

            <br />
            <Box>
              {/*auctionmode*/}
              {auctionMode ? (
                <>
                  <Grid item={true} xs={12}>
                    <FormControl
                      className={classes.formControl}
                      fullWidth
                      variant="outlined"
                    >
                      {/* <InputLabel>Choose an auction</InputLabel> */}
                      <Controller
                        // required
                        // initialValue={buyerBidNext}
                        // defaultValue={buyerBidNext}
                        fullWidth
                        //defaultValue={amountList[0]}
                        control={control}
                        name="amount"
                        //innerRef={register("amount")}

                        value={buyerBidNext}
                        defaultValue={buyerBidNext}
                        as={
                          <Select
                            //         onChange={handleNextBidChange}
                            id="amount"
                            //name="amount"
                            //     value={buyerBidNext ? buyerBidNext : 0}
                            //   defaultValue={buyerBidNext ? buyerBidNext : 0}
                          >
                            {amountList.map((_amount) => (
                              <MenuItem key={_amount} value={_amount}>
                                {_amount === 0
                                  ? "Placeholder"
                                  : "$" + _amount.toLocaleString()}
                              </MenuItem>
                            ))}
                          </Select>
                        }
                      />
                    </FormControl>
                  </Grid>
                </>
              ) : (
                <>{/*}
                <MinimumBidAmount />
            */}</>
              )}
            </Box>

            <Collapse in={expanded} timeout={300} unmountOnExit></Collapse>
            {expanded === false ? (
              <>
                <SignInButton />

                <PlaceBidButton />

                <br />

                <Box py={2}>
                  {auctionMode ? (
                    <>
                      <Typography
                        variant="subtitle1"
                        p={1}
                        style={{ lineHeight: "1.35em" }}
                      >
                        With maximum bid your bid will increase automatically to
                        ensure you remain the highest bidder, until your maximum
                        is reached.
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Typography
                        variant="subtitle1"
                        p={1}
                        style={{ lineHeight: "1.35em" }}
                      >
                        With quick bid you can quickly place a bid at the
                        current bidding price.
                      </Typography>
                    </>
                  )}
                </Box>
              </>
            ) : (
              <Box></Box>
            )}

            <Collapse in={expanded} timeout={300} unmountOnExit>
              <Button
                className={classes.button}
                disableElevation
                fullWidth
                type="submit"
                size="large"
                disabled={pending}
                style={{ color: "white", backgroundColor: "#004225" }}
                // onClick={handleExpandClick}
                //       onClick="success"
                // onClick="success"
              >
                {!pending && (
                  <span>Confirm Bid ${buyerBidNext.toLocaleString()}</span>
                )}
                {/* {!pending && <span>Confirm Bid {getValues("amount")}</span>} */}

                {pending && <CircularProgress size={28} />}
              </Button>

              <Button
                fullWidth
                variant="outlined"
                className={clsx(classes.expand, {
                  [classes.expandOpen]: expanded,
                })}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
                className={classes.button}
              >
                Cancel
              </Button>
            </Collapse>

            {/*}     <LotBiddingAt item={item} /> */}
          </>
        ) : null}
        {/* </CardContent> */}
      </form>

      {auctioneerBuyerMessage}
      <p></p>
      {/*   <BidsItem item={item} /> */}
    </Card>
  );

  //if (itemData === undefined) {
  //  return null;
  //}

  //if ((auctions && auctions.status !== "success") || auction === false || itemData === undefined) {
  //  return null;
  //}

  //if (!(itemId && itemStatus !== "success")) {
  //  return <CircularProgress />;
  //}

  //if (itemData === undefined) {
  //  return <CircularProgress />;
  //}
}

export default BuyerBidEdit;
