import React, { useState, useEffect } from "react";
import { Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { requireAuth } from "../util/auth";
import { useAuth } from "./../util/auth.js";
import {
  createAuction,
  useAuctionByEvent,
  useLotsByAuction,
  updateLot,
} from "./../util/db.js";
import { pickHighest } from "../util/array.js";

//import { createMessage } from "./../util/db.js";

import { makeLots } from "./../util/lot.js";
import { updatePriceTables } from "./../util/price.js";

import { debugFlag } from "./../util/dev.js";

const useStyles = makeStyles((theme) => ({
  paperItems: {
    minHeight: "300px",
  },
}));

// makeLots(items, lotsData, event, maxIndex)

const lotClosingTimeIncrement = 0;
//const lotTimeSlot = 0;

/*

  Auction Start at 12:00  on Monday
  Auction starts to close at 17:50 on Friday.
  Lot 1. starts at 12:00 on Monday. closes at 17:51 on Friday
  Lot 2. starts at 12:00 on Monday. closes at 17:52 on Friday
  Lot 3. starts at 12:00 on Monday. closes at 17:53 on Friday


  */
const test = false;

function CreateLots(props) {
  const auth = useAuth();
  const classes = useStyles();
  const { items } = props;
  const { event } = props;
  const isAuctioneer = auth.user.role && auth.user.role === "auctioneer";

  const [maxIndex, setMaxIndex] = useState(0);

  const { status, data, error } = useAuctionByEvent(event.id);

  const {
    data: lotsData,
    status: lotsStatus,
    error: lotsError,
  } = useLotsByAuction(event.id);

  const isAuction = data === undefined || data === null ? false : true;

  const closesAt = event.endTime;
  const startsAt = event.startTime;

  const [creatingItem, setCreatingItem] = useState(false);
  const [itemCount, setItemCount] = useState();

  useEffect(() => {
    if (status === "error") {
      console.log("error", error);
      createAuction(event.id, { state: false });
      return;
    }

    if (status !== "success") {
      return;
    }

    if (lotsStatus !== "success") {
      return;
    }
    const i = pickHighest(lotsData);
    setMaxIndex(i);

    // Then run makeLots;
    // makeLots();
  }, [status, data, lotsStatus, lotsData]);

  useEffect(() => {
    if (items === undefined) {
      return;
    }
    setItemCount(items.length);
  }, [items]);

  useEffect(() => {
    if (itemCount === undefined || itemCount === 0) {
      return;
    }
    if (lotsStatus !== "success") {
      return true;
    }
    console.log("MAKE LOTS UPDATE TRIGGERED");
    //    makeLots();

    makeLots(event);
  }, [itemCount, lotsStatus]);

  function handleMakeLots() {
    makeLots(event);
  }

function handleUpdatePriceTables() {
  updatePriceTables(event);
}

  function reindexLots() {
    var count = 0;
    var index;

    for (index in lotsData) {
      updateLot(lotsData[index].item, { index: count });
      count += 1;
    }
  }


  if (status !== "success") {
    return null;
  }

  // Do not render anything...
  // Comment out to enable the buttons.

  return (
    <>
      <Typography className={classes.text} variant="h5"></Typography>
      {isAuctioneer ? (
        <>
{debugFlag && (
          <Button
            variant="contained"
            size="medium"
            color="primary"
            onClick={handleMakeLots}
            //disabled={true}
          >
            GENERATE LOT TIMES (AUTO){" "}
          </Button>
)}
{false && (  
          <Button
            variant="contained"
            size="medium"
            color="primary"
            onClick={reindexLots}
            //disabled={true}
          >
            REINDEX LOTS{" "}
          </Button>
)}

{false && (          <Button
            variant="contained"
            size="medium"
            color="primary"
            onClick={handleUpdatePriceTables}
            //disabled={true}
          >
            UPDATE PRICE TABLES{" "}
          </Button>
)}
          {/* <ResetAuction event={event} />
              <PublishEvent event={event} />
              <RunAuction event={event}/> */}
        </>
      ) : null}
    </>
  );
}

export default requireAuth(CreateLots);
