import { Box } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

const ListSkeleton = ({ listsToRender }) => {
    return (
      <>
        {Array(listsToRender)
          .fill(1)
          .map((card, index) => (
            <Box my={3} key={index}>
              <Skeleton  variant="rect" height={300} />
            </Box>
          ))}
      </>
    );
  };

  export default ListSkeleton;