import React, { useMemo, useState, Suspense } from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";
import { Typography, Hidden, Box } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      cursor:'pointer',
    },
    
    thubmnail: {
      width:15,
      backgroundColor: "red",
      "&.image-gallery-thumbnail.active": {
        borderColor: "green",
      },
      "&.image-gallery-thumbnail:hover": {
        borderColor: "lightgreen",
      },
    },
    mobileGalleryContainer: {
      maxHeight: "300px",
      minHeight: "300px", // Set the minHeight to the same value as maxHeight
      // overflow: "hidden",
      //display: "flex", // Add display flex
      justifyContent: "center", // Center the image horizontally
      alignItems: "center", // Center the image vertically
    },    


  })
);

export default function Carousel(props) {
  const {images} = props;
  //console.log("Carousel images")
  //console.log(images)
  let textInput = React.createRef();
  const classes = useStyles();

  const [carouselStatus, setCarouselStatus] = useState("waiting");

  const [fullScreen, setFullScreen] = useState(false);
  const [carouselIndex, setCarouselIndex] = useState();
  const items = images;

//  console.log("carousel", images)

//   console.log("carousel status", carouselStatus)
  
  /*
  const items = useMemo(() => {
    if (images === undefined) {return []}
    return images.map((item) => {
      const newItem = { ...item };
      newItem.thumbnailClass = classes.thumbnail;
      return newItem;
    });
  }, [classes.thumbnail]);
*/
  function handleScreenChange() {
    setFullScreen(!fullScreen);


    if (fullScreen === true) {
      document.body.style.overflow = 'unset';
      textInput.current.slideToIndex(0);
    }
  }

  function handleClick(event) {
    if (fullScreen === false) {
      textInput.current.fullScreen();
      document.body.style.overflow = 'hidden';
    }
  }

  function renderFullscreenButton(onClick, isFullscreen) {
    if (isFullscreen === false) {
      return null;
    }

    return (

      <Button
        className={`close-carousel-button${isFullscreen ? " active" : ""}`}
        onClick={onClick}
        style={{
          position: "fixed",
          top: "20px",
          right: "20px",
          background: "white",
        }}
      >
        <CloseIcon style={{fontSize:"4em"}} />
      </Button>

    );
  }





function handleImageLoad() {
console.log("Carousel handleImageLoad");
//setCarouselStatus("success")

props.onCarouselLoad();

}


  return (
    <>



{/* MOBILE GALLERY */}
<Hidden mdUp>

<div className={classes.mobileGalleryContainer}>

<ImageGallery
  ref={textInput}
  slideDuration={0}
  //items={{...items, loading:"eager"}}
  items={items}
  startIndex={carouselIndex}
  showIndex={false}
  useBrowserFullscreen={false}
  thumbnailPosition={"bottom"}
  showPlayButton={false}
  // showNav={true}
  disableKeyDown={true}
  showThumbnails={false}   
  showFullscreenButton={false}
  lazyLoad={true}   
  showBullets={true} 
  
  swipingTransitionDuration={350}
  onImageLoad={()=>handleImageLoad()}

/>

</div>
</Hidden>

{/* DESKTOP VIEW*/}



<Hidden smDown>


<div className={classes.root}>
 {/* <div className="fade"> */}
  <ImageGallery
    ref={textInput}
    originalClass="fullscreen"
    slideDuration={0}
    onScreenChange={handleScreenChange}
    items={items}
    lazyLoad={true}   
    startIndex={carouselIndex}
    showIndex={false}
    useBrowserFullscreen={false}
    thumbnailPosition={"bottom"}
    showPlayButton={false}
    showNav={true}
    renderFullscreenButton={renderFullscreenButton}
    onThumbnailClick={handleClick}
    onClick={handleClick}
    disableKeyDown={true}
    showThumbnails={false}    
    onImageLoad={()=>handleImageLoad()}
   // loading="eager"     
  />
{/* </div> */}

{/* {(carouselStatus === 'success') && (  */}

<div style={{textAlign:'center', marginTop:'35px', zIndex:"0"}}>


<Button onClick={handleClick} variant='outlined'>
<Typography variant="subtitle1" style={{textTransform:'none', fontWeight:'500'}}>
View Gallery

</Typography>
</Button>


</div>
{/* )} */}


</div>
</Hidden>

</>
  );
}

