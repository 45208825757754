import React from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Link } from "./../util/router.js";
import { useAuth } from "./../util/auth.js";
import { makeStyles } from "@material-ui/core/styles";
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';


const useStyles = makeStyles((theme) => ({
  tab: {
    "& .MuiTab-root": {
    //  color: "red",
    //  fontSize: "0.5em",
     textTransform: "none",
     
    }
  },

  iconContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginRight:'8px',
    color: theme.palette.error.main,
  }
  
}));

function SettingsNav(props) {
  const classes = useStyles();
  const auth = useAuth();

  const {flags} = props;

  const isEnabledSeller = auth.user && auth.user.isEnabledSeller;
  const hasCredit = false;

  const isDeveloper = auth?.user?.developer?.enabled;

  const isAffiliate = auth?.user?.affiliate?.enabled;



  return (

    <Tabs
      value={props.activeKey}
      indicatorColor="primary"
      textColor="primary"
      centered={false}
      className={classes.tab}
      variant="scrollable"
      // scrollButtons="on"
      //variant='fullWidth'
    >
{isEnabledSeller && (
 
    <Tab
        // label="Public Seller Profile"
        value="profile"
        component={Link}
        to="/settings/profile"
        // icon={ flags && flags.profile && flags.profile.flag && flags.profile.flag !== 'success' && <ErrorOutlineOutlinedIcon />}
        icon={
          <div className={classes.iconContainer}>
            {flags &&
              flags.profile &&
              flags.profile.flag &&
              flags.profile.flag !== 'success' && (
                <ErrorOutlineOutlinedIcon className={classes.icon} />
              )}
            <span>Public Seller Profile</span>
          </div>
        }

      ></Tab>
  )}


      <Tab
        label="Contact Details"
        value="general"
        component={Link}
        to="/settings/general"
      ></Tab>

      <Tab
        label="Password"
        value="password"
        component={Link}
        to="/settings/password"
      ></Tab>
{isEnabledSeller && (
      <Tab
        // label="Billing"
        value="billing"
        component={Link}
        to="/settings/billing"
        // icon={ flags && flags.billing && flags.billing.flag && flags.billing.flag !== 'success' && <ErrorOutlineOutlinedIcon />}

        icon={
          <div className={classes.iconContainer}>
            {flags && flags.billing && flags.billing.flag && flags.billing.flag !== 'success' && (
                <ErrorOutlineOutlinedIcon className={classes.icon} />
              )}
            <span>Billing</span>
          </div>
        }



      ></Tab>)}
      {isAffiliate && (
      <Tab
        label="Affiliate"
        value="affiliate"
        component={Link}
        to="/settings/affiliate"
      ></Tab>
      )} 
{isDeveloper && (
      <Tab
        label="Developer"
        value="developer"
        component={Link}
        to="/settings/developer"
      ></Tab>)}
    </Tabs>
  );
}

export default SettingsNav;
