import React from "react";
import Section from "./Section";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  // Increase <Container> padding so it's
  // at least half of <Grid> spacing to
  // avoid horizontal scroll on mobile.
  // See https://material-ui.com/components/grid/#negative-margin
  container: {
    padding: `0 ${theme.spacing(4)}px`,
  },
  imageWrapper: {
    // height:'20vh',
    margin: "0 auto",
    maxWidth: 570,
    width: "100%",
    "& > img": {
      width: "100%",
    },
  },
  row: {
 
    
    // Spacing between rows
    "&:not(:last-child)": {
      marginBottom: `${theme.spacing(2)}px`,
    },
  },
  title: {
    fontFamily: '"Cormorant Garamond", "serif"',
  },
}));

function BidwellManifesto(props) {
  const classes = useStyles();
  const { flavour } = props;
  const items = [
    {
      // number: 1,
      // iconColor: "primary.main",
    },
  ];

  const handleClick = () => {
    document.querySelector("#apply-section").scrollIntoView({ behavior: "smooth" });
  };

  return (
<>
      {/* <Typography variant='h3'  className={classes.title}>Start Selling</Typography> */}
 
 <Section>
   <Container>
        <Grid container={true} alignItems="center" spacing={2}>
          {/* <Grid container={true} item={true} direction="column" xs={12} md={6}>
            <figure className={classes.imageWrapper}>
              <img src={props.image} alt="" />
            </figure>
          </Grid> */}

          <Grid item={true} xs={12} md={12}>
            {items.map((item, index) => (
              <Grid
                className={classes.row}
                item={true}
                container={true}
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={5}
                key={index}
              >
{/* <Grid container={true} item={true} direction="column" xs={12} md={6}>
            <figure className={classes.imageWrapper}>
              <img src={props.image} alt="" />
            </figure>
          </Grid>
             */}



                <Grid item={true} xs={12} sm={9}  >
                <Box style={{textAlign:'center'}}>


{flavour === "about" && (

<Typography variant="h6" color="textSecondary" >
Welcome to LadyBidwell, where we bring together buyers and sellers in the Hamptons to create a vibrant marketplace for unique and interesting furniture, collectibles, jewelry, and art. We're dedicated to making it simple and enjoyable for buyers to find extraordinary items that suit their tastes.
</Typography>

)}

{flavour !== "about" && (
  <>
                  <Typography variant="h6" color="textSecondary" >
                    LadyBidwell works with sellers both large and small
                    throughout the Hamptons, with a focus on creating a
                    marketplace of interesting and unique furniture,
                    collectables, jewelry and art. We are pretty selective and
                    only accept items and sellers that will appeal to our
                    LadyBidwell shoppers.
                    <br />
                    <br />
                    Whether you are an estate sale company with unique finds
                    week-in-week-out, or you have one two many kitchen tables we
                    want to hear from you. Let us know what you are wanting to
                    sell. LadyBidwell and her team will review and
                    confirm your submission within one business day.
                  </Typography>
              <Box pt={4}>
                  
                  <Grid item={true} xs={true}>
             
              <Button variant="contained"
              onClick={handleClick}

              // fullWidth
            size="large"
            color="primary" style={{fontSize:'1.3em', fontWeight:'500'}}>
                  Get Started
                </Button>

      
               {/* <ApplySection /> */}

              </Grid>
              </Box>
              </>
)}
              </Box>
              </Grid>

         

    
                </Grid>
                
            ))}
          </Grid>
        </Grid>
        </Container>
      </Section>
    </>
  );
}

export default BidwellManifesto;
