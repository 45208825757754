import React from "react";
import { Container, Card, CardContent, Divider } from "@material-ui/core";
import SectionHeader from "./SectionHeader";
import Auth from "./Auth";

function AuthSection(props) {
  // Values for each auth type
  const allTypeValues = {
    signin: {
      // Top title
      title: "Sign In",
      // Submit button text
      buttonText: "Sign in",
      // Link text to other auth types
      linkTextSignup: "Create an account",
      linkTextForgotpass: "Forgot Password?",
    },
    signup: {
      title: "Create an account",
      buttonText: "Sign up",
      linkTextSignin: "Sign in",
    },
    forgotpass: {
      title: "Get a new password",
      buttonText: "Reset password",
    },
    changepass: {
      title: "Choose a new password",
      buttonText: "Change password",
    },
  };


  // Ensure we have a valid auth type
  const currentType = allTypeValues[props.type] ? props.type : "signup";

  // Get values for current auth type
  const typeValues = allTypeValues[currentType];

  const afterAuthPath = props.afterAuthPath ? "?next=" + props.afterAuthPath : "";


  
  return (
    <>
    {/* <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
   
    > */}
      {/* <Container maxWidth="xs"  > */}
      <Card elevation={0}>
        <CardContent>
        <SectionHeader
          title={allTypeValues[currentType].title}
          subtitle=""
          size={4}
          textAlign="center"
          style={{marginBottom:'1rem'}}
        />



       
        <Auth

          type={currentType}
          typeValues={typeValues}
          providers={props.providers}
          afterAuthPath={props.afterAuthPath}
          hideFooter={props.hideFooter}
          key={currentType}
        />
</CardContent>
</Card>
      {/* </Container> */}
   
    {/* </Section> */}
    <Divider style={{background:'#ffffff'}} />
    </>
  );
}

export default AuthSection;
