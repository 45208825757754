import React, { useState } from "react";
import Section from "./Section";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Button } from "@material-ui/core";
import EditEventModal from "./EditEventModal";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import StorefrontOutlinedIcon from "@material-ui/icons/StorefrontOutlined";
import { toast } from "react-toastify";
function ApplySection(props) {
  const [creatingSeller, setCreatingSeller] = useState(false);
  const [sellerType, setSellerType] = useState("one off");

  const handleCreatingSeller = (type) => {
    setSellerType(type);
    setCreatingSeller(true);
  };

  const showModalClosedToast = () => {
    toast.success(
      "Thank you for your enquiry. We will get back to you within 1 business day.",
      {}
    );
    setCreatingSeller(false);
  };

  const items = [
    {
      title: "Individual Seller",
      stat: "I would like to sell a few items.",
    },
    {
      title: "Professional",
      stat: "I am a professional seller.",
    },
    // {
    //   title: "Followers",
    //   stat: "456k",
    // },
    // {
    //   title: "Likes",
    //   stat: "789",
    // },
  ];

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <Typography
          variant="h3"
          style={{
            textAlign: "center",
            fontFamily: '"Cormorant Garamond", "serif"',
            marginBottom: "1em",
          }}
        >
          Ready to Start Selling?
        </Typography>
        <Grid container={true} justify="center" spacing={2}>
          <Grid item={true} xs={12} sm={8}>
            <Button
              style={{ height: "4em" }}
              variant="outlined"
              size="large"
              color="primary"
              fullWidth
              startIcon={<PersonOutlineOutlinedIcon />}
              onClick={() => handleCreatingSeller("one off")}
            >
              {"I've got a few items to sell"}
            </Button>
          </Grid>

          <Grid item={true} xs={12} sm={8}>
            <Button
              style={{ height: "4em" }}
              variant="outlined"
              fullWidth
              size="large"
              color="primary"
              onClick={() => handleCreatingSeller("professional")}
              startIcon={<StorefrontOutlinedIcon />}
            >
              {
                "I'm a Professional Estate Sale company, gallery, dealer or shop"
              }
            </Button>
          </Grid>

          {creatingSeller && (
            // <EditEventModal sellerType={sellerType} onDone={() => setCreatingSeller(false)} />
            <EditEventModal
              sellerType={sellerType}
              onSubmit={showModalClosedToast}
              onDone={() => setCreatingSeller(false)}
            />
          )}

          {/* <ToastContainer position="top-right" theme="dark" autoClose={8000} /> */}
        </Grid>
      </Container>
    </Section>
  );
}

export default ApplySection;
