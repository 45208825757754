import React, { useEffect, useState } from "react";
import { useRouter, history } from "./../util/router.js";

import {
  useItem, // necessary?
  useLotByItemLiveRefetch, // necessary?
  useUncachedEvent,
  useLotStatus,
} from "./../util/db.js";
import { useIdentity } from "./../util/identity.js";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./../components/Section";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import {
  Grid,
  Box,
  Fade,
  Switch,
  FormControlLabel,
  CardContent,
  Card,
} from "@material-ui/core";
import ItemPageTopSection from "./../components/ItemPageTopSection";
import NotFoundPage from "./../pages/not-found";
import { useAuth } from "./../util/auth.js";
import ImpressionCounter from "./../components/ImpressionCounter.js";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import ItemStrip from "../components/ItemStrip.js";
import ItemPageBottomSection from "../components/ItemPageBottomSection";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { debugFlag } from "../util/dev.js";
import ShareItemAffiliate from "../components/ShareItemAffiliate.js";
import Meta from "./../components/Meta";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },

  cardContent: {
    padding: theme.spacing(3),
  },

  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
    background: "#f5f5f5",
  },
  switch_track: {
    backgroundColor: "#f50057",
  },
  switch_base: {
    color: "#f50057",
    "&.Mui-disabled": {
      color: "#e886a9",
    },
    "&.Mui-checked": {
      color: "#95cc97",
    },
    "&.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#4CAF50",
    },
  },
  switch_primary: {
    "&.Mui-checked": {
      color: "#4CAF50",
    },
    "&.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#4CAF50",
    },
  },
}));

const defaultPalette = { lightVibrant: "#cccccc", darkMuted: "#000000" };

function ItemPage(props) {
  const classes = useStyles();
  const router = useRouter();
  const inputId = router.query.itemid;

  const id = inputId == null ? inputId : inputId.split("&")[0];

  useEffect(() => {
    console.log("ItemPage id", inputId, id);
  }, [inputId, id]);

  // const seller = {id:id};
  //const { event, mode } = props;
  const auth = useAuth();
  const isDeveloper = auth?.user?.developer?.enabled;

  const { isSeller } = useIdentity();

  const { eventId, eventIndex } = props;
  const [status, setStatus] = useState();

  const [checked, setChecked] = React.useState(false);

  const [isItemBuyerVisible, setIsItemBuyerVisible] = useState();

  const [isItemUserVisible, setIsItemUserVisible] = useState();

  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  const isAuctioneer =
    auth.user && auth.user.role && auth.user.role === "auctioneer";

  const shareUrl = window.location.href;

  const { data: item, status: itemStatus, error: itemError } = useItem(id);
  const {
    data: lot,
    status: lotStatus,
    error: lotError,
  } = useLotByItemLiveRefetch(id);

  //const {isLotStarted} = useLotStatus({...lot, startsAt:event?.startTime});

  if (itemStatus === "success" && item && item.palette === undefined) {
    item.palette = defaultPalette;
  }

  const isOwner =
    auth.user && auth.user.id && auth.user.id === (item && item.owner);

  const isAffiliate =
    auth.user && auth.user.affiliate && auth.user.affiliate.enabled;

  // NEED TO REMEMBER THIS PATTERN
  // YUP - it is helpful.
  const {
    status: eventStatus,
    data: event,
    error: eventError,
  } = useUncachedEvent(item && item.auction);

  //const {status: auctionStatus, data: auction, error:auctionError} = useUncachedAuctionByEvent(item && item.auction);

  // If there is a startsAt time in the lot (and it isn't a declining price auction)...
  // ie increasing bid ... then use that.
  // Otherwise use the eventStartTime.

  const { isLotStarted, isLotEnded } = useLotStatus({ ...lot, ...item });

  const itemLotEventStatus =
    itemStatus === "success" &&
    lotStatus === "success" &&
    eventStatus === "success";

  //  const isItemUserVisible = isOwner || isAuctioneer || (isLotStarted && isItemBuyerVisible);

  useEffect(() => {
    if (isLotStarted === undefined) return;
    if (isLotEnded === undefined) return;
    if (isItemBuyerVisible === undefined) return;

    // Show item page for ended items
    //  const isItemUserVisible = isOwner || isAuctioneer || (isLotStarted && isItemBuyerVisible && !isLotEnded);
    //const v = isOwner || isAuctioneer || (isLotStarted && isItemBuyerVisible);
    const v = isOwner || isAuctioneer || isItemBuyerVisible;

    console.log(
      "ItemPage isLotStarted, isLotEnded, isItemBuyerVisible",
      isLotStarted,
      isLotEnded,
      isItemBuyerVisible
    );

    setIsItemUserVisible(v);
  }, [isLotStarted, isLotEnded, isItemBuyerVisible]);

  useEffect(() => {
    console.log("ItemPage item", item);
  }, [item]);

  useEffect(() => {
    console.log("ItemPage event", event);
  }, [event]);

  //useEffect(()=>{
  //  console.log("ItemPage id lot", id, lot);
  //  }, [id, lot]);

  useEffect(() => {
    if (eventStatus === "success" && itemStatus === "success") {
      setStatus("success");
    } else {
      setStatus("loading");
    }
  }, [eventStatus, itemStatus]);

  useEffect(() => {

    if (status !== "success") {
      return;
    }

    if (isLotStarted === undefined) {
      return;
    }

    if (item === undefined) {
      return;
    }

    var visible = false;

    //  if (!item) {
    //    visible = true; // I think this make a null item appear
    //  }

    if (event && event.published) {
      visible = true;
    }

    if (event && isOwner) {
      visible = true;
    }

    if (event && isAuctioneer) {
      visible = true;
    }

if (lot && lot.accepted === false) {
  visible = false;
}

    console.log(
      "ItemPage visible item.id isLotStarted",
      visible,
      item.id,
      isLotStarted
    );

    setIsItemBuyerVisible(visible);

  }, [event, item, isLotStarted, status]);

  history.listen(() => {
    toast.dismiss();
  });

  if (itemStatus === "success" && eventStatus === "idle") {
    if (isOwner || isAuctioneer) {
      return (
        <>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="30vh"
            width="100%"
            style={{ boxSizing: "border-box" }}
          >
            <Container>
              <Card
                variant="outlined"
                style={{
                  // height: "90%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                <CardContent>
                  <Typography variant="h5">
                    Item not viewable. <br />
                    Select a sale to add the item to.
                  </Typography>
                </CardContent>
              </Card>
            </Container>
          </Box>
        </>
      );
    }
    return (
      <>
        <NotFoundPage />
      </>
    );
  }

  if (item && item.test && item.test === true && !isDeveloper) {
    return (
      <>
        <NotFoundPage />
      </>
    );
  }
  //f (status !== 'success') {return (null);}
/*
if (lot && lot.accepted === false && !(isOwner || isAuctioneer) ) {
    return (
      <>
        <NotFoundPage />
      </>
    );
}
*/

  return (
    <div>
      <Meta title={item?.name} description={item?.description} />

      {debugFlag && (
        <>
          isItemBuyerVisible{" - "}
          {isItemBuyerVisible ? "true" : "false"}
          <br />
          isItemUserVisible {isItemUserVisible ? "true" : "false"}
          isLotStarted{" - "} {isLotStarted ? "Lot Started" : "Lot not started"}{" "}
          {lot?.startsAt}
          <br />
          isLotEnded{" - "} {isLotEnded ? "Lot Ended" : "Lot not ended"}{" "}
          {lot?.endsAt}
          <br />
          item{" - "} {item ? "true" : "false"}
          <br />
          event{" - "} {event ? "true" : "false"}
          <br />
          role{" - "}
          {isOwner || isAuctioneer
            ? "is Owner or Auctioneer"
            : "neither Owner nor Auctioneer"}
          <br />
          event {event && event.published ? "published" : "not published"}
        </>
      )}

      <Section
        size="large"
        style={{ paddingTop: "0px", minHeight: "100vh", paddingBottom: "0px" }}
      >
        {itemLotEventStatus && (
          <>
            {isItemBuyerVisible === undefined && <></>}
            {status === "success" &&
              isItemBuyerVisible === false &&
              isItemBuyerVisible !== undefined && (
                <>
                  <Box
                    py={1}
                    style={{
                      width: "100%",
                      textAlign: "center",
                      backgroundColor: "#000000",
                    }}
                  >
                    <Container>
                      <Typography variant="h6" style={{ color: "white" }}>
                        Sale not currently running. Refresh page or check back
                        later.
                      </Typography>
                    </Container>
                  </Box>
                </>
              )}

            {isAffiliate && (
              <Container>
                <ShareItemAffiliate />
              </Container>
            )}

            <ImpressionCounter
              lot={{ id: item && item.id }}
              partialVisibility={true}
            >
              {/* {(isOwner || isAuctioneer) && (
                <>
                  <Box pb={1} style={{ backgroundColor: "#000000" }}>
                    <Container style={{ textAlign: "right" }}>
                      <FormControlLabel
                        control={
                          <Switch
                            classes={{
                              track: classes.switch_track,
                              switchBase: classes.switch_base,
                              colorPrimary: classes.switch_primary,
                            }}
                            checked={checked}
                            onChange={handleChange}
                          />
                        }
                        label={
                          <Typography
                            variant="h6"
                            style={{
                              fontFamily: '"Cormorant Garamond", "serif"',
                              color: "#ffffff",
                            }}
                          >
                            Manage Listing
                          </Typography>
                        }
                      />
                    </Container>
                  </Box>

                  <Fade in={checked} unmountOnExit>
                    <Box
                      py={3}
                      style={{
                        background: "#f5f5f5",
                        borderBottom: "1px dashed black",
                      }}
                    >
                      <Container maxWidth="md">
                        <Grid container>
                          <Grid item xs={12}>
                            <Box pb={1}>
                              {event &&
                                event.published &&
                                event.published === false && (
                                  <>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        flexWrap: "wrap",
                                      }}
                                    >
                                      <Typography
                                        variant="h6"
                                        style={{ textAlign: "left" }}
                                      >
                                        &nbsp; Item not visible to buyers.
                                        Collection under review by LadyBidwell.
                                      </Typography>
                                      <VisibilityOffIcon />
                                    </div>
                                  </>
                                )}

                              {event && event.published && (
                                <>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      flexWrap: "wrap",
                                    }}
                                  >
                                    <VisibilityIcon />
                                    <Typography
                                      variant="h6"
                                      style={{ textAlign: "left" }}
                                    >
                                      &nbsp; Item visible to buyers.
                                    </Typography>
                                  </div>
                                </>
                              )}
                            </Box>
                          </Grid>

                          <Grid item xs={12}>
                            <ItemStrip item={item} />
                          </Grid>
                        </Grid>
                      </Container>
                    </Box>
                  </Fade>
                </>
              )} */}

              {item && event && isItemUserVisible && (
                <>
                  <ItemPageTopSection
                    bgColor="white"
                    item={item}
                    event={event}
                    mode={"view"}
                  />

                  <ItemPageBottomSection
                    bgColor="light"
                    item={item}
                    event={event}
                  />
                </>
              )}
            </ImpressionCounter>
          </>
        )}
      </Section>

      {/* <ToastContainer />  */}
    </div>
  );
}

export default ItemPage;
