import React, { useState, useEffect } from "react";
import { useAuth } from "./../util/auth.js";

import { getPaymentMethodStatus } from "./../util/stripe.js";

import { makeStyles } from "@material-ui/core/styles";



import { isPastTime } from "../util/time";
import ItemActionRouter from "./ItemActionRouter.js";

import { debugFlag } from "../util/dev.js";

const defaultPalette = { lightVibrant: "#cccccc", darkMuted: "#000000" };

const useStyles = makeStyles((theme) => ({
  root: {
    // minWidth: 275,
    // boxShadow: "1px 0px 4px rgb(0 2 4 / 2%), 0 7px 20px rgb(1 1 1 / 3%)",
    padding: "15px",
    borderColor: "transparent",
    // border: '1px solid',
    "&:hover": {
      borderColor: "#0000001f",
      border: "1px solid",
    },
  },
  "&:hover": {
    borderColor: "red",
  },

  estimate: {
    justifyContent: "space-between",
    // paddingLeft: '16px',
    // paddingRight: '16px',

    display: "flex",
    alignItems: "center",
  },

  media: {
    height: "200px",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },

  paperItems: {
    minHeight: "300px",
  },
  // featured: {
  //   backgroundColor:
  //     theme.palette.type === "dark" ? theme.palette.action.selected : "#fdf8c2",
  // },
  starFeatured: {
    color: theme.palette.warning.main,
  },
  name: {
    overflow: "hidden",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
  },
  StipTitle: {
    textDecoration: "none",
    color: "black",
    fontWeight: 600,
  },
}));

function ItemAction(props) {
  const { likes, event, tab, item, lot, mode } = props;

  //const debugFlag = process.env.REACT_APP_DEBUG === "on" ? true : false;
  //const debugFlag = true;
  const classes = useStyles();

  const [canBid, setCanBid] = useState();

  const [change, setChange] = useState();
  const auth = useAuth();

  useEffect(() => {
    console.log("ItemAction start", item && item.id);
  }, []);

  useEffect(() => {
    console.log("ItemAction event", event);
  }, [event]);

  if (item && item.palette === undefined) {
    item.palette = defaultPalette;
  }

  const isOwner = auth.user && auth.user.id === (item && item.owner);

  const matchesTab = applyTabs(tab);

  function applyTabs(tab) {
    var matchesTab = true;

    if (tab === "all") {
      return true;
    }

    if (tab === "active") {
      if (!isOwner && isPastTime(lot.endsAt)) {
        matchesTab = false;
      }
      if (isOwner && lot && lot.paid === true) {
        matchesTab = false;
      }
    }

    if (tab === "past") {
      //matchesTab = false;
      if (lot === undefined) {
        matchesTab = false;
        return false;
      }
      if (!isOwner && !isPastTime(lot.endsAt)) {
        matchesTab = false;
      }
      if (isOwner && !isPastTime(lot.endsAt)) {
        matchesTab = false;
      }
    }
    return matchesTab;
  }

  useEffect(() => {
    //if (event == null) {
    //  return;
   // }
    console.log("ItemAction event", event)
    //if (event.type === "declining_price") {
      setCanBid(true);
    //  return;
    //}

    // Other type of event is "increasing_bid"?
    // So run the check.

    if (canBid === undefined) {
    // Call the stripe function
    const c = getPaymentMethodStatus();
    setCanBid(c);
    }
  }, []);

  if (canBid == null) {
    return null;
  }
  if (matchesTab === false) {
    return (
      <>
        {debugFlag && <>"IGNORED" {item.name}</>}
        <br />
      </>
    );
  }

  function handleStatus(e) {
    setChange(!change);
  }

  //return(
  //  <div className="fade" style={{width:'200px', height:'200px', backgroundColor:'#fccc01'}} />);

  return (
    <>

      <ItemActionRouter
        change={change}
        item={item}
        event={event}
        lot={lot}
        mode={mode}
        onStatus={(e) => handleStatus(e)}
      />
      {/*
      <MessagesItem
        //  key={"item_action_router_auctioneer_review_item_messages_item_"+item.id}

        key={item.id}
        //itemId={item.id}
        item={item}
        //choice={choice}
        user={{ id: item.owner }}
        collapsed={true}
        status={true}
        //onStatus={(e)=>handleStatus(e)}
      /> 
  */}
    </>
  );
}

export default ItemAction;
