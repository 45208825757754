import React, { useState, useEffect } from "react";
import { useAuth, requireAuth } from "./../util/auth.js";
import { updateItem, createMessage, useItemByUser, updateLot } from "./../util/db.js";
import { Button, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PublishIcon from "@material-ui/icons/Publish";
import { isPastTime } from "./../util/time.js";
import { Link } from "react-router-dom";
import { Alert } from "@material-ui/lab";


import { getFunctions, httpsCallable } from "firebase/functions";

const functions = getFunctions();

const processWithdrawItem = httpsCallable(functions,"withdrawItem");


const useStyles = makeStyles((theme) => ({
  deleteButton: {
    backgroundColor: "#000000",
    color: "#ffffff",
  },
}));

export function ConsignItem(props) {
  const classes = useStyles();
  const auth = useAuth();

  const { item, event } = props;

  const [userMessage, setUserMessage] = useState();

  const isOwner = auth.user.id && auth.user.id === item.owner;
  //const isOwner = true;
  const isAuctioneer = auth.user.role && auth.user.role === "auctioneer";

  const [canConsign, setCanConsign] = useState(false);

  ////MIKE CODE
  var {
    data: itemUser,
    status: itemUserStatus,
    error: itemUserError,
  } = useItemByUser(item);

  //const [confirmOpen, setConfirmOpen] = useState(false);
  useEffect(() => {
    console.log("ConsignItem canConsign", canConsign);
  }, [canConsign]);

  const isAccepted = item && item.accepted;

  ///MIKE CODE
  // const itemReady =
  //   item &&
  //   event?.name &&
  //   itemUser &&
  //   item.description &&
  //   item.sellerStartingPrice &&
  //   item.condition &&
  //   itemUser.reservePrice && true;


    const itemReady =
    item && 
    itemUser &&
    item.description &&
    item.condition &&
    (( event?.name && item.sellerStartingPrice && itemUser.reservePrice) || (event?.name && item.listingType === "marketplace_price" && item.sellerStartingPrice) ) &&
    true;

  //console.log("item detail", item);
useEffect(()=>{
console.log("ConsignItem item itemUser", item, itemUser)

}, [item, itemUser])
  const wasConsigned =
    item && item.wasConsigned === undefined ? false : item.wasConsigned;
  const isConsigned =
    item && item.consigned === undefined ? false : item.consigned;

  const isWithdrawn =
    item && item.withdrawn === undefined ? false : item.withdrawn;

  const canChargeSeller = true;

  // Determine if it can be consigned.

  useEffect(() => {
    console.log("ConsignItem event", event);

    if (!canChargeSeller) {
      setUserMessage(
        <>
          Billing{" "}
          <Link to="/settings/billing" style={{ textDecoration: "underline" }}>
            account
          </Link>{" "}
          requires further information.
        </>
      );
    }
    var c =
      (!isConsigned || (isConsigned && item.accepted === false)) &&
      isOwner &&
      canChargeSeller;

    console.log("ConsignItem item", item);
    if (
      item.auction === undefined ||
      item.auction === null ||
      item.auction === ""
    ) {
      c = false;
      setUserMessage("Item does not have a chosen collection.");
    }

    if (event && isPastTime(event.startTime) && item.auction === event.id && (['declining_price','increasing_bid']).includes(item.listingType) ) {


      c = false;
      setUserMessage("Sale has closed. Edit item to select different sale.");


    }

    //// MIKE CODE -- If the item has all details set it as ready to be consigned
    console.log("ConsignItem item itemReady", item, itemReady)
    if (item && !itemReady) {
      c = false;
      setUserMessage("Item needs further details.");
    }
    //console.log("ConsignItem item itemReady", item, itemReady)
    if (item && itemReady) {
      //c = true;
      //setUserMessage("Item needs further details.");
      setUserMessage("");
    }


     // MIKE CODE -- If there is no error, clear the error state
     if (c === true) {
      setUserMessage("");
    }

    setCanConsign(c);
  }, [event, item, isConsigned, itemReady]);



  
  useEffect(() => {
    console.log("Consignitem start");
  }, []);
  // Consigned the item and allow it to be withdrawn
  // if the item has not yet been accepted.

  const consignItem = (item) => {
    if (isOwner && item.accepted !== true) {
      //updateItem(item.id, { consigned: !item.consigned });

/*
      processWithdrawItem(item).then((result)=>{

      })
      .catch((error)=>{})
*/
      updateItem(item.id, {
        consigned: true,
        wasConsigned: true,
        accepted: null,
        withdrawn: false,
      });

      var message = "Submitted to LadyB.";

      // createMessage
      // Creates a message in the users collection under messages with a unique reference
      // return firestore.collection("users").doc(datagram.from).collection("messages").doc().set({

      createMessage({
        subject: message,
        item: item.id,
        from: auth.user.id,
        to: "auctioneer",
      });
    } else {
      if (!isOwner) {
        alert("You must be the Owner of the item to use this feature");
      } else {
        alert("Contact the Auctioneer. This item has been accepted.");
      }
    }
  };

  if (!isOwner) {
    return null;
  }

  // accepted
  // consigned

  return (
    <>
      {isOwner && (
        <>
          <Button
            startIcon={<PublishIcon />}
            fullWidth
            // className={classes.deleteButton}
            color="primary"
            variant="contained"
            disableElevation
            disabled={!canConsign}
            checked={item.consigned}
            onClick={() => {
              consignItem(item);
            }}
            name="checkedA"
            inputProps={{ "aria-label": "secondary checkbox" }}
          >
            {!wasConsigned ? "Submit" : "Resubmit"}
          </Button>
        </>
      )}
   
   

      {userMessage && (
        <Box py={1}>
          <Alert severity="warning">{userMessage}</Alert>
        </Box>
      )}
    </>
  );
}

export default requireAuth(ConsignItem);
