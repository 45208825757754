import React, { useState, useEffect, useRef } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { Container } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import ItemCards from "./ItemCards";
import { IconButton } from "@material-ui/core";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import useMediaQuery from '@material-ui/core/useMediaQuery';


const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        position:'relative',
        flexWrap: "nowrap",
        alignItems: "center",
        // justifyContent: "space-around",
        overflow: "hidden",
      },
      container: {
        display: "flex",
        overflowX: "scroll",
        overflowY:'hidden',
        scrollbarWidth: "thin",
        scrollbarColor: theme.palette.primary.main + " #f9f9f9",
        "&::-webkit-scrollbar": {
          height: theme.spacing(.3),
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "#e0e0e0",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: '#797979',
        },
      },
      horizontal: {
        display: "flex",
        flexWrap: "nowrap",
        overflowX: "auto",
        alignItems:'flex-end',
        scrollbarWidth: "thin",
        scrollbarColor: theme.palette.primary.main + " #f9f9f9",
        "&::-webkit-scrollbar": {
          height: theme.spacing(1),
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "#f9f9f9",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: theme.palette.primary.main,
        },
        paddingBottom: theme.spacing(2), // add bottom padding
      },
      imageList: {
        flexWrap: "nowrap",
        transform: "translateZ(0)",
      },
      ImageListItem: {
        height: "100%",
      },
      listItem: {
        height: "100%",
      },
      demo: {
        alignItems: "flex-end",
      },
      paperItems: {
        minHeight: "300px",
      },
      featured: {
        backgroundColor:
          theme.palette.type === "dark" ? theme.palette.action.selected : "#fdf8c2",
      },
      starFeatured: {
        color: theme.palette.warning.main,
      },
      scrollButton: {
       position: "absolute",
    
        // top: "calc(50% - 10%)",  // subtract half the scrollbar height
        // transform: "translateY(-50%)",
        zIndex: 1,
        // backgroundColor: theme.palette.primary.main,
        // color: theme.palette.common.white,
        backgroundColor: '#e7e7e7',
        "&:hover": {
            backgroundColor: theme.palette.secondary.light, // change background color on hover
          },
        // color: theme.palette.primary.main,
        borderRadius: "50%",
        border: "none",
        padding: theme.spacing(2),
        cursor: "pointer",
      },
      leftButton: {
        left: 0,
      },
      rightButton: {
        right: 0,
      },
    }));

function ItemsGridHorizontal(props) {
  const { items, flavour } = props;
  console.log("itemsgridhorizonal props items", items);
  const enableScroll = true;
  const [count, setCount] = useState();
  const classes = useStyles();
  const ref = useRef(null);
  const loadingContext = false;
  const [isLoading, setIsLoading] = useState(true); // new state variable for loading
  const matches = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const [scrollPosition, setScrollPosition] = useState(0);

  const checkScrollEnds = () => {
    const container = ref.current;
    setScrollPosition(container.scrollLeft);
  };

  // Add the new state
const [isMouseOver, setIsMouseOver] = useState(false);

// Event handlers for mouse enter and leave
const handleMouseEnter = () => {
  setIsMouseOver(true);
};

const handleMouseLeave = () => {
  setIsMouseOver(false);
};

  useEffect(() => {
    const container = ref.current;
    if (container) {
      container.addEventListener('scroll', checkScrollEnds);
  
      // Cleanup function to remove the event listener
      return () => {
        container.removeEventListener('scroll', checkScrollEnds);
      };
    }
  }, []);




  useEffect(() => {
    if (items == null) {
      return;
    }
    const c = calculateVisibleItems(items);
    setCount(c);
    setIsLoading(false); // set isLoading to false when data is ready
  }, [items]);

  function isItemVisible(item) {
    return item.name !== undefined && item.accepted === true;
  }

  const calculateVisibleItems = (items) => {
    let count = 0;
    items?.forEach((item) => {
      if (isItemVisible(item)) {
        count += 1;
      }
    });
    return count;
  };

  const scroll = (direction) => {
    const container = ref.current;
    const containerScrollPosition = ref.current.scrollLeft;
    
    switch(direction) {
      case 'left':
        container.scrollTo({ top: 0, left: containerScrollPosition - container.clientWidth, behavior: 'smooth' });
        break;
      case 'right':
        container.scrollTo({ top: 0, left: containerScrollPosition + container.clientWidth, behavior: 'smooth' });
        break;
    }
  };


  function handleCardCount(c) {
    if (props.onCardCount) {
      props.onCardCount(c);
    }
  }

  return (
    <>





    <div className={classes.root}  onMouseEnter={handleMouseEnter} 
  onMouseLeave={handleMouseLeave}>
    {matches && scrollPosition > 0 && isMouseOver && (
    <IconButton  variant="contained"
      className={`${classes.scrollButton} ${classes.leftButton}`}
      onClick={() => scroll('left')}
    >
     <ArrowBackIosIcon />
    </IconButton>
  )}
            <div className={classes.container} style={{width:"100%"}}  ref={ref}>
    <Grid container spacing={1} >
      <Grid item>
        <div key={event?.id}>
          <Skeleton
            key={"itemcards_skeleton_" + event?.id}
            variant="rect"
            height={800}
            style={{
              display: loadingContext ? "block" : "none",
              backgroundColor: "gold",
              transitionDelay: "0ms",
            }}
          />
          <div
            style={{
              display: loadingContext ? "none" : "block",
              transitionDelay: "0ms",
              position: "relative", // to make sure the scroll buttons are positioned correctly
            }}
          >
            {items && (
              <div className={classes.horizontal} ref={ref}>
          
                <ItemCards
                  flavour="horizontal_grid"
                  key={"itemcards_" + event?.id}
                  items={items}
                  gridType="non_masonry"
                  onCardCount={(c) => handleCardCount(c)}
                />
              </div>
            )}



        
          </div>
        </div>
      </Grid>
    </Grid>
    </div>
    {matches && ref.current && scrollPosition < ref.current.scrollWidth - ref.current.clientWidth && isMouseOver && (
    <IconButton
      className={`${classes.scrollButton} ${classes.rightButton}`}
      onClick={() => scroll('right')}
    >
      <ArrowForwardIosIcon />
    </IconButton>
  )}
  </div>


  </>
  );
}

export default ItemsGridHorizontal;
