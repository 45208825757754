import { useState, useEffect } from "react";
import moment from "moment";

import { getFunctions, httpsCallable } from "firebase/functions";

// Import the DateTime class from Luxon
import { DateTime } from "luxon";

const functions = getFunctions();

export const getFirebaseTime = httpsCallable(functions, "getFirebaseTime");

export const getDropTime = httpsCallable(functions, "getDropTime");

function makeDropDateTime(result) {

      const currentDateTime = new Date();
/*
      console.debug("time getDropTime result", result);
*/
      const dropTime = result.dropTime.clockTime;
      const str = zuluDropTime(result.dropTime);

      /*
  if (d < currentDateTime) {
  d.setDate(d.getDate() + 1);
  }
  */

      const a = getAge(str);

//getAge(dropDateTime.zuluDropTime

const d = new Date(Date.parse(str));

const humanTimeZoneClockTime = d.toLocaleString([],{hour:'2-digit', minute:'2-digit',hour12:true, timeZoneName:"short"}).replaceAll(".","").replace(" pm", "pm").replace(" am", "am");


return {
        dropTime: result.dropTime,
        zuluDropTime: str,
        humanTimeZoneClockTime: humanTimeZoneClockTime,
duration:a,
      };

}


// Import the DateTime class from Luxon
//import { DateTime } from 'luxon';

  function getAge(zuluTime) {
//    console.log("time getAge dropDateTime", dropDateTime);

    if (zuluTime == null) {
      return true;
    }

  const zuluTimeEpoch = Date.parse(zuluTime);


    // Pass zulu timestamps in.
    var startDatetime = new Date(Date.now());
    var diff = startDatetime - zuluTimeEpoch;

    if (diff > 0) {
      diff = 24 * 60 * 60 * 1000 - diff;
    }

    const togo = secondsToReadableString(diff / 1000, true);
/*
    console.debug(
      "PriceDropCountdown togo",
      togo,
      diff,
      startDatetime,
      zuluTime
    );
*/
    return togo;
  }


// TODO:
// BUILD A HOOK TO GET THE DROP TIME (FIRESTORE OR FUNCTIONS CALL)
// AND PROVIDE IT WITHIN THE COMPONENT

export function useDropTime() {


// Apply the default here.
// And then slow check and correct the droptime if the server says
// it is something else.

// Not tested.

// This is what the client can assume. 
// It needs to be updated here (and only here) if it changes for LadyBidwell.


const defaultData = {
  "dropTime": {
    "clockTime": "7:30",
    "hour": 7,
    "minute": 30,
    "timeZone": "America/New_York"
  }
};



  const [dropDateTime, setDropDateTime] = useState(makeDropDateTime(defaultData));
  const [duration, setDuration] = useState();
  const [status, setStatus] = useState("loading");

  const [error, setError] = useState(null);



  useEffect(() => {

if (dropDateTime == null) {return;}

    setDuration(getAge(dropDateTime.zuluDropTime));
    const intervalId = setInterval(() => {
      const i = getAge(dropDateTime.zuluDropTime);
      setDuration(i);

      console.log("time useDropTime tick i", i, dropDateTime);
    }, 15000);

    // our cleanup will run when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, [dropDateTime]);

  useEffect(() => {
    setDropDateTime({ ...dropDateTime, duration: duration });
    console.log("time duration", duration);
  }, [duration]);




  useEffect(() => {
    console.log("time useEffect start");

    const t = getDropTime();
    t.then((result) => {
/*
      const currentDateTime = new Date();

      console.log("time getDropTime result", result);

      const dropTime = result.data.data.dropTime.clockTime;
      const str = zuluDropTime(result.data.data.dropTime);

      const a = getAge(str);


const d = new Date(Date.parse(str));

const humanTimeZoneClockTime = d.toLocaleString([],{hour:'2-digit', minute:'2-digit',hour12:true, timeZoneName:"short"}).replaceAll(".","").replace(" pm", "pm").replace(" am", "am");
*/
console.log("time getDropTime promise result", result);
const n = makeDropDateTime(result.data.data);
setDropDateTime(n);

/*
      setDropDateTime({
        ...dropDateTime,
        dropTime: result.data.data.dropTime,
        zuluDropTime: str,
        humanTimeZoneClockTime: humanTimeZoneClockTime
      });
*/
      setStatus("success");
    }).catch((error) => {
      console.error(error);
      setError(error);
      setStatus("error");
    });
  }, []);


useEffect(()=>{

console.log("time dropDateTime", dropDateTime);

},[dropDateTime]);

  return { data: dropDateTime, status: status, error: error };
}

export function zuluDropTime(dropTime) {
  // Create a new DateTime object with the current time
  let dt = DateTime.local();

  // Set the timezone to America/New_York and set the hour to 10 AM
  //dt = dt.setZone('America/New_York').set({ hour: 10, minute: 0 });

  const parts = dropTime.clockTime.split(":");

  dt = dt
    .setZone(dropTime.timeZone)
    .set({ hour: parts[0], minute: parts[1], second: 0, millisecond: 0 });

  //return dt.toISOString();
  // Format the date and time as a string
  //let formattedDate = dt.toLocaleString(DateTime.DATETIME_FULL);
  //const d = new Date(formattedDate);
  //console.log(formattedDate); // Output: Thursday, March 16, 2023 at 10:00:00 AM Eastern Standard Time

  const formattedDate = dt.toISO();
  return zuluTime(formattedDate);
}

function getAgeMinutes(bid) {
  const milliseconds = age(bid);
  const minutes = Math.floor(milliseconds / (60 * 1000));

  return minutes;
}

export function compareFirebaseTimes(a, b) {
  const diff = new Date(firebaseTime(a)) - new Date(firebaseTime(b));
  return diff;
}

export function firebaseTime(firebaseObject) {
  if (firebaseObject == null) {
    return null;
  }

  if (firebaseObject.seconds == null) {
    return true;
  }
  var epochtime = firebaseObject.seconds;
  //console.debug("time firebaseTime epochtime", epochtime);
  if (epochtime == null) {
    return true;
  } // error signal

  var d = new Date(epochtime * 1000);
  //console.debug("time firebaseTime d", d)
  return d.toISOString();
}

const dayOrdinal = function (d) {
  if (d > 3 && d < 21) return d + "th";
  switch (d % 10) {
    case 1:
      return d + "st";
    case 2:
      return d + "nd";
    case 3:
      return d + "rd";
    default:
      return d + "th";
  }
};

export function headlineEventPeriod(event) {
  const endAtEpoch = Date.parse(event.endTime);
  var endAt = new Date(endAtEpoch);

  const startAtEpoch = Date.parse(event.startTime);
  var startAt = new Date(startAtEpoch);
  const startDay = startAt.toLocaleDateString(undefined, { day: "numeric" });
  const startMonth = startAt.toLocaleDateString(undefined, { month: "long" });
  const startYear = startAt.toLocaleDateString(undefined, { year: "numeric" });

  const endDay = endAt.toLocaleDateString(undefined, { day: "numeric" });
  const endMonth = endAt.toLocaleDateString(undefined, { month: "long" });
  const endYear = endAt.toLocaleDateString(undefined, { year: "numeric" });

  var day = dayOrdinal(startDay);
  if (startDay === endDay) {
    day = dayOrdinal(startDay);
  } else {
    day = dayOrdinal(startDay) + "-" + dayOrdinal(endDay);
  }

  var time = dayOrdinal(day) + " " + startMonth;
  if (startMonth === endMonth) {
    time = day + " " + startMonth;
  } else {
    time =
      dayOrdinal(startDay) +
      " " +
      startMonth +
      " - " +
      dayOrdinal(endDay) +
      " " +
      endMonth;
  }

  if (startYear === endYear) {
    time = time + " " + startYear;
  } else {
    time =
      dayOrdinal(startDay) +
      " " +
      startMonth +
      " " +
      startYear +
      " - " +
      dayOrdinal(endDay) +
      " " +
      endMonth +
      " " +
      endYear;
  }

  return time;
}

// at firebase object
export function age(at) {
  if (at === null) {
    return null;
  }

  const age = Date.now() - at.seconds * 1000;
  return age;
  //return (Date.now() - at.seconds *1000)
}

// at firebase object
export function ageMinutes(at) {
  const milliseconds = age(at);
  const minutes = Math.floor(milliseconds / (60 * 1000));

  return minutes;
}

export function humanAge(at) {
  var milliseconds = age(at);
  var seconds = Math.floor(milliseconds / 1000);

  if (seconds > 60 * 60) {
    return humanTime(firebaseTime(at));
  }

  if (seconds > 2 * 60) {
    return Math.floor(seconds / 60) + " minutes ago.";
  }

  if (seconds > 60) {
    return Math.floor(seconds / 60) + " minute ago.";
  }
  if (seconds > 1) {
    return Math.floor(seconds) + " seconds ago.";
  }

  return Math.floor(seconds) + " second ago.";
}

// at firebase object
export function isoAge(at) {
  if (at === null) {
    return null;
  }

  var d = new Date(at);
  var milliseconds = Math.floor(d.getTime());
  const age = Date.now() - milliseconds;
  return age;
  //return (Date.now() - at.seconds *1000)
}

export function humanIsoAge(isoAt) {
  var milliseconds = isoAge(isoAt);
  var seconds = Math.floor(milliseconds / 1000);

  if (seconds > 60 * 60) {
    return humanIsoTime(isoAt);
  }

  if (seconds > 2 * 60) {
    return Math.floor(seconds / 60) + " minutes ago.";
  }

  if (seconds > 60) {
    return Math.floor(seconds / 60) + " minute ago.";
  }

  if (seconds > 1) {
    return Math.floor(seconds) + " seconds ago.";
  }

  return Math.floor(seconds) + " second ago.";
}

export function muiZuluTime(at) {
  var epochtime = Date.parse(at);

  var d = new Date(epochtime);

  //return d.toISOString().slice(0,-8);

  var yourDate = d.toLocaleString();

  var options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
    hourCycle: "h23",
  };

  // The below fails. Because the locale string is client? determined.
  //const f = d.toLocaleDateString("en-CA", options);
  const f = d.toLocaleDateString("sv-SE", options);

  // Something funky happened overnight with the en-CA locale.
  // Switched to sv-SE and it works.
  // This native date picker should be changed.
  //  https://github.com/nodejs/node/issues/45945

  console.log("time muiZuluTime f", f);
  // but we need the locale string to have the browser client do its date awareness.

  //  const iso = d.toISOString();
  // 2022-08-21T17:51:00.000Z
  // regex magic
  //const filteredISO = iso.replace(/(:*)\.[^:]+$/,'');

  const g = f.replace(", ", "T");
  //const d = new Date(f);
  //const g = d.getYear() +'-' + d.getDay();
  // MUI only recognizes this filtered ISO format.
  //  2022-08-21T13:51

  //return  "2022-08-21T13:51";

  //return  "2022-08-21T13:51";

  console.log("time muiTime g", g);
  return g;

  /*
  yourDate = yourDate.replace(" ", "T");
  yourDate = yourDate.replace(/:\d{2}\s/, " ");
  yourDate = yourDate.replace(/\//g, "-");
  yourDate = yourDate.replace("PM", "");
  yourDate = yourDate.replace("AM", "");
*/

  // MUI requires date string to be this format.
  //  return "2022-06-22T10:00";
}

export function zuluTime(at) {
  if (!at) {
    return null;
  }

  var epochtime = Date.parse(at);

  var d = new Date(epochtime);
  return d.toISOString();
}

export function clientZuluTimestamp() {
  //var epochtime = Date.parse(at);
  //var d = Date.now();
  //var d = new Date(epochtime);
  return new Date().toISOString();
}

export function humanTime(at) {
  if (at === undefined) {
    return "Not available";
  }
  var epochtime = Date.parse(at);

  var d = new Date(epochtime);
  //return d.toLocaleString([], {timeStyle: 'short'});
  return d.toLocaleString().replace(/:\d{2}\s/, " ");
}

export function humanIsoTime(isoAt) {
  if (isoAt === undefined) {
    return "Not available";
  }
  //var epochtime = new Date(at);

  var d = new Date(isoAt);
  //return d.toLocaleString([], {timeStyle: 'short'});
  return d.toLocaleString().replace(/:\d{2}\s/, " ");
}

export function humanEpochTime(epochtime) {
  //  if (at === undefined) {return "Not available"}
  //  var epochtime = Date.parse(at);

  var d = new Date(epochtime * 1000);
  //return d.toLocaleString([], {timeStyle: 'short'});
  return d.toLocaleString().replace(/:\d{2}\s/, " ");
}

export function weekdayTime(at) {
  if (at === undefined) {
    return "Not available";
  }
  var epochtime = Date.parse(at);

  var d = new Date(epochtime);
  //return d.toLocaleString([], {timeStyle: 'short'});
  //return d.toLocaleString().replace(/:\d{2}\s/, " ");
  return d.toLocaleString([], { weekday: "long" });
}

export function monthdayTime(at) {
  if (at === undefined) {
    return "Not available";
  }
  var epochtime = Date.parse(at);

  var d = new Date(epochtime);
  //return d.toLocaleString([], {timeStyle: 'short'});
  //return d.toLocaleString().replace(/:\d{2}\s/, " ");
  return (
    d.toLocaleString([], { month: "numeric" }) +
    "/" +
    d.toLocaleString([], { day: "numeric" })
  );
}

export function yearTime(at) {
  if (at === undefined) {
    return "Not available";
  }
  var epochtime = Date.parse(at);

  var d = new Date(epochtime);
  //return d.toLocaleString([], {timeStyle: 'short'});
  //return d.toLocaleString().replace(/:\d{2}\s/, " ");
  return d.toLocaleString([], { year: "numeric" });
}

// Alternative formats
/*
d.toLocaleString([], {timeStyle: 'short'});
*/
/*
d.toLocaleString().replace(/:\d{2}\s/,' ');
*/
export function addMinute(at) {
  var tempLotEndTime = at;

  if (tempLotEndTime === undefined) {
    return true;
  }

  var epochtime = Date.parse(tempLotEndTime);

  // Extend lot end by 1 minute.
  epochtime = epochtime + 60 * 1000;

  var d = new Date(epochtime);
  return d.toISOString();
}

export function isPastTime(at) {
  var epochTime = Date.parse(at);
  var currentTime = Date.now();

  if (currentTime - epochTime > 0) {
    // The time given is in the past
    return true;
  }
  return false;
}

export function secondsDifference(timeA, timeB) {
  var epochTimeA = Date.parse(timeA);
  var epochTimeB = Date.parse(timeB);

  var millisecondsDifference = epochTimeA - epochTimeB;
  var secondsDifference = (millisecondsDifference / 1000).toFixed(0);
  return secondsDifference;
}

export function millisecondsDifference(timeA, timeB) {
  var epochTimeA = Date.parse(timeA);
  var epochTimeB = Date.parse(timeB);

  var millisecondsDifference = epochTimeA - epochTimeB;
  //var secondsDifference = (millisecondsDifference / 1000).toFixed(0);
  return millisecondsDifference;
}

// Consider: refactor to remove reliance on moment
export function getFirebaseCurrentTime(updateFlag) {
  if (updateFlag === true) {
    getFirebaseTime()
      .then((result) => {
        //var serverTime = Date.now();
        var serverTime = result.data;
        var offset = new Date(serverTime).getTime() - Date.now();
        moment.now = function () {
          return offset + Date.now();
        };
        var d = new Date(moment.now());
        return d.toISOString();
      })
      .catch((error) => {
        console.error("time getFirebaseCurrentTime error", error);
        var d = new Date(moment.now());
        return d.toISOString();
      });
  }

  //eturn moment.now;
  var d = new Date(moment.now());
  return d.toISOString();
}

export function countdownTime(at) {
  const currentTime = getFirebaseCurrentTime();
  var t = secondsDifference(at, currentTime);

  if (t >= 24 * 60 * 60) {
    return humanTime(at);
  }

  return secondsToReadableString(t);
}

export function timetogoTime(at) {
  const currentTime = getFirebaseCurrentTime();
  var t = secondsDifference(at, currentTime);

  //if (t>=(24*60*60)) {return humanTime(at);}

  return secondsToReadableString(t);
}

export function secondsToReadableString(seconds, spice = null) {
  seconds = seconds || 0;
  seconds = Number(seconds);
  seconds = Math.abs(seconds);

  const d = Math.floor(seconds / (3600 * 24));
  const h = Math.floor((seconds % (3600 * 24)) / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.floor(seconds % 60);
  const parts = [];

  if (d > 0) {
    parts.push(d + " day" + (d > 1 ? "s" : ""));
  }

  if (h > 0) {
    parts.push(h + " hour" + (h > 1 ? "s" : ""));
  }

  if (h === 0 && d > 0) {
    parts.push(h + " hour" + (h > 1 ? "s" : ""));
  }

  if (m > 0) {
    parts.push(m + " minute" + (m > 1 ? "s" : ""));
  }

  if (m === 0 && (h > 0 || d > 0)) {
    parts.push(m + " minute" + (m > 1 ? "s" : ""));
  }
  if ((spice = null)) {
    if (s > 0 && d === 0) {
      parts.push(s + " second" + (s > 1 ? "s" : ""));
    }

    if (s === 0 && d === 0 && (m > 0 || h > 0 || d > 0)) {
      parts.push(s + " second" + (s > 1 ? "s" : ""));
    }
  }

  return parts.join(", ");
}

export function addDays(date, days) {
  var result = new Date(date);
  result.setDate(date.getDate() + days);

  return result;
}

export function indexedTime(at, lotIndex, lotClosingTimeIncrement) {
  var epochtime = Date.parse(at);
  epochtime = epochtime + lotIndex * lotClosingTimeIncrement * 1000;

  var d = new Date(epochtime);
  return d.toISOString();
}
