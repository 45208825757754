import React, { useEffect } from "react";
import { useItem } from "./../util/db.js";
import ItemCard from "./ItemCard.js";


function ItemIdCard(props) {
  const { itemId, tab, likes, mode, flavour, spice } = props;

  const {data:item, status, error} = useItem(itemId);

  useEffect(()=>{
console.log("ItemIdCard itemId", itemId);
    console.log("ItemIdCard item", item);
    console.log("ItemIdCard status", status)
    console.log("ItemIdCard error",error)



  }, [item, status, error])


//if (itemStatus !== 'success') {return (<>Skeleton</>);}
if (status !== 'success') {return (<>Skeleton</>)}

  return (
    <>
    {/* {itemId} */}
   <ItemCard spice={spice} likes={likes} flavour={flavour} item={item} tab={tab} mode={mode} /> 

    </>
  );
}

export default ItemIdCard;
