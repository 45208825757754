import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { Typography } from '@material-ui/core';


const ConfirmDialog = (props) => {
  const { title, children, open, setOpen, onConfirm } = props;
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="confirm-dialog"
     
    >
    
     
      <DialogContent> <Typography variant='h5' gutterBottom >{title}</Typography><Typography variant='subtitle1'>{children}</Typography></DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => setOpen(false)}
          color="primary"
        >
          No
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            setOpen(false);
            onConfirm();
          }}
      
        >
          Yes
        </Button>
      </DialogActions>
     
    </Dialog>
  );
};
export default ConfirmDialog;