import React from "react";
import Section from "./Section";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import ScheduleIcon from '@material-ui/icons/Schedule';
import GavelIcon from '@material-ui/icons/Gavel';


const useStyles = makeStyles((theme) => ({
  // Increase <Container> padding so it's
  // at least half of <Grid> spacing to
  // avoid horizontal scroll on mobile.
  // See https://material-ui.com/components/grid/#negative-margin
  container: {
    padding: `0 ${theme.spacing(4)}px`,
  },
  title1: {
    fontFamily: '"Cormorant Garamond", "serif"',
    textAlign: 'center',
    // fontWeight:'600',
    // marginBottom: '1em',
  },
  title: {
    fontFamily: '"Cormorant Garamond", "serif"',
    
  },
}));

function WhySell(props) {
  const classes = useStyles();

  const items = [
    {
      title: "Selling Made Easy",
      subtitle:
        "No need to take your item to a stuffy old auction house or consignment store. List it from exactly where it is, sell it, get paid directly and securely.  Easy, peasy, lemon-(Philippe Starck) squezzy.",
      icon: GavelIcon,
      iconColor: "primary.main",
    },
    {
      title: "One Low Fee",
      subtitle:
        "The only fee you will ever get charged by LadyBidwell is 15% on the sold price. So if it gets sold for $100, you'll keep $85. We'll take care of all the boring bits like sales tax and processing fees. There are no listing fees, monthly subscriptions or Auntie Florence's hidden fees here.",
      icon: AttachMoneyIcon,
      iconColor: "primary.main",
    },
    {
      title: "Sell Smart",
      subtitle:
        "Say goodbye to the traditional auctions $1 starting price with no reserve, and hello to LadyBidwell's unique declining price auction.  You set the price you would love to get, as well as the lowest price you're willing to accept. We create an optimized price decline for every item, and then everyday for the duration of the sale, the price will decline, until sold.",
        
        
        // From fast and free listings to auctions happening, everything we do is fast. Faster than a 4-horse drawn carriage going up Mayfair, trust me, that's fast.",
      icon: ScheduleIcon,
      iconColor: "primary.main",
    },

    // {
    //     title: "Auction Local",
    //     subtitle:
    //       "Buy Local, Eat Local and well you heard it here first, Auction Local. All our auctions are hyper-local, which means selling to a bevy of motivated local buyers, who are ready and willing to pick-up (unless you negotiated shipping with them)",
    //     icon: Brightness4Icon,
    //     iconColor: "#00d1b2",
    //   },

    // {
    //     title: "No Hassle",
    //     subtitle:
    //       "Dark mode is so last year. We have a light mode and turquoise mode. Either way, your eyes are going to hurt. Don't forget to stay charged!",
    //     icon: Brightness4Icon,
    //     iconColor: "#00d1b2",
    //   },
  ];

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container className={classes.container} >
      <Grid item={true} xs={12} md={12}>
      <Typography variant='h3'  className={classes.title1} >Why Sell with LadyBidwell?</Typography>
    </Grid>
    <Box mt={6}>
        <Grid container={true} justify="center" spacing={4} >
       
          {items.map((item, index) => (
            <Grid item={true} xs={12} md={4} key={index}>
              <Box textAlign="center">
                <Box color={item.iconColor} fontSize="2rem">
                  <item.icon fontSize="inherit" />
                </Box>
                <Typography variant="h4"  className={classes.title} gutterBottom={true}>
                  {item.title}
                </Typography>
                <Box mt={1}>
                  <Typography variant="body1">{item.subtitle}</Typography>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
        </Box>
      </Container>
    </Section>
  );
}

export default WhySell;
