import { useRouter } from "./../util/router.js";
import {
  useLocation,
} from "react-router-dom";



function PreLaunchPage(props) {
  const router = useRouter();
  const path = router.query.path;

  const location = useLocation();
  const { pathname, hash, key } = location;


//useEffect(()=>{
//console.log("path", path)
//}, [path])
  return (
    <>
   Pre Launch Page
    </>
  );
}

export default PreLaunchPage;
