import React from "react";
import { useRouter } from "./../util/router.js";

import { makeStyles } from "@material-ui/core/styles";
import { useAuth } from "./../util/auth.js";
import WeeklyPost from "../components/WeeklyPost.js";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },

  cardContent: {
    padding: theme.spacing(3),
  },

  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
    background: "#f5f5f5",
  },
}));

const defaultPalette = {lightVibrant:"#cccccc",darkMuted: "#000000"};

function WeeklyGuide(props) {
  const classes = useStyles();
  const router = useRouter();
  const post = router.query.post;
  const sellerMessage = router.query.seller;
  
  const auth = useAuth();
  
  const isAuctioneer = auth.user && auth.user.role && auth.user.role === "auctioneer";
  

  
  const shareUrl = window.location.href;




  return (
    <div>
{sellerMessage}
<WeeklyPost post={post} />

    </div>
  );
}

export default WeeklyGuide;
