import React, { useEffect } from "react";
import { useAuth } from "./../util/auth.js";


import ItemUnlist from "./ItemUnlist.js";
import { isPastTime } from "../util/time";



export default function OwnerUnlistItem(props) {

    const {item, lot, mode} = props;

  const auth = useAuth();

  const isDelisted =
    lot && lot.delistedAt && lot.delistedAt !== false ? true : false;

  const isAdministrator =
    auth.user && auth.user.role && auth.user.role === "auctioneer";
  const isOwner = auth.user && auth.user.id === (item && item.owner);

  const isLotEnded = lot && isPastTime(lot.endsAt);
   
useEffect(() =>{

  console.log("OwnerUnlistItem start", item && item.id)

},[])

    if (mode && mode === "view") {
      return null;
    }

    return (
      <>
        {!isDelisted && isOwner && !isAdministrator && !isLotEnded && (
          <>            <ItemUnlist item={item} lot={lot} />
          </>
        )}
      </>
    );





  };
