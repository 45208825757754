import React, { useState } from "react";
import Section from "./Section";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

import { headlineEventPeriod } from "./../util/time.js";
import {
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ProfileInformation from "./../components/ProfileInformation";
import AuctionDetailsDialog from "./AuctionDetailsDialog.js";


const removeMd = require("remove-markdown");
const useStyles = makeStyles((theme) => ({
  // root: {
  //   minHeight: "250px",
  //   // Add bottom margin if element below
  //   "&:not(:last-child)": {
  //     marginBottom: "2rem",
  //   },
  // },
  paper: {
    height: "auto",
  },
  container: {
    display: "flex",
  },

  clamped: {
    WebkitBoxOrient: "vertical",
    display: "-webkit-box",
    WebkitLineClamp: 3,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "normal",
  },

  heading: {
    overflow: "hidden",
    display: "-webkit-box",
    webkitBoxOrient: "vertical",
    /* truncate to 4 lines */
    webkitLineClamp: 1,
  },

  avatar: {
    minWidth: "0px",
  },
  container: {
    paddingBottom: '12px',
  },

  list: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
  },

  item: {
    paddingRight: 22,
    paddingBottom: 0,
    paddingTop: 0, 
  },

  subtitle: {
    // Subtitle text generally isn't very long
    // so usually looks better to limit width.
    maxWidth: 700,
    // So we can have max-width but still
    // have alignment controlled by text-align.
    display: "inline-block",
  },

  fontFamily: {
    fontFamily: '"Cormorant Garamond", "serif"',
    fontWeight: 500,
  },
  listText: {
    paddingLeft: 8,
  },


  open: {
    transform: "scaleX(1)",
  },
  close: {
    transform: "scaleX(-1)",
  },
}));

function EventDetailsSection(props) {
  const classes = useStyles();
  const { event } = props;
  console.log(event);


  // const [checked, setChecked] = React.useState(false);
  // const [details, setDetails] = React.useState(false);

  // const handleChange = () => {
  //   setChecked((prev) => !prev);
  // };

  // const handleDetailsChange = () => {
  //   setDetails((prev) => !prev);
  // };

  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Section
      style={{ backgroundColor: event.colour + "aa" }}
      bgColor="light"
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >

      <Container>
        <Box textAlign="left">
          <Grid container spacing={1} className={classes.root}>
            <Grid item xs={12} sm={10}>
              <Typography variant="h6" gutterBottom={true} className={classes.fontFamily}>
                {headlineEventPeriod(event)}
              </Typography>
           
              <Typography
                variant="h3"
                gutterBottom={true}
                className={classes.fontFamily}
              >
                {removeMd(event.name)}
              </Typography>



       
              <Typography
                variant='subtitle1'
                gutterBottom={true}
               
              >

              Seller: {'  '} <ProfileInformation spice={["drawer"]} profile={{ id: event.owner }} />  


       </Typography>
          


              </Grid>
         
              <Grid item xs={12} sm={11} style={{paddingBottom:'12px'}}>
              <Typography
               color='textSecondary'
                gutterBottom={true}
                variant="body1"
                style={{ lineHeight: "1.5em", whiteSpace: "pre-line" }}
                
              >
                {event.review}
              </Typography>
              </Grid>
   


              <AuctionDetailsDialog
        isDialogOpened={isOpen}
        handleCloseDialog={() => setIsOpen(false)}
        subtitle={removeMd(event.name)}
        body={event.pickupandshipping}
      />
   
   <Button
          
             onClick={() => handleOpen()}  
             color="primary"
             variant='outlined'
             size='small'
          >
            <Typography variant='body2'>
           Show More Info 
            </Typography>
            </Button>





       
          </Grid>
        </Box>
      </Container>
    </Section>
  );
}

export default EventDetailsSection;
