import React from "react";
import Container from "@material-ui/core/Container";

import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

import { useEvent } from "./../util/db.js";
import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  CardContent,
  CardHeader,
} from "@material-ui/core";

import ProfileInformation from "./../components/ProfileInformation";

// import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import {
  SecurityOutlined as SecurityOutlinedIcon,
  LockOutlined as LockOutlinedIcon,
  VerifiedUserOutlined as VerifiedUserOutlinedIcon,
} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: "30px",
  },

  cardHeader: {
    background: "#ffffff",
    borderBottom: "1px solid #e5e8eb",
  },

  gridItem: {
    // Add border that contrasts lightly with background color.
    // We use boxShadow so that it's hidden around outer edge
    // due to container <Card> having overflow: hidden
    // boxShadow: `1px 1px 0 0 ${emphasize(theme.palette.background.paper, 0.08)}`,
    textAlign: "left",
  },
  imageContainer: {
    margin: "0 auto",
    maxWidth: "200px",
    marginBottom: "30px",
    "& img": {
      width: "100%",
    },
  },

  cardContent: {
    background: "#fbfcff",
  },
  // title: {
  //   fontWeight: 700,
  //   fontFamily: '"Cormorant Garamond", "serif"',

  //   textTransform: "uppercase",
  // },
  subtitle: {
    fontSize: "1rem",
  },

  open: {
    transform: "scaleX(1)",
  },
  close: {
    transform: "scaleX(-1)",
  },
  clamped: {
    WebkitBoxOrient: "vertical",
    display: "-webkit-box",
    WebkitLineClamp: 5,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "normal",
  },
  section1: {
    border: "1px solid #f2f1f1",
    background: "#ffffff",
    marginBottom: 20,
  },
  section2: {
    border: "1px solid #f2f1f1",
    background: "#f7f7f7",

    marginBottom: 20,
  },
  // primaryListText: {
  //   fontSize:'1rem'
  // },
  // secondaryListText: {
  //   fontSize:'0.85rem'
  // },
}));

function ItemDetailsSection(props) {
  const classes = useStyles();
  const { item } = props;
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const {
    data: _event,
    status: eventStatus,
    error: eventError,
  } = useEvent(item.event);

  if (eventStatus !== "success") {
    return null;
  }

  // If the event doesn't exist (or no longer exists)
  // the hook will return null.
  // So don't return an item - because there is no corresponding event.
  if (_event === null) {
    return null;
  }
  function handleImpressionsChange(e) {}
  return (
    <Box my={3}>
      <Container>
        <Grid container spacing={2} flexdirection="column">
          <Grid item xs={12}>
            <Card variant="outlined">
              <CardHeader
                className={classes.cardHeader}
                title={<Typography variant="h6">Item Details</Typography>}
              />

              <CardContent className={classes.cardContent}>
                <List>
                  {/* <ListItem disableGutters alignItems="flex-start"> */}

                  <ListItemText
                    primary="About"
                    secondary={
                      <Typography
                        style={{ whiteSpace: "pre-line" }}
                        variant="body1"
                        color="textSecondary"
                        component="span"
                      >
                        {item?.description}
                      </Typography>
                    }
                  />

                  {/* </ListItem> */}

                  <ListItem disableGutters alignItems="flex-start">
                    <ListItemText
                      primary="Condition"
                      secondary={
                        <Typography
                          style={{ whiteSpace: "pre-line" }}
                          variant="body1"
                          color="textSecondary"
                          component="span"
                        >
                          {item?.condition}
                        </Typography>
                      }
                    />
                  </ListItem>

                  <ListItem
                    disableGutters
                    alignItems="flex-start"
                    component="div"
                  >
                    <ListItemText
                      primary="Seller"
                      secondary={
                        <Typography
                          style={{ whiteSpace: "pre-line" }}
                          variant="body1"
                          color="textSecondary"
                          component="span"
                        >
                          <ProfileInformation spice={["drawer"]} profile={{ id: _event?.owner }} />
                        </Typography>
                      }
                    />
                  </ListItem>

                  <ListItem disableGutters alignItems="flex-start">
                    <ListItemText
                      primary="Location"
                      secondary={
                        <Typography
                          style={{ whiteSpace: "pre-line" }}
                          variant="body1"
                          color="textSecondary"
                          component="span"
                        >
                          {_event?.location}
                        </Typography>
                      }
                    />
                  </ListItem>
                </List>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card variant="outlined" style={{ background: "#ffffff" }}>
              <CardHeader
                className={classes.cardHeader}
                title={
                  <Typography variant="h6">
                    {" "}
                    Pick-up / Shipping Information
                  </Typography>
                }
              />

              <CardContent className={classes.cardContent}>
                <ListItem disableGutters>
                  <Typography  variant="body1"   color="textSecondary">{_event?.pickupandshipping}</Typography>
                </ListItem>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card elevation={0} style={{ background: "#abf7ed" }}>
              <CardContent>
                <List>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <ListItem disableGutters alignItems="flex-start">
                        <ListItemAvatar className={classes.root}>
                          <VerifiedUserOutlinedIcon />
                        </ListItemAvatar>
                        <ListItemText
                          // classes={{ primary: classes.primaryListText, secondary:classes.secondaryListText }}
                          primary={
                            <Typography variant="subtitle1">
                              Verified Seller
                            </Typography>
                          }
                          secondary={
                            <Typography color="textSecondary" variant="body1">
                              All seller's are individually verified by
                              LadyBidwell to ensure a great buying experience
                              for all.
                            </Typography>
                          }
                        />
                      </ListItem>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <ListItem disableGutters alignItems="flex-start">
                        <ListItemAvatar className={classes.root}>
                          <LockOutlinedIcon />
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <Typography variant="subtitle1">
                              Secure Payment
                            </Typography>
                          }
                          secondary={
                            <Typography color="textSecondary" variant="body1">
                              Secure transactions by credit card through Stripe.
                            </Typography>
                          }
                        />
                      </ListItem>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <ListItem disableGutters alignItems="flex-start">
                        <ListItemAvatar className={classes.root}>
                          <SecurityOutlinedIcon />
                        </ListItemAvatar>
                        <ListItemText
                          classes={{
                            primary: classes.primaryListText,
                            secondary: classes.secondaryListText,
                          }}
                          primary={
                            <Typography variant="subtitle1">
                              Buyer Protection
                            </Typography>
                          }
                          secondary={
                            <Typography color="textSecondary" variant="body1">
                              If your item is not as described we will assist
                              both the buyer and seller to make it right.
                            </Typography>
                          }
                        />
                      </ListItem>
                    </Grid>
                  </Grid>
                </List>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default ItemDetailsSection;
