import React, { useState } from "react";
import { useAuth, requireAuth } from "./../util/auth.js";
import { capitalizeFirstLetter} from "./../util/text.js";
import { deleteEvent, createMessage } from "./../util/db.js";
import { makeStyles } from "@material-ui/core/styles";
import ConfirmDialog from "./ConfirmDialog.js";
import { IconButton } from "@material-ui/core";
import Delete from "@material-ui/icons/Delete";





const useStyles = makeStyles((theme) => ({
  deleteButton: {
    backgroundColor: "#000000",
    color: "#ffffff",
  },
}));

export function EventDelete(props) {
  const classes = useStyles();
  const auth = useAuth();

  const { event } = props;

  const isOwner = auth.user.id && auth.user.id === event.owner;

  const isAuctioneer = auth.user.role && auth.user.role === "auctioneer";

  const [canConsign, setCanConsign] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);

  const isCollection = event.auction === undefined ? true : false;

  const eventTypeSingular = isCollection ? "collection" : "client";
  const eventTypePlural = isCollection ? "collections" : "clients";

  //const item.accepted ===  null || item.accepted === undefined ? "Submit" : "Resubmit"}

  /*
  const {
    data: auctionData,
    status: auctionStatus,
    error: auctionError,
  } = useEvent(item && item.auction ? item.auction : undefined);

  const isAccepted =
    item && item.accepted === undefined ? false : item.accepted;
  const wasConsigned =
    item && item.wasConsigned === undefined ? false : item.wasConsigned;
  const isConsigned =
    item && item.consigned === undefined ? false : item.consigned;

    const isWithdrawn =
    item && item.withdrawn === undefined ? false : item.withdrawn;
*/
// You can delete an item if:
// You are the collection owner
// You are the item owner and it is not consigned
// You are the item owner and it is rejected
// You are the item owner and it is neither accepted or rejected

    // It is consigned and not yet accepted or rejected.
    // It has been consigned and accepted
    // It has not already been withdrawn

    //const canWithdraw = !isWithdrawn && ((isConsigned && item.accepted === null) || (isConsigned && isAccepted)) 

//const canDelete = isAuctioneer || (isOwner && ((!isConsigned || isWithdrawn) && !isAccepted))

const canDelete = isAuctioneer || isOwner;


  // Consigned the item and allow it to be withdrawn
  // if the item has not yet been accepted.

  const handleDeleteEvent = (event) => {
    if (isOwner) {

  // Uncomment this to activate deleting. ********************************
      deleteEvent(event.id)

      var message = "Owner deleted a " + eventTypeSingular + ".";

  
      createMessage({
        subject: message,
        event: event.id,
        from: auth.user.id,
        to: "auctioneer",
      });
    } else {
      alert("You must be the Owner of the "+ eventTypeSingular +" to use this feature");
    }
  };

  if (!isOwner) {
    return null;
  }


  return (
    <>



{isOwner ? (<>
        <IconButton
        
        variant='outlined'
        disabled={!canDelete}
          checked={true}
          onClick={() => {setConfirmOpen(true)
            
           
          }}
          name="checkedA"
          // inputProps={{ "aria-label": "secondary checkbox" }}
        >
          <Delete />
          
        </IconButton>
        <ConfirmDialog
        title={"Delete "+capitalizeFirstLetter(eventTypeSingular)+"?"}
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={() =>{handleDeleteEvent(event)}}
      >
        Are you sure you want to delete this {eventTypeSingular}?
      </ConfirmDialog>
      
      </>
      ) : null} 


    </>
  );
}

export default requireAuth(EventDelete);

