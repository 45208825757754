import React, { useState, useEffect } from "react";
import Section from "./Section";
import ReauthModal from "./ReauthModal";
import SettingsNav from "./SettingsNav";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Alert from "@material-ui/lab/Alert";
import SettingsProfile from "./SettingsProfile";
import SettingsGeneral from "./SettingsGeneral";
import SettingsPassword from "./SettingsPassword";
import SettingsBilling from "./SettingsBilling";
import SettingsDeveloper from "./SettingsDeveloper";
import SettingsAffiliate from "./SettingsAffiliate";
import { useAuth } from "./../util/auth.js";
import { Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import HeroSection2 from "./HeroSection2";

const useStyles = makeStyles((theme) => ({
  box: {
    padding: ".5em",
  },
}));

function SettingsSection(props) {
  const classes = useStyles();
  const auth = useAuth();
  const [formAlert, setFormAlert] = useState(null);

  // State to control whether we show a re-authentication flow
  // Required by some security sensitive actions, such as changing password.
  const [reauthState, setReauthState] = useState({
    show: false,
  });

  const validSections = {
    profile: true,
    general: true,
    password: true,
    billing: true,
    affiliate: true,
    developer: true,
  };
  //const [flags, setFlags] = useState();
  const section = validSections[props.section] ? props.section : "general";
  const message = props.message;
  const flags = props.flags;
  // Handle status of type "success", "error", or "requires-recent-login"
  // We don't treat "requires-recent-login" as an error as we handle it
  // gracefully by taking the user through a re-authentication flow.
  const handleStatus = ({ type, message, source, callback }) => {
    var f = flags;
    if (source === "profile") {
      f = { ...flags, profile: { flag: type } };

      if (props.onFlags) {
        props.onFlags(f);
      }
    }

    if (source === "billing") {
      console.log("SettingsSection handleStatus source billing");
      f = { ...flags, billing: { flag: type } };

      if (props.onFlags) {
        props.onFlags(f);
      }
    }

    if (type === "requires-recent-login") {
      // First clear any existing message
      setFormAlert(null);
      // Then update state to show re-authentication modal
      setReauthState({
        show: true,
        // Failed action to try again after reauth
        callback: callback,
      });
    } else {
      // Display message to user (type is success or error)
      if (source === section) {
        setFormAlert({
          source: source,
          type: type,
          message: message,
        });
      }
    }
  };

  useEffect(() => {
    console.log("SettingsSection start");
  }, []);

  useEffect(() => {
    console.log("SettingsSection formAlert", formAlert);
  }, [formAlert]);

  useEffect(() => {
    console.log("SettingsSection flags", flags);
  }, [flags]);

  return (
    <>
      {reauthState.show && (
        <ReauthModal
          callback={reauthState.callback}
          provider={auth.user.providers[0]}
          onDone={() => setReauthState({ show: false })}
        />
      )}

      <HeroSection2 title="Your Account" size="normal" />

      <Section
        bgColor={props.bgColor}
        size={props.size}
        bgImage={props.bgImage}
        bgImageOpacity={props.bgImageOpacity}
        // className={classes.box}
      >
        <Container maxWidth="md">
          <SettingsNav activeKey={section} flags={flags} />
          <Divider />

          <Box my={5}>
            {formAlert &&
              formAlert.type !== "silent" &&
              formAlert.type !== "success" && (
                <Box mb={4} style={{ position: "sticky", top: 0, zIndex: 99 }}>
                  <Alert severity={formAlert.type}>{formAlert.message}</Alert>
                </Box>
              )}

            <div style={{ display: section === "profile" ? "block" : "none" }}>
              <SettingsProfile onStatus={handleStatus} />
            </div>

            <div style={{ display: section === "general" ? "block" : "none" }}>
              <SettingsGeneral message={message} onStatus={handleStatus} />
            </div>

            <div style={{ display: section === "password" ? "block" : "none" }}>
              <SettingsPassword message={message} onStatus={handleStatus} />
            </div>

            <div style={{ display: section === "billing" ? "block" : "none" }}>
              <SettingsBilling message={message} onStatus={handleStatus} />
            </div>

            <div
              style={{ display: section === "affiliate" ? "block" : "none" }}
            >
              <SettingsAffiliate message={message} onStatus={handleStatus} />
            </div>

            <div
              style={{ display: section === "developer" ? "block" : "none" }}
            >
              <SettingsDeveloper message={message} onStatus={handleStatus} />
            </div>
          </Box>
        </Container>
      </Section>
    </>
  );
}

export default SettingsSection;
