import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { useAuth } from "./../util/auth.js";
import { updateEvent } from "./../util/db.js";
import { makeStyles } from "@material-ui/core/styles";
import EditEventPanel from "./../components/EditEventPanel";
import { Grid, TextField, Button, InputAdornment } from "@material-ui/core";
import { headlineEventPeriod } from "./../util/time.js";
import CollectionDetails from "./CollectionDetails";
import ClipboardCopy from "./../components/ClipboardCopy";
import EventStar from "./EventStar";
import EventName from "./EventName";
import LazyLoad from "react-lazyload";
import { useProfile } from "./../util/db.js";

const prefix = process.env.REACT_APP_WEB_PREFIX;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paperItems: {
    minHeight: "300px",
    background: "transparent",
  },
  featured: {
    backgroundColor:
      theme.palette.type === "dark" ? theme.palette.action.selected : "#fdf8c2",
  },
  starFeatured: {
    color: theme.palette.warning.main,
  },
  collections: {
    display: "flex",
    borderBottom: "0px",
  },
  flexContainer: {
    display: "flex",
    flexDirection: "row",
    padding: 0,
  },
}));

function Event(props) {
  const { event } = props;
  const classes = useStyles();

  const auth = useAuth();

  const isAuctioneer = auth.user.role && auth.user.role === "auctioneer";

  const eventTypeWordSingular = isAuctioneer ? "Collection" : "Event";
  const eventTypeWordPlural = isAuctioneer ? "Collections" : "Events";

  const {
    data: profileData,
    status: profileStatus,
    error: profileError,
  } = useProfile(event.owner);

  useEffect(() => {
    console.log("profileData", profileData);
  }, [profileData]);

  const canUseStar =
    auth.user.planIsActive &&
    (auth.user.planId === "pro" || auth.user.planId === "business");

  const handleStarEvent = (event) => {
    if (canUseStar) {
      updateEvent(event.id, { featured: !event.featured });
    } else {
      alert("You must upgrade to the pro or business plan to use this feature");
    }
  };

  const hasEventLink = event && event.slug ? true : false;
  var copyLink = prefix;
  if (hasEventLink) {
    copyLink = prefix + "/#" + event.slug;
  }

  if (
    profileData &&
    event &&
    event.id &&
    auth.user &&
    profileData.event &&
    event.id === profileData.event
  ) {
    return null;
    // return (<>DO NOT SHOW DEFAULT EVENT IN LIST</>)
  }

  return (
    <LazyLoad key={event.id}>
      <Box>
        <Paper
          elevation={0}
          style={{
            border: "1px solid #e2e2e2",
            marginBottom: "10px",
            padding: "10px",
            background: "#f5f6f8",
          }}
        >
          <div className={classes.root}>
            {/* <ListItemSecondaryAction> */}
            <Box display="flex">
              <EditEventPanel event={event} seller={profileData} />
            </Box>
            <Divider />

            {/* </ListItemSecondaryAction> */}
            <ListItem
              //divider={index !== events.length - 1}
              className={classes.collections}
              // component={Link}
              to={`/client/${event.id}`}
              style={{
                textDecoration: "none",
                color: "black",
                height: "100%",
              }}
            >
              <ListItemText className={classes.listText}>
                <Box>
                  {isAuctioneer && (
                    <>
                      <EventStar event={event} />
                    </>
                  )}
                </Box>

                <Link to={`/client/${event.id}`}>
                  <Typography variant="h5">{event.name} </Typography>
                </Link>

                {/* <Typography variant="subtitle1">{copyLink} </Typography><Box py={1}><ClipboardCopy copyText={copyLink} /> </Box> */}
<Box py={1}>
                <TextField
                  variant="outlined"
                  defaultValue={copyLink}
                  fullWidth
                  style={{background:'#ffffff'}}
                  InputProps={{
                    readOnly: true,
                    
                    endAdornment: (
                      <InputAdornment position="end">
                        <ClipboardCopy />
                      </InputAdornment>
                    ),
                  }}
                />
</Box>
                {isAuctioneer && (
                  <>
                    <Typography variant="subtitle1">
                      Date: {headlineEventPeriod(event)}{" "}
                    </Typography>
                    <CollectionDetails event={event} />
                  </>
                )}

                {!isAuctioneer && (
                  <>
                    <Link to={`/client/${event.id}`}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <Typography gutterBottom variant="subtitle1">
                            <b>Description:</b>
                            <br /> {event.review}
                          </Typography>

                          <Grid item xs={12} sm={6}>
                            <Typography variant="subtitle1">
                              <b> Pickup and Shipping Details:</b>
                              <br /> {event?.pickupandshipping}
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <Typography gutterBottom variant="subtitle1">
                            <b>Default Sale:</b>{" "}
                            <EventName
                              event={event.auction}
                              format={"html link"}
                            />
                          </Typography>

                          <Typography gutterBottom variant="subtitle1">
                            <b>General Location:</b>
                            <br /> {event.location}
                          </Typography>
                          <Typography variant="subtitle1">
                            <b>Memo:</b>
                            <br /> {event.memo}
                          </Typography>
                          <Typography gutterBottom variant="subtitle1">
                            <b>Pick Up Address:</b>
                            <br /> {event.address}
                          </Typography>
                          {/* <Typography gutterBottom variant="subtitle1">
                                  <b>Pickup and Shipping Details:</b>
                                  <br /> {event.pickupandshipping}
                                </Typography> */}
                        </Grid>
                      </Grid>
                    </Link>
                  </>
                )}
              </ListItemText>
            </ListItem>
          </div>
        </Paper>
      </Box>
    </LazyLoad>
  );
}

export default Event;
