import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  AppBar,
  Container,
  Button,
} from "@material-ui/core";
import BuyerInformation from "./BuyerInformation";
import Divider from "@material-ui/core/Divider";
import { useAuth } from "./../util/auth.js";
import {
  useUncachedEvent,
  useLotByItemLive,
  useItemByUser,
} from "./../util/db.js";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ItemThumbnail from "./../components/ItemThumbnail";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import { Toolbar } from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import UserItemHeart from "./../components/UserItemHeart";
import ItemStatus from "./ItemStatus";
import ItemDelete from "./ItemDelete";
import { CardHeader } from "@material-ui/core";
import ItemAction from "./../components/ItemAction";
//import { loadGetInitialProps } from "next/dist/next-server/lib/utils";
import LotPrice from "./../components/LotPrice";
import { useIdentity } from "./../util/identity.js";

import LotPricesTable from "./LotPricesTable";
import ProfileInformation from "./ProfileInformation";
import EventName from "./EventName";
import MessagesItem from "./MessagesItem";
import { getFunctions, httpsCallable } from "firebase/functions";
import { Skeleton } from "@material-ui/lab";
import LazyLoad from "react-lazyload";
import SellerBadge from "./SellerBadge";
import ItemIssueMessage from "./ItemIssueMessage";
import { getSlug } from "../util/slug";
import EventStatus from "./EventStatus";
import TestIndicator from "./TestIndicator";
import StoreOutlinedIcon from '@material-ui/icons/StoreOutlined';
import GavelOutlinedIcon from '@material-ui/icons/GavelOutlined';
import VisibilitySensor from "react-visibility-sensor";

import { IsSold } from "./../util/filter.js";

import { debugFlag } from "./../util/dev.js";

const defaultPalette = { lightVibrant: "#cccccc", darkMuted: "#000000" };

const useStyles = makeStyles((theme) => ({
  root: {
    // minWidth: 275,
    // boxShadow: "1px 0px 4px rgb(0 2 4 / 2%), 0 7px 20px rgb(1 1 1 / 3%)",
    padding: "15px",

    borderColor: "transparent",
    // border: '1px solid',
    "&:hover": {
      borderColor: "#0000001f",
      border: "1px solid",
    },
  },
  "&:hover": {
    borderColor: "red",
  },

  // accordion: {
  //   border: "1px solid rgba(0, 0, 0, .125)",
  //   boxShadow: "none",
  //   "&:not(:last-child)": {
  //     borderBottom: 0,
  //   },
  //   "&:before": {
  //     display: "none",
  //   },
  //   "&$expanded": {
  //     margin: "auto",
  //   },
  // },
  // expanded: {},

  accordion: {
    justifyContent: "center",
  },

  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },

  summary: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },

  content: {
    justifyContent: "center",
  },

  estimate: {
    justifyContent: "space-between",
    // paddingLeft: '16px',
    // paddingRight: '16px',

    display: "flex",
    alignItems: "center",
  },

  media: {
    height: "200px",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },

  paperItems: {
    minHeight: "300px",
  },
  featured: {
    backgroundColor:
      theme.palette.type === "dark" ? theme.palette.action.selected : "#fdf8c2",
  },
  starFeatured: {
    color: theme.palette.warning.main,
  },
  name: {
    overflow: "hidden",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
  },
  StipTitle: {
    textDecoration: "none",
    color: "black",
    fontWeight: 600,
  },
  headerTitle: {
    paddingBottom: "0px",
    fontFamily: '"Cormorant Garamond", "serif"',
    overflow: "hidden",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
  },
  headerRoot: {
    // paddingBottom: "0px",
    background: "#ffffff",
  },
  stripThumbnail: {
    background: "#000000",
  },
  stretch: { height: "100%" },
  item: { display: "flex", flexDirection: "column" }, // KEY CHANGES
}));

function ItemStrip(props) {
  //const { likes } = props;

  const classes = useStyles();
  const { isAuctioneer, isSeller } = useIdentity();
  const [thumbStatus, setThumbStatus] = useState("loading");

  const auth = useAuth();

  var {
    item,
    likes,
    tab,
    mode,
    print,
    orders,
    flavour,
    filterType,
    showSkeleton,
  } = props;
  const [itemLot, setItemLot] = useState({});
  const [itemStatus, setItemStatus] = useState("loading");

  const [visible, setVisible] = useState();
  const [seen, setSeen] = useState();
  if (item && item.palette === undefined) {
    item.palette = defaultPalette;
  }

  const isDeveloper = auth?.user?.developer?.enabled;

  const isOwner = auth.user && auth.user.id === (item && item.owner);
  // const isSeller = auth.user && auth.user.seller !== undefined;
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [placeHolderHeight, setPlaceHolderHeight] = useState(0);
  /*
  const {
    data: event,
    status: eventStatus,
    error: eventError,
  } = useEvent(item && item.auction ? item.auction : undefined);
*/
  const {
    data: event,
    status: eventStatus,
    error: eventError,
  } = useUncachedEvent(seen && item && item.auction ? item.auction : false);

  const {
    data: lot,
    status: lotStatus,
    error: lotError,
  } = useLotByItemLive(seen && item && item.id ? item.id : false);

  //  const { isBought, isBuyable } = useLot({item:item,lot:lot});
  //const isBought = filterHydratedItem(item, 'sold');
  const isBought = IsSold({ lot: lot });

  var {
    data: itemUser,
    status: itemUserStatus,
    error: itemUserError,
  } = useItemByUser(seen && item);

  useEffect(() => {
    console.log("ItemStrip start", item && item.id);
  }, []);

  const isReady =
    eventStatus === "success" &&
    lotStatus === "success" &&
    itemUserStatus === "success";

  const partialVisibility = true;

  useEffect(() => {
    setVisible(false);
  }, [item, filterType]);

  function handleVisible(v) {
    if (v === visible) {
      return;
    }

    //v.preventDefault();
    console.log("ItemStrip handleVisible v item id", item.id, v);
    //setPlaceHolderHeight(300);
    setVisible(v);
    handleSeen();
    if (props.onVisible) {
      props.onVisible(v);
    }
  }

  function handleSeen() {
    setSeen(true);
  }

  const isEditable =
    item.accepted === undefined || (item.accepted && item.accepted === false);
  //const isEditable = false;

  function handleLot() {}

  useEffect(() => {
    handleReady();
  }, [event, eventStatus, eventError]);

  useEffect(() => {
    handleReady();
  }, [lot, lotStatus, lotError]);

  const handleReady = () => {
    if (lotStatus === "success" && eventStatus === "success") {
      setItemStatus("success");
    }
    if (item && !item.auction && lotStatus === "success") {
      setItemStatus("success");
    }
  };

  // This is the "right hand" of the 3 (or 4?) panels.
  // It houses the actions the user can take.

  function handleStatus(e) {
    if (e.messages === "success") {
      setItemStatus("success");
    }
  }

  useEffect(() => {
    setPlaceHolderHeight(0);
  }, [filterType]);

  // sold function
  const functions = getFunctions();

  const processBuyerInformation = httpsCallable(
    functions,
    "getBuyerInformation"
  );

  const [data, setData] = useState();

  useEffect(() => {
    if (!lot) return;
    if (!lot.bidOwner) return;
    const buyerId = lot && lot.bidOwner;

    const { data: data, error: error } = processBuyerInformation({
      itemId: lot.item,
      userId: buyerId,
    })
      .then((result) => {
        console.log("BuyerInformation result", result);
        setData(result.data.data);
      })
      .catch((error) => {
        console.error("BuyerInformation error", error);
      });
  }, [lot]);
  //if (!isDeveloper && item.test !== true) {

  if (!isDeveloper && item && item.test && item.test === true) {
    return null;
  }

  //  if ((itemStatus !== "success") && (props.showSkeleton && props.showSkeleton === true)) {
  if (itemStatus !== "success") {
    //if (!isReady) {
    const skeletonHeight = flavour === "simple" ? 100 : 300;
    return (
      <VisibilitySensor
        onChange={(v) => handleVisible(v)}
        partialVisibility={partialVisibility}
      >
        <div>
          {debugFlag && (
            <>
              {"ITEMSTATUS"}
              {itemStatus}
              {" ITEMUSERSTATUS "}
              {itemUserStatus}
              {" LOT "}
              {lotStatus}
            </>
          )}
          <Skeleton variant="rect" height={skeletonHeight} />
        </div>
      </VisibilitySensor>
    );
  }

  return (
    <>
      <VisibilitySensor
        onChange={(v) => handleVisible(v)}
        partialVisibility={partialVisibility}
      >
        <>
          {filterType === "sold" && flavour === "simple" && isBought && (
            <>
              <LazyLoad height={100} offset={1000}>
                <BuyerInformation item={item} lot={lot} flavour={flavour} />
              </LazyLoad>
            </>
          )}

          {((filterType === "sold" && flavour !== "simple" && isBought) ||
            filterType === "all" ||
            (filterType === "wip" && isEditable)) && (
            <>
              <TestIndicator item={item} />
              {/* <LazyLoad height={400} offset={400}>  */}

              <Grid container direction={"row"} spacing={4}>
                <Grid item xs={12}>
                  <Card
                    style={
                      {
                        // minHeight: "250px",
                        // backgroundColor:
                        //   item && item.palette && item.palette
                        //     ? item.palette.lightVibrant + "26"
                        //     : "#f5f5f5",
                      }
                    }
                    // className={item.featured && classes.featured}
                    variant="outlined"
                  >
                    {likes && (
                      <>
                        <CardHeader
                          title={
                            <Link to={`/item/${getSlug(item.name)}/${item.id}`}>
                              {item.name}
                            </Link>
                          }
                          // subheader={<>Event: {event?.name}</>}
                          // subheader={"Item Reference: " + (item && item.itemRef ? item.itemRef.replace("item_","") : 'not available')}
                          classes={{
                            title: classes.headerTitle,
                            root: classes.headerRoot,
                          }}
                          action={
                            <>
                              {likes && (
                                <UserItemHeart item={item} type={"remove"} />
                              )}
                            </>
                          }
                        />
                      </>
                    )}

                    {!likes && (
                      <>
                        <CardHeader
                      
                          subheader={
                            item.listingType === "declining_price" && (
                          
                              <Button disabled style={{color:'#000000', opacity:'.54'}} endIcon={<GavelOutlinedIcon/>}> Sale Listing</Button>

                            )
                            ||
                            item.listingType === "marketplace_price" && (
                              
                              <Button disabled style={{color:'#000000', opacity:'.54'}} endIcon={<StoreOutlinedIcon/>}> Marketplace Listing</Button>
                            ) 

                          }
                          style={{
                            // paddingBottom: "0px",
                            // borderBottom: "1px solid #bdbdbd",
                            textAlign: "right",
                            background:'#f7f7f7'
                          }}
                         

                        />
                        <CardHeader
                          // title={<ProfileInformation profile={{ id: item.owner }} />}
                          subheader={
                            "ref: " +
                            (item && item.itemRef
                              ? item.itemRef.replace("item_", "")
                              : "not available")
                          }
                          href={`/item/${getSlug(item.name)}/${item.id}`}
                          classes={{
                            title: classes.headerTitle,
                            root: classes.headerRoot,
                          }}
                          action={
                            <>
                              <Box
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  flexGrow: "1",
                                }}
                              >
                                {mode !== "view" && (
                                  <ItemStatus
                                    mode={mode}
                                    item={item}
                                    lot={lot}
                                  />
                                )}
                                <ItemDelete
                                  mode={mode}
                                  item={item}
                                  event={event}
                                  lot={lot}
                                />
                              </Box>
                            </>
                          }
                        />
                      </>
                    )}

                    {isAuctioneer && (
                      <CardContent>
                        <Typography>
                          <b>Seller: </b>
                          <ProfileInformation spice={["drawer"]} profile={{ id: item.owner }} />
                        </Typography>

                        <Typography>
                          <b>Seller Status: </b>
                          <SellerBadge
                            user={{ id: item.owner }}
                            flavour={"tiny"}
                          />
                        </Typography>

                        <Typography>
                          <b>Seller Event: </b> <EventName event={item.event} />
                        </Typography>
                      </CardContent>
                    )}

                    <Divider />

                    {!likes && (
                      <>
                        <ItemIssueMessage lot={lot} item={item} />
                      </>
                    )}

                    <CardContent className={classes.cardContent}>
                      {!likes && (
                        <Typography
                          variant="h5"
                          gutterBottom
                          className={classes.headerTitle}
                        >
                          {" "}
                          {item.name}
                        </Typography>
                      )}
                      <Box py={1}>
                        <Grid
                          container
                          spacing={3}
                          direction="row"
                          style={{ justifyContent: "space-between" }}
                        >
                          <Grid item xs={4} sm={2}>
                            <Link to={`/item/${getSlug(item.name)}/${item.id}`}>
                              <Box
                                display="flex"
                                alignItems="center"
                                style={{ height: "100%" }}
                              >
                                <ItemThumbnail item={item} flavour="cart" />
                              </Box>
                            </Link>
                          </Grid>

                          <Grid item xs={8} sm={5} md={4}>
                            {(isAuctioneer || isOwner) &&
                              itemUser &&
                              !likes && (item.listingType === "declining_price") && (
                                <>
                                  <Typography variant="subtitle1">
                                    <b>Sale: </b>
                                    {event?.name}
                                  </Typography>
                                  <Typography variant="subtitle1">
                                    <b>Sale Status: </b>
                                    <EventStatus
                                      event={event}
                                      flavour={"text"}
                                    />
                                  </Typography>
                                  <Typography variant="subtitle1">
                                    {" "}
                                    <b>Starting Price:</b>{" "}
                                    {"$" + item.sellerStartingPrice}
                                  </Typography>
                                  <Typography variant="subtitle1">
                                    {" "}
                                    <b>Reserve Price:</b>{" "}
                                    {"$" +
                                      (itemUser.reservePrice / 100).toFixed(0)}
                                  </Typography>



{/* 
                                  {item && item.accepted && (
                                    <>
                                      <Typography variant="subtitle1">
                                        <LotPrice
                                          lot={lot}
                                          flavour="horizon_price"
                                        />
                                      </Typography>
                                    </>
                                  )} */}
                                </>
                              )}



{(isAuctioneer || isOwner) &&
                              itemUser &&
                              !likes && (item.listingType === "marketplace_price") && (
                                <>
                                  <Typography variant="subtitle1">
                                    <b>Marketplace: </b>
                                    {event?.name}
                                  </Typography>
                                  {/* <Typography variant="subtitle1">
                                    <b>Martketplace Status: </b>
                                    <EventStatus
                                      event={event}
                                      flavour={"text"}
                                    />
                                  </Typography> */}
                          

                                </>
                              )}




{(isAuctioneer || isOwner) &&
                              itemUser &&
                              !likes && (item.listingType === "marketplace_price") && (
                                <>
<Typography variant="subtitle1">
                                   <b>Price</b>:&nbsp;    

                                      <LotPrice lot={lot} item={item} flavour="marketplace_price" spice="simple"  />

                                      </Typography>
                                      </> )}
                                

                            {likes && (lot.type === "declining_price" ) && (
                              <LotPrice lot={lot} flavour="horizon_price" spice="item_card"  />
                            )}

{likes && (lot.type === "marketplace_price") && (
                              <LotPrice lot={lot} item={item} flavour="marketplace_price" spice="item_card"  />
                            )}
                          </Grid>

                        
                        



                          <Grid item xs sm={4} md={5}>
                            <ItemAction
                              key={item.id}
                              item={item}
                              event={event}
                              lot={lot}
                              mode={mode}
                              onStatus={(e) => handleStatus(e)}
                            />
                          </Grid>

                          {(isAuctioneer || isSeller) && !likes && (
                            <>
                              <Grid item xs={12}>
                                <Divider />
                              </Grid>

                              <Box style={{ width: "100%" }}>
                                <Accordion
                                  elevation={0}
                                  // style={{
                                  //   border: "1px solid rgba(0, 0, 0, .125)",
                                  // }}
                                  // classes={{
                                  //   root: classes.accordion,
                                  // }}
                                  expanded={expanded === "panel1"}
                                  onChange={handleChange("panel1")}
                                >
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                    classes={{ content: classes.content }}
                                  >
                                    <Typography variant="subtitle1">
                                      More Details
                                    </Typography>
                                  </AccordionSummary>

                                  <AccordionDetails style={{ display: "grid" }}>
                                    <Grid
                                      container
                                      display="flex"
                                      direction="row"
                                      spacing={1}
                                    >

{(isAuctioneer || isOwner) &&
                              itemUser &&
                              !likes && (item.listingType === "declining_price") && (

                                      <Grid item xs={12} sm={4}>
                                        <Box
                                          py={1}
                                          px={2}
                                          style={{
                                            border: "1px solid #d7d8da",
                                            backgroundColor: "#ffffff",
                                            height: "100%",
                                          }}
                                        >
                                          <Box>
                                            <Typography
                                              variant="subtitle1"
                                              gutterBottom
                                              style={{
                                                textDecoration: "underline",
                                              }}
                                            >
                                              Price Drop Table
                                            </Typography>
                                          </Box>
                                          {item?.accepted === true && (
                                            <LotPricesTable lot={lot} />
                                          )}
                                        </Box>
                                      </Grid>
                              )}

                                      <Grid item xs={12} sm={4}>
                                        <MessagesItem
                                          key={
                                            "item_action_router_auctioneer_review_item_messages_item_" +
                                            item.id
                                          }
                                          //itemId={item.id}
                                          item={item}
                                          user={{ id: item.owner }}
                                          collapsed={true}
                                          status={true}
                                        />
                                      </Grid>
                                      <Grid item xs={12} sm={4}>
                                        <BuyerInformation
                                          item={item}
                                          lot={lot}
                                        />
                                      </Grid>
                                    </Grid>
                                  </AccordionDetails>
                                </Accordion>
                              </Box>
                            </>
                          )}
                        </Grid>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
              {/* </LazyLoad>  */}
            </>
          )}
        </>
      </VisibilitySensor>
    </>
  );
}

export default ItemStrip;
