import React, { useState, useEffect, useCallback } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useAuth } from "./../util/auth.js";
import { useForm } from "react-hook-form";
import { updateProfile, useProfile } from "../util/db.js";
//import SellerBadge from "./../components/SellerBadge";
import { Typography, Box, IconButton, Container } from "@material-ui/core";
import { Link } from "./../util/router.js";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import { useDropzone } from "react-dropzone";
import { firebaseApp, storage } from "./../util/firebase";
import {
  getStorage,
  ref,
  uploadBytes,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function SettingsProfile(props) {
  const auth = useAuth();
  const {
    data: sellerData,
    status: sellerStatus,
    error: sellerError,
  } = useProfile(auth.user.id);

  const [pending, setPending] = useState(false);

  // const { register, handleSubmit, errors, formState: { isSubmitting, isDirty, isValid,  }   } = useForm({ mode: "onChange" })

  const methods = useForm({ mode: "onBlur" });

  const {
    register,
    handleSubmit,
    errors,
    formState: { isSubmitting, isDirty, isValid },
  } = methods;

  // State for holding uploaded file
  const [uploadedImage, setUploadedImage] = useState(null);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    setSelectedFile(file);

    // create a URL for the selected file
    const fileURL = URL.createObjectURL(file);
    // update uploadedImage so that the selected image will be displayed
    setUploadedImage(fileURL);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  useEffect(() => {
    if (sellerData && sellerData.profileImageUrl) {
      setUploadedImage(sellerData.profileImageUrl);
    }
  }, [sellerData]);

  // useEffect(()=>{

  // console.log("SettingsProfile isDirty isValid", isDirty, isValid)

  // }, [isDirty, isValid])

  useEffect(() => {
    console.log("Form data:", methods.getValues());
    console.log("Form errors:", errors);
    methods.trigger();
  }, []);

  useEffect(() => {
    if (!isValid) {
      props.onStatus({
        type: "error",
        message: "Your Seller Profile Requires Further Details",
        source: "profile",
      });
    }
  }, [isValid]);

  useEffect(() => {
    if (isValid) {
      props.onStatus({
        type: "success",
        source: "profile",
      });
    }
  }, [isValid]);

  useEffect(() => {
    console.log("Error Status:", sellerStatus);
  }, [sellerStatus]);

  const onSubmit = (data) => {
    // Show pending indicator
    setPending(true);
    console.log(auth.user.id);
    console.log("seller update", data);

    const updateAndNotify = (imageUrl) => {
      return updateProfile(auth.user.id, data, imageUrl)
        .then(() => {
          toast.success("Your profile has been updated successfully");
          props.onStatus({
            type: "success",

            source: "profile",
          });
        })
        .catch((error) => {
          console.log("Error during profile update:", error);
          props.onStatus({
            type: "error",
            message: error.message,
            source: "profile",
          });
        })
        .finally(() => {
          // Hide pending indicator
          setPending(false);
        });
    };

    if (selectedFile) {
      const storageRef = ref(storage, `images/${selectedFile.name}`);
      const uploadTask = uploadBytesResumable(storageRef, selectedFile);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Progress function
          var progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
        },
        (error) => {
          // Error function
          console.log(error);
        },
        () => {
          // Complete function
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            console.log("File available at", downloadURL);
            setUploadedImage(downloadURL);
            updateAndNotify(downloadURL);
          });
        }
      );
    } else {
      updateAndNotify(uploadedImage);
    }
  };

  const ProfileButton = (props) => {
    var u = auth.user && auth.user.uid ? auth.user.uid : null;

    if (u === undefined || u === null) {
      return <>This Seller does not have a profile set up.</>;
    }

    return (
      <Button
        variant="outlined"
        component={Link}
        to={"/seller/" + u}
        endIcon={<ExitToAppIcon />}
      >
        View Your Seller Page
      </Button>
    );
  };

  if (sellerStatus !== "success") {
    return null;
  }

  return (
    <>
      {/*<SellerBadge />*/}

      <Box pb={2} style={{ display: "flex", justifyContent: "flex-end" }}>
        <ProfileButton />
      </Box>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container={true} spacing={3}>
          <Grid item container xs={12} justifyContent="left">
            <Typography variant="h6">
              Fill in all the fields below for your seller profile.{" "}
            </Typography>
          </Grid>

          <Grid item={true}>
            {/* <Typography variant="subtitle1" gutterBottom>Profile Display Image</Typography> */}
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              {uploadedImage ? null : (
                <Box
                  p={4}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    border: "1px dashed black",
                  }}
                >
                  <IconButton color="primary" component="span">
                    <CameraAltIcon fontSize="large" />
                  </IconButton>
                  <Typography color="textSecondary" variant="body1">
                    Upload image for your seller page
                  </Typography>
                  <Typography color="textSecondary" variant="body2">
                    (please ensure this is high res, and a hero/banner image,
                    not just a logo)
                  </Typography>
                </Box>
              )}
              <div
                style={{
                  position: "relative",
                  display: "block",
                  width: "250px",
                }}
              >
                <img
                  width="250"
                  style={{ position: "relative", display: "block" }}
                  src={uploadedImage || ""}
                  alt=""
                />
                {uploadedImage && (
                  <Button
                    disableElevation
                    fullWidth
                    variant="contained"
                    color="primary"
                    style={{ position: "absolute", bottom: 0, zIndex: 2 }}
                    onClick={() => {
                      setUploadedImage(null);
                      setSelectedFile(null);
                    }}
                  >
                    Change Image
                  </Button>
                )}
              </div>
            </div>
          </Grid>

          <Grid item={true} xs={12}>
            <TextField
              variant="outlined"
              type="text"
              label="Company Name (this will be public facing on your selling pages)"
              name="name"
              placeholder="Company Name"
              defaultValue={
                sellerData && sellerData.name ? sellerData.name : null
              }
              error={errors.name ? true : false}
              helperText={errors.name && errors.name.message}
              fullWidth={true}
              inputRef={register({
                required: "Company Name",
              })}
            />
          </Grid>


          <Grid item={true} xs={12}>
            <TextField
    
         
              variant="outlined"
              type="text"
              label="Location"
              name="location"
              placeholder="Primary Business Location"
              defaultValue={
                sellerData && sellerData.location ? sellerData.location : null
              }
              error={errors.location ? true : false}
              helperText={errors.location && errors.location.message}
              fullWidth={true}
              inputRef={register({
                required: "Primary Business Location",
              })}
            />
          </Grid>


          <Grid item={true} xs={12}>
            <TextField
              variant="outlined"
              type="text"
              label="Contact Name"
              name="contactName"
              placeholder="Contact Name"
              defaultValue={
                sellerData && sellerData.contactName
                  ? sellerData.contactName
                  : null
              }
              error={errors.contactName ? true : false}
              helperText={errors.contactName && errors.contactName.message}
              fullWidth={true}
              inputRef={register({
                required: "Contact Name for Buyers",
              })}
            />
          </Grid>

          <Grid item={true} xs={6}>
            <TextField
              variant="outlined"
              type="text"
              label="Contact Number"
              name="contactNumber"
              placeholder="Contact Number"
              defaultValue={
                sellerData && sellerData.contactNumber
                  ? sellerData.contactNumber
                  : null
              }
              error={errors.contactNumber ? true : false}
              helperText={errors.contactNumber && errors.contactNumber.message}
              fullWidth={true}
              inputRef={register({
                required: "Contact Number for Buyers",
              })}
            />
          </Grid>

          <Grid item={true} xs={6}>
            <TextField
              variant="outlined"
              type="email"
              label="Contact Email (this will be displayed to buyers)"
              name="profileEmail"
              placeholder="user@example.com"
              defaultValue={
                sellerData && sellerData.profileEmail
                  ? sellerData.profileEmail
                  : null
              }
              error={errors.profileEmail ? true : false}
              helperText={errors.profileEmail && errors.profileEmail.message}
              fullWidth={true}
              inputRef={register({
                required: "Contact Email for Buyers",
              })}
            />
          </Grid>

          <Grid item={true} xs={12}>
            <TextField
              multiline
              rows={10}
              variant="outlined"
              type="text"
              label="About your Business"
              name="headline"
              placeholder="About your Business"
              defaultValue={
                sellerData && sellerData.headline ? sellerData.headline : null
              }
              error={errors.headline ? true : false}
              helperText={errors.headline && errors.headline.message}
              fullWidth={true}
              inputRef={register({
                required: "This is a default about your business.",
              })}
            />
          </Grid>

        

            <Grid
              item
              xs={12} sm={12} md={6}
           
              justifyContent="flex-end" // aligns horizontally in the center
              alignItems="center" // aligns vertically in the center
              // change the height value as needed
              style={{  }}
            >
              <Button
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
                size="large"
                disabled={isSubmitting}
                // disabled={!isDirty || !isValid}
                // style={{ position:'sticky', bottom:0, zIndex:'99' }}
              >
                {!pending && <span>Save</span>}
                {pending && <CircularProgress size={28} />}
              </Button>
            </Grid>

        </Grid>
      </form>
      <ToastContainer />


      
    </>
  );
}

export default SettingsProfile;
