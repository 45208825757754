import React from "react";
import Section from "./Section";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  // Increase <Container> padding so it's
  // at least half of <Grid> spacing to
  // avoid horizontal scroll on mobile.
  // See https://material-ui.com/components/grid/#negative-margin
  container: {
    padding: `0 ${theme.spacing(4)}px`,
  },
  imageWrapper: {
    // height:'20vh',
    margin: "0 auto",
    maxWidth: 570,
    width: "100%",
    "& > img": {
      width: "100%",
    },
  },
  row: {
    // Spacing between rows
    "&:not(:last-child)": {
      marginBottom: `${theme.spacing(2)}px`,
    },
  },
  title: {
    fontFamily: '"Cormorant Garamond", "serif"',
  },
}));

function TradeProgramIntro(props) {
  const classes = useStyles();
  const handleClick = () => {
    document.querySelector("#contact-section").scrollIntoView({ behavior: "smooth" });
  };
  const items = [
    {
      // number: 1,
      // iconColor: "primary.main",
    },
  ];

  return (
    <>
      {/* <Typography variant='h3'  className={classes.title}>Start Selling</Typography> */}

      <Section size="medium">
        <Container>
          <Grid container={true} alignItems="center" spacing={2}>
            {/* <Grid container={true} item={true} direction="column" xs={12} md={6}>
            <figure className={classes.imageWrapper}>
              <img src={props.image} alt="" />
            </figure>
          </Grid> */}

            <Grid item={true} xs={12} md={12}>
              {items.map((item, index) => (
                <Grid
                  className={classes.row}
                  item={true}
                  container={true}
                  direction="row"
                  justify="center"
                  alignItems="center"
                  spacing={5}
                  key={index}
                >
                  <Grid
                    container={true}
                    item={true}
                    direction="column"
                    xs={12}
                    md={6}
                  >
                    <figure className={classes.imageWrapper}>
                      <img src={props.image} alt="" />
                    </figure>
                  </Grid>

                  <Grid item={true} xs={12} md={6}>
                    <Typography variant="body1" style={{ fontWeight: "400" }}>
                    Individuals in the field of interior design, architecture, or other design-related professions who are working on residential or hospitality projects and can provide a valid Resale Certificate or other approved documentation are eligible to apply for membership. Membership determinations will be made at our sole discretion. Each designer must apply for membership individually and provide their own qualifications.
                    </Typography>
                    <Box pt={4}>
                      <Grid item={true} xs={true}>
                        <Button
                          variant="contained"
                          // fullWidth
                          onClick={handleClick}
                          size="medium"
                          color="primary"
                          style={{ fontSize: "1.3em", fontWeight: "500" }}
                        >
                          Get Started
                        </Button>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Container>
      </Section>
    </>
  );
}

export default TradeProgramIntro;
