import React from "react";



import { useAuth } from "./../util/auth.js";
import { makeStyles } from "@material-ui/core/styles";


import { isPastTime } from "../util/time.js";


const useStyles = makeStyles((theme) => ({
  paperItems: {
    minHeight: "300px",
  },

  featured: {
    backgroundColor:
      theme.palette.type === "dark" ? theme.palette.action.selected : "#fdf8c2",
  },
  starFeatured: {
    color: theme.palette.warning.main,
  },
  accepted: {
    backgroundColor:
      theme.palette.type === "dark" ? theme.palette.action.selected : "#fdf8c2",
  },
  tickAccepted: {
    color: theme.palette.success.main,
  },
  deleteDisabled: {
    color: theme.palette.action.disabledBackground,
  },
  editDisabled: {
    color: theme.palette.action.disabledBackground,
  },
  notAccepted: {
    color: theme.palette.error.dark,
  },
  hourWaiting: {
    color: theme.palette.warning.dark,
  },
  notSubmitted: {
    color: theme.palette.warning.dark,
  },

  ListItemSecondaryAction: {
    minWidth: "120px",
    textAlign: "left",
  },
}));

function EventStatus(props) {
  const classes = useStyles();

  const { event } = props;

  const auth = useAuth();



  //const isAdministrator = auth.user.role && auth.user.role === "auctioneer";


  //const isOwner = auth.user.id && auth.user.id === item.owner;
 

  // *************************************
  // USE MessagesItem.js as messaging base.
  // ***********************************

  //if (mode == 'view') {return (<></>)}



  if (event && isPastTime(event.endTime)) {
    return (
      <>
      
          Ended
       
      </>
    );
  }

  if (event && isPastTime(event.startTime)) {
    return (
      <>
     
            Running
         
      </>
    );
  }


  return (
    <>
      Not Started
    </>
  );
}

export default EventStatus;
