import React, { useEffect, useState } from "react";
import { useEvent, useProfile } from "./../util/db.js";
import { useAuth } from "../util/auth.js";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";

import { privateCatchallFlag } from "./../util/dev.js";

const useStyles = makeStyles((theme) => ({
  gridItem: {
    // Add border that contrasts lightly with background color.
    // We use boxShadow so that it's hidden around outer edge
    // due to container <Card> having overflow: hidden
    // boxShadow: `1px 1px 0 0 ${emphasize(theme.palette.background.paper, 0.08)}`,
    textAlign: "left",
  },
  imageContainer: {
    margin: "0 auto",
    maxWidth: "200px",
    marginBottom: "30px",
    "& img": {
      width: "100%",
    },
  },
  link: {
    textDecoration: "underline",
  },
}));

export default function EventName(props) {
  const { event } = props;
  const { format } = props;

const [actualEvent, setActualEvent] = useState();

const auth = useAuth();
const isDeveloper = auth?.user?.developer?.enabled;

  const classes = useStyles();

  const {
    data: actualEventData,
    status: actualEventStatus,
    error: actualEventError,
  } = useEvent(event);

  const {
    data: profileData,
    status: profileStatus,
    error: profileError,
  } = useProfile(actualEventData && actualEventData.owner);

  const {
    data: eventData,
    status: eventStatus,
    error: eventError,
  } = useEvent(actualEvent);
useEffect(() =>{

  console.log("EventName event", event)


}, [event])

useEffect(() =>{

  console.log("EventName actualEvent", actualEvent)


}, [actualEvent])

useEffect(() =>{

  console.log("EventName actualEventData", actualEventData)


}, [actualEventData])

useEffect(() =>{

  console.log("EventName eventData", eventData)


}, [eventData])

useEffect(() =>{
if (actualEventData == null) {return;}

console.log("EventName ActualEventData", actualEventData)

if (privateCatchallFlag === true && actualEventData.private) {
  if (profileData == null) {return;}
  console.log("EventData actual event private")
setActualEvent(profileData.event);
return;
}
console.log("EventData actual event not private event", event)
setActualEvent(event);

}, [actualEventData, profileData]);


  if (eventStatus !== "success") {
    return null;
  }



  if (eventData === null) {
    return "Auction not found.";
  }



  if (format === "html hash button") {
function handleButtonClick() {
    window.onbeforeunload = null;

       window.location.href ="/#" + eventData.slug;
}



return (
      <Button disableRipple  style={{ backgroundColor: 'transparent' }} onClick={()=>handleButtonClick()} >
      
        {eventData && eventData.name ? eventData.name : "Noname"}
    
      </Button>
    );
  }

  if (format === "text") {
    return <>{eventData && eventData.name ? eventData.name : "Noname"}</>;
  }

  if (format === "html hash link") {
    return (
      <Link to={"/#" + eventData.slug}>
        {eventData && eventData.name ? eventData.name : "Noname"}
      </Link>
    );
  }

  if (format === "html link") {
    return (
      <Link to={"/collection" + "/" + eventData.id}>

        {eventData && eventData.name ? eventData.name : "Noname"}
      </Link>
    );
  }

  return (
 <>
      {/* <Typography variant="body1">
        
                  {eventData && eventData.location ? eventData.location : "Location not found"}
                  </Typography> */}

      <Link to={"/collection" + "/" + eventData.id}>
        {eventData && eventData.name ? eventData.name : "Noname"}
      </Link>
      </>
  );
}
