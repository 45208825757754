import React from "react";
import {
  Typography,
  Container,
  Box,
  Divider,
  Grid,
  Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Home, ShoppingBasket, VerifiedUser } from "@material-ui/icons";
import HeroSectionCenter from "../components/HeroSectionCenter";
import Meta from "./../components/Meta";
import BidwellManifesto from "../components/BidwellManifesto";
import AboutSections from "../components/AboutSections";
import aboutbackground from "./../images/aboutbackground.jpg";
import lovedby from "./../images/lovedby.jpg"
import SellSteps from "../components/SellSteps";
import ApplySection from "../components/ApplySection";
import HeroSection3 from "../components/HeroSection3";
import { useScrollToTop } from "../util/db";

const useStyles = makeStyles((theme) => ({
  section: {
    marginBottom: theme.spacing(4),
  },
  icon: {
    fontSize: "3rem",
    marginBottom: theme.spacing(1),
  },
}));

const AboutPage = () => {
  const classes = useStyles();
  useScrollToTop();
  return (
    <>
      <Meta title="Seller Application" />

 
<HeroSection3

// bgColor="trade"
        size="medium"
        bgImage={aboutbackground}
        bgImageOpacity={1}
        title="A little bit about LadyBidwell"
        subtitle="Curated finds from the Hampton’s Finest Homes."
        // bgImageOpacity={1.2}
      />


<BidwellManifesto
         bgColor="dark"
         size="medium"
         bgImage=""
         bgImageOpacity={1}      
        //  image="https://cdn.pixabay.com/photo/2018/01/24/15/08/live-3104077_1280.jpg"
        flavour="about"
      />

<AboutSections
         bgColor="light"
         size="medium"
         bgImage=""
         bgImageOpacity={1}      
        //  image="https://cdn.pixabay.com/photo/2018/01/24/15/08/live-3104077_1280.jpg"
      />

<SellSteps flavour="about" />

{/* <Container maxWidth='lg'>
  <Box >
 <img src={lovedby} width="100%" />
 </Box>
</Container> */}


<ApplySection
   
        bgColor="dark"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
      />




    </>
  );
};

export default AboutPage;
