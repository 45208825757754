import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { Typography, Box, useTheme, useMediaQuery } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import DescriptionIcon from "@material-ui/icons/Description";
import EventIcon from "@material-ui/icons/Event";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import { humanTime } from "./../util/time.js";
import LaunchIcon from "@material-ui/icons/Launch";

const useStyles = makeStyles((theme) => ({
  // list: {
  //   width: '380px',
  //   [theme.breakpoints.down("xs")]: {
  //     width: '100vw'
  //   },
  // },
  endIcon:{
margin:0,
  },
  fullList: {
    width: "auto",
  },
  fontFamily: {
    fontFamily: '"Cormorant Garamond", "serif"',
    // fontWeight: 500,
  },

  toolbar: {
    justifyContent: "space-between",
  },
  title: {
    flexGrow: 1,
    textAlign: "center",
  },
  paper: {
    width: "380px",
    borderRadius: "15px 0px 0px 15px",
    [theme.breakpoints.down("xs")]: {
      borderRadius: "0px",
      width: "100vw",
    },
  },
}));

export default function InfoDrawer(props) {
  const classes = useStyles();
  const theme = useTheme();
  const { event } = props;
  //const { title, subtitle, pickupandshipping, review, startTime, endTime, event, location } = props.event;
  const isSmallOrLess = useMediaQuery(theme.breakpoints.up("md"));

  const [state, setState] = React.useState({
    // top: false,
    // left: false,
    // bottom: false,
    right: false,
  });

  const removeMd = require("remove-markdown");
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  useEffect(() => {
    console.log("event", event);
  }, [event]);

  return (
    <div>
      {["right"].map((anchor) => (
        <React.Fragment key={anchor}>
          {/* <Button
            // variant="outlined"
            onClick={toggleDrawer(anchor, true)}
            classes={{endIcon:classes.endIcon}}
            endIcon={<LaunchIcon />}
           
          > */}


<div style={{
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    cursor: "pointer", textTransform: "none"
}}
onClick={toggleDrawer(anchor, true)}
>



<Typography
color="textSecondary"
              variant="subtitle1"
 
            >More Info</Typography> &nbsp; <LaunchIcon />

</div>


            {/* <Typography
              variant="subtitle1"
              size='small'
              endIcon={<LaunchIcon />}
              style={{ cursor: "pointer", textTransform: "none" }}
            >
              More Info 
            </Typography> */}
          {/* </Button> */}

          <Drawer
            classes={{
              paper: classes.paper,
            }}
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            <Toolbar className={classes.toolbar}>
              <Typography className={classes.title}>Event Info</Typography>
              <IconButton onClick={toggleDrawer(anchor, false)}>
                <CloseIcon />
              </IconButton>
            </Toolbar>
            <Box pb={1}>
              <Divider />
            </Box>
{/* 
            <Box justifyContent="center" display="flex" pt={1}>
              <img height="130px" src={house} />
            </Box> */}

            <List>
              <ListItem>
                <ListItemIcon>
                  <DescriptionIcon />
                </ListItemIcon>
                <ListItemText
                  primary={removeMd(event?.name)}
                  secondary={event?.review}
                />
              </ListItem>

              <ListItem>
                <ListItemIcon>
                  <LocalShippingIcon />
                </ListItemIcon>
                <ListItemText primary="Location" secondary={event?.location} />
              </ListItem>

              <ListItem>
                <ListItemIcon>
                  <LocalShippingIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Pickup / Shipping"
                  secondary={event?.pickupandshipping}
                />
              </ListItem>

              {(event?.startTime || event?.endTime) && (
                <>
                  <ListItem>
                    <ListItemIcon>
                      <EventIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary="Real Life Time"
                      secondary={event?.review}
                    />
                  </ListItem>

                  <Box mt={1}>
                    <Box display="flex" pb={0.5}>
                      <Box flexGrow={1}>
                        <Typography
                          variant="body2"
                          style={{ paddingRight: "15px" }}
                        >
                          Starts:
                        </Typography>
                      </Box>

                      <Typography variant="body2">
                        {humanTime(event?.startTime)}
                      </Typography>
                    </Box>

                    <Box display="flex" pb={0.5}>
                      <Box flexGrow={1}>
                        <Typography
                          variant="body2"
                          style={{ paddingRight: "15px" }}
                        >
                          Ends:
                        </Typography>
                      </Box>
                      <Typography variant="body2">
                        {humanTime(event?.endTime)}
                      </Typography>
                    </Box>
                  </Box>
                </>
              )}
            </List>
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
