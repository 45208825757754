import React from "react";
import Section from "./Section";
import Container from "@material-ui/core/Container";
import SectionHeader from "./SectionHeader";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import EventList from "./EventList";
import { useRouter } from "./../util/router.js";
import { useAuth } from "./../util/auth.js";
import { makeStyles } from "@material-ui/core/styles";
import HeroSection2 from "./../components/HeroSection2";


const useStyles = makeStyles((theme) => ({
  cardContent: {
    padding: theme.spacing(3),
  },
  title: {
    color:'#ffffff'
  },
}));

function EventSection(props) {
  const classes = useStyles();

  const auth = useAuth()

  const router = useRouter();


  return (  
     <>

   

        {/* <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={3}
          textAlign="left"
          className={classes.title}
        /> */}


        <HeroSection2 
 title={props.title}
 subtitle={props.subtitle}
 bgColor={props.bgColor}
size={props.size}

/>
   



   <Container >
        <Grid container={true} spacing={4}>
          <Grid item={true} xs={12} md={12}>
      
            <EventList />
          </Grid>
          
        </Grid>
        </Container>
        </>
  );
}

export default EventSection;
