import { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import ItemInformation from "./../components/ItemInformation";
import { useEvent, useLotByItem } from "./../util/db.js";
import { loadState, saveState, starItem } from "./../util/db.js";
import { useAuth } from "./../util/auth.js";

function ItemPanel(props) {
  const auth = useAuth();
  const { item, mode } = props;
  function handleBid() {}

  const {
    data: event,
    status: eventStatus,
    error: eventError,
  } = useEvent(item.auction);

  const {
    data: lot,
    status: lotStatus,
    error: lotError,
  } = useLotByItem(item.id);

  const [readyStatus, setReadyStatus] = useState("not_ready");
 
  useEffect(() => {
    if (item === undefined) {
      return;
    }
    if (!(auth.user && auth.user.uid)) {
      return;
    }

    const localStar = loadState("star");
    if (localStar !== null) {
      starItem({ ...localStar, owner: auth.user.uid });

      saveState("star", null);
    }
  }, [item]);

  // function handleReady(e) {
  //   if (e === "ready") {
  //     setReadyStatus("ready");
  //   }
  // }

  // useEffect(() => {
  //   console.log("ready status use effect", readyStatus);
  // }, [readyStatus]);





  function handleReady(e) {
    setReadyStatus(e);
  }
  
  useEffect(() => {
    if (readyStatus === "ready") {
      console.log("ready status", readyStatus);
    } else {
      console.log("ready status", readyStatus);
    }
  }, [readyStatus]);


  const handleStatus = (e) => {
    console.log("ItemPanel e", e);
  };


  if (eventStatus !== "success" || lotStatus !== "success") {
    return <></>;
  }


  return (

    <>


          {item && (
            <Grid container>
              <Grid item xs={12}>
                {/* <Container> */}
                  <Typography
                    variant="h5"
                    style={{
                      textTransform: "uppercase",
                      fontWeight: "700",
                      // color: item.palette.darkMuted,
                      fontFamily: '"Cormorant Garamond", "serif"',
                    }}
                  >
                    {item.name}
                  </Typography>
                {/* </Container> */}
              </Grid>
            </Grid>
          )}

          <ItemInformation
            item={item}
            event={event}
            lot={lot}
            mode={mode}
            onStatus={(e) => handleReady(e)}
          />
      
   

    </>
  );




}

export default ItemPanel;
