import React, { useState, useEffect } from "react";
import {
  useMessagesByItemUser,
} from "../util/db";
import { useAuth } from "./../util/auth.js";



import { Typography, Box } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import { red } from "@material-ui/core/colors";
import CurrentMessage from "./CurrentMessage";
import MessagesDrawer from "./MessagesDrawer";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

export default function MessagesItem(props) {
  //const { itemId } = props;
  const { item } = props;
  const itemId = item.id;
  var { user } = props;

  const classes = useStyles();

  const auth = useAuth();

  const userID = user === undefined ? auth.user.id : user.id;

  const [expanded, setExpanded] = React.useState(false);
  const [filteredMessages, setFilteredMessages] = useState();
  const [firstLoad, setFirstLoad] = useState(true);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const { status: messagesStatus, data: messages } = useMessagesByItemUser(
    item,
    userID
  );

  useEffect(() => {
    console.log("MessagesItem Firing up messagesItem itemId", itemId);
  }, []);

  function areEqual(prevProps, nextProps) {
    return false;
    const isEqual = prevProps.messages.length === nextProps.messages.length;
    // true to skip render.
    // https://dev.to/kelvinvmwinuka/how-to-optimise-react-with-usememo-and-react-memo-33b8

    if (messagesStatus === "loading") {
      return true;
    }

    if (isEqual) {
    }

    return isEqual;
  }


  // const MessageCardsMemo = React.memo((props) => {
  //   return <MessageCards messages={props.messages} />;
  // }, areEqual);

  //const MessageCardsMemo = React.memo(MessageCards, true);

  // const MessageCards = (props) => {
  //   const { messages } = props;
  //   if (messages === null) {
  //     return null;
  //   }
  //   //const sortedMessages = messages.slice().sort((a, b) => b.date.compareTo(a.date));
  //   return (
  //     <>
  //       {messages.map((message, index) => (
  //         <>
  //           <MessageCard key={message.id} message={message} broadcast="room" />
  //         </>
  //       ))}
  //     </>
  //   );
  // };

  //return (<>{messages && (<MessageCards messages={messages} />)}</>);

  // const CurrentMessage = (props) => {
  //   const { messages, item } = props;
  //   // Do something to try and trigger renger
  //   if (item.id === null) {
  //   }
  //   if (messages === undefined) {
  //     return null;
  //   }
  //   if (messages.length === 0) {
  //     return <>No message found.</>;
  //   }

  //   const sortedMessages = messages.slice().sort(function (a, b) {
  //     return a.createdAt < b.createdAt;
  //     //        return age(a.createdAt) < age(b.createdAt);
  //     //return a.createdAt.seconds < b.createdAt.seconds;
  //   });

  //   console.log("messages", messages);
  //   //    return (<><MessageCard key={messages[0].id} message={messages[0]} broadcast="room" /></>);
  //   return (
  //     <>
  //       <MessageCard message={sortedMessages[0]} broadcast="room" />
  //     </>
  //   );

  // };
useEffect(()=>{
  if (!messages) {
    console.log("MessagesItem messages bang")
    return;}


  console.log("MessagesItem messages count", messages.length);
  console.log("MessagesItem messages", messages);
  const m = messages.filter((message)=>{
    if (message.from === 'system' && message.subject==='Price table updated.') {
      return false;
    }
return true;

  })

  console.log("MessagesItem filtered messages", m);

  setFilteredMessages(m);

}, [messages]);
  return (
    <>
<Box py={1} px={2} style={{border:"1px solid #d7d8da", backgroundColor:'#ffffff', height:'100%'}}>
  
      <Box >
        <Typography variant="subtitle1" gutterBottom style={{textDecoration:'underline'}}>
          Messages
        </Typography>
      </Box>



    
        {/*firstLoad && <>LOADING</>*/}
        <Box display='flex'>
        {filteredMessages && filteredMessages.length > 0 && <CurrentMessage messages={filteredMessages} />} 
        </Box>
        <Box textAlign="center">
<MessagesDrawer messages={filteredMessages} user={user} />

      </Box>
      </Box>
    </>
  );
}




        {/* <Box textAlign="right">
          {props.collapsed && (
            <>
              <IconButton
                className={clsx(classes.expand, {
                  [classes.expandOpen]: expanded,
                })}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
              >
                <ExpandMoreIcon />
              </IconButton>
            </>
          )}
        </Box>

        <div>
          {props.collapsed && (
            <>
              <Collapse in={expanded} timeout="auto" unmountOnExit>
                <Typography
                  style={{
                    fontWeight: "500",
                    marginBottom: "1em",
                    textAlign: "center",
                  }}
                  variant="subtitle1"
                >
                  Message History
                </Typography>
       

                {filteredMessages && filteredMessages.length > 0 && (
                  <MessageCards messages={filteredMessages} />
                )}
           
              </Collapse>
            </>
          )}
        </div> */}