import React from "react";
import OrderSummary from "./../components/OrderSummary";
import { useRouter } from "./../util/router.js";
import { useAuth } from "./../util/auth.js";
import { usePaymentIntent } from "../util/stripe.js";
import { Container } from "@material-ui/core";
import { requireAuth } from "./../util/auth.js";
import Meta from "./../components/Meta";


function OrderSummaryPage(props) {
  const router = useRouter();
  const endpointUrl = window.location.pathname; //href gives full url
  const auth = useAuth();
  const user = auth && auth.user && auth.user.uid ? auth.user.uid : null;

  //const router = useRouter();
  const orderId = router.query.orderId.replace("order_", "");

  const clientSecret = usePaymentIntent(user, null);
  const { timestamp } = props;

  const handleStatus = () => {};
  
  return (
    <>

<Meta 
title="Order Summary"
/>
      <Container>
        {/* {orderId} */}
        
        <OrderSummary orderId={orderId} />
      </Container>
   
    </>
  );
}

export default requireAuth(OrderSummaryPage);
