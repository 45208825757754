import Section from "./Section";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import LocationOnOutlined from "@material-ui/icons/LocationOnOutlined";
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
const removeMd = require("remove-markdown");
const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "250px",
    // Add bottom margin if element below
    "&:not(:last-child)": {
      marginBottom: "2rem",
    },
  },
  subtitle: {
    // Subtitle text generally isn't very long
    // so usually looks better to limit width.
    maxWidth: 700,
    // So we can have max-width but still
    // have alignment controlled by text-align.
    display: "inline-block",
  },

  fontFamily: {
    fontFamily: '"Cormorant Garamond", "serif"',
    fontWeight: 500,
  },

}));

function SellerDetailsSection(props) {
  const classes = useStyles();
  const { seller } = props;
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));
  const isSm = useMediaQuery(theme.breakpoints.up('sm'));
  const isMd = useMediaQuery(theme.breakpoints.up('md'));
  const isLg = useMediaQuery(theme.breakpoints.up('lg'));
  let imageHeight;

  if (isXs) {
    imageHeight = "300px";
  } else if (isSm) {
    imageHeight = "400px";
  } else if (isMd || isLg) {
    imageHeight = "500px";
  }
  return (


<>

<Box style={{ overflow: "hidden", position:'sticky', top:0, zIndex:0 }} >

  <img  
  src={seller?.profileImageUrl}
  className="object-fit"
  style={{
    objectFit: "cover",
    position: "relative",
    left: "-20%",
    height: imageHeight,
    width: "120%",
    overflow: "hidden",
  }} /> 
</Box>


    <Section
      style={{ backgroundColor: seller.colour + "aa" }}
      bgColor="light"
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
      {/* <img src="https://d7hftxdivxxvm.cloudfront.net?height=320&quality=80&resize_to=fill&src=https%3A%2F%2Fd32dm0rphc51dk.cloudfront.net%2F1Zg3-3fRhkllF0ia5KaPpQ%2Fwide.jpg&width=767" /> */}

        <Box textAlign="left">
          <Grid container spacing={1} className={classes.root}>
            <Grid item xs={12} sm={10}>
              <Typography  variant="h4" className={classes.fontFamily}>
                {removeMd(seller.name)}
              </Typography>

              <Typography variant="h6" gutterBottom>
              <div style={{
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
}}>
              {/* <LocationOnOutlined
                fontSize="medium"
                style={{ marginRight: "5px" }}
              /> */}
              {seller.location}
              </div>     
          </Typography>


            </Grid>
           
          

            {/* <Grid item xs={12} sm={10}>
              <Typography variant="h4" className={classes.fontFamily}>
                {removeMd(seller.contactName)}
              </Typography>
              
            </Grid>

            <Grid item xs={12} sm={10}>
              <Typography variant="h4" className={classes.fontFamily}>
                {removeMd(seller.contactNumber)}
              </Typography>
              
            </Grid>

            <Grid item xs={12} sm={10}>
              <Typography variant="h4" className={classes.fontFamily}>
                {removeMd(seller.profileEmail)}
              </Typography>
              
            </Grid>        
              */}


          
            {/* <ShareModal /> */}

          
              
            <Grid item xs={12} sm={10}>
            <Typography variant="h5" gutterBottom >
               About the seller
              </Typography>

              <Typography variant="subtitle1" color="textSecondary">
                {seller.headline}
              </Typography>
          </Grid>


{false && seller.profileTelephone && (
            <Grid item xs={12} sm={10}>
              <Typography variant="h6" style={{ fontWeight: "400" }}>
                {seller.sellerTelephone}
              </Typography>
          </Grid>)}

{false && seller.profileEmail && (
            <Grid item xs={12} sm={10}>
              <Typography variant="h6" style={{ fontWeight: "400" }}>
                {seller.sellerTelephone}
              </Typography>
          </Grid>)}

{false && seller.profileUrl && (
            <Grid item xs={12} sm={10}>
              <Typography variant="h6" style={{ fontWeight: "400" }}>
                {seller.sellerTelephone}
              </Typography>
          </Grid>)}





          {/* <Grid item xs={12} sm={10}>
        
              <Typography variant="h6">
              <div style={{
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
}}>
              <LocationOnOutlined
                fontSize="medium"
                style={{ marginRight: "5px" }}
              />
              {seller.location}
              </div>     
          </Typography>

            </Grid> */}
         </Grid> 
        </Box>
      </Container>
    </Section>
    </>
  );
}

export default SellerDetailsSection;
