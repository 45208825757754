import { useEffect, useState } from "react";
import { useRouter } from "./../util/router.js";
import { useAuth } from "./../util/auth.js";
import { useItem } from "./../util/db.js";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./../components/Section";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Carousel from "./../components/Carousel";
import { Link } from 'react-router-dom';
import { Grid, Button } from "@material-ui/core";
import { useItemImages, useAuctionByEvent } from "./../util/db.js";
import Box from "@material-ui/core/Box";
import ItemPanel from "./ItemPanel.js";
import EventName from "./EventName.js";
import { Skeleton } from "@material-ui/lab";
import brokenImage from "./../images/brokenImage.png";
import UserItemHeart from "./../components/UserItemHeart";
import ProfileInformation from "./ProfileInformation.js";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },

  cardContent: {
    padding: theme.spacing(3),
  },

  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
    background: "#f5f5f5",
  },

  carousel: {
    width: "100%",
    position: "sticky",
    top: "5rem",
    bottom: "5rem",
    zIndex: 99,
  },
}));

const defaultPalette = { lightVibrant: "#cccccc", darkMuted: "#000000" };

function ItemPageTopSection(props) {
  const classes = useStyles();
  const router = useRouter();
  const inputId = router.query.itemid;
  const id = inputId == null ? inputId : inputId.split('&')[0];

  const { event, mode } = props;

  const auth = useAuth();
  const shareUrl = window.location.href;

  const [loadingContext, setLoadingContext] = useState(true);
  const { data: item, status: itemStatus, error: itemError } = useItem(id);

  useEffect(() => {
    console.log("ItemPageTopSection id", id);
  }, [id]);

  const {
    data: auction,
    status: auctionStatus,
    error: auctionError,
   
  } = useAuctionByEvent(item.auction);

  useEffect(() => {
    console.log("ItemPageTopSection start");
  }, []);

  const isOwner = auth.user && auth.user.id && auth.user.id === item.owner;

  if (itemStatus === "success" && item && item.palette === undefined) {
    item.palette = defaultPalette;
  }

  let itemImages = item === undefined ? [] : item.images;

  const {
    data: imagesData,
    status: imagesStatus,
    error: imagesError,
  } = useItemImages(itemImages);

  useEffect(() => {
    console.log("ItemPageTopSection itemImages", itemImages);
  }, [itemImages]);

  useEffect(() => {
    if (imagesStatus !== "success") {
      return;
    }
    console.log('Images received by the component:', imagesData.images);
  }, [imagesData, imagesStatus]);

  function handleCarouselLoad() {
    setLoadingContext(false);
  }

  return (
    <div>
      {item === undefined ? <>No item found.</> : null}

      {item !== undefined ? (
        <>
          <Section
            style={{ backgroundColor: "white", paddingBottom:'0px' }}
            size={props.size}
            bgImage={props.bgImage}
            bgImageOpacity={props.bgImageOpacity}
          >
            <Container disableGutters={false}>
            <div style={{ display: "flex", justifyContent: "flex-end", alignItems:'center' }}>
              <Typography
                variant="h6"
                gutterBottom
                component={Link}
  to={"/seller/" + item?.owner}
                style={{
                  flexGrow: 1,
                  textDecoration: "underline",
                  fontFamily: '"Cormorant Garamond", "serif"',
                }}
              >
                {/* <EventName event={item.event} format={"html hash button"} /> */}
                {/* <EventName event={item.auction}  /> */}
                Seller: <ProfileInformation spice={["name"]} profile={{ id: item?.owner}} />

     


              </Typography>

              <UserItemHeart item={item} size="lg" />
           </div>
              <Grid
                container
                spacing={4}
                alignContent="center"
                // justify="center"
              >
                <Grid item xs={12} md={5}>
                  <Box className={classes.carousel} >
                    {isOwner &&
                      imagesStatus === "success" &&
                      imagesData &&
                      imagesData.images &&
                      imagesData.images.length === 0 && (
                        <Typography variant="h5">
                          <strong>No images </strong>
                          <p>Please add images to this item.</p>
                        </Typography>
                      )}

                    {!isOwner &&
                      imagesStatus === "success" &&
                      imagesData &&
                      imagesData.images &&
                      imagesData.images.length === 0 && (
                        <img width="100%" src={brokenImage} />
                      )}
                    {!isOwner &&
                      imagesStatus === "success" &&
                      imagesData &&
                      imagesData.images &&
                      imagesData.images.length !== 0 &&
                      loadingContext && (
                        <Skeleton variant="rect" height={500} />
                      )}

                    <div style={{ display: loadingContext ? "none" : "block" }}>
                      {imagesStatus === "success" &&
                        imagesData.images.length !== 0 && (
                          <>
                            <Carousel
                              images={imagesData.carouselImages}
                              onCarouselLoad={() => handleCarouselLoad()}
                            />
                          </>
                        )}
                    </div>
                  </Box>
                </Grid>
                <Grid item xs={12} md style={{paddingBottom:'0px'}}>
                  <ItemPanel item={item} mode={mode} />

                  {/* {item && event && (<ItemDetailsSection bgColor="light" item={item} event={event} />)} */}
                </Grid>
              </Grid>
            </Container>
          </Section>
        </>
      ) : null}
    </div>
  );
}

export default ItemPageTopSection;
