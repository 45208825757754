import React, { useState, useEffect } from "react";
import { useAuth } from "./../util/auth.js";
import {
  updateAuction,
  useAuctionByEvent,
  useLotsByAuction,
} from "../util/db.js";
// import firebase from "../util/firebase";
import {
  serverTimestamp,
} from "firebase/firestore";
import { firebaseApp } from "./../util/firebase";
import {
  getFirestore,
} from "firebase/firestore";

const firestore = getFirestore(firebaseApp);


//const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp;

export default function CollectionDetails(props) {
  const auth = useAuth();
  const isAuctioneer = auth.user.role && auth.user.role === "auctioneer";

  const [auctionState, setAuctionState] = useState(false);
  const [knockedDownCount, setKnockedDownCount] = useState(0);
  const { item } = props;
  const { event } = props;
  const { auction } = props;

  var auctionID = false;

  if (item && item.auction) {
    console.log("foo");
    auctionID = item.auction;
  }

  if (event && event.id) {
    auctionID = event.id;
  }
  if (auction && auction.id) {
    auctionID = auction.id;
  }

  const {
    data: auctionData,
    status: auctionStatus,
    error: auctionErrror,
  } = useAuctionByEvent(auctionID);

  useEffect(() => {
    if (auctionStatus !== "success") {
      return;
    }
    if (auctionData && auctionData.endedAt !== undefined) {
      setAuctionState(true);
      return;
    }
  }, [auctionData, auctionStatus]);

  const {
    data: collectionData,
    status: collectionStatus,
    error: collectionError,
  } = useLotsByAuction(auctionID);

  var isOwner = false;
  var isWinner = false;

  useEffect(() => {
    //console.log(collectionData, collectionStatus, collectionError);
    //if (collectionData.endedAt === false) {setAuctionState("not ended"); return;}
    //setAuctionState("ended");
    if (collectionStatus !== "success") {
      return;
    }
    handleCollectionClose();
  }, [collectionData, collectionStatus, collectionError]);

  function handleCollectionClose() {
console.log("CollectionDetails handleCollectionClose collectionData", collectionData);
    // Auction has already ended.
    if (
      collectionData &&
      collectionData.endedAt &&
      collectionData.endedAt !== false
    ) {
      return;
    }

    // If any of the lots are false (or undefined) then the auction is not complete
    var isAuctionEnded = true;
    var k = 0;

    collectionData.every(function (lot, index) {
      if (lot.knockedDownAt === false || lot.knockedDownAt === undefined) {
        isAuctionEnded = false;

        k += 1;
      }

      return true;
    });

    setKnockedDownCount(collectionData.length - k);
    

    if (isAuctionEnded === true) {
      updateAuction(auctionID, {
        endedAt: serverTimestamp(),
      })
        .then((result) => {
          console.log("CollectionDetails setKnockedDownCount", auctionID,  result);
        })
        .catch((error) => {
          console.error("CollectionDetails setKnockedDownCount error", error, auctionID);
        });
    }

    if (collectionData.length === 0) {isAuctionEnded = null;}

    setAuctionState(isAuctionEnded);

    //    updateLot(item.id, { endsAt: datestring });
  }

  //  if (auctionStatus !== "success") {
  //    return null;
  //  }

  if (collectionStatus !== "success") {
    //return (<>---{auctionID}---</>);
    //return (<>sadf{auctionID}</>);
    return (<>FOO</>);
  }

  if (auctionStatus !== "success") {
    return (<>BAR</>);
  }

  if (auctionID === false) {
    return (<>MERP</>);
  }

  if (!isAuctioneer) {
    return <div>Auction has ended: {collectionData.endedAt}</div>;
  }

  return (
    <>
      <div>
        <div>ID: {auctionID} </div>
        <div>
          Knockeddown: {knockedDownCount} of {collectionData.length}{" "}
          {collectionData.length === 1 ? "lot" : "lots"}
        </div>
        <div>Collection state: {auctionState === true && "Ended"}
        {auctionState === false && "Lots available"}
        {auctionState === null && "Not known"}
         </div>

        {/*     <Button disabled={auctionState} isonClick={handleCollectionClose} variant="outlined">
          Close Collection
        </Button>
  */}

      {/*  <InvoiceCollection collection={auctionID} /> */}

        {/*<div>
This button checks to see if all the lots have been knocked down.
If they have then it marks the Firebase auction as "endedAt". Once
an auction has an endedAt then the Invoice Collection button should become available.
</div>*/}
      </div>
    </>
  );
}
