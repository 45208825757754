import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useAuth } from "./../util/auth.js";
import { useForm } from "react-hook-form";
import {
  useBidsByItem,
  useBidsByItemOwner,
  useAuctionByEvent,
} from "./../util/db.js";
import { makeStyles } from "@material-ui/core/styles";
import { v4 as uuidv4 } from "uuid";
import { createBid } from "./../util/db.js";
import InputAdornment from "@material-ui/core/InputAdornment";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { Controller } from "react-hook-form";
import Select from "@material-ui/core/Select";
import clsx from "clsx";
import Card from "@material-ui/core/Card";
import Collapse from "@material-ui/core/Collapse";
import Typography from "@material-ui/core/Typography";


// import firebase from "../util/firebase";
import ReactTextTransition from "react-text-transition";
import SignInButton from "./../components/SignInButton";
// import { database } from "firebase-admin";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";

import SellerReserveEdit from "./SellerReserveEdit.js";
import { isPastTime } from "../util/time.js";
import { bidTable } from "../util/auction.js";
import PaymentIcon from "@material-ui/icons/Payment";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { getFunctions, httpsCallable } from "firebase/functions";

const functions = getFunctions();

const processStripeClientSecret = httpsCallable(
  functions,
  "processStripeClientSecret"
);
const processBid = httpsCallable(functions, "processBid");

const processPaymentItems = httpsCallable(
  functions,
  "processPaymentItems"
);


const useStyles = makeStyles((theme) => ({
  root: {
    border: "none",
    boxShadow: "none",
    background: "none",
    // maxWidth: 345,
    // display: "flex",
    // "& > * + *": {
    //   marginLeft: theme.spacing(2),
    // },
  },

  formControl: {
    background: "white",
    minWidth: 140,
  },

  content: {
    paddingLeft: "0px",
    paddingBottom: 24,
  },

  button: {
    fontWeight: "600",
    paddingTop: "15px",
    paddingBottom: "15px",
    marginTop: "10px",
    fontSize: "1.25em",
  },

  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: "theme.transitions.duration.shortest",
    }),
  },
  button: {
    fontWeight: 600,
    paddingTop: "15px",
    paddingBottom: "15px",
    marginTop: "10px",
  },

  // expandOpen: {
  //   transform: 'rotate(180deg)',
  // },
}));

function OffersItem(props) {
  const classes = useStyles();

  const auth = useAuth();

  const [itemId, setItemId] = useState(props.item.id);

  const { item, lot, bid } = props;



// On accept on offer.
// Issue knockdown.
// Issue charge card instruction to backend.

// On counteroffer.
// Create a price in "prices" valid for that user for N hours.
// A user can always buy an item immediately at an avaiable price.

function handleAccept() {
/*
exports.processPaymentItems = functions.https.onCall((data, context) => {
  const uid = context.auth.uid;
  const items = data.items;
  const paymentMethodId = data.paymentMethodId;
*/
// Get paymentMethodId from bid?
const data ={items:[item], paymentMethodId:"tbd"};

processPaymentItems(data);

}

return (<>OFFER
<br />
ACCEPT - This would have an Accept button.
<Button onClick={()=>handleAccept()} >ACCEPT</Button>

<br />
COUNTER - And a CounterOffer button.
<br />
</>)

}

export default OffersItem;
