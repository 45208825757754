import React from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import { emphasize } from "@material-ui/core/styles/colorManipulator";

//import FirebaseStorageThumbnail from "./../components/FirebaseStorageThumbnail";
// import {FirebaseStorageThumbnailAdvanced as FirebaseStorageThumbnail} from "./../components/FirebaseStorageThumbnailAdvanced";
import FirebaseStorageThumbnailAdvanced from "../components/FirebaseStorageThumbnailAdvanced";

// Backend renders 220x220 thumbnail (maxwidth or maxheight)

const useStyles = makeStyles((theme) => ({
  gridItem: {
    // Add border that contrasts lightly with background color.
    // We use boxShadow  so that it's hidden around outer edge
    // due to container <Card> having overflow: hidden
    boxShadow: `1px 1px 0 0 ${emphasize(theme.palette.background.paper, 0.08)}`,
    textAlign: "center",
  },

  stripImageContainer: {
    margin: "0 auto",

    "& img": {
      // width: "100%",
      width: "80px",
      maxHeight: "125px",
      maxWidth: "100px",
    },
  },

  cartImageContainer: {
    // margin: "0 auto",
    // height:'200px',
    width: "100%",
    "& img": {
      width:'100%',
      maxHeight: '150px',
      objectFit:'contain',
      // width: "100%",
      // height: "auto",
      // height:"75px",  
    },
  },

  cardImageContainer: {
    margin: "0 auto",
    height: "120px",
    display:'flex',
    [theme.breakpoints.down("xs")]: {
      height: "110px",
    },
    overflow: "hidden",
    "& img": {
      maxHeight: "100%",
      maxWidth: "100%",
      height: "auto",
      width: "auto",
      objectFit: "contain",
    },
  },
}));

function ItemThumbnail(props) {
  // const storageRef = firebase.storage().ref();

  const classes = useStyles();
  const { item, flavour } = props;

  let images = item.images === undefined ? [] : item.images;

  let image = images[0] === undefined ? { location: "" } : images[0];

  let location = image.location === undefined ? "" : image.location;
  let path = location.split("://")[1];

  let parts = path === undefined ? ["", "", ""] : path.split("/");
  let imageBucket = parts[2];
  let imageFile = parts[3];

  let thumbPath = "images/items/" + imageBucket + "/thumb_" + imageFile;

 // const { data: imageUrl, status, error } = useDownloadURL(thumbPath);

const handleStatus = (e) =>{

}



  if (flavour === "cart") {
    return (
      <>


        <Box className={classes.cartImageContainer}>
          <FirebaseStorageThumbnailAdvanced allowDelete={false} image={image} onStatus={(e)=>handleStatus(e)}/>
        </Box>
      </>
    );
  }

  if (flavour === "strip") {
    return (
      <>
        <Box className={classes.stripImageContainer}>
        
          <FirebaseStorageThumbnailAdvanced allowDelete={false} image={image}  onStatus={(e)=>handleStatus(e)}/>
        </Box>
      </>
    );
  }

  return (
    <>
      {/* <Box className={classes.imageContainer} > */}


      <Box className={classes.cardImageContainer}>

      <FirebaseStorageThumbnailAdvanced allowDelete={false} image={image} onStatus={(e)=>handleStatus(e)} /> 
  
      </Box>   
    </>
  );
}

export default ItemThumbnail;
