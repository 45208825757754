import React, { useEffect } from "react";
import { useProfile } from "./../util/db.js";
// import ProfileItemList from '../components/ProfileItemList';

import SellerInfoDrawer from "./SellerInfoDrawer";

function ProfileInformation(props) {
  const id = props.profile.id;
  const {spice} = props;
  //const seller = {id:prilfe; d
  //const seller = props.profile;
  const {
    data: profileData,
    status: profileStatus,
    error: profileError,
  } = useProfile(id);


useEffect(() =>{

console.log("profileData", profileData);

}, [profileData]);


  return (
    <>



      {profileStatus === "loading" && (
        <>
          <span>&nbsp;&nbsp;</span>
        </>
      ) }
      {profileStatus === "success" && profileData !== null && (
        <>



{spice && spice.includes("name") && (
  <>{profileData?.name}</>
)}


  {/* <Grid item component='span' style={{ display: "flex" }}> */}
   
    
  {spice && spice.includes("drawer") && (
            <SellerInfoDrawer profileData={profileData} flavour="bottom" />
  )}
            
          {/* </Grid> */}
        
        </>
      )}

      {profileStatus === "error" && (
        <>No profile retrieved. Please try to refresh your browser.</>
      ) }

      {profileStatus === "success" && profileData === null && <></>}
    </>
  );
}

export default ProfileInformation;
