import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useAuth } from "./../util/auth.js";
import { useForm } from "react-hook-form";


function SettingsGeneral(props) {
  const auth = useAuth();
  const [pending, setPending] = useState(false);

  const { register, handleSubmit, errors, reset, getValues } = useForm();

const isSeller = auth.user && auth.user.seller !== undefined;


  const onSubmit = (data) => {
    // Show pending indicator
    setPending(true);

    return auth
      .updateProfile(data)
      .then(() => {
        // Set success status
        props.onStatus({
          type: "success",
          message: "Your profile has been updated",
        });
      })
      .catch((error) => {
        if (error.code === "auth/requires-recent-login") {
          props.onStatus({
            type: "requires-recent-login",
            // Resubmit after reauth flow
            callback: () => onSubmit(data),
          });
        } else {
          // Set error status
          props.onStatus({
            type: "error",
            message: error.message,
          });
        }
      })
      .finally(() => {
        // Hide pending indicator
        setPending(false);
      });


      
  };

useEffect(() =>{

console.log("SettingsGeneral auth.user", auth.user);

},[auth && auth.user]);


  

  return (
<>


    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container={true} spacing={2}>
        <Grid item={true} xs={12}>
          {/* <TextField
            variant="outlined"
            type="text"
            label="First and Last name"
            name="firstLastName"
            placeholder="Eleanor Rigby"
            //defaultValue={auth.user && auth.user.firstLastName ? auth.user.firstLastName : ''}

            defaultValue={auth.user.name}
            error={errors.firstLastName ? true : false}
            helperText={errors.firstLastName && errors.firstLastName.message}
            fullWidth={true}
            inputRef={register({
              required: "Please enter your first and last name",
            })}
          /> */}

<TextField
 variant="outlined"
        type="text"
        label="Name"
        name="name"
        placeholder="Name"
        defaultValue={auth.user.name}
        error={errors.name ? true : false}
        helperText={errors.name && errors.name.message}
        fullWidth={true}
        inputRef={register({
          required: "Please enter your name",
            })}
          />





        </Grid>
        <Grid item={true} xs={12}>
          <TextField
            variant="outlined"
            type="email"
            label="Email"
            name="email"
            placeholder="user@example.com"
            defaultValue={auth.user && auth.user.email ? auth.user.email : 'user@example.com'}
            error={errors.email ? true : false}
            helperText={errors.email && errors.email.message}
            fullWidth={true}
            inputRef={register({
              required: "Please enter your email",
            })}
          />
        </Grid>
{/*isSeller && (<>
        Seller profile
        <Grid item={true} xs={12}>
          <TextField
            variant="outlined"
            type="profileEmail"
            label="Profile Email"
            name="profileEmail"
            placeholder="user@example.com"
            defaultValue={auth.user && auth.user.email ? auth.user.email : 'user@example.com'}
            error={errors.profileEmail ? true : false}
            helperText={errors.profileEmail && errors.profileEmail.message}
            fullWidth={true}
            inputRef={register({
//              required: "Please enter your email",
            })}
          />
        </Grid>


        <Grid item={true} xs={12}>
          <TextField
            variant="outlined"
            type="profileTelephone"
            label="Profile Telephone"
            name="profileTelephone"
            placeholder="user@example.com"
            defaultValue={auth.user && auth.user.profileTelephone ? auth.user.profileTelephone : '111 555-5555'}
            error={errors.profileTelephone ? true : false}
            helperText={errors.profileTelephone && errors.profileTelephone.message}
            fullWidth={true}
            inputRef={register({
//              required: "Please enter a contact telephone/",
            })}
          />
        </Grid>


        <Grid item={true} xs={12}>
          <TextField
            variant="outlined"
            type="profileUrl"
            label="Profile Url"
            name="profileUrl"
            placeholder="user@example.com"
            defaultValue={auth.user && auth.user.profileUrl ? auth.user.profileUrl : 'https://ladybidwell.com/seller/' + auth.user.id}
            error={errors.profileUrl ? true : false}
            helperText={errors.profileUrl && errors.profileUrl.message}
            fullWidth={true}
            inputRef={register({
//              required: "Please enter a contact telephone/",
            })}
          />
        </Grid>


          </>)*/}







        <Grid item={true} xs={12}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            type="submit"
            disabled={pending}
            fullWidth={true}
          >
            {!pending && <span>Save</span>}

            {pending && <CircularProgress size={28} />}
          </Button>
        </Grid>




    

      </Grid>
    </form>

{auth && auth.user && auth.user.shortid ? auth.user.shortid : auth.user.uid}



</>
  );
}

export default SettingsGeneral;
