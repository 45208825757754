import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  Grid,
  Dialog,
  DialogContentText,
  DialogTitle,
  DialogContent,
  TextField,
  FormControlLabel,
  Switch,
  Checkbox,
  Button,

} from "@material-ui/core";
import { Link } from "./../util/router.js";
import { useItemImages } from "../util/db";
import ItemCardHorizontal from "./ItemCardHorizontal";
import ItemCard from "./ItemCard";
import ProfileInformation from "./ProfileInformation";
import { createMessage, useFirebaseStorageThumbURL } from "../util/db";
import { useAuth } from "./../util/auth.js";
import { useProfile } from "../util/db";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

const EmailSellerModal = (props) => {
  const { item } = props;
  const auth = useAuth();
  const [open, setOpen] = useState(false);
  const [shipping, setShipping] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [hold, setHold] = useState(false);
  const [emailError, setEmailError] = useState("");

  const [formState, setFormState] = useState({
    firstName: "",
    lastName: "",
    message: "",
    address: "",
    city: "",
    country: "",
    zipCode: "",
  });


  
  //  const testUrl = "/snowfallthumb.png";
  const lb_path =
    item && item.images && item.images.length > 0
      ? item.images[0].location
      : "";
  const {
    data: imageUrl,
    status,
    error: imageError,
  } = useFirebaseStorageThumbURL(lb_path);

  useEffect(() => {
    if (open) {
      setShipping(false);
      setHold(false);
    }
  }, [open]);

  const handleOpen = () => {
    setOpen(true);
  };

  const {
    data: profileData,
    status: profileStatus,
    error: profileError,
  } = useProfile(item.owner);

  // const handleOpen = () => {
  //   setOpen(true);
  // };

  const handleClose = () => {
    setOpen(false);
  };

  const handleInputChange = (event) => {
    setFormState({
      ...formState,
      [event.target.name]: event.target.value,
    });
  };

  const handleToggleShipping = () => {
    setShipping(!shipping);
  };

  const handleToggleHold = () => {
    setHold(!hold);
  };

  const handleSubmit = async (data) => {
    event.preventDefault();


  // Regular expression for email validation
  const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

  // Validate email
  if (!formState.email || !emailPattern.test(formState.email)) {
    setEmailError("Please enter a valid email address.");
    return; // return early from handleSubmit
  } else {
    setEmailError(""); // Clear the error if validation is successful
  }


    try {
      // Define the data for the SendinBlue API request
      const emailData = {
        sender: { email: "noreply@ladybidwell.com", name: "LadyBidwell" },
        to: [{ email: profileData?.profileEmail }],
        templateId: 8, // Replace with your actual template ID
        params: {
          FIRSTNAME: formState.firstName,
          LASTNAME: formState.lastName,
          MESSAGE: formState.message,
          EMAIL: formState.email,
          // IMAGE_URL: "https://ladybidwell.com/" +  imageUrl,
          IMAGE_URL: imageUrl,
          // Add other fields as needed
          ITEM_NAME: item.name,
          SHIPPING: shipping
            ? `Address: ${formState.address}, ${formState.city}, ${formState.country}, ${formState.zipCode}`
            : "No shipping requested",
          HOLD: hold
            ? "Please could you put the item on on hold"
            : "No hold requested",
        },
      };

      // Send the email through SendinBlue
      // Send the email through SendinBlue
      try {
        await axios.post(
          "https://api.sendinblue.com/v3/smtp/email",
          emailData,
          {
            headers: {
              "Content-Type": "application/json",
              "api-key": process.env.REACT_APP_SEND_IN_BLUE_KEY,
            },
          }
        );
      } catch (emailError) {
        console.error("Error sending email: ", emailError);
        setErrorMessage(emailError.response.data.message);
        return; // return early from handleSubmit
      }

      // Add the sender to SendinBlue contacts
      const contactData = {
        email: formState.email,
        attributes: {
          FIRSTNAME: formState.firstName,
          LASTNAME: formState.lastName,
          // Add other attributes as needed
        },
        // Replace with your actual list ID
        listIds: [5],
      };

      try {
        await axios.post(
          "https://api.sendinblue.com/v3/contacts",
          contactData,
          {
            headers: {
              "Content-Type": "application/json",
              "api-key": process.env.REACT_APP_SEND_IN_BLUE_KEY,
            },
          }
        );
      } catch (contactError) {
        console.error("Error adding contact: ", contactError);
        setErrorMessage(contactError.response.data.message);
        return; // return early from handleSubmit
      }

      setOpen(false);
      // If everything went well, show a success message
      toast("Your message has been sent!", { toastId: "sellerEmailId" });
    } catch (error) {
      // If there was a higher-level error, show the error message
      console.error("Unexpected error in handleSubmit: ", error);
      setErrorMessage(error.message);
    }
  };

  return (
    <div>
      <Button
        size="large"
        variant="contained"
        fullWidth
        color="primary"
        onClick={handleOpen}
      >
        <Typography variant="subtitle1" style={{ color: "#ffffff" }}>
          Contact the Seller
        </Typography>
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Email Seller</DialogTitle>

        <DialogContent>
          Seller:{" "}
          <ProfileInformation spice={["name"]} profile={{ id: item?.owner }} />
          {/* { profileData?.profileEmail } */}
          {/* {imageUrl} */}
          <ItemCardHorizontal item={item} />
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  fullWidth
                  name="firstName"
                  label="First Name"
                  onChange={handleInputChange}
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  fullWidth
                  name="lastName"
                  label="Last Name"
                  onChange={handleInputChange}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  name="email"
                  label="Email Address"
                  onChange={handleInputChange}
                  required
                  error={!!emailError} // Display an error if the emailError state is not empty
                  helperText={emailError} // Show the email error message
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="message"
                  label="Message"
                  multiline
                  rows={4}
                  onChange={handleInputChange}
                  required
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  //   control={<Switch checked={shipping} onChange={handleToggleShipping} />}
                  control={
                    <Checkbox
                      checked={shipping}
                      onChange={handleToggleShipping}
                    />
                  }
                  label="Request Shipping"
                />
              </Grid>

              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox checked={hold} onChange={handleToggleHold} />
                  }
                  label="Request to put the item on hold"
                />
              </Grid>
              {shipping && (
                <>
                  <Grid item>
                    <TextField
                      fullWidth
                      name="address"
                      label="Shipping Address"
                      onChange={handleInputChange}
                      required
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      fullWidth
                      name="city"
                      label="City"
                      onChange={handleInputChange}
                      required
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      fullWidth
                      name="country"
                      label="Country"
                      onChange={handleInputChange}
                      required
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      fullWidth
                      name="zipCode"
                      label="Zip Code"
                      onChange={handleInputChange}
                      required
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12}>
                <Box py={2}>
                  <Button
                    fullWidth
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Submit
                  </Button>
                </Box>

                <Box textAlign="center" py={2}>
            <Typography color="textSecondary" variant="body2">
              By making an enquiry on LadyBidwell, you agree to the{" "}
              <Box component={Link} to={"/legal/terms-of-service"}>
                {" "}
                Terms of Service{" "},
              </Box>{" "}
              <Box component={Link} to={"/legal/privacy-policy"}>
                {" "}
                Privacy Policy,{" "}
              </Box>{" "}
              and to receive promotional emails.
            </Typography>
          </Box>



              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default EmailSellerModal;
