import React from "react";
import { useAuth } from "./../util/auth.js";
import { updateEvent } from "./../util/db.js";
import { Button, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  button: {
    "&:disabled": {
      backgroundColor: theme.palette.success.main,
      color:'#ffffff',
  },
},
}));


export default function PublishEvent(props) {

  const classes = useStyles();
  const {event} = props;

  const auth = useAuth();

  const isAuctioneer = auth.user.role && auth.user.role === "auctioneer";
  const canUsePublish = isAuctioneer;
  const publishEvent = (event) => {
    if (canUsePublish) {
      updateEvent(event.id, { published: !event.published });
    } else {
      alert("You must be the Auctioneer to use this feature");
    }
  };

  return (<>


{isAuctioneer && event && event.published && (<>
    {/* <Switch
      checked={event.published}
      onChange={() => {
        publishEvent(event);
      }}
      name="checkedA"
      inputProps={{ "aria-label": "secondary checkbox" }}
    />

    {event && event.published ? (<>Published</>) : (<>Not published</>)} */}
<Grid item>
    <Button
    // disabled={!canBuy}
    // disabled={event.published}
    onClick={() => {
      publishEvent(event);
    }}
    //       onClick={() => addItem({ ...conditionedItem })}
    variant="outlined"
    // style={{ backgroundColor: "black", color: "white" }}
    // className={classes.button}
   
    elevation={0}

  >
Unpublish
{/* {event && event.published ? (<>Unpublish</>) : (<></>)} */}

{/* {!isCopied ? "Add To Cart" : "Added to Cart"} */}
    {/* <span>{isCopied ? 'Added To Cart' :   'Add to Cart'}</span> */}
  </Button>
  </Grid>

    
</>
  )}


{isAuctioneer && (<>
    {/* <Switch
      checked={event.published}
      onChange={() => {
        publishEvent(event);
      }}
      name="checkedA"
      inputProps={{ "aria-label": "secondary checkbox" }}
    />

    {event && event.published ? (<>Published</>) : (<>Not published</>)} */}
<Grid item>
    <Button
  
    disabled={event.published}
    disableElevation
    className={classes.button}
    onClick={() => {
      publishEvent(event);
    }}

    variant="outlined"

    elevation={0}

  >

{event && event.published ? (<>Sale Published</>) : (<>Publish Sale</>)}

{/* {!isCopied ? "Add To Cart" : "Added to Cart"} */}
    {/* <span>{isCopied ? 'Added To Cart' :   'Add to Cart'}</span> */}
  </Button>
  </Grid>

    
</>
  )}

</>
  )

    }



