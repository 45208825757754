import { useState, useEffect } from "react";
import { useAuth } from "./../util/auth.js";
import { getInvoicesByCustomer } from "../util/stripe";
import OrderInformation from "./OrderInformation.js";
import ListSkeleton from "./ListSkeleton.js";
import { Typography, Card, CardContent } from "@material-ui/core";

// The handleClick triggers a re-render.
// handleExpand doesn't.

function ItemsListOrder(props) {
  const { filter } = props;

  const [expandedInvoices, setExpandedInvoices] = useState([]);

  const auth = useAuth();

  const customerId =
    auth.user && auth.user.payment && auth.user.payment.customer;


  const [groups, setGroups] = useState();
  const [groupsStatus, setGroupsStatus] = useState();

  // Using click to explore React re-render triggering.
  // This works just fine as expected.

  const [click, setClick] = useState(false);

  function handleClick() {
    setClick(!click);
  }

  useEffect(() => {
    console.log("ItemsListOrder start");
    console.log("ItemsListOrder customerId", customerId)
    getInvoicesByCustomer(customerId).then(function (result) {
      console.log("ItemsListOrder result", result);
      var invoices = [];
      if (filter === "payment_draft_open") {
        invoices = result.data.data.filter(
          (invoice) => invoice.status === "open" || invoice.status === "draft"
        );
      }
      // if (filter === "payment_paid") {
      //   invoices = result.data.data.filter(
      //     (invoice) => invoice.status === "paid"
      //   );
      // }
      if (filter === "orders") {
        invoices = result.data.data.filter(
          (invoice) => invoice.status === "paid"
        );
      }

      console.log("ItemsListOrder invoices", invoices);

      const g = invoices
        .map((invoice) => {
          console.log("invoice lines data", invoice.lines.data);
          const items = invoice.lines.data.map((line) => {
            return { id: line.metadata.item };
          });
          return { invoice: invoice, items: items };
        })
      


      console.log("ItemsListOrder bar");

      setGroups(g);
      setGroupsStatus("success");
    })
    .catch((error) => {
      console.log("ItemsListOrder error", error);
      setGroups([]);
      setGroupsStatus("success")
    });
  }, []);

  // Add the invoice id to an an array.
  // If it is in the array than hide it from display (exapnd/collapse)
  function handleExpand(index) {
    const tempArray = expandedInvoices;
    if (tempArray.includes(index)) {
      const i = tempArray.indexOf(index);
      if (i > -1) {
        tempArray.splice(i, 1);
      }
    } else {
      tempArray.push(index);
    }
    setExpandedInvoices(tempArray);

    console.log("setExpandedInvoices", expandedInvoices);
  }

  if (groupsStatus !== "success") {
    return (
      <>
        <ListSkeleton listsToRender={8} />
      </>
    );
  }

  // 4th option found invoices from Stripe. Here they are.
  return (
    <>
      {/*     <Button onClick={handleClick}>{click ? "ON" : "OFF"}</Button> */}
{groups && groups.length === 0 && (
  <>
  <Card variant="outlined" style={{height:'80vh'}}>
  <CardContent>
    <Typography variant="h6"> No orders placed yet.</Typography>
  </CardContent>
</Card>
  </>
  
  
  )}
      {groups && groups.length !== 0 && groups.map((group, index) => {
        // Testing here making the key unique based on id and whether it is in the expand list.
        // This doesn't seem to trigger the re-render either.
        const tempKey =
          group.invoice.id + expandedInvoices.includes(group.invoice.id);
        console.log("tempKey", tempKey);
        return <OrderInformation key={tempKey} group={group} />;
      })}
    </>
  );
}

export default ItemsListOrder;
