import React, { useState, useEffect } from "react";
import { useRouter } from "./../util/router.js";
import Alert from "@material-ui/lab/Alert";
import { useAuth } from "./../util/auth.js";
import {
  makeStyles,
  Typography,
} from "@material-ui/core";
import { Link } from "./../util/router";
import { useAuctionsByFeatured, useProfile } from "../util/db";
import { secondsToReadableString } from "../util/time.js";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";

const useStyles = makeStyles((theme) => ({
  alert: {
    // Let <Section> handle colors
   
    color: "#ffffff",
    borderRadius: 0,
  },

  message: {
    width: "100%",
    textAlign: "center",
  },
}));

function SellerAnnouncement(props) {
  const router = useRouter();
  const path = router.pathname;
  const { flavour } = props;
  //const [showAnnouncement, setShowAnnouncement] = useState(false);
  const auth = useAuth();
  const {
    data: auctions,
    status: auctionsStatus,
    error,
  } = useAuctionsByFeatured();


/*
  const {
    data: sellerAccountData,
    status: sellerAccountStatus,
    error: sellerAccountError,
  } = useSellerAccountStatusByUser(auth?.user?.id);
*/
  const { 
    data: profileData,
    status: profileStatus,
    error: profileError
  } = useProfile(auth?.user?.id);
  
  
  // const isAvailable = !(profileData && profileData.charges_enabled == null) ||
  // (profileData && profileData.charges_enabled && profileData.charges_enabled === true);
  
  const isSellerAccountAvailable = 
  profileData?.charges_enabled && profileData.charges_enabled === true;

  useEffect(() => {

    console.log("SellerAnnouncement", auth?.user?.id, profileData, profileStatus, isSellerAccountAvailable)
    
    }, [profileData, profileStatus, isSellerAccountAvailable])

/*
useEffect(() => {

console.log("SellerAnnouncement", auth?.user?.id, sellerAccountData, sellerAccountStatus)

}, [sellerAccountData, sellerAccountStatus])
*/


  useEffect(() => {
    if (!auctions) {
      return;
    }
    if (auctions.length === 0) {
      return;
    }

    var s = path === "/";
    if (s) {
      setOpen(true);
      return;
    }

    s = path.startsWith("/item/");
    if (s) {
      setOpen(true);
      return;
    }

    setOpen(false);
  }, [path, auctions]);

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const {
    onClose,
    bgColor,
    textColor,
    text,
    linkPath,
    linkText,
    ...otherProps
  } = props;

  const [duration, setDuration] = useState();



  function getAge() {
    // Pass zulu timestamps in.
    var startDatetime = new Date(Date.now());
    const dropDatetime = new Date(startDatetime.toISOString());

    //https://stackoverflow.com/questions/1090815/how-to-clone-a-date-object

    const announcement = { text: "Some text to be announced", runAt: "21:00" };

    const parts = announcement.runAt.split(":");

    var dropTimeHours = parts[0];
    var dropTimeMinutes = parts[1];

    dropDatetime.setUTCHours(dropTimeHours);
    dropDatetime.setUTCMinutes(dropTimeMinutes);

    var diff = startDatetime - dropDatetime;

    if (diff > 0) {
      diff = 24 * 60 * 60 * 1000 - diff;
    }

    const togo = secondsToReadableString(diff / 1000, true);
    return togo;
  }

  // const [index, setIndex] = useState(0);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setIndex(index + 1);
  //   }, 3000);
  //   return () => clearInterval(interval);
  // });

  // const loopArr = [
  //   "Next Price Drop " + duration,
  //   "The Best Items from This Week's Hamptons Estate Sales.",
  // ];

  // const announcements = [{announcement:"[Ttst]"}, { announcement: "Next Price Drop - " + duration }, { announcement: "The Finest Items from This Week's Hamptons Estate Sales and Shops." } ];                                                                                                                                 uncements = [{announcement:'<>Ttst</>'},{ announcement: "Next Price Drop - " + duration }, { announcement: "The Finest Items from This Week's Hamptons Estate Sales and Shops." } ];

  // const announcements = [{ announcement: "Next Price Drop - " + duration }, { announcement: "The Finest Items from This Week's Hamptons Estate Sales and Shops." } ];                                                                                                                                 uncements = [{announcement:'<>Ttst</>'},{ announcement: "Next Price Drop - " + duration }, { announcement: "The Finest Items from This Week's Hamptons Estate Sales and Shops." } ];

  // const announcements = [{ announcement: `Next Price Drop in ${duration}` }, { announcement: "**Curated Finds from Hamptons Estate Sales, Private Sellers and Shops.**" }, { announcement: "**Declining Price Auction on Every Item for 7 Days. [Learn More.](https://ladybidwell/learnmore)** " }];

  const announcements = [
    { announcement: `Next Price Drop in ${duration}` },
    {
      announcement:
        "Curated Finds from Estate Sales, Private Sellers and Shops.",
    },
    {
      announcement:
        "Price drops on every item for 7 Days. [Learn More.](https://ladybidwell/learnmore) ",
    },
  ];

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  const [currentAnnouncementIndex, setCurrentAnnouncementIndex] =
    React.useState(0);

  React.useEffect(() => {
    const id = setTimeout(
      () =>
        setCurrentAnnouncementIndex(
          (currentAnnouncementIndex + 1) % announcements.length
        ),
      6000
    );
    return () => {
      clearInterval(id); // removes React warning when gets unmounted
    };
  }, [currentAnnouncementIndex]);
  if (profileStatus !== 'success') {

//if (sellerAccountStatus !== 'success') {
  return null;}
//if (sellerAccountData === false) {
//  if (true) {
 if (isSellerAccountAvailable !== true) {
  return (
  


<>
    {/* <Collapse in={open}> */}
      {/* <Section bgColor={bgColor} size="auto" {...otherProps}  > */}
      <Alert
      severity="error"
        variant="filled"
        icon={false}
        onClose={onClose}
        classes={{
          root: classes.alert,
          message: classes.message,
        }}
      >



        <Typography
          variant="subtitle1"
          style={{ color: "#ffffffe6", fontWeight: "400" }}
        >
Complete / Update billing details to have items accepted into sale. <Link style={{textDecoration:'underline'}} to="/settings/billing">Go to settings. </Link>     
        </Typography>

      </Alert>

</>
  
  );
      }

      return null;
}

export default SellerAnnouncement;
