import React, { useState, useEffect, useRef } from "react";
import Grid from "@material-ui/core/Grid";
import { TextField, Button, Container } from "@material-ui/core";
import Section from "./Section.js";
import { useAuth } from "./../util/auth.js";
import { useForm } from "react-hook-form";
import { updateProfile } from "../util/db.js";

import { firebaseTime } from "../util/time.js";

//import SellerBadge from "./../components/SellerBadge";
import { Typography, InputAdornment, Divider } from "@material-ui/core";

import ItemIdCard from "./../components/ItemIdCard";

import { getSlug } from "./../util/slug.js";

import { toast } from "react-toastify";

import { useLotsByAffiliate, createAffiliate } from "./../util/db.js";

import { updateUser } from "./../util/db.js";

// Useful. Appropriate?
import { forceCheck } from "react-lazyload";

import { getFunctions, httpsCallable } from "firebase/functions";
const functions = getFunctions();

// For testing.
// This triggers a backend function which will be called periodically
// to update the affiliated sales based on if the sale has been recognized
// after x hours/days.

// Need this here to test that.




const processAffiliationsTest = httpsCallable(functions, "processAffiliations"); // Find affiliate for a sale

function SettingsAffiliate(props) {
  const auth = useAuth();

  const [createAffiliateMessage, setCreateAffiliateMessage] = useState();
  const [createPaypalEmailMessage, setCreatePaypalEmailMessage] = useState();

  const [processAffiliationsTestMessage, setProcessAffiliationsTestMessage] =
    useState();

  const [email, setEmail] = useState("");
  const [copyStatus, setCopyStatus] = useState("Copy");
  const textFieldRef = useRef(null);

  const [affiliations, setAffiliations] = useState();
  const [affiliationsMessage, setAffiliationsMessage] = useState();

  

  const handleCopy = () => {
    if (typeof auth?.user?.affiliate?.affiliateCode !== "undefined") {
      //    if (textFieldRef && textFieldRef.current) {
      //      const textFieldValue = textFieldRef.current.value;

      const textFieldValue = auth?.user?.affiliate?.affiliateCode;

      navigator.clipboard.writeText(textFieldValue);
      setCopyStatus("Copied");
      setTimeout(() => setCopyStatus("Copy"), 10000);
    }
  };
  const [pending, setPending] = useState(false);

  const { register, handleSubmit, errors, getValues } = useForm();

  const {
    data: affiliatedLots,
    status: affiliatedLotsStatus,
    error: affiliatedLotsError,
  } = useLotsByAffiliate(auth?.user?.id);

  useEffect(() => {

if (affiliatedLots == null) {return ;}

    console.log(
      "SettingsAffiliate affiliatedLots",
      affiliatedLots,
      auth?.user?.id
    );

if (affiliatedLots.length === 0) {
        props.onStatus({
          type: "error",
          message: "No affiliate code set",
          source:"affiliate"
        });
}



  }, [affiliatedLots]);


  const [error, setError] = useState("");
  const [showError, setShowError] = useState(false);
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  function handleSave() {
    setCreatePaypalEmailMessage("Saving PayPal email.");

    const paypalEmail = getValues("paypal-email");

    if (!emailRegex.test(paypalEmail)) {
      setError("Invalid email");
      console.log("Invalid email entered:", email);
    } else {
      setError("");
    }



    const datagram = {
      to: "administrator",
      subject: "merp",
      text: "merp",
      paypalEmail: paypalEmail,
    };

    return updateUser(auth.user.uid, {
      affiliate: { paypalEmail: paypalEmail },
    })
      .then((result) => {
        setCreatePaypalEmailMessage("Paypal email saved.");
        setShowError(false);
        // Share the excitement.
        toast("Saved paypal email " + paypalEmail + ". Thank you.");
      })
      .catch((affiliateError) => {
        // setCreatePaypalEmailMessage(error.message);
        setCreatePaypalEmailMessage(affiliateError.message);
        setShowError(true);
      });
  }

  // Discuss: forceCheck

  useEffect(() => {
    forceCheck();
  }, [auth?.user?.affiliate?.affiliateCode]);

  /*
// Discuss: Or make a state variable update.

const [affiliateCode, setAffiliateCode] = useState();
useEffect(() =>{
 setAffiliateCode(auth?.user?.affiliate?.affiliateCode);
},[auth?.user?.affiliate?.affiliateCode]);
*/

  function handleTestProcessAffilationsButtonClick() {
    setProcessAffiliationsTestMessage("test running");

    const datagram = {
      to: "administrator",
      subject: "merp",
      text: "merp",
      bidOwner: auth.user.id,
    };

    console.log("SettingsDeveloper handleAffiliateButtonClick");
    processAffiliationsTest(datagram)
      .then((result) => {
        console.log(
          "SettingsDeveloper handeTestProcessAffiliations result",
          result
        );

        if (result !== undefined) {
          console.log(
            "SettingsDeveloper handleTestProcessAffiliations result",
            result
          );

          if (result.data !== undefined) {
            console.log(
              "SettingsDeveloper handleTestProcessAffiliations result.data",
              result.data
            );

            if (result.data.data !== undefined) {
              console.log(
                "SettingsDeveloper handleTestProcessAffiliations result.data.data hey",
                result.data.data.message,
                result.data.data.result
              );
              setAffiliationsMessage(result.data.data.message);
              setAffiliations({
                data: result.data.data.result,
                message: result.data.data.message,
                status: "success",
                error: null,
              });

              if (result.data.data.message) {
                setProcessAffiliationsTestMessage(result.data.data.message);
              }
            }
          }
        }
      })
      .catch((error) => {
        console.log("SettingsDeveloper affilate error", error);

        if (error.message) {
          setProcessAffiliationsTestMessage(error.message);
        }
      });
  }


  useEffect(() => {
    console.log("SettingsAffiliate affiliations", affiliations);
  }, [affiliations]);

  function handleCreateAffiliateButtonClick() {
    setCreateAffiliateMessage("Making a new affiliate code.");

    const userInputAffiliateCode = getValues("affiliate-code");
    const affiliateId = getSlug(userInputAffiliateCode); // Pretty much. Needs to be url safe.import {getSlug} from "./../util/slug.js";

    const datagram = {
      to: "administrator",
      subject: "merp",
      text: "merp",
      affiliateId: affiliateId,
    };

    console.log("SettingsDeveloper handleCreateAffiliateButtonClick");

    return createAffiliate(auth.user.id, affiliateId)
      .then((result) => {
        console.log("SettingsAffiliate result", result);

        setCreateAffiliateMessage("Affiliate code generated.");

        return updateUser(auth.user.uid, {
          affiliate: { affiliateCode: affiliateId },
        })
          .then((result) => {
            // Share the excitement.
            toast("Created unique affiliate code " + affiliateId + ".");
            setCreateAffiliateMessage("Affiliate code created.");
          })
          .catch((affiliateError) => {
            // setCreateAffiliateMessage(error.message);
            setCreateAffiliateMessage(affiliateError.message);
          });
      })
      .catch((error) => {
        console.log("SettingsAffiliate error", error);
        //        if (error.message) {
        setCreateAffiliateMessage("Affiliate code not created.");
        //        }
      });
  }

  // Consider:Not needed

  const onSubmit = (data) => {
    // Show pending indicator
    setPending(true);
    console.log(auth.user.id);
    console.log("seller update", data);
    return updateProfile(auth.user.id, data)
      .then(() => {
        props.onStatus({
          type: "success",
          message: "Your profile has been updated",
          source: "affiliate",
        });
        //setPending(false);
        //return;
      })
      .catch((error) => {
        //if (error.code === "auth/requires-recent-login") {
        //  props.onStatus({
        //    type: "requires-recent-login",
        // Resubmit after reauth flow
        //     callback: () => onSubmit(data),
        //   });
        //} else {
        // Set error status
        props.onStatus({
          type: "error",
          message: error.message,
          source:"affiliate"
        });
        // }
      })
      .finally(() => {
        // Hide pending indicator
        setPending(false);
      });

    //;

    //return;
    /*
    return auth
      .updateProfile(data)
      .then(() => {
        // Set success status
        props.onStatus({
          type: "success",
          message: "Your profile has been updated",
        });
      })
      .catch((error) => {
        if (error.code === "auth/requires-recent-login") {
          props.onStatus({
            type: "requires-recent-login",
            // Resubmit after reauth flow
            callback: () => onSubmit(data),
          });
        } else {
          // Set error status
          props.onStatus({
            type: "error",
            message: error.message,
          });
        }
      })
      .finally(() => {
        // Hide pending indicator
        setPending(false);
      });
      */
  };

  var copyLink = "ladybcode";

  const ProfileButton = (props) => {
    var u = auth.user && auth.user.uid ? auth.user.uid : null;

    if (u === undefined || u === null) {
      return <>This Seller does not have a profile set up.</>;
    }

    // return (
    //   <Button
    //     variant="outlined"
    //     component={Link}
    //     to={"/seller/" + u}
    //     endIcon={<ExitToAppIcon />}
    //   >
    //     View Your Seller Page
    //   </Button>
    // );
  };

  // if (sellerStatus !== "success") {
  //   return null;
  // }

  return (
    <Grid container={true} spacing={1}>


<Grid container item xs={12} spacing={1}>
      <Grid item xs={12}>
          <Typography variant="h6" align="right">
            Your tracking id: <b>{
            auth?.user?.affiliate?.affiliateCode
              ? auth?.user?.affiliate?.affiliateCode
              : null
          }</b> &nbsp;
          <Button size="small" variant="outlined" onClick={handleCopy}>{copyStatus}</Button>
          </Typography>
          
        </Grid>
        <Grid item xs={12}>
          <Typography gutterBottom variant="h6" align="right">
            Your Balance :
          </Typography>
        </Grid>
      </Grid>

      <Section bgColor="bid">
      <Container>
      

      <Grid item container xs={12}>

      <Typography variant="subtitle1">
          Create a unique tracking id and add it to any ladybidwell.com url to
          start earning commisions on products sold. e.g. ladybidwell.com/[...]&
          {auth?.user?.affiliate?.affiliateCode}
        </Typography>
        <TextField
          variant="outlined"
          // type="email"
          name="affiliate-code"
          defaultValue={
            auth?.user?.affiliate?.affiliateCode
              ? auth?.user?.affiliate?.affiliateCode
              : null
          }
          fullWidth={true}
          style={{ background: "#ffffff" }}
          inputRef={(el) => {
            register(el, { required: "Please enter your email" });
            textFieldRef.current = el;
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">

                <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={() => handleCreateAffiliateButtonClick()}
            //type="submit"
            //disabled={pending}
            //fullWidth={true}
          >
            Create Custom Tracking Id
          </Button>

              </InputAdornment>
            ),
          }}
        />

        {/* <Grid item={true} xs={12}>
          <br />
          FRONT END TEST. This creates an affiliate Code (Id) from the slugified
          provided input above. Returning feedback if already found.
          <br />
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={() => handleCreateAffiliateButtonClick()}
            //type="submit"
            //disabled={pending}
            //fullWidth={true}
          >
            Create Custom Tracking Id
          </Button>
        </Grid>
        {createAffiliateMessage} */}

      </Grid>


      <Divider
        style={{ width: "100%", marginTop: "10px", marginBottom: "10px" }}
      />
      <Grid item container xs={12}>
        <Typography variant="h6">
          Paypal Payments for Commissions
        </Typography>


             
        <Typography variant="subtitle1" gutterBottom>
          Set a paypal email address so you can be paid for your commissions.
          Payments will be made at the end of every month.
        </Typography>

        <TextField
          variant="outlined"
          type="email"
          name="paypal-email"
          helperText={
            <Typography style={{ color: "red" }}>
                 {error}
            </Typography>
          }
          // label="PayPal Email (this will be used to send you affiliate payments)"
          placeholder="Your PayPal Email"
          defaultValue={
            auth?.user?.affiliate?.paypalEmail
              ? auth?.user?.affiliate?.paypalEmail
              : "fitzwilliam.darcy@ladybidwell.com"
          }
          fullWidth={true}
          style={{ background: "#ffffff" }}
          inputRef={(el) => {
            register(el, { required: "Please enter your email" });
            textFieldRef.current = el;
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                  <Button
          disableElevation
          variant="contained"
          color={props.buttonColor}
          size="medium"
          type="submit"
          onClick={() => {
            if (!emailRegex.test(getValues("paypal-email"))) {
              setError("Invalid email");
              setShowError(true);
            } else {
              setError("");
              setShowError(false);
              handleSave();
            }
          }}
        >
          Save
        </Button>
              </InputAdornment>
            ),
          }}

        />
  
      </Grid>


      </Container>
      </Section>





      <Divider
        style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }}
      />

      <Grid item={true} xs={12}>
        <Typography variant="subtitle1" gutterBottom>
          Total Earnings (all time)
        </Typography>
      </Grid>

      <Grid item={true} xs={12}>
        <Typography variant="subtitle1" gutterBottom>
          Sold Items
        </Typography>

        {affiliatedLots && affiliatedLots.length === 0 && <>No items found.</>}

        {affiliatedLots &&
          affiliatedLots.map((affiliatedLot, index) => {
            console.log("affiliatedLot", affiliatedLot);
            return (
              <>
                <ItemIdCard
                  itemId={affiliatedLot.lot}
                  flavour="invoice"
                  spice={["noremove"]}

                  //   flavour="payment-confirmation"
                  //   spice={["address", "noremove"]}
                />
                {firebaseTime(affiliatedLot.affiliatedAt)}
              </>
            );
          })}

        {affiliatedLotsStatus === "loading" && <>LOADING</>}
        {affiliatedLotsError !== null && (
          <>Problem retrieving items you helped sell.</>
        )}
      </Grid>

      <Grid item={true} xs={12}>
        <br />
        BACK END TEST. This calls for a check of knockeddown events and
        affiliates them with the appropriate affiliate. It is a way to get the
        backend to check for affiliate sales as the back-end code which is
        current called 8 hourly.
        <br />
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={() => handleTestProcessAffilationsButtonClick()}
          //type="submit"
          //disabled={pending}
          //fullWidth={true}
        >
          Test Process Affiliations (Back End Test)
        </Button>
      </Grid>
      {processAffiliationsTestMessage}

      {affiliations &&
        affiliations.data &&
        Array.isArray(affiliations.data) &&
        affiliations.data.map((affiliation, index) => {
          return <div key={index}> Affiliation {index}</div>;
        })}
    </Grid>
  );
}

export default SettingsAffiliate;
