import { useState, useEffect } from "react";
import { Box, Typography } from "@material-ui/core";
import Section from "./Section.js";
import StripeElementsChargeCard from "./StripeElementsChargeCard";
import { useCart } from "../util/cart.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useLocation } from 'react-router-dom';
/*
Delay is Stripe Element coming in.
Communication with stripe and holding page render until stripe element is ready.
*/

// I think this is StripeElementsCheckoutDisplay

const CheckoutDisplay = (props) => {
  const [isProcessing, setIsProcessing] = useState(false);

  const { data: cartData, status: cartStatus, error: cartError } = useCart();

  const cartItems = cartData && cartData.cartItems;
  const cartCount = cartData && cartData.cartCount;

  const unavailableItems = cartData && cartData.unavailableItems;

  // dev 25 Sep 2022

  //  const _totalPrice = dynamicShoppingCart && dynamicShoppingCart.totalPrice;

  const _totalPrice = cartData.totalPrice;

  const [isCartReady, setIsCartReady] = useState(false);

  const [items, setItems] = useState();
  const [totalPrice, setTotalPrice] = useState();

  const [isCheckedOut, setIsCheckedOut] = useState(false);

  const { pathname } = useLocation();
//const pathname = window.location.pathname;
  useEffect(() => 
  console.log ("pathname", pathname)
  )
  
  function handleStatus(e) {
    //console.log("handleStatus", e)
    //props.onStatus(e);
  }

  function handleCheckout() {
    setIsCheckedOut(true);
  }

  useEffect(() => {
    if (cartData === undefined) {
      return;
    }

    if (isCheckedOut) {
      return;
    }

    if (cartStatus !== "success") {
      return;
    }

    const i = cartData.cartItems.map((lotItem) => {
      return { ...lotItem.item, id: lotItem.id, price: lotItem.price };
    });

    //    setItems(cartData.cartItems);
    setItems(i);
    setTotalPrice(cartData.cartTotal);
    setIsCartReady(true);
  }, [cartData, cartStatus]);
/*
if (pathname === '/checkout') {

return (<>BOB</>)

}
*/

useEffect(() =>{


  console.log("OrderSummary isCheckedOut isProcessing cartCount pathname", isCheckedOut, isProcessing, cartCount, pathname)
  
  
  }, [isCheckedOut, isProcessing, cartCount, pathname]);

  

  if (!isCartReady) {
    return null;
  }

  if (isProcessing || isCheckedOut) {
    return (
      <Box
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "#ffffff",
          opacity: 0.9,
          zIndex: 9999,
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box display="block" textAlign="center">
          <CircularProgress size="5em" color="secondary" />

          <Box my={3}>
            <Typography variant="h4">
              Payment Processing. Please Do Not Reload Page.{" "}
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  }

  if (items === undefined) {
    return (
      <Box sx={{ textAlign: "center" }}>
        <h2>Shopping Cart</h2>
        <h3>No items in cart.</h3>
      </Box>
    );
  }


  // This is making sure that when there is nothing in the cart the user
   // sees an empty cart type response. Not An empty order request.
 
 
  //  if (cartCount === 0 && isCheckedOut === false) {


    if (cartCount === 0 && isCheckedOut === false) {
    return (
      <Box sx={{ textAlign: "center" }}>
        <h3>No items in cart.</h3>
      </Box>
    );
  }




  

  return (
    <>
      <Section style={{ minHeight: "100vh" }}>
        <StripeElementsChargeCard
          // key={cartId}
          // key={Date.now()}
          onStatus={(e) => handleStatus(e)}
          //      paymentProcessor={paymentProcessor}
          items={items}
          cartCount={cartCount}
          totalPrice={{ currency: "USD", amount: totalPrice }}
          onCheckout={(e) => handleCheckout(e)}
          isProcessing={isProcessing}
          //     cartId={cartId}
          //       onZipCodeChange={(e) => handleZipCodeChange(e)}
        />
      </Section>
    </>
  );
};

export default CheckoutDisplay;
