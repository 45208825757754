import React, { useEffect } from "react";
import {
  useUser,
  updateUser,
  useSellerAccountStatusByUser,
} from "./../util/db.js";
import { useAuth } from "./../util/auth.js";
import { useSeller } from "./../util/identity.js";
//import SellerBadge from '../components/SellerBadge';
import Switch from "@material-ui/core/Switch";
import { useForm } from "react-hook-form";
import { serverTimestamp } from "firebase/firestore";
import { Typography } from "@material-ui/core";

//const serverTimestamp = firebaseApp.firestore.FieldValue.serverTimestamp;

export default function SellerBadge(props) {
  const id = props.user.id;
  const { flavour } = props;
  const auth = useAuth();
  const { handleSubmit, register, errors, reset } = useForm();

  const { data: userData, status: userStatus, error: userError } = useUser(id);
  const { data: sellerData, error: sellerError } = useSeller(id);

  //const {data:userData,status:userStatus,error:userError} = useUser(id);

  const {
    data: sellerAccountData,
    status: sellerAccountStatus,
    error: sellerAccountError,
  } = useSellerAccountStatusByUser(id);

  const isSellerGood =
    //    sellerAccountData !== null &&
    sellerAccountStatus === "loading" ||
    (sellerAccountStatus === "success" && sellerAccountData === true)
      ? true
      : false;


  const [state, setState] = React.useState({
    sellerEnabled: true,
    checkedB: true,
  });

  useEffect(() => {
    console.log("Badges sellerData", sellerData);
  }, [sellerData]);

  const handleChange = (type, event) => {
    if (type === "seller.enabled") {
      console.log("event", event);

      // setState({ ...state, [event.target.name]: event.target.checked });
      updateUser(id, {
        seller: { enabled: event.target.checked, enabledAt: serverTimestamp() },
      });
      return;
    }

    if (type === "buyer.enabled") {
      console.log("event", event);

      // setState({ ...state, [event.target.name]: event.target.checked });
      updateUser(id, {
        buyer: { enabled: event.target.checked, enabledAt: serverTimestamp() },
      });
      return;
    }
  };

  useEffect(() => {
    console.log("SellerBadge sellerAccountData", id, sellerAccountData);
  }, [sellerAccountData]);

  useEffect(() => {
    console.log("SellerBadge sellerData", id, sellerData);
  }, [sellerData]);


    if ((userStatus !== "success") && (sellerAccountStatus !== 'success')) {
      return null;
    }

    

  if (auth.user && !auth.user.isAdministrator) {
    return null;
  }

  if (props?.flavour === "icon") {
    return (
      <>
        ICON GOES HERE
        {sellerData?.data?.requirements?.charges_enabled
          ? "CHARGES OK"
          : "CHARGES NOT OK"}
      </>
    );
  }

  if (flavour === "tiny") {

    //const isSellerGood = sellerAccountData === true ? true : false; 
    //if (sellerAccountData === null) {return null;}
    return (
      <>
        {isSellerGood && "Seller Account OK"}


        <Typography style={{color:'red'}}>
        {!isSellerGood && "Issue with Seller Account"}
        </Typography>
      </>
    );
  }


  // Full badge return
  return (
  

      <>
        <div>
          SELLER BADGE
          <Switch
            checked={userData && userData.seller && userData.seller.enabled}
            onChange={(e) => handleChange("seller.enabled", e)}
            name="seller.enabled"
            inputProps={{ "aria-label": "secondary checkbox" }}
            inputRef={register()}
          />
        </div>
        <div>TOGGLE ENABLED/NOT ENABLED SELLER</div>
        <div>
          {" "}
          {userData && userData.seller ? <>SELLER RECORD SEEN</> : null}{" "}
        </div>
        <div>
          {" "}
          {userData && userData.seller && userData.seller.account ? (
            <>WITH ACCOUNT ID</>
          ) : null}{" "}
        </div>
        <div>
          {" "}
          {userData && userData.seller && userData.seller.approvedAt ? (
            <>APPROVED</>
          ) : null}{" "}
        </div>

        <div>Firestore response</div>
        <div>
          {userData?.seller?.payouts_enabled
            ? "PAYOUTS ENABLED"
            : "PAYOUTS NOT ENABLED"}
        </div>
        <div>
          {userData?.seller?.charges_enabled
            ? "CHARGES ENABLED"
            : "CHARGES NOT ENABLED"}
        </div>

        <div>Stripe response</div>
        <div>{sellerData?.data?.requirements?.disabled_reason}</div>
        <div>
          {sellerData?.data?.requirements?.payouts_enabled
            ? "PAYOUTS ENABLED"
            : "PAYOUTS NOT ENABLED"}
        </div>
        <div>
          {sellerData?.data?.requirements?.charges_enabled
            ? "CHARGES ENABLED"
            : "CHARGES NOT ENABLED"}
        </div>

        <div>TOGGLE APPROVED SELLER STATUS</div>
    
    </>
  );
}

//export default SellerBadge;
