import React, { memo } from "react";

//import { console } from "./../util/log.js";

import { makeStyles } from "@material-ui/core/styles";


import {
  Slide,
} from "@material-ui/core";
import {} from "@material-ui/core";
import { getFunctions, httpsCallable } from "firebase/functions";
import StripeAddPaymentCard from "./StripeAddPaymentCard.js";

const functions = getFunctions();

const errorMessages = {
  processing:
    "Processing Error: No charge has been made to your card. Please contact customer service for help.",
};

const processStripeClientSecret = httpsCallable(
  functions,
  "processStripeClientSecret"
);

const processStripeCustomerUpdate = httpsCallable(
  functions,
  "processStripeCustomerUpdate"
);

const startButtonDisabled = true;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const processStripePaymentItems = httpsCallable(
  functions,
  "processPaymentItems"
);

const processStripeEstimateItems = httpsCallable(
  functions,
  "processEstimateItems"
);

const processStripeQuoteItems = httpsCallable(functions, "processQuoteItems");

const getCustomerPaymentMethods = httpsCallable(
  functions,
  "getCustomerPaymentMethods"
);

const updatePaymentMethod = httpsCallable(functions, "updatePaymentMethod");

const useStyles = makeStyles((theme) => ({
  textField: {
    "&&": {
      marginBottom: "10px",
    },
    "& input:disabled": {
      // boxShadow: '#2ee519',
      "-webkit-box-shadow": "0 0 0 1000px #e8e8e8 inset",
    },
  },

  checkedout: {
    backgroundColor: "#000000",
  },

  paperItems: {
    minHeight: "300px",
  },
  item: {
    justifyContent: "space-between",
    display: "flex",
  },
  featured: {
    backgroundColor:
      theme.palette.type === "dark" ? theme.palette.action.selected : "#fdf8c2",
  },
  starFeatured: {
    color: theme.palette.warning.main,
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    marginTop: "2em",
    // padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  dialogTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    textAlign: "right",
  },
  warning: {
    color: theme.palette.error.main,
  },
}));

function StripeElementsChargeCard(props) {
  const { items, totalPrice, cartCount } = props;

  function handleCheckout(e) {
    if (props.onCheckout) {
      props.onCheckout(e);
    }
  }

  return (
    <>
      <StripeAddPaymentCard
        onCheckout={(e) => handleCheckout(e)}
        items={items}
        totalPrice={totalPrice}
        cartCount={cartCount}
      />
    </>
  );
}

export default memo(StripeElementsChargeCard);
