/*
  A wrapper around React Router that adds a useRouter() hook so that any component
  can easily access params, location, history, and trigger navigation.
  Import from this file instead of react-router-dom directly.
*/

import React, { useMemo, useEffect, useState } from "react";
import {
  Route as RouteOriginal,
  Router as RouterOriginal,
  useParams,
  useLocation,
  useHistory,
  useRouteMatch,
} from "react-router-dom";
import queryString from "query-string";
import { useResizeDetector } from "react-resize-detector";

// Use a custom history object and pass to Router so that we
// can utilize history.listen() where needed (such as for pageview tracking)
import { createBrowserHistory } from "history";
//import { useScrollMemory } from "./browser.js";
// import useIdleCallback from 'react-use-idle';
import { useIdle } from "@react-corekit/use-idle";

//import {saveState} from "./db.js";

//import { BrowserRouter } from 'react-router-dom' // <=V5 not compatible with V6

import { debugFlag } from "./../util/dev.js";
//const debugFlag = true;

export const history = createBrowserHistory();



// Includes custom history object and component for auto-scrolling to top

export function Router({ children }) {
  return (
    <RouterOriginal history={history}>
      {/* <ScrollToTop /> */}
      <ScrollToAnchor>{children}</ScrollToAnchor> 
    </RouterOriginal>
  );
}

export function ConditionalRoute({ children }) {
  return <RouteOriginal history={history}>{children}</RouteOriginal>;
}

// Custom useRouter hook for getting route data and methods inside any component.
// NOTE: This hook includes all React Router hooks, which can result in extra re-renders
// in some cases. When needed, you can optimize performance by importing the specific hook
// you need (such as useParams or useLocation) instead of this custom useRouter hook.
export function useRouter() {
  const params = useParams();

  const location = useLocation();

  const history = useHistory();
  const match = useRouteMatch();

  // Return our custom router object
  // Memoize so that a new object is only returned if something changes
  return useMemo(() => {
    return {
      // For convenience add push(), replace(), pathname at top level
      push: history.push,
      replace: history.replace,
      pathname: location.pathname,
      // Merge params and parsed query string into single "query" object
      // so that they can be used interchangeably.
      // Example: /:topic?sort=popular -> { topic: "react", sort: "popular" }
      query: {
        ...queryString.parse(location.search), // Convert string to object
        ...params,
      },
      // Include match, location, history objects so we have
      // access to extra React Router functionality if needed.
      match,
      location,
      history,
    };
  }, [params, match, location, history]);
}

// Remove or customize if you need more advanced scroll behavior
// and don't want to always scroll to top when location.pathname changes.
/*
function deprecateScrollToTop() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return null;
}
*/

/*
Probably misnamed now.
More like AweseomScrollerHandler
*/

const ScrollToAnchor = ({ children, props }) => {
  const location = useLocation();
  const history = useHistory();
  // isBack is a ltched vriable to control
  // whether to scroll to the anchor.
  // Or to realease control back to the user.
  const [isScrollControlled, setIsScrollControlled] = useState(true);
  //const [scrollToComplete, setScrollToComplete] = useState(false);

  const [restoreScrollComplete, setRestoreScrollComplete] = useState(false);

  const [hasReturned, setHasReturned] = useState(false);

  const [isBrowserBack, setIsBrowserBack] = useState();
  const [timestamp, setTimestamp] = useState(Date.now());
  const [age, setAge] = useState();
  const { width, height, ref } = useResizeDetector();

  //const scrollData = {};
  //useScrollMemory(scrollData);

  //useEffect(() => {
  //  if (debugFlag) {
  //console.log("RLE scrollData", scrollData);
  //  }
  //}, [scrollData]);

  const options = {
    timeToIdle: 1000,
    initialState: "idle",
    activityEvents: [
      "click",
      "mousemove",
      "keydown",
      "DOMMouseScroll",
      "mousewheel",
      "mousedown",
      "touchstart",
      "touchmove",
      "focus",
    ],
    inactivityEvents: [
      "click",
      "mousemove",
      "keydown",
      "DOMMouseScroll",
      "mousewheel",
      "mousedown",
      "touchstart",
      "touchmove",
      "focus",
    ],
  };

  const isIdle = useIdle(options);

  const [locationKeys, setLocationKeys] = useState([]);

  function scrollToTop() {
    // const location = useLocation();
    // useEffect(() => {
    console.log("RLE scrollToTop");
    window.scrollTo(0, 0);
  }

  function ScrollToTop() {
    const location = useLocation();
    useEffect(() => {
      scrollToTop();
      // window.scrollTo(0, 0);
    }, [location.pathname]);
    return null;
  }

  function restoreLastScrollPositionIfAny() {
    if (restoreScrollComplete) {
      return;
    }

    //if (location?.state?.scrollToTop) {
    //  return;
    //  }

    if (getOverrideScrollBehaviour() === "scrollToTop") {
      return;
    }

    console.log(
      "RLE restoreLastScollPositionIfAny location.state.doNotRestoreScroll",
      location?.state?.doNotRestoreScroll
    );

    if (location?.state?.doNotRestoreScroll) {
      console.log("RLE restoreLastScrollPositionIfAny saw doNotRestoreScroll");

      //console.log("RLE restoreLastScollPositionIfAny location.state.doNotRestoreScroll if",location?.state?.doNotRestoreScroll);

      if (history.location.state && history.location.state.doNotRestoreScroll) {
        console.log("RLE restoreLastScollPositionIfAny attempt wipe");

        let state = { ...history.location.state };
        delete state.doNotRestoreScroll;
        history.replace({ ...history.location, state });
      }
      setRestoreScrollComplete(true);
      return;
    }

    console.log("RLE restoreLastScollPositionIfAny attempt scrollTo");

    const x = sessionStorage.getItem(pathname);

    const x2 = JSON.parse(x);

    console.log(
      "RLE restoreLastScrollPositionIfAny getItem ",
      pathname,
      "x2",
      x2
    );

    const scrollX = 0;
    const scrollY = x2?.lastScrollPosition;

    console.log("RLE restoreLastScrollPositionIfAny scrollY", scrollY);
    //    window.scrollTo(scrollX, history.state.lastScrollPosition);
    //    const cleanState = { ...history.state };
    console.log("RLE scrollTo", scrollX, scrollY);

    if (scrollY === undefined) {
      //scrollToTop();
      return;
    }
    //document.querySelector('body').scrollTo(scrollX,scrollY);
    //window.history.scrollRestoration = "manual";
    window.scrollTo(scrollX, scrollY);
    const cleanState = { ...x2 };

    delete cleanState.lastScrollPosition;

    const serializedCleanState = JSON.stringify(cleanState);

    console.log(
      "RLE storeLastScrollPositionIfAny cleanState",
      pathname,
      serializedCleanState
    );
    sessionStorage.setItem(pathname, serializedCleanState);
    setRestoreScrollComplete(true);

    //history.replace(cleanState, pathname);
  }

  useEffect(() => {
    return history.listen((location) => {
      console.log("RLE button location", location);
      console.log("RLE button history.action", history.action);
      console.log(
        "RLE button locationKeys location.key",
        locationKeys,
        location.key
      );

      if (!location.key) {
        location.key = "superb";
      }

      if (history.action === "PUSH") {
        console.log("RLE button heard PUSH", location.key);
        setLocationKeys([location.key]);
        // Problem is this is triggered also
        // by clicking on any link.

        setRestoreScrollComplete(false);
        storeScrollPositionInHistoryState();
      }

      if (history.action === "POP") {
        setRestoreScrollComplete(false);
        // If there are two items on the location
        // stack. What is _
        if (locationKeys[1] === location.key) {
          setLocationKeys(([_, ...keys]) => keys);

          // Handle forward event
          console.log("RLE button forward");
        } else {
          setLocationKeys((keys) => [location.key, ...keys]);
          console.log("RLE button back");

          setIsBrowserBack(true);
          setHasReturned(true);

          // Flag this current interaction as a direct result of a BACK press.
          //          setIsBrowserBack(true);
          // Not convinced this next line is actively doing nyhing.
          //restoreLastScrollPositionIfAny();

          //          setHasReturned(true);
          // Handle back even
          //          console.log("RLE hndleBackEvent dev")
        }
      }
    });
  }, [locationKeys]);

  /*
Listen for user activity.
If user is activity (assumed trying to scroll/move in browser page)
then give the user control by setting isScrollCtroled to true.
*/

  useEffect(() => {
    if (isIdle === true) {
      if (debugFlag) console.log("RLE isIdle idle detected.");
      setIsScrollControlled(false);
    }
  }, [isIdle]);

  useEffect(() => {
    //console.log("height timestamp saw height changed", height)
    var t = Date.now();
    //console.log("height timestamp", t)
    setTimestamp(t);
  }, [height]);

  useEffect(() => {
    //handleScrollRestoration();
    const timerSettled = setInterval(() => {
      const nt = Date.now();
      const a = nt - timestamp;
      setAge(a);

      if (a > 0) {
        if (isBrowserBack === true) {
          // if (isScrollControlled) {
          restoreLastScrollPositionIfAny();
          // }
        }
      }

      if (a > 6000) {
        //
        clearInterval(timerSettled);
      }
    }, 500);
    // Clear timeout if the component is unmounted
    return () => clearTimeout(timerSettled);
  }, [timestamp]);

  function scrollToHash() {
    if (hash === "") {
      console.log("RLE scrollToHash blank seen");
      //window.scrollTo(0, 0);
      return;
    }
    const id = hash.replace("#", "");
    console.log("RLE scrollToHash id", id);
    const element = document.getElementById(id);
    if (element) {
      console.log("RLE jump to element id", id);
      console.log("RLE scrollIntoView");
      element.scrollIntoView();
      // setScrollToComplete(true);
      // element.scrollIntoView({ block: 'nearest', inline: 'start', behavior:'smooth' })
    } else {
      console.log("RLE scrollTohash did not find element");
    }
  }

  useEffect(() => {
    console.log("RLE router hasReturned", hasReturned);
  }, [hasReturned]);

  useEffect(() => {
    console.log("RLE router isScrollControlled", isScrollControlled);
  }, [isScrollControlled]);

  useEffect(() => {
    window.onpopstate = (e) => {
      console.log("RLE window.onpopstate set scrollRestoration manual");
      //storeScrollPositionInHistoryState();
      //setIsScrollControlled(true);
      //setHasReturned(true);
      window.history.scrollRestoration = "manual";
    };
  });

  const { pathname, hash, key } = location;

  //const y = useUnload();

  useEffect(() => {
    //    console.log("RLE []")
    if ("scrollRestoration" in window.history) {
      console.log(
        "RLE saw scrollRestoration in window.history set scrollRestoration manual"
      );
      window.history.scrollRestoration = "manual";
      //storeScrollPositionInHistoryState()
    }
  }, []);

  const useUnload = (fn) => {
    const cb = React.useRef(fn);

    React.useEffect(() => {
      const onUnload = cb.current;
      window.addEventListener("beforeunload", onUnload);
      return () => {
        window.removeEventListener("beforeunload", onUnload);
        console.log("RLE beforeunload listener");
      };
    }, [cb]);
  };

  function storeScrollPositionInHistoryState() {
    //console.log("RLE storeScrollPositionInHistoryState");
    //const scrollY = 0; // Hard code to top. Until we see it in console.
    // Make sure to add lastScrollPosition to the current state
    // to avoid removing other properties

    const newState = { ...history.state, lastScrollPosition: window.scrollY };
    console.log("RLE storeScrollPositionInHistoryState newState", newState);
    //    console.log("RLE state", newState)
    //    console.log("RLE location.href", location.href)
    //    console.log("RLE hash", hash)
    //    console.log("RLE pathname", pathname)
    //return;
    // Pass the current location.href since you only want to update
    // the state, not the url
    //history.replaceState(newState, "", pathname)

    const serializedNewState = JSON.stringify(newState);

    sessionStorage.setItem(pathname, serializedNewState);
    console.log("RLE storeScrollPositionInHistoryState pathname", pathname);

    console.log(
      "RLE storeScrollPositionInHistoryState localStorage serialized",
      pathname,
      serializedNewState
    );

    //history.replace(newState, pathname);

    //    console.log("RLE scrollY", history.state);
  }

  useEffect(() => {
    window.onbeforeunload = function () {
      //
      //      console.log("RLE onbeforeunload");
      doBeforeUnload();
      return "Are you sure you want to leave?";
    };

    window.onload = (event) => {
      //      console.log("RLE window onload");
      setTimeout(() => {
        // console.log("page is fully loaded")
        //scrollAfterLoad();
      }, 1500);
    };

    window.onhashchange = function () {
      //      console.log("RLE onhashchange");
      if (window.innerDocClick) {
        //Your own in-page mechanism triggered the hash change
        console.log("RLE window.innerDocClick");
      } else {
        //Browser back button was clicked
        console.log("RLE !window.innerDocClick");
      }
    };

    window.onpopstate = (e) => {
      console.log("RLE onpopstate");
      //setIsBack(true);
    };
  });

  function doBeforeUnload() {
    //    console.log("RLE doBeforeUnload.");
  }

  function scrollAfterLoad() {
    //var b = false;
    //    console.log("RLE start scrollAfterLoad (back test)");

    //    console.log("router.js afterload pathname hsh key isScrollControlled", pathname, hash, key, isScrollControlled);

    // if ("scrollRestoration" in window.history) {
    //     window.history.scrollRestoration = "manual"
    //   }

    // Does a position exist in the history???????
    // Then go to that.

    // Two parts. ... are we leaving the page??? Save the position.
    /*
    if (isBack) {

console.log("We're back.");
setIsBack(false);
return;

    }
    */
    if (!isScrollControlled) {
      //      console.log("router.js isBack setIsBack to false.");
      setIsScrollControlled(true);
      return;
    }
    //    console.log("router.js Attempt to go to hash ... or top of page back.", hash);
    if (hash === "") {
      console.log("RLE router.js hash empty back", hash);
      console.log("RLE scrollTo 0,0");
      window.scrollTo(0, 0);
      //setScrollToComplete(true);
    }
    // else scroll to id
    else {
      //
      //      console.log("router.js hash not empty", hash);
      setTimeout(() => {
        //        console.log("router.js set hash timeout back");
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        //        console.log("router.js element", element);
        if (element) {
          //          console.log("router.js scroll into view back", element);
          // element.scrollIntoView({ behavior: "smooth" });
          console.log("RLE scrollIntoView");
          element.scrollIntoView();
          //setScrollToComplete(true);
        } else {
          console.log("RLE scrollTo 0,0");
          window.scrollTo(0, 0);
          // setScrollToComplete(true);
        }
      }, 2000);
    }
  }
  useEffect(() => {
    // console.log("called scrolltohash chec, isBack, isIdle", isBack, isIdle)
    if (!isScrollControlled) {
      return;
    }
    scrollToHash();
    // console.log("scrolld to scrolltohash tag")
  });

  useEffect(() => {
    //    console.log("age", age);

    // if is back is true, then we have already scrolled once.
    if (!isScrollControlled) {
      return;
    }
    //scrollToHash();
    if (age && age > 4000) {
      // scrollToHash();
      // Now flag that we have scroll.ed.
      // To latch in place. Don't eep scrolling.
      // if we hvae scrolled once.
      setIsScrollControlled(false);
    }
  }, [age]);

  function handleScrollRestoration() {
    // We want to do scroll restoration ....
    // When you are going from an item page to the home page.
    // When you are item page going back to another item page
    //

    console.log("RLE handleScrollRestoration");
    console.log(
      "RLE handleScrollRestoration location?.state?",
      location?.state
    );

    if (location?.state?.scrollToTop) {
      //console.log("RLE restoreLastScollPositionIfAny location.state.doNotRestoreScroll if",location?.state?.doNotRestoreScroll);

      if (history.location.state && history.location.state.scrollToTop) {
        console.log("RLE restoreLastScollPositionIfAny attempt wipe");

        let state = { ...history.location.state };
        delete state.scrollToTop;
        history.replace({ ...history.location, state });
      }
      scrollToTop();
      setIsScrollControlled(false);
      return;
    }

    //if (isScrollControlled === false) {return;}
    /*
    if (pathname.includes("item")) {
      console.log("RLE handleScrollRestoration saw pathname has item")
      scrollToTop();
      setIsScrollControlled(false);
      return;
    }

    if (pathname.includes("client") && !pathname.includes("clients")) {
      console.log("RLE handleScrollRestoration saw pathname is client related")
      scrollToTop();
      setIsScrollControlled(false);
      return;
    }
*/
    if (getOverrideScrollBehaviour() === "scrollToTop") {
      scrollToTop();
      setIsScrollControlled(false);
    }
  }

  function getOverrideScrollBehaviour() {
    if (pathname.includes("item")) {
      return "scrollToTop";
    }

    if (pathname.includes("client") && !pathname.includes("clients")) {
      return "scrollToTop";
    }

    return "restoreScrollPosition";
  }
  useEffect(() => {
    handleScrollRestoration();
    //  }, [pathname, hash, key]); // do this on route change
  }, [pathname]); // do this on route change

  return (
    <>
      {debugFlag && (
        <>
          {height}
          {" --- "}
          {timestamp}
          {" --- "}
          {age}
          {"----"}
          {pathname}
          {"----"}
          {isScrollControlled ? "scrollControlled" : "notScrollControlled"}
          {"----"}
          {restoreScrollComplete
            ? "restoreScrollComplete"
            : "notrestoreScrollComplete"}
        </>
      )}

      <div ref={ref}>{children}</div>
    </>
  );
};

export {
  Route,
  Switch,
  Link,
  NavLink,
  useParams,
  useLocation,
  useHistory,
  useRouteMatch,
} from "react-router-dom";

