import { useEffect, useState } from "react";
import { useEventBySlug, useCollectionsByEvent} from "./../util/db.js";
import SellerWeeklyEvent from "./SellerWeeklyEvent";


import { useAuth } from "./../util/auth.js";


import NotFoundPage from "../pages/not-found";
import { debugFlag } from "./../util/dev.js";

export default function WeeklyPost(props) {
  const { post, seller, searchQuery, flavour } = props;

 const auth = useAuth();

  // Requires a hook here to pull in all
  // Matching events (for the "post") and return the slug ids.

  // Not quite this hook.
  // Which trnslates the ucitoner given slug to an event (id + everything)
  const { data: event, status, error } = useEventBySlug(post);
const [sellers, setSellers] = useState([]);
const [seller2, setSeller2] = useState();
useEffect(() =>{

console.log("WeeklyPost event", event);

},[event]);


  const eventId = event && event.id;
  const {
    data: collectionIds,
    status: statusCollections,
    error: errorCollections,
  } = useCollectionsByEvent(eventId);
  //const {data: event, status, error} = useItem('5GB2p3EzWjGnLybe2X3j');
  const [itemsCount, setItemsCount] = useState(0);
const [catchallItems, setCatchallItems] = useState([]);
  const [metaEvents, setMetaEvents] = useState({});

  useEffect(() => {
    console.log("WeeklyPost searchQuery", searchQuery);
    //setItemsCount(0);
  }, [searchQuery]);

  function handleCardCount(c) {
    console.log("WeeklyPost c", c);

    //  function handleIsCardVisible(c) {
    console.log("WeeklyPost handleCardCount c", c);

    const _metaEvents = metaEvents;

    _metaEvents[c.id] = c.count;
    setMetaEvents({ ..._metaEvents });
  }


function handleSeller(s) {

console.log("WeeklyPost seller s sellers",s, sellers);
if (s !== seller) {

if (!sellers.includes(s)){
const newSellers = sellers.push(s);
}
//setSellers(newSellers);
setSeller2(s);
}
}

function handleCatchallItems(i) {
console.log("newItems", i);

const newItems = catchallItems;

i.map((iItem)=>{
  const index = catchallItems.findIndex(item => item.id === iItem.id);

  if (index === -1) {
    newItems.push(iItem);
  }

})

setCatchallItems(newItems);




return;
//const newItems = [...catchallItems, ...i];
//console.log("WeeklyPost handleCatchallItems", newItems);
//setCatchallItems(newItems);

}

useEffect(() =>{

console.log("WeeklyPost catchallItems", catchallItems);

},[catchallItems]);

  useEffect(() => {
    console.log("WeeklyPost metaEvents", metaEvents);

    const keys = Object.keys(metaEvents);

    if (keys == null) {
      console.log("ItemCards null keys");
      return;
    }
    if (!Array.isArray(keys)) {
      console.log("ItemCards Not array keys");
      return;
    }
    //console.log("WeeklyPost keys", keys);

    const eventMatchItemCounts = keys.map((key) => {
      return metaEvents[key];
    });

    console.log("WeeklyPost matchCount", eventMatchItemCounts);

    const s = calculateSum(eventMatchItemCounts);
setItemsCount(s);
    if (props.onCardCount) {
      props.onCardCount({id:eventId,count:s});
    }
  }, [metaEvents]);

  const calculateSum = (arr) => {
    return arr.reduce((total, current) => {
      if (current == null) {
        return total;
      }
      return total + current;
    }, 0);
  };

  // Need to get all Events which have an Item in the Current collection.
  // So need to get the current collection (from events) above.
  // Then get all items
  // Get event for each item.
  // Then get unique events.
  //  const {data: events, status2, error2} = useEventsBySlug(post);

  // Develop logic to pick up siuation where
  // there is no Wordpress page and no collection available.
  // ie the requested weekly guide does not exist
  if (
    status === "success" &&
    event === null &&
    statusCollections === "idle" &&
    collectionIds &&
    collectionIds.length === 0
  ) {
    return (
      <>
        <NotFoundPage />
      </>
    );
  }

  return (
    <>
      {/* {(status !== 'SellerWeeklyEvent catchallloading') && (event) && (<>EVENT NAME: {event.name}
{event.description}
</>)} */}
      {status !== "loading" && event && <></>}
      {debugFlag && (<>
WEEKPOST ITEMS MATCHING SEARCH QUERY {itemsCount}
</>
      )}

      {eventId &&
        collectionIds.map((collectionId, index) => {
          return (
     

            <SellerWeeklyEvent
              seller={seller}
              key={collectionId}
              eventId={collectionId}
              flavour={flavour}
              eventIndex={index}
              auction={event}
              searchQuery={searchQuery}
              onCardCount={(c) => handleCardCount(c)}
              onCatchall={(i)=>handleCatchallItems(i)}
              onSeller={(s)=>handleSeller(s)}
            />

  
       

          );
        })}



{sellers && sellers.map((s)=>{
  const sellerCatchallItems = catchallItems.filter((item)=>{return item.owner===s})
return (

          <SellerWeeklyEvent
              seller={s}
              key={s}
              flavour={flavour}
  ////tempItems = [...tempItems, ...injectItems];
           items={sellerCatchallItems} //dev explore injecting 'additional items'
//              eventIndex={index}
              auction={event}
              searchQuery={searchQuery}
              onCardCount={(c) => handleCardCount(c)}
//              onCatchall={(i)=>handleCatchallItems(i)}
            />

)})}

   {/*     <ItemsGrid items={filteredItems} flavour={flavour} event={event} onCardCount={(c)=>handleCardCount(c)} onCountChange={(d)=>handleCountChange(d)} searchQuery={searchQuery} /> */}



    </>
  );
}
