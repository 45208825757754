import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useRouter } from "./../util/router.js";

import Typography from "@material-ui/core/Typography";
import icon_lb from "./../images/icon_lb.png";
import { makeStyles } from "@material-ui/core/styles";
import { CSSTransition } from "react-transition-group";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import { throttle } from "lodash";

import {
  Box,
  Container,
  Collapse,
  Button,
  Divider,
  Input,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "sticky",
    top: "0em",
    zIndex: "99",
    display: "block",
    alignItems: "center",
    background: "#ffffff",
    // borderBottom:'1px solid #ecebeb;'
    // boxShadow: 'rgb(0 0 0 / 10%) 0px 2px 10px 0px',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },

  icon: {
    // display: 'none',
    fontSize: "1.2em",
    color: "#333333",
    [theme.breakpoints.up("sm")]: {
      display: "flex",
    },
  },

  toolbar: {
    borderBottom: "1px solid black",
  },

  alert: {
    // Let <Section> handle colors
    backgroundColor: "#ffffff",
    color: "#000",
    borderRadius: 0,
  },

  message: {
    width: "100%",
    textAlign: "center",
  },

  // title: {
  //   flexGrow: 1,
  //   display: "flex",
  //   // fontSize:'1.4em',
  //   [theme.breakpoints.up("sm")]: {
  //     display: "block",
  //   },
  // },

  search: {
    position: "relative",
    // borderRadius: theme.shape.borderRadius,
    // backgroundColor: "#f7f7f7",
    // borderBottom: "2px solid #f44336",

    "&:focus-within": {
      backgroundColor: "transparent",
    },

    width: "100%",

    [theme.breakpoints.up("sm")]: {
      width: "100%",
      display: "block",
    },
  },

  menu: {
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      display: "none",
    },
  },

  searchMobile: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "transparent",
    border: "1px solid #000",
    "&:focus-within": {
      backgroundColor: "transparent",
      border: "1px solid #f44336",
    },
    // marginLeft: 15,
    width: "100%",
  },

  container: {
    position: "relative",
    zIndex: 99,
  },

  searchIcon: {
    padding: theme.spacing(0, 1),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "20px",
  },
  inputRoot: {
    color: "inherit",
    display: "flex",
    fontSize: "16px",
    // border:'1px solid black',
    "& ::placeholder": {
      color: "#000000",
      // opacity: 1,
    },
    // fontSize:'1.5em',
  },

  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(3)}px)`,
    transition: theme.transitions.create("width"),
    fontSize: "16px",

    // fontSize:'1.5em',
    width: "100%",

    [theme.breakpoints.up("sm")]: {
      fontSize: "16px",
      width: "100%",

      "&:focus": {
        fontSize: "16px",
        width: "100%",
      },
    },
  },

  logo: {
    height: "2.5em",
    marginRight: theme.spacing(1),
    transition: "opacity 300ms ease-in-out",
  },

  logoEnter: {
    opacity: 0,
  },

  logoEnterActive: {
    opacity: 1,
  },

  logoExit: {
    opacity: 1,
    transition: "opacity 300ms ease-in-out",
  },

  logoExitActive: {
    opacity: 0,
  },
}));

export default function Search(props) {
  const classes = useStyles();
  const router = useRouter();
  const { flavour } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 70,
  });
  const {
    onClose,
    bgColor,
    textColor,
    text,
    linkPath,
    linkText,
    countItems,
    seller,
    ...otherProps
  } = props;
  const path = router.pathname;
  const [isTop, setIsTop] = useState(false);
  const textInput = React.useRef(null);
  const searchRef = useRef(null);

  const searchEnabled = false; // Algolia / Elastic search. Pay to play. Disable for launch.

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [menuState, setMenuState] = useState(null);

  const [keywords, setKeywords] = useState(null);

  const handleScroll = () => {
    if (searchRef.current) {
      const rect = searchRef.current.getBoundingClientRect();
      console.log("Component top position:", rect.top);

      if (rect.top <= 25) {
        setIsTop(true);
      } else {
        setIsTop(false);
      }
    }
  };

  useEffect(() => {
    const handleScrollThrottled = throttle(handleScroll, 250);
    window.addEventListener("scroll", handleScrollThrottled);
    handleScroll(); // set isTop on initial render

    return () => {
      window.removeEventListener("scroll", handleScrollThrottled);
    };
  }, []);

  const handleClearClick = (e) => {
    textInput.current.value = "";
    setKeywords("");
  };

  const [query, setQuery] = useState();
  const [filteredQuery, setFilteredQuery] = useState();
  const inputRef = useRef(null);
  const placeholder = seller?.name ? `Search ${seller?.name}'s items by type, style, etc.` : "Search by type, style, etc.";

  useEffect(() => {
    console.log("Search keywords", keywords);
    if (props.onChangeSearch) {
      props.onChangeSearch(keywords);
    }
  }, [keywords]);

  useEffect(() => {
    //console.log(path);

    const laststep = path.replace(/-/g, " ");
    var s = laststep.replace(/\//g, "");
    //s = null;
    setQuery(s);

    setFilteredQuery(stripIdFromTitle(s));
  }, [path]);

  const handleOpenMenu = (event, id) => {
    // Store clicked element (to anchor the menu to)
    // and the menu id so we can tell which menu is open.
    setMenuState({ anchor: event.currentTarget, id });
  };

  let history = useHistory();

  useEffect(() => {
    console.log("Search component start");
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();

    console.log("Search handleSubmit");

    // const slug = getSlug(keywords);
    // history.push(path);
    if (props.onRequestSearch) {
      props.onRequestSearch(keywords);
    }
  };

  function isNumeric(token) {
    if (typeof token != "string") return false; // we only process strings!
    return (
      !isNaN(token) && !isNaN(parseFloat(token)) // use type coercion to parse t>
    ); // ...and ensure strings of whitespace fail
  }

  function stripIdFromTitle(text) {
    var filteredPostTitle = text.toLowerCase();

    filteredPostTitle = filteredPostTitle.replace("httpsdev gimmu com", "");
    filteredPostTitle = filteredPostTitle.replace("for sale", "");
    filteredPostTitle = filteredPostTitle.replace("accessories", "");

    var tokens = filteredPostTitle.split(" ");
    var a = filteredPostTitle.split(" ");

    var lastToken = a[a.length - 1];

    if (isNumeric(lastToken)) {
      // 113992540786
      // 10000000000

      if (lastToken > 10000000000) {
        a.pop();
        var filteredPostTitle = a.join(" ");
      }
    }

    return filteredPostTitle;
  }

  // if (flavour === "searchscroll") return <SearchScroll />;

  return (
    // <Section bgColor={props.color} size="auto" >

    <>

      <div id="search" className={classes.root} ref={searchRef}>
        <Box pt={2} style={{height:'90px'}}>
          <Container>
            <div style={{ display: "flex", alignItems: "center" }}>
              <CSSTransition
                in={isTop}
                timeout={300}
                unmountOnExit
                classNames={{
                  enter: classes.logoEnter,
                  enterActive: classes.logoEnterActive,
                  exit: classes.logoExit,
                  exitActive: classes.logoExitActive,
                }}
              >
                <img src={icon_lb} alt="Logo" className={classes.logo} />
              </CSSTransition>

              <div className={classes.search}>
                <form onSubmit={handleSubmit}>
                  <div className={classes.searchIcon}>
                    <SearchIcon />
                  </div>

                  <Input
                    // disableUnderline
                    placeholder={placeholder}
                    key={query}
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput,
                    }}
                    inputProps={{ "aria-label": "search" }}
                    onChange={(e) => setKeywords(e.target.value)}
                    endAdornment={
                      !(keywords === "" || keywords == null) && (
                        <>
                          <Button onClick={(e) => handleClearClick(e)}>
                            <ClearIcon />
                          </Button>
                        </>
                      )
                    }
                    inputRef={textInput}
                  />
                </form>
              </div>
            </div>

    
          </Container>

              {keywords && (
                <>
                  <Container className={classes.container}>
                    <Box>
                      <Typography variant="subtitle1">
                        {countItems} {countItems > 1 ? "items" : "item"}{" "}
                        matching your search.
                      </Typography>
                    </Box>
                  </Container>
    
                </>
              )}
     
        </Box>

        {/* {!keywords && (
          <>
            <Divider />
          </>
        )} */}
      </div>
    </>
  );
}
