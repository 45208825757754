import React, { useEffect, useState } from "react";
import { Box, Button, Checkbox, FormControlLabel, Card, CardContent, Typography, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link, useRouter } from "./../util/router.js";
import { useAuth } from "./../util/auth.js";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import CheckoutTickbox from "./CheckoutTickbox.js";


const useStyles = makeStyles((theme) => ({
  modal: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      background: 'white',
      border: '2px solid #000',
      boxShadow: 24,
      p: 4,
  },
  button: {
    fontWeight: 600,
    paddingTop: "15px",
    paddingBottom: "15px",
    // marginTop: "10px",
  },
  labelStyle: {
  // display: 'flex',
  // alignItems: 'flex-start'
},
}));

export default function CheckoutButton(props) {
  const auth = useAuth();
  const router = useRouter();
  const classes = useStyles();
  const { cartCount } = props;
  const [termsAccepted, setTermsAccepted] = useState(false);
  const checkoutTickbox = <CheckoutTickbox termsAccepted={termsAccepted} setTermsAccepted={setTermsAccepted} />;
  const handleProceedToCheckout = (e) => {
    if (!termsAccepted) {
      e.preventDefault();
      alert("Please accept the terms and conditions before proceeding to checkout.");
    }
  };

  if (auth && auth.user) {
    return (
      <div>
   
        <Button
          fullWidth
          component={Link}
          to="/checkout"
          style={{ backgroundColor: termsAccepted ? "black" : "#d7d7d7", color: "white" }}
          disableElevation={true}
          endIcon={<ArrowForwardIosIcon />}
          className={classes.button}
          variant="contained"
          size="large"
          onClick={handleProceedToCheckout}
        >
          Proceed To Checkout ({cartCount} {cartCount === 1 ? "item" : "items"})
        </Button>

<Box py={1}> 

{checkoutTickbox}
    </Box>
      </div>
    );
  }


  return (
    <div>

      <Button disableElevation={true} fullWidth component={Link} to={"/auth/signin?next=" + "/checkout"}  onClick={handleProceedToCheckout}  style={{ backgroundColor: termsAccepted ? "black" : "#d7d7d7", color: "white" }} endIcon={<ArrowForwardIosIcon />} className={classes.button} variant="contained" size="large" elevation={1} >
                  Proceed To Checkout {" "} ({cartCount} {cartCount === 1 ? "item" : "items"}){" "}
                </Button>

                <Box py={1}> 
{checkoutTickbox}
  
</Box>
    </div>
  );
}
