import React, { useState } from "react";
import HeroSectionCenter from "../components/HeroSectionCenter";
import ApplySection from "../components/ApplySection";
import SellSteps from "../components/SellSteps";
import BidwellManifesto from "../components/BidwellManifesto";
import WhySell from "../components/WhySell";
import WhatCanSell from "../components/WhatCanSell";
import room from "./../images/room.jpg"
import snowfall from "./../images/snowfall.jpg"
import Meta from "./../components/Meta";
import { useScrollToTop } from "../util/db";

function NewSeller(props) {
  useScrollToTop();
  const [creatingSeller, setCreatingSeller] = useState(false);
const [sellerType, setSellerType] = useState('one off');

const handleCreatingSeller = (type) => {

setSellerType(type);
setCreatingSeller(true);
}


  return (
    <>

<Meta 
title="Seller Application"
/>

      {/* <HeroSection2
        bgColor="dark"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Auctions Made JoyFul"
        subtitle="Beautiful items have the power to bring happiness into every home. "
      /> */}

<HeroSectionCenter
        bgColor="seller"
        fontColor="#ffffff"
        size="medium"
        bgImage={snowfall}
        bgImageOpacity={.4}
        title="Start Selling With LadyBidwell"
        subtitle="Sell unique and interesting items without any of the hassles."
      />



{/* <HeroSection2
        bgColor="light"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Bringing Joy to a Tired Industry"
        subtitle="Buying and selling beautiful items should be as joyous as owning them. That's why we made selling with LadyBidwell more of an ahhhhh, less of a ahhhghmygod. From low commissions and no hidden fees to easy peasy (lemon Philip Stark) squezzy uploading of items."
      /> */}



<BidwellManifesto
         bgColor="dark"
         size="medium"
         bgImage=""
         bgImageOpacity={1}      
        //  image="https://cdn.pixabay.com/photo/2018/01/24/15/08/live-3104077_1280.jpg"
      />





<WhySell
         bgColor="light"
         size="medium"
         bgImage=""
         bgImageOpacity={1}      
        //  image="https://cdn.pixabay.com/photo/2018/01/24/15/08/live-3104077_1280.jpg"
      />

<WhatCanSell
         bgColor="dark"
         size="medium"
         bgImage=""
         bgImageOpacity={1}      
         image={room}
      />

<SellSteps
         bgColor="light"
         size="medium"
         bgImage=""
         bgImageOpacity={1}      
         image="https://cdn.pixabay.com/photo/2018/01/24/15/08/live-3104077_1280.jpg"
      /> 


<div id="apply-section">
<ApplySection
   
        bgColor="dark"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
      />
</div>


    </>
  );
}

export default NewSeller;
