import Section from "./Section";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { Link, useRouter } from "./../util/router.js";
import { Divider } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { makeStyles } from "@material-ui/core/styles";
import NewsletterSection from "./NewsletterSection";
import { Facebook, Twitter, Instagram } from "@material-ui/icons";



const useStyles = makeStyles((theme) => ({
  sticky: {
    // marginTop: "auto",
    // paddingTop: "5em",
    //marginTop:'calc(100% + 60px)',
    // bottom: 0,
    // position:'fixed',
    // background:'#ffffff',
    // display:'flex',
    // padding: 80px 60px;
    // background: black;
    // position: 'sticky',
    // bottom: 0,
    // width: '100%',
    marginTop: "auto",
    paddingTop: "5em",
  },
  notSticky: {
    // marginTop: "auto",
    // paddingTop: "5em",
    //marginTop:'calc(100% + 60px)',
    marginTop: "auto",
    paddingTop: "2em",
  },
  brand: {
    display: "block",
    height: 32,
  },

  listItem: {
    paddingTop: 0,
    paddingBottom: 0,
    // paddingLeft: 12,
    paddingRight: 12,
    color: "#0000008a",
  },

  listItemTextHeader: {
    fontWeight: "bold",
    // color:"#ffffffe6",
  },
  socialIcon: {
    minWidth: 30,
  },
  white: {
    color: "#00000",
  },
  flexContainer: {
    display: "flex",
    flexDirection: "row",
  },
}));



function Footer(props) {
  const classes = useStyles();
  const router = useRouter();

  const path = router.pathname;
  // const showFrame = !(path === "/checkout" || path === "/auth/signin");
  const showFrame = !(path === "/checkout" || path.startsWith("/auth"));
  // const showFrame = !(path === "/auth/signin/?next=/");
  //  const showFrame = true;

  // Use inverted logo if specified
  // and we are in dark mode
  const logo = props.logoInverted ? props.logoInverted : props.logo;




  //  CHECKOUT PAGE FOOTER

  if (!showFrame) return null;
  <>
    {/* <Section
      // bgColor={props.bgColor}
      style={{ backgroundColor: "#ffffff" }}
      size={props.size}
      // bgImage={props.bgImage}
      // bgImage="https://cdn.pixabay.com/photo/2021/05/05/11/43/background-6230888_960_720.png"
      bgImageOpacity={props.bgImageOpacity}
      className={props.sticky && classes.sticky}
    > */}

    {/* <Box className={props.sticky && classes.sticky}>
          <Divider />
          <Container style={{width:'100%'}}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              py={3}
            >
              {props.copyright && (
                <Box>
                  <Typography component="p" className={classes.white}>
                    {props.copyright}
                  </Typography>
                </Box>
              )}

              <Box style={{ opacity: "0.5" }}>
                <a href="https://www.stripe.com" target="_blank">
                  <img src={PoweredByStripe} style={{ width: "140px" }} />
                </a>
              </Box>
            </Box>
          </Container>
        </Box> */}

    {/* </Section> */}
  </>;

  // EVERYTHING ELSE FOOTER

  return (
    <>
    <Section
    // bgColor="#fff"
    // style={{ backgroundColor: "#f4f4f4" }}
    bgColor="newsletter"
    size={props.size}
    // bgImage={props.bgImage}
    // bgImage="https://cdn.pixabay.com/photo/2021/05/05/11/43/background-6230888_960_720.png"
    bgImageOpacity={props.bgImageOpacity}
    className={props.sticky && classes.notSticky}
  >
    <Container maxWidth="sm">
 <Box pb={2} id="newsletter" >
          <NewsletterSection flavour="copy_buylike" />
        </Box>
        </Container>
    </Section>

    <Section
      // bgColor="#fff"
      // style={{ backgroundColor: "#f4f4f4" }}
      bgColor="section"
      size={props.size}
      // bgImage={props.bgImage}
      // bgImage="https://cdn.pixabay.com/photo/2021/05/05/11/43/background-6230888_960_720.png"
      bgImageOpacity={props.bgImageOpacity}
      className={props.sticky && classes.notSticky}
    >



      <Container>
    
     

        <Grid container={true} justifyContent="space-between" spacing={1}>
    

          <Grid item={true} xs={12}>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12} sm={4}>
                <List disablePadding={true}>
                  <ListItem disableGutters className={classes.listItem}>
                    <Typography
                      variant="overline"
                      className={classes.listItemTextHeader}
                    >
                      Sellers
                    </Typography>
                  </ListItem>

                  <ListItem
                    className={classes.listItem}
                    component={Link}
                    to={{
                      pathname: "/sell/apply",
                      state: { scrollToTop: true },
                    }}
                    disableGutters
                  >
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography
                          variant="body2"
                          className={classes.listItem}
                        >
                          Seller Application
                        </Typography>
                      }
                    />
                  </ListItem>

                  {/* <ListItem
                    className={classes.listItem}
                    component={Link}
                    to={{
                      pathname: "/seller-faq",
                      state: { scrollToTop: true },
                    }}
                    disableGutters
                  >
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography
                          variant="body2"
                          className={classes.listItem}
                        >
                   
                            Seller FAQ
                        
                        </Typography>
                      }
                    />
                  </ListItem> */}
                </List>
              </Grid>

              <Grid item={true} xs={12} sm={4}>
                <List disablePadding={true}>
                  <ListItem disableGutters className={classes.listItem}>
                    <Typography
                      variant="overline"
                      className={classes.listItemTextHeader}
                    >
                      Buyers
                    </Typography>
                  </ListItem>

                  <ListItem
                    className={classes.listItem}
                    component={Link}
                    to={{
                      pathname: "/trade/apply",
                      state: { scrollToTop: true },
                    }}
                    disableGutters
                  >
                    <ListItemText
                      className={classes.listItem}
                      disableTypography
                      primary={
                        <Typography
                          className={classes.listItem}
                          variant="body2"
                        >
              
                            Trade Program
                       
                        </Typography>
                      }
                    />
                  </ListItem>

                  {/* <ListItem
                    className={classes.listItem}
                    component={Link}
                  
                    to={{
                      pathname: "/buying",
                      state: { scrollToTop: true },
                    }}
                    disableGutters
                  >
                    <ListItemText
                      className={classes.listItem}
                      disableTypography
                      primary={
                        <Typography
                          variant="body2"
                          className={classes.listItem}
                        >
                          Buying on LadyBidwell
                        </Typography>
                      }
                    />
                  </ListItem> */}

                  {/* <ListItem
                    className={classes.listItem}
                    component={Link}
                    to={{
                      pathname: "/seller-faq",
                      state: { scrollToTop: true },
                    }}
                   
                    disableGutters
                  >
                    <ListItemText
                      className={classes.listItem}
                      disableTypography
                      primary={
                        <Typography
                          variant="body2"
                          className={classes.listItem}
                        >
                          Buyer FAQ
                        </Typography>
                      }
                    />
                  </ListItem> */}
                </List>
              </Grid>

              <Grid item={true} xs={12} sm={4}>
                <List disablePadding={true}>
                  <ListItem className={classes.listItem} component="a" disableGutters>
                    <Typography
                      variant="overline"
                      className={classes.listItemTextHeader}
                    >
                      Company
                    </Typography>
                  </ListItem>

                  <ListItem
                    className={classes.listItem}
                    component={Link}
                    to={{
                      pathname: "/about",
                      state: { scrollToTop: true },
                    }}
                    disableGutters
                  >
                    <ListItemText
                    
                      className={classes.listItem}
                      disableTypography
                      primary={
                        <Typography
                          variant="body2"
                          className={classes.listItem}
                        >
                          About
                        </Typography>
                      }
                    />
                  </ListItem>

                  <ListItem
                    className={classes.listItem}
                    component={Link}
                    to={{
                      pathname: "/contact",
                      state: { scrollToTop: true },
                    }}
                    disableGutters
                  >
                    <ListItemText
                      className={classes.listItem}
                      disableTypography
                      primary={
                        <Typography
                          variant="body2"
                          className={classes.listItem}
                        >
                          Contact
                        </Typography>
                      }
                    />
                  </ListItem>

                  {/* <ListItem
                    disableGutters
                    component="a"
                    href="https://medium.com"
                    target="_blank"
                    rel="noreferrer"
                    className={classes.listItem}
                  >
                    <ListItemText
                      className={classes.listItem}
                      disableTypography
                      secondary={
                        <Typography
                          variant="body2"
                          className={classes.listItem}
                        >
                          Blog
                        </Typography>
                      }
                      // secondary="Blog"
                    />
                  </ListItem> */}
                </List>
              </Grid>

      
            </Grid>
          </Grid>
        </Grid>

        <Box py={2}>
          <Divider />
        </Box>
        {props.description && (
          <Box>
            <Typography
              color="textSecondary"
              variant="h6"
              // className={classes.white}

              style={{ fontFamily: '"Cormorant Garamond", "serif"' }}
            >
              {props.description}
            </Typography>
          </Box>
        )}

        {props.copyright && (
          <Box mt={3} pb={2} display="flex" >
            <Typography variant="body2" color="textSecondary">
              {props.copyright}
            </Typography>

            
          </Box>
        )}

<Box pb={2}>
              <List disablePadding={true} className={classes.flexContainer}>
                <ListItem
                  disableGutters={true}
                  button={true}
                  component="a"
                  href="https://instagram.com/lady_bidwell"
                  target="_blank"
                  rel="noreferrer"
                  className={classes.listItem}
                >
                  <ListItemIcon className={classes.socialIcon}>
                    <Instagram />
                  </ListItemIcon>
                </ListItem>
      
              </List>
            </Box>

        <Box pb={2} display="flex">
          <Link
            to={{
              pathname: "/legal/terms-of-service",
              state: { scrollToTop: true },
            }}
          
            style={{ textDecoration: "none", color: "#adadad" }}
          >
            <Typography
              color="textSecondary"
              variant="body2"
              // component={Link}
            >
              Terms & Conditions |
            </Typography>
          </Link>

          <Link 
          to={{
            pathname: "/legal/privacy-policy",
            state: { scrollToTop: true },
          }}
          
          style={{ textDecoration: "none" }}>
            <Typography color="textSecondary" variant="body2">
              &nbsp;Privacy Policy
            </Typography>
          </Link>
        </Box>
      </Container>
    </Section>
    </>
  );
}

export default Footer;
