import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useAuth } from "./../util/auth.js";
import { useForm } from "react-hook-form";
import {
  updateLot,
  createLot,
  createMessage,
} from "./../util/db.js";
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
  },

  content: {
    paddingBottom: 24,
  },
}));

//const options = ["Option 1", "Option 2"];

function LotEdit(props) {
  const classes = useStyles();

  const auth = useAuth();
  const isAuctioneer = auth.user.role && auth.user.role === "auctioneer";
  //const isOwner = auth.user.id === (item && item.owner);

  const [pending, setPending] = useState(false);
  const [formAlert, setFormAlert] = useState(null);

  const { register, handleSubmit, errors, control } = useForm();

  const { item, lot, event } = props;

  const needsEstimate = true;
  // !isAuctioneer || (lot && lot.type && lot.type !=='declining_price') || (event && event.type && event.type !== 'declining_price')
  const canEditStartingPrice =
    (event && event.type && event.type === "declining_price") ||
    (lot && lot.type && lot.type === "declining_price") ||
    !isAuctioneer;
  
  const onSubmit = (submitData) => {
 
    setPending(true);

    /* Define nonfungible limit as cents */
    const centsStartingPrice = Math.floor(submitData.startingPrice * 100);
    const centsEstimatePrice = Math.floor(submitData.estimatePrice * 100);

    console.log("LotEdit submitData", submitData);

    var newItemData = {
      ...submitData,
      startingPrice: centsStartingPrice,
      estimatePrice: centsEstimatePrice,
      auction: item.auction,
      item: item.id,
    };

    const query = lot
      ? updateLot(item.id, newItemData)
      : createLot({ ...newItemData });

    query
      .then(() => {
   
        // Let parent know we're done so they can hide modal
        props.onDone(newItemData);

        const datagram = {
          subject: "Lady Bidwell priced the item.",
          item: item.id,
          from: "auctioneer",
          to: item.owner,
        };

        createMessage(datagram, item.owner);
  

        setPending(false);
      })
      .catch((error) => {
        console.error("LotEdit error", error);
        // Hide pending indicator
        setPending(false);
        // Show error alert message
        setFormAlert({
          type: "error",
          message: error.message,
        });
      });
  };



  if (isAuctioneer) {
    return (
      <>
      <Box py={2}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container={true} spacing={2}>
            
            
          {needsEstimate && (
            <Grid item={true} xs={12}>

        
              <TextField
                disabled={!isAuctioneer}
                variant="outlined"
                type="text"
                label="Estimate Price"
                name="estimatePrice"
                defaultValue={lot && lot.estimatePrice}
                error={errors.estimatePrice ? true : false}
                helperText={
                  errors.estimatePrice && errors.estimatePrice.message
                }
                fullWidth={true}
                autoFocus={false}
                autoComplete="off"
                inputRef={register()}
                onChange={(e) => props.onUpdate(e)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
            </Grid>
    )}
    {/*lot && lot.startingPrice ? lot.startingPrice : item.sellerStartingPrice*/}
            <Grid item={true} xs={12}>
              <TextField
                disabled={canEditStartingPrice}
                variant="outlined"
                type="text"
                label="Starting Price"
                name="startingPrice"
                defaultValue={
item && item.sellerStartingPrice 
                }
                error={errors.startingPrice ? true : false}
                helperText={
                  errors.startingPrice && errors.startingPrice.message
                }
                fullWidth={true}
                autoFocus={false}
                autoComplete="off"
                inputRef={register()}
                onChange={(e) => props.onUpdate(e)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
            </Grid>

            {props.buttons === true ? (
              <>
                <Grid item={true} xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    type="submit"
                    disabled={pending}
                  >
                   
                    {!pending && lot !== undefined ? (
                      <span>Update Lot</span>
                    ) : null}
                    {!pending && lot === undefined ? (
                      <span>Save Lot</span>
                    ) : null}

                    {pending && <CircularProgress size={28} />}
                  </Button>
                </Grid>
              </>
            ) : null}

          </Grid>
        </form>
        </Box>
      </>
    );
  }

  return null;
}

export default LotEdit;
