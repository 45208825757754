import React, { useEffect } from "react";


import { scoreHydratedItem } from "./../util/item.js";

function ScoredItems(props) {

const {item:referenceItem} = props;
  //console.log("FilteredItems props.items", props.items);
  //console.log("FilteredItems props.hydratedItems", props.hydratedItems);

console.log("HydratedItems props.filterType props.items", props.filterType, props.items);


  const updateWithprops = React.Children.map(props.children, (child, i) => {
    const scoredItems = props.items.map((item) => {

return {...item, score:scoreHydratedItem(item, {id:referenceItem.id, item:referenceItem})};

    });



    return React.cloneElement(child, { color: "red", items: scoredItems });
  });



useEffect(()=>{

console.log("FilteredItems props.items", props.items);

}, [props.items]);



  return <div>{updateWithprops}</div>;
}

export default ScoredItems;
