import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import { useAuth } from "./../util/auth.js";
import {
  useItemsByAuction,
} from "./../util/db.js";
import { makeStyles } from "@material-ui/core/styles";
import ItemsGrid from "./../components/ItemsGrid";
import Container from "@material-ui/core/Container";

const useStyles = makeStyles((theme) => ({
  paperItems: {
    minHeight: "300px",
  },
  featured: {
    backgroundColor:
      theme.palette.type === "dark" ? theme.palette.action.selected : "#fdf8c2",
  },
  starFeatured: {
    color: theme.palette.warning.main,
  },
}));

function AuctionItemList(props) {
  const { filter } = props;

  var { flavour, event } = props;
  //  flavour = flavour === undefined ? "standard" : "simple";

  const classes = useStyles();

  const [thumbStatus, setThumbStatus] = useState("loading");

  const auth = useAuth();

  const {
    data: items,
    status: itemsStatus,
    error: itemsError,
  } = useItemsByAuction(props.event.id);

  const [creatingItem, setCreatingItem] = useState(false);

  const [updatingItemId, setUpdatingItemId] = useState(null);

  const itemsAreEmpty = !items || items.length === 0;
  const [filteredItems, setFilteredItems] = useState([]);
  const [filteredItemsStatus, setFilteredItemsStatus] = useState("waiting");


  useEffect(() => {
    var tempItems = [];

    if (itemsStatus !== "success") {
      return;
    }

    if (filter === "star") {
      tempItems = items;
      console.log("items", items);
      tempItems = items.filter((item) => item.starred === true);
      setFilteredItems(tempItems);
    } else {
      setFilteredItems(items);
    }
    setFilteredItemsStatus("success");
  
    console.log("asdsf", items.length)
  if (typeof props.handleItemCount !== "undefined") {
    props.handleItemCount(items.length);
  }
  }, [items, itemsStatus]);


  if (filteredItemsStatus !== "success") {
    return null;
  }

  return (
    <>
      {/* 
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    > */}


    
      <Container>
        {itemsError && (
          <Box mb={3}>
            <Alert severity="error">{itemsError.message}</Alert>
          </Box>
        )}

        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          padding={1}
        >
          {/* <Typography className={classes.text} variant="h6">
            Browse Lots
          </Typography> */}
        </Box>
        {/* <Divider /> */}

        {itemsStatus !== "loading" && items && filteredItems.length > 0 && (
          <ItemsGrid event={event} items={filteredItems} flavour={flavour} />
      
      
        )}
      </Container>
      {/* </Section> */}
    </>
  );
}

export default AuctionItemList;
