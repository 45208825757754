/* 

Dear future Nick and Mike.

This is not particularly well developed right now.
It doesn't delete images.
It doesn't allow other images to be uploaded.

Dev is to factor ou UploadImages and EditImages into a new component.
Shared between EditEventModal and EditItemModal.

Otherwise there is two sets of image handling code.
Double plus ungood.

Just a reminder when you dont think this is working very well.

*/

import React, { useState, useEffect, useRef } from "react";
import { zuluTime, muiZuluTime } from "../util/time.js";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { DialogActions, Typography, Checkbox, Divider } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useAuth } from "./../util/auth.js";
import { useForm } from "react-hook-form";
import {
  useUncachedEvent,
  updateEvent,
  createEvent,
  createMessage,
  useEventsByOwner,
  deleteBucketImage,
} from "./../util/db.js";

import { makeLots } from "./../util/lot.js";
import { updatePriceTables } from "./../util/price.js";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import {
  Select,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Slide,
  useMediaQuery,
  Switch,
} from "@material-ui/core";
import { Controller } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import { BlockPicker } from "react-color";
import ImagesManager from "./ImagesManager.js";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getSlug } from "../util/slug";

import { debugFlag, privateCatchallFlag } from "./../util/dev.js";

const functions = getFunctions();

const getFirebaseTime = httpsCallable(functions, "getFirebaseTime");
const processEmail = httpsCallable(functions, "processEmail");

const menuProps = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  getContentAnchorEl: null,
};

// TODO
// Get visible auctions.
// a) Lady Bidwell's available auctions. By event time horizon
// b) Not applicable.
// c) [...] post MVP
// Add default auction dropdown.

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  textarea: {
    resize: "both",
  },

  select: {
    display: "flex",
    minWidth: 250,
    // background: 'white',
    borderStyle: "none",
    borderRadius: 8,
    // paddingTop: 14,
    // paddingBottom: 15,
    marginBottom: 15,
    boxShadow: "none",
    background: "#00000014",
    "&:focus": {
      borderRadius: 8,
      backgroundColor: "none",
      // background: 'white',
    },
    '&[aria-expanded="true"]': {
      background: "none",
    },
    "& > div": {
      display: "inline-flex", // this shows the icon in the SelectInput but not the dropdown
      alignItems: "center",
    },
  },

  listIcon: {
    minWidth: "40px",
    paddingLeft: "10px",
  },

  content: {
    paddingBottom: 24,
  },
  dialogTitle: {
    textAlign: "center",
    fontFamily: '"Cormorant Garamond", "serif"',
  },
  backDrop: {
    backdropFilter: "blur(3px)",
    backgroundColor: "rgba(0,0,30,0.4)",
  },
}));

function EditEventModal(props) {
  const classes = useStyles();
  const auth = useAuth();
  const [pending, setPending] = useState(false);
  const [formAlert, setFormAlert] = useState(null);
  const { seller } = props;

  const CHARACTER_LIMIT = 60;

  const [values, setValues] = useState({
    name: "",
    // name: "this is an event name"
  });

  const handleChange = (name) => (event) => {
    setValues({ ...values, [name]: event.target.value });
  };

  // Get a list of all the acutions that can be associated with this event
  const auctions = useEventsByOwner("curator", "future");
  // This will fetch event if props.id is defined
  // Otherwise query does nothing and we assume
  // we are creating a new event.
  const { data: eventData, status: eventStatus } = useUncachedEvent(props.id);

  const { register, handleSubmit, watch, errors, control } = useForm();

  // const { register, handleSubmit, watch, control, formState: { errors }  } = useForm();
  const handleEntered = () => {
    window.$crisp.push(["do", "chat:hide"]);
  };

  const handleExited = () => {
    window.$crisp.push(["do", "chat:show"]);
  };
  const [eventId, setEventId] = useState(props.id);
  const [imageIndex, setImageIndex] = useState(0);

  useEffect(() => {
    console.log("EditEventModal errors", errors);
  }, [errors]);

  //const [auctionLabel, setAuctionLabel] = useState("Please select an auction");

  const hasAccount = auth && auth.user && auth.user.id !== undefined;
  const isAuctioneer =
    auth && auth.user && auth.user.role && auth.user.role === "auctioneer";

  const isDeveloper = auth?.user?.developer?.enabled;

  const eventType = isAuctioneer ? "Sale" : "Collection";

  const [auction, setAuction] = useState(false);
  //const [availableAuctions, setAvailableAuctions] = useState();
  //const [availableAuctionsStatus, setAvailableAuctionsStatus] = useState('waiting');

  const saleTypeRaw = watch("saletype");
  const saleType = saleTypeRaw ? saleTypeRaw : "estate_sale";

  useEffect(() => {
    if (saleType === undefined) {
      return;
    }
  }, [saleType]);

  // Needed?
  const [editImagesKey, setEditImagesKey] = useState(uuidv4());
  const [uploadedImages, setUploadedImages] = useState([]);

  const [unsavedImages, setUnsavedImages] = useState([]);
  const [initialImages, setInitialImages] = useState([]);

  const [latestImages, setLatestImages] = useState([]);

  const ref = useRef({ maxIndex: 0 });
  const defaultColour = "#f7f7f7";

  // const [colour, setColour] = useState(defaultColour);
  const [colour, setColour] = useState();
  const [endTime, setEndTime] = useState();

  /*
  useEffect(() => {
    console.log("EditEventModal colour eventData", eventData);
    if (typeof colour === "undefined" && eventData.color === "undefined") {
      setColour(defaultColour);
    }
  }, [colour, eventData]);  
  */

  // useEffect(() => {

  //   console.log("EditEventModal colour", colour);
  // }, [colour]);

  // useEffect(() => {
  //   console.log("EditEventModal colour", colour);
  // }, []);

  useEffect(() => {
    if (eventStatus === "idle" || eventData === undefined) {
      setAuction("");
      return;
    }

    // OK - we are here.
    // Need to resolve that the color and images are Auctioneer things.
    // So they belong with the auction. Not the event.
    // The creator of the event can't change the color or the image.

    // Work on this with Mike.

    // At the moment because we are LadyBidwell, it \doens't see a corresponding auction.
    // So doesn't load in stuff.

    if (eventStatus !== "success") {
      return;
    }

    console.log("EditEventModal colour eventData", eventData);

    if (eventData.images === undefined) {
      setUploadedImages([]);
      setUnsavedImages([]);
      setInitialImages([]);
      ref.current.maxIndex = 0;
    } else {
      setUploadedImages(eventData.images);
      setUnsavedImages(eventData.images);
      setInitialImages(eventData.images);
      ref.current.maxIndex = pickHighest(eventData.images);
    }

    if (colour == null) {
      if (eventData.colour == null) {
        console.log("EditEventModal colour eventData.colour undefined");
        setColour(defaultColour);
      } else {
        console.log(
          "EditEventModal colour eventData.colour",
          eventData.colour,
          colour
        );

        setColour(eventData.colour);

        console.log("colour picker", colour);
      }
    }

    if (auctions.status !== "success") {
      return;
    }

    if (eventData.auction === undefined) {
      setAuction("");
      return;
    }

    // if (eventData.saletype === undefined) {
    //   ///      setAuction("");
    //   return;
    // }

    const auctionElement = auctions.data.find(
      (element) => element.id === eventData.auction
    );

    //const name = (auctionElement === undefined ? null : auctionElement.name);

    setAuction(eventData.auction);
    //setAuctionLabel(name);
  }, [eventData, eventStatus, auctions]);

  const handleEndTime = (event) => {
    setEndTime(event.target.value);
  };
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  function handleImage(image) {
    // Get the image meta information to allo image deduplication and deletion/editing.
    const { name, path, lastModifiedDate, size, type } = image.file.file;

    var imageData = {
      index: imageIndex,
      id: image.filename,
      location: image.location,
      name: name,
      path: path,
      size: size,
      type: type,
    };
    setEditImagesKey(uuidv4());
    setUploadedImages((uploadedImages) => [...uploadedImages, imageData]);
  }

  useEffect(() => {
    console.log("EditEventModal colour useEffect", colour);
  }, [colour]);

  const onSubmit = (data) => {
    if (props.onSubmit) {
      props.onSubmit();
    }
    console.log("EditEventModal onSubmit clicked");
    setPending(true);

    // Check if there is images in initialImages,
    // which are not in the unsavedImages list.
    // If there are they need to be deleted from the image bucket.

    initialImages.map((image, i) => {
      const found = unsavedImages.find(
        (element) => element.location === image.location
      );
      if (found === undefined) {
        deleteBucketImage(image.location);
      }
    });

    console.log("EditEventModal onSubmit colour", colour);

    var eventColour = defaultColour;
    if (typeof colour !== "undefined") {
      eventColour = colour;
    }

    var newData = {
      ...data,
      images: latestImages,
      colour: eventColour,
      //colour: colour,
      //     palette: paletteData,
    };

    console.log("EditEventModal newData", newData);

    var userId = "unknown";
    if (auth.user.id !== undefined) {
      userId = auth.user.id;
    }

    if (!newData.slug) {
      newData.slug = getSlug(newData.name);
    }

    if (newData.slug) {
      newData.slug = getSlug(newData.slug);
    }

    //// Make sure times are UTC (Zulu)
    // Date picker proves local time.

    //const st = new Date(newData.startTime)
    //const utcSt = st.toUTCString();
    const utcSt = zuluTime(newData.startTime);
    //newData.startTime = utcSt;

    //const et = new Date(newData.endTime)
    //const utcEt = et.toUTCString();
    const utcEt = zuluTime(newData.endTime);

    newData = { ...newData, startTime: utcSt, endTime: utcEt };
    //newData = { ...newData, startTime: utcSt, endTime: utcEt };

    if (props.sellerType !== undefined) {
      var name = auth.user.name;
      if (newData.name !== undefined) {
        name = newData.name;
      }

      const subject = "New Seller [" + props.sellerType + "]: " + name;
      var text =
        "Seller type not recognized " +
        "http://localhost/seller/" +
        userId +
        ".";

      var contact =
        auth && auth.user && auth.user.email ? auth.user.email : null;
      if (newData && newData.contact) {
        contact = newData.contact;
      }

      var url = "Not a logged in user.";
      if (userId !== "unknown") {
        url = "http://localhost/seller/" + userId;
      }

      if (props.sellerType === "professional") {
        text =
          url +
          "\n" +
          " Wants to know how to be a seller.\n" +
          newData.name +
          "\n" +
          newData.location +
          "\n" +
          newData.description +
          "\n" +
          contact +
          "\n";
      }

      if (props.sellerType === "one off") {
        text =
          url +
          "\n" +
          " Wants to know how to be a seller.\n" +
          newData.name +
          "\n" +
          newData.location +
          "\n" +
          contact +
          "\n";
      }

      const datagram = {
        to: "administrator",
        subject: subject,
        text: text,
        transport: "email",
      };

      // Not always logged in. If this is a new request to be a seller, only
      // allow the backend to send messages to the administrator.
      if (newData.contact !== undefined) {
        return processEmail(datagram)
          .then((result) => {
            props.onDone();
            return;
          })
          .catch((error) => {
            console.error("EditEventModal processEmail error", error);
          });
      }
      createMessage(datagram, userId);
    }

    // As of 15 April 2023.
    // Moved the make lots call to after the updateEvent createEvent call.
    // This ensures the update has happened.

    // Test as of 20 Sep 2022
    // event
    //console.log("EditEventModal makeLots newData", newData)

    // In the case of a new event, there is no need to makeLots.
    console.log("EditEventModal eventId", eventId)

    // haven't chnged any part of the event yet.

/*
    if (typeof eventId !== "undefined") {

      console.log("EditItemModal trigger makeLots");

      const eventTemp = { ...newData, id: eventId };
      makeLots(eventTemp)
        .then((result) => {
          console.log(
            "EditEventModal makeLots result",
            eventId,
            result,
            eventTemp
          );

          updatePriceTables(eventTemp);
        })
        .catch((error) => {
          console.error("EditEventModal makeLots error", error);
        });
    }
*/
    //console.log("EditEventModal makeLots", newData)

    console.log("EditEventModal newData", newData);

    const query = props.id
      ? updateEvent(props.id, newData)
      : createEvent({ owner: userId, ...newData });
    query
      .then((docRef) => {

// Get the event id.

// Curious this is needed, because the updateLot call does not return 
// an event id.


// Need to check because we might be dealing with a newly created lot.
// So no need to run makeLots.
// And running makeLots will trigger errors.

if (typeof eventId !== "undefined") {
var tempEventId = eventId;

// There will never be a lot in a newly created event.
/*
if (docRef && docRef.id) {
   // createEvent specific
   console.log("EditEventModal xkcd result", eventId, docRef, docRef.id);
tempEventId = docRef.id;
}
*/

// console.log("EditItemModal trigger docRef", docRef);

      const eventTemp = { ...newData, id: tempEventId };
      makeLots(eventTemp)
        .then((result) => {

          console.log(
            "EditEventModal update create lot result",
            eventId,
            result,
            eventTemp
          );

          updatePriceTables(eventTemp);
        })
        .catch((error) => {
          console.error("EditEventModal makeLots error", error);
        });
      }

// Probably here where makeLots should go.

        // Let parent know we're done so they can hide modal
        props.onDone();
      })
      .catch((error) => {
        classes.log("EditEventModal xkcd error", error);
        // Hide pending indicator
        setPending(false);
        // Show error alert message
        setFormAlert({
          type: "error",
          message: error.message,
        });
      });
  };
  /*
const handleChangeComplete = (color) => {

setColour({background:color.hex})
console.log("EditEventModal color", color)
}
*/

  useEffect(() => {
    setEditImagesKey(uuidv4());
    ref.current.maxIndex = pickHighest(uploadedImages);
  }, [uploadedImages, setUploadedImages]);

  const pickHighest = (arr) => {
    var maxIndex = 0;
    arr.forEach((el) => {
      const { index } = el;
      if (index > maxIndex) {
        maxIndex = index;
      }
    });
    return maxIndex;
  };

  function handleReorderImages(images) {
    setUploadedImages(images);
  }



  function handleImage(image) {
    // Get the image meta information to allo image deduplication and deletion/editing.
    const { name, path, lastModifiedDate, size, type } = image.file.file;

    ref.current.maxIndex += 1;

    var imageData = {
      index: ref.current.maxIndex,
      id: image.filename,
      location: image.location,
      name: name,
      path: path,
      size: size,
      type: type,
    };
    setEditImagesKey(uuidv4());

    setUploadedImages((uploadedImages) => [...uploadedImages, imageData]);
    setUnsavedImages((unsavedImages) => [...unsavedImages, imageData]);
    //setUnsavedImages(uploadedImages);

    // Does not trigger a write of the firestore collection record.
  }

  const handleImagesUpdate = (images) => {
    setLatestImages(images.latestImages);
    setUnsavedImages(images.unsavedImages);
  };

  function handleReorderImages(images) {
    setUnsavedImages((prevImages) => images);
  }

  useEffect(() => {}, [unsavedImages]);

  useEffect(() => {}, [uploadedImages]);

  const handleChangeAuction = (event) => {
    setAuction(event.target.value);
  };
  // If we are updating an existing event
  // don't show modal until event data is fetched.
  if (props.id && eventStatus !== "success") {
    return null;
  }

  if ((auctions && auctions.status !== "success") || auction === false) {
    return null;
  }

  if (props.sellerType === "one off") {
    return (
      <Dialog
        TransitionComponent={Transition}
        fullScreen={fullScreen}
        disableBackdropClick="true"
        open={true}
        BackdropProps={{
          classes: {
            root: classes.backDrop,
          },
        }}
        onClose={props.onDone}
        maxWidth="md"
        onEntered={handleEntered}
      >
        <Box p={1}>
          <DialogTitle>
            <Typography variant="h4" className={classes.dialogTitle}>
              {!props.id && <>Individual Seller Enquiry</>}
            </Typography>
          </DialogTitle>

          {/*hasAccount ? "Is a current user" : "Not a current user"*/}

          <form formId="seller_enquiry" onSubmit={handleSubmit(onSubmit)}>
            <DialogContent className={classes.content}>
              {formAlert && (
                <Box mb={4}>
                  <Alert severity={formAlert.type}>{formAlert.message}</Alert>
                </Box>
              )}

              {isDeveloper && (
                <Switch
                  defaultChecked={eventData && eventData.test}
                  //onChange={(e) => handleChange("developer.enabled", e)}
                  name="test"
                  inputProps={{ "aria-label": "secondary checkbox" }}
                  inputRef={register()}
                />
              )}

              <Grid container={true} spacing={2}>
                {hasAccount === false ? (
                  <>
                    <Grid item={true} xs={12}>
                      <TextField
                        multiline
                        variant="outlined"
                        type="text"
                        label="Your Name"
                        placeholder="e.g. Charlotte Bronte"
                        name="name"
                        defaultValue={eventData && eventData.name}
                        error={errors.name ? true : false}
                        helperText={errors.name && errors.name.message}
                        fullWidth={true}
                        // autoFocus={true}
                        InputLabelProps={{ shrink: true }}
                        inputRef={register({
                          required: "Please enter your name",
                        })}
                      />
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item={true} xs={12}>
                      <TextField
                        variant="outlined"
                        type="text"
                        label="Your Name"
                        name="name"
                        defaultValue={auth.user.name}
                        error={errors.name ? true : false}
                        helperText={errors.name && errors.name.message}
                        fullWidth={true}
                        // autoFocus={true}
                        InputLabelProps={{ shrink: true }}
                        inputRef={register({
                          required: "Please enter your name",
                        })}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                  </>
                )}

                <Grid item={true} xs={12}>
                  <TextField
                    multiline
                    variant="outlined"
                    type="text"
                    label="Location of Items"
                    placeholder="e.g. The Hamptons, New York City, Palm Beach."
                    name="location"
                    defaultValue={eventData && eventData.location}
                    error={errors.location ? true : false}
                    helperText={errors.location && errors.location.message}
                    fullWidth={true}
                    // autoFocus={true}
                    InputLabelProps={{ shrink: true }}
                    inputRef={register({
                      required: "Please tell us the location of the items.",
                    })}
                  />
                </Grid>

                <Grid item={true} xs={12}>
                  <TextField
                    multiline
                    minRows={6}
                    variant="outlined"
                    type="text"
                    label="Items Description"
                    placeholder="Describe the type of items you are looking to sell, including how many pieces, value, period, style, and any other pertinent information."
                    name="description"
                    defaultValue={eventData && eventData.name}
                    error={errors.name ? true : false}
                    helperText={
                      errors.description && errors.description.message
                    }
                    fullWidth={true}
                    // autoFocus={true}
                    InputLabelProps={{ shrink: true }}
                    inputRef={register({
                      required:
                        "Please describe what you want to sell with Lady Bidwell",
                    })}
                  />
                </Grid>

                {hasAccount === false ? (
                  <>
                    <Grid item={true} xs={12}>
                      <TextField
                        multiline
                        variant="outlined"
                        type="text"
                        label="Contact Information (email and phone)"
                        placeholder="e.g. name@gmail.com, 717-777-7777"
                        name="contact"
                        defaultValue={eventData && eventData.contact}
                        error={errors.contact ? true : false}
                        helperText={errors.contact && errors.contact.message}
                        fullWidth={true}
                        // autoFocus={true}
                        InputLabelProps={{ shrink: true }}
                        inputRef={register({
                          required: "Contact information needed.",
                        })}
                      />
                    </Grid>
                  </>
                ) : null}
                <Grid item={true} xs={12}>
                  <Typography variant="body1">
                    After submitting we will contact you within the next
                    business day and match you with the perfect seller.
                  </Typography>
                </Grid>
              </Grid>
            </DialogContent>
            <Box p={2}>
              <DialogActions
                style={{
                  position: "sticky",
                  bottom: "0",
                  background: "#ffffff",
                  // borderTop: "2px solid #c4c4c4",
                }}
              >
                <Box>
                  <Button
                    variant="outlined"
                    color="primary"
                    size="large"
                    onClick={props.onDone}
                    disabled={pending}
                    style={{ justifyContent: "flex-start" }}
                  >
                    <span>Cancel</span>
                  </Button>
                </Box>

                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  type="submit"
                  disabled={pending}
                >
                  {!pending && <span>Submit</span>}

                  {pending && <CircularProgress size={28} />}
                </Button>
              </DialogActions>
            </Box>
          </form>
        </Box>
      </Dialog>
    );
  }

  if (props.sellerType === "professional") {
    return (
      <Dialog
        TransitionComponent={Transition}
        fullScreen={fullScreen}
        disableBackdropClick="true"
        open={true}
        onClose={props.onDone}
        maxWidth="md"
        onEntered={handleEntered}
        BackdropProps={{
          classes: {
            root: classes.backDrop,
          },
        }}
      >
        <Box p={1}>
          <DialogTitle className={classes.dialogTitle}>
            <Typography variant="h4" className={classes.dialogTitle}>
              {!props.id && <>Professional Seller Enquiry</>}
            </Typography>
          </DialogTitle>

          {/*hasAccount ? "Is a current user" : "Not a current user"*/}

          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent className={classes.content}>
              {formAlert && (
                <Box mb={4}>
                  <Alert severity={formAlert.type}>{formAlert.message}</Alert>
                </Box>
              )}

              {isDeveloper && (
                <Switch
                  defaultChecked={eventData && eventData.test}
                  //onChange={(e) => handleChange("developer.enabled", e)}
                  name="test"
                  inputProps={{ "aria-label": "secondary checkbox" }}
                  inputRef={register()}
                />
              )}

              <Grid container={true} spacing={2}>
                {hasAccount === false ? (
                  <>
                    <Grid item={true} xs={12}>
                      <TextField
                        multiline
                        variant="outlined"
                        type="text"
                        label="Your Name"
                        placeholder="e.g. Charlotte Bronte"
                        name="name"
                        defaultValue={eventData && eventData.name}
                        error={errors.name ? true : false}
                        helperText={errors.name && errors.name.message}
                        fullWidth={true}
                        // autoFocus={true}
                        InputLabelProps={{ shrink: true }}
                        inputRef={register({
                          required: "Please enter your name",
                        })}
                      />
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item={true} xs={12}>
                      <TextField
                        variant="outlined"
                        type="text"
                        label="Your Name"
                        name="name"
                        defaultValue={auth.user.name}
                        error={errors.name ? true : false}
                        helperText={errors.name && errors.name.message}
                        fullWidth={true}
                        // autoFocus={true}
                        InputLabelProps={{ shrink: true }}
                        inputRef={register({
                          required: "Please enter your name",
                        })}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                  </>
                )}

                <Grid item={true} xs={12}>
                  <TextField
                    multiline
                    minRows={5}
                    variant="outlined"
                    type="text"
                    label="Type of Business"
                    placeholder="Please describe the type of business e.g. estate sales, dealer, shop, gallery, interior designer."
                    name="description"
                    defaultValue={eventData && eventData.description}
                    error={errors.description ? true : false}
                    helperText={
                      errors.description && errors.description.message
                    }
                    fullWidth={true}
                    // autoFocus={true}
                    InputLabelProps={{ shrink: true }}
                    inputRef={register({
                      required: "Please describe your type of business",
                    })}
                  />
                </Grid>

                <Grid item={true} xs={12}>
                  <TextField
                    multiline
                    variant="outlined"
                    type="text"
                    label="Primary Selling Location"
                    placeholder="e.g. The Hamptons, New York City, Palm Beach."
                    name="location"
                    defaultValue={eventData && eventData.location}
                    error={errors.location ? true : false}
                    helperText={errors.location && errors.location.message}
                    fullWidth={true}
                    // autoFocus={true}
                    InputLabelProps={{ shrink: true }}
                    inputRef={register({
                      required: "Please describe where you mainly do business.",
                    })}
                  />
                </Grid>

                {hasAccount === false ? (
                  <>
                    <Grid item={true} xs={12}>
                      <TextField
                        multiline
                        variant="outlined"
                        type="text"
                        label="Contact Information (email and phone)"
                        placeholder="e.g. name@business.com, 717-777-7777"
                        name="contact"
                        defaultValue={eventData && eventData.contact}
                        error={errors.contact ? true : false}
                        helperText={errors.contact && errors.contact.message}
                        fullWidth={true}
                        // autoFocus={true}
                        InputLabelProps={{ shrink: true }}
                        inputRef={register({
                          required: "Contact information needed.",
                        })}
                      />
                    </Grid>
                  </>
                ) : null}
                <Grid item={true} xs={12}>
                  <Typography variant="body1">
                    After submitting we will contact you within the next
                    business day to ask any further questions / start to onboard
                    you as a seller.
                  </Typography>
                </Grid>

                {/* // <>
                //   Thank you {auth.user.name}. After clicking submit please
                //   expect us to contact you within 24 hours. Otherwise call (555)
                //   5555-5555.
                // </> */}
              </Grid>
            </DialogContent>

            <DialogActions
              style={{
                position: "sticky",
                // bottom: "0",
                background: "#ffffff",
              }}
            >
              <Box>
                <Button
                  variant="outlined"
                  color="primary"
                  size="large"
                  onClick={props.onDone}
                  disabled={pending}
                  style={{ justifyContent: "flex-start" }}
                >
                  <span>Cancel</span>
                </Button>
              </Box>

              <Button
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                disabled={pending}
              >
                {!pending && <span>Submit</span>}

                {pending && <CircularProgress size={28} />}
              </Button>
            </DialogActions>
          </form>
        </Box>
      </Dialog>
    );
  }

  return (
    <Dialog
      TransitionComponent={Transition}
      fullScreen={fullScreen}
      disableBackdropClick="true"
      open={true}
      onClose={props.onDone}
      maxWidth="md"
      onEntered={handleEntered}
      BackdropProps={{
        classes: {
          root: classes.backDrop,
        },
      }}
    >
      <DialogTitle>
        <Typography variant="h4" className={classes.dialogTitle}>
          {props.id && <>Update</>}
          {!props.id && <>Create New</>}
          {` `}
          {eventType}
        </Typography>
      </DialogTitle>

      {/*hasAccount ? "Is a current user" : "Not a current user"*/}

      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent className={classes.content}>
          {formAlert && (
            <Box mb={4}>
              <Alert severity={formAlert.type}>{formAlert.message}</Alert>
            </Box>
          )}

          {isDeveloper && (
            <Switch
           
              defaultChecked={eventData && eventData.test}
              //onChange={(e) => handleChange("developer.enabled", e)}
              name="test"
              inputProps={{ "aria-label": "secondary checkbox" }}
              inputRef={register()}
            />
          )}

          {hasAccount === false ? <></> : null}




          <Grid container={true} spacing={3}>
            {isAuctioneer ? (
              <>
                <Grid item={true} xs={12}>
                  <ImagesManager
                    item={eventData}
                    onUpdate={(e) => handleImagesUpdate(e)}
                  />
                </Grid>

                <Grid item={true} xs={12}>
                  <BlockPicker
                    width="100%"
                    color={colour}
                    presetColors={[
                      "#D0021B",
                      "#F5A623",
                      "#F8E71C",
                      "#8B572A",
                      "#7ED321",
                      "#417505",
                      "#BD10E0",
                      "#9013FE",
                      "#4A90E2",
                      "#50E3C2",
                      "#B8E986",
                      "#000000",
                      "#4A4A4A",
                      "#9B9B9B",
                      "#FFFFFF",
                    ]}
                    onChangeComplete={(colour) => {
                      console.log(
                        "EditEventModal colour onChangeComplete setColour colour",
                        colour
                      );
                      setColour(colour.hex);
                    }}
                  />
                </Grid>
              </>
            ) : null}


            {isAuctioneer ? (
              <>
                <Grid item={true} xs={12}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Type</FormLabel>
                    <Controller
                      style={{ flexDirection: "row" }}
                      rules={{ required: true }}
                      control={control}
                      defaultValue={eventData && eventData.type}
                      name="type"
                      as={
                        <RadioGroup>
                          <FormControlLabel
                            value="declining_price"
                            control={<Radio />}
                            label="Declining Price"
                          />
                          <FormControlLabel
                            value="increasing_bid"
                            control={<Radio />}
                            label="Traditional Auction"
                          />
                              <FormControlLabel
                            value="marketplace_price"
                            control={<Radio />}
                            label="Marketplace"
                          />
                        </RadioGroup>
                      }
                    />
                  </FormControl>
                </Grid>
              </>
            ) : null}

{privateCatchallFlag === true && !isAuctioneer && (<>
<Grid item={true} xs={12}>

<FormControlLabel
        control={<Checkbox onChange={handleChange} name="private" defaultChecked={eventData && eventData.private} />}
        label={<Typography variant="subtitle1" style={{fontWeight:600}}>Add To Your Catch-All / Mixed Collection</Typography>}
        inputRef={register()}
      />
      <Typography>Check box if you only have one or two items or want to keep the client/collection name private. The buyer pickup details below will be shared with successful buyers. </Typography>
</Grid>


<Divider style={{width:'100%'}}  />
<br/>
</>)}
            {true && (
              <>
                <Grid item={true} xs={12}>
                  <TextField
                    multiline
                    variant="outlined"
                    type="text"
                    label={"Collection Name (public)"}
                    placeholder="e.g. Stunning Meadow Lane Estate Sale (keep it classy, no exclamations etc)"
                    name="name"
                    defaultValue={eventData && eventData.name}
                    error={errors.name ? true : false}
                    // helperText={errors.name && errors.name.message}
                    fullWidth={true}
                    InputLabelProps={{ shrink: true }}
                    maxLength={CHARACTER_LIMIT}
                    helperText={`${values.name.length}/${CHARACTER_LIMIT}`}
                    onChange={handleChange("name")}
                    inputRef={register({
                      required: "Please enter a Event Name",
                    })}
                  />
                </Grid>

              </>
            )}

            <Grid item={true} xs={12}>
              <TextField
                variant="outlined"
                type="text"
                label="General Location of Items (public)"
                placeholder="e.g. Southampton Village"
                name="location"
                defaultValue={eventData && eventData.location}
                error={errors.location ? true : false}
                helperText={errors.location && errors.location.message}
                fullWidth={true}
                // autoFocus={true}
                InputLabelProps={{ shrink: true }}
                inputRef={register({
                  required:
                    "Please enter the town/area the collection is in (public)",
                })}
              />
            </Grid>

            {isAuctioneer ? null : (
              <>
                <Grid item={true} xs={12}>
                  <TextField
                    multiline
                    minRows={3}
                    variant="outlined"
                    type="text"
                    label="Collection Description (public)"
                    placeholder="e.g. This is a fine  sale"
                    name="review"
                    defaultValue={eventData && eventData.review}
                    error={errors.review ? true : false}
                    helperText={errors.review && errors.review.message}
                    fullWidth={true}
                    // autoFocus={true}
                    InputLabelProps={{ shrink: true }}
                    inputRef={register({
                      required: "Please enter a description.",
                    })}
                  />
                </Grid>

                {!isAuctioneer ? (
                  <>
                    <Grid item={true} xs={12}>
                      <FormControl component="fieldset">
                        <FormLabel component="legend">
                      <Typography variant="body2"> Pickup / Shipping Options (public)</Typography>   
                        </FormLabel>
                      <Typography variant="p">Seller is responsible for coordinating pickup and shipping. </Typography>  
                     
                        <Controller
                          style={{ flexDirection: "row" }}
                          rules={{
                            required: "Pickup / Delivery Options Required",
                          }}
                          control={control}
                          defaultValue={eventData && eventData.deliveryoptions}
                          name="deliveryoptions"
                          error={errors.deliveryoptions ? true : false}
                          helpertext={
                            errors.deliveryoptions &&
                            errors.deliveryoptions.message
                          }
                  

                          as={
                            <RadioGroup>
                            
                              <FormControlLabel
                                value="pickup_only"
                                control={<Radio />}
                                label="Pickup Only"
                              />
                              <FormControlLabel
                                value="pickupand_shipping"
                                control={<Radio />}
                                label="Pickup and Shipping"
                              />
                              <FormControlLabel
                                value="shipping_only"
                                control={<Radio />}
                                label="Shipping Only"
                              />
                            </RadioGroup>
                          }
                        />



                        <Typography variant="body1" style={{ color: "#f44336" }}>
                          {errors &&
                            errors.deliveryoptions &&
                            errors.deliveryoptions.message}
                        </Typography>
                      </FormControl>
                    </Grid>
                  </>
                ) : null}

                <Grid item={true} xs={12}>
                  <TextField
                    multiline={true}
                    variant="outlined"
                    type="text"
                    placeholder="e.g. Pick Up only on XX date no shipping / For shipping quotes please contact us"
                    name="pickupandshipping"
                    defaultValue={eventData && eventData.pickupandshipping}
                    error={errors.pickupandshipping ? true : false}
                    helperText={
                      errors.pickupandshipping &&
                      errors.pickupandshipping.message
                    }
                    padding="normal"
                    label="Pick Up & Shipping Details (public)"
                    InputLabelProps={{ shrink: true }}
                    fullWidth={true}
                    // autoFocus={true}
                    inputProps={{ className: classes.textarea }}
                    inputRef={register({
                      required:
                        "Please enter Pickup/Shipping details (where and when-by)",
                    })}
                  />
                </Grid>

                <Grid item={true} xs={12}>
                  <TextField
                    multiline={true}
                    variant="outlined"
                    type="text"
                    label="Address for Buyer Pickup. (PRIVATE - will only be shared with winning buyers by email)"
                    placeholder="Provide exact address for buyer pickup.  All buyer pickup information should be listed in full here."
                    name="address"
                    InputLabelProps={{ shrink: true }}
                    defaultValue={eventData && eventData.address}
                    error={errors.address ? true : false}
                    helperText={errors.address && errors.address.message}
                    fullWidth={true}
                    inputProps={{ className: classes.textarea }}
                    inputRef={register({
                      required: "Please enter an address",
                    })}
                  />
                </Grid>

                <Grid item={true} xs={12}>
                  <TextField
                    multiline
                    minRows={2}
                    variant="outlined"
                    type="text"
                    label="Collection Memo (optional - PRIVATE)"
                    placeholder="e.g. client details, commision rates etc. This is for your use only and is private."
                    name="memo"
                    defaultValue={eventData && eventData.memo}
                    error={errors.memo ? true : false}
                    helperText={errors.memo && errors.memo.message}
                    fullWidth={true}
                    InputLabelProps={{ shrink: true }}
                    inputRef={register({
                      required: false,
                    })}
                  />
                </Grid>

              </>
            )}

            {isAuctioneer ? null : (
              <>
                <Grid item={true} xs={12}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                    fullWidth
                  >
                    <InputLabel
                      id="input_designationselected"
                      style={{
                        backgroundColor: "white",
                        paddingLeft: "5px",
                        paddingRight: "5px",
                      }}
                    >
                      Choose default weekly sale to submit items to
                    </InputLabel>
                    <Controller
                      // required
                      defaultValue={auction}
                      fullWidth
                      control={control}
                      name="auction"
                      //error={true}
                      //helperText="I do not think this is helful."
                      innerRef={register}
                      as={
                        <Select id="auction-select">
                          {auctions.data.map((_auction) => (
                            <MenuItem key={_auction.id} value={_auction.id}>
                              {_auction.name}
                            </MenuItem>
                          ))}
                        </Select>
                      }
                    />
                  </FormControl>
                </Grid>
              </>
            )}

            {!isAuctioneer ? null : (
              <>
                <Grid item={true} xs={12}>
                  <TextField
                    multiline
                    minRows={4}
                    fullWidth
                    variant="outlined"
                    id="headline-local"
                    label="Headline"
                    type="text"
                    name="headline"
                    //defaultValue="2017-05-24T10:30"
                    defaultValue={eventData && eventData.headline}
                    className={classes.textField}
                    //value={endTime}
                    //onChange={handleEndTime}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputRef={register({
                      required: "Please enter a headline",
                    })}
                  />
                </Grid>
              </>
            )}

{!isAuctioneer ? null : (
            <>


             {debugFlag && (  
                <>
                  startTime {eventData && eventData.startTime}
                  <br />
                  endTime {eventData && eventData.endTime}
                  <br />
                  muiStartTime {muiZuluTime(eventData && eventData.startTime)}
                  <br />
                  muiEndTime {muiZuluTime(eventData && eventData.endTime)}
                  <br />
                </>
               )}

             <Grid item={true} xs={12}>
                {/* {!isAuctioneer && (
                  <Typography gutterBottom>
                    Live Events Only (optional)
                  </Typography>
                )} */}
                <TextField
                  fullWidth
                  id="datetime-local"
                  label="Start Time"
                  type="datetime-local"
                  name="startTime"
                  className={classes.textField}
                  defaultValue={muiZuluTime(eventData && eventData.startTime)}
                  //value={muiZuluTime(eventData && eventData.startTime)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputRef={register({
                    required: false,
                  })}
                />
              </Grid>
              <Grid item={true} xs={12}>
                <TextField
                  fullWidth
                  id="datetime-local"
                  label="Starts to Close"
                  type="datetime-local"
                  name="endTime"
                  //defaultValue="2017-05-24T10:30"
                  defaultValue={muiZuluTime(eventData && eventData.endTime)}
                  // defaultValue="2022-09-08T00:55:00"
                  className={classes.textField}
                  //value={muiZuluTime(eventData && eventData.endTime)}
                  //value={endTime}
                  //onChange={handleEndTime}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputRef={register({
                    required: false,
                  })}
                />
              </Grid>
            </>
)}
          
          </Grid>
        </DialogContent>

        <DialogActions
          style={{
            position: "sticky",
            bottom: "0",
            background: "#ffffff",
            borderTop: "2px solid #c4c4c4",
            zIndex: "99",
            padding: "16px",
          }}
        >
          <Button
            variant="outlined"
            color="primary"
            size="large"
            onClick={() => {
              // window.$crisp.push(["do", "chat:show"]);
              props.onDone();
            }}
            disabled={pending}
            style={{ justifyContent: "flex-start" }}
            
          >
            <span>Cancel</span>
          </Button>

          <div style={{ position: 'relative' }}>
            {pending && (
            <div
              style={{
                background: "rgba(255, 255, 255, 0.5)",
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                zIndex: 99,
              }}
              >
                <CircularProgress size={20} style={{ color: "#000" }} /> 
              </div>
            )}


          <Button
            variant="contained"
            color="primary"
            size="large"
            type="submit"
            disabled={pending}
            // onClick={() => {
            //   window.$crisp.push(["do", "chat:show"]);
            // }}
          >
            Save
          </Button>
          </div>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default EditEventModal;
