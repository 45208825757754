import React, { useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import { useAuth } from "./../util/auth.js";
import { updateEvent } from "./../util/db.js";
import { makeStyles } from "@material-ui/core/styles";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import StarIcon from "@material-ui/icons/Star";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import { Link } from "./../util/router.js";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

const defaultStar = {featured: false, type: "event" };

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 270,
  },

  estimate: {
    justifyContent: "space-between",
    paddingLeft: "16px",
    paddingRight: "16px",
    paddingTop: "0px",
  },

  media: {
    height: 180,
    display: "flex",
    justifyContent: "center",
  },

  paperItems: {
    minHeight: "300px",
  },
  featured: {
    backgroundColor:
      theme.palette.type === "dark" ? theme.palette.action.selected : "#fdf8c2",
  },
  starStarred: {
    color: theme.palette.secondary.main,
  },
}));

function EventStar(props) {
  const classes = useStyles();

  const auth = useAuth();
  const endpointUrl = window.location.pathname; //href gives full url

  const { event } = props;
  const { type } = props;

//  const initialStar = { ...defaultStar, item: item.id, owner: auth.user.id };
const initialStar = event.featured;

  const [star, setStar] = useState(initialStar);


//  const { status, data, error } = useStarByItemOwner(item.id, auth.user.id);

/*
  useEffect(() => {
    if (status !== "success") {
      return;
    }

    //setStar(data[data.length - 1]);
setStar(
  }, [data, status, error]);
*/

  /*
  const canUseStar =
    auth.user.planIsActive &&
    (auth.user.planId === "pro" || auth.user.planId === "business");
*/

  const canUseStar = auth.user && true;

  const handleClickStar = () => {
    if (canUseStar) {
      //if (star.id === undefined || star.id === false) {
      //  var t = createStar({ ...star, starred: true });
      //
      //  return;
      //}

      updateEvent(event.id, { ...event,  featured: !event.featured });
      setStar(!event.featured);
    } else {
      alert("You must upgrade to the pro or business plan to use this feature");
    }
  };

  // If not authenticated display this and then return to this page.
  if (!auth.user) {
    return (
      <>

        <Button
            edge="start"
            aria-label="star"
            className={event.featured && classes.starStarred}
            style={{ border: "1px solid #696969", width: "95px" }}
            component={Link}
            to={"/auth/signin?next=" + endpointUrl}
          >
            <Typography variant="subtitle1" color={event.colour}>
              Save{" "}
            </Typography>
            <StarBorderIcon style={{ width: "1.25em", height: "1.25em" }} />
          </Button>

      </>
    );
  }

  // If "save" prop is passed then show the custom "save"
  // type of star icon.
  if (type === "save") {
    if (star === undefined) {
      return (
        <>
          <Button
            edge="start"
            aria-label="star"
            onClick={() => handleClickStar()}
            className={event.featured && classes.starStarred}
            style={{ border: "1px solid #696969", width: "95px" }}
            endIcon={<StarBorderIcon style={{ width: "1.25em", height: "1.25em" }} />}
          >
            <Typography variant="subtitle1" color={event.colour}>
            Save{" "}
            </Typography>
            
          </Button>
        </>
      );
    }
    if (star) {
      return (
        <>
          {/*}       {status !== "loading" && star && (  */}

          <Button
            edge="start"
            aria-label="star"
            onClick={() => handleClickStar()}
            className={star.featured && classes.starStarred}
            style={{ border: "1px solid #a888b5", width: "95px" }}
            endIcon={
              type === "remove" ? (
                "REMOVE"
              ) : (
                <StarIcon style={{ width: "1.25em", height: "1.25em", color:'#c2a661' }} />
              )
            }
          >
            <Typography variant="subtitle1" color='secondary' >
              Saved{" "}
            </Typography>

          </Button>

          {/*}      )}  */}
        </>
      );
    }

    return (
      <>
        <Button
          edge="start"
          aria-label="star"
          onClick={() => handleClickStar()}
          className={star.featured && classes.starStarred}
          style={{ border: "1px solid #696969", width: "95px" }}
          endIcon={
            type === "remove" ? (
              "REMOVE"
            ) : (
              <StarBorderIcon
                style={{ width: "1.25em", height: "1.25em", color: "#696969" }}
              />
            )
          }
        >
          <Typography
            variant="subtitle1"
            style={{ color: event.colour }}
          >
            {" "}
            Save{" "}
          </Typography>
        </Button>

        {/*}      )}  */}
      </>
    );
  }

  // Otherwise show a standard heart.
  if (star === undefined) {
    return (
      <>
        <IconButton
          edge="start"
          aria-label="star"
          onClick={() => handleClickStar()}
          className={event.featured && classes.starStarred}
        >
          <StarBorderIcon style={{ width: "1.25em", height: "1.25em" }} />
        </IconButton>
      </>
    );
  }
  if (star) {
    return (
      <>
        <IconButton
          edge="start"
          aria-label="star"
          onClick={() => handleClickStar()}
          className={star.featured && classes.starStarred}
        >
          {type === "remove" ? (
            <>
              <CancelOutlinedIcon
              color="primary"
              opacity=".6"
                 style={{ width: "1.25em", height: "1.25em" }}
              />
            </>
          ) : (
            <StarIcon style={{ width: "1.25em", height: "1.25em" }} />
          )}
        </IconButton>
      </>
    );
  }

  return (
    <>
      {/*}       {status !== "loading" && star && (  */}
     
     {/* {event.featured === true && (<> */}
      <IconButton
        edge="start"
        aria-label="star"
        onClick={() => handleClickStar()}
        className={classes.starStarred}
      >
        <StarBorderIcon style={{ width: "1.25em", height: "1.25em" }} />
      </IconButton>
      {/* </>)} */}
      {/*}      )}  */}
    </>
  );
}

export default EventStar;
