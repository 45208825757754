import React, { useState } from "react";
import { useAuth } from "./../util/auth.js";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import Container from "@material-ui/core/Container";
import ItemsListSelling from "./../components/ItemsListSelling";
import ItemsListWatch from "./../components/ItemsListWatch";
import ItemsListBids from "./../components/ItemsListBids";
import ItemsListDelivery from "./../components/ItemsListDelivery";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import ItemsListOrderLocation from "./ItemsListOrderLocation.js";
import { Box } from "@material-ui/core";
import ItemsListOrder from "./../components/ItemsListOrder.js";
import Meta from "./../components/Meta";

const useStyles = makeStyles((theme) => ({
  paperItems: {
    minHeight: "300px",
  },
  featured: {
    backgroundColor:
      theme.palette.type === "dark" ? theme.palette.action.selected : "#fdf8c2",
  },
  starFeatured: {
    color: theme.palette.warning.main,
  },
  tabBar: {
    backgroundColor: "#e2e2e2",
    color: "black",
  },
}));

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function BuyerItemList(props) {
  const { filter, mode } = props;
  const classes = useStyles();

  const auth = useAuth();

  var backgroundColor = props.bgColor;
  const [tabValue, setTabValue] = React.useState(0);
  const [tab, setTab] = React.useState("all");
  const handleTabChange = (event, newValue) => {
    if (newValue === 0) {
      setTab("all");
    }
    if (newValue === 1) {
      setTab("active");
    }
    if (newValue === 2) {
      setTab("past");
    }

    setTabValue(newValue);
  };

  if (filter === "liked") {
    backgroundColor = "love2";
  }
  if (filter === "bids") {
    backgroundColor = "bid2";
  }
  if (filter === "delivery") {
    backgroundColor = "delivery2";
  }

  /// TABS

  const [filterType, setFilterType] = useState("orders");
  function handleSellerLensClick() {
    if (filterType === "orders") {
      setFilterType("orders");
      return;
    }
    if (filterType === "invoices") {
      setFilterType("invoices");
      return;
    }
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Section>
        <Container>
          {filter === "selling" ? <ItemsListSelling tab={tab} /> : null}

          {filter === "liked" ? <><Meta 
title="Liked"
/>

 <ItemsListWatch tab={tab} mode={mode} /> </>: null}

          {filter === "bids" ? (
            <>
              <ItemsListBids tab={tab} />
            </>
          ) : null}

          {filter === "delivery" ? <ItemsListDelivery tab={tab} /> : null}

          {(filter === "orders" || filter === "invoices") && (
            <>
      <Meta 
title="Your Orders"
/>      
              <Box pb={1}>
                <Tabs value={value} aria-label="simple tabs example">
                  <Tab
                    label="Orders by date"
                    {...a11yProps(0)}
                    onClick={() => {
                      setFilterType("orders");
                      setValue(0);
                    }}
                  />
                  <Tab
                    label="Items by location"
                    {...a11yProps(1)}
                    onClick={() => {
                      setFilterType("invoices");
                      setValue(1);
                    }}
                  />
                </Tabs>
              </Box>

              {/* {filter === "orders" && ( */}
              {filterType === "orders" && (
                <>
                  <ItemsListOrder filter="orders" tab={tab} />
                </>
              )}

              {/* {filter === "orders" && ( */}
              {filterType === "invoices" && (
                <>
                  <ItemsListOrderLocation tab={tab} />
                </>
              )}
            </>
          )}

          {filter === "payment" ? <ItemsListOrder tab={tab} /> : null}

          {filter === "payment_paid" ? (
            <ItemsListOrder filter={filter} tab={tab} />
          ) : null}

          {filter === "payment_draft_open" && (
            <ItemsListOrder filter={filter} tab={tab} />
          )}
        </Container>
      </Section>
      {/* </Section> */}
    </>
  );
}

export default BuyerItemList;
