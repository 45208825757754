import React, { useEffect } from "react";
import { useRouter } from "./../util/router.js";
import { useEvent } from "./../util/db.js";
import AuctionItemList from "./../components/AuctionItemList";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import EventDetailsSection from "../components/EventDetailsSection";
import EventItemsGrid from "../components/EventItemsGrid";
import Meta from "./../components/Meta";

function CollectionPage(props) {
  const { flavour } = props;
  const router = useRouter();
  const id = router.query.eventid;
  const { data: _event, status: eventStatus, error: eventError } = useEvent(id);

  useEffect(() => {
    console.log("auctionpage");
    console.log(eventStatus);
    console.log("event collectionpage", _event);


  }, [eventStatus, _event]);

  if (_event === null) {
    return <>NOTHING HERE</>;
  }


  if (
    _event !== undefined &&
    _event !== null &&
    !_event.published &&
    eventStatus === "success"
  ) {
    return (
      <>

         <EventDetailsSection event={_event} />

        <Box py={3}>
          <EventItemsGrid event={_event} flavour="SellerGrid" />
        </Box>

      </>
    );
  }

  return (
    <>
      {/* <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <Box textAlign="center">
          <SectionHeader
            title={props.title}
            subtitle={props.subtitle}
            size={4}
          />

     
        </Box>

   <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          padding={1}
        >
          <Typography variant="h6">
          {props.title}
          </Typography>
          
        </Box>


     </Container> 

   


    </Section> */}

      {eventStatus === "loading" ? (
        <>
          <CircularProgress size={32} />
        </>
      ) : null}
      {eventStatus === "success" && _event !== null ? (
        <>
          {/* {_event.published ? "PUBLISHED" : "NOT PUBLISHED"} */}

          <EventDetailsSection event={_event} />
          {/* <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >     
    <Container>
         <SectionHeader
            // title={_event.title}
            // subtitle={_event.name}
            // size={4}
          />

<Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          padding={1}
        >
          <Typography variant="h6">
          {_event.title}
          </Typography>
          
        </Box> */}

          {/* {_event.title}
            {_event.name} */}
          <Box py={2}>
            <AuctionItemList event={_event} flavour={"standard"} />
          </Box>
          {/* </Container>
    </Section>   */}
        </>
      ) : null}

      {eventStatus === "error" ? (
        <>No events retrieved. Please try to refresh your browser.</>
      ) : null}

      {eventStatus === "success" && _event === null ? (
        <>No event exists with that name.</>
      ) : null}
    </>
  );
}

export default CollectionPage;
