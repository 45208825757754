import { useState, useEffect } from "react";
import { Box, Typography, Divider, Container, Hidden } from "@material-ui/core";
import ItemCard from "./../components/ItemCard";
import { Grid } from "@material-ui/core";
import Section from "./Section";
import PoweredByStripe from "./../images/PoweredByStripe.svg";
import { useCart } from "./../util/cart.js";
import CheckoutButton from "./CheckoutButton";
import ListSkeleton from "./ListSkeleton.js";
import { debugFlag } from "./../util/dev.js";
import HeroSection2 from "./HeroSection2";
import { useAuth } from "./../util/auth.js";
import ShoppingCartTest from "./ShoppingCartTest";
import DebugReport from "./DebugReport";

//const debugFlag = process.env.REACT_APP_DEBUG && process.env.REACT_APP_DEBUG === "on" ? true : false;
//const debugFlag = true;
const ShoppingCart = () => {
  const {
    data: cartData,
    status: cartStatus,
    error: error,
    clearCart,
    addItemLot,
    //} = useCart(10000, 20);
  } = useCart();

  const auth = useAuth();

  // const [itemLots, setItemLots] = useState();

  //const [cartItemLots, setCartItemLots] = useState();

  const cartItemLots = cartData && cartData.cartItems;
  const missedItemLots = cartData && cartData.missedItems;
  const unavailableItemLots =
    cartData &&
    cartData.unavailableItems &&
    cartData.unavailableItems.filter((unavailableItem) => {
      if (unavailableItem.lot == null) {
        return true;
      }
      if (unavailableItem.lot.bidOwner === auth.user.uid) {
        return false;
      }
      return true;
    });

  //  const cartItemLots = cartData && cartData.cartItems;
  const allItemLots = cartData && cartData.allItems;

  const cartCount = cartData && cartData.cartCount;

  const { cartTotal, cartFormattedTotal } = cartData;

  const [status, setStatus] = useState();
  const [cartUpdateFlag, setCartUpdateFlag] = useState();
  const [totalDetails, setTotalDetails] = useState();

  useEffect(() => {
    console.log("ShoppingCart cartData", cartData);
  }, [cartData]);
  // Keep track of number of updates.
  // So they can be limited if needs be.
  //const [count, setCount] = useState(0);

  const handleRemoveItem = (itemId) => {
    console.log("ShoppingCart item removed", itemId);
  };

  function handleClearShoppingCart() {
    clearCart();
  }

  return (
    <>
      <HeroSection2 size="normal" title="Your shopping cart" />
      <Container>
        {cartStatus === "loading" && <ListSkeleton listsToRender={4} />}
        {cartCount === 0 && (
          <>
            {debugFlag && (
              <>
                <ShoppingCartTest />
                {/*
            <Button onClick={() => handleCheckoutTest()}>
              CLICK TO MAKE A TEST ITEM TO BUY.
            </Button>
*/}
              </>
            )}

            <Box my={4} py={4} sx={{ textAlign: "center" }}>
              <Typography variant="h5">
                Your cart is empty.
                <br /> Please add an item.
              </Typography>
            </Box>
          </>
        )}

        <Section>
          <Box
            sx={{
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {cartCount > 0 && (
              <Grid container spacing={5}>
                <Box clone order={{ xs: 2, sm: 2, md: 1 }}>
                  <Grid item xs={12} sm={12} md={7}>
                    {cartItemLots &&
                      cartItemLots.map((cartItemLot, index) => {
                        console.log("ShoppingCart item", cartItemLot);
                        return (
                          <>
                      
                          <ItemCard
                            key={cartItemLot.id}
                            item={{ ...cartItemLot.item, id: cartItemLot.id }}
                            flavour="cart"
                            spice="publicAddress"
                            removeCard={(c) => handleRemoveItem(c)}
                          />
                
                  </>
                        );
                      })}
                  </Grid>
                </Box>

                <Hidden mdUp>
                  {/* <Container> */}
                  <Box
                    py={2}
                    style={{
                      width: "100%",
                      // position: "sticky",
                      top: "0rem",
                      bottom: "1rem",
                      background: "#fff",
                    }}
                  >
                    <Container>
                    <Typography variant="h6" gutterBottom>
                      <b> Subtotal {cartFormattedTotal}</b>
                    </Typography>

                    <CheckoutButton cartCount={cartCount} />

                    <Box py={2}>
                      <Divider />
                    </Box>

                    <Box>
                      <Typography variant="body1">
                        Act briskly! You've got {cartCount}{" "}
                        {cartCount === 1 ? "item" : "items"} in your cart.
                        Checkout before another LadyBidwell'er pounces on this
                        find.
                      </Typography>
                    </Box>

                    <Box pt={2}>
                      <Divider />
                    </Box>
                    </Container>
                  </Box>
                  {/* </Container> */}
                  {/* </Grid> */}
                </Hidden>

                <Hidden smDown>
                  <Box clone order={{ xs: 1, sm: 1, md: 2 }}>
                    <Grid item xs={12} sm={12} md order={{ xs: 1, sm: 2 }}>
                      <Box
                        textAlign="left"
                        // p={3}

                        style={{
                          width: "100%",
                          position: "sticky",
                          top: "1rem",
                          bottom: "1rem",
                          background: "#fff",
                        }}
                      >
                        <CheckoutButton cartCount={cartCount} />

                        {/* <Box py={1}>You have {cartCount} items in your cart.</Box> */}

                        <Box py={1}>
                          <Typography variant="subtitle1">
                            Tax : Calculated at checkout
                          </Typography>
                        </Box>

                        <Box style={{ display: "flex", alignItems: "end" }}>
                          <Typography variant="subtitle1">
                            Total Price:
                            <b> {cartFormattedTotal} </b>{" "}
                          </Typography>
                        </Box>

                        <Box py={1}>
                          <Divider />
                        </Box>
                        <Box>
                          <Typography variant="body1">
                            Act briskly! You've got {cartCount}{" "}
                            {cartCount === 1 ? "item" : "items"} in your cart.
                            Checkout before another LadyBidwell'er pounces on
                            this find.
                          </Typography>
                        </Box>
                        <Box py={1}>
                          <Divider />
                        </Box>
                        <Box py={1} style={{ opacity: "0.5" }}>
                          <a href="https://www.stripe.com" target="_blank" rel="noreferrer">
                            <img
                              src={PoweredByStripe}
                              style={{ width: "120px" }}
                            />
                          </a>
                        </Box>
                      </Box>
                    </Grid>
                  </Box>
                </Hidden>
              </Grid>
            )}

            {debugFlag && (
              <>
                <ShoppingCartTest />
              </>
            )}

            <Box py={3}>
              <Divider />
            </Box>
            <Box py={1}>
              {unavailableItemLots && unavailableItemLots.length > 0 && (
                <Typography variant="h6">Unavailable Items</Typography>
              )}
            </Box>
            {unavailableItemLots &&
              unavailableItemLots.length > 0 &&
              unavailableItemLots.map((unavailableItemLot, index) => {
                if (unavailableItemLot === undefined) {
                  return;
                }
                console.log(
                  "ShoppingCart unavailableItemLot",
                  unavailableItemLot
                );
                return (
                  <Box key={unavailableItemLot.id}>
                    <Grid container spacing={5}>
                      <Grid item xs={12} sm={8} md={7}>
                        {debugFlag && (
                          <DebugReport object={unavailableItemLot} />
                        )}

                        <ItemCard
                          item={{
                            ...unavailableItemLot.item,
                            id: unavailableItemLot.id,
                          }}
                          flavour="cart"
                          spice={["showStatus", "address"]}
                          removeCard={(c) => handleRemoveItem(c)}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                );
              })}
          </Box>
        </Section>
      </Container>
    </>
  );
  //  }
};

export default ShoppingCart;
