import React from "react";
import { useAuth } from "./../util/auth.js";
import InvoiceGroupHeader from "./../components/InvoiceGroupHeader";
import ItemsIds from "./../components/ItemsIds";
import Box from "@material-ui/core/Box";
import { Card, CardContent, Typography } from "@material-ui/core";
import LazyLoad from "react-lazyload";

// The handleClick triggers a re-render.
// handleExpand doesn't.

function OrderInformation(props) {
  const { group } = props;


  const auth = useAuth();

  const customerId =
    auth.user && auth.user.payment && auth.user.payment.customer;


 //   const { group } = props;
if (group == null) {return null;}
    return (
      <>
        <Box mb={4}>
          <Card
            elevation={0}
            style={{ background: "#ffffff", border: "1px solid #d8d8d8" }}
          >
            <CardContent>
              <InvoiceGroupHeader invoice={group.invoice} />

              <Box p={3}>
                <Typography gutterBottom variant="h6">
                  Items Ordered & Pickup Information
                </Typography>

                <Card elevation={0}>
                  <LazyLoad offset={200}>
                    <ItemsIds
                      items={group.items}
                      spice={["address", "noremove"]}
                    />
                  </LazyLoad>
                </Card>
              </Box>
            </CardContent>
          </Card>
        </Box>
      </>
    );
/*
  if (groupsStatus !== "success") {
    return (
      <>
        <ListSkeleton listsToRender={8} />
      </>
    );
  }
*/

  
}

export default OrderInformation;
