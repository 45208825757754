import React, { useEffect } from "react";
import { useItem } from "./../util/db.js";
import ItemStrip from "./ItemStrip.js";

function ItemIdStrip(props) {
  const { itemId, tab, likes, mode, filterType } = props;

  const { data: item, status, error } = useItem(itemId);

  //  if (status !== "success") {
  //return null;
  //    return <>SKELETON</>;
  //  }
  useEffect(()=>{

    console.log("ItemIdStrip item status error", item, status, error)

  }, [item, status, error])

  return (
    <>
    {item && (
      <ItemStrip key={itemId} likes={likes} item={item} tab={tab} mode={mode} filterType={filterType} />
    )}
      </>
  );
}

export default ItemIdStrip;
