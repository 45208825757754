import React, { useState, useEffect, useReducer } from "react";
import { useAuth } from "./../util/auth.js";

import { makeStyles } from "@material-ui/core/styles";

import { loadStripe } from "@stripe/stripe-js";

import {
  processPaymentMethodDetach,
} from "../util/stripe.js";

// import firebase from "../util/firebase";


import { getCustomerByCustomer } from "../util/stripe.js";
//import AddPaymentCard from "./../components/AddPaymentCard";
import { v4 as uuidv4 } from "uuid";
import IconButton from '@material-ui/core/IconButton';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';

import { getFunctions, httpsCallable } from "firebase/functions";

const functions = getFunctions();


const processStripeClientSecret = httpsCallable(functions,"processStripeClientSecret");

const useStyles = makeStyles((theme) => ({
  paperItems: {
    minHeight: "300px",
  },
  featured: {
    backgroundColor:
      theme.palette.type === "dark" ? theme.palette.action.selected : "#fdf8c2",
  },
  starFeatured: {
    color: theme.palette.warning.main,
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    marginTop: "2em",
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    border:'1px solid #e0e0e0'
  },
}));

function useForceUpdate() {
  const [value, setValue] = useState(0);
  return () => setValue((value) => ++value);
}

function RemovePaymentCard(props) {
  const { card } = props;
  const { filter } = props;
  const classes = useStyles();

  const [uuid, setUuid] = useState(uuidv4());

  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  //const forceUpdate = useForceUpdate();
  const [cardExpiry, setCardExpiry] = useState();
  const [isValidUser, setIsValidUser] = useState(false);

  const [customer, setCustomer] = useState();
  // const stripePromise = loadStripe("pk_test_6pRNASCoBOKtIshFeQd4XMUh");
  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
  );
  //const stripe = useStripe();

  const auth = useAuth();
const [canRemove, setCanRemove] = useState(true);
  const isSeller =
    auth.user &&
    auth.user.seller &&
    auth.user.seller.approvedAt &&
    auth.user.seller.approvedAt !== false;

  const [merp, setMerp] = useState([]);

  const [customerId, setCustomerId] = useState(
    auth.user && auth.user.payment && auth.user.payment.customer
      ? auth.user.payment.customer
      : null
  );

  const isAuctioneer = auth.user.role && auth.user.role === "auctioneer";

  const itemTypeWordSingular = isAuctioneer ? "Auction" : "Collection";
  const itemTypeWordPlural = isAuctioneer ? "Auctions" : "Collections";

  useEffect(() => {
    const customerRecord = getCustomerByCustomer(customerId);
  
    customerRecord
      .then((result) => {
 
        if (result.data === null) {
          setIsValidUser(false);
          return;
        }
        setIsValidUser(true);
        setCustomer(result.data);
      })
      .catch((error) => {
        setIsValidUser(false);
      });
  }, [customerId]);

  function handleDetachPaymentMethod(paymentMethod) {
    setCanRemove(false);
    console.log("CLICK", customerId, paymentMethod);
    processPaymentMethodDetach(paymentMethod)
      .then((result) => {

        props.refreshCards();
        props.refreshCustomer();

        // Remove this.
        // They will see it removed.
        /*
        props.onStatus({
          type: "success",
          message: "Removed payment card.",
        });
        */
        setCanRemove(true); // unnecessary?

      })
      .catch((error) => {
        props.onStatus({
          type: "error",
          message: "Could not remove payment card.",
        });
        console.log("RemovePaymentCards handleDetachPaymentMethod error", error);
        setCanRemove(true);
      });
  }


  
  return (
           

                <IconButton
                  
                  disabled={!canRemove}
                  color="primary"
                  variant="outlined"
                  disableElevation
                  onClick={() => handleDetachPaymentMethod(card.payment_method)}
                >
                    <HighlightOffOutlinedIcon />
                </IconButton>

  );
}

export default RemovePaymentCard;

