import React, { useState, useEffect } from "react";
import { useAuth, requireAuth } from "./../util/auth.js";
import { createMessage, useLotStatus } from "./../util/db.js";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ConfirmDialog from "./ConfirmDialog.js";
import { debugFlag } from "./../util/dev.js";
import { getFunctions, httpsCallable } from "firebase/functions";

const functions = getFunctions();

const delistLot = httpsCallable(functions, "delistLot");

const useStyles = makeStyles((theme) => ({
  deleteButton: {
    backgroundColor: "#000000",
    color: "#ffffff",
  },
}));

export function ItemUnlist(props) {
  const classes = useStyles();
  const auth = useAuth();

  const { item, lot } = props;

  const isOwner = auth.user && auth.user.id && auth.user.id === item.owner;

  const isAuctioneer =
    auth.user && auth.user.role && auth.user.role === "auctioneer";

  const [canConsign, setCanConsign] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);

  const isAccepted =
    item && item.accepted === undefined ? false : item.accepted;
  const wasConsigned =
    item && item.wasConsigned === undefined ? false : item.wasConsigned;
  const isConsigned =
    item && item.consigned === undefined ? false : item.consigned;

  const isWithdrawn =
    item && item.withdrawn === undefined ? false : item.withdrawn;

  const { isDelisted, isLotStarted, isPaid, isKnockedDown } = useLotStatus(lot);

  // You can withdrawn an item if:
  // It has been consigned and not yet accepted or rejected.
  // It has been consigned and accepted
  // It has not already been withdrawn
  const canDelist = !isKnockedDown && (isOwner || isAuctioneer) && !isDelisted;
  //const canUnlist = true;

  useEffect(() => {
    console.log("ItemUnlist start", item && item.id);
  }, []);

  // const unlistItem = () => {
  //   if (isOwner || isAuctioneer) {
  //     //  if (isOwner && item.accepted !== true) {
  //     const response = delistLot(item);
  //     console.log ("delist reponse", response)
  //     //updateItem(item.id, { unlist:true });

  //     var message = "Owner unlisted item from the sale.";

  //     // createMessage
  //     // Creates a message in the users collection under messages with a unique reference
  //     // return firestore.collection("users").doc(datagram.from).collection("messages").doc().set({

  //     console.log("Lot: itemunlist", lot);
  //     console.log("Item ID: itemunlist", item.id);
  //     console.log("Auth user ID: itemunlist", auth.user.id);
  //     createMessage({
  //       subject: message,
  //       item: item.id,
  //       from: auth.user.id,
  //       to: "auctioneer",
  //     });
  //     console.log("ItemUnlist createMessage done");
  //   } else {
  //     alert("You must be the Owner of the item to use this feature");
  //   }
  // };

  const unlistItem = async () => {
    if (isOwner || isAuctioneer) {
      const response = await delistLot(item);
      console.log("delist response", response);
  
      var message = "Owner unlisted item from the sale.";
  
      console.log("Lot: itemunlist", lot);
      console.log("Item ID: itemunlist", item.id);
      console.log("Auth user ID: itemunlist", auth.user.id);
      await createMessage({
        subject: message,
        item: item.id,
        from: auth.user.id,
        to: "auctioneer",
      });
      console.log("ItemUnlist createMessage done");
    } else {
      alert("You must be the Owner of the item to use this feature");
    }
  };

  if (!(isOwner || isAuctioneer)) {
    return null;
  }

  if (isDelisted) {
    return null;
  }

  //  if (!isLotStarted) {
  //  //  return (
  //  //    <Box style={{height:'50px', width:'50px', backgroundColor:'yellow'}} > TESTER BLOCK</Box>
  //  //    )
  //    return null;
  // }

  if (isWithdrawn) {
    return null;
  }

  // accepted
  // consigned

  // if (auctionStatus !== 'success') {return null;}

  return (
    <>
      {debugFlag && (
        <>
          {" "}
          isOwner {isOwner ? "YES" : "NO"}
          isAuctioneer {isAuctioneer ? "YES" : "NO"}
          isDelisted {isDelisted ? "YES" : "NO"}
          canDelist {canDelist ? "YES" : "NO"} isPaid {isPaid ? "YES" : "NO"}{" "}
          isKnockedDown {isKnockedDown ? "YES" : "NO"}
        </>
      )}
      {isOwner || isAuctioneer ? (
        <>
          <Button
            fullWidth
            variant="outlined"
            disabled={!canDelist}
            //checked={item.consigned}
            onClick={() => {
              setConfirmOpen(true);
            }}
            name="checkedA"
          >
            Unlist Item
          </Button>
          <ConfirmDialog
            title="Unlist Item?"
            open={confirmOpen}
            setOpen={setConfirmOpen}
            onConfirm={() => {
              unlistItem();
              console.log("ItemUnlist onConfirm done");
            }}
          >
            Are you sure? You are about to unlist this item from an ongoing
            auction? Please only do this if absolutely necessary.
          </ConfirmDialog>
        </>
      ) : null}

      {/*item.consigned ? (<div>Submitted</div>) : (<div>Submit for approval</div>)*/}
    </>
  );
}

export default requireAuth(ItemUnlist);
