import { useEffect, useState } from "react";
//import { useAuth } from "./../util/auth.js";
import { useAuth } from "./../util/auth.js";
import { getFunctions, httpsCallable } from "firebase/functions";

const functions = getFunctions();

const getSellerAccount = httpsCallable(functions, "getSellerAccount");

//const auth = useAuth();

export function useIdentity() {
  const auth = useAuth();
  const isAuctioneer = auth?.user?.role && auth?.user?.role === "auctioneer";

  //const isOwner = auth.user && auth.user.id === (item && item.owner);
  const isSeller = auth.user && auth.user.seller !== undefined;

  return { isAuctioneer: isAuctioneer, isSeller: isSeller };
}

export function useSeller(userId) {
  const auth = useAuth();

  const [data, setData] = useState();
const [error, setError] = useState();
  useEffect(() => {






    const fetchUrl = async () => {
console.log("identity useSeller");
    getSellerAccount({id:userId}).then((result)=>{
console.log("identity useSeller result", result);
//if (result.error !== null) {return;}
setData(result.data);
setError(null);
}).catch((error)=>{console.log("identity useSeller error", error);
setData(null);
setError(error);
});



      //setClientSecret("");
    };
    fetchUrl();







  }, []);

  return {data:data, error:error};
}

export const isAuctioneer = () => {
  /*
  

    // Get authenticated user
   


    return (auth?.user?.role &&
  (auth?.user?.role === "auctioneer"))
  */
};
// dev figure out how to do this.
/*
export function isAuctioneer() {
const auth = useAuth();
  return auth.user.role &&
(auth.user.role === "auctioneer");

}
*/
/*
export const isSeller = () => {

  const auth = useAuth();
  auth.user && auth.user.seller === undefined;

}
*/
/*
export const isSeller =
    auth.user &&
    auth.user.seller &&
    auth.user.seller.approvedAt &&
    auth.user.seller.approvedAt !== false;
    */
