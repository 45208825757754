import React, { useState, useEffect } from "react";
import { useAuth } from "./../util/auth.js";


import { makeStyles } from "@material-ui/core/styles";

import { loadStripe } from "@stripe/stripe-js";

import {
  processPaymentMethodUpdateByCustomer,
} from "../util/stripe.js";

// import firebase from "../util/firebase";


import Button from "@material-ui/core/Button";
//import AddPaymentCard from "./../components/AddPaymentCard";
import { v4 as uuidv4 } from "uuid";
import { Box } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { getFunctions, httpsCallable } from "firebase/functions";

const functions = getFunctions();



const processBid = httpsCallable(functions,"processBid");

const processStripeClientSecret = httpsCallable(functions, "processStripeClientSecret");
//const processPaymentAuthorization = firebase.functions().httpsCallable("processPaymentAuthorization");

const useStyles = makeStyles((theme) => ({
  paperItems: {
    minHeight: "300px",
  },
  featured: {
    backgroundColor:
      theme.palette.type === "dark" ? theme.palette.action.selected : "#fdf8c2",
  },
  starFeatured: {
    color: theme.palette.warning.main,
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    marginTop: "2em",
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    border: "1px solid #e0e0e0",
  },
}));

function DefaultPaymentCard(props) {
  const { card, customer } = props;
  const { filter } = props;
  const classes = useStyles();

  const [uuid, setUuid] = useState(uuidv4());

  const [cardExpiry, setCardExpiry] = useState();
  const [isValidUser, setIsValidUser] = useState(false);

  const [priorPaymentMethod, setPriorPaymentMethod] = useState();
  const [status, setStatus] = useState("loading");

  //const [customer, setCustomer] = useState();
  // const stripePromise = loadStripe("pk_test_6pRNASCoBOKtIshFeQd4XMUh");
  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
  );
  //const stripe = useStripe();

  const auth = useAuth();
  //console.log("authuser", auth.user)

  useEffect(() => {
    console.log("DefaultPaymentCard card update", card);
    console.log(
      "DefaultPaymentCard card update priorPaymentMethod",
      priorPaymentMethod
    );
    // things that are not of use
    if (card === undefined) {
      setStatus("loading");
      return;
    }
    if (card.payment_method === undefined) {
      setStatus("loading");
      return;
    }

    if (priorPaymentMethod === card.payment_method) {
      // nothing has changed.
      setStatus("success");
      return;
    }

    setPriorPaymentMethod(card.payment_method);
    setStatus("success");

    /*
props.onStatus({
  type: "info",
  message: "Set default card.",
});
*/
  }, [card]);

  function handleDefaultPaymentMethod(paymentMethod) {
    setStatus("loading");
    //console.log("customer", customer);
    //const customerId = customer.id;
    //console.log("CLICK", customerId, paymentMethod);
    var customerId = auth?.user?.payment?.customer;

    ////if (customer && customer.id !== undefined) {
    //ustomerId = customer.id;
    // }

    processPaymentMethodUpdateByCustomer(customerId, paymentMethod)
      .then((result) => {
        console.log("handleDefaultPaymentMethod result", result);
        props.refreshCards();
        props.refreshCustomer();
        //setStatus('success');
      })
      .catch((error) => {
        console.log("handleDefaultPaymentMethod error", error);
      });
  }

  //  if (card === undefined) {return (<>LOADING</>)}
  //if (card.isDefault === undefined) {return (<>LOADINGX</>)}

  //if (status === 'loading') {return (<>LOADING</>);}

  return (
    <Button
      size="small"
      variant="outlined"
      onClick={() => handleDefaultPaymentMethod(card.payment_method)}
    >
      <Box mr={2}>Set as Default Card</Box>{" "}
      {status === "loading" ? (
        <CircularProgress m={3} size={20} color="secondary" />
      ) : null}
    </Button>
  );
}

export default DefaultPaymentCard;
