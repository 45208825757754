import React from "react";
import Section from "./Section";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import { makeStyles } from "@material-ui/core/styles";



const useStyles = makeStyles((theme) => ({
  // Increase <Container> padding so it's
  // at least half of <Grid> spacing to
  // avoid horizontal scroll on mobile.
  // See https://material-ui.com/components/grid/#negative-margin
  container: {
    padding: `0 ${theme.spacing(4)}px`,
  },
  imageWrapper: {
    // height:'20vh',
    margin: "0 auto",
    maxWidth: 570,
    width: "100%",
    "& > img": {
      width: "100%",
    },
  },
  row: {
    // Spacing between rows
    "&:not(:last-child)": {
      marginBottom: `${theme.spacing(2)}px`,
    },
  },
  title: {
    fontFamily: '"Cormorant Garamond", "serif"',
  },
  title1: {
    fontFamily: '"Cormorant Garamond", "serif"',
    textAlign: 'center',
    // fontWeight:'600',
    // marginBottom: '1em',
  },

}));

function WhatCanSell(props) {
  const classes = useStyles();

  const items = [
    {
      // number: 1,
      // iconColor: "primary.main",
    },
  ];

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      {/* <Typography variant='h3'  className={classes.title}>Start Selling</Typography> */}
      <Container className={classes.container}>
        <Grid container={true} alignItems="center" spacing={8}>
          {/* <Grid container={true} item={true} direction="column" xs={12} md={6}>
            <figure className={classes.imageWrapper}>
              <img src={props.image} alt="" />
            </figure>
          </Grid> */}

          <Grid item={true} xs={12} md={12}>
            {items.map((item, index) => (
              <Grid
                className={classes.row}
                item={true}
                container={true}
                direction="row"
                justify="center"
                alignItems="center"
                spacing={5}
                key={index}
              >
          
          <Grid item={true} xs={true} xs={12} md={12}>
          <Typography variant='h3'  className={classes.title1} >What can I sell?</Typography>
              </Grid>

                <Grid item={true} xs={true} xs={12} md={6}>
         

                  <Typography variant="body1" stlye={{color:'#ffffff'}}>
                  Two words sum up what LadyBidwell likes… Mr. Darcy, well yes, but also art, furniture or collectibles, antique or modern.  If it fits these criteria it will suit LadyBidwell’s fancy, just as much as any dark and brooding suitor. 
                 

                    <br />
      
                  </Typography>



              {/* <Box pt={4}>
                  
                  <Grid item={true} xs={true}>

                  <SellWithUsButton />
         
              </Grid>
              </Box> */}
         
              </Grid>

              <Grid container={true} item={true} direction="column" xs={12} md={6}>
            <figure className={classes.imageWrapper}>
              <img src={props.image} alt="" />
            </figure>
          </Grid>

    
                </Grid>
                
            ))}
          </Grid>
        </Grid>
      </Container>
    </Section>
  );
}

export default WhatCanSell;
