
import React, {  useEffect } from "react";
import { useEvent } from "./../util/db.js";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import CollectionDetails from "./../components/CollectionDetails";

const useStyles = makeStyles((theme) => ({
    gridItem: {
      // Add border that contrasts lightly with background color.
      // We use boxShadow so that it's hidden around outer edge
      // due to container <Card> having overflow: hidden
      // boxShadow: `1px 1px 0 0 ${emphasize(theme.palette.background.paper, 0.08)}`,
      textAlign: "left",
    },
    imageContainer: {
      margin: "0 auto",
      maxWidth: "200px",
      marginBottom: "30px",
      "& img": {
        width: "100%",
      },
    },
  }));

export default function EventName(props) {
    const {event} = props;
    const classes = useStyles();

    const {
        data: eventData,
        status: eventStatus,
        error: eventError,
      } = useEvent(event);

useEffect(() => {
  //console.log(event.id)
console.log("EVENTDETAILS")
console.log(eventStatus)
console.log(eventData)
console.log(eventError)


},[eventStatus, eventData])
  

      if (eventStatus !== 'success') {return null}
      if (eventData === null) {return ("Auction not found.")}
return (


                <Box p={3}>     
           
                  {/* <Typography variant="body1">
        
                  {eventData && eventData.location ? eventData.location : "Location not found"}
                  </Typography> */}

                                 
                  <Typography variant="body1">
        
                  Name: {eventData && eventData.name ? eventData.name : "No event name available."}
                  </Typography>

                  <Typography variant="body1">
        
        Description: {eventData && eventData.description ? eventData.description : "No description available."}
        </Typography>

                  <Typography variant="body1">
        
        Pickup and Shipping: {eventData && eventData.pickupandshipping ? eventData.pickupandshipping : "No pickup and shipping available."}
        </Typography>

        <Typography variant="body1">
        
        Address: {eventData && eventData.address ? eventData.address : "No address available."}
        </Typography>

        <Typography variant="body1">
        
        Location: {eventData && eventData.address ? eventData.location : "No location available."}
        </Typography>

<CollectionDetails event={event} />

                </Box>
             

             



             
)


}
