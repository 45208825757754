import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import { Link } from "./../util/router";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  // Increase <Container> padding so it's
  // at least half of <Grid> spacing to
  // avoid horizontal scroll on mobile.
  // See https://material-ui.com/components/grid/#negative-margin
  container: {
    padding: `0 ${theme.spacing(3)}px`,
  },
  image: {
    margin: "0 auto",
    maxWidth: 570,
    display: "block",
    height: "auto",
    width: "100%",
  },
  fontFamily: {
    fontFamily: '"Cormorant Garamond", "serif"',
   fontWeight: 700,
  },
}));

function HeroSection3(props) {
  const classes = useStyles();

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container className={classes.container}>
        <Grid container={true} alignItems="center" spacing={6}>
          <Grid container={true} item={true} direction="column" xs={12}>
            <Box textAlign='center'>
     
             <Typography variant='h3' className={classes.fontFamily} style={{color:'#ffffff'}}>
                {props.title}
             </Typography>
           <Box py={2}>
            {props.buttonText && (
             <Button
                component={Link}
                to={props.buttonPath}
                variant="contained"
                size="large"
                style={{background:'#ffffff'}}
                
              >
                {props.buttonText}
              </Button>
              )}
              </Box>
            </Box>
          </Grid>
    
        </Grid>
      </Container>
    </Section>
  );
}

export default HeroSection3;