import React, { useEffect } from "react";
import {
  useProfile,
  useUser,
  updateUser,
  updateProfile,
  createEvent,
} from "./../util/db.js";
import { useAuth } from "./../util/auth.js";
import { useSeller } from "./../util/identity.js";
import Switch from "@material-ui/core/Switch";
import { useForm } from "react-hook-form";
import { Grid, Divider, Container, Typography, Box } from "@material-ui/core";
import { serverTimestamp } from "firebase/firestore";
import { toast } from "react-toastify";

//const serverTimestamp = firebaseApp.firestore.FieldValue.serverTimestamp;
const removeMd = require("remove-markdown");
function Badges(props) {
  //const router = useRouter();
  //const id = router.query.sellerid;
  //const seller = {id:id};

  const seller = props.user;
  const sellerProfile = props.sellerProfile;
  const id = seller.id;

  const auth = useAuth();
  const { handleSubmit, register, errors, reset } = useForm();

  const {
    data: profileData,
    status: profileStatus,
    error: profileError,
  } = useProfile(id);

  const { data: sellerData, error: sellerError } = useSeller(id);

  const { data: userData, status: userStatus, error: userError } = useUser(id);

  const [state, setState] = React.useState({
    sellerEnabled: true,
    checkedB: true,
  });

  useEffect(() => {
    console.log("Badges userData", userData);
  }, [userData]);

  useEffect(() => {
    console.log("Badges sellerData", sellerData);
  }, [sellerData]);

  useEffect(() => {
    console.log("Badges start");
  }, []);

  const handleChange = (type, event) => {
    event.preventDefault();
    console.log("badges handleChange start");
    if (type === "seller.enabled") {
      console.log("badges handleChange event", event);

      // setState({ ...state, [event.target.name]: event.target.checked });
      updateUser(id, {
        seller: { enabled: event.target.checked, enabledAt: serverTimestamp() },
      }).then((updateUserResult)=>{

if (event.target.checked === false) {

console.log("Badges profileData", profileData)

if (profileData && typeof profileData.event !== "undefined") {
          toast("There is already a default event.");
          return;
        }



        const defaultEventData = {
          //      "id": "WBobnNCbNuE6A2Zi2cGe",
          colour: "#f7f7f7",
          review: "f",
          pickupandshipping: "f",
          location: "f",
          //     "createdAt": {
          //       "seconds": 1676318320,
          //       "nanoseconds": 463000000
          //     },
          deliveryoptions: "pickup_only",
          //    "auction": "PQoBwllWry1ZsqVQpfoK",
          startTime: null,
          //    "owner": "OEej9uVineeHkjzuFvJRNn9Fgyl2",
          memo: "f",
          endTime: null,
          images: [],
          name: "Mixed Collection (catch-all)",
          address: "f",
          slug: "f",
        };

        createEvent({ owner: id, ...defaultEventData })
          .then((result) => {
            console.log("Badges createEvent result", result.id);

           // updateUser(id, {
           //   event: result.id,
           // });

            updateProfile(id, {
              event: result.id,
            }).then((updateProfileResult)=>{
              toast("Created a default event "+ result.id +".");
              console.log("Badges updateProfile result", updateProfileResult)

            }).catch((updateProfileError)=>{
              toast("There was a problem creating a default event.");
              console.error("Badges updateProfile error", updateProfileError)

            })


          })
          .catch((error) => {
            console.error("Badges createEvent error", error);
            toast("There was a problem creating that event.");
          });
        }
        }).catch((updateUserError)=>{
toast("There was a problem updating that user")
        console.error("Badges updateUserError", updateUserError)


      })



      return;
    }

    if (type === "developer.enabled") {
      console.log("event", event);

      // setState({ ...state, [event.target.name]: event.target.checked });
      updateUser(id, {
        developer: {
          enabled: event.target.checked,
          enabledAt: serverTimestamp(),
        },
      });
      return;
    }

    if (type === "affiliate.enabled") {
      console.log("event", event);

      // setState({ ...state, [event.target.name]: event.target.checked });
      updateUser(id, {
        affiliate: {
          enabled: event.target.checked,
          enabledAt: serverTimestamp(),
        },
      });
      return;
    }

    if (type === "buyer.enabled") {
      console.log("event", event);

      // setState({ ...state, [event.target.name]: event.target.checked });
      updateUser(id, {
        buyer: { enabled: event.target.checked, enabledAt: serverTimestamp() },
      });
      return;
    }
  };

  const UserBadge = () => {
    // Maybe enabled as a seller,
    // But may not be enabled.

    // Seller?
    if (userStatus !== "success") {
      return null;
    }
    return (
      <>
        <div>USER BADGE</div>
        <div>TOGGLE ENABLED/NOT ENABLED USER</div>
      </>
    );
  };
  const AdministratorBadge = () => {
    // Maybe enabled as a seller,
    // But may not be enabled.

    // Seller?
    if (userStatus !== "success") {
      return null;
    }
    return (
      <>
        <div>ADMINISTRATOR BADGE</div>
        <div>TOGGLE ENABLED/NOT ENABLED ADMINSTRATOR</div>
      </>
    );
  };

  const CuratorBadge = () => {
    // Maybe enabled as a seller,
    // But may not be enabled.

    // Seller?
    if (userStatus !== "success") {
      return null;
    }
    return (
      <>
        <div>CURATOR BADGE</div>
        <div>TOGGLE ENABLED/NOT ENABLED CURATOR</div>
      </>
    );
  };

  const SellerBadge = () => {
    // Maybe enabled as a seller,
    // But may not be enabled.

    // Seller?
    if (userStatus !== "success") {
      return null;
    }

    return (
      <>
        <div>
          SELLER BADGE
          <Switch
            checked={userData && userData.seller && userData.seller.enabled}
            onChange={(e) => handleChange("seller.enabled", e)}
            name="seller.enabled"
            inputProps={{ "aria-label": "secondary checkbox" }}
            inputRef={register()}
          />
        </div>
        <div>TOGGLE ENABLED/NOT ENABLED SELLER</div>
        <div>
          {" "}
          {userData && userData.seller ? <>SELLER RECORD SEEN</> : null}{" "}
        </div>
        <div>
          {" "}
          {userData && userData.seller && userData.seller.account ? (
            <>WITH ACCOUNT ID</>
          ) : null}{" "}
        </div>
        <div>
          {" "}
          {userData && userData.seller && userData.seller.approvedAt ? (
            <>APPROVED</>
          ) : null}{" "}
        </div>

        <div>Firestore response</div>
        <div>
          {userData?.seller?.payouts_enabled
            ? "PAYOUTS ENABLED"
            : "PAYOUTS NOT ENABLED"}
        </div>
        <div>
          {userData?.seller?.charges_enabled
            ? "CHARGES ENABLED"
            : "CHARGES NOT ENABLED"}
        </div>

        <div>Stripe response</div>
        <div>{sellerData?.data?.requirements?.disabled_reason}</div>
        <div>
          {sellerData?.data?.payouts_enabled
            ? "PAYOUTS ENABLED"
            : "PAYOUTS NOT ENABLED"}
        </div>
        <div>
          {sellerData?.data?.charges_enabled
            ? "CHARGES ENABLED"
            : "CHARGES NOT ENABLED"}
        </div>

        <div>TOGGLE APPROVED SELLER STATUS</div>
      </>
    );
  };

  const DeveloperBadge = () => {
    // Maybe enabled as a seller,
    // But may not be enabled.

    // Seller?
    if (userStatus !== "success") {
      return null;
    }

    return (
      <>
        <div>
          DEVELOPER BADGE
          <Switch
            checked={
              userData && userData.developer && userData.developer.enabled
            }
            onChange={(e) => handleChange("developer.enabled", e)}
            name="developer.enabled"
            inputProps={{ "aria-label": "secondary checkbox" }}
            inputRef={register()}
          />
        </div>
        <div>TOGGLE ENABLED/NOT ENABLED SELLER</div>
        <div>
          {" "}
          {userData && userData.developer ? <>SELLER RECORD SEEN</> : null}{" "}
        </div>
        <div>
          {" "}
          {userData && userData.developer && userData.developer.approvedAt ? (
            <>APPROVED</>
          ) : null}{" "}
        </div>

        <div>TOGGLE DEVELOPER STATUS</div>
      </>
    );
  };

  const AffiliateBadge = () => {
    // Maybe enabled as a seller,
    // But may not be enabled.

    // Seller?
    if (userStatus !== "success") {
      return null;
    }

    return (
      <>
        <div>
          AFFILIATE BADGE
          <Switch
            checked={
              userData && userData.affiliate && userData.affiliate.enabled
            }
            onChange={(e) => handleChange("affiliate.enabled", e)}
            name="affiliate.enabled"
            inputProps={{ "aria-label": "secondary checkbox" }}
            inputRef={register()}
          />
        </div>
        <div>TOGGLE ENABLED/NOT ENABLED AFFILIATE</div>
        <div>
          {" "}
          {userData && userData.developer ? (
            <>AFFILIATE RECORD SEEN</>
          ) : null}{" "}
        </div>
        <div>
          {" "}
          {userData && userData.developer && userData.developer.approvedAt ? (
            <>APPROVED</>
          ) : null}{" "}
        </div>

        <div>TOGGLE AFFILIATE STATUS</div>
      </>
    );
  };

  const BuyerBadge = () => {
    // Seller?
    if (userStatus !== "success") {
      return null;
    }
    return (
      <>
        <div>BUYER BADGE</div>
        <div>TOGGLE BUYER ENABLED</div>
        <Switch
          checked={userData && userData.buyer && userData.buyer.enabled}
          onChange={(e) => handleChange("buyer.enabled", e)}
          name="buyer.enabled"
          inputProps={{ "aria-label": "secondary checkbox" }}
          inputRef={register()}
        />
        <div>TOGGLE CAN/CAN NOT CHARGE</div>

        <div>TOGGLE CAN/CAN NOT PICKUP</div>
        <div>
          {" "}
          {userData && userData.payment ? (
            <>PAYMENT RECORD SEEN</>
          ) : (
            <>NO PAYMENT RECORD SEEN</>
          )}{" "}
        </div>

        <div>
          {" "}
          {userData && userData.payment && userData.payment.id ? (
            <>PAYMENT INTENT SETUP</>
          ) : (
            <>NO PAYMENT INTENT SETUP</>
          )}
        </div>
        <div>
          {" "}
          {userData && userData.payment && userData.payment.customer ? (
            <>WITH STRIPE SETUP</>
          ) : null}{" "}
        </div>
        <div>
          {" "}
          {userData && userData.payment && userData.payment.payment_method ? (
            <>CARD SETUP</>
          ) : null}{" "}
        </div>
      </>
    );
  };

  const UserBadges = () => {
    if (userStatus !== "success") {
      return null;
    }
    if (auth && auth.user && auth.user.isAdministrator) {
      return (
        <>
          <Container style={{ background: "#d8d8d8" }}>
            <Box py={2}>
              <Grid container spacing={2}>
                <Grid item sm={12}>
                  <Typography variant="h6">ADMINISTRATOR ONLY</Typography>

                  {/* <Typography variant="subtitle1">Account Information</Typography>  */}
                  <Typography variant="subtitle1">
                    <b>Company Name:</b> {sellerProfile?.name}
                  </Typography>
                  <Typography variant="subtitle1">
                    <b>Company Email:</b> {sellerProfile?.email}
                  </Typography>
                  <Typography variant="subtitle1">
                    <b>Company Phone:</b> {sellerProfile?.contactNumber}
                  </Typography>
                  <Typography variant="subtitle1">
                    <b>Private Name:</b> {userData?.name}
                  </Typography>
                  <Typography variant="subtitle1">
                    <b>Private Email: </b>
                    {userData?.email}
                  </Typography>
                  <Typography variant="subtitle1">
                    <b>Private Telephone (Stripe): </b>
                    {userData?.telephone}
                  </Typography>

                  <Typography variant="subtitle1">
                    <b>Stripe Seller Account:</b> {userData?.seller?.account}
                  </Typography>
                  {userData?.seller?.account && (
                    <a
                      href={
                        "https://dashboard.stripe.com/test/connect/accounts/" +
                        userData?.seller?.account +
                        "/activity"
                      }
                    >
                      {"Stripe Account " + userData?.seller?.account}{" "}
                    </a>
                  )}

                  <Typography variant="subtitle1">
                    <b>Stripe Customer Account:</b> {userData?.seller?.customer}
                  </Typography>
                  {/* https://dashboard.stripe.com/test/customers/cus_MNv4FDLrfbDqVK */}

                  {userData?.seller?.account && (
                    <a
                      href={
                        "https://dashboard.stripe.com/test/customers/" +
                        userData?.seller?.customer
                      }
                    >
                      {"Stripe Customer " + userData?.seller?.customer}{" "}
                    </a>
                  )}
                </Grid>

                {/* <UserBadge />
<br></br>
<SellerBadge />
<br></br>
<BuyerBadge />
<br></br>
<CuratorBadge />
<br></br>
<AdministratorBadge />
<br></br> */}

                <Grid item sm={2}>
                  <UserBadge />
                </Grid>

                <Grid item sm={2}>
                  <SellerBadge />

                  {/*<SellerBadgeTest user={seller} flavour="icon" />*/}
                </Grid>

                <Grid item sm={2}>
                  <BuyerBadge />
                </Grid>

                <Grid item sm={2}>
                  <CuratorBadge />
                </Grid>
                <Divider orientation="vertical" />

                <Grid item sm={2}>
                  <AdministratorBadge />
                </Grid>
                <Grid item sm={2}>
                  <AffiliateBadge />
                </Grid>
                <Grid item sm={2}>
                  <DeveloperBadge />
                </Grid>
              </Grid>
            </Box>
          </Container>
        </>
      );
    }

    return null;
  };

  if (auth.user && !auth.user.isAdministrator) {
    return null;
  }

  return (
    <>
      <UserBadges />
    </>
  );
}

export default Badges;
