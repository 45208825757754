import React, { useEffect, useState } from "react";
import { useRouter } from "./../util/router.js";
import { useProfile, useUser, useItemsByOwner, hydrateItem } from "./../util/db.js";
import { canBuy } from "./../util/filter.js";
import { useAuth } from "./../util/auth.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import SellerDetailsSection from "../components/SellerDetailsSection";
// import ProfileItemList from '../components/ProfileItemList';
//import SellerBadge from '../components/SellerBadge';
import Badges from "../components/Badges";
import NotFoundPage from "./not-found";
import { Grid, Container } from "@material-ui/core";
import ProfileEventList from "../components/ProfileEventList";
import SellerItemList from "../components/SellerItemList.js";
import ItemsGrid from "../components/ItemsGrid.js";
import ItemCardHorizontal from "../components/ItemCardHorizontal.js";
import Search from "../components/Search.js";
import NoSearchResults from "../components/NoSearchResults.js";
import { forceCheck } from "react-lazyload";
import ItemsGridHorizontal from "../components/ItemsGridHorizontal.js";
import AuctionItem from "../components/AuctionItem.js";
import Skeleton from '@material-ui/lab/Skeleton';


function ProfilePage(props) {
  const removeMd = require("remove-markdown");
  const router = useRouter();
  const id = router.query.sellerid;
  const seller = { id: id };
  // const [countItems, setCountItems] = useState();
  const [searchQuery, setSearchQuery] = useState();
  const auth = useAuth();
  const [countItems, setCountItems] = useState();
  const [processingItems, setProcessingItems] = useState(true);
  const [filteredItemsDecliningPrice, setFilteredItemsDecliningPrice] = useState([]);
  //const isSeller = auth.user && auth.user.seller !== undefined;
  const isAdministrator =
    auth.user && auth.user.role && auth.user.role === "auctioneer";
  useEffect(() => {
    console.log("auth.user", auth.user);
  }, [auth]);

  function handleRequestSearch(c) {
    console.log("Profilepage handleSearch c", c);
    setSearchQuery(c);
  }

  function handleChangeSearch(c) {
    console.log("Profilepage handleChange c", c);
    setSearchQuery(c);
  }

  useEffect(() => {
    console.log("WeeklyPost searchQuery", searchQuery);
    //setItemsCount(0);
  }, [searchQuery]);

  function handleCardCount(c) {
    console.log("AuctionsHome handleCardCount c", c);
    console.log("set count items", c);
    setCountItems(c);
    forceCheck();
  }

  const {
    data: profileData,
    status: profileStatus,
    error: profileError,
  } = useProfile(id);

  const { data: userData, status: userStatus, error: userError } = useUser(id);

  useEffect(() => {
    console.log("Fetched items: ", items);
  }, [items]);



  const {
    data: items,
    status: itemsStatus,
    error: itemsError,
  } = useItemsByOwner(id);


  const [expiredItems, setExpiredItems] = useState([]);

  function handleExpired(item) {
    setExpiredItems(prevItems => [...prevItems, item]);
  }


  const filteredItemsMarketplace = items
    ? items.filter((item) => 
{
return item.listingType === "marketplace_price";
}

)
    : [];




//   const filteredItemsDecliningPrice = items
//     ? (items.filter(
// (item) => {

// if (item.listingType !== "declining_price") {return false;} 
// const hydratedItem = hydrateItem(item);
// const canBuyItem = canBuy(hydratedItem);
// return canBuyItem;
// }).slice(0, 5))
//     : [];


useEffect(() => {
  if (items) {
    console.log('items:', items); // print items to console
    
    const processItems = async () => {
      const hydratedItems = await Promise.all(
        items.map((item) => hydrateItem(item.id)) // assuming the function hydrateItem needs an id
      );
      
      console.log('hydratedItems:', hydratedItems); // print hydratedItems to console

      const newFilteredItemsDecliningPrice = hydratedItems
        .filter((hydratedItem) => {
          if (hydratedItem.item.listingType !== "declining_price") {
            return false;
          }
          const canBuyItem = canBuy(hydratedItem);
          return canBuyItem;
        })
        .slice(0, 5);
        
      console.log('newFilteredItemsDecliningPrice:', newFilteredItemsDecliningPrice); // print filteredItemsDecliningPrice to console

      setFilteredItemsDecliningPrice(newFilteredItemsDecliningPrice);
      setProcessingItems(false);
    };

    processItems();
  }
}, [items]);

// const transformedItems = filteredItemsDecliningPrice.map(({item}) => item);
const transformedItems = filteredItemsDecliningPrice.map(({id, item}) => ({id, ...item}));



  useEffect(() => {
    console.log("ProfilePage userData", userData);
  }, [userData]);
  useEffect(() => {
    console.log("profileData", profileData);
  }, [profileData]);
  const UserBadge = () => {
    // Maybe enabled as a seller,
    // But may not be enabled.

    // Seller?
    if (userStatus !== "success") {
      return null;
    }
    return (
      <>
        <div>USER BADGE</div>
        <div>TOGGLE ENABLED/NOT ENABLED USER</div>
      </>
    );
  };




  const AdministratorBadge = () => {
    // Maybe enabled as a seller,
    // But may not be enabled.

    // Seller?
    if (userStatus !== "success") {
      return null;
    }
    return (
      <>
        <div>ADMINISTRATOR BADGE</div>
        <div>TOGGLE ENABLED/NOT ENABLED ADMINSTRATOR</div>
      </>
    );
  };

  const CuratorBadge = () => {
    // Maybe enabled as a seller,
    // But may not be enabled.

    // Seller?
    if (userStatus !== "success") {
      return null;
    }
    return (
      <>
        <div>CURATOR BADGE</div>
        <div>TOGGLE ENABLED/NOT ENABLED CURATOR</div>
      </>
    );
  };

  const SellerBadge = () => {
    // Maybe enabled as a seller,
    // But may not be enabled.

    // Seller?
    if (userStatus !== "success") {
      return null;
    }
    return (
      <>
        <div>SELLER BADGE</div>
        <div>TOGGLE ENABLED/NOT ENABLED SELLER</div>
        <div>
          {" "}
          {userData && userData.seller ? <>SELLER RECORD SEEN</> : null}{" "}
        </div>
        <div>
          {" "}
          {userData && userData.seller && userData.seller.account ? (
            <>WITH ACCOUNT ID</>
          ) : null}{" "}
        </div>
        <div>
          {" "}
          {userData && userData.seller && userData.seller.approvedAt ? (
            <>APPROVED</>
          ) : null}{" "}
        </div>

        <div>TOGGLE APPROVED SELLER STATUS</div>
      </>
    );
  };


  const itemsToRender = filteredItemsDecliningPrice.filter(item => !expiredItems.includes(item) );
  itemsToRender.map(item => console.log("itemstorender", item));

  const AffiliateBadge = () => {
    // Maybe enabled as a seller,
    // But may not be enabled.

    // Seller?
    if (userStatus !== "success") {
      return null;
    }
    return (
      <>
        <div>AFFILIATE BADGE</div>
        <div>TOGGLE ENABLED/NOT ENABLED AFFILIATE</div>
        <div>
          {" "}
          {userData && userData.seller ? <>SELLER RECORD SEEN</> : null}{" "}
        </div>
        <div>
          {" "}
          {userData && userData.seller && userData.seller.account ? (
            <>WITH ACCOUNT ID</>
          ) : null}{" "}
        </div>
        <div>
          {" "}
          {userData && userData.seller && userData.seller.approvedAt ? (
            <>APPROVED</>
          ) : null}{" "}
        </div>

        <div>TOGGLE APPROVED SELLER STATUS</div>
      </>
    );
  };

  const BuyerBadge = () => {
    // Seller?
    if (userStatus !== "success") {
      return null;
    }
    return (
      <>
        <div>BUYER BADGE</div>
        <div>TOGGLE CAN/CAN NOT CHARGE</div>
        <div>TOGGLE CAN/CAN NOT PICKUP</div>
        <div>
          {" "}
          {userData && userData.payment ? <>PAYMENT RECORD SEEN</> : null}{" "}
        </div>
        <div>
          {" "}
          {userData && userData.payment && userData.payment.id ? (
            <>WITH SETUP INTENT</>
          ) : null}{" "}
        </div>
        <div>
          {" "}
          {userData && userData.payment && userData.payment.customer ? (
            <>WITH STRIPE SETUP</>
          ) : null}{" "}
        </div>
        <div>
          {" "}
          {userData && userData.payment && userData.payment.payment_method ? (
            <>CARD SETUP</>
          ) : null}{" "}
        </div>
      </>
    );
  };

  const deprecateBadges = () => {
    if (userStatus !== "success") {
      return null;
    }
    if (auth && auth.user && auth.user.isAdministrator) {
      return (
        <>
          ONLY ADMINISTRATOR SEES THIS
          <br />
          <Grid container display="flex">
            <Grid item sm={2}>
              <UserBadge />
            </Grid>

            <Grid item sm={2}>
              <SellerBadge />
            </Grid>

            <Grid item sm={2}>
              <BuyerBadge />
            </Grid>

            <Grid item sm={2}>
              <AffiliateBadge />
            </Grid>

            <Grid item sm={2}>
              <CuratorBadge />
            </Grid>

            <Grid item sm={2}>
              <AdministratorBadge />
            </Grid>

            <br></br>
          </Grid>
        </>
      );
    }

    return null;
  };

  useEffect(() => {
    console.log("auth user", auth.user);
  }, [auth]);

  //if (profileStatus === "success" && profileData===null && (auth.user && (!auth.user.isAdministrator))) {return( <><NotFoundPage /></>)}
  // if (!isAdministrator) {return (<NotFoundPage />)}

  return (
    <>
      {profileStatus === "loading" ? (
        <>
          <CircularProgress size={32} />
        </>
      ) : null}

      {auth.user && auth.user.isAdministrator ? (
        <>
          <Badges user={seller} sellerProfile={profileData} />
        </>
      ) : null}

      {profileStatus === "success" && profileData !== null ? (
        <>
          <SellerDetailsSection seller={profileData} />

{processingItems && (
 <Box pt={4} style={{ position: "relative", background: "white" }}>
 <Container maxWidth="lg">
   <Box pb={2}>
     <Typography variant="h4">
       <Skeleton height={400}  />
     </Typography>
   </Box>
   <Grid container spacing={3}>
     {[1, 2, 3, 4, 5].map((item) => (
       <Grid item xs={12} sm={6} md={4} lg={3} key={item}>
         <Box pt={1}>
           <Skeleton variant="rect" width="100%" height={200} />
           <Box pt={1}>
             <Skeleton />
             <Skeleton width="60%" />
           </Box>
         </Box>
       </Grid>
     ))}
   </Grid>
 </Container>
</Box>
)}


{!processingItems && itemsStatus === 'success' && filteredItemsDecliningPrice?.length > 0 && (
  <>


      <Box pt={4} style={{ position: "relative", background: "white" }}>
      <Container maxWidth="lg">
        <Box pb={2}>
          <Typography variant="h4">Declining Price Items</Typography>
        </Box>

        <ItemsGridHorizontal items={transformedItems}  />

      </Container>
    </Box>
  </>
)}


{!processingItems && (<>

          {searchQuery && countItems === 0 && (
            <div>
              <NoSearchResults />
            </div>
          )}

          {itemsStatus === 'success' && filteredItemsMarketplace && (
            <>
              <Box pt={8} style={{ position: "relative", background: "white" }}>
                <Container maxWidth="lg">
                  <Typography variant="h4">
                    <Box pb={2}> Marketplace Items </Box>
                  </Typography>
                  {/* <Typography variant='h5' color='textSecondary' gutterBottom><Box >Fantastic finds from .</Box></Typography> */}
                </Container>
                <Search
                  onRequestSearch={(s) => handleRequestSearch(s)}
                  onChangeSearch={(s) => handleChangeSearch(s)}
                  countItems={countItems}
                  flavour="seller"
                  seller={profileData}
                />

                <ItemsGrid
                  items={filteredItemsMarketplace}
                  searchQuery={searchQuery}
                  onCardCount={(c) => handleCardCount(c)}
                />
              </Box>
            </>
          )}
</>)}
          <Box py={2}>
            {/* <ProfileEventList seller={seller} flavour={"standard"} /> */}

            {/* <ProfileItemList seller={seller} flavour={"standard"} />  */}
          </Box>
        </>
      ) : null}

      {profileStatus === "error" ? (
        <>
          <div
            style={{
              padding: "50px",
              width: "100%",
              textAlign: "center",
            }}
          >
            <Typography variant="h6">
              No profile retrieved. Please try to refresh your browser.
            </Typography>
          </div>
        </>
      ) : null}

      {profileStatus === "success" && profileData === null && (
        <>
          <div
            style={{
              padding: "50px",
              width: "100%",
              textAlign: "center",
            }}
          >
            <Typography variant="h6">No Seller Profile Created.</Typography>
          </div>
        </>
      )}
    </>
  );
}

export default ProfilePage;
