import { useState, useRef } from "react";
import { Link } from "react-router-dom";
import Box from "@material-ui/core/Box";

import Typography from "@material-ui/core/Typography";

import CircularProgress from "@material-ui/core/CircularProgress";

import { useIdentity } from "./../util/identity.js";
import { useAuth } from "./../util/auth.js";
import { useAuctionByEvent, useLotByItem, useLotStatus } from "./../util/db.js";
import ItemThumbnail from "./../components/ItemThumbnail";
import { makeStyles } from "@material-ui/core/styles";

// import { forceCheck } from 'react-lazyload';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import LotBid from "./../components/LotBid";
import LotPrice from "./../components/LotPrice";
import LazyLoad from "react-lazyload";
import { getSlug } from "../util/slug.js";

import { privateCatchallFlag } from "./../util/dev.js";

const useStyles = makeStyles((theme) => ({
  root: {
    // minWidth: 275,
    // height: 140,
    // boxShadow: "1px 0px 4px rgb(0 2 4 / 2%), 0 7px 20px rgb(1 1 1 / 3%)",
    padding: "15px",
    // backgroundColor: "#f5f5f5",

    // [theme.breakpoints.down("sm")]: {
    //   backgroundColor: "transparent",
    //   border: "1px solid #c0c0c0",
    // },
    // boxShadow: '3px 10px 16px -6px rgb(230 230 230)',
    border: "1px solid #0000001f",
    "&:hover": {
      // borderColor: "#0000001f",
      // border: "1px solid",
      // backgroundColor: '#f7f7f7',
      boxShadow: "0 0.2rem 0.3rem rgb(0 0 0 / 5%)",
    },
  },

  // cardcontent: {
  //   padding:'0px',
  //   "&:last-child": {
  //     paddingBottom: 5
  //   }
  // },

  cardheader: {
    paddingBottom: "5px",
    opacity: "0.5",
  },

  estimate: {
    justifyContent: "space-between",
    // paddingLeft: '16px',
    // paddingRight: '16px',

    display: "flex",
    alignItems: "center",
  },

  media: {
    height: 180,
    // minHeight: '300px',
    // height: "auto",
    alignItems: "flex-end",
    width: "auto",
    [theme.breakpoints.down("xs")]: {
      // width: "100%",
      // height: 110,
      // paddingTop: '56.25%', // 16:9
    },

    // minHeight: '200px',
    justifyContent: "center",

    display: "flex",
  },

  featured: {
    backgroundColor:
      theme.palette.type === "dark" ? theme.palette.action.selected : "#fdf8c2",
  },
  starFeatured: {
    color: theme.palette.warning.main,
  },
  name: {
    overflow: "hidden",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    lineHeight: "inherit",
    WebkitBoxOrient: "vertical",
    [theme.breakpoints.up("md")]: {
      lineHeight: "1.5em",
      minHeight: "3em",
    },
  },
}));

const ItemCardHorizontal = (props) => {
  const { item, flavour, event } = props;
  const classes = useStyles();
  const { isAuctioneer } = useIdentity();
  const auth = useAuth();
  const isDeveloper = auth?.user?.developer?.enabled;

  const [updatingItemId, setUpdatingItemId] = useState();
  const updatingItemIdRef = useRef({});
  updatingItemIdRef.current = updatingItemId;

  const {
    data: lot,
    status: lotStatus,
    error: lotError,
  } = useLotByItem(item && item.id);

  const itemIsEmpty = !item;

  const {
    data: auction,
    status: auctionStatus,
    error: auctionError,
  } = useAuctionByEvent(item && item.auction);

  const { isLotStarted } = useLotStatus({ ...lot, ...item });
  //const isVisible = (isOwner || isAuctioneer) || isLotStarted;
  const isCardVisible = isLotStarted;
  const handleStatus = (e) => {
    console.log("handleStatus", e);
  };

  return (
    <>
      {(lotStatus === "loading" || itemIsEmpty) && (
        <Box py={5} px={3} align="center">
          {lotStatus === "loading" && (
            <CircularProgress color="secondary" size={32} />
          )}

          {lotStatus !== "loading" && itemIsEmpty && (
            <Typography variant="h4">Add your first item.</Typography>
          )}
        </Box>
      )}

      {lotStatus !== "loading" && item && (
        <>
          <Card
            elevation={0}
            // className={classes.root}
            key={item.id}
            // variant="outlined"
            component={Link}
            to={`/item/${getSlug(item.name)}/${item.id}`}
            style={{
              // backgroundColor:'#f7f7f7',
              borderRadius: "0.5em",
              textDecoration: "none",
              // height: "100%",
              // height: "auto",
              // position: "relative",
              display: "flex",
            }}
          >
            {/* <CardContent style={{backgroundColor:'#f2efef', display:'flex', alignItems:'center'}}>
             */}
            <CardContent style={{ display: "flex", alignItems: "center" }}>
              <CardMedia
                style={{
                  width: 100,
                  display: "flex", // Add flex display
                  alignItems: "center", // Center vertically
                  justifyContent: "center", // Center horizontally
                }}
              >
                <ItemThumbnail item={item} onStatus={(e) => handleStatus(e)} />
              </CardMedia>
            </CardContent>

            <Box style={{ display: "flex", flexDirection: "column", flex: 1,  alignSelf:'center'}}>
              <CardContent style={{ flex: "1 0 auto", }}>
                <Box display="flex">
                  <Box flexGrow={1}>
                    <Typography
                      gutterBottom
                      variant="subtitle1"
                      color="primary"
                      className={classes.name}
                    >
                      {item.name}
                    </Typography>
                  </Box>
                </Box>

                {privateCatchallFlag === true &&
                  event &&
                  event.private &&
                  event.private === true && (
                    <Typography gutterBottom variant="subtitle2">
                      Location: {event.location}
                    </Typography>
                  )}
                {item && item.listingType === "declining_price" || item.listingType !== "marketplace_price" &&(
                  <LotPrice
                    lot={lot}
                    flavour="horizon_price"
                    spice={["item_cardhorizontal"]}
                  />
                )}

                {item && item.listingType === "marketplace_price" &&(

                  <> 
                  <b>Price</b>: {" "}
                  <LotPrice
                    lot={lot}
                    item={item}
                    flavour="marketplace_price"
                    spice={["simple"]}
                  />
                  </>
                )}
              </CardContent>
              <LazyLoad>
                {lot && lot.type === "increasing_bid" && (
                  <>
                    {" "}
                    <Box>
                      {flavour !== "simple" && <LotBid item={item} />}
                    </Box>{" "}
                  </>
                )}
              </LazyLoad>
            </Box>
          </Card>
        </>
      )}
    </>
  );
};

export default ItemCardHorizontal;
