import React from "react";
//import AuthSection from "./../components/AuthSection";
//import { useRouter } from "./../util/router.js";
import { requireAuth } from "./../util/auth.js";
//import SettingsGeneral from "../components/SettingsGeneral";
// import LoginModal from "../components/LoginModal";
//
//import { useShoppingCart } from 'use-shopping-cart';
import { Container } from "@material-ui/core";
import CheckoutDisplay from "../components/CheckoutDisplay";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

function CheckoutPage(props) {

//    const clientSecret = useSetupIntent();
//    const [uuid, setUuid] = useState();



// const [stripePromise, setStripePromise] = useState(() =>
// loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)
// );

const stripePromise =loadStripe (process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);



  return (
    <>
CHECKOUT PAGE

<Container >
 
    <Elements stripe={stripePromise} >
    <CheckoutDisplay  />
 
</Elements>

</Container>

    </>
  );

}

export default requireAuth(CheckoutPage);
