import { TramOutlined } from "@material-ui/icons";

export const bidTable = [
  { bid: 0, increment: 25 },
  { bid: 1000, increment: 50 },
  { bid: 2500, increment: 100 },
  { bid: 5000, increment: 250 },
  { bid: 10000, increment: 500 },
  { bid: 25000, increment: 1000 },
  { bid: 100000, increment: 5000 },
  { bid: 500000, increment: 10000 },
  { bid: 1000000, increment: 20000 },
];

// Test this

export function availableBids(lot, buyerBidMinimum) {
  const dropdownSize = 100;

  var bidAmount = 0;
  bidAmount =
    lot && lot.startingPrice
      ? bidTablePlacedAt(lot.startingPrice) - bidIncrement(lot.startingPrice)
      : bidAmount;
  if (lot && lot.bidAmount) {
    bidAmount = lot.bidAmount;
  }
  const startAt = bidAmount + bidIncrement(bidAmount);

  let t = [];

  //var bidDropDownAmount = startAt;
  var bidDropDownAmount = buyerBidMinimum;
  for (var i = 0; i < dropdownSize; i++) {
    t.push(bidDropDownAmount);
    bidDropDownAmount = bidDropDownAmount + bidIncrement(bidDropDownAmount);
  }
  return t;
}

/*

                            {availableReserves(sellerReserveAmount,lot.bidAmount,lot.startingPrice).map((_reserve) => (
                              <MenuItem value={_res
*/
// Work on this. re "buyerBidMinimum"
export function availableReserves(reservePrice, biddingAt, startAmount) {
  //const dropdownSize = 10;
  //var bidAmount = 0;
  /*
      bidAmount =
        lot && lot.startingPrice
          ? bidTablePlacedAt(lot.startingPrice) - bidIncrement(lot.startingPrice)
          : bidAmount;
      if (lot && lot.bidAmount) {
        bidAmount = lot.bidAmount;
      }
      const startAt = bidAmount + bidIncrement(bidAmount);
  */

  // console.log("reservePrice",reservePrice)
  // console.log("biddingAt", biddingAt)
  let t = [];
  // Test out of bounds reserve request
  //t.push(1000)

  //var bidDropDownAmount = startAt;
  //var bidDropDownAmount = reservePrice;
  var bidDropDownAmount = 0;
  var includeFlag = TramOutlined;
  while (bidDropDownAmount < reservePrice) {
    includeFlag = true;
    //for (var i = 0; i < dropdownSize; i++) {

    if (bidDropDownAmount < startAmount) {
      includeFlag = false;
    }

    if (bidDropDownAmount < biddingAt) {
      includeFlag = false;
    }

    if (bidDropDownAmount === 0) {
      includeFlag = true;
    }

    if (includeFlag === true) {
      t.push(bidDropDownAmount);
    }
    bidDropDownAmount = bidDropDownAmount + bidIncrement(bidDropDownAmount);
  }
  return t;
}

export function bidTablePlacedAt(num) {
  var bidTable = 0;
  var lastBidTable = 0;
  while (num >= bidTable) {
    lastBidTable = bidTable;
    bidTable = bidTable + bidIncrement(bidTable);
  }
  return lastBidTable;
}

export function bidIncrement(num) {
  var increment = 10;
  for (var i = 0; i < bidTable.length; i++) {
    if (num < bidTable[i].bid) {
      break;
    }
    increment = bidTable[i].increment;
  }

  return increment;
}
